
<div class="row">
  <div class="col-sm-6">
    <button class="btn btn-block w-100" (click)="setCommon()" [class.btn-primary]="curpage==null" [class.btn-outline-primary]="curpage!==null">Paramètres communs</button>
  </div>
  <div class="col-sm-6" *ngIf="pages &amp;&amp; pages.length &gt; 0">
    <form [formGroup]="form">
      <select class="form-select" formControlName="page">
        <option [ngValue]="null">Sélectionnez une page</option>
        <option [ngValue]="null" *ngIf="emptyName">{{emptyName}}</option>
        <option *ngFor="let page of pages" [ngValue]="getPageId(page)">{{getPageDesc(page)}}</option>
      </select>
    </form>
  </div>
</div>