
<div class="row">
  <div class="col-sm-3" *ngIf="!upload">
    <lvadg-upload-file [url]="uploadImportUrl" (uploaded)="onUploaded($event)" [data]="{code: uploadCode}"></lvadg-upload-file>
  </div>
  <div class="col-sm-3" *ngIf="upload">
    <pre>Uploaded <code>{{upload.code}}</code></pre>
    <pre>{{upload.filename}}</pre>
    <pre>{{upload.filesize}} octets</pre>
  </div>
  <div class="col-sm-9">
    <form class="row" [formGroup]="form">
      <div class="col-3">
        <div class="form-group">
          <label>Nom de la feuille</label>
          <input class="form-control" formControlName="sheetName">
        </div>
        <div class="form-group">
          <label>Skip header</label>
          <input class="form-control" formControlName="skip" type="number">
        </div>
        <div class="form-group mt-4">
          <button class="btn btn-primary btn-block w-100" (click)="launchImport()"><i class="fa fa-cogs me-2"></i>Lancer l'importation</button>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>Champs à importer</label>
          <select class="form-select form-select-sm" [multiple]="true" formControlName="fields" size="15">
            <option *ngFor="let f of ALL_FIELDS" [ngValue]="f">{{f}}</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>Champs pour match</label>
          <select class="form-select form-select-sm" [multiple]="true" formControlName="match" size="15">
            <option *ngFor="let f of ALL_FIELDS" [ngValue]="f">{{f}}</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>Champs pour update</label>
          <select class="form-select form-select-sm" [multiple]="true" formControlName="update" size="15">
            <option *ngFor="let f of ALL_FIELDS" [ngValue]="f">{{f}}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="col-12" *ngIf="result">
    <lvadg-oplog-display [result]="result?.result"></lvadg-oplog-display>
  </div>
</div>