import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {OfferSelectComponent} from "./offer-select/offer-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [OfferSelectComponent],
  exports: [OfferSelectComponent]
})
export class OfferSelectModule {}
