import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";


export class ArticleTarifItem extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSForeignKeyField({
    name: "articletarif",
    verboseName: "Ligne tarif",
    required: false,
    helpText: "Ligne de tarif",
    relatedModel: "tarifs.ArticleTarif",
    remoteField: "id",
    relatedName: "items"
  })
  public articletarif!: number;


  @DSDecimalField({
    name: "price",
    verboseName: "Prix",
    required: false,
    helpText: "Prix",
    default: null,
    factor: 10000,
  })
  public price!: number;

  @DSForeignKeyField({
    name: "storage",
    verboseName: "Dépôt",
    required: false,
    helpText: "Dépôt",
    relatedModel: "structure.Storages",
    remoteField: "id",
    relatedName: "articletarifitems"
  })
  public storage!: number;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "Etat",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["OK", "Valide"],
      ["TV", "A valider"]
    ]
  })
  public status!: string;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSIntegerField({
    required: false,
    name: "available",
    helpText: "Disponibilité",
    verboseName: "Disponibilité",
    default: 0
  })
  public available!: number;



}

@Injectable({
  providedIn: "root"
})
export class ArticleTarifItemService extends DSRestCollection<ArticleTarifItem> {
  public adapter_config = {basePath: "/api/v2/tarifs/articletarifitems"};
  public model = ArticleTarifItem;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
