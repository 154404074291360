/**
 * Label : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @changed 2018-04-26-12:56:53
 * @date 2018-04-26-12:56:59
 * @copyright (c) 2018 Jean-Matthieu BARBIER
 */
import {BaseLabel, BaseLabelService} from "../../product/_label/label-base.model";
import {Injectable} from "@angular/core";
import {DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {Label} from "../../product/label/label.service";


export interface SLabelCache {
  [index: number]: SLabel;
}

export class SLabel extends BaseLabel {

}

@Injectable({providedIn: "root"})
export class SLabelService extends BaseLabelService<Label> {
  public adapter_config = {basePath: "/api/v2/structure/slabels"};
  public model = SLabel;


  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}


