import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Site, SiteService} from "./site.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class SiteResolve implements Resolve<Site> {
  constructor(private _bs: SiteService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Site> {
    return this._bs.get(+route.params["siteId"]);
  }
}
