import {Inject, Injectable, InjectionToken} from "@angular/core";

export const SENTRY_MAIN = new InjectionToken<any>("lvadg.sentry");


@Injectable({
  providedIn: "root"
})
export class SentryErrorHandlerService {
  constructor(@Inject(SENTRY_MAIN) public Sentry: any) {
  }

  handleError(error: any) {
    console.error(error);
    const eventId = this.Sentry.captureException(error.originalError || error);
    // this.Sentry.showReportDialog({eventId});
  }
}
