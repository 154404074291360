
<div class="navigation">
  <div class="prev pointer" *ngIf="allowPageChange &amp;&amp; type=='page'" (click)="previousPage()"><i class="fa fa-caret-left me-2"></i>Page précédente</div>
  <div class="current" *ngIf="allowPageChange &amp;&amp; type=='page'">{{curpage?.name||emptyName}}</div>
  <div class="current" *ngIf="type=='email'">
    <ng-container *ngIf="curpage?.dmail">Destinataire : {{curpage.dmail||''}}</ng-container>
    <ng-container *ngIf="!curpage?.dmail">{{emptyName}}</ng-container>
  </div>
  <div class="zoom"><i class="fa fa-search-plus me-2 pointer" (click)="zoomPlus()"></i><i class="fa fa-search-minus pointer" (click)="zoomMinus()"></i></div>
  <div class="next pointer" *ngIf="allowPageChange  &amp;&amp; type=='page'" (click)="nextPage()">Page suivante<i class="fa fa-caret-right ms-2"></i></div>
</div>
<div class="preview">
  <iframe id="printpreview" [ngClass]="zoomClass()" [srcdoc]="preview||''" [class.loading]="loading"></iframe>
  <div class="loading text-center" *ngIf="loading"><i class="fa fa-spinner fa-4x fa-spin mt-4"></i>
    <div class="mt-2">Chargement en cours...</div>
  </div>
</div>