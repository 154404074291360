import {Component, Input, OnInit} from "@angular/core";
import {DSRestBackend} from "@solidev/ngdataservice";
import {Tarif, TarifService} from "../tarif.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import slugify from "slugify";

@Component({
  selector: "lvadg-tarif-export",
  templateUrl: "./tarif-export.component.pug",
  styleUrls: ["./tarif-export.component.sass"]
})
export class TarifExportComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public mode: 'export'|'nego' = 'export';
  @Input() public btnClasses: string = "btn btn-primary";
  @Input() public label = "Export excel";
  public exportingXlsx: boolean = false;

  constructor(private _ts: TarifService,
              private _http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private _msgs: BsacMessageService,
              private _modal: NgbModal) {
  }

  ngOnInit(): void {
  }

  public async xlsxExport(nego: boolean = false): Promise<void> {
    this.exportingXlsx = true;
    const a = document.createElement("a");
    document.body.appendChild(a);
    (a as any).style = "display: none";
    const createXlsxFromBlob = (file: Blob, name: string) => {
      a.download = slugify(name) + ".xlsx";
      a.href = window.URL.createObjectURL(file);
      a.click();
    };
    const adapt = this._ts.queryset.collection.adapter.detail_action(this.tarif, "xlsx_export");
    const options = (this._ts.queryset.collection.backend as DSRestBackend).getRequestOptions(adapt);
    // Force accept header to excel mimetype
    (options.headers as {[header: string]: string})["accept"] = "application/octet-stream";
    options.responseType = "blob";
    (options.params as {[param: string]: string })["page_size"] = "10000";
    if(nego) {
      (options.params as {[param: string]: string })["mode"] = "nego";
    }
    this._http.request("GET", (this._ts.queryset.collection.backend as DSRestBackend).getRequestUrl(adapt), {
      responseType: "blob",
      headers: options.headers,
      params: options.params
    }).subscribe({
      next: (res) => {
        this.exportingXlsx = false;
        this._msgs.success("L'export Xslx est prêt");
        createXlsxFromBlob(res, this.tarif.name.replace(/\//g, "-"));
      },
      error: (err) => {
        this._msgs.error("Erreur lors de l'export xlsx", err);
        this.exportingXlsx = false;
      }
    });
  }
}
