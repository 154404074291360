import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField,
  DSTextField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";

export class FolderAccess {
  id?: number;
  admin_only: boolean;
  all_members: boolean;
  all_clients: boolean;
  all_restos: boolean;
  members: number[];
  members_details: { id: number; name: string }[];
  clients: number[];
  clients_details: { id: number; name: string }[];
  restos: number[];
  restos_details: { id: number; name: string }[];
  persons: number[];
  persons_details: { id: number; lastname: string; firstname: string; email: string }[];
  created: Date;
  updated: Date;

  constructor(data: any = {}) {
    Object.assign(this, data);
  }
}


export class Folder extends DSModel {
  @DSAutoField({
    name: "id"
  })
  public id!: number;

  @DSCharField({
    name: "zone",
    choices: [
      ["FLD", "F&L - Zone documents"],
      ["FLMRD", "F&L&M - Zone documents"],
      ["MRD", "Marée - Zone documents"],
      ["FLT", "F&L - Zone tarifs"],
      ["FLMRT", "F&L&M - Zone tarifs"],
      ["MRT", "Marée - Zone tarifs"]
    ]
  })
  public zone!: string;

  @DSCharField({
    name: "title",
    verboseName: "Titre",
    minLength: 3,
    maxLength: 200,
    required: true
  })
  public title!: string;

  @DSSlugField({
    name: "slug",
    required: false
  })
  public slug!: string;

  @DSForeignKeyField({
    required: false,
    name: "parent"
  })
  public parent!: number;

  @DSCharField({
    name: "type",
    required: false,
    default: "default",
    choices: [
      ["default", "Affichage par défaut"],
      ["file", "Dossier de fichiers"],
      ["video", "Dossier de vidéos"],
      ["price", "Dossier de tarifs"]
    ]
  })
  public type!: string;

  @DSForeignKeyField({
    required: false,
    name: "logo",
    helpText: "",
    verboseName: "ID Logo",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
  })
  public logo!: number;
  public logo_img!: Rimg;

  @DSForeignKeyField({
    required: false,
    name: "read_access"
  })
  public read_access!: number;

  @DSForeignKeyField({
    required: false,
    name: "cread_access"
  })
  public cread_access!: number;


  @DSForeignKeyField({
    required: false,
    name: "write_access"
  })
  public write_access!: number;

  @DSForeignKeyField({
    required: false,
    name: "cread_access"
  })
  public cwrite_access!: number;


  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date de mise à jour",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "En ligne"],
      ["ST", "En ligne - staff uniquement"],
      ["SU", "En ligne - superadmin uniquement"],
      ["KO", "Hors ligne"],
      ["DE", "Hors ligne, supprimé"]
    ],
    default: null,
    maxLength: 4,
  })
  public status!: string;


  @DSCharField({
    required: false,
    name: "content_type",
    helpText: "",
    verboseName: "Codage description",
    choices: [],
    default: "md",
    maxLength: 4,
  })
  public content_type!: string;

  @DSTextField({
    required: false,
    name: "content_src",
    helpText: "",
    verboseName: "Description",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content_src!: string;

  @DSTextField({
    required: false,
    name: "content",
    helpText: "",
    verboseName: "Description html",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content!: string;


  @DSDefaultField({
    required: false,
    name: "tags",
    helpText: "",
    verboseName: "tags",
    choices: [],
    default: [],
  })
  public tags!: any;

  @DSIntegerField({
    required: false,
    name: "level"
  })
  public level!: number;

  @DSIntegerField({
    required: false,
    name: "lft"
  })
  public lft!: number;

  @DSIntegerField({
    required: false,
    name: "rght"
  })
  public rght!: number;

  @DSIntegerField({
    required: false,
    name: "tree_id"
  })
  public tree_id!: number;
  @DSDefaultField({
    required: false,
    name: "read_access_details"
  })
  public read_access_details!: FolderAccess;

  @DSDefaultField({
    required: false,
    name: "read_access_raw"
  })
  public read_access_raw!: FolderAccess;

  public parents!: { id: number; title: string }[];


  public get fullPath(): string {
    const out = [];
    if (!this.parents) {
      return "";
    }
    for (const p of this.parents) {
      out.push(p.title);
    }
    return out.join(" :: ");
  }

}


@Injectable({
  providedIn: "root"
})
export class FolderService extends DSRestCollection<Folder> {
  public adapter_config = {basePath: "/api/v2/document/folders"};
  public model = Folder;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
