import {Component, Input, OnInit} from "@angular/core";
import {Article} from "../../../catalog/article/article.service";
import {Rnm} from "../rnm.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-rnm-article-details",
  templateUrl: "./rnm-article-details.component.pug",
  styleUrls: ["./rnm-article-details.component.sass"]
})
export class RnmArticleDetailsComponent implements OnInit {
  @Input() public article!: Article;
  @Input() public rnm!: Rnm;

  public rnmData: { rnm: string; id: number; crit: string; price: number | null }[] = [];

  constructor() {
  }

  async ngOnInit(): Promise<void> {
    if (this.article) {
      this.rnmData = await firstValueFrom(this.article.action("rnm_candidates", {method: "GET"}));
    }
  }

}
