
<ng-container *ngIf="display=='view'">
  <bsac-breadcrumb></bsac-breadcrumb>
  <button class="btn btn-outline-primary btn-sm float-end ms-2" (click)="create=!create"><i class="fa fa-plus me-2"></i>Ajouter un article</button>
  <h1 class="page-header"><i class="fa fa-book-open me-2"></i>Catalogue externe</h1>
</ng-container>
<div class="card my-2" *ngIf="create">
  <div class="card-header ch-1"><i class="fa fa-plus me-2"></i>Ajouter un article</div>
  <div class="card-body">
    <lvadg-externalarticle-create (created)="createdArticle($event)" (cancelled)="create=false"></lvadg-externalarticle-create>
  </div>
</div>
<lvadg-externalarticle-list *ngIf="ready" [article_detail_route]="article_detail_route" [externalarticle_detail_route]="externalarticle_detail_route" [allowed_fields]="allowed_fields" [default_fields]="default_fields" [excluded_fields]="excluded_fields" [allowed_filters]="allowed_filters" [default_filters]="default_filters" [excluded_filters]="excluded_filters"></lvadg-externalarticle-list>