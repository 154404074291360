
<bsac-breadcrumb></bsac-breadcrumb>
<div class="card" *ngIf="create">
  <h4 class="card-header"><i class="fa fa-plus me-2"></i>Ajout manuel de relation</h4>
  <div class="card-body">
    <lvadg-relation-create (created)="created($event)"></lvadg-relation-create>
  </div>
</div>
<h1 class="page-header">
  <button class="btn btn-info float-end" (click)="create=!create"><i class="fa fa-plus me-2"></i><span class="hidden-sm">Ajout manuel</span></button><i class="fa fa-link me-2"></i>Relations
</h1>
<lvadg-relation-list *ngIf="ready" [name]="currentRoute?.name||'relation-list'" [mode]="mode" [displayMode]="mode==='user'?'vertical':'horizontal'" [provider_detail_route]="provider_detail_route" [producer_detail_route]="producer_detail_route" [seaproducer_detail_route]="seaproducer_detail_route" [resto_detail_route]="resto_detail_route" [client_detail_route]="client_detail_route" [storage_detail_route]="storage_detail_route" [member_detail_route]="member_detail_route" [detail_route]="relation_detail_route" [reload]="reload$"></lvadg-relation-list>