import {Injectable} from "@angular/core";
import {Article, ArticleService} from "./article.service";
import {firstValueFrom} from "rxjs";
import {BsacModelList} from "@solidev/bsadmincomponents";
import {formatCurrency} from "@angular/common";
import {NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {ArticleStatDetailComponent} from "./article-stat-detail/article-stat-detail.component";
import {ArticleFamily, ArticleFamilyService} from "../family/article-family.service";

export type STAT_INTERVALS = "curtrim" | "lasttrim" | "cursem" | "lastsem" | "curyear" | "lastyear" | "last4w" | "last100d"
export type STAT_GROUPERS = "W" | "M"

export interface StatsParams {
  start: Date,
  end: Date,
  columns: string[]
}

export interface ArticleDetailStats {
  min: number,
  max: number,
  avg: number,
  sdev: number,
  prices: {
    [index: string]: {
      min: number,
      max: number,
      avg: number,
      sdev: number,
      count: number,
      details: {
        date: Date,
        price: number,
        tarif: string,
        tarif_id: number,
        tariftype: string,
        tariftype_id: number,
        pilotes: string[]
      }[]
    }
  }
}


@Injectable({
  providedIn: "root"
})
export class ArticleStatService {
  public defaultColumns: { id: number, code: string; name: string }[];

  constructor(private _as: ArticleService,
              private _af: ArticleFamilyService,
              private _ofc: NgbOffcanvas) {
    this.defaultColumns = [];
    for (let i = 0; i < 54; i++) {
      this.defaultColumns.push({id: i, code: `tarifs_stats_${i}`, name: `Colonne stats ${i}`});
    }
  }


  public STAT_INTERVALS = [
    {desc: "Trimestre en cours", value: "curtrim"},
    {desc: "Trimestre précédent", value: "lasttrim"},
    {desc: "Semestre en cours", value: "cursem"},
    {desc: "Semestre précédent", value: "lastsem"},
    {desc: "Année en cours", value: "curyear"},
    {desc: "Année précédente", value: "lastyear"},
    {desc: "4 dernières semaines", value: "last4w"},
    {desc: "100 derniers jours", value: "last100d"},
  ];
  public STAT_GROUPERS = [
    {value: "W", desc: "Semaine"},
    {value: "M", desc: "Mois"},
  ];

  public interval: STAT_INTERVALS = "curtrim";
  public grouper: STAT_GROUPERS = "W";
  public params?: StatsParams;

  public column(i: number): { active: boolean, name: string, code: string } {
    const code = `tarifs_stats_${i}`;
    if (!this.params) {
      return {active: false, name: "--", code};
    }
    if (this.params.columns[i]) {
      return {active: true, name: this.params.columns[i], code};
    }
    return {active: false, name: "--", code};
  }

  public async setInterval(i?: STAT_INTERVALS, g?: STAT_GROUPERS) {
    if (i) {
      this.interval = i;
    }
    if (g) {
      this.grouper = g;
    }
    this.params = await firstValueFrom(this._as.action(null, "stats_params", {
      method: "POST",
      body: {
        interval: this.interval,
        grouper: this.grouper
      }
    }));

  }

  public updateFields(list: BsacModelList<Article|ArticleFamily>) {
    for (let i = 0; i < 52; i++) {
      const cd = this.column(i);
      if (cd.active) {
        list.fields.enable(cd.code);
      } else {
        list.fields.disable(cd.code);
      }
    }
  }

  public display(article: Article, col: number): string {
    if (article.tarifs_stats && article.tarifs_stats.stat[col]) {
      const st = article.tarifs_stats.stat[col];
      if (st.avg) {
        return `${formatCurrency(st.avg, "fr", "€", "EUR")}`;
      }
    }
    return "";
  }

  public summary(article: Article|ArticleFamily, col: number): string {
    let out = "";
    if (article.tarifs_stats && article.tarifs_stats.stat[col]) {
      const st = article.tarifs_stats.stat[col];
      if (st.min !== st.avg) {
        out += `min: ${formatCurrency(st.min, "fr", "€", "EUR")} `;
      }
      if (st.max !== st.avg) {
        out += `max: ${formatCurrency(st.max, "fr", "€", "EUR")} `;
      }
      if (st.sdev) {
        out += `écart moyenne: ${formatCurrency(st.sdev, "fr", "€", "EUR")} `;
      }
      if (st.count && st.count > 0) {
        out += `nb tarifs: ${st.count} `;
      }

    }
    return out;
  }


  public danger(article: Article|ArticleFamily, col: number): boolean {
    if (article.tarifs_stats && article.tarifs_stats.stat[col]) {
      const st = article.tarifs_stats.stat[col];
      if (!st.sdev || st.count <= 2) {
        return false
      }
      if (st.min < st.avg - 1.5 * st.sdev ) {
        return true
      }
      if (st.max > st.avg + 1.5 * st.sdev ) {
        return true
      }
    }
    return false
  }

  public warning(article: Article|ArticleFamily, col: number): boolean {
    if (article.tarifs_stats && article.tarifs_stats.stat[col]) {
      const st = article.tarifs_stats.stat[col];
      if (!st.sdev || st.count <= 2) {
        return false
      }
      if (st.min < st.avg -1.2 * st.sdev && st.min > st.avg - 1.5 * st.sdev ) {
        return true
      }
      if (st.max > st.avg + 1.2 * st.sdev && st.max < st.avg + 1.5 * st.sdev ) {
        return true
      }
    }
    return false

  }
  public async dispDetail(article: Article, col: number) {
    const focus = this.column(col).name
    const details: ArticleDetailStats = await firstValueFrom(this._as.action(article, "stats_details", {
      method: "POST",
      body: {
        interval: this.interval,
        grouper: this.grouper,
        focus: col
      }
    }));
    const ofcref = this._ofc.open(ArticleStatDetailComponent, {position: "bottom", scroll: true,
    panelClass: "h-auto"});
    console.log(details);
    ofcref.componentInstance.article = article;
    ofcref.componentInstance.stats = details;
    ofcref.componentInstance.focus = focus;

  }


}
