import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseOfferProduct} from "../_base/baseofferproduct.service";

export class OfferSeaProduct extends BaseOfferProduct {
}


@Injectable({
  providedIn: "root"
})
export class OfferSeaProductService extends DSRestCollection<OfferSeaProduct> {
  public adapter_config = {basePath: "/api/v2/offerseaproducts"};
  public model = OfferSeaProduct;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
