import {Component, Input, OnChanges} from "@angular/core";
import {ArticleTarif, ArticleTarifStatus, LastPriceData} from "../../articletarif.service";

@Component({
  selector: "lvadg-articletarif-lastprice-display",
  templateUrl: "./articletarif-lastprice-display.component.pug",
  styleUrls: ["./articletarif-lastprice-display.component.sass"]
})
export class ArticletarifLastpriceDisplayComponent implements OnChanges {
  /**
   * ArticleTarif used for price display.
   */
  @Input() public at!: ArticleTarif;
  /**
   * Currently selected articletarif (for color management)
   */
  @Input() public current?: ArticleTarif;
  @Input() public price!: number;
  @Input() public index: number = 0;
  @Input() public variation: boolean = false;
  public cur?: LastPriceData;
  public ref?: LastPriceData;
  public ArticleTarifStatus = ArticleTarifStatus;

  constructor() {
  }

  public get direction(): number {
    if (this.cur && this.cur.value !== undefined &&
      this.ref && this.ref.value !== undefined) {
      if (this.cur.value <= this.ref.value) {
        if (this.at.id === this.current?.id) {
          return 2;
        }
        return 1;
      }
      return -1;
    }
    return 0;
  }

  public ngOnChanges(): void {
    // Get current price comparison
    if (this.at && this.at.lastprices && this.at.lastprices[this.index]) {
      this.cur = this.at.lastprices[this.index];
    } else {
      delete this.cur;
    }
    // Get reference price (only for valid atts)
    if (this.at && this.at.att_details.status == "ACT" && this.at.price !== undefined) {
      this.ref = {value: this.at?.price, date: null, current: true};
    } else {
      delete this.ref;
    }
  }

}
