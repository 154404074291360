import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {Producer, ProducerService} from "../../../structure/producer/producer.service";
import {SeaProducer, SeaProducerService} from "../../../structure/seaproducer/seaproducer.service";
import {Provider, ProviderService} from "../../../structure/provider/provider.service";
import {Storage, StorageService} from "../../../structure/storage/storage.service";
import {Article, ArticleService} from "../../../catalog/article/article.service";
import {DetailDocument, DetailDocumentService} from "../basedocument.service";
import {ProductService} from "../../../product/product/product.service";
import {SeaProductService} from "../../../product/seaproduct/seaproduct.service";
import {LabelService} from "../../../product/label/label.service";
import {SeaLabelService} from "../../../product/sealabel/sealabel.service";
import {SLabelService} from "../../../structure/slabel/slabel.service";
import {ArticleFamily, ArticleFamilyService} from "../../../catalog/family/article-family.service";

@Component({
  selector: "lvadg-detaildocument-listmanage",
  templateUrl: "./detaildocument-listmanage.component.pug",
  styleUrls: ["./detaildocument-listmanage.component.sass"]
})
export class DetailDocumentListmanageComponent implements OnInit, OnChanges {
  @Input() public model!: Producer | SeaProducer | Provider | Storage | Article | ArticleFamily;
  @Input() public documents!: DetailDocument[];
  @Input() public fields = ["title", "type", "start", "end", "status", "article", "articlefamily",
    "producer", "seaproducer", "storage", "provider", "products",
    "seaproducts", "article", "prlabel", "seaprlabel", "stlabel"];
  @Input() public type!: string;
  @Input() public uploadData: any = {};
  @Output() public deleted = new EventEmitter<void>();
  @Output() public added = new EventEmitter<void>();

  public pdocuments: DetailDocument[] = [];

  constructor(public _docs: DetailDocumentService,
              public article$: ArticleService,
              public articlefamily$: ArticleFamilyService,
              public producer$: ProducerService,
              public seaproducer$: SeaProducerService,
              public provider$: ProviderService,
              public storage$: StorageService,
              public product$: ProductService,
              public seaproduct$: SeaProductService,
              public prlabel$: LabelService,
              public seaprlabel$: SeaLabelService,
              public stlabel$: SLabelService) {
  }

  async ngOnInit(): Promise<void> {
    const pdocuments = [];
    if (this.documents && this.documents.length) {
      for (const d of this.documents) {
        if (!this.type || this.type === d.type) {
          if (!d.download) {
            pdocuments.push(await this._docs.create(d, {save: false}).toPromise());
          }
        }
      }
    }
    this.pdocuments = pdocuments;
    if (this.type) {
      this.uploadData.type = this.type;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    console.log("Changes detected", changes);
    this.ngOnInit();
  }


  async download(d: DetailDocument) {
    d.download();
  }

  public async deleteDocument(doc: DetailDocument) {
    await doc.delete().toPromise();
    this.deleted.emit();
  }

  public getUploadUrl(): string {
    if (!this.model) {
      return "";
    }
    return (this.model._collection.backend as any).getRequestUrl(
      (this.model._collection as any).adapter.detail_action(this.model, "add_document")
    );
  }

  public async onUploaded(event: any): Promise<void> {
    await this.added.next();
  }

  public display(name: string): boolean {
    return this.fields.indexOf(name) !== -1;
  }

}
