
<p class="text-muted">Accès global</p>
<div class="row">
  <div class="col-12 col-xl-6 mb-1">
    <button class="btn btn-block w-100 btn-outline-warning" *ngIf="curaccess.admin_only" (click)="setAdminOnly(false)">Admin</button>
    <button class="btn btn-block w-100 btn-warning" *ngIf="!curaccess.admin_only" (click)="setAdminOnly()">Admin</button>
  </div>
  <div class="col-12 col-xl-6 mb-1">
    <button class="btn btn-block w-100 btn-success" *ngIf="curaccess.all_members" (click)="setAllMembers(false)">Adhérents</button>
    <button class="btn btn-block w-100 btn-outline-success" *ngIf="!curaccess.all_members" (click)="setAllMembers()">Adhérents</button>
  </div>
  <div class="col-12 col-xl-6 mb-1">
    <button class="btn btn-block w-100 btn-success" *ngIf="curaccess.all_clients" (click)="setAllClients(false)">Clients</button>
    <button class="btn btn-block w-100 btn-outline-success" *ngIf="!curaccess.all_clients" (click)="setAllClients()">Clients</button>
  </div>
  <div class="col-12 col-xl-6 mb-1">
    <button class="btn btn-block w-100 btn-success" *ngIf="curaccess.all_restos" (click)="setAllRestos(false)"> Restos</button>
    <button class="btn btn-block w-100 btn-outline-success" *ngIf="!curaccess.all_restos" (click)="setAllRestos()"> Restos</button>
  </div>
</div>
<hr>
<p class="text-muted">Accès individuel</p>
<dl>
  <dt><i class="fa fa-plus pointer float-end" (click)="show.addmember=!show.addmember"></i>Adhérents</dt>
  <dd>
    <lvadg-member-select *ngIf="show.addmember" (selected)="addMember($event)"></lvadg-member-select>
    <div *ngFor="let m of curaccess.members_details"><i class="fa fa-trash pointer me-2" (click)="removeMember(m.id)"></i>{{m.name}}</div>
  </dd>
  <dt><i class="fa fa-plus pointer float-end" (click)="show.addclient=!show.addclient"></i><b>Clients</b></dt>
  <dd>
    <lvadg-client-select *ngIf="show.addclient" (selected)="addClient($event)"></lvadg-client-select>
    <div *ngFor="let m of curaccess.clients_details"><i class="fa fa-trash pointer me-2" (click)="removeClient(m.id)"></i>{{m.name}}</div>
  </dd>
  <dt><i class="fa fa-plus pointer float-end" (click)="show.addresto=!show.addresto"></i><b>Restos</b></dt>
  <dd>
    <lvadg-resto-select *ngIf="show.addresto" (selected)="addResto($event)"></lvadg-resto-select>
    <div *ngFor="let m of curaccess.restos_details"><i class="fa fa-trash pointer me-2" (click)="removeResto(m.id)"></i>{{m.name}}</div>
  </dd>
  <dt><i class="fa fa-plus pointer float-end" (click)="show.addperson=!show.addperson"></i><b>Personnes</b></dt>
  <dd>
    <lvadg-person-select *ngIf="show.addperson" (selected)="addPerson($event)"></lvadg-person-select>
    <div *ngFor="let m of curaccess.persons_details"><i class="fa fa-trash pointer me-2" (click)="removePerson(m.id)"></i>{{m.lastname}} {{m.firstname}} {{m.email}}</div>
  </dd>
</dl>