import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Relation, RelationService} from "./relation.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class RelationResolve implements Resolve<Relation> {
  constructor(private _bs: RelationService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Relation> {
    return this._bs.get(+route.params["relationId"]);
  }
}
