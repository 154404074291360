// @changed 2020-06-30-08:23:36
import {NgModule} from "@angular/core";
import {SeaProducerCreateComponent} from "./seaproducer-create/seaproducer-create.component";
import {SeaProducerDeleteComponent} from "./seaproducer-delete/seaproducer-delete.component";
import {SeaProducerDisplayComponent} from "./seaproducer-display/seaproducer-display.component";
import {SeaProducerListComponent} from "./seaproducer-list/seaproducer-list.component";
import {SeaProducerManageComponent} from "./seaproducer-manage/seaproducer-manage.component";
import {SeaProducerService} from "./seaproducer.service";
import {SeaProducerResolve} from "./seaproducer.resolve";
import {LocationModule} from "../location/location.module";
import {StorageModule} from "../storage/storage.module";
import {ImageModule} from "../../content/image/image.module";
import {NgStringPipesModule} from "ngx-pipes";
import {SLabelModule} from "../slabel/slabel.module";
import {SelectionModule} from "../../selection/selection.module";
import {SeaLabelModule} from "../../product/sealabel/sealabel.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {VideoModule} from "../../content/video/video.module";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {SeaProducerSelectModule} from "./seaproducer-select.module";
import {BaseDocumentModule} from "../../document/basedocument/base-document.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    CustomDataModule,
    LocationModule,
    StorageModule,
    SLabelModule,
    SelectionModule,
    SeaLabelModule,
    NgStringPipesModule,
    VideoModule,
    ImageModule,
    SeaProducerSelectModule,
    BaseDocumentModule
  ],
  providers: [
    SeaProducerService,
    SeaProducerResolve
  ],
  declarations: [
    SeaProducerDisplayComponent,
    SeaProducerDeleteComponent,
    SeaProducerListComponent,
    SeaProducerManageComponent,
    SeaProducerCreateComponent,
  ],
  exports: [
    SeaProducerSelectModule,
    SeaProducerDisplayComponent,
    SeaProducerDeleteComponent,
    SeaProducerListComponent,
    SeaProducerManageComponent,
    SeaProducerCreateComponent,

  ],
})
export class SeaProducerModule {
}
