import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {ExternalArticleCollection, ExternalArticleCollectionService} from "../externalarticlecollection.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-externalarticlecollection-create",
  templateUrl: "./externalarticlecollection-create.component.pug",
  styleUrls: ["./externalarticlecollection-create.component.sass"]
})
export class ExternalarticlecollectionCreateComponent implements OnInit {
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public eac!: ExternalArticleCollection;
  @Output() public created = new EventEmitter<ExternalArticleCollection>();

  constructor(private _eac: ExternalArticleCollectionService) {
  }

  public async ngOnInit(): Promise<void> {
    this.eac = await firstValueFrom(this._eac.create({type: "STO", status: "OK"}));
  }

  public async create(): Promise<void> {
    this.eac.assign(this.form.value);
    await firstValueFrom(this.eac.save());
    this.created.next(this.eac);
  }

}
