
<div class="row g-3" *ngIf="tarifType">
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Données générales</div>
      <div class="card-body">
        <dl class="row">
          <bsac-dd-field class="col-4" [model]="tarifType" field="id" type="auto" [editable]="false">ID modèle</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="code" type="auto" [editable]="adminEditable">Code</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="type" type="auto" [editable]="adminEditable">Type de modèle</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="status" type="auto" [editable]="adminEditable">Etat</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="zone" type="auto" [editable]="adminEditable">Zone</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="lifecycle" type="auto" [editable]="adminEditable">Cycle de vie</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="storages_mode" type="auto" [editable]="adminEditable">Mode dépôts</bsac-dd-field>
        </dl>
        <dl class="row">
          <bsac-dd-field class="col-12" [model]="tarifType" field="name" type="auto" [editable]="adminEditable">Nom du modèle</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="tarifType" field="name_template" type="auto" [editable]="adminEditable">Template de nom</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <bsac-dd-field class="col-4" [model]="tarifType" field="asscom" type="auto" [related]="persons$" desctpl="&lt;%= obj.lastname %&gt; &lt;%= obj.firstname %&gt; [&lt;%= obj.email %&gt;]" relatedEmpty="Ass. comm. à sélectionner" relatedLabel="Sélectioner l'ass. com.." [editable]="adminEditable">Ass. Comm.</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="respcom" type="auto" [related]="persons$" desctpl="&lt;%= obj.lastname %&gt; &lt;%= obj.firstname %&gt; [&lt;%= obj.email %&gt;]" relatedEmpty="Resp. comm. à sélectionner" relatedLabel="Sélectioner le/la resp. com.." [editable]="adminEditable">Resp. Comm.</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="pilotes" type="auto" [related]="persons$" desctpl="&lt;%= obj.lastname %&gt; &lt;%= obj.firstname %&gt; [&lt;%= obj.email %&gt;]" relatedEmpty="Pilote à sélectionner" relatedLabel="Sélectioner les pilotes tarifaires" [editable]="adminEditable">Pilote(s) tarifaire</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="admin_groups" type="auto" [related]="groups$" desctpl="[&lt;%= obj.slug %&gt;] &lt;%= obj.description %&gt;" relatedEmpty="Ajouter des groupes" relatedLabel="Ajoutez des groupes" [editable]="adminEditable">Groupes (admin)</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="write_groups" type="auto" [related]="groups$" desctpl="[&lt;%= obj.slug %&gt;] &lt;%= obj.description %&gt;" relatedEmpty="Ajouter des groupes" relatedLabel="Ajoutez des groupes" [editable]="adminEditable">Groupes (écriture)</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="read_groups" type="auto" [related]="groups$" desctpl="[&lt;%= obj.slug %&gt;] &lt;%= obj.description %&gt;" relatedEmpty="Ajouter des groupes" relatedLabel="Ajoutez des groupes" [editable]="adminEditable">Groupes (lecture)</bsac-dd-field>
        </dl>
        <hr>
        <dl class="row mt-3" *ngIf="adminEditable">
          <bsac-flags-field class="col-12" [model]="tarifType">Gestion des flags</bsac-flags-field>
        </dl>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Paramètres</div>
      <div class="card-body">
        <dl class="row" *ngIf="tarifType">
          <bsac-dd-field class="col-12" [model]="tarifType" field="base_interval" type="auto" [editable]="adminEditable">Intervalle de base</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="decondi_status" type="auto" [editable]="adminEditable">Déconditionnement</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="decondi_kg" type="auto" *ngIf="tarifType.decondi_status=='CTR'" [editable]="adminEditable">Tarif décondi kg</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="tarifType" field="decondi_pce" type="auto" *ngIf="tarifType.decondi_status=='CTR'" [editable]="adminEditable">Tarif décondi pce</bsac-dd-field>
          <div class="col-12">
            <form [formGroup]="editorForm" (submit)="saveText()" *ngIf="editorForm">
              <div class="form-group">
                <label><b>Message du modèle de tarif</b></label>
                <quill-editor class="d-block" formControlName="text" [modules]="quillModules" [readOnly]="!adminEditable"></quill-editor>
              </div>
              <button class="btn btn-sm btn-block w-100 btn-primary" *ngIf="adminEditable"><i class="fa fa-save me-2"></i>Enregistrer...</button>
            </form>
          </div>
          <div class="col-12 mt-3">
            <form [formGroup]="editorForm" (submit)="saveContractText()" *ngIf="editorForm">
              <div class="form-group">
                <label><b>Conditions de vente / contrat</b></label>
                <quill-editor class="d-block" formControlName="contract" [modules]="quillModules" [readOnly]="!adminEditable"></quill-editor>
              </div>
              <button class="btn btn-sm btn-block w-100 btn-primary" *ngIf="adminEditable"><i class="fa fa-save me-2"></i>Enregistrer...</button>
            </form>
          </div>
        </dl>
      </div>
    </div>
  </div>
</div>
<div class="row g-3 mt-3">
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Récurrence</div>
      <div class="card-body">
        <h4>Date de validité</h4>
        <lvadg-rrule-editor (save)="onRRuleSave($event)" [rule]="tarifType.rrule" [editable]="adminEditable"></lvadg-rrule-editor>
        <h4>Dates de contrôle</h4>
        <p class="text-info">En heures avant le début de la date de validité du tarif</p>
        <dl class="row">
          <div class="col">
            <bsac-dd-field [model]="tarifType" field="delta_prepared" type="auto" [editable]="adminEditable">Limite de préparation</bsac-dd-field>
          </div>
          <div class="col">
            <bsac-dd-field [model]="tarifType" field="delta_validated" type="auto" [editable]="adminEditable">Limite de validation</bsac-dd-field>
          </div>
          <div class="col">
            <bsac-dd-field [model]="tarifType" field="delta_published" type="auto" [editable]="adminEditable">Limite de publication</bsac-dd-field>
          </div>
        </dl>
      </div>
    </div>
  </div>
  <div class="col" *ngIf="tarifType.type=='CLI'">
    <div class="card h-100">
      <div class="card-header">Client / dépôts / contacts</div>
      <div class="card-body">
        <h4>Client / dépôts</h4>
        <dl class="row">
          <bsac-dd-field class="col-6" [model]="tarifType" field="client" type="auto" [related]="clients$" desctpl="&lt;% if(obj.parent_details) { %&gt;&lt;%= obj.parent_details.name %&gt; / &lt;%= obj.name %&gt; &lt;% } else { %&gt;&lt;%= obj.name %&gt; &lt;% } %&gt;" relatedLabel="Sélectionnez un client" [editable]="adminEditable">Client</bsac-dd-field>
          <div class="col-6">
            <bsac-dd-field [model]="tarifType" field="storages_mode" type="auto" [editable]="adminEditable">Détails par dépôt</bsac-dd-field>
            <bsac-dd-field [model]="tarifType" field="storages" type="auto" [related]="storages$" descfield="name" relatedLabel="Sélectionnez un dépôt" [editable]="adminEditable">Dépôts</bsac-dd-field>
          </div>
          <hr>
        </dl>
        <button class="btn btn-outline-primary btn-sm float-end" (click)="addContact=!addContact">Ajouter un contact</button>
        <h4>Contacts</h4>
        <lvadg-contact-manage-form *ngIf="addContact" [tarifType]="tarifType" type="TR" (created)="contactAdded()" [searchUser]="adminEditable"></lvadg-contact-manage-form>
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <th>Titre</th>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Email</th>
              <th>Utilisateur lvadg</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of tarifType.contacts_details">
              <td>{{c.title}}</td>
              <td>{{c.lastname}}</td>
              <td>{{c.firstname}}</td>
              <td>{{c.email}}</td>
              <td>{{c.user_details?.email}}</td>
              <td>
                <lvadg-confirm-delete type="trash" (confirm)="removeContact(c)"></lvadg-confirm-delete>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col" *ngIf="tarifType.type=='GEN'">
    <div class="card h-100">
      <div class="card-header">Dépots</div>
      <div class="card-body">
        <dl class="row">
          <bsac-dd-field class="col-6" [model]="tarifType" field="storages" type="auto" [related]="storages$" descfield="name" relatedLabel="Sélectionnez un dépôt">Dépôts</bsac-dd-field>
        </dl>
      </div>
    </div>
  </div>
</div>
<div class="row g-3 mt-3">
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Relations entrantes</div>
      <div class="card-body">
        <lvadg-tariftyperelation-listmanage [dest]="tarifType" [tariftype_detail_route]="tariftype_detail_route" [editable]="adminEditable"></lvadg-tariftyperelation-listmanage>
      </div>
    </div>
    <div class="card h-100">
      <div class="card-header">Relations sortantes</div>
      <div class="card-body">
        <lvadg-tariftyperelation-listmanage [src]="tarifType" [tariftype_detail_route]="tariftype_detail_route" [editable]="adminEditable"></lvadg-tariftyperelation-listmanage>
      </div>
    </div>
  </div>
</div>