/**
 * Site : model & service providers
 * @changed 2018-01-05-05:22:53
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>

 * @date 2018-04-29-16:28:20
 * @copyright (c) 2017 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";

export class Site extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSSlugField({
    required: true,
    name: "domain",
    helpText: "",
    verboseName: "Domaine",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public domain!: string;

  @DSCharField({
    required: true,
    name: "title",
    helpText: "",
    verboseName: "Titre",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public title!: string;

  @DSCharField({
    required: true,
    name: "type",
    helpText: "",
    verboseName: "Type",
    choices: [
      ["member", "Site adhérent"],
      ["client", "Site client"],
      ["resto", "Site restaurant"],
      ["prov", "Site fournisseur"]
    ],
    default: null,
    maxLength: 10,
  })
  public type!: string;

  @DSForeignKeyField({
    required: false,
    name: "logo",
    helpText: "Logo (f&l)",
    verboseName: "Logo site f&l",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "logosite",
  })
  public logo!: number;

  @DSForeignKeyField({
    required: false,
    name: "sealogo",
    helpText: "Logo (marée)",
    verboseName: "Logo site marée",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "logoseasite",
  })
  public sealogo!: number;

  @DSForeignKeyField({
    required: false,
    name: "header",
    helpText: "Header (fl)",
    verboseName: "Header site fl",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "headersite",
  })
  public header!: number;

  @DSIntegerField({
    required: false,
    name: "header_alpha",
    helpText: "Header alpha (fl)",
    verboseName: "Header alpha site fl",
    choices: [],
    default: 50,
    min: 0,
    max: 100
  })
  public header_alpha!: number;

  @DSForeignKeyField({
    required: false,
    name: "seaheader",
    helpText: "Header (marée)",
    verboseName: "Header site marée",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "headerseasite",
  })
  public seaheader!: number;

  @DSIntegerField({
    required: false,
    name: "seaheader_alpha",
    helpText: "Header alpha (marée)",
    verboseName: "Header alpha (marée)",
    choices: [],
    default: 50,
    min: 0,
    max: 100
  })
  public seaheader_alpha!: number;

  @DSForeignKeyField({
    required: false,
    name: "member",
    helpText: "",
    verboseName: "Adhérent",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Member",
    remoteField: "site",
  })
  public member?: number;

  @DSForeignKeyField({
    required: false,
    name: "client",
    helpText: "",
    verboseName: "Client",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Client",
    remoteField: "site",
  })
  public client?: number;

  @DSForeignKeyField({
    required: false,
    name: "resto",
    helpText: "",
    verboseName: "Restaurant",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Resto",
    remoteField: "site",
  })
  public resto?: number;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "En ligne"],
      ["AD", "En ligne - admin. uniquement"],
      ["SU", "En ligne - superadmin. uniquement"],
      ["KO", "Hors ligne"],
      ["DEL", "Supprimé"]
    ],
    default: null,
    maxLength: 4,
  })
  public status!: string;

  @DSManyToManyField({
    required: false,
    name: "images",
    helpText: "",
    verboseName: "Images",
    choices: [],
    default: [],
  })
  public images!: number[];

  @DSManyToManyField({
    required: false,
    name: "admin",
    helpText: "",
    verboseName: "Admins IDs",
    choices: [],
    default: [],
  })
  public admin!: number[];

  @DSManyToManyField({
    required: false,
    name: "user",
    helpText: "",
    verboseName: "Users IDs",
    choices: [],
    default: [],
  })
  public user!: number[];

  @DSDefaultField({
    required: false,
    verboseName: "Flags",
    name: "flags"
  })
  public flags!: string[];

  public admin_details!: any;
  public logo_img!: any;
  public sealogo_img!: any;
  public header_img!: any;
  public seaheader_img!: any;
  public client_details!: any;
  public resto_details!: any;
  public member_details!: any;
  public user_details!: any;


  public isAdminUser(userid: number): boolean {
    return this.admin.indexOf(userid) !== -1;
  }

  public isBasicUser(userid: number): boolean {
    return this.user.indexOf(userid) !== -1;
  }
  public isUser(userid: number): boolean {
    return this.admin.indexOf(userid) !== -1 || this.user.indexOf(userid) !== -1;
  }

}

@Injectable({providedIn: "root"})
export class SiteService extends DSRestCollection<Site> {
  public adapter_config = {basePath: "/api/v2/sites"};
  public model = Site;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
