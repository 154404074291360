import {Component, Input} from "@angular/core";
import {Provider} from "../../../../lib/models/structure/provider/provider.service";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonBaseComponent} from "../../common-base.component";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {VIEW_DISPLAY} from "../../../view-constants";

export interface CatalogArticleListViewV3Params extends BaseRouteV3Params {
  provider_detail_route: RouteConfigItemV3;
  article_detail_route: RouteConfigItemV3;
  display: VIEW_DISPLAY;
}

export interface CatalogArticleListViewV3Data extends CatalogArticleListViewV3Params {
  provider?: Provider;
}


@Component({
  selector: "lvadg-catalog-article-list-v3-view",
  templateUrl: "./catalog-article-list-view.component.pug",
  styleUrls: ["./catalog-article-list-view.component.sass"]
})
export class CatalogArticleListViewV3Component extends CommonBaseComponent {
  @Input() public provider!: Provider;

  public provider_detail_route!: RouteV3<Provider>;
  public article_detail_route!: RouteV3<Article>;

  public create!: any;
  public default_fields: string[] = [];
  public allowed_fields: string[] = [];
  public excluded_fields: string[] = [];
  public default_filters: string[] = ["search", "infamily"];
  public allowed_filters: string[] = [];
  public excluded_filters: string[] = [];

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }


  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogArticleListViewV3Data;
    this.provider = data.provider || this.provider;
    this.provider_detail_route = new RouteV3<Provider>(data.provider_detail_route, this);
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    return super.preNgOnInit();
  }
}
