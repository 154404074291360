import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {SendDestination} from "../senddestination/senddestination.service";
import {SenderJob} from "../senderjob.service";
import {Offer} from "../../../offer/offer/offer.service";
import {ModelSend} from "../modelsend.service";


export class SendDestinationJobStatus extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSForeignKeyField({
    name: "destination",
    verboseName: "Destination",
    required: false,
    helpText: "ID Destination",
    relatedModel: "mailer.SendDestination",
    remoteField: "id",
    relatedName: "jobs"
  })
  public destination!: number;
  public destination_details!: SendDestination;

  @DSForeignKeyField({
    name: "senderjob",
    verboseName: "ID Tache d'envoi",
    required: false,
    helpText: "ID Tâche d'envoi",
    relatedModel: "mailer.SenderJob",
    remoteField: "id",
    relatedName: "dest_statuses"
  })
  public senderjob!: number;
  public senderjob_details!: SenderJob;

  @DSDefaultField({
    name: "offer_details",
    verboseName: "Détails offre",
    required: false,
    helpText: "Détails offre",
  })
  public offer_details!: Offer;

  @DSDefaultField({
    name: "model_details",
    verboseName: "Détails envoi",
    required: false,
    helpText: "Détails envoi  ",
  })
  public model_details!: ModelSend;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["presend", "Envoi en préparation"],
      ["stored", "Accepté pour envoi"],
      ["accepted", "Dans la queue d'envoi"],
      ["sending", "En cours d'envoi"],
      ["rejected", "Envoi non accepté"],
      ["delivered", "Délivré"],
      ["failedtemporary", "Echec temporaire de distribution"],
      ["failedpermanent", "Echec définitif de distribution"],
      ["opened", "Ouvert"],
      ["clicked", "Cliqué"]
    ],
    maxLength: 20
  })
  public status!: string;


  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class SendDestinationJobStatusService extends DSRestCollection<SendDestinationJobStatus> {
  public adapter_config = {basePath: "/api/v2/senddestinationjobstatus"};
  public model = SendDestinationJobStatus;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
