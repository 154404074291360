import {NgModule} from "@angular/core";
import {TarifrelationListmanageComponent} from "./tarifrelation-listmanage/tarifrelation-listmanage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  declarations: [
    TarifrelationListmanageComponent
  ],
  exports: [
    TarifrelationListmanageComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
  ]
})
export class TarifRelationModule {
}
