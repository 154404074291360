import {Injectable} from "@angular/core";
import {DSDefaultField, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProductFamily} from "../_productfamily/productfamily-base.model";
import {SeaProducer} from "../../structure/seaproducer/seaproducer.service";
import {SeaLabel} from "../sealabel/sealabel.service";

export class SeaProductFamily extends BaseProductFamily {
  @DSDefaultField({
    required: false,
    name: "producers_details",
    helpText: "Détails producteurs",
    verboseName: "Détails producteurs"
  })
  public producers_details!: SeaProducer[];

  @DSDefaultField({
    required: false,
    name: "Liste labels",
    helpText: "Liste des labels",
    verboseName: "Liste des labels"
  })
  public labels_details!: SeaLabel[];

}

@Injectable({
  providedIn: "root"
})
export class SeaProductFamilyService extends DSRestCollection<SeaProductFamily> {
  public adapter_config = {basePath: "/api/v2/seaproductfamilies"};
  public model = SeaProductFamily;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
