
<ng-container *ngIf="fd">
  <div class="row g-3">
    <div class="col">
      <div class="card h-100">
        <div class="card-header"><i class="fa fa-info-circle me-2"></i>Infos & données textuelles</div>
        <div class="card-body">
          <dl class="row">
            <bsac-dd-field class="col-4" [model]="fd" field="id" type="auto" [editable]="false">ID</bsac-dd-field>
            <bsac-dd-field class="col-4" [model]="fd" field="type" type="auto" [editable]="true">Type</bsac-dd-field>
            <bsac-dd-field class="col-4" [model]="fd" field="status" type="auto" [editable]="true">Etat</bsac-dd-field>
            <bsac-dd-field class="col-6" [model]="fd" field="created" type="auto" [editable]="false">Création</bsac-dd-field>
            <bsac-dd-field class="col-4" [model]="fd" field="updated" type="auto" [editable]="false">Mise à jour</bsac-dd-field>
            <bsac-dd-field class="col-12" [model]="fd" field="title" type="auto" [editable]="true">Titre</bsac-dd-field>
            <div class="col-12">
              <form [formGroup]="editorForm" (submit)="saveText()">
                <div class="form-group">
                  <label>Texte</label>
                  <quill-editor class="d-block" formControlName="text" [modules]="quillModules"></quill-editor>
                </div>
                <button class="btn btn-sm btn-block w-100 btn-primary"><i class="fa fa-save me-2"></i>Enregistrer...</button>
              </form>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-header"><i class="fa fa-link me-2"></i>Liaisons et conditions</div>
        <div class="card-body">
          <dl class="row">
            <bsac-dd-field class="col-12" [model]="fd" field="families" type="auto" [related]="families" descfield="cfullname" [relatedFilter]="{fields: 'id,cfullname'}" relatedLabel="Ajouter une famille" (changed)="updatedFamily($event)">Familles</bsac-dd-field>
            <bsac-dd-field class="col-8" [model]="fd" field="egalims" type="auto" [related]="egalims" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;" relatedLabel="Ajouter un label/certif (par code)" (changed)="updated()">Labels & certifications</bsac-dd-field>
            <bsac-dd-field class="col-4" [model]="fd" field="allegalims" type="auto">Tout label/certif.</bsac-dd-field>
            <bsac-dd-field class="col-8" [model]="fd" field="origins" type="auto" [related]="origins" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;" relatedLabel="Ajouter une origine (par code)" (changed)="updated()">Origines</bsac-dd-field>
            <bsac-dd-field class="col-4" [model]="fd" field="allorigins" type="auto">Toute origine</bsac-dd-field>
            <bsac-dd-field class="col-8" [model]="fd" field="categories" type="auto" [related]="categories" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;" relatedLabel="Ajouter une catégorie (par code)" (changed)="updated()">Catégories</bsac-dd-field>
            <bsac-dd-field class="col-4" [model]="fd" field="allcategories" type="auto">Toute catégorie</bsac-dd-field>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card h-100">
        <div class="card-header"><i class="fa fa-eye me-2"></i>Prévisualisation article</div>
        <div class="card-body">
          <bsac-select-related [source]="articles" descfield="libelle" (selected)="articleSelected($event)" label="Sélectionnez un article" [filter]="articleFilter"></bsac-select-related>
          <div class="row" *ngIf="article &amp;&amp; articleFichesData">
            <div class="col-4" *ngFor="let f of articleFichesData">
              <div class="card m-3">
                <div class="card-header" style="font-size: 1rem">{{f.title}} [{{f.id}} {{f.type}}]</div>
                <div class="card-body">
                  <div [innerHTML]="safe(f.text)"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>