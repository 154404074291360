import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {Relation, RelationService} from "../relation.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {MemberService} from "../../member/member.service";
import {StorageService} from "../../storage/storage.service";
import {ClientService} from "../../client/client.service";
import {RestoService} from "../../resto/resto.service";
import {ProducerService} from "../../producer/producer.service";
import {SeaProducerService} from "../../seaproducer/seaproducer.service";
import {ProviderService} from "../../provider/provider.service";

@Component({
  selector: "lvadg-relation-create",
  templateUrl: "./relation-create.component.pug",
  styleUrls: ["./relation-create.component.sass"]
})
export class RelationCreateComponent implements OnInit {
  public relation!: Relation;
  public creation_fields: string[] = [];

  public form!: UntypedFormGroup;

  @Output() public created: EventEmitter<Relation> = new EventEmitter<Relation>();
  @Output() public cancelled: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _relations: RelationService,
              public members$: MemberService,
              public storages$: StorageService,
              public clients$: ClientService,
              public restos$: RestoService,
              public producer$: ProducerService,
              public seaproducer$: SeaProducerService,
              public provider$: ProviderService,
              private _fb: UntypedFormBuilder) {
  }

  public ngOnInit(): void {
    this._relations.create().subscribe((model) => {
      this.relation = model;
      this.form = this._fb.group({});
    });
  }


  public create() {
    if (this.form.valid) {
      const data = this.form.value;
      const cleaned: any = {type: data.type, status: "OK"};
      if (data.type === "MC") {
        cleaned.member = data.member;
        cleaned.client = data.client;
      } else if (data.type === "MR") {
        cleaned.member = data.member;
        cleaned.resto = data.resto;
      } else if (data.type === "SC") {
        cleaned.storage = data.storage;
        cleaned.client = data.client;
      } else if (data.type === "SR") {
        cleaned.storage = data.storage;
        cleaned.resto = data.resto;
      } else if (data.type === "WP") {
        cleaned.provider = data.provider;
        cleaned.producer = data.producer;
      } else if (data.type === "WQ") {
        cleaned.provider = data.provider;
        cleaned.seaproducer = data.seaproducer;
      } else if (data.type === "PS") {
        cleaned.storage = data.storage;
        cleaned.producer = data.producer;
      } else if (data.type === "PQ") {
        cleaned.storage = data.storage;
        cleaned.seaproducer = data.seaproducer;
      }
      for (const k of Object.keys(cleaned)) {
        if (!cleaned[k]) {
          return;
        }
      }
      this._relations.create(cleaned, {save: true})
        .subscribe((saved) => {
          this.created.emit(saved);
        });
    }
  }

  public cancel() {
    this.cancelled.emit(true);
  }
}
