
<ng-template #publish>
  <h4>{{tarif.name}}</h4>
  <ng-container *ngIf="notifications.length">
    <table class="table table-sm table-striped mb-3">
      <thead>
        <tr>
          <th>Date</th>
          <th>Titre</th>
          <th>Prio</th>
          <th>Etat</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let not of notifications">
          <td>{{not.created|date:'short'}}</td>
          <td>{{not.title}}</td>
          <td>{{not.priority|choice:not:'priority'}}</td>
          <td>{{not.status|choice:not:'status'}}</td>
          <td>
            <lvadg-confirm-delete [model]="not" type="trash" *ngIf="not.status=='PEN'" (deleted)="deletedNotif(not)"></lvadg-confirm-delete>
          </td>
        </tr>
      </tbody>
    </table>
    <hr>
  </ng-container>
  <lvadg-tarifnotification-create [tarif]="tarif" (created)="onNotificationCreated($event)" (cancelled)="cancelled.emit()"></lvadg-tarifnotification-create>
</ng-template>
<ng-container *ngIf="modal">
  <div class="modal-header">
    <h4 class="modal-title">Publier un tarif</h4>
    <button class="btn-close" (click)="cancelled.emit()"></button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="publish"></ng-container>
  </div>
</ng-container>