import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {SeaProductFamilySelectComponent} from "./seaproductfamily-select/seaproductfamily-select.component";

@NgModule({
  imports: [CommonModule, BsacFormsModule],
  declarations: [SeaProductFamilySelectComponent],
  exports: [SeaProductFamilySelectComponent]
})
export class SeaProductFamilySelectModule {}
