import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {SettingsValue, SettingsValueService} from "./settingsvalue.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class SettingsValueResolve implements Resolve<SettingsValue> {
  constructor(private _bs: SettingsValueService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<SettingsValue> {
    return this._bs.get(+route.params["settingsvalueId"]);
  }
}
