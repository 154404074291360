import {NgModule} from "@angular/core";

import {MODELLIST_IMPORTS} from "../../../../../includes/components/modellist.imports";
import {ModelSendDestinationsComponent} from "./modelsend-destinations/modelsend-destinations.component";
import {ModelSendContactManageComponent} from "./modelsend-contact-manage/modelsend-contact-manage.component";
import {ContactModule} from "../../../people/contact/contact.module";

@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    ContactModule
  ],
  declarations: [

    ModelSendDestinationsComponent,
    ModelSendContactManageComponent,
  ],
  exports: [

    ModelSendContactManageComponent,
    ModelSendDestinationsComponent
  ],

})
export class ModelSendModule {
}
