import {Component, Input, OnInit} from "@angular/core";
import {Label, LabelService} from "../../../product/label/label.service";
import {MIMEICONS} from "../../document/document-icon/document-icon.component";
import {Provider} from "../../../structure/provider/provider.service";
import {DetailDocument, DetailDocumentService} from "../basedocument.service";
import {SeaLabel, SeaLabelService} from "../../../product/sealabel/sealabel.service";
import {SLabel, SLabelService} from "../../../structure/slabel/slabel.service";
import {Producer} from "../../../structure/producer/producer.service";
import {SeaProducer} from "../../../structure/seaproducer/seaproducer.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-detaildocument-display",
  templateUrl: "./detaildocument-display.component.pug",
  styleUrls: ["./detaildocument-display.component.sass"]
})
export class DetailDocumentDisplayComponent implements OnInit {
  @Input() public provider!: Provider;
  @Input() public producer!: Producer;
  @Input() public seaproducer!: SeaProducer;
  @Input() public document!: DetailDocument;
  @Input() public mode: "preview" | "label" | "previewlabel" = "previewlabel";

  public doc!: DetailDocument;
  public label!: Label;
  public stlabel!: SLabel;
  public sealabel!: SeaLabel;

  constructor(public _labels: LabelService,
              public _sealabels: SeaLabelService,
              public _stlabels: SLabelService,
              private _prd: DetailDocumentService) {
  }

  public async ngOnInit(): Promise<void> {
    if (this.document && this.document.prlabel) {
      this._labels.getCached(this.document.prlabel).subscribe((label) => this.label = label);
    }
    if (this.document && this.document.seaprlabel) {
      this._sealabels.getCached(this.document.seaprlabel).subscribe((label) => this.sealabel = label);
    }
    if (this.document && this.document.stlabel) {
      this._stlabels.getCached(this.document.stlabel).subscribe((label) => this.stlabel = label);
    }
    if (!this.document.download) {
      this.doc = await firstValueFrom(this._prd.create(this.document, {save: false}));
    } else {
      this.doc = this.document;
    }

  }

  public getFileType(): string {
    if (MIMEICONS[this.doc.mimetype]) {
      return MIMEICONS[this.doc.mimetype];
    }
    return "fa-file";
  }

  public download(doc: DetailDocument): void {
    doc.download();
  }
}
