import {Component, Input, OnInit} from "@angular/core";
import {IDSModel} from "@solidev/ngdataservice";
import {CustomDataKey, CustomDataKeyService} from "../../customdatakey/customdatakey.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-customdata-manage",
  templateUrl: "./customdata-manage.component.pug",
  styleUrls: ["./customdata-manage.component.sass"]
})
export class CustomDataManageComponent implements OnInit {

  @Input() public model!: IDSModel;
  @Input() public dest!: string;
  @Input() public categ!: string;
  @Input() public alt!: string;
  @Input() public write!: string;

  public categs: CustomDataKey[] = [];
  public keys!: CustomDataKey[];
  public edit: { [index: number]: boolean } = {};
  public values: { [index: number]: any } = {};
  public altvalues: { [index: number]: any } = {};
  public readonly: { [index: number]: boolean } = {};
  public found: { [index: number]: boolean } = {};

  constructor(private _customdatakeys: CustomDataKeyService,
              private _msgs: BsacMessageService) {
  }

  public async ngOnInit(): Promise<any> {
    this.keys = await firstValueFrom(this._customdatakeys.getByDest(this.dest));
    this.categs = [];
    for (const k of this.keys) {
      const kv = k.values(this.model, this.alt, this.write);
      this.values[k.id] = kv.value;
      this.altvalues[k.id] = kv.altvalue;
      this.found[k.id] = kv.found;
      this.readonly[k.id] = kv.readonly;

      if (k.level === 0 && k.type === "CAT") {
        this.categs.push(k);
      }
    }
  }

  public async save(key: CustomDataKey, value: any, unset: boolean = false): Promise<any> {
    if (key.type === "DOC") {
      value = value ? value.id : null;
    } else if (key.type === "FOL") {
      value = value ? value.id : null;
    }
    await firstValueFrom(this.model.action("update_customdata", {
      method: "POST", body: {
        key: key.id,
        value: value,
        unset: unset
      }
    }));
    await firstValueFrom(this.model.refresh());
    const kv = key.values(this.model, this.alt, this.write);
    this.values[key.id] = kv.value;
    this.altvalues[key.id] = kv.altvalue;
    this.found[key.id] = kv.found;
    this.readonly[key.id] = kv.readonly;
    this.edit[key.id] = false;
    this._msgs.success("Valeur modifiée avec succès");
  }
}
