
<ng-template let-modal #addmarker>
  <div class="modal-header">
    <h4 class="modal-title">Ajouter un marqueur</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <h4>Marqueurs externes (fichier xlsx)</h4>
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let f of xlsxCustomFiles"><b class="me-2">{{LAYER_TYPES[f.type]}}</b><span>{{f.file.name}}</span><i class="fa fa-eye pointer" *ngIf="!f.enabled" (click)="toggleXlsxMarkers(f)"></i><i class="fa fa-eye-slash pointer" *ngIf="f.enabled" (click)="toggleXlsxMarkers(f)"></i></li>
    </ul>
    <hr *ngIf="xlsxCustomFiles &amp;&amp; xlsxCustomFiles.length">
    <button class="btn btn-primary" (click)="addXlsxFile=true"><i class="fa fa-file-excel me-2"></i>Ajouter et géocoder un fichier excel</button>
    <lvadg-upload-geocode *ngIf="addXlsxFile" (data)="onCustomPointerFile($event)" [types]="LAYER_TYPES"></lvadg-upload-geocode>
    <hr>
    <h4>Marqueurs manuel</h4>
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let m of $any(customMarkers.data).features"><b class="me-2">{{LAYER_TYPES[m.properties.type]}}</b><span>{{m.properties.name}} {{m.properties.address}}</span><i class="fa fa-trash text-danger" (click)="removeMarker(m)"></i></li>
    </ul>
    <h5>Ajouter un marqueur à une adresse</h5>
    <form class="mt-4" [formGroup]="customMarkerForm" (submit)="addMarker()">
      <div class="row me-2">
        <div class="col-sm-3">
          <div class="form-group">
            <label>Type</label>
            <select class="form-select" formControlName="type">
              <option value="vivalya">Vivalya</option>
              <option value="producers">Producteur</option>
              <option value="seaproducers">Producteur marée</option>
              <option value="storages">Dépôt</option>
              <option value="seastorages">Dépôt marée</option>
              <option value="members">Adhérent</option>
              <option value="restos">Restaurant</option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label>Nom à afficher</label>
            <input class="form-control" formControlName="name">
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>Adresse complète</label>
            <input class="form-control" formControlName="address">
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label></label>
            <button class="btn btn-primary mt-3" [disabled]="!customMarkerForm.valid"><i class="fa fa-plus me-2"></i>Ajouter</button>
          </div>
        </div>
      </div>
    </form>
    <hr>
    <h5>Ajouter un marqueur prédéfini</h5>
    <div class="row">
      <div class="col-sm-6">
        <lvadg-storage-select (selected)="addStructureCustomMarker('storages', $event)" label="Ajouter un dépôt" [filter]="{gtype: 'FL'}"></lvadg-storage-select>
      </div>
      <div class="col-sm-6">
        <lvadg-storage-select (selected)="addStructureCustomMarker('seastorages', $event)" label="Ajouter un dépôt marée" [filter]="{gtype: 'MR'}"></lvadg-storage-select>
      </div>
      <div class="col-sm-6">
        <lvadg-producer-select (selected)="addStructureCustomMarker('producers', $event)" label="Ajouter un producteur FL"></lvadg-producer-select>
      </div>
      <div class="col-sm-6">
        <lvadg-seaproducer-select (selected)="addStructureCustomMarker('seaproducers', $event)" label="Ajouter un producteur marée"></lvadg-seaproducer-select>
      </div>
      <div class="col-sm-6">
        <lvadg-resto-select (selected)="addStructureCustomMarker('restos', $event)" label="Ajouter un restaurant"></lvadg-resto-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="modal.close('save')">Fermer</button>
  </div>
</ng-template>
<ng-template let-modal #addcircle>
  <div class="modal-header">
    <h4 class="modal-title">Ajouter un cercle</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let m of $any(customCircles.data).features"><span>{{m.properties.diameter}} km</span><span>{{m.properties.address}}</span><i class="fa fa-trash text-danger" (click)="removeCircle(m)"></i></li>
    </ul>
    <form class="mt-2" [formGroup]="customCircleForm" (submit)="addCircle()">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label>Rayon (en km)</label>
            <input class="form-control" type="number" formControlName="circleRadius">
          </div>
        </div>
        <div class="col-sm-9">
          <div class="form-group">
            <label>Adresse</label>
            <input class="form-control" formControlName="address">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Couleur de fond</label>
            <input class="form-control" type="color" formControlName="fillColor">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Opacité du fond (0 = transparent, 100 = opaque)</label>
            <input class="form-control" type="number" formControlName="fillOpacity">
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>Couleur de contour</label>
            <input class="form-control" type="color" formControlName="strokeColor">
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>Largeur du contour</label>
            <input class="form-control" type="number" formControlName="strokeWeight">
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>Opacité du contour (0 = transparent, 100 = opaque)</label>
            <input class="form-control" type="number" formControlName="strokeOpacity">
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label></label>
            <button class="btn btn-primary mt-3" [disabled]="!customCircleForm.valid"><i class="fa fa-plus me-2"></i>Ajouter</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="modal.close('save')">Fermer</button>
  </div>
</ng-template>
<ng-template let-modal #setdisplay>
  <div class="modal-header">
    <h4 class="modal-title">Afficher / masquer des pointeurs</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-info">Cliquer pour afficher / masquer les pointeurs</p>
    <ul class="list-group mt-3">
      <ng-container *ngFor="let layer of DATA_LAYERS">
        <li class="list-group-item list-group-item-action pointer" *ngIf="layerDisplayStatus[layer] !== undefined" (click)="toggleLayer(layer, !layerDisplayStatus[layer])"><span *ngIf="layerDisplayStatus[layer]">{{LAYER_TYPES[layer]}} : affichés</span><span *ngIf="!layerDisplayStatus[layer]">{{LAYER_TYPES[layer]}} : masqués</span></li>
      </ng-container>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="modal.close('close')">Fermer</button>
  </div>
</ng-template>
<ng-template let-modal #setstyle>
  <div class="modal-header">
    <h4 class="modal-title">Modifier le style de base</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-info">Modifier le style de base de la carte</p>
    <ul class="list-group mt-3">
      <ng-container *ngFor="let style of AVAILABLE_STYLES">
        <li class="list-group-item list-group-item-action pointer" (click)="changeStyle(style.url)">{{ style.description }}</li>
      </ng-container>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="modal.close('close')">Fermer</button>
  </div>
</ng-template>
<div id="map" #map [style.height]="height"></div>
<div class="help text-info"><span class="me-3">[ctrl+click] = orientation 3D / [shift+click] = zoom zone</span>
  <div class="d-inline-block" ngbDropdown><span class="actions pointer" ngbDropdownToggle>Actions</span>
    <div ngbDropdownMenu>
      <button ngbDropdownItem (click)="print()"><i class="fa fa-download"></i>Télécharger (france entière)</button>
      <button ngbDropdownItem (click)="print(true)"><i class="fa fa-download"></i>Télécharger (vue courante)</button>
      <button ngbDropdownItem *ngIf="boundary=='auto'" (click)="setBoundary('france')"><i class="fa fa-search me-2"></i>Désactiver zoom auto</button>
      <button ngbDropdownItem *ngIf="boundary!='auto'" (click)="setBoundary('auto')"><i class="fa fa-search me-2"></i>Activer zoom auto</button>
      <button ngbDropdownItem *ngIf="!displayNames" (click)="toggleNames(true)"><i class="fa fa-map-marker-edit me-2"></i>Afficher les textes</button>
      <button ngbDropdownItem *ngIf="displayNames" (click)="toggleNames(false)"><i class="fa fa-map-marker-edit me-2"></i>Masquer les textes</button>
      <button ngbDropdownItem (click)="showModal(addmarker)"><i class="fa fa-map-marker me-2"></i>Ajouter un marqueur</button>
      <button ngbDropdownItem (click)="showModal(addcircle)"><i class="fa fa-circle me-2"></i>Ajouter un cercle</button>
      <button ngbDropdownItem (click)="showModal(setdisplay)"><i class="fa fa-map-marker-check me-2"></i>Afficher/masquer des pointeurs</button>
      <button ngbDropdownItem (click)="showModal(setstyle)"><i class="fa fa-eye me-2"></i>Changer le style</button>
    </div>
  </div>
</div>
<div id="popup-hide">
  <div id="popup" #popup>
    <ng-container *ngIf="info">
      <ng-container *ngIf="info.waiting">
        <div class="text-center m-3"><i class="fa fa-spinner fa-spin fa-3x"></i>
          <div class="mt-2">chargement des informations...</div>
        </div>
      </ng-container>
      <ng-container *ngIf="!info.waiting">
        <div class="picture" *ngIf="info.item &amp;&amp; info.item.logo_img">
          <lvadg-rimg [src]="info.item?.logo_img" display="thumb96" size="sm"></lvadg-rimg>
        </div>
        <div class="name"><b>{{info.item?.name}}</b></div>
        <div class="location mt-1" style="font-size: 0.8rem">{{info.item?.locations_details[0]?.postcode}} {{info.item?.locations_details[0]?.city}}</div>
        <div class="desc mt-1" style="max-width: 250px; font-size: 0.8rem">{{info.item?.content_src | shorten: 50: '...'}}</div>
        <div class="phone mt-1" style="font-size: 0.8rem" *ngIf="info.item?.phone">Tel: {{info.item.phone}}</div>
        <div class="link align-right mt-2"><a [routerLink]="infoGetLink().internal" *ngIf="infoGetLink().internal">détails</a><a [href]="infoGetLink().external" target="_blank" *ngIf="infoGetLink().external">site web</a></div>
      </ng-container>
    </ng-container>
  </div>
</div>