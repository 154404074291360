<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'pcount'; desc:'Nombre de produits (direct)'; group: 'general'">
        <bsac-model-list-value-filter [list]="list" field="pcount" [enabled]="['eq', 'gte', 'lte']"></bsac-model-list-value-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'pccount'; desc:'Nombre de produits (enfants)'; group: 'general'">
        <bsac-model-list-value-filter [list]="list" field="pccount" [enabled]="['eq', 'gte', 'lte']"></bsac-model-list-value-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'level'; desc:'Niveau hiérachique'; group: 'general'">
        <bsac-model-list-value-filter [list]="list" field="level" [enabled]="['eq', 'gte', 'lte']"></bsac-model-list-value-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'with_tarif'; desc: 'Dans un tarif'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="with_tarif" [choices]="[{value: 'own', desc: 'Direct'}, {value:'desc', desc: 'Arborescence'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'stat_grouper'; desc: 'Regroupement statistique'; group: 'stats'">
        <bsac-model-list-select-filter [list]="list" field="stat_grouper" [choices]="statss.STAT_GROUPERS"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'stat_interval'; desc: 'Intervalle à afficher'; group: 'stats'">
        <bsac-model-list-select-filter [list]="list" field="stat_interval" [choices]="statss.STAT_INTERVALS"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'article-egalims'; desc: 'Stats label(s) / certification(s)'; group: 'stats'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="article-egalims" [queryset]="egalims$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez un label" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'article-egalim_status'; desc:'Stats statut Egalim'; group: 'stats'">
        <bsac-model-list-select-filter [list]="list" field="article-egalim_status" [choices]="[{desc:'Non Egalim', value: 'no'}, {desc:'Egalim', value: 'yes'}, {desc:'Equivalence', value: 'eq'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'article-origines'; desc: 'Stats par origine'; group: 'origin'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="article-origines" [queryset]="origines$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une origine" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'article-origine_glob'; desc: 'Stats origine globale'; group: 'stats'">
        <bsac-model-list-select-filter [list]="list" field="article-origine_glob" [choices]="[{value: 'FR', desc: 'France'},{value:'UE', desc: 'Europe'},{value: 'IMP', desc: 'Import'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'article-udf_unit'; desc: 'Stats unité de facturation'; group: 'stats'">
        <bsac-model-list-select-filter [list]="list" field="article-udf_unit" [choices]="[{value: 'K', desc: 'Kilogramme'},{value:'G', desc: 'Gramme'},{value: 'L', desc: 'Litre'},{value: 'P', desc: 'Pièce'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'article-local'; desc: 'Recherche produit local'; group: 'origin'">
        <bsac-model-list-select-filter [list]="list" field="article-local" [choices]="[{value: 'L', desc:'Local'}, {value:'U', desc: 'Ultra local'}, {value: '-', desc: '-'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="pcount">
            <th cdk-header-cell *cdkHeaderCellDef>Nb articles (todo)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.pcount}}</td>
          </ng-container>
          <ng-container cdkColumnDef="pccount">
            <th cdk-header-cell *cdkHeaderCellDef>Nb articles (N+1 - todo)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.pccount}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producers_details">
            <th cdk-header-cell *cdkHeaderCellDef>Producteurs</th>
            <td cdk-cell *cdkCellDef="let row">{{row.producers_details}}</td>
          </ng-container>
          <ng-container cdkColumnDef="labels_details">
            <th cdk-header-cell *cdkHeaderCellDef>Labels</th>
            <td cdk-cell *cdkCellDef="let row">{{row.labels_details}}</td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>Nom & famille</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="lvl1" *ngIf="row.level==0"><a [routerLink]="getDetailLink(row)">{{ row.name }}</a></div>
              <div class="lvl2" *ngIf="row.level==1"><a [routerLink]="getDetailLink(row)">{{row.name}} <br/><small>{{ row.cfullname }}</small></a></div>
              <div class="lvl3" *ngIf="row.level==2"><a [routerLink]="getDetailLink(row)">{{row.name}} <br/><small>{{ row.cfullname }}</small></a></div>
              <div class="lvl4" *ngIf="row.level==3"><a [routerLink]="getDetailLink(row)">{{row.name}} <br/><small>{{ row.cfullname }}</small></a></div>
              <div class="lvl5" *ngIf="row.level==4"><a [routerLink]="getDetailLink(row)">{{row.name}} <br/><small>{{ row.cfullname }}</small></a></div>
              <div class="lvl6" *ngIf="row.level==5"><a [routerLink]="getDetailLink(row)">{{row.name}} <br/><small>{{ row.cfullname }}</small></a></div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="cfullname">
            <th cdk-header-cell *cdkHeaderCellDef>Nom complet</th>
            <td cdk-cell *cdkCellDef="let row">{{row.cfullname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="cname">
            <th cdk-header-cell *cdkHeaderCellDef>Nom</th>
            <td cdk-cell *cdkCellDef="let row">{{row.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="dispname">
            <th cdk-header-cell *cdkHeaderCellDef>Affichage nom ?</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check" *ngIf="row.dispname"></i><i class="fa fa-times" *ngIf="!row.dispname"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="dmode">
            <th cdk-header-cell *cdkHeaderCellDef>Mode d'affichage</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.dmode">{{row.dmode|choice:row:'dmode'}}</ng-container>
              <ng-container *ngIf="!row.dmode">{{row.cdmode|choice:row:'dmode'}} (hérité)</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="content_type">
            <th cdk-header-cell *cdkHeaderCellDef>Type contenu</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_type}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content_src">
            <th cdk-header-cell *cdkHeaderCellDef>Contenu (source)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_src}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content">
            <th cdk-header-cell *cdkHeaderCellDef>Contenu (compilé)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content}}</td>
          </ng-container>
          <ng-container cdkColumnDef="logo">
            <th cdk-header-cell *cdkHeaderCellDef>Logo ?</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check" *ngIf="row.logo"></i><i class="fa fa-times" *ngIf="!row.logo"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="clogo">
            <th cdk-header-cell *cdkHeaderCellDef>Logo ? (hérité)</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check" *ngIf="row.logo"></i><i class="fa fa-times" *ngIf="!row.logo"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="logo_img">
            <th cdk-header-cell *cdkHeaderCellDef>Image logo</th>
            <td cdk-cell *cdkCellDef="let row">{{row.logo_img}}</td>
          </ng-container>
          <ng-container cdkColumnDef="clogo_img">
            <th cdk-header-cell *cdkHeaderCellDef>Image logo (hérité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.clogo_img}}</td>
          </ng-container>
          <ng-container cdkColumnDef="images">
            <th cdk-header-cell *cdkHeaderCellDef>ID Images</th>
            <td cdk-cell *cdkCellDef="let row">{{row.images}}</td>
          </ng-container>
          <ng-container cdkColumnDef="productname">
            <th cdk-header-cell *cdkHeaderCellDef>Nom du produit</th>
            <td cdk-cell *cdkCellDef="let row">{{row.productname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="productdesc">
            <th cdk-header-cell *cdkHeaderCellDef>Description produit</th>
            <td cdk-cell *cdkCellDef="let row">{{row.productdesc}}</td>
          </ng-container>
          <ng-container cdkColumnDef="productseasons">
            <th cdk-header-cell *cdkHeaderCellDef>
              <div class="pointer" (click)="changePerlineSeasons()">Saisons produit<i class="fa fa-line-columns ms-2 text-muted"></i></div>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-seasons-manage [seasons]="row.productseasons" [editable]="checkMode('admin')" (changed)="updateSeasons(row, $event)" [perline]="seasons_perline" [zone]="row.zone"></lvadg-seasons-manage>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="pseasons">
            <th cdk-header-cell *cdkHeaderCellDef>
              <div class="pointer" (click)="changePerlineSeasons()">Stats saisons (famille)<i class="fa fa-line-columns ms-2 text-muted"></i></div>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-seasons-manage [sstats]="row.pseasons" [editable]="false" [perline]="seasons_perline" [zone]="zone"></lvadg-seasons-manage>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="pcseasons">
            <th cdk-header-cell *cdkHeaderCellDef>
              <div class="pointer" (click)="changePerlineSeasons()">Stats saisons (enfant)<i class="fa fa-line-columns ms-2 text-muted"></i></div>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-seasons-manage [sstats]="row.pcseasons" [editable]="false" [perline]="seasons_perline" [zone]="zone"></lvadg-seasons-manage>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="cproductdesc">
            <th cdk-header-cell *cdkHeaderCellDef>Description produit (hérité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.cproductdesc}}</td>
          </ng-container>
          <ng-container cdkColumnDef="cproductseasons">
            <th cdk-header-cell *cdkHeaderCellDef>
              <div class="pointer" (click)="changePerlineSeasons()">Saisons produit (hérité)<i class="fa fa-line-columns ms-2 text-muted"></i></div>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-seasons-manage [seasons]="row.cproductseasons" [editable]="false" [zone]="zone" [perline]="seasons_perline"></lvadg-seasons-manage>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="parent">
            <th cdk-header-cell *cdkHeaderCellDef>Parent ID</th>
            <td cdk-cell *cdkCellDef="let row">{{row.parent}}</td>
          </ng-container>
          <ng-container cdkColumnDef="level">
            <th cdk-header-cell *cdkHeaderCellDef>Niveau</th>
            <td cdk-cell *cdkCellDef="let row">{{row.level}}</td>
          </ng-container>
          <ng-container cdkColumnDef="parents">
            <th cdk-header-cell *cdkHeaderCellDef>Parents</th>
            <td cdk-cell *cdkCellDef="let row">{{row.parents}}</td>
          </ng-container>
          <ng-container cdkColumnDef="storages_details">
            <th cdk-header-cell *cdkHeaderCellDef>Détails dépôts</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="storage" *ngFor="let p of row.storages_details">{{p.name}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="documents">
            <th cdk-header-cell *cdkHeaderCellDef>Tous documents associés</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="pointer" *ngFor="let d of row.documents_details" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.type}} {{d.title}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="doc_FT">
            <th cdk-header-cell *cdkHeaderCellDef>Fiches techniques</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="d.type=='FT'" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.title}}</div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="doc_DL">
            <th cdk-header-cell *cdkHeaderCellDef>Documents structure</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="d.type=='DL'" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.title}}</div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="doc_DE">
            <th cdk-header-cell *cdkHeaderCellDef>Documents label/certification</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="d.type=='DE'" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.title}}</div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="doc_OT">
            <th cdk-header-cell *cdkHeaderCellDef>Autres documents</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="d.type=='OT'" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.title}}</div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="documents_count">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="documents_count">Nb documents</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.documents_count}}</td>
          </ng-container>
          <ng-container cdkColumnDef="tarifs_stats">
            <th class="text-center" cdk-header-cell *cdkHeaderCellDef>Stat globale</th>
            <td class="text-center" cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.tarifs_stats &amp;&amp; row.tarifs_stats.avg"><span>{{row.tarifs_stats.avg|currency:"EUR"}}</span></ng-container>
            </td>
          </ng-container>
          <ng-container *ngFor="let i of stats_intervals">
            <ng-container [cdkColumnDef]="'tarifs_stats_'+i">
              <th class="text-center" cdk-header-cell *cdkHeaderCellDef>{{statss.column(i).name}}</th>
              <td class="text-center" cdk-cell *cdkCellDef="let row" [class.table-danger]="statss.danger(row, i)" [class.table-warning]="statss.warning(row, i)"><span [title]="statss.summary(row, i)" (click)="statss.dispDetail(row, i)" role="button">{{statss.display(row, i)}}</span></td>
            </ng-container>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>