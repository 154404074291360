import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField,
  DSTextField
} from "@solidev/ngdataservice";
import {Label} from "../../product/label/label.service";
import {SeaLabel} from "../../product/sealabel/sealabel.service";
import {Client} from "../../structure/client/client.service";


export class ArticleEgalim extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "vvid",
    verboseName: "ID Vivalya",
    required: false,
    helpText: "ID Vivalya",
    minLength: 0,
    maxLength: 16,
    choices: []
  })
  public vvid!: string;


  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 3,
    choices: [
      ["bio", "Egalim Bio"],
      ["cli", "Egalim client"],
      ["hve", "Egalim HVE"],
      ["rup", "Egalim RUP"],
      ["val", "Egalim Valorisation"],
      ["unk", "Non précisé"]
    ]
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;

  @DSForeignKeyField({
    name: "flabel",
    verboseName: "Lien label F&L",
    required: false,
    helpText: "Lien label F&L",
    relatedModel: "product.Label",
    remoteField: "id",
    relatedName: "articleegalim"
  })
  public flabel!: number;
  public flabel_details!: Label;

  @DSForeignKeyField({
    name: "slabel",
    verboseName: "Lien label marée",
    required: false,
    helpText: "Lien label marée",
    relatedModel: "product.SeaLabel",
    remoteField: "id",
    relatedName: "articleegalim"
  })
  public slabel!: number;
  public slabel_details!: SeaLabel;

  @DSTextField({
    name: "content_src",
    verboseName: "Description (source)",
    required: false,
    helpText: "Description (source)",
    minLength: 0,
    maxLength: 1000,
    choices: []
  })
  public content_src!: string;
  public content!: string;

  @DSCharField({
    name: "url",
    verboseName: "URL de référence",
    required: false,
    helpText: "URL de référence",
    minLength: 0,
    maxLength: 500,
    choices: []
  })
  public url!: string;

  @DSBooleanField({
    name: "egalim",
    verboseName: "Egalim ?",
    required: false,
    helpText: "Egalim ?",
    default: false
  })
  public egalim!: boolean;

  @DSBooleanField({
    name: "egalim_plus",
    verboseName: "Egalim plus ?",
    required: false,
    helpText: "",
    default: false
  })
  public egalim_plus!: boolean;

  @DSManyToManyField({
    name: "egalim_plus_client",
    verboseName: "Egalim plus client",
    required: false,
    relatedModel: "structure.Client",
    remoteField: "articleegalim",
    helpText: "Egalim plus client",
    default: [],
  })
  public egalim_plus_client!: number[];
  public egalim_plus_client_details!: Client[];


}

@Injectable({
  providedIn: "root"
})
export class ArticleEgalimService extends DSRestCollection<ArticleEgalim> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/egalims"};
  public model = ArticleEgalim;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
