
<form *ngIf="relation" [formGroup]="form">
  <div class="row">
    <div class="col-sm-4">
      <bsac-field-control field="type" [model]="relation" [form]="form"><span class="label">Type</span></bsac-field-control>
    </div>
    <div class="col-sm-4" *ngIf="form.value.type &amp;&amp; form.value.type.indexOf('M') !== -1">
      <bsac-field-control field="member" [model]="relation" [form]="form" [related]="members$" descfield="name"><span class="label">Adhérent</span></bsac-field-control>
    </div>
    <div class="col-sm-4" *ngIf="form.value.type &amp;&amp; form.value.type.indexOf('S') !== -1">
      <bsac-field-control field="storage" [model]="relation" [form]="form" [related]="storages$" descfield="name"><span class="label">Dépôt</span></bsac-field-control>
    </div>
    <div class="col-sm-4" *ngIf="form.value.type &amp;&amp; form.value.type.indexOf('C') !== -1">
      <bsac-field-control field="client" [model]="relation" [form]="form" [related]="clients$" descfield="name"><span class="label">Client</span></bsac-field-control>
    </div>
    <div class="col-sm-4" *ngIf="form.value.type &amp;&amp; form.value.type.indexOf('R') !== -1">
      <bsac-field-control field="resto" [model]="relation" [form]="form" [related]="restos$" descfield="name"><span class="label">Restaurant</span></bsac-field-control>
    </div>
    <div class="col-sm-4" *ngIf="form.value.type &amp;&amp; form.value.type.indexOf('P') !== -1">
      <bsac-field-control field="producer" [model]="relation" [form]="form" [related]="producer$" descfield="name"><span class="label">Producteur</span></bsac-field-control>
    </div>
    <div class="col-sm-4" *ngIf="form.value.type &amp;&amp; form.value.type.indexOf('Q') !== -1">
      <bsac-field-control field="seaproducer" [model]="relation" [form]="form" [related]="seaproducer$" descfield="name"><span class="label">Producteur marée</span></bsac-field-control>
    </div>
    <div class="col-sm-4" *ngIf="form.value.type &amp;&amp; form.value.type.indexOf('W') !== -1">
      <bsac-field-control field="provider" [model]="relation" [form]="form" [related]="provider$" descfield="name"><span class="label">Fournisseur référencé</span></bsac-field-control>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <button class="btn btn-primary btn-block w-100" (click)="create()">Submit</button>
    </div>
    <div class="col-4">
      <button class="btn btn-secondary btn-block w-100" (click)="cancel()">Cancel</button>
    </div>
  </div>
</form>