/**
 * Image : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @changed 2018-05-22-09:16:20
 * @status ALPHA
 * @date 2018-01-01-18:55:15
 * @copyright (c) 2017-2018 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";
import {RimgResp} from "../../../comps/rimg/rimg";

export class Image extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date de MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "Affiché"],
      ["KO", "Non affiché"]
    ],
    default: null,
    maxLength: 4,
  })
  public status!: string;

  @DSSlugField({
    required: false,
    name: "slug",
    helpText: "",
    verboseName: "Slug",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public slug!: string;

  @DSCharField({
    required: false,
    name: "title",
    helpText: "",
    verboseName: "Titre",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public title!: string;

  @DSCharField({
    required: false,
    name: "description",
    helpText: "",
    verboseName: "Description",
    choices: [],
    default: null,
    maxLength: 2000,
  })
  public description!: string;

  @DSCharField({
    required: false,
    name: "type",
    helpText: "",
    verboseName: "Type mime",
    choices: [],
    default: null,
    maxLength: 30,
  })
  public type!: string;

  @DSCharField({
    required: false,
    name: "link",
    helpText: "",
    verboseName: "Lien",
    choices: [],
    default: null,
    maxLength: 30,
  })
  public link!: string;

  @DSDefaultField({
    required: false,
    name: "orig",
    helpText: "",
    verboseName: "Image source",
    choices: [],
    default: null,
  })
  public orig!: any;

  @DSIntegerField({
    required: false,
    name: "width",
    helpText: "",
    verboseName: "Largeur (px)",
    choices: [],
    default: null,
  })
  public width!: any;

  @DSIntegerField({
    required: false,
    name: "height",
    helpText: "",
    verboseName: "Hauteur (px)",
    choices: [],
    default: null,
  })
  public height!: any;

  @DSIntegerField({
    required: false,
    name: "order",
    helpText: "",
    verboseName: "Priorité affichage",
    choices: [],
    default: null,
  })
  public order!: any;

  @DSCharField({
    required: false,
    name: "video",
    helpText: "URL complète de la vidéo (vimeo, youtube)",
    verboseName: "URL Vidéo",
    default: null
  })
  public video!: string;

  @DSDefaultField({
    required: false,
    name: "flags",
    helpText: "",
    verboseName: "Flags",
    choices: [],
    default: [],
  })
  public flags!: string[];

  @DSDefaultField({
    required: false,
    name: "responsive",
    helpText: "",
    verboseName: "Responsive images",
    choices: [],
    default: [],
  })
  public responsive!: RimgResp[];
}

@Injectable({providedIn: "root"})
export class ImageService extends DSRestCollection<Image> {
  public adapter_config = {basePath: "/api/v2/images"};
  public model = Image;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
