import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RnmCotation, RnmCotationService} from "./rnmcotation.service";

@Injectable({providedIn: "root"})
export class RnmCotationResolve implements Resolve<RnmCotation> {
  constructor(private _bs: RnmCotationService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<RnmCotation> {
    return this._bs.get(+route.params["rnmCotationId"]);
  }
}
