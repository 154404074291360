import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Tarif, TarifService} from "../tarif.service";
import {HttpClient} from "@angular/common/http";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {LifeCycles} from "../../tariftype/tariftype.service";

@Component({
  selector: "lvadg-tarif-manage",
  templateUrl: "./tarif-manage.component.pug",
  styleUrls: ["./tarif-manage.component.sass"]
})
export class TarifManageComponent implements OnInit {

  @Input() public tarif!: Tarif;
  @Output() public duplicated = new EventEmitter<Tarif>();
  public LifeCycles = LifeCycles;
  public editorForm!: UntypedFormGroup;
  public publishModal!: NgbModalRef;
  public duplicate: boolean = false;

  public quillModules: any = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote", {"script": "sub"}, {"script": "super"}],
      [{"list": "ordered"}, {"list": "bullet"}],
      [{"color": []}, {"background": []}, "clean", "link"]
    ]
  };
  public exportingXlsx: boolean = false;
  public updatingArticles: boolean = false;

  constructor(private _ts: TarifService,
              private _http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private _msgs: BsacMessageService,
              private _modal: NgbModal) {
  }


  public async updateArticles(): Promise<void> {
    this.updatingArticles = true;
    try {
      await firstValueFrom(this.tarif.action("att_update", {method: "POST", body: {}}));
    } catch (e) {
      this._msgs.error("Erreur lors de la mise à jour des articles", "", e);
      this.updatingArticles = false;
      return;
    }
    this._msgs.success("Articles mis à jour avec succès");
    this.updatingArticles = false;
  }


  public ngOnInit(): void {
    this.editorForm = new UntypedFormGroup({text: new UntypedFormControl(this.tarif.message)});
  }

  public async saveText(): Promise<void> {
    this.tarif.message = this.editorForm.value.text;
    await firstValueFrom(this.tarif.update(["message"]));
  }

  public onCreated($event: Tarif) {
    this.duplicate = false;
    this.duplicated.next($event);
  }

  public async deleteTarif(): Promise<void> {
    try {
      await firstValueFrom(this.tarif.delete());
      this._msgs.success("Tarif supprimé avec succès");
      this.router.navigate([".."], {relativeTo: this.route});
    } catch (e) {
      this._msgs.error("Suppression impossible");
    }
  }


  public async openPublish(ref: any): Promise<void> {
    await firstValueFrom(this.tarif.refresh());
    if (this.tarif.documents_details.length === 0) {
      this._msgs.error("Pas de publication sans document");
      return;
    }
    this.publishModal = this._modal.open(ref, {size: "xl"});
  }


  public async closePublish(success: boolean = false): Promise<void> {
    if (success) {
      await firstValueFrom(this.tarif.refresh());
      this._msgs.success("Tarif publié avec succès");
    }
    if (this.publishModal) {
      this.publishModal.close("closed");
    }

  }
}
