import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {Observable, ReplaySubject} from "rxjs";
import {CommField, IFieldParameter, IFieldParameters} from "./commfield.service";
import {ModelComm} from "../modelcomm/modelcomm.service";
import {DSRestCollection} from "@solidev/ngdataservice";

export interface CommParamsDefinition {
  pageQuery: string; // Page query string
  pageParamKey: string; // Page content parameter
}

/**
 * Manage comm field parameter.
 *
 * Load, update, change comm parameters.
 *
 */
@Injectable({
  providedIn: "root"
})
export class CommFieldParamsService {
  public model!: ModelComm;  // Current model comm
  public current!: IFieldParameters; // Current parameters
  public updated = new ReplaySubject<void>(1); // Updated subject
  public pageId?: number; // Page number
  protected _of!: DSRestCollection<CommField>;
  private _params!: CommParamsDefinition; // Comm parameters

  /**
   * Load commmodel parameters definition.
   * @param params Comm Parameters definition
   */
  public setUp(params: CommParamsDefinition) {
    this._params = params;
  }

  /**
   * Load page parameters, calling get_params for current comm.
   * If no pageId is given (or null), query for generic parameters.
   * If given, query for page specific parameters (inheritance from
   * generic to specific is made server-side).
   *
   * @param model load for this comm
   * @param pageId (optionnal) load for this page number
   */
  public async load(model: ModelComm, pageId?: number): Promise<CommFieldParamsService> {
    if (!this._params) {
      throw Error("CommFieldParams not initialized, please use setUp before");
    }
    // Prepare query
    const qry: any = {};
    this.model = model;
    this.pageId = pageId;
    if (pageId !== undefined && pageId !== null) {
      qry[this._params.pageQuery] = pageId;
    }
    // Load current
    this.current = await this.model.action("get_params", {
      method: "GET",
      query: qry
    }).toPromise();

    // Create field detail object
    for (const part of Object.keys(this.current)) {
      for (const name of Object.keys(this.current[part])) {
        if (this.current[part][name].field_details) {
          this.current[part][name].field_details = await this._of.create(this.current[part][name].field_details).toPromise();
        }
      }
    }
    // Emit "updated" signal
    this.updated.next();
    return this;
  }

  /**
   * Trigger manual change (reload) for current page.
   */
  public triggerChange(): void {
    this.load(this.model, this.pageId);
  }

  /**
   * Get parameter by name observable.
   * Used to bind parameter widgets to their value.
   *
   * @param part part name
   * @param name field name
   */
  public getByName(part: string, name: string): Observable<IFieldParameter|undefined> {
    return this.updated.pipe(map(() => {
      if (this.current && this.current[part]) {
        return this.current[part][name];
      }
      return undefined;
    }));
  }

  /**
   * Remove field customisation by name.
   *
   * @param part part name
   * @param name field name
   */
  public async removeByName(part: string, name: string): Promise<void> {
    const cur = this.current[part][name];
    if (cur.field_details && cur.field_details.id) {
      await cur.field_details.delete().toPromise();
      await this.load(this.model, this.pageId);
    }
  }


  /**
   * Set parameter value by name.
   *
   * @param part part name
   * @param name field name
   * @param values field value to set
   */
  public async setByName(part: string, name: string, values: any) {
    const cur = this.current[part][name];
    if (this.pageId) {
      // PAGE ID IS SET --> it's a specific paramter
      if (cur.field_details && cur.field_details.id) {
        // Field content already have an id, we update it
        if (cur.field_details[this._params.pageParamKey] === this.pageId) {
          // We update current parameter key value
          cur.field_details.assign(values);
          await cur.field_details.update(Object.keys(values)).toPromise();
          await this.load(this.model, this.pageId);
        } else {
          // Key parameter don't match, we create it
          const data: any = {};
          Object.assign(data, values);
          data[this._params.pageParamKey] = this.pageId;
          data.model = this.model.id;
          data.name = name;
          data.part = part;
          data.status = "OK";
          cur.field_details = await this._of.create(data, {save: true}).toPromise();
          cur.field = cur.field_details.id;
          await this.load(this.model, this.pageId);
        }
      } else {
        // No record, create it
        const data: any = {};
        Object.assign(data, values);
        data[this._params.pageParamKey] = this.pageId;
        data.model = this.model.id;
        data.name = name;
        data.part = part;
        data.status = "OK";
        cur.field_details = await this._of.create(data, {save: true}).toPromise();
        cur.field = cur.field_details.id;
        await this.load(this.model, this.pageId);
      }

    } else {
      // NO PAGE ID --> we are generic
      if (cur.field_details && cur.field_details.id) {
        // We update current key parameter parameter
        cur.field_details.assign(values);
        await cur.field_details.update(Object.keys(values)).toPromise();
        await this.load(this.model, this.pageId);
      } else {
        // key paramter don't match, we create it
        const data: any = {};
        Object.assign(data, values);
        data.model = this.model.id;
        data.name = name;
        data.part = part;
        data.status = "OK";
        cur.field_details = await this._of.create(data, {save: true}).toPromise();
        cur.field = cur.field_details.id;
        await this.load(this.model, this.pageId);
      }
    }
  }
}
