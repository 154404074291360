import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RimgComponent} from "./rimg/rimg.component";
import {UploadImageComponent} from "./upload-image/upload-image.component";
import {NgxUploaderModule} from "ngx-uploader";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {DistancePipe} from "./pipes/distance.pipe";
import {ConfirmDeleteComponent} from "./confirm-delete/confirm-delete.component";
import {BsacMessageModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {RbgDirective} from "./rimg/rbg.directive";
import {UploadFileComponent} from "./upload-file/upload-file.component";
import {ModellistDisplayComponent} from "./modellist-display/modellist-display.component";
import {DistanceCo2Pipe} from "./pipes/distance-co2.pipe";
import {DateRangepickerComponent} from "./daterangepicker/date-rangepicker.component";
import {ModellistAdminComponent} from "./modellist-admin/modellist-admin.component";
import {ModellistXlsxExportComponent} from "./modellist-xlsx-export/modellist-xlsx-export.component";
import {ChoicePipe} from "./pipes/choice.pipe";
import {DsDecimalPipe} from "./pipes/ds-decimal.pipe";
import {RruleEditorComponent} from "./rrule-editor/rrule-editor.component";
import {ReactiveFormsModule} from "@angular/forms";
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";
import {OplogDisplayComponent} from "./oplog-display/oplog-display.component";
import {DynamicBreadcrumbComponent} from "./dynamic-breadcrumb/dynamic-breadcrumb.component";
import {RouterModule} from "@angular/router";
import {DsCurrencyPipe} from "./pipes/ds-currency.pipe";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxUploaderModule,
    BsacMessageModule,
    BsacModelListModule,
    ReactiveFormsModule,
    NgbModule
  ],
  declarations: [
    RimgComponent,
    UploadImageComponent,
    DistancePipe,
    ConfirmDeleteComponent,
    RbgDirective,
    UploadFileComponent,
    ModellistDisplayComponent,
    DistanceCo2Pipe,
    DateRangepickerComponent,
    ModellistAdminComponent,
    ModellistXlsxExportComponent,
    ChoicePipe,
    DsDecimalPipe,
    DsCurrencyPipe,
    RruleEditorComponent,
    SafeHtmlPipe,
    OplogDisplayComponent,
    DynamicBreadcrumbComponent
  ],
  exports: [
    RimgComponent,
    UploadImageComponent,
    DistancePipe,
    ChoicePipe,
    ConfirmDeleteComponent,
    RbgDirective,
    UploadFileComponent,
    ModellistDisplayComponent,
    DistanceCo2Pipe,
    DateRangepickerComponent,
    ModellistAdminComponent,
    ModellistXlsxExportComponent,
    DsDecimalPipe,
    DsCurrencyPipe,
    RruleEditorComponent,
    SafeHtmlPipe,
    OplogDisplayComponent,
    DynamicBreadcrumbComponent
  ]
})
export class CompsModule {
}
