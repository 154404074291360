/**
 * KVStore : object storage for persons.
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>

 * @date 2018-04-23-22:25:53
 * @copyright (c) 2018 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {IBsacPersistence, IBsacPersistenceService} from "@solidev/bsadmincomponents";
import {Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";

export class KVStore extends DSModel implements IBsacPersistence {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSForeignKeyField({
    required: false,
    name: "person",
    helpText: "",
    verboseName: "person",
    choices: {},
    default: null,
    autoCreated: true,
    relatedModel: "Person",
    remoteField: "person",
    multiple: false,
  })
  public person!: number;


  @DSCharField({
    required: true,
    name: "type",
    helpText: "",
    verboseName: "type",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public type!: string;


  @DSCharField({
    required: true,
    name: "zone",
    helpText: "",
    verboseName: "zone",
    choices: [],
    default: null,
    maxLength: 255,
  })
  public zone!: string;

  @DSCharField({
    required: true,
    name: "key",
    helpText: "",
    verboseName: "key",
    choices: [],
    default: null,
    maxLength: 255,
  })
  public key!: string;


  @DSDefaultField({
    required: false,
    name: "value",
    helpText: "",
    verboseName: "value",
    choices: [],
    default: false,
  })
  public value!: any;

  @DSDefaultField({
    required: false,
    name: "public",
    helpText: "",
    verboseName: "public",
    choices: [],
    default: false,
  })
  public public!: boolean;

}

@Injectable({providedIn: "root"})
export class KVStoreService extends DSRestCollection<KVStore> implements IBsacPersistenceService {
  public adapter_config = {basePath: "/api/v2/kv"};
  public model = KVStore;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }

  public listP(type: string, zone: string): Observable<IBsacPersistence[]> {
    return this.queryset.query({type: type, zone: zone}).get().pipe(map((results) => {
      return results.items;
    }));
  }

  public getP(type: string, zone: string, key: string, pub: boolean): Observable<IBsacPersistence> {
    return this.queryset.query({type: type, zone: zone, key: key, public: pub}).get().pipe(map((results) => {
      return results.items[0];
    }));
  }

  public saveP(type: string, zone: string, key: string, value: any, pub: boolean): Observable<IBsacPersistence> {
    return this.getP(type, zone, key, pub).pipe(switchMap((result) => {
      if (result) {
        result.value = value;
        return (<any>result).save() as Observable<IBsacPersistence>;
      } else {
        return this.create({type: type, zone: zone, key: key, pub: pub, value: value}, {save: true});
      }
    }));
  }

  public removeP(type: string, zone: string, key: string): Observable<any> {
    return this.getP(type, zone, key, false).pipe(switchMap((result) => {
      if (result) {
        return (<any>result).destroy();
      } else {
        return of(true);
      }
    }));
  }
}
