import {Person, PersonService} from "../person.service";
import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {IDSQueryset} from "@solidev/ngdataservice";
import {merge, Observable, Subject} from "rxjs";
import {debounceTime, filter, map, switchMap} from "rxjs/operators";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "lvadg-person-select",
  templateUrl: "./person-select.component.pug",
  styleUrls: ["./person-select.component.sass"]
})
export class PersonSelectComponent {
  public queryset!: IDSQueryset<Person, any, any>;
  @Input() public hint = "Selectionnez une personne";
  @Output() public selected: EventEmitter<Person> = new EventEmitter<Person>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("th", {static: true}) th: NgbTypeahead;
  click$ = new Subject<string>();
  focus$ = new Subject<string>();

  constructor(private _model: PersonService) {
    this.queryset = this._model.queryset;
  }


  public search = (text$: Observable<string>) => {
    const click$ = this.click$.pipe(filter(() => !this.th.isPopupOpen()));
    const focus$ = this.focus$.pipe(filter(() => !this.th.isPopupOpen()));
    return merge(click$, focus$, text$).pipe(
      debounceTime(200),
      switchMap((term) => {
        this.queryset.query({search: term});
        return this.queryset.get().pipe(
          map((result) => {
            return result.items;
          }));
      }));
  };

  public getName(x: any): string {
    return `${x.lastname} ${x.firstname} (${x.email})`;
  }


  public selItem(model: any): void {
    this.selected.emit(model.item);
  }

  public scroll($event: any) {
    const elem = $event.currentTarget.nextElementSibling.getElementsByClassName("active")[0];
    elem.scrollIntoView({behavior: "smooth", block: "nearest"});
  }

}
