import {NewsCreateComponent} from "./news-create/news-create.component";
import {NewsDeleteComponent} from "./news-delete/news-delete.component";
import {NewsListComponent} from "./news-list/news-list.component";
import {NewsManageComponent} from "./news-manage/news-manage.component";
import {NewsSelectModule} from "./news-select.module";
import {NgModule} from "@angular/core";
import {ImageModule} from "../image/image.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";

@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    NewsSelectModule,
    ImageModule
  ],
  providers: [],
  declarations: [
    NewsCreateComponent,
    NewsListComponent,
    NewsDeleteComponent,
    NewsManageComponent
  ],
  exports: [
    NewsCreateComponent,
    NewsListComponent,
    NewsDeleteComponent,
    NewsManageComponent,
    NewsSelectModule
  ]
})
export class NewsModule {
}
