import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Rnm} from "../rnm/rnm.service";


export class ArticleTarifRnm extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "att",
    verboseName: "Article",
    required: false,
    helpText: "Article",
    relatedModel: "ArticleTarifTemplate",
    remoteField: "id",
    relatedName: "rnms"
  })
  public att!: number;

  @DSForeignKeyField({
    name: "rnma",
    verboseName: "ArticleRNM",
    required: false,
    helpText: "RNM Article",
    relatedModel: "tarif.RnmArticle",
    remoteField: "id",
    relatedName: "atts"
  })
  public rnma!: number;
  public rnm_details!: Rnm;

  @DSIntegerField({
    name: "score_manual",
    verboseName: "Score manuel",
    required: false,
    helpText: "Score manuel",
    default: 0,
    choices: []
  })
  public score_manual!: number;

  @DSIntegerField({
    name: "score_auto",
    verboseName: "Score auto",
    required: false,
    helpText: "Score auto",
    default: 0,
    choices: []
  })
  public score_auto!: number;


  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class ArticleTarifRnmService extends DSRestCollection<ArticleTarifRnm> {
  public adapter_config = {basePath: "/api/v2/tarifs/articletarifrnms"};
  public model = ArticleTarifRnm;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
