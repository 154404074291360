
<table class="table table-sm table-bordered">
  <thead>
    <tr>
      <th>ID</th>
      <th>Type</th>
      <th>Modèle de tarif</th>
      <th>Opérations</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let r of rels|async">
      <td>{{r.id}}</td>
      <td>{{r.type|choice:r:'type'}}</td>
      <td>
        <ng-container *ngIf="src &amp;&amp; r.dest &amp;&amp; r.dest_details"><code class="me-2">{{r.dest_details.code}}</code><a [routerLink]="tariftype_detail_route?.route({tarifType: r.dest_details})" target="_blank">{{r.dest_details?.name}}<i class="fa fa-external-link ms-2" *ngIf="tariftype_detail_route"></i></a></ng-container>
        <ng-container *ngIf="dest &amp;&amp; r.src &amp;&amp; r.src_details"><code class="me-2">{{r.src_details.code}}</code><a [routerLink]="tariftype_detail_route?.route({tarifType: r.src_details})" target="_blank">{{r.src_details?.name}}<i class="fa fa-external-link ms-2" *ngIf="tariftype_detail_route"></i></a></ng-container>
      </td>
      <td>
        <ng-container *ngIf="!editOps[r.id]">{{r.ops}}<i class="fa fa-pencil px-3" role="button" (click)="editOps[r.id] = !editOps[r.id]" *ngIf="editable"></i></ng-container>
        <ng-container *ngIf="editOps[r.id]">
          <bsac-dd-field [model]="r" field="ops" type="text" (changed)="editOps[r.id]=false">Opérations (cf doc pour la syntaxe)</bsac-dd-field>
        </ng-container>
      </td>
      <td>
        <lvadg-confirm-delete type="trash" [model]="r" (deleted)="reload()" *ngIf="editable"></lvadg-confirm-delete>
      </td>
    </tr>
    <tr *ngIf="add &amp;&amp; editable">
      <td class="p-2" colspan="999">
        <form class="row" [formGroup]="createForm" (submit)="create()">
          <div class="col-sm-2">
            <label>Type</label>
            <bsac-field-control [form]="createForm" [model]="newRelation" field="type"></bsac-field-control>
          </div>
          <div class="col-sm-8" *ngIf="src">
            <label>Relation (destination)</label>
            <bsac-field-control [form]="createForm" [model]="newRelation" field="dest" [related]="tariftypes$" descfield="name" [relatedParams]="{query: {fields: 'id,type,name'}}"></bsac-field-control>
          </div>
          <div class="col-sm-8" *ngIf="dest">
            <label>Relation (source)</label>
            <bsac-field-control [form]="createForm" [model]="newRelation" field="src" [related]="tariftypes$" descfield="name" [relatedParams]="{query: {fields: 'id,type,name'}}"></bsac-field-control>
          </div>
          <div class="col-sm-2">
            <bsac-field-control [form]="createForm" [model]="newRelation" field="ops"><span class="label">Opérations (cf doc)</span></bsac-field-control>
          </div>
          <div class="col-8">
            <button class="btn btn-primary w-100" type="submit">Créer</button>
          </div>
          <div class="col-4">
            <button class="btn btn-secondary w-100" type="cancel" (click)="add=false">Annuler</button>
          </div>
        </form>
      </td>
    </tr>
    <tr *ngIf="!add &amp;&amp; editable">
      <td class="table-primary text-center" colspan="999" role="button" (click)="initNewRelation()">Ajouter une relation</td>
    </tr>
  </tbody>
</table>