import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {GeoDepartement, GeoDepartementService} from "./geodepartement.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class GeoDepartementResolve implements Resolve<GeoDepartement> {
  constructor(private _bs: GeoDepartementService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<GeoDepartement> {
    return this._bs.get(+route.params["geodepartementId"]);
  }
}
