import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Member, MemberService} from "./member.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class MemberResolve implements Resolve<Member> {
  constructor(private _bs: MemberService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Member> {
    return this._bs.get(+route.params["memberId"]);
  }
}
