import {Component, Input} from "@angular/core";
import {Provider, ProviderService} from "../provider.service";
import {BsacMessageService, BsacModelListProvider, IBsacApiConstants} from "@solidev/bsadmincomponents";
import {firstValueFrom, Observable} from "rxjs";
import {first, takeUntil} from "rxjs/operators";
import {SLabelService} from "../../slabel/slabel.service";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {CustomDataKey, CustomDataKeyService} from "../../../customdata/customdatakey/customdatakey.service";
import {ArticleFamily, ArticleFamilyService} from "../../../catalog/family/article-family.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {Producer} from "../../producer/producer.service";


@Component({
  selector: "lvadg-provider-list",
  templateUrl: "./provider-list.component.pug",
  styleUrls: ["./provider-list.component.sass"]
})
export class ProviderListComponent extends ModelListBaseComponent<Provider, ProviderService> {

  @Input() public provider!: Provider;
  @Input() public detail_route!: RouteV3<Provider>;
  @Input() public producer_detail_route!: RouteV3<Producer>;
  public refgammes = [
    {desc: "1ere gamme (ET)", value: "and1g"},
    {desc: "4eme gamme (ET)", value: "and4g"},
    {desc: "5eme gamme (ET)", value: "and5g"},
    {desc: "1ere gamme (OU)", value: "or1g"},
    {desc: "4eme gamme (OU)", value: "or4g"},
    {desc: "5eme gamme (OU)", value: "or5g"}
  ]
  public slabels!: IBsacApiConstants;
  public cdatakeys!: CustomDataKey[];

  public flags!: Observable<any>;
  public families$!: DSRestQueryset<ArticleFamily>;

  constructor(_list: BsacModelListProvider,
              _items: ProviderService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              private _customdatakeys: CustomDataKeyService,
              private _families: ArticleFamilyService,
              private _slabels: SLabelService) {
    super(_list, _items, _msgs, routes);
    this.type = "providers";
  }

  public async preNgOnInit(): Promise<void> {
    this.families$ = this._families.queryset;
    this.slabels = await firstValueFrom(this._slabels.constants$.pipe(first()));
    this.flags = this._items.action(null, "get_flags", {method: "GET", body: {}})
      .pipe(
        takeUntil(this._subscriptions$)
      );
    this.custom_fields.push({name: "city", verbose_name: "Ville"});
    this.custom_fields.push({name: "postcode", verbose_name: "Code postal"});
    this.custom_fields.push({name: "country", verbose_name: "Pays"});
    this.cdatakeys = await firstValueFrom(this._customdatakeys.getByDest("PRV", false));
    for (const ck of this.cdatakeys) {
      this.custom_fields.push({name: `customdata_${ck.id}`, verbose_name: ck.name});
    }
    return super.preNgOnInit();
  }


  public getDetailLink(model: Provider): (string | number)[] {
    if (this.detail_route) {
      return this.detail_route.route({provider: model});
    }
    return this.routes.get(this.zone, "provider", model);
  }

  getProducerRoute(p: Producer) {
    if (this.producer_detail_route) {
      return this.producer_detail_route.route({producer: p});
    }
    return this.routes.detail("fl", "producers", p);
  }
}
