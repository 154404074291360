import {Component} from "@angular/core";
import {Label, LabelService} from "../label.service";
import {LabelDisplayBaseComponent} from "../../_label/label-display-base/label-display-base.component";

@Component({
  selector: "lvadg-label-display",
  templateUrl: "./label-display.component.pug",
  styleUrls: ["./label-display.component.sass"]
})
export class LabelDisplayComponent extends LabelDisplayBaseComponent<Label, LabelService> {

  constructor(protected _labels: LabelService) {
    super(_labels);
  }

}
