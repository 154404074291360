import {NgModule} from "@angular/core";
import {TariftyperelationListmanageComponent} from "./tariftyperelation-listmanage/tariftyperelation-listmanage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  declarations: [
    TariftyperelationListmanageComponent,
  ],
  exports: [
    TariftyperelationListmanageComponent,
  ],
  imports: [
    ...MODELLIST_IMPORTS
  ]
})
export class TariftyperelationModule {
}
