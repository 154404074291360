import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export type IExternalArticleType = "CLI"|"STO"|"MEM"|"PRO"|"LVG"|"ADG"|"CLB";
export class ExternalArticle extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 3,
    choices: [["CLI", "Client"],
      ["STO", "Dépôt"],
      ["MEM", "Adhérent"],
      ["PRO", "Fournisseur"],
      ["LVG", "Lavieadugout historique"],
      ["ADG", "Groupe de dépôts"],
      ["CLB", "Client import bulk"]]
  })
  public type!: IExternalArticleType;

  @DSForeignKeyField({
    name: "client",
    verboseName: "Client",
    required: false,
    helpText: "Client",
    relatedModel: "structure.Client",
    remoteField: "id",
    relatedName: "articles"
  })
  public client!: number;
  public client_details!: { id: number; cvva: string; name: string; parent_details?: { id: number; cvva: string; name: string } };

  @DSForeignKeyField({
    name: "tariftype",
    verboseName: "Tarif",
    required: false,
    helpText: "Tarif",
    relatedModel: "tarif.TarifType",
    remoteField: "id",
    relatedName: "externalarticles"
  })
  public tariftype!: number;
  public tariftype_details!: { id: number; name: string; code: string; };

  @DSForeignKeyField({
    name: "storage",
    verboseName: "Dépôt",
    required: false,
    helpText: "Dépôt",
    relatedModel: "structure.Storage",
    remoteField: "id",
    relatedName: "articles"
  })
  public storage!: number;
  public storage_details!: { id: number; name: string };

  @DSForeignKeyField({
    name: "member",
    verboseName: "Adhérent",
    required: false,
    helpText: "Adhérent",
    relatedModel: "structure.Member",
    remoteField: "id",
    relatedName: "articles"
  })
  public member!: number;
  public member_details!: { id: number; name: string };

  @DSForeignKeyField({
    name: "provider",
    verboseName: "Fournisseur",
    required: false,
    helpText: "Fournisseur",
    relatedModel: "structure.Provider",
    remoteField: "id",
    relatedName: "articles"
  })
  public provider!: number;
  public provider_details!: { id: number; name: string };

  @DSForeignKeyField({
    name: "collection",
    verboseName: "Collection",
    required: false,
    helpText: "Collection",
    relatedModel: "catalog.ExternalArticleCollection",
    remoteField: "id",
    relatedName: "articles"
  })
  public collection!: number;
  public collection_details!: { id: number; name: string };

  @DSCharField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "icode",
    verboseName: "Autre code",
    required: false,
    helpText: "Autre code",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public icode!: string;


  @DSCharField({
    name: "ean13",
    verboseName: "EAN13",
    required: false,
    helpText: "EAN13",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public ean13!: string;

  @DSCharField({
    name: "gamme",
    verboseName: "Classif niveau 1",
    required: false,
    helpText: "Classification niveau 1",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public gamme!: string;

  @DSCharField({
    name: "sgamme",
    verboseName: "Classif niveau 2",
    required: false,
    helpText: "Classification niveau 2",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public sgamme!: string;

  @DSCharField({
    name: "marque",
    verboseName: "Marque",
    required: false,
    helpText: "Marque",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public marque!: string;


  @DSCharField({
    name: "libelle",
    verboseName: "Libellé",
    required: false,
    helpText: "Libellé",
    minLength: 0,
    maxLength: 400,
    choices: []
  })
  public libelle!: string;


  @DSCharField({
    name: "categorie",
    verboseName: "Catégorie",
    required: false,
    helpText: "Catégorie",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public categorie!: string;

  @DSCharField({
    name: "calibre",
    verboseName: "Calibre",
    required: false,
    helpText: "Calibre",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public calibre!: string;

  @DSCharField({
    name: "origine",
    verboseName: "Origine",
    required: false,
    helpText: "Origine",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public origine!: string;

  @DSCharField({
    name: "uf",
    verboseName: "Unité Facturation",
    required: false,
    helpText: "Unité Facturation",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public uf!: string;

  @DSDecimalField({
    name: "uf_value",
    verboseName: "Unité Facturation (valeur)",
    required: false,
    helpText: "Unité Facturation (valeur)",
    default: 0,
    factor: 10000,
    choices: []
  })
  public uf_value!: number;

  @DSCharField({
    name: "uf_unit",
    verboseName: "Unité Facturation (unité)",
    required: false,
    helpText: "Unité Facturation (unité)",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public uf_unit!: string;

  @DSCharField({
    name: "pe",
    verboseName: "Poids Egoutté",
    required: false,
    helpText: "Poids Egoutté",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public pe!: string;

  @DSDecimalField({
    name: "pe_value",
    verboseName: "Poids Egoutté (valeur)",
    required: false,
    helpText: "Poids Egoutté (valeur)",
    default: 0,
    factor: 10000,
    choices: []
  })
  public pe_value!: number;

  @DSCharField({
    name: "pe_unit",
    verboseName: "Poids Egoutté (unité)",
    required: false,
    helpText: "Poids Egoutté (unité)",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public pe_unit!: string;

  @DSCharField({
    name: "uc",
    verboseName: "Unité Commande",
    required: false,
    helpText: "Unité Commande",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public uc!: string;

  @DSDecimalField({
    name: "uc_value",
    verboseName: "Unité Commande (valeur)",
    required: false,
    helpText: "Unité Commande (valeur)",
    default: 0,
    factor: 10000,
    choices: []
  })
  public uc_value!: number;

  @DSCharField({
    name: "uc_unit",
    verboseName: "Unité Commande (unité)",
    required: false,
    helpText: "Unité Commande (unité)",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public uc_unit!: string;


  @DSCharField({
    name: "ucuf",
    verboseName: "Ratio UC/UF",
    required: false,
    helpText: "Ratio UC/UF",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public ucuf!: string;

  @DSCharField({
    name: "ucond",
    verboseName: "Unité Conditionnement",
    required: false,
    helpText: "Unité Conditionnement",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public ucond!: string;

  @DSDecimalField({
    name: "ucond_value",
    verboseName: "Unité Conditionnement (valeur)",
    required: false,
    helpText: "Unité Conditionnement (valeur)",
    default: 0,
    factor: 10000,
    choices: []
  })
  public ucond_value!: number;

  @DSCharField({
    name: "ucond_unit",
    verboseName: "Unité Conditionnement (unité)",
    required: false,
    helpText: "Unité Conditionnement (unité)",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public ucond_unit!: string;


  @DSCharField({
    name: "nego_unit",
    verboseName: "Unité Négociation (unité)",
    required: false,
    helpText: "Unité Négociation (unité)",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public nego_unit!: string;


  @DSManyToManyField({
    name: "articles",
    verboseName: "Articles",
    required: false,
    relatedModel: "catalog.Article",
    remoteField: "externalarticles",
    helpText: "Articles",
    default: [],
  })
  public articles!: number[];
  public mappings_details: {
    id: number;
    article: number;
    flags: string[];
    article_details: {
      id: number;
      code: string;
      vvid: number;
      libelle: string
    }
  }[];


  @DSForeignKeyField({
    name: "flvariant",
    verboseName: "Variante FL origine",
    required: false,
    helpText: "Nomenclature FL (lien)",
    relatedModel: "product.ProductVariant",
    remoteField: "id",
    relatedName: "externalarticles"
  })
  public flvariant!: number;
  @DSForeignKeyField({
    name: "seavariant",
    verboseName: "Nomenclature marée (lien)",
    required: false,
    helpText: "Nomenclature marée (lien))",
    relatedModel: "product.SeaProductVariant",
    remoteField: "id",
    relatedName: "externalarticles"
  })
  public seavariant!: number;


  @DSCharField({
    name: "xlsxhash",
    verboseName: "XLSX Hash",
    required: false,
    helpText: "XLSX Hash",
    minLength: 0,
    maxLength: 400,
    choices: []
  })
  public xlsxhash!: string;

  @DSDefaultField({
    name: "orig_data",
    verboseName: "Données d'origine",
    required: false,
    helpText: "Données d'origine",
  })
  public orig_data!: any;

  @DSCharField({
    name: "importref",
    verboseName: "Ref import",
    required: false,
    helpText: "Ref import",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public importref!: string;


// Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "",
    choices: [
      ["INT", "Dans tarif"],
      ["OUT", "Hors tarif"],
      ["PBM", "Problème à régler"],
      ["TGT", "Ciblé pour tarif"],
      ["NSG", "Non significatif"],
    ],
    maxLength: 10
  })
  public status!: string;

  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags!: any;


  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class ExternalArticleService extends DSRestCollection<ExternalArticle> {
  public adapter_config = {basePath: "/api/v2/catalog/externalarticles"};
  public model = ExternalArticle;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
