
<div class="folder-breadcrumb">
  <div class="folder-tree">
    <div class="parent pointer" (click)="tree.setCurrent()"><i class="fa fa-home me-1"></i>DOSSIERS</div>
    <div class="parent pointer" *ngFor="let p of parents" (click)="tree.setCurrent(p)"><i class="fa fa-folder me-1"></i>{{p.title}}</div>
    <div class="current pointer" *ngIf="current" (click)="tree.setCurrent(current)"><i class="fa fa-folder-open me-1"></i>{{current.title}}</div>
  </div>
  <div class="folder-actions">
    <button class="btn btn-link create" *ngIf="mode == 'admin'" (click)="goCreate()"><i class="fa fa-plus"></i></button>
    <button class="btn btn-link" (click)="tree.reload()"><i class="fa fa-sync"></i></button>
    <button class="btn btn-link" [class.active]="tree.display.properties" (click)="tree.display.properties=!tree.display.properties"><i class="fa fa-info-circle"></i></button>
  </div>
</div>