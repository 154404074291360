import {Component, Input} from "@angular/core";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {Article, ArticleService} from "../article.service";
import {Client, ClientService} from "../../../structure/client/client.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {ArticleMarque, ArticleMarqueService} from "../../articledata/article-marque.service";
import {ArticleCalibre, ArticleCalibreService} from "../../articledata/article-calibre.service";
import {ArticleCategorie, ArticleCategorieService} from "../../articledata/article-categorie.service";
import {ArticleClient, ArticleClientService} from "../../articledata/article-client.service";
import {ArticleEgalim, ArticleEgalimService} from "../../articledata/article-egalim.service";
import {ArticleOrigine, ArticleOrigineService} from "../../articledata/article-origine.service";
import {ArticleParticularite, ArticleParticulariteService} from "../../articledata/article-particularite.service";
import {ArticlePresentation, ArticlePresentationService} from "../../articledata/article-presentation.service";
import {ArticleProcess, ArticleProcessService} from "../../articledata/article-process.service";
import {ArticleProduction, ArticleProductionService} from "../../articledata/article-production.service";
import {ArticleRegion, ArticleRegionService} from "../../articledata/article-region.service";
import {ArticleUsage, ArticleUsageService} from "../../articledata/article-usage.service";
import {ArticleFamily, ArticleFamilyService} from "../../family/article-family.service";
import {Provider, ProviderService} from "../../../structure/provider/provider.service";
import {DetailDocument, DetailDocumentService} from "../../../document/basedocument/basedocument.service";
import {ExternalArticle} from "../../externalarticle/externalarticle.service";
import {TarifType} from "../../../tarif/tariftype/tariftype.service";
import {firstValueFrom} from "rxjs";
import {ArticleStatService} from "../article-stat.service";


@Component({
  selector: "lvadg-article-list",
  templateUrl: "./article-list.component.pug",
  styleUrls: ["./article-list.component.sass"]
})
export class ArticleListComponent extends ModelListBaseComponent<Article, ArticleService> {
  @Input() public client!: Client;
  @Input() public provider!: Provider;
  @Input() public ea!: ExternalArticle;
  @Input() public family!: ArticleFamily;
  @Input() public in_tarif!: TarifType;
  @Input() public stats: boolean = false;
  @Input() public article_detail_route!: RouteV3<Article>;
  @Input() public provider_detail_route!: RouteV3<Provider>;

  public pricedclients$!: DSRestQueryset<Client>;
  public marques$!: DSRestQueryset<ArticleMarque>;
  public calibres$!: DSRestQueryset<ArticleCalibre>;
  public categories$!: DSRestQueryset<ArticleCategorie>;
  public clients$!: DSRestQueryset<ArticleClient>;
  public egalims$!: DSRestQueryset<ArticleEgalim>;
  public origines$!: DSRestQueryset<ArticleOrigine>;
  public particularites$!: DSRestQueryset<ArticleParticularite>;
  public presentations$!: DSRestQueryset<ArticlePresentation>;
  public processs$!: DSRestQueryset<ArticleProcess>;
  public productions$!: DSRestQueryset<ArticleProduction>;
  public regions$!: DSRestQueryset<ArticleRegion>;
  public usages$!: DSRestQueryset<ArticleUsage>;
  public providers$!: DSRestQueryset<Provider>;
  public WITH_PRICES = [
    {desc: "Dans un tarif", value: "yes"},
    {desc: "Dans un tarif, avec un prix", value: "notnull"},
    {desc: "Sans tarif", value: "no"}
  ];


  public stats_intervals: number[] = [];

  constructor(_list: BsacModelListProvider,
              _items: ArticleService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              private _pricedclients: ClientService,
              private _marques: ArticleMarqueService,
              private _calibres: ArticleCalibreService,
              private _categories: ArticleCategorieService,
              private _clients: ArticleClientService,
              private _egalims: ArticleEgalimService,
              private _origines: ArticleOrigineService,
              private _particularites: ArticleParticulariteService,
              private _presentations: ArticlePresentationService,
              private _processes: ArticleProcessService,
              private _productions: ArticleProductionService,
              private _regions: ArticleRegionService,
              private _usages: ArticleUsageService,
              private _providers: ProviderService,
              private _ddocs: DetailDocumentService,
              public families$: ArticleFamilyService,
              public statss: ArticleStatService,
  ) {
    super(_list, _items, _msgs, routes);
  }


  public async preNgOnInit(): Promise<void> {
    if (!this.type) {
      this.type = "article";
    }
    this.custom_fields = [
      {name: "select", verbose_name: "Sélection"},
      {name: "actions", verbose_name: "Actions"},
      {name: "colisage", verbose_name: "Colisage"},
      {name: "uci", verbose_name: "UCI"},
      {name: "udf", verbose_name: "UDF"},
      {name: "isEgalim", verbose_name: "Egalim ?"},
      {name: "isEgalimPlus", verbose_name: "Equivalence Egalim ?"},
      {name: "doc_FT", verbose_name: "Fiches techniques"},
      {name: "doc_DL", verbose_name: "Documents structure"},
      {name: "doc_DE", verbose_name: "Documents label/certification"},
      {name: "doc_OT", verbose_name: "Autres documents"},
    ];
    if (this.in_tarif) {
      this.filter.in_tarif = this.in_tarif.id;
    }
    if (this.stats) {
      await this.statss.setInterval();
      this.filter.tarifs_stats = 1;
      this.default_fields.push(`tarifs_stats`);
      for (const c of this.statss.defaultColumns) {
        this.stats_intervals.push(c.id);
        this.custom_fields.push({name: c.code, verbose_name: c.name});
        if (this.statss.column(c.id).active) {
          this.default_fields.push(c.code);
        }
      }
    }
    this.custom_fields.push({name: "tarifs_stats", verbose_name: "Stats tarifs"});
    this.pricedclients$ = this._pricedclients.queryset;
    this.clients$ = this._clients.queryset;
    this.marques$ = this._marques.queryset;
    this.calibres$ = this._calibres.queryset;
    this.categories$ = this._categories.queryset;
    this.clients$ = this._clients.queryset;
    this.egalims$ = this._egalims.queryset;
    this.origines$ = this._origines.queryset;
    this.particularites$ = this._particularites.queryset;
    this.presentations$ = this._presentations.queryset;
    this.processs$ = this._processes.queryset;
    this.productions$ = this._productions.queryset;
    this.regions$ = this._regions.queryset;
    this.regions$ = this._regions.queryset;
    this.usages$ = this._usages.queryset;
    this.providers$ = this._providers.queryset;

    this.updateFilter({
      client: this.client,
      fournisseur: this.provider,
      externalarticle: this.ea,
      family: this.family
    });


  }


  public async postNgOnInit(): Promise<void> {
    this.list.emitFields = true;
    if (this.stats) {
      this.statss.updateFields(this.list);
    }
    this.list.filter.output$.subscribe(async (f) => {
      if (f["stat_interval"] || f["stat_grouper"]) {
        await this.statss.setInterval(f["stat_interval"] as any, f["state_grouper"] as any);
        this.statss.updateFields(this.list);
      }
    });
    return super.postNgOnInit();
  }

  public async downloadDocument(d: DetailDocument): Promise<void> {
    if (!d.download) {
      const doc = await firstValueFrom(this._ddocs.create(d, {save: false}));
      doc.download();
    }
  }

  public getDetailLink(model: Article): (string | number)[] {
    if (this.article_detail_route) {
      return this.article_detail_route.route({article: model});
    }
    // LEGACY, nomessage because massively used
    if (this.provider) {
      return this.routes.get(this.zone, this.type, this.provider, model);
    }
    if (this.family) {
      return this.routes.get(this.zone, this.type, this.family, model);
    }
    return this.routes.get(this.zone, this.type, model);
  }

  public onAction(action: any): void {
    this.action.emit(action);
  }

  getProviderLink(fournisseur_details: Provider) {
    if (this.provider_detail_route) {
      return this.provider_detail_route.route({provider: fournisseur_details});
    }
  }

  getRowClasses(model: Article): { [p: string]: string | boolean } {
    const rowc = super.getRowClasses(model);
    if (this.in_tarif && model.in_tarif) {
      rowc["table-success"] = true;
    }
    return rowc;
  }
}
