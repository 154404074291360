import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import {CdkTableModule} from "@angular/cdk/table";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {CompsModule} from "../../../comps/comps.module";

import {SeaProductPriceListComponent} from "./seaproductprice-list/seaproductprice-list.component";

import {SeaProductPriceCreateComponent} from "./seaproductprice-create/seaproductprice-create.component";

import {SeaProductPriceManageComponent} from "./seaproductprice-manage/seaproductprice-manage.component";

import {SeaProductPriceDeleteComponent} from "./seaproductprice-delete/seaproductprice-delete.component";

import {SeaProductPriceSelectModule} from "./seaproductprice-select.module";

@NgModule({
  imports: [
    CommonModule,
    SeaProductPriceSelectModule,
    CdkTableModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,

    CompsModule
  ],
  declarations: [
    SeaProductPriceListComponent,
    SeaProductPriceCreateComponent,
    SeaProductPriceManageComponent,
    SeaProductPriceDeleteComponent
  ],
  exports: [
    SeaProductPriceSelectModule,

    SeaProductPriceListComponent,
    SeaProductPriceCreateComponent,
    SeaProductPriceManageComponent,
    SeaProductPriceDeleteComponent
  ]
})
export class SeaProductPriceModule {}
