import {Component, Input} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {Person, PersonService} from "../../../people/person/person.service";
import {TarifType} from "../tariftype.service";
import {firstValueFrom, Subject} from "rxjs";

@Component({
  selector: "lvadg-tariftype-userlist",
  templateUrl: "./tariftype-userlist.component.pug",
  styleUrls: ["./tariftype-userlist.component.sass"]
})
export class TariftypeUserlistComponent extends ModelListBaseComponent<Person, PersonService> {
  @Input() public tarifType!: TarifType;
  @Input() public person_detail_route!: RouteV3<Person>;
  public inchoices: { desc: string; value: string }[] = [];

  constructor(
    _list: BsacModelListProvider,
    _items: PersonService,
    _msgs: BsacMessageService,
    routes: RoutesService
  ) {
    super(_list, _items, _msgs, routes);
  }

  public async preNgOnInit(): Promise<void> {
    this.reload = new Subject<void>();
    this.allowed_fields = ["id", "lastname", "firstname", "email", "groups"];
    this.default_fields = ["id", "lastname", "firstname", "email",
      "intariftype_groups", "intariftype_starred", "intariftype_notified"];
    this.default_filters = ["search", "modetariftype1", "modetariftype2"];
    this.custom_fields = [
      {name: "intariftype_groups", verbose_name: "Groupes du tarif"},
      {name: "intariftype_starred", verbose_name: "Préféré ?"},
      {name: "intariftype_notified", verbose_name: "Notifié ?"},
    ];
    this.filter = {intariftype: this.tarifType.id.toString()};
    this.inchoices = [
      {desc: "Administration", value: `admingps:${this.tarifType.id}`},
      {desc: "Edition", value: `writegps:${this.tarifType.id}`},
      {desc: "Consultation", value: `editgps:${this.tarifType.id}`},
      {desc: "Préférés", value: `starred:${this.tarifType.id}`},
      {desc: "Notifiés", value: `notified:${this.tarifType.id}`},
      {desc: "Tout", value: `all:${this.tarifType.id}`}
    ];
    return super.preNgOnInit();

  }

  public getDetailLink(model: Person): (string | number)[] {
    if (this.person_detail_route) {
      return this.person_detail_route.route({person: model});
    }
    return super.getDetailLink(model);
  }

  public async postNgOnInit(): Promise<void> {
  }

  public async starToggle(current: Person) {
    const res = await firstValueFrom(this.tarifType.action("manage_contact", {method: "POST", body: {mode: "star", user: current.id}}));
    (this.reload as Subject<void>).next();
    this._msgs.success(`Préférence modifiée avec succès`, `Utilisateur : ${current.email}`);
  }

  public async notifyToggle(current: Person) {
    const res = await firstValueFrom(this.tarifType.action("manage_contact", {method: "POST", body: {mode: "notify", user: current.id}}));
    (this.reload as Subject<void>).next();
    this._msgs.success(`Etat de notification modifié avec succès`, `Utilisateur : ${current.email}`);
  }

  public async viewAllAction(action: string) {
    const res = await firstValueFrom(this.tarifType.action("bulk", {method: "POST", body: {action, group: "pilote:ttcli:viewall"}}));
    (this.reload as Subject<void>).next();
    this._msgs.success(`Opération en masse réalisée avec succès`);
  }
}
