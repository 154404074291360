
<ng-container *ngIf="!file">
  <lvadg-upload-file [url]="uploadUrl" [data]="{type: 'GCOD', code: code}" (uploaded)="onUploaded($event)"></lvadg-upload-file>
</ng-container>
<ng-container *ngIf="file">
  <div class="form-group">
    <label>Type de pointeur</label>
    <select class="form-select" [(ngModel)]="defaults.type">
      <option *ngFor="let t of itypes" [value]="t">{{types[t]}}</option>
    </select>
  </div>
  <lvadg-upload-xlsx-map [file]="file" [fields]="geoFields" [defaults]="defaults" (mapped)="onMapped($event)"></lvadg-upload-xlsx-map>
</ng-container>