<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'flags'; desc: 'Recherche par flag'; group: 'general'">
        <bsac-model-list-flags-filter [list]="list" field="flags" [flags]="flags" label="Recherche par flag"></bsac-model-list-flags-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'preferred'; desc: 'Filtrer mes préférés'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="preferred" [choices]="PREFERRED_CHOICES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'statuses'; desc: 'Filtrer par état'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="statuses" choices="TARIFTYPE_STATUSES"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'zone'; desc: 'Filtrer par zone'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="zone" [choices]="ZONES_CHOICES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'lifecycle'; desc: 'Filtrer par cycle de vie'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="lifecycle" [choices]="LIFECYCLE_CHOICES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend__OK__lte'; desc: 'Date de fin (valide) avant'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend__OK__lte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend__OK__gte'; desc: 'Date de fin (valide) après'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend__OK__gte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend__OK_AR__lte'; desc: 'Date de fin (valide / archivé) avant'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend__OK_AR__lte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend__OK_AR__gte'; desc: 'Date de fin (valide / archivé) après'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend__OK_AR__gte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart__OK__lte'; desc: 'Date de début (valide) avant'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart__OK__lte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart__OK__gte'; desc: 'Date de début (valide) après'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart__OK__gte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart__OK_AR__lte'; desc: 'Date de début (valide / archivé) avant'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart__OK_AR__lte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart__OK_AR__gte'; desc: 'Date de début (valide / archivé) après'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart__OK_AR__gte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'dates'; desc: 'Recherche par dates'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status|choice:row:'status'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="code">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="code">Code</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.code}}</code></td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>Nom du modèle</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="getDetailLink(row)">{{row.name}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="name_template">
            <th cdk-header-cell *cdkHeaderCellDef>Modèle de nom</th>
            <td cdk-cell *cdkCellDef="let row">{{row.name_template}}</td>
          </ng-container>
          <ng-container cdkColumnDef="message">
            <th cdk-header-cell *cdkHeaderCellDef>Message (manuel)</th>
            <td cdk-cell *cdkCellDef="let row">{{(row.message||'')|stripTags|shorten:50:'...'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="auto_message">
            <th cdk-header-cell *cdkHeaderCellDef>Message (auto)</th>
            <td cdk-cell *cdkCellDef="let row">{{(row.auto_message||'')|stripTags|shorten:50:'...'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="contract_message">
            <th cdk-header-cell *cdkHeaderCellDef>Message CGV</th>
            <td cdk-cell *cdkCellDef="let row">{{(row.contract_message||'')|stripTags|shorten:50:'...'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type|choice:row:'type'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="lifecycle">
            <th cdk-header-cell *cdkHeaderCellDef>Cycle de vie</th>
            <td cdk-cell *cdkCellDef="let row">{{row.lifecycle|choice:row:'lifecycle'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="admin_groups">
            <th cdk-header-cell *cdkHeaderCellDef>Groupes (administration)</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="badge mx-1 bg-danger" *ngFor="let g of row.admin_groups_details" [title]="g.description">{{g.slug}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="write_groups">
            <th cdk-header-cell *cdkHeaderCellDef>Groupes (écriture)</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="badge mx-1 bg-warning" *ngFor="let g of row.write_groups_details" [title]="g.description">{{g.slug}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="read_groups">
            <th cdk-header-cell *cdkHeaderCellDef>Groupes (lecture)</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="badge mx-1 bg-info" *ngFor="let g of row.read_groups_details" [title]="g.description">{{g.slug}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="client">
            <th cdk-header-cell *cdkHeaderCellDef>Client</th>
            <td cdk-cell *cdkCellDef="let row"><span *ngIf="row.client_details?.parent_details"><b>{{row.client_details?.parent_details?.name||"-"}}</b> / {{row.client_details?.name||"-"}}</span><span *ngIf="!row.client_details?.parent_details"><b>{{row.client_details?.name||"-"}}</b></span></td>
          </ng-container>
          <ng-container cdkColumnDef="member">
            <th cdk-header-cell *cdkHeaderCellDef>Adhérent</th>
            <td cdk-cell *cdkCellDef="let row">{{row.member_details?.name||"-"}}</td>
          </ng-container>
          <ng-container cdkColumnDef="storage">
            <th cdk-header-cell *cdkHeaderCellDef>Dépôt</th>
            <td cdk-cell *cdkCellDef="let row">{{row.storage_details?.name||"-"}}</td>
          </ng-container>
          <ng-container cdkColumnDef="members">
            <th cdk-header-cell *cdkHeaderCellDef>Adhérents</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let m of row.members_details">{{m.name}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="storages">
            <th cdk-header-cell *cdkHeaderCellDef>Dépôts</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let s of row.storages_details">{{s.name}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="base_interval">
            <th cdk-header-cell *cdkHeaderCellDef>Intervalle de base</th>
            <td cdk-cell *cdkCellDef="let row">{{row.base_interval|choice:row:'base_interval'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="user_level">
            <th cdk-header-cell *cdkHeaderCellDef>Droits utilisateur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.user_level}}</td>
          </ng-container>
          <ng-container cdkColumnDef="parameters">
            <th cdk-header-cell *cdkHeaderCellDef>Paramètres divers</th>
            <td cdk-cell *cdkCellDef="let row">{{row.parameters|json}}</td>
          </ng-container>
          <ng-container cdkColumnDef="user_starred">
            <th cdk-header-cell *cdkHeaderCellDef>Préféré ?</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-star" *ngIf="row.user_starred"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="decondi_status">
            <th cdk-header-cell *cdkHeaderCellDef>Déconditionnement</th>
            <td cdk-cell *cdkCellDef="let row">{{row.decondi_status|choice:row:'decondi_status'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="decondi_kg">
            <th cdk-header-cell *cdkHeaderCellDef>Decondi kg</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.decondi_kg &amp;&amp; row.decondi_status=='CTR'">{{row|dsDecimal:'decondi_kg'}}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="decondi_pce">
            <th cdk-header-cell *cdkHeaderCellDef>Décondi pce</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.decondi_pce &amp;&amp; row.decondi_status=='CTR'">{{row|dsDecimal:'decondi_pce'}}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="asscom">
            <th cdk-header-cell *cdkHeaderCellDef>Ass. Comm.</th>
            <td cdk-cell *cdkCellDef="let row">{{row.asscom_details?.lastname}} {{row.asscom_details?.firstname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="respcom">
            <th cdk-header-cell *cdkHeaderCellDef>Resp. Comm.</th>
            <td cdk-cell *cdkCellDef="let row">{{row.respcom_details?.lastname}} {{row.respcom_details?.firstname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="pilotes">
            <th cdk-header-cell *cdkHeaderCellDef>Pilote(s)</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let p of row.pilotes_details">{{p.lastname}} {{p.firstname}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="contacts">
            <th cdk-header-cell *cdkHeaderCellDef>Contacts</th>
            <td cdk-cell *cdkCellDef="let row">Voir fiche détails</td>
          </ng-container>
          <ng-container cdkColumnDef="user_notified">
            <th cdk-header-cell *cdkHeaderCellDef>Notifié</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-bell" *ngIf="row.user_notified"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="tarifs_ok">
            <th cdk-header-cell *cdkHeaderCellDef>Tarifs valides</th>
            <td cdk-cell *cdkCellDef="let row"><a class="d-block text-success" [routerLink]="routes.get(zone, 'tarif', row, r)" *ngFor="let r of row.tarifs_ok">{{r.datestart|date:'shortDate'}}&nbsp;&rightarrow;&nbsp;{{r.dateend|date:'shortDate'}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="tarifs_pr">
            <th cdk-header-cell *cdkHeaderCellDef>Tarifs en préparation</th>
            <td cdk-cell *cdkCellDef="let row"><a class="d-block text-secondary" [routerLink]="routes.get(zone, 'tarif', row, r)" *ngFor="let r of row.tarifs_pr">{{r.datestart|date:'shortDate'}}&nbsp;&rightarrow;&nbsp;{{r.dateend|date:'shortDate'}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="tarifs_tv">
            <th cdk-header-cell *cdkHeaderCellDef>Tarifs à valider</th>
            <td cdk-cell *cdkCellDef="let row"><a class="d-block text-warning" [routerLink]="routes.get(zone, 'tarifs', row, r)" *ngFor="let r of row.tarifs_tv">{{r.datestart|date:'shortDate'}}&nbsp;&rightarrow;&nbsp;{{r.dateend|date:'shortDate'}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="zone">
            <th cdk-header-cell *cdkHeaderCellDef>Zone</th>
            <td cdk-cell *cdkCellDef="let row">{{row.zone|choice:row:'zone'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="rrule">
            <th cdk-header-cell *cdkHeaderCellDef>Récurrence</th>
            <td cdk-cell *cdkCellDef="let row">{{row.rrule}}</td>
          </ng-container>
          <ng-container cdkColumnDef="delta_prepared">
            <th cdk-header-cell *cdkHeaderCellDef>Delta préparation</th>
            <td cdk-cell *cdkCellDef="let row">{{row.delta_prepared/24}}j avant</td>
          </ng-container>
          <ng-container cdkColumnDef="delta_validated">
            <th cdk-header-cell *cdkHeaderCellDef>Delta validation</th>
            <td cdk-cell *cdkCellDef="let row">{{row.delta_validated/24}}j avant</td>
          </ng-container>
          <ng-container cdkColumnDef="delta_published">
            <th cdk-header-cell *cdkHeaderCellDef>Delta publication</th>
            <td cdk-cell *cdkCellDef="let row">{{row.delta_published/24}}j avant</td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>