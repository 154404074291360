import {Offer, OfferService} from "../offer.service";
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {StorageService} from "../../../structure/storage/storage.service";
import {ClientService} from "../../../structure/client/client.service";
import {Member} from "../../../structure/member/member.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {Resto, RestoService} from "../../../structure/resto/resto.service";
import {map} from "lodash-es";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "lvadg-offer-clone",
  templateUrl: "./offer-clone.component.pug",
  styleUrls: ["./offer-clone.component.sass"]
})
export class OfferCloneComponent implements OnInit, OnDestroy {
  @Input() public source!: Offer;
  public offer!: Offer;
  public form!: UntypedFormGroup;
  public result!: { success: boolean; messages: string[]; result: number };
  @Input() public member!: Member;
  @Output() public cloned: EventEmitter<number> = new EventEmitter<number>();
  @Output() public cancelled: EventEmitter<any> = new EventEmitter<any>();

  public storageFilter: any = {};
  public clientFilter: any = {};
  public clientReset$ = new Subject<void>();
  public generic = false;
  public restos: Resto[] = [];
  public restoFilter: any = {};
  public selectedRestos: Resto[] = [];

  private _subscriptions$ = new Subject<void>();

  constructor(private _offers: OfferService,
              public storages$: StorageService,
              public clients$: ClientService,
              public restos$: RestoService) {
  }

  public ngOnDestroy(): void {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  public ngOnInit(): void {
    const data = this.source.toJSON();
    delete data.id;
    this._offers.create(data).subscribe(model => {
      this.offer = model;
      this.form = new UntypedFormGroup({
        storage: new UntypedFormControl(),
        client: new UntypedFormControl()
      });
    });
    // Changement de dépôt
    this.form.get("storage").valueChanges
      .pipe(takeUntil(this._subscriptions$))
      .subscribe((change) => this.onStorageChange(change));
    // Changement de client
    this.form.get("client").valueChanges
      .pipe(takeUntil(this._subscriptions$))
      .subscribe((change) => this.onClientChange(change));

    this.form.get("storage").setValue(this.source.storage);
    this.form.get("client").setValue(this.source.client);

    if (this.member) {
      this.restoFilter = {member: this.member.id};
      this.storageFilter = {member: this.member.id};
      this.clientFilter = {member: this.member.id};
    }
  }

  public setDateRange(range: { from: NgbDate, to: NgbDate }): void {
    this.offer.datestart = new Date(Date.UTC(range.from.year, range.from.month - 1, range.from.day, 6, 0, 0));
    this.offer.dateend = new Date(Date.UTC(range.to.year, range.to.month - 1, range.to.day, 21, 0, 0));
  }

  // Modification storage (=> modification client et restos)
  public onStorageChange(newStorage: number): void {
    this.clientFilter.storage = newStorage;
    if (this.offer.storage !== newStorage) {
      this.form.get("client").setValue(undefined);
      this.clientReset$.next();
      this.restoFilter.storage = newStorage;
      this.generic = true;
    }
  }

  // Modification client
  public onClientChange(newClient: number): void {
    this.restoFilter.client = newClient;
    this.restos = [];
    this.selectedRestos = [];
    if (newClient) {
      this.generic = false;
    } else {
      this.generic = true;
    }
  }


  public allRestos(): void {
    this.selectedRestos = [];
    for (const r of this.restos) {
      this.selectedRestos.push(r);
    }

  }


  public makeGeneric(): void {
    this.onClientChange(undefined);
    this.clone();
  }

  public clone() {
    if (this.form.valid) {
      const data = this.form.value;
      if (this.member) {
        data.member = this.member.id;
      } else if (this.offer.member) {
        data.member = this.member;
      }
      data.status = "PRP";
      data.datestart = this.offer.datestart;
      data.dateend = this.offer.dateend;
      if (this.generic) {
        delete data.client;
      } else {
        data.restos = map(this.selectedRestos, "id");
      }
      this.source.action("clone", {
        method: "POST",
        body: data
      }).subscribe(result => {
        this.result = result;
      });
    }
  }

  public cancel() {
    this.cancelled.emit(true);
  }

}
