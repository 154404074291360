
<ng-template #createModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Sélectionner un article [WIP]</h4>
    <button class="btn-close" (click)="modal.dismiss('x')" type="button"></button>
  </div>
  <div class="modal-body">
    <button class="btn btn-danger float-end" (click)="removeRelation()" *ngIf="cur">Supprimer</button>
    <h1>{{relation.src_details.name}}</h1>
    <div class="row mb-3">
      <div class="col-8"><span class="fw-bold fs-5 font-monospace">{{att.name}}</span><small class="ms-1">(ce tarif)</small>
        <div class="ms-4" *ngIf="cur"><span class="fw-bold fs-5 font-monospace"><i class="fa fa-arrow-up me-2"></i>{{cur.src_details.name}}</span><small class="ms-1">(dans {{relation.src_details.name}})</small></div>
      </div>
      <div class="col-4">
        <label>Opération</label>
        <input class="form-control" [formControl]="opctrl">
      </div>
    </div>
    <lvadg-articletariftemplate-list [tarifType]="relation.src_details" [filter]="{tarif_type: relation.src}" [name]="'att_relations_'+relation.id" [default_fields]="att_select_default_fields" [actions]="['select']" (selected)="onSelected($event)"></lvadg-articletariftemplate-list>
  </div>
</ng-template>
<div *ngIf="cur; else nomatch">
  <div role="button" (click)="setRelation()">{{cur.src_details?.name}}</div><code *ngIf="cur.ops">{{cur.ops}}</code>
</div>
<ng-template #nomatch>
  <div role="button" (click)="setRelation()">--</div>
</ng-template>