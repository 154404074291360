import {Component, Input} from "@angular/core";
import {ArticleFamily, ArticleFamilyService} from "../../../../lib/models/catalog/family/article-family.service";
import {firstValueFrom, Subject} from "rxjs";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonBaseComponent} from "../../common-base.component";
import {VIEW_DISPLAY} from "../../../view-constants";

export interface CatalogArticleFamilyTreeViewV3Params extends BaseRouteV3Params {
  articlefamily_detail_route: RouteConfigItemV3;
  display?: VIEW_DISPLAY;
}

export interface CatalogArticleFamilyTreeViewV3Data extends CatalogArticleFamilyTreeViewV3Params {

}


@Component({
  selector: "lvadg-vivalya-family-tree-view",
  templateUrl: "./catalog-family-tree.component.pug",
  styleUrls: ["./catalog-family-tree.component.sass"]
})
export class CatalogArticleFamilyTreeViewV3Component extends CommonBaseComponent {
  @Input() public parent!: ArticleFamily;
  public current!: number;
  public current$ = new Subject<number>();
  public opened: { [index: number]: boolean } = {};
  public articlefamily_detail_route!: RouteV3<ArticleFamily>;

  public families!: ArticleFamily[];

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute,
              private _fams: ArticleFamilyService) {
    super(routes, router, route);

  }

  public async preNgOnInit() {
    const data = this.route.snapshot.data as CatalogArticleFamilyTreeViewV3Data;
    this.articlefamily_detail_route = new RouteV3<ArticleFamily>(data.articlefamily_detail_route, this);

    if (!this.parent) {
      this.families = (await firstValueFrom(this._fams.queryset.query({level: 0})
        .sort([{field: "name", direction: "asc"}])
        .get())).items;
    } else {
      this.families = (await firstValueFrom(this._fams.queryset.query({parent: this.parent.id})
        .sort([{field: "name", direction: "asc"}])
        .get())).items;
    }
  }

  public openclose(f: ArticleFamily) {
    this.opened[f.id] = !this.opened[f.id];
    if (f.id !== this.current) {
      this.current = f.id;
      this.current$.next(f.id);
      this.router.navigate(this.articlefamily_detail_route.route({articleFamily: f}));
    }
  }


}
