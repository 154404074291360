import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Tarif} from "../tarif/tarif.service";
import {ExternalArticle} from "../../catalog/externalarticle/externalarticle.service";
import {Article} from "../../catalog/article/article.service";


export class TarifExternalMap extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "tarif",
    verboseName: "Tarif",
    required: false,
    helpText: "Tarif",
    relatedModel: "tarifs.Tarif",
    remoteField: "id",
    relatedName: "tarifexternalmaps"
  })
  public tarif!: number;
  public tarif_details!: Tarif;

  @DSIntegerField({
    name: "line",
    verboseName: "Ligne",
    required: false,
    helpText: "Ligne",
    default: 0,
    choices: []
  })
  public line!: number;

  @DSDecimalField({
    name: "price",
    verboseName: "Prix",
    required: false,
    helpText: "Prix",
    default: 0,
    factor: 1000,
    choices: []
  })
  public price!: number;

  @DSForeignKeyField({
    name: "external",
    verboseName: "Catalogue externe",
    required: false,
    helpText: "Catalogue externe",
    relatedModel: "catalog.ExternalArticle",
    remoteField: "id",
    relatedName: "tarifexternalmaps"
  })
  public external!: number;
  public external_details!: ExternalArticle;


  @DSForeignKeyField({
    name: "article",
    verboseName: "Article catalogue",
    required: false,
    helpText: "Article catalogue",
    relatedModel: "catalog.Article",
    remoteField: "id",
    relatedName: "tarifexternalmaps"
  })
  public article!: number;
  public article_details!: Article;

  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class TarifExternalMapService extends DSRestCollection<TarifExternalMap> {
  public adapter_config = {basePath: "/api/v2/tarifs/tarifexternalmaps"};
  public model = TarifExternalMap;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
