
<lvadg-dynamic-breadcrumb></lvadg-dynamic-breadcrumb>
<h1 class="page-header">
  <button class="btn btn-danger float-end" (dblclick)="remove=!remove"><i class="fa fa-trash fa-s"></i><span class="hidden-sm">Supprimer</span></button><i class="fa fa-building me-2"></i>{{provider?.name}}<small>détails</small>
</h1>
<lvadg-provider-delete *ngIf="remove" [provider]="provider" (removed)="removed()" (cancelled)="remove=false"></lvadg-provider-delete>
<nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
  <ng-container ngbNavItem="manage"><a ngbNavLink>Fournisseur référencé</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-provider-manage [provider]="provider"></lvadg-provider-manage>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="documents"><a ngbNavLink>Documents fournisseur</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-detaildocument-list name="admin-basedocument-list" [default_fields]="documents_default_fields" [default_filters]="documents_default_filters" [filter]="{provider: provider.id}"></lvadg-detaildocument-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="articles"><a ngbNavLink>Articles fournisseur</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-article-list [provider]="provider" [provider_detail_route]="provider_detail_route" [article_detail_route]="article_detail_route" mode="admin" type="provider.article" [default_fields]="[&quot;id&quot;,&quot;vvid&quot;, &quot;seasons&quot;, &quot;family&quot;, &quot;name&quot;, &quot;libelle&quot;, &quot;egalim&quot;, &quot;isEgalim&quot;]" [filter]="{fournisseur: provider.id}"></lvadg-article-list>
      </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav"></div>