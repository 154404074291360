import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Offer} from "../offer.service";
import {Resto, RestoService} from "../../../structure/resto/resto.service";
import {first} from "rxjs/operators";

@Component({
  selector: "lvadg-offer-manage-restos",
  templateUrl: "./offer-manage-restos.component.pug",
  styleUrls: ["./offer-manage-restos.component.sass"]
})
export class OfferManageRestosComponent implements OnInit {
  @Input() public offer!: Offer;
  @Output() public changed = new EventEmitter<void>();
  public available: Resto[] = [];
  public curFilter = "";

  constructor(public _restos: RestoService) {
  }

  public ngOnInit() {
    this._restos.queryset
      .query({cclient: this.offer.client, storage: this.offer.storage})
      .sort([{field: "name", direction: "asc"}])
      .paginate({page_size: 1000})
      .get().pipe(
      first()
    ).subscribe((results) => {
      this.available = results.items;
    });
  }

  public selected(r: Resto): boolean {
    return this.offer.restos.indexOf(r.id) !== -1;
  }

  public async toggleResto(resto: Resto): Promise<void> {
    if (this.selected(resto)) {
      await this.removeResto(resto.id);
    } else {
      await this.addResto(resto);
    }
  }

  public filter(resto: Resto) {
    if (!this.curFilter) {
      return true;
    }
    return resto.name.toLowerCase().search(this.curFilter.toLowerCase()) !== -1;
  }

  public async addResto(resto: Resto): Promise<void> {
    await this.offer.action("add_resto", {method: "POST", body: {resto: resto.id}}).toPromise();
    await this.offer.refresh().toPromise();
    this.changed.emit();
  }

  public async removeResto(restoId: number): Promise<void> {
    await this.offer.action("remove_resto", {method: "POST", body: {resto: restoId}}).toPromise();
    await this.offer.refresh().toPromise();
    this.changed.emit();
  }

  public async removeRestos(): Promise<void> {
    await this.offer.action("remove_resto", {method: "POST", body: {all: 1}}).toPromise();
    await this.offer.refresh().toPromise();
    this.changed.emit();
  }

  public async addAllRestos(): Promise<void> {
    await this.offer.action("add_resto", {method: "POST", body: {all: 1}}).toPromise();
    await this.offer.refresh().toPromise();
    this.changed.emit();
  }
}
