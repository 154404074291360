import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleCalibre, ArticleCalibreService} from "../article-calibre.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";
import {ArticleFamilyService} from "../../family/article-family.service";

@Component({
  selector: "lvadg-article-calibres-manage",
  templateUrl: "./article-calibres-manage.component.pug",
  styleUrls: ["./article-calibres-manage.component.sass"]
})
export class ArticleCalibresManageComponent extends ArticleDataManageBaseComponent<ArticleCalibre, ArticleCalibreService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleCalibreService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute,
              public families$: ArticleFamilyService
  ) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "calibreId";
    this.filterField = "calibre";
  }
}
