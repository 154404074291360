
<bsac-breadcrumb></bsac-breadcrumb>
<button class="btn btn-primary float-end" (click)="create=!create"><i class="fa fa-plus me-2"></i>Créer une donnée</button>
<h1 class="page-header"><i class="fa fa-text me-2"></i>Données de fiches techniques</h1>
<div class="card" *ngIf="create">
  <div class="card-header"><i class="fa fa-plus me-2"></i>Ajouter une donnée</div>
  <div class="card-body">
    <lvadg-fichesdata-create (created)="onCreated($event)"></lvadg-fichesdata-create>
  </div>
</div>
<lvadg-fichesdata-list *ngIf="ready"></lvadg-fichesdata-list>