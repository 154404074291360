import {NgModule} from "@angular/core";
import {CustomDataKeyChoiceListComponent} from "./customdatakeychoice-list/customdatakeychoice-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {CustomDataKeyChoiceCreateComponent} from "./customdatakeychoice-create/customdatakeychoice-create.component";
import {CustomdatakeychoiceManageComponent} from "./customdatakeychoice-manage/customdatakeychoice-manage.component";

@NgModule({
  declarations: [
    CustomDataKeyChoiceListComponent,
    CustomDataKeyChoiceCreateComponent,
    CustomdatakeychoiceManageComponent
  ],
  exports: [
    CustomDataKeyChoiceListComponent,
    CustomDataKeyChoiceCreateComponent,
    CustomdatakeychoiceManageComponent
  ],

  imports: [
    ...MODELLIST_IMPORTS,
  ]
})
export class CustomDataKeyChoiceModule {
}
