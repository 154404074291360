import {Component, Input, OnInit} from "@angular/core";
import {BsacModelList} from "@solidev/bsadmincomponents";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: "lvadg-modellist-admin",
  templateUrl: "./modellist-admin.component.pug",
  styleUrls: ["./modellist-admin.component.sass"]
})
export class ModellistAdminComponent implements OnInit {
  @Input() public list!: BsacModelList<any>;
  @Input() public mode = "";
  public display = false;

  constructor(private _route: ActivatedRoute) {

  }

  ngOnInit() {
    this._route.fragment.subscribe((frag: string) => {
      if (frag === "modellistadmin") {
        this.display = true;
      } else {
        this.display = false;
      }
    });
  }

}
