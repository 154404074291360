
<ng-container *ngIf="display=='view'">
  <bsac-breadcrumb></bsac-breadcrumb>
  <button class="btn btn-primary float-end" (click)="create=!create">Ajouter une collection</button>
  <h1 class="page-header"><i class="fa fa-book-open me-2"></i>Collection d'articles</h1>
</ng-container>
<div class="card" *ngIf="create">
  <div class="card-header"><i class="fa fa-plus me-2"></i>Créer une collection</div>
  <div class="card-body">
    <lvadg-externalarticlecollection-create (created)="router.navigate(externalarticlecollection_detail_route.route({externalArticleCollection: $event}))"></lvadg-externalarticlecollection-create>
  </div>
</div>
<lvadg-externalarticlecollection-list *ngIf="ready" [name]="currentRoute.name" [externalarticlecollection_detail_route]="externalarticlecollection_detail_route" [storage_detail_route]="storage_detail_route"></lvadg-externalarticlecollection-list>