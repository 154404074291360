
<ng-template #addcontact let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Ajouter un contact<small *ngIf="destination.resto"><br/> {{destination.resto_details?.name}}</small></h4>
    <button class="btn-close" type="button" aria-label="close" (click)="modal.dismiss('x')"></button>
  </div>
  <div class="modal-body">
    <ul class="list-group mb-4">
      <li class="list-group-item list-group-item-action pointer" *ngIf="resto" (click)="addRestoContact()">Ajouter un contact destinataire "restaurant"<small class="text-secondary d-none d-lg-block">Il recevra toutes les offres destinées à ce restaurant</small><small class="text-secondary d-none d-lg-block">Sélectionné pour les prochains envois</small></li>
      <li class="list-group-item list-group-item-action pointer" *ngIf="!resto &amp;&amp; member" (click)="addMemberContact()">Ajouter un contact destinataire "adhérent"<small class="text-secondary d-none d-lg-block">Il recevra toutes les offres faites par l'adhérent</small><small class="text-secondary d-none d-lg-block">Sélectionné pour les prochains envois</small></li>
      <li class="list-group-item list-group-item-action pointer" *ngIf="!resto &amp;&amp; storage" (click)="addStorageContact()">Ajouter un contact destinataire "dépôt"<small class="text-secondary d-none d-lg-block">Il recevra toutes les offres faites par le dépôt</small><small class="text-secondary d-none d-lg-block">Sélectionné pour les prochains envois</small></li>
      <li class="list-group-item list-group-item-action pointer" *ngIf="!resto &amp;&amp; client" (click)="addClientContact()">Ajouter un contact destinataire "client" (pour ce dépôt)<small class="text-secondary d-none d-lg-block">Il recevra toutes les offres de ce dépôt destinées à ce client</small><small class="text-secondary d-none d-lg-block">Sélectionné pour les prochains envois</small></li>
      <li class="list-group-item list-group-item-action pointer" (click)="addTempoContact()">Ajouter un contact temporaire<small class="text-secondary d-none d-lg-block">Uniquement pour cet envoi</small></li>
      <!--li.list-group-item.list-group-item-action.pointer(-->
      <!--  (click)="searchPerson()") Rechercher un contact existant-->
      <!--  small.text-secondary.d-none.d-lg-block Parmi les utilisateurs du site lavieadugout-->
      <li class="list-group-item list-group-item-action pointer" *ngIf="destination.dmail==''" (click)="dropEmptyDestination()"><i class="fa fa-trash me-2"></i>Supprimer cette destination<small class="text-secondary d-none d-lg-block">Elle sera recréée (en mode "invalide") si c'est la dernière</small></li>
    </ul>
    <div *ngIf="mode=='createContact' &amp;&amp; contact">
      <h4>Edition d'un contact restaurant</h4>
      <p class="text-info">Le contact sera enregistré et automatiquement sélectionné pour les prochains envois</p>
      <lvadg-contact-manage-form [contact]="contact" (created)="onContact($event)"></lvadg-contact-manage-form>
    </div>
    <form [formGroup]="addTempoContactForm" *ngIf="mode=='tempoEmail'" (submit)="onTempoContact()">
      <h4>Ajout d'un contact temporaire</h4>
      <p class="text-info">Le contact ne sera pas enregistré pour un prochain envoi</p>
      <div class="form-group">
        <label>Adresse email</label>
        <input class="form-control" type="email" email formControlName="email">
      </div>
    </form>
  </div>
</ng-template>
<ng-template #editcontact let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Modifier un contact<small *ngIf="destination?.resto"><br/> {{destination.resto_details?.name}}</small></h4>
    <button class="btn-close" type="button" aria-label="close" (click)="modal.dismiss('x')"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="destination.contact">
      <ul class="list-group">
        <li class="list-group-item list-group-item-action pointer" (click)="editContact()"><i class="fa fa-pencil me-2"></i>Modifier le contact</li>
        <li class="list-group-item list-group-item-action pointer" (click)="deactivateSend(false)"><i class="fa fa-mail-bulk me-2"></i>Désactiver pour cet envoi</li>
        <li class="list-group-item list-group-item-action pointer" (click)="deactivateSend(true)"><i class="fa fa-mail-bulk me-2"></i>Désactiver pour cet envoi et les suivants</li>
        <li class="list-group-item list-group-item-action pointer" (click)="activateSend()"><i class="fa fa-mail-bulk me-2"></i>Réactiver l'envoi</li>
      </ul>
    </div>
    <div *ngIf="destination?.person">
      <ul class="list-group">
        <li class="list-group-item list-group-item-action pointer" (click)="dropDestination()"><i class="fa fa-trash me-2"></i>Supprimer ce destinataire</li>
      </ul>
    </div>
    <div *ngIf="destination?.email">
      <ul class="list-group">
        <li class="list-group-item list-group-item-action pointer" (click)="dropDestination()"><i class="fa fa-trash me-2"></i>Supprimer ce destinataire</li>
      </ul>
    </div>
    <div *ngIf="mode=='editContact' &amp;&amp; contact">
      <h4>Edition d'un contact</h4>
      <p class="text-info">Le contact sera enregistré et automatiquement sélectionné pour les prochains envois</p>
      <lvadg-contact-manage-form [contact]="contact" (updated)="onContact($event)" [resto]="$any(destination.resto_details)"></lvadg-contact-manage-form>
    </div>
  </div>
</ng-template><span *ngIf="destination?.dmail">
  <div class="pointer d-flex flex-row" (click)="openEdit()"><i class="fa fa-address-book fa-2x me-2" *ngIf="destination.contact"></i><i class="fa fa-user fa-2x me-2" *ngIf="destination.person"></i><i class="fa fa-recycle fa-2x me-2" *ngIf="destination.email"></i>
    <div>
      <div>{{destination.dfirstname}} {{destination.dlastname}} &lt;{{destination.dmail}}&gt;</div>
      <div *ngIf="destination.contact_details">
        <div *ngIf="destination.contact_details.resto">Restaurant : {{destination.contact_details.resto_details?.name}}</div>
        <div *ngIf="destination.contact_details.storage">Dépôt : {{destination.contact_details.storage_details?.name}}</div>
        <div *ngIf="destination.contact_details.member">Adhérent : {{destination.contact_details.member_details?.name}}</div>
        <div *ngIf="destination.contact_details.client">Client : {{destination.contact_details.client_details?.name}}</div>
      </div>
    </div>
  </div></span><span *ngIf="!destination?.dmail">
  <div class="pointer" (click)="openAdd()">Ajouter contact</div></span>