import {Directive, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {IFrontMode, IFrontZone} from "./constants";
import {Member} from "../lib/models/structure/member/member.service";
import {Client} from "../lib/models/structure/client/client.service";
import {Resto} from "../lib/models/structure/resto/resto.service";
import {Storage} from "../lib/models/structure/storage/storage.service";
import {Subject} from "rxjs";
import {RoutesService} from "../lib/comps/routes.service";
import {Provider} from "../lib/models/structure/provider/provider.service";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class FrontBaseComponent implements OnInit, OnDestroy {
  public zone!: IFrontZone;
  public mode!: IFrontMode;
  public member!: Member;
  public client!: Client;
  public resto!: Resto;
  public storage!: Storage;
  public provider!: Provider;
  public ready = false;
  protected _subscriptions = new Subject<void>();

  constructor(protected _router: Router,
              protected _route: ActivatedRoute,
              public routes: RoutesService) {
  }

  public async ngOnInit(): Promise<void> {
    await this.preNgOnInit();

    // Load all current data from route
    this.zone = this._route.snapshot.data.zone;
    this.mode = this._route.snapshot.data.mode;
    this.member = this._route.snapshot.data.currentMember;
    this.client = this._route.snapshot.data.currentClient;
    this.resto = this._route.snapshot.data.currentResto;
    this.storage = this._route.snapshot.data.currentStorage;

    await this.postNgOnInit();
    this.ready = true;
  }

  public async ngOnDestroy(): Promise<void> {
    await this.preNgOnDestroy();
    this._subscriptions.next();
    this._subscriptions.complete();
    await this.postNgOnDestroy();
  }

  public async preNgOnInit(): Promise<void> {

  }

  public async postNgOnInit(): Promise<void> {

  }

  public async preNgOnDestroy(): Promise<void> {

  }

  public async postNgOnDestroy(): Promise<void> {

  }

  public checkMode(...args: string[]): boolean {
    return args.indexOf(this.mode) !== -1;
  }



}
