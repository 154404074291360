import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Tarif, TarifStatuses} from "../tarif.service";
import {firstValueFrom} from "rxjs";
import {TarifLog, TarifLogService} from "../../tariflog/tariflog.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {first} from "rxjs/operators";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {LifeCycles} from "../../tariftype/tariftype.service";

interface IStep {
  main: string;
  subs: string[];
}

interface ISteps {
  [index: string]: IStep[];
}


const TSTEPS: ISteps = {
  GENERIC: [
    {main: "PRP", subs: ["edt", "exp", "val"]},
    {main: "VAL", subs: ["snd", "imp", "pub"]},
    {main: "PUB", subs: ["msg", "pub", "pdone"]},
    {main: "UPD1", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD2", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD3", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD4", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD5", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD6", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD7", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD8", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD9", subs: ["prp", "msg", "pub", "pdone"]},
  ],
  GENERICA: [
    {main: "PRP", subs: ["edt", "exp", "val"]},
    {main: "VAL", subs: ["snd", "imp", "pub"]},
    {main: "PUB", subs: ["msg", "pub", "pdone"]},
    {main: "UPD1", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD2", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD3", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD4", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD5", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD6", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD7", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD8", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD9", subs: ["prp", "msg", "pub", "pdone"]},
  ],
  GENNOVAL: [
    {main: "PRP", subs: ["edt", "exp", "val"]},
    {main: "PUB", subs: ["msg", "pub", "pdone"]},
    {main: "UPD1", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD2", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD3", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD4", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD5", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD6", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD7", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD8", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD9", subs: ["prp", "msg", "pub", "pdone"]},
  ],
  GENNOVALA: [
    {main: "PRP", subs: ["edt", "exp", "val"]},
    {main: "PUB", subs: ["msg", "pub", "pdone"]},
    {main: "UPD1", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD2", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD3", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD4", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD5", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD6", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD7", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD8", subs: ["prp", "msg", "pub", "pdone"]},
    {main: "UPD9", subs: ["prp", "msg", "pub", "pdone"]},
  ],
};

const TSTATUSES: [string, TarifStatuses][] = [
  ["PRP-edt", TarifStatuses.Preparation],
  ["PRP-val", TarifStatuses.ToValidate],
  ["VAL-pub", TarifStatuses.Valid],
  ["PUB-pdone", TarifStatuses.Published],
];

@Component({
  selector: "lvadg-tarif-lifecycle",
  templateUrl: "./tarif-lifecycle.component.pug",
  styleUrls: ["./tarif-lifecycle.component.sass"]
})
export class TarifLifecycleComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Output() public action = new EventEmitter<string>();
  public waiting = {tarifPublish: false};
  public steps: IStep[] = TSTEPS.GENERIC; // activated step matrix
  public mainSteps!: string[];
  public stepsSequence: string[] = [];    // all steps sequence
  public stepsAssoc: { [index: string]: string[] } = {}; // steps by status
  public updateSteps: string[] = [];  // update special steps
  public currentStep: { main: string; sub: string }; // current step
  public subDisplays: { [index: string]: "done" | "current" | "futur" }; // step display types
  public mainDisplays: { [index: string]: "done" | "current" | "futur" }; // status display types
  public stepLogItems!: { [index: string]: TarifLog | null };
  public tlqs!: DSRestQueryset<TarifLog>;
  public tariflogs: TarifLog[] = [];
  public collapsed: { [index: string]: boolean } = {};
  public LifeCycles = LifeCycles;

  constructor(private tl: TarifLogService,
              private _msgs: BsacMessageService) {
  }

  public ngOnInit(): void {
    // Get valid steps for this tarif
    this.steps = TSTEPS[this.tarif.type_details.lifecycle];
    this.mainSteps = [];
    // Process step matrix
    this.tlqs = this.tl.queryset;
    this.tlqs.query({tarif: this.tarif.id});
    this.tlqs.results.subscribe((tls) => {
      this.tariflogs = tls.items;
      this.stepLogItems = {};
      for (const s of this.stepsSequence) {
        this.stepLogItems[s] = null;
        for (const l of this.tariflogs) {
          if (l.step === s) {
            this.stepLogItems[s] = l;
            break;
          }
        }
      }
    });
    for (const st of this.steps) {
      this.stepsAssoc[st.main] = st.subs;
      this.mainSteps.push(st.main);
    }
    if (!this.stepsAssoc[this.tarif.step_main]) {
      console.error("Unkown main step", this.tarif.step);
      this.tarif.step = "PRP-" + this.stepsAssoc["PRP"][0];
    }
    if (this.stepsAssoc[this.tarif.step_main].indexOf(this.tarif.step_sub) === -1) {
      console.warn("Unkwnon step", this.tarif.step);
      this.tarif.step = "PRP-" + this.stepsAssoc["PRP"][0];
    }
    this.updateStatuses();
    this.tlqs.get().pipe(first()).subscribe(() => {
    });
  }

  public async updateStatuses() {
    this.currentStep = {main: this.tarif.step_main, sub: this.tarif.step_sub};
    this.subDisplays = {};
    this.mainDisplays = {};
    this.stepsSequence = [];
    this.updateSteps = [];
    if (this.tarif.step_main.startsWith("UPD")) {
      let done = false;
      for (let i = 1; i <= 9; i++) {
        const st = `UPD${i}`;
        if (!done) {
          this.updateSteps.push(st);
        }
        if (this.tarif.step_main === st) {
          done = true;
        }
      }
    }
    let found = false;
    let stFound = false;
    for (const s of this.steps) {
      for (const st of s.subs) {
        const stp = `${s.main}-${st}`;
        if (this.tarif.step === stp) {
          found = true;
          this.subDisplays[stp] = "current";
        } else {
          this.subDisplays[stp] = (found ? "futur" : "done");
        }
        this.stepsSequence.push(stp);
      }
      if (!found) {
        this.mainDisplays[s.main] = "done";
      } else if (found && !stFound) {
        this.mainDisplays[s.main] = "current";
        stFound = true;
      } else {
        this.mainDisplays[s.main] = "futur";
      }
    }
    await this.checkTarifStatus();
  }

  public async checkStep(status: string, step: string) {
    const stp = `${status}-${step}`;
    try {
      this.tarif.step = this.stepsSequence[this.stepsSequence.indexOf(stp) + 1];
      await firstValueFrom(this.tarif.update(["step"]));
    } catch (e) {
      console.log(e);
    }
    await firstValueFrom(this.tlqs.get());
    await this.updateStatuses();
  }

  public async setStep(main: string, sub: string) {
    const nstp = `${main}-${sub}`;
    const cstp = `${this.currentStep.main}-${this.currentStep.sub}`;
    if (this.stepsSequence.indexOf(cstp) < this.stepsSequence.indexOf(nstp) || main !== this.currentStep.main) {
      this._msgs.warning("Transition impossible", "Vous ne pouvez que revenir en arrière, dans le cycle courant", {}, 1000);
      return;
    }
    this.tarif.step = nstp;
    await firstValueFrom(this.tarif.update(["step"]));
    await firstValueFrom(this.tlqs.get());
    await this.updateStatuses();
  }

  public getCardClasses(main: string): string[] {
    if (this.mainDisplays[main] === "done") {
      return ["border-success"];
    } else if (this.mainDisplays[main] === "current") {
      return ["border-primary"];
    } else {
      return ["bg-secondary", "text-muted"];
    }
  }

  public getCardHeaderClasses(main: string): string[] {
    if (this.mainDisplays[main] === "done") {
      return ["bg-success", "text-white"];
    } else if (this.mainDisplays[main] === "current") {
      return ["bg-primary", "text-white"];
    } else {
      return ["bg-secondary", "text-muted"];
    }
  }

  public getStepClasses(main: string, sub: string): string[] {
    const st = `${main}-${sub}`;
    if (this.subDisplays[st] === "done") {
      return ["list-group-item-success"];
    } else if (this.subDisplays[st] === "current") {
      return ["list-group-item-action", "list-group-item-primary"];
    } else {
      return ["list-group-item-action", "list-group-item-secondary"];
    }
  }

  public getCheckClasses(main: string, sub: string): string[] {
    const st = `${main}-${sub}`;
    if (this.subDisplays[st] === "done") {
      return ["text-success"];
    } else if (this.subDisplays[st] === "current") {
      return ["text-muted"];
    } else {
      return ["text-muted"];
    }
  }

  public getStepLogItem(main: string, sub: string): TarifLog | null {
    if (!this.stepLogItems) {
      return null;
    }
    const d = this.stepLogItems[`${main}-${sub}`];
    if (d === null) {
      return null;
    }
    return d;
  }

  public async tarifPublish(main: string, sub: string) {
    interface PublishResponse {
      published: boolean;
      notif?: number;
      message?: string;
    }

    this.waiting.tarifPublish = true;
    try {
      const res: PublishResponse = await firstValueFrom(this.tarif.action("publish", {
        method: "POST",
        body: {
          type: main
        }
      }));
      if (!res.published) {
        this._msgs.error("Erreur lors de la publication", res.message);

      } else if (!res.notif) {
        this._msgs.warning("Tarif publié sans notification", res.message);
      }
    } catch (e) {
      console.error(e);
      this._msgs.error("Erreur lors de la publication", `${e}`);
    }
    await this.checkStep(main, sub);
    this.waiting.tarifPublish = false;
  }

  public async checkTarifStatus(): Promise<void> {
    const stp = `${this.currentStep.main}-${this.currentStep.sub}`;
    const stppos = this.stepsSequence.indexOf(stp);
    for (const st of TSTATUSES) {
      if (this.stepsSequence.indexOf(st[0]) >= stppos) {
        if (this.tarif.status !== st[1]) {
          if (["AR", "OK"].indexOf(this.tarif.status) !== -1) {
            this._msgs.warning(`Pas de changement d'état du tarif ${this.tarif.status} -> ${st[1]}`);
          } else {
            this._msgs.info(`Changement d'état du tarif : ${this.tarif.status} -> ${st[1]}`);
            this.tarif.status = st[1];
            await firstValueFrom(this.tarif.update(["status"]));
          }
        }
        break;
      }
    }
  }


}
