<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producers'; desc: 'Producteur(s)'; group: 'link'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="producers" [queryset]="producers$" descfield="name" hint="Sélectionnez un producteur" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storages'; desc: 'Dépôt(s)'; group: 'link'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storages" [queryset]="storages$" descfield="name" hint="Sélectionnez un dépôt" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'members'; desc: 'Adhérent(s)'; group: 'link'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="members" [queryset]="members$" descfield="name" hint="Sélectionnez un adhérent" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'restos'; desc: 'Restaurant(s)'; group: 'link'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="restos" [queryset]="restos$" descfield="name" hint="Sélectionnez un restaurant" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'rdistance'; desc: 'Distance dépôt/producteur (en km)'; group: 'storage'">
        <bsac-model-list-value-filter [list]="list" field="rdistance"></bsac-model-list-value-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producernear'; desc: 'Producteur proche de'; group: 'producer'">
        <bsac-model-list-geodistance-filter [list]="list" field="producernear"></bsac-model-list-geodistance-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagenear'; desc: 'Dépôt proche de'; group: 'storage'">
        <bsac-model-list-geodistance-filter [list]="list" field="storagenear"></bsac-model-list-geodistance-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'seasons'; desc: 'Saisonnalité (au moins une)'; group: 'properties'">
        <bsac-model-list-multiselect-filter [list]="list" field="seasons" [choices]="seasons" descfield="name"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'allseasons'; desc: 'Saisonnalité (toutes)'; group: 'properties'">
        <bsac-model-list-multiselect-filter [list]="list" field="allseasons" [choices]="seasons" descfield="name"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'families'; desc: 'Famille &amp; sous-famille'; group: 'properties'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="families" [queryset]="families1$" descfield="cfullname" hint="Sélectionnez une famille" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'exactfamilies'; desc: 'Famille (exact)'; group: 'properties'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="exactfamilies" [queryset]="families2$" descfield="cfullname" hint="Sélectionnez une famille" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagestatus'; desc: 'Etat de dépôt (au moins un)'; group: 'storage'">
        <bsac-model-list-select-filter [list]="list" field="storagestatus" [choices]="'PRODUCTSTORAGE_STATUSES'"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producergeoregions'; desc: 'Région du producteur'; group: 'producer'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="producergeoregions" [queryset]="regions$" descfield="fullname" hint="Sélectionnez une région" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producergeodepartements'; desc: 'Département du producteur'; group: 'producer'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="producergeodepartements" [queryset]="departements$" descfield="fullname" hint="Sélectionnez un département" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producergeocommunes'; desc: 'Commune du producteur'; group: 'producer'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="producergeocommunes" [queryset]="communes$" descfield="fullname" hint="Sélectionnez une commune" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeoregions'; desc: 'Région du dépôt'; group: 'storage'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeoregions" [queryset]="regions$" descfield="fullname" hint="Sélectionnez une région" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeodepartements'; desc: 'Département du dépôt'; group: 'storage'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeodepartements" [queryset]="departements$" descfield="fullname" hint="Sélectionnez un département" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeocommunes'; desc: 'Commune du dépôt'; group: 'storage'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeocommunes" [queryset]="communes$" descfield="fullname" hint="Sélectionnez une commune" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'labels'; desc: 'Label'; group: 'properties'">
        <bsac-model-list-multiselect-filter [list]="list" field="labels" [choices]="labels"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'egalim'; desc: 'Egalim'; group: 'properties'">
        <bsac-model-list-select-filter [list]="list" field="egalim" [choices]="EGALIM_STATUSES"></bsac-model-list-select-filter>
      </div>
      <lvadg-customdata-modellist-filters [cdatakeys]="cdatakeys" [list]="list" group="properties"></lvadg-customdata-modellist-filters>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'link'; desc: 'Recherche par lien'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'storage'; desc: 'Recherche par dépôt'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'producer'; desc: 'Recherche par producteur'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'properties'; desc: 'Recherche par caractéristiques'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <lvadg-modellist-xlsx-export [list]="list" [mode]="mode"></lvadg-modellist-xlsx-export>
    <button class="btn btn-link" (click)="resultsMode='map'" *ngIf="resultsMode=='list'">Afficher sur une carte</button>
    <button class="btn btn-link" (click)="resultsMode='list'" *ngIf="resultsMode=='map'">Afficher la liste</button>
    <ng-container *ngIf="checkAction('select')">
      <lvadg-selection-summary stype="product" unit="produit(s)" (action)="onAction($event)" [actions]="select_actions" [custom_actions]="select_custom_actions"></lvadg-selection-summary>
    </ng-container>
  </div>
  <div class="list-results">
    <ng-container *ngIf="resultsMode=='map'">
      <lvadg-locations-osm-display zone="sea" [seaproducers]="mapProducers" [seastorages]="$any(mapStorages)" [restos]="mapRestos" height="700px"></lvadg-locations-osm-display>
    </ng-container>
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="select">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container *ngIf="checkAction('select')">
                <lvadg-selection-selitem [items]="results" stype="product"></lvadg-selection-selitem>
              </ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkAction('select')">
                <lvadg-selection-selitem [item]="row" stype="product"></lvadg-selection-selitem>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="storages">
            <th cdk-header-cell *cdkHeaderCellDef>IDS Dépôts</th>
            <td cdk-cell *cdkCellDef="let row"><span *ngIf="row.storages">{{ row.storages?.length }}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="content_type">
            <th cdk-header-cell *cdkHeaderCellDef>Format description</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_type}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content_src">
            <th cdk-header-cell *cdkHeaderCellDef>Description</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_src}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content">
            <th cdk-header-cell *cdkHeaderCellDef>Description (html)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ccontent">
            <th cdk-header-cell *cdkHeaderCellDef>Description famille (html)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.ccontent}}</td>
          </ng-container>
          <ng-container cdkColumnDef="family">
            <th cdk-header-cell *cdkHeaderCellDef>Famille</th>
            <td cdk-cell *cdkCellDef="let row">{{row.family}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producer">
            <th cdk-header-cell *cdkHeaderCellDef>ID Producteur</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="routes.detail(zone, 'producers', row.producer)">{{row.producer}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="producerlocation">
            <th cdk-header-cell *cdkHeaderCellDef>ID Localisation producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.producerlocation}}</td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="name">Nom</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkMode('resto', 'member', 'client', 'vivalya')">
                <div class="productname pointer" [routerLink]="routes.detail(zone, type, row)">
                  <div class="logo">
                    <lvadg-rimg [src]="row.logo_img" display="thumb48" *ngIf="row.logo_img"></lvadg-rimg>
                    <lvadg-rimg [src]="row.family_details?.clogo_img" display="thumb48" *ngIf="!row.logo_img"></lvadg-rimg>
                  </div>
                  <div class="desc">
                    <div class="name">{{row.name}}</div>
                    <div class="family">[{{row.family_details?.cfullname}}]</div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="checkMode('admin')"><i class="fa fa-pencil float-start me-2 pointer" (click)="editName[row.id]=!editName[row.id]" *ngIf="!editName[row.id]"></i></ng-container>
              <ng-container *ngIf="checkMode('admin', 'memberadmin')"><a [routerLink]="routes.detail(zone, type, row)">{{ row.name }}</a>
                <input class="form-control form-control-sm" *ngIf="editName[row.id]" [(ngModel)]="row.name" (keyup.enter)="saveName(row)" (keyup.escape)="editName[row.id]=false">
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="seasons">
            <th cdk-header-cell *cdkHeaderCellDef>
              <div class="pointer" (click)="changePerlineSeasons()">Saisons<i class="fa fa-line-columns ms-2 text-muted"></i></div>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-seasons-manage [item]="row" [seasons]="row.seasons" (changed)="updateSeasons(row, $event)" [editable]="checkMode('admin')" [perline]="seasons_perline" [zone]="zone"></lvadg-seasons-manage>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="cseasons">
            <th cdk-header-cell *cdkHeaderCellDef>Saisons (famille)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.cseasons}}</td>
          </ng-container>
          <ng-container cdkColumnDef="labels">
            <th cdk-header-cell *cdkHeaderCellDef>Labels</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkMode('admin')"><span class="label" *ngFor="let l of row.labels">
                  <lvadg-sealabel-display class="me-1 mb-1" [labelId]="l" mode="name"></lvadg-sealabel-display></span></ng-container>
              <ng-container *ngIf="!checkMode('admin')"><span class="label" *ngFor="let l of row.labels">
                  <lvadg-sealabel-display class="me-1 mb-1" [labelId]="l"></lvadg-sealabel-display></span></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="logo">
            <th cdk-header-cell *cdkHeaderCellDef>ID Logo</th>
            <td cdk-cell *cdkCellDef="let row">{{row.logo}}</td>
          </ng-container>
          <ng-container cdkColumnDef="images">
            <th cdk-header-cell *cdkHeaderCellDef>ID Images</th>
            <td cdk-cell *cdkCellDef="let row">{{row.images}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producerlocation_details">
            <th cdk-header-cell *cdkHeaderCellDef>Localisation producteur</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check fa-s" *ngIf="row.producer_details?.status == 'OK'"></i><i class="fa fa-crosshairs fa-s" *ngIf="row.producer_details?.status == 'KO'"></i><span>{{ row.producer_details?.mainlocation_details?.postcode }} {{ row.producer_details?.mainlocation_details?.city }}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="producer_details">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="producer__name">Producteur</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="producer" *ngIf="!routes.detail(zone, 'producers', row.producer)">
                <div class="name">{{ row.producer_details?.name }}</div>
                <div class="location">{{ row.producer_details?.mainlocation_details?.postcode }} {{ row.producer_details?.mainlocation_details?.city }}</div>
              </div>
              <div class="producer" *ngIf="routes.detail(zone, 'producers', row.producer)">
                <div class="name"><a [routerLink]="routes.detail(zone, 'producers', row.producer)">{{ row.producer_details?.name }}</a>
                  <div class="location">{{ row.producer_details?.mainlocation_details?.postcode }} {{ row.producer_details?.mainlocation_details?.city }}</div>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="producer_name">
            <th cdk-header-cell *cdkHeaderCellDef>Nom du producteur</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="producer" *ngIf="!routes.detail(zone, 'producers', row.producer)">
                <div class="name">{{ row.producer_details?.name }}</div>
              </div>
              <div class="producer" *ngIf="routes.detail(zone, 'producers', row.producer)">
                <div class="name"><a [routerLink]="routes.detail(zone, 'producers', row.producer)">{{ row.producer_details?.name }}</a></div>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="producer_postcode">
            <th cdk-header-cell *cdkHeaderCellDef>Code postal producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.producer_details?.mainlocation_details?.postcode}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producer_city">
            <th cdk-header-cell *cdkHeaderCellDef>Ville producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.producer_details?.mainlocation_details?.city}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producer_dptcode">
            <th cdk-header-cell *cdkHeaderCellDef>Code département producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.producer_details?.mainlocation_details?.geodepartement_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producer_dptname">
            <th cdk-header-cell *cdkHeaderCellDef>Nom département producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.producer_details?.mainlocation_details?.geodepartement_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="storages_details">
            <th cdk-header-cell *cdkHeaderCellDef><span>Localisation dépôt</span><i class="fa fa-filter text-muted ms-2" (click)="storagesDisplayMode='full'" *ngIf="storagesDisplayMode!='full'"></i><i class="fa fa-filter ms-2" (click)="storagesDisplayMode='sel'" *ngIf="storagesDisplayMode!='sel'"></i><i class="fa fa-ruler ms-2 pointer" *ngIf="showStorageDistance" (click)="showStorageDistance=false"></i><i class="fa fa-ruler text-muted pointer ms-2" *ngIf="!showStorageDistance" (click)="showStorageDistance=true"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let s of row.storages_details">
                <ng-container *ngIf="displayStorage(s.id)">
                  <ng-container *ngIf="checkMode('admin')"><i class="fa fa-check fa-s pointer" *ngIf="s.status == 'OK'" (click)="changeStatus(row, s.psid, 'TF')"></i><i class="fa fa-exclamation-triangle fa-s pointer" *ngIf="s.status == 'TF'" (click)="changeStatus(row, s.psid, 'KO')"></i><i class="fa fa-times fa-s pointer" *ngIf="s.status == 'KO'" (click)="changeStatus(row, s.psid, 'OK')"></i><b class="me-1"><a [routerLink]="routes.detail(zone, 'storages', s.id)">{{s.name}}</a></b>
                    <ng-container *ngIf="showStorageDistance"><span class="me-1">{{s.distance | distance}}</span><small>{{s.mainlocation_details?.postcode}} {{s.mainlocation_details?.city}}</small></ng-container>
                  </ng-container>
                  <ng-container *ngIf="checkMode('memberadmin', 'vivalya')"><i class="fa fa-check fa-s" *ngIf="s.status == 'OK'"></i><i class="fa fa-exclamation-triangle fa-s pointer" *ngIf="s.status == 'TF'"></i><i class="fa fa-times fa-s" *ngIf="s.status == 'KO'"></i><b class="me-1"><a [routerLink]="routes.detail(zone, 'storages', s.id)">{{s.name}}</a></b>
                    <ng-container *ngIf="showStorageDistance"><span class="me-1">{{s.distance | distance}}</span><small>{{s.mainlocation_details?.postcode}} {{s.mainlocation_details?.city}}</small></ng-container>
                  </ng-container>
                  <ng-container *ngIf="checkMode('member') &amp;&amp; s.status == 'OK' &amp;&amp; s.storage?.member === member?.id">
                    <ng-container *ngIf="!storage"><b class="me-1"><a [routerLink]="routes.detail(zone, 'storages', s.id)">{{s.name}}</a></b>
                      <ng-container *ngIf="showStorageDistance"><small>{{s.mainlocation_details?.postcode}} {{s.mainlocation_details?.city}} - {{s.distance | distance}}</small></ng-container>
                    </ng-container>
                    <ng-container *ngIf="storage &amp;&amp; storage.id == s.id">
                      <ng-container *ngIf="showStorageDistance"><span>{{s.distance | distance}}</span></ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="checkMode('client') &amp;&amp; s.status == 'OK'">
                    <ng-container *ngIf="!storage"><b class="me-1"><a [routerLink]="routes.detail(zone, 'storages', s.id)">{{s.name}}</a></b>
                      <ng-container *ngIf="showStorageDistance"><small>{{s.mainlocation_details?.postcode}} {{s.mainlocation_details?.city}} - {{s.distance | distance}}</small></ng-container>
                    </ng-container>
                    <ng-container *ngIf="storage &amp;&amp; storage.id == s.id">
                      <ng-container *ngIf="showStorageDistance"><span>{{s.distance | distance}}</span></ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="checkMode('resto') &amp;&amp; s.status == 'OK' &amp;&amp; storage &amp;&amp; s.id == storage.id"><b class="me-1"><a [routerLink]="routes.detail(zone, 'storages', s.id)">{{s.name}}</a></b>
                    <ng-container *ngIf="showStorageDistance"><small>{{s.mainlocation_details?.postcode}} {{s.mainlocation_details?.city}}<br/>distance producteur-dépôt : {{s.distance | distance}}</small></ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="producer_distance">
            <th cdk-header-cell *cdkHeaderCellDef>Distance dépôt/producteur</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let s of row.storages_details">
                <ng-container *ngIf="displayStorage(s.id)">
                  <ng-container *ngIf="checkMode('admin')">
                    <div>{{s.distance | distance}}</div>
                  </ng-container>
                  <ng-container *ngIf="checkMode('memberadmin', 'vivalya')">
                    <div>{{s.distance | distance}}</div>
                  </ng-container>
                  <ng-container *ngIf="checkMode('member') &amp;&amp; s.status == 'OK' &amp;&amp; s.member_details.id === member?.id">
                    <div>{{s.distance | distance}}</div>
                  </ng-container>
                  <ng-container *ngIf="checkMode('client') &amp;&amp; s.status == 'OK'">
                    <div>{{s.distance | distance}}</div>
                  </ng-container>
                  <ng-container *ngIf="checkMode('resto') &amp;&amp; s.status == 'OK' &amp;&amp; storage &amp;&amp; s.id == storage.id">
                    <div>{{s.distance | distance}}</div>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="logo_img">
            <th cdk-header-cell *cdkHeaderCellDef>Détails logo</th>
            <td cdk-cell *cdkCellDef="let row">{{row.logo_img}}</td>
          </ng-container>
          <ng-container cdkColumnDef="clogo_img">
            <th cdk-header-cell *cdkHeaderCellDef>Détails logo famille</th>
            <td cdk-cell *cdkCellDef="let row">{{row.clogo_img}}</td>
          </ng-container>
          <ng-container cdkColumnDef="images_details">
            <th cdk-header-cell *cdkHeaderCellDef>Détails images</th>
            <td cdk-cell *cdkCellDef="let row">{{row.images_details}}</td>
          </ng-container>
          <ng-container cdkColumnDef="offer_details">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="offers__order">Détails offre locale</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="offerdetails" *ngFor="let offdetails of row.offer_details"><span *ngIf="!checkMode('resto', 'client') &amp;&amp; offdetails.order !== 0"><b>Priorité : {{offdetails.order}}</b></span><span *ngIf="offdetails.comment"><b class="me-2">Commentaire :</b>{{offdetails.comment}}</span>
                <div class="offerdetailsdetails" *ngFor="let details of offdetails.details_details"><span class="ccode"><b>Code client</b> {{details.custccode || details.variant_details?.ccode || "NC"}}</span><span class="lname"><b>Désignation</b> {{details.custname || details.variant_details?.lname || "NC"}}</span>
                  <ng-container *ngIf="!checkMode('resto')"><span class="tarif"><b>Tarif</b> {{details.custprice/1000 || details.price_details?.price /1000 || "NC" }} / {{details.custrawprice/1000 || details.price_details?.raw_price /1000 || "" }}</span>
                    <div class="dinfos"><span class="caliber"><b>Calibre</b> {{details.custcaliber || details.variant_details?.caliber || "NC"}}</span><span class="udv"><b>UDV</b>&nbsp;
                        <ng-container *ngIf="details.custsalesunit"><span>{{details.custsalesunit}}</span></ng-container>
                        <ng-container *ngIf="!details.custsalesunit"><span>{{details.variant_details?.sales_value/1000}} {{details.variant_details?.sales_unit}}</span></ng-container></span><span class="udp"><b>UDP</b>&nbsp;
                        <ng-container *ngIf="details.custpackaging"><span>{{details.custpackaging}}</span></ng-container>
                        <ng-container *ngIf="!details.custpackaging"><span>{{details.variant_details?.packaging_value/1000}} {{details.variant_details?.packaging_unit}}</span></ng-container></span><span class="packing"><b>Packaging:</b> {{details.variant_details?.packing || "NC"}}</span></div>
                  </ng-container>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="labels_details">
            <th cdk-header-cell *cdkHeaderCellDef>Détails labels</th>
            <td cdk-cell *cdkCellDef="let row">{{row.labels_details}}</td>
          </ng-container>
          <ng-container cdkColumnDef="direct_distance">
            <th cdk-header-cell *cdkHeaderCellDef>Distance
              <div *ngIf="selectedAddress"><small>{{selectedAddress.name}}</small></div>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let d of resultsDistances[row.id]">
                <ng-container *ngIf="d.type=='SA' &amp;&amp; d.dist1">
                  <ng-container *ngIf="displayStorage(d.ref)"><span *ngIf="d.dist1.road&gt;=0">[{{d.desc}}] {{d.dist1.road|distance}}</span><span *ngIf="d.dist1.road&lt;0">[{{d.desc}}] ~{{d.dist1.direct|distance}}<i class="fa fa-spinner fa-spin ms-2"></i></span></ng-container>
                </ng-container>
                <ng-container *ngIf="d.type=='PA' &amp;&amp; d.dist1"><span *ngIf="d.dist1.road&gt;=0">[{{d.desc}}] {{d.dist1.road|distance}}</span><span *ngIf="d.dist1.road&lt;0">[{{d.desc}}] ~{{d.dist1.direct|distance}}<i class="fa fa-spinner fa-spin ms-2"></i></span></ng-container>
                <ng-container *ngIf="d.type=='PSA' &amp;&amp; d.dist1 &amp;&amp; d.dist2"><span *ngIf="d.dist1.road&lt;0 || d.dist2.road&lt;0">[{{d.desc}}] ~{{d.dist1.direct+d.dist2.direct|distance}}<i class="fa fa-spinner fa-spin ms-2"></i></span><span *ngIf="d.dist1.road&gt;=0 &amp;&amp; d.dist2.road &gt;=0">[{{d.desc}}] {{d.dist1.road + d.dist2.road|distance}}</span></ng-container>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="inoffer">
            <th cdk-header-cell *cdkHeaderCellDef>Dans l'offre locale ?</th>
            <td cdk-cell *cdkCellDef="let row">{{row.inoffer}}</td>
          </ng-container>
          <ng-container cdkColumnDef="family_details">
            <th cdk-header-cell *cdkHeaderCellDef>Détails catégorie</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="!checkMode('admin')">{{ row.family_details.cname }}<br/><small>{{ row.family_details?.cfullname }}</small></ng-container>
              <ng-container *ngIf="checkMode('admin')"><i class="fa fa-pencil float-start me-2 pointer" (click)="editFamily[row.id]=!editFamily[row.id]" *ngIf="!editFamily[row.id]"></i><a [routerLink]="routes.detail(zone, 'products.families', row.family)">{{ row.family_details.cname }}<br/><small>{{ row.family_details?.cfullname }}</small></a>
                <lvadg-seaproductfamily-select *ngIf="editFamily[row.id]" hint="Selectionner une famille" [level]="2" (selected)="changeFamily(row, $event)" (cancel)="editFamily[row.id] = false"></lvadg-seaproductfamily-select>
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngFor="let ck of cdatakeys">
            <ng-container [cdkColumnDef]="'customdata_'+ck.id">
              <th cdk-header-cell *cdkHeaderCellDef>{{ck.name}}</th>
              <td cdk-cell *cdkCellDef="let row">
                <lvadg-customdata-rowfield-display [mode]="mode" [editors]="['admin', 'memberadmin']" [model]="row" [dest]="ck.dest" [code]="ck.code" alt="cfcustomdata_details" [write]="'PRF'"></lvadg-customdata-rowfield-display>
              </td>
            </ng-container>
          </ng-container>
          <ng-container cdkColumnDef="actions">
            <th cdk-header-cell *cdkHeaderCellDef>Actions</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkAction('offer_add') &amp;&amp; !row.inoffer">
                <button class="btn btn-primary btn-sm ms-1 me-1 mt-1" (click)="action.emit({action: 'offer_add', product: row})"><i class="fa fa-plus"></i><span class="d-none d-xl-inline ms-2"> Offre locale</span></button>
              </ng-container>
              <ng-container *ngIf="checkAction('offer_manage') &amp;&amp; row.inoffer">
                <button class="btn btn-outline-success btn-sm ms-1 me-1 mt-1" (click)="action.emit({action: 'offer_manage', product: row})"><i class="fa fa-pencil"></i><span class="d-none d-xl-inline ms-2"> Edition</span></button>
              </ng-container>
              <ng-container *ngIf="checkAction('offer_remove') &amp;&amp; row.inoffer">
                <button class="btn btn-danger btn-sm ms-1 me-1 mt-1" (click)="action.emit({action: 'offer_remove', product: row})"><i class="fa fa-trash"></i><span class="d-none d-xl-inline ms-2">Supprimer</span></button>
              </ng-container>
              <ng-container *ngIf="checkAction('quickprintproduct')">
                <button class="btn btn-link btn-sm ms-1 me-1 mt-1" (click)="onAction({action: 'quickprintproduct', product: row})"><i class="fa fa-print me-2"></i></button>
              </ng-container>
              <ng-container *ngIf="checkAction('quickprintproductposter')">
                <button class="btn btn-link btn-sm ms-1 me-1 mt-1" (click)="onAction({action: 'quickprintproductposter', product: row})"><i class="fa fa-file-image me-2"></i></button>
              </ng-container>
            </td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>