import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DispStatsComponent} from "./disp-stats/disp-stats.component";
import {BsacApiConstantsModule} from "@solidev/bsadmincomponents";
import {SelectStatsComponent} from "./select-stats/select-stats.component";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {GraphStatsComponent} from "./graph-stats/graph-stats.component";
import {StatsSelectIvaluePipe} from "./stats-select.pipe";
import {ChartsModule} from "../../../comps/charts/charts.module";
import {CompsModule} from "../../../comps/comps.module";

@NgModule({
    imports: [
        CommonModule,
        BsacApiConstantsModule,
        NgbDropdownModule,
        ChartsModule,
        CompsModule
    ],
  declarations: [DispStatsComponent, SelectStatsComponent, GraphStatsComponent, StatsSelectIvaluePipe],
  exports: [DispStatsComponent, SelectStatsComponent, GraphStatsComponent, StatsSelectIvaluePipe]
})
export class StatsModule {
}
