import {GeoCommune, GeoCommuneService} from "../geocommune.service";
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {IDSQueryset} from "@solidev/ngdataservice";
import {Observable} from "rxjs";
import {debounceTime, map, switchMap} from "rxjs/operators";

@Component({
  selector: "lvadg-geocommune-select",
  templateUrl: "./geocommune-select.component.pug",
  styleUrls: ["./geocommune-select.component.sass"]
})
export class GeoCommuneSelectComponent {
  public queryset!: IDSQueryset<GeoCommune, any, any>;
  @Input() public hint = "Selectionnez une commune";
  @Input() public filter: any = {};
  @Input() public storage!: Storage;
  @Output() public selected: EventEmitter<GeoCommune> = new EventEmitter<GeoCommune>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();

  public search = (text$: Observable<string>): Observable<any> => {
    return text$.pipe(
      debounceTime(200),
      switchMap((term) => {
        const src = this.filter;
        src.search = term;
        this.queryset.query(src);
        return this.queryset.get().pipe(
          map((result) => {
          return result.items;
        }));
      })
    );
  };

  constructor(private _model: GeoCommuneService) {
    this.queryset = this._model.queryset;
  }

  public getName(x: any): string {
    return `${x.postcode} ${x.postname}`;
  }


  public selItem(model: any): void {
    this.selected.emit(model.item);
  }

}
