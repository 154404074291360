import {Pipe, PipeTransform} from "@angular/core";
import {SLabelCache, SLabelService} from "./slabel.service";

@Pipe({
  name: "slabelName",
  pure: false
})
export class SlabelNamePipe implements PipeTransform {
  private _cache: SLabelCache|null = null;

  constructor(private _slabels: SLabelService) {

  }

  transform(value: any, args?: any): any {
    if (this._cache === null) {
      this._cache = {};
      this._slabels.prefetch().subscribe((cache) => {
        this._cache = cache;
      });
    }
    if (this._cache[value]) {
      return this._cache[value].name;
    } else {
      return "???";
    }
  }

}
