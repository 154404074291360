import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {VivalyaBill, VivalyaBillService} from "../bill.service";
import {VivalyaBillItem, VivalyaBillItemService} from "../../billitem/billitem.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {debounceTime, switchMap, takeUntil, tap} from "rxjs/operators";
import {combineLatest, concat, Observable, of, Subject} from "rxjs";
import {assign} from "lodash-es";
import {DisplayinfosService} from "../../../../comps/displayinfos.service";
import {IFilterOutput} from "@solidev/bsadmincomponents";
import {prefixKeys} from "../../../../comps/utils";
import {endOfMonth, format, startOfMonth, sub} from "date-fns";
import {CategSeriesChartDataset} from "../../../../comps/charts/categ-series-chart/categ-series-chart.component";
import {TimeSeriesChartDataset} from "../../../../comps/charts/formats";

export interface IGraphData {
  count: CategSeriesChartDataset;
  amount: CategSeriesChartDataset;
  weight: CategSeriesChartDataset;
}

export interface IAmountTimeStats {
  [index: string]: TimeSeriesChartDataset;
}


export interface IProductTypeStats {
  [index: string]: IGraphData;
}


@Component({
  selector: "lvadg-billing-stats",
  templateUrl: "./billing-stats.component.pug",
  styleUrls: ["./billing-stats.component.sass"]
})
export class BillingStatsComponent implements OnInit, OnDestroy {
  @Input() public restoFilter: IFilterOutput = {};
  @Input() public storageFilter: IFilterOutput = {};
  @Input() public restoFilter$!: Observable<IFilterOutput>;
  @Input() public clientFilter$!: Observable<IFilterOutput>;
  @Input() public storageFilter$!: Observable<IFilterOutput>;
  public bills!: VivalyaBill[];
  public amountTimeStats!: IAmountTimeStats;
  public productTypeStats!: IProductTypeStats;
  public billDetails: { [index: number]: VivalyaBillItem[] } = {};
  public mainFilterForm!: UntypedFormGroup;
  public mainstat!: "bygamme" | "bynature" | "byfamily" | "bysubfamily" | "byorigin" | "bystorage" | "bycertificate" | "bylabel";
  public statvalue!: "weight" | "count" | "amount";
  public storages!: { id: number, name: string }[];
  public legendPosition: "bottom" | "right" = "right";
  public screenWidth: "xs" | "sm" | "md" | "lg" | "xl" = "sm";
  public loading = true;
  public mainStatTitles: {[index: string]: string} = {
    bygamme: "gamme",
    bynature: "nature",
    byfamily: "famille",
    bysubfamily: "sous-famille",
    byorigin: "origine",
    bystorage: "dépôt",
    bycertificate: "certification",
    bylabel: "label"
  };
  public statValueTitles = {
    weight: "poids total",
    amount: "montant total",
    count: "nombre"
  };
  private _subscriptions = new Subject<void>();

  constructor(private _bills: VivalyaBillService,
              private _billItems: VivalyaBillItemService,
              private _display: DisplayinfosService) {
  }


  ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  ngOnInit(): void {
    this._display.isMobile.pipe(takeUntil(this._subscriptions)).subscribe((mobile) => {
      this.legendPosition = mobile ? "bottom" : "right";
    });
    this._display.screenWidth.pipe(takeUntil(this._subscriptions)).subscribe((w) => {
      this.screenWidth = w;
    });
    this.mainFilterForm = new UntypedFormGroup(
      {
        datestart: new UntypedFormControl(startOfMonth(sub(new Date(), {months: 24}))),
        dateend: new UntypedFormControl(endOfMonth(sub(new Date(), {months: 1}))),
        mainstat: new UntypedFormControl("bygamme"),
        statvalue: new UntypedFormControl("weight"),
        storage: new UntypedFormControl("")
      }
    );
    const qs = this._bills.queryset;

    combineLatest([
      this.restoFilter$ ? this.restoFilter$ : of(this.restoFilter),
      this.storageFilter$ ? this.storageFilter$ : of(this.storageFilter)
    ])
      .pipe(
        debounceTime(300),
        switchMap(([resto, storage]) => {
          this.restoFilter = resto;
          this.storageFilter = storage;
          const baseFilter: any = assign({mainstat: "bystorage"},
            prefixKeys(this.restoFilter, "resto"),
            prefixKeys(this.storageFilter, "storage"),
          );
          return this._bills.action(null, "stats", {
            method: "GET", query: baseFilter
          });
        }),
        tap((stats) => {
          this.storages = [];
          for (const s of stats.bystorage) {
            this.storages.push(s);
          }
        }),
        switchMap(() => concat(of(true), this.mainFilterForm.valueChanges)),
        switchMap(() => {
          this.loading = true;
          this.mainstat = this.mainFilterForm.value.mainstat;
          this.statvalue = this.mainFilterForm.value.statvalue;
          const filter: any = assign(
            {
              datestart: format(new Date(this.mainFilterForm.value.datestart), "yyyy-MM-dd"),
              dateend: format(new Date(this.mainFilterForm.value.dateend), "yyyy-MM-dd")
            },
            prefixKeys(this.restoFilter, "resto"),
            prefixKeys(this.storageFilter, "storage"));

          if (this.mainFilterForm.value.storage) {
            filter["storage-id"] = this.mainFilterForm.value.storage;
          }
          const tfilter = assign({}, filter, {mainstat: "timed", statvalue: this.statvalue});
          const pfilter = assign({}, filter, {mainstat: this.mainstat, statvalue: this.statvalue});
          return combineLatest([
            qs.query(filter).get(),
            this._bills.action(null, "stats", {method: "GET", query: tfilter}),
            this._bills.action(null, "stats", {method: "GET", query: pfilter}),
          ]);
        }),
        takeUntil(this._subscriptions))
      .subscribe(([res, tstats, pstats]) => {
        this.loading = false;
        this.bills = res.items;
        this.amountTimeStats = this.getAmountTimeStats(tstats);
        this.productTypeStats = this.getProductTypeStats(pstats);
      });

    console.log(qs.filter.fields);
  }


  public getAmountTimeStats(stats: any): IAmountTimeStats {
    // Get min date
    const result: IAmountTimeStats = {
      count: {
        title: "",
        subtitle: "",
        axis: {time: {title: "Date", unit: ""}, value: {title: "Nombre", unit: "nb"}},
        stacked: false,
        legend: false,
        data: [["Date", "Nombre"]]
      },
      amount: {
        title: "",
        subtitle: "",
        axis: {time: {title: "Date", unit: ""}, value: {title: "Montant", unit: "€"}},
        stacked: false,
        legend: false,
        data: [["Date", "Montant"]]
      },
      weight: {
        title: "",
        subtitle: "",
        axis: {time: {title: "Date", unit: ""}, value: {title: "Poids", unit: "kg"}},
        stacked: false,
        legend: false,
        data: [["Date", "Poids"]]
      }
    };
    for (const stat of stats) {
      const name = new Date(stat.year, 0, stat.week * 7);
      result.amount!.data!.push([name, stat.amount / 10000]);
      result.count!.data!.push([name, stat.count]);
      result.weight!.data!.push([name, stat.count]);
    }
    return result;
  }

  public getProductTypeStats(stats: any): any {
    const types = ["bynature", "bygamme", "byfamily", "bysubfamily", "byorigin", "bystorage", "bycertificate", "bylabel"];
    const result: IProductTypeStats = {};
    for (const t of types) {
      result[t] = {
        count: {
          title: "",
          subtitle: "",
          axis: {
            categs: {title: this.mainStatTitles[t] + "\n" + this.statValueTitles.count, unit: "Nb"},
            value: {title: "Nombre", unit: "nb"}
          },
          legend: false,
          data: []
        },
        amount: {
          title: "",
          subtitle: "",
          axis: {
            categs: {title: this.mainStatTitles[t] + "\n" + this.statValueTitles.amount, unit: "€"},
            value: {title: "Montant (€)", unit: "€"}
          },
          legend: false,
          data: []
        },
        weight: {
          title: "",
          subtitle: "",
          axis: {
            categs: {title: this.mainStatTitles[t] + "\n" + this.statValueTitles.weight, unit: "kg"},
            value: {title: "Poids (kg)", unit: "kg"}
          },
          legend: false,
          data: []
        }
      };
      if (!stats[t]) {
        continue;
      }
      for (const stat of stats[t]) {
        if (stat.amount !== 0) {
          result[t].amount.data.push({
            name: stat.name || stat.code,
            value: stat.amount / 10000
          });
        }
        if (stat.count !== 0) {
          result[t].count.data.push({
            name: stat.name || stat.code,
            value: stat.count
          });
        }
        if (stat.weight !== 0) {
          result[t].weight.data.push({
            name: stat.name || stat.code,
            value: stat.weight / 10000
          });
        }
      }
    }
    return result;
  }

  public openBill(b: VivalyaBill): void {
    if (this.billDetails[b.id]) {
      delete this.billDetails[b.id];
    } else {
      this._billItems.queryset.query({bill: b.id}).get().subscribe((res) => {
        this.billDetails[b.id] = res.items;
      });
    }
  }
}
