<span *ngIf="current" [class.readonly]="readonly">
  <ng-container *ngIf="key.type=='INT'">
    <ng-container *ngIf="current.value_int">{{current.value_int | number:'1.0-0'}} {{key.unit}}</ng-container>
  </ng-container>
  <ng-container *ngIf="key.type=='DEC'">
    <ng-container *ngIf="current.value_dec">{{current.value_dec | number }} {{key.unit}}</ng-container>
  </ng-container>
  <ng-container *ngIf="key.type=='CUR'">
    <ng-container *ngIf="current.value_dec">{{current.value_dec| currency:key.unit:'symbol':'1.2-2'}}</ng-container>
  </ng-container>
  <ng-container *ngIf="key.type=='STX'">{{current.value_stx}}</ng-container>
  <ng-container *ngIf="key.type=='LTX'">{{current.value_ltx}}</ng-container>
  <ng-container *ngIf="key.type=='DTE'">{{current.value_dte | date:'mediumDate'}}</ng-container>
  <ng-container *ngIf="key.type=='DTH'">{{current.value_dth | date:'medium'}}</ng-container>
  <ng-container *ngIf="key.type=='CH1'">{{key.choice(current.choice)?.name}}</ng-container>
  <ng-container *ngIf="key.type=='FIL'">TODO (fichier)</ng-container>
  <ng-container *ngIf="key.type=='DOC'">
    <ng-container *ngIf="current.document_details"><a class="pointer" (click)="downloadDocument(current.document_details); false;" [href]=""><i class="fa fa-file me-2"></i>{{value?.document_details?.title}}<br><small>{{value?.document_details?.filename}}</small></a></ng-container>
  </ng-container>
  <ng-container *ngIf="key.type=='FOL'">
    <ng-container *ngIf="current.folder_details"><i class="fa fa-link pointer pointer me-2" [routerLink]="routes.detail('fl', 'documents', current.folder_details.slug+'-'+current.folder_details.id)"></i><span (click)="getFolderDocuments($event, current.folder_details)"><i class="fa fa-folder me-2"></i>{{value?.folder_details?.title}}</span>
      <div class="ms-3" *ngFor="let d of documents"><a class="pointer" (click)="downloadDocument(d); false;" [href]=""><i class="fa fa-file me-2"></i>{{d.title}}</a></div>
    </ng-container>
  </ng-container></span>
<ng-container *ngIf="!current"><span></span></ng-container>