import {Component, OnInit} from "@angular/core";
import {Upload, UploadService} from "../../../document/upload/upload.service";
import {firstValueFrom} from "rxjs";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ExternalArticleService} from "../externalarticle.service";

type EXMAPFIELD = "id" | "type" | "client" | "client_name" | "tariftype" | "tariftype_name" | "storage" | "member" | "provider" |
  "code" | "libelle" | "gamme" | "categorie" | "calibre" | "origine" | "uf" | "ucuf" | "ucond" | "article_id" | "article_lib" |
  "status" | "created" | "updated" | "xlsxhash";

@Component({
  selector: "lvadg-externalarticle-load-xlsx",
  templateUrl: "./externalarticle-load-xlsx.component.pug",
  styleUrls: ["./externalarticle-load-xlsx.component.sass"]
})
export class ExternalarticleLoadXlsxComponent implements OnInit {
  public sheetName = "mapping";
  public skip = 1;

  public ALL_FIELDS: EXMAPFIELD[] = ["id", "type", "client", "client_name", "tariftype", "tariftype_name", "storage", "member", "provider",
    "code", "libelle", "gamme", "categorie", "calibre", "origine", "uf", "ucuf", "ucond", "article_id", "article_lib",
    "status", "created", "updated", "xlsxhash"];
  public fields: EXMAPFIELD[] = ["id", "article_id", "code", "libelle", "client", "tariftype"];
  public match: EXMAPFIELD[] = ["libelle"];
  public update: EXMAPFIELD[] = ["code", "article_id"];
  public upload!: Upload;
  public uploadImportUrl!: string;
  public uploadCode!: string;
  public form!: UntypedFormGroup;
  public result!: { result: { level: number; line: number; message: string; data: any }[] };
  public minLog: number = 0;

  constructor(private _uploads: UploadService,
              private _extas: ExternalArticleService) {
  }

  ngOnInit(): void {
    this.uploadImportUrl = (<any>this._uploads).backend.getRequestUrl(
      (<any>this._uploads).adapter.create(null)
    );
    this.uploadCode = "UPEXAI" + (new Date()).getFullYear() +
      // tslint:disable-next-line:no-bitwise
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

    this.form = new UntypedFormGroup({
      sheetName: new UntypedFormControl(this.sheetName),
      skip: new UntypedFormControl(this.skip),
      fields: new UntypedFormControl(this.fields),
      match: new UntypedFormControl(this.match),
      update: new UntypedFormControl(this.update)
    });
    this.form.enable();
  }

  public async onUploaded(result: Upload[]): Promise<void> {
    if (result.length > 0 && result[0].id) {
      this.upload = await firstValueFrom(this._uploads.get(result[0].id));
      this.form.enable();
    }
  }

  public async launchImport() {
    this.result = await firstValueFrom(this._extas.action(null, "xlsx_import", {
      method: "POST", body: {
        upload: this.upload?.id || 711,
        sheet: this.form.value.sheetName,
        skip: this.form.value.skip,
        fields: this.form.value.fields,
        match: this.form.value.match,
        update: this.form.value.update
      }
    }));
  }
}
