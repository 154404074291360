import {Component, OnInit} from "@angular/core";
import {MeteoFeature, MeteoFull} from "../meteo-full";
import {EnvService} from "../../../../../front/env.service";
import {MeteoService} from "../meteo.service";
import {first} from "rxjs/operators";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-meteo-summary",
  templateUrl: "./meteo-summary.component.pug",
  styleUrls: ["./meteo-summary.component.sass"]
})
export class MeteoSummaryComponent implements OnInit {
  public meteo!: { [index: string]: MeteoFeature };
  public cities!: string[];
  public selcities = ["Lille", "Strasbourg", "Paris", "Brest", "Lyon", "Bordeaux", "Marseille"];

  constructor(private _mt: MeteoService,
              public env: EnvService) {
  }

  async ngOnInit(): Promise<void> {
    const meteo: MeteoFull = await firstValueFrom(this._mt.full.pipe(first()));
    this.cities = [];
    this.meteo = {};
    for (const m of meteo.features || []) {
      this.meteo[m.properties.name] = m;
      this.cities.push(m.properties.name);
    }

  }


  public citySelected($event: any) {
    const city = $event.target.value;
    console.log("Changed value", city);
    if (this.selcities.indexOf(city) !== -1) {
      this.selcities.splice(this.selcities.indexOf(city), 1);
    } else {
      this.selcities.push(city);
    }
  }
}
