import {Injectable} from "@angular/core";
import {DSCharField, DSDefaultField, DSManyToManyField, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProductFamily} from "../../product/_productfamily/productfamily-base.model";
import {Producer} from "../../structure/producer/producer.service";
import {Label} from "../../product/label/label.service";
import {DetailDocument} from "../../document/basedocument/basedocument.service";
import {ArticleTarifsStats} from "../article/article-tarifs-stats";


export class ArticleFamily extends BaseProductFamily {
  @DSDefaultField({
    required: false,
    name: "Détails producteurs",
    helpText: "Détails producteurs",
    verboseName: "Détails producteurs"
  })
  public producers_details!: Producer[];

  @DSDefaultField({
    required: false,
    name: "Liste labels",
    helpText: "Liste des labels",
    verboseName: "Liste des labels"
  })
  public labels_details!: Label[];

  @DSManyToManyField({
    name: "documents",
    verboseName: "Documents article",
    required: false,
    relatedModel: "document.DetailDocument",
    remoteField: "articleproviderdocuments",
    helpText: "Documents article",
    default: [],
  })
  public documents!: number[];
  public documents_details!: DetailDocument[];

  @DSDefaultField({
    required: false,
    name: "documents_count",
    verboseName: "Nb Documents",
    helpText: "Nombre de documents",
  })
  public documents_count!: number;

  @DSCharField({
    name: "dmode",
    verboseName: "Mode affichage",
    required: false,
    helpText: "Mode d'affichage",
    minLength: 0,
    maxLength: 5,
    choices: [
      ["FL-1G", "Fruits & légumes, 1ère gamme"],
      ["FL-4G", "Fruits & légumes, 4ème gamme"],
      ["FL-5G", "Fruits & légumes, 5ème gamme"],
      ["MR-1G", "Marée, 1ère gamme"],
      ["MR-4G", "Marée, 4ème gamme"],
      ["MR-5G", "Marée, 5ème gamme"],
    ]
  })
  public dmode!: string;
  public cdmode!: string;
  public tarifs_stats?: ArticleTarifsStats;

  public get zone(): "fl" | "mr" {
    if (!this.cdmode) {
      return "fl";
    }
    return this.cdmode.indexOf("MR") !== -1 ? "mr" : "fl";
  }
}

@Injectable({
  providedIn: "root"
})
export class ArticleFamilyService extends DSRestCollection<ArticleFamily> {
  public adapter_config = {basePath: "/api/v2/catalog/families"};
  public model = ArticleFamily;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
