
<form [formGroup]="form" *ngIf="eac" (submit)="create()">
  <div class="form-group">
    <label>Type</label>
    <bsac-field-control [form]="form" [model]="eac" field="type"></bsac-field-control>
  </div>
  <div class="form-group">
    <label>Nom</label>
    <bsac-field-control [form]="form" [model]="eac" field="name"></bsac-field-control>
  </div>
  <button class="btn btn-primary"><i class="fa fa-save me-2"></i>Enregistrer</button>
</form>