<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'status'; desc: 'Recherche par état'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="status" choices="PRODUCER_STATUSES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'pubimage'; desc: 'Recherche par droit image'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="pubimage" choices="PRODUCER_PUBIMAGES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'avmedias'; desc: 'Recherche par médias disponibles'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="avmedias" [choices]="PRODUCER_PRINTABLE_CHOICES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'missingdocs'; desc: 'Recherche par certificats manquants'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="missingdocs" [choices]="MISSINGDOCS"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'slabels'; desc: 'Recherche par label(s) structure'; group: 'general'">
        <bsac-model-list-multiselect-filter *ngIf="slabels" [list]="list" field="slabels" [choices]="slabels" descfield="name"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'statuses'; desc: 'Recherche par état(s)'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="statuses" choices="PRODUCER_STATUSES"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'products_count'; desc: 'Par nombre de produits'; group: 'general'">
        <bsac-model-list-value-filter [list]="list" field="products_count" [enabled]="['gt', 'lt']"></bsac-model-list-value-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'flags'; desc: 'Par flag admin'; group: 'general'">
        <bsac-model-list-flags-filter [list]="list" field="flags" [flags]="flags" label="Par flag"></bsac-model-list-flags-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storages'; desc: 'Recherche par dépôt(s)'; group: 'link'">
        <bsac-model-list-multiautocomplete-filter [list]="list" [queryset]="storages$" descfield="name" field="storages" [filter]="filter" hint="Sélectionnez un dépôt" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'members'; desc: 'Recherche par adhérent(s)'; group: 'link'">
        <bsac-model-list-multiautocomplete-filter [list]="list" [queryset]="members$" descfield="name" field="members" hint="Sélectionnez un adhérent" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagenear'; desc: 'Recherche par dépôt proche de ...'; group: 'geostorage'">
        <bsac-model-list-geodistance-filter [list]="list" field="storagenear"></bsac-model-list-geodistance-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeoregions'; desc: 'Recherhce par région(s) de depôt'; group: 'geostorage'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeoregions" [queryset]="regions$" descfield="fullname" hint="Sélectionnez une région" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeodepartements'; desc: 'Recherche par département(s) du dépôt'; group: 'geostorage'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeodepartements" [queryset]="departements$" descfield="fullname" hint="Sélectionnez un département" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeocommunes'; desc: 'Recherche par commmune(s) du dépôt'; group: 'geostorage'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeocommunes" [queryset]="communes$" descfield="fullname" hint="Sélectionnez une commune" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'oneseasons'; desc: 'Par saisonnalité (au moins une)'; group: 'product'">
        <bsac-model-list-multiselect-filter [list]="list" field="oneseasons" [choices]="seasons"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'allseasons'; desc: 'Par saisonnalité (toutes)'; group: 'product'">
        <bsac-model-list-multiselect-filter [list]="list" field="allseasons" [choices]="seasons"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'families'; desc: 'Par famille &amp; sous-famille'; group: 'product'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="families" [queryset]="families1$" descfield="cfullname" hint="Sélectionnez une famille" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'exactfamilies'; desc: 'Par famille (exacte)'; group: 'product'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="exactfamilies" [queryset]="families2$" descfield="cfullname" hint="Sélectionnez une famille" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'labels'; desc: 'Par label produit'; group: 'product'">
        <bsac-model-list-multiselect-filter *ngIf="labels" [list]="list" field="labels" [choices]="labels"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producernear'; desc: 'Recherche par fournisseur proche de ...'; group: 'geoproducer'">
        <bsac-model-list-geodistance-filter [list]="list" field="producernear"></bsac-model-list-geodistance-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producergeoregions'; desc: 'Recherche par région(s) du fournisseur'; group: 'geoproducer'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="producergeoregions" [queryset]="regions$" descfield="fullname" hint="Sélectionnez une région" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producergeodepartements'; desc: 'Recherche par département(s) du fournisseur'; group: 'geoproducer'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="producergeodepartements" [queryset]="departements$" descfield="fullname" hint="Sélectionnez un département" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producergeocommunes'; desc: 'Recherche par commune(s) du fournisseur'; group: 'geoproducer'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="producergeocommunes" [queryset]="communes$" descfield="fullname" hint="Sélectionnez une commune" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'allrdistance'; desc: 'Rechercher par distance dépôt / fournisseur (en km, tous)'; group: 'geostorage'">
        <bsac-model-list-value-filter [list]="list" field="allrdistance" [enabled]="['gt', 'lt']"></bsac-model-list-value-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'rdistance'; desc: 'Rechercher par distance dépôt / fournisseur (en km, au moins un)'; group: 'geoproducer'">
        <bsac-model-list-value-filter [list]="list" field="rdistance" [enabled]="['gt', 'lt']"></bsac-model-list-value-filter>
      </div>
      <ng-container *ngFor="let cd of cdatakeys">
        <ng-container *ngIf="cd.type=='CH1'">
          <div class="filtersetitem" *bsacFilterSetItem="'customdata_'+cd.id; desc: cd.name; group: 'properties'">
            <lvadg-customdata-modellist-filter-choices [list]="list" [code]="cd.code" [dest]="cd.dest"></lvadg-customdata-modellist-filter-choices>
          </div>
        </ng-container>
      </ng-container>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'link'; desc: 'Recherche par lien'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'geoproducer'; desc: 'Recherche géographique producteur'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'geostorage'; desc: 'Recherche géographique dépôt'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'product'; desc: 'Recherche par caractéristiques produit'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'properties'; desc: 'Recherche par données producteur'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <lvadg-modellist-xlsx-export [list]="list" [mode]="mode"></lvadg-modellist-xlsx-export>
    <button class="btn btn-link" (click)="resultsMode='map'" *ngIf="resultsMode!='map'">Afficher sur une carte</button>
    <button class="btn btn-link" (click)="resultsMode='list'" *ngIf="resultsMode!='list'">Afficher la liste</button>
    <lvadg-selection-summary stype="producer" unit="producteur(s)" [actions]="select_actions" [custom_actions]="select_custom_actions" (action)="onAction($event)"></lvadg-selection-summary>
  </div>
  <div class="list-results">
    <ng-container *ngIf="resultsMode=='map'">
      <ng-container *ngIf="list.results|async; let res">
        <lvadg-locations-osm-display zone="sea" [seaproducers]="res.items" [seastorages]="mapStorages" height="700px"></lvadg-locations-osm-display>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status | bsacApiConstant:'PRODUCER_STATUSES'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="select">
            <th cdk-header-cell *cdkHeaderCellDef>
              <lvadg-selection-selitem [items]="results" stype="producer"></lvadg-selection-selitem>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkAction('select')">
                <lvadg-selection-selitem [item]="row" stype="producer"></lvadg-selection-selitem>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="content_src">
            <th cdk-header-cell *cdkHeaderCellDef>Description (texte)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_src}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content">
            <th cdk-header-cell *cdkHeaderCellDef>Description (html)</th>
            <td cdk-cell *cdkCellDef="let row">
              <div [innerHtml]="row.content_src"></div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="name"><b>Nom</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row"><a [routerLink]="routes.detail(zone, type, row)"><b>{{row.name}}</b></a></td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type | bsacApiConstant:'PRODUCER_TYPES'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="mainaddress">
            <th cdk-header-cell *cdkHeaderCellDef>Adresse</th>
            <td cdk-cell *cdkCellDef="let row">
              <address>{{row.mainaddress | trim:'\n'}}</address>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="locations">
            <th cdk-header-cell *cdkHeaderCellDef>#&nbsp;Emplacements</th>
            <td cdk-cell *cdkCellDef="let row"><span *ngIf="row.locations">{{row.locations.length}}</span><span class="text-danger" *ngIf="!row.locations">aucun</span></td>
          </ng-container>
          <ng-container cdkColumnDef="producer_postcode">
            <th cdk-header-cell *cdkHeaderCellDef>Code postal producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.postcode}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producer_city">
            <th cdk-header-cell *cdkHeaderCellDef>Ville producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.city}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producer_dptcode">
            <th cdk-header-cell *cdkHeaderCellDef>Code département producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.geodepartement_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producer_dptname">
            <th cdk-header-cell *cdkHeaderCellDef>Nom département producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.geodepartement_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="phone">
            <th cdk-header-cell *cdkHeaderCellDef>Téléphone</th>
            <td cdk-cell *cdkCellDef="let row">{{row.phone}}</td>
          </ng-container>
          <ng-container cdkColumnDef="siren">
            <th cdk-header-cell *cdkHeaderCellDef>Siren</th>
            <td cdk-cell *cdkCellDef="let row">{{row.siren}}</td>
          </ng-container>
          <ng-container cdkColumnDef="siret">
            <th cdk-header-cell *cdkHeaderCellDef>Siret</th>
            <td cdk-cell *cdkCellDef="let row">{{row.siret}}</td>
          </ng-container>
          <ng-container cdkColumnDef="tvaic">
            <th cdk-header-cell *cdkHeaderCellDef>N° TVA</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tvaic}}</td>
          </ng-container>
          <ng-container cdkColumnDef="naf">
            <th cdk-header-cell *cdkHeaderCellDef>Code NAF</th>
            <td cdk-cell *cdkCellDef="let row">{{row.naf}}</td>
          </ng-container>
          <ng-container cdkColumnDef="legal">
            <th cdk-header-cell *cdkHeaderCellDef>Structure légale</th>
            <td cdk-cell *cdkCellDef="let row">{{row.legal}}</td>
          </ng-container>
          <ng-container cdkColumnDef="capital">
            <th cdk-header-cell *cdkHeaderCellDef>Capital</th>
            <td cdk-cell *cdkCellDef="let row">{{row.capital}}</td>
          </ng-container>
          <ng-container cdkColumnDef="pubimage">
            <th cdk-header-cell *cdkHeaderCellDef>Droit image</th>
            <td cdk-cell *cdkCellDef="let row">{{row.pubimage | bsacApiConstant: 'PRODUCER_PUBIMAGES'}}<i class="fa fa-pencil ms-1 pointer" (click)="updateDI(row)" *ngIf="checkMode('admin')"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="logo">
            <th cdk-header-cell *cdkHeaderCellDef>Logo&nbsp;?</th>
            <td cdk-cell *cdkCellDef="let row"><span *ngIf="row.logo">OUI</span><span *ngIf="!row.logo">NON</span></td>
          </ng-container>
          <ng-container cdkColumnDef="storages">
            <th cdk-header-cell *cdkHeaderCellDef>#&nbsp;Dépôts</th>
            <td cdk-cell *cdkCellDef="let row"><span *ngIf="row.storages">{{row.storages.length}}</span><span class="text-danger" *ngIf="!row.storages">aucun</span></td>
          </ng-container>
          <ng-container cdkColumnDef="labels">
            <th cdk-header-cell *cdkHeaderCellDef>Labels</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkMode('admin')"><span class="label" *ngFor="let l of row.labels">
                  <lvadg-sealabel-display class="me-1 mb-1" [labelId]="l" mode="name"></lvadg-sealabel-display></span></ng-container>
              <ng-container *ngIf="!checkMode('admin')"><span class="label" *ngFor="let l of row.labels">
                  <lvadg-sealabel-display class="me-1 mb-1" [labelId]="l"></lvadg-sealabel-display></span></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="images">
            <th cdk-header-cell *cdkHeaderCellDef>#&nbsp;Images</th>
            <td cdk-cell *cdkCellDef="let row"><span *ngIf="row.images">{{row.images.length}}</span><span class="text-warning" *ngIf="!row.images">aucune</span></td>
          </ng-container>
          <ng-container cdkColumnDef="products_count">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="products_count">#&nbsp;Produits</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.products_count }}</td>
          </ng-container>
          <ng-container cdkColumnDef="documents_count">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="documents_count">#&nbsp;Documents</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.documents_count}}</td>
          </ng-container>
          <ng-container *ngIf="storages_manage" cdkColumnDef="storages_details">
            <th cdk-header-cell *cdkHeaderCellDef><span>Dépôts</span><i class="fa fa-ruler ms-2 pointer" *ngIf="showStorageDistance" (click)="showStorageDistance=false"></i><i class="fa fa-ruler text-muted pointer ms-2" *ngIf="!showStorageDistance" (click)="showStorageDistance=true"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let s of row.storages_details">
                <div *ngIf="checkMode('admin')">
                  <lvadg-confirm-delete class="me-2" type="trash" (confirm)="removeStorage(row, s.id)"></lvadg-confirm-delete><a [routerLink]="routes.detail(zone, 'storages', s)">{{s.name}}
                    <ng-container *ngIf="showStorageDistance"><small class="ms-2" *ngIf="s.distance">{{s.distance | distance}}</small><small class="ms-2">{{s.member_details?.name}}</small></ng-container></a>
                </div>
                <div *ngIf="checkMode('memberadmin') &amp;&amp; s.member_details?.id==member?.id">
                  <lvadg-confirm-delete class="me-2" type="trash" (confirm)="removeStorage(row, s.id)"></lvadg-confirm-delete><a [routerLink]="routes.detail(zone, 'storages', s)">{{s.name}}
                    <ng-container *ngIf="showStorageDistance"><small class="ms-2" *ngIf="s.distance">{{s.distance | distance}}</small><small class="ms-2">{{s.member_details?.name}}</small></ng-container></a>
                </div>
              </ng-container>
              <div *ngIf="checkMode('admin', 'memberadmin')">
                <div class="pointer" (click)="addstorage[row.id]=true"><i class="fa fa-plus me-2"></i>Associer un dépôt</div>
                <div *ngIf="addstorage[row.id]">
                  <lvadg-storage-select (selected)="addStorage($event, row)" [member]="member" [filter]="addStorageFilter"></lvadg-storage-select>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="!storages_manage" cdkColumnDef="storages_details">
            <th cdk-header-cell *cdkHeaderCellDef><span>Dépôts</span><i class="fa fa-filter text-muted ms-2 pointer" (click)="storagesDisplayMode='full'" *ngIf="storagesDisplayMode!='full'"></i><i class="fa fa-filter ms-2 pointer" (click)="storagesDisplayMode='sel'" *ngIf="storagesDisplayMode!='sel'"></i><i class="fa fa-ruler ms-2 pointer" *ngIf="showStorageDistance" (click)="showStorageDistance=false"></i><i class="fa fa-ruler text-muted ms-2 pointer" *ngIf="!showStorageDistance" (click)="showStorageDistance=true"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let s of row.storages_details">
                <ng-container *ngIf="displayStorage(s.id)"><a [routerLink]="routes.detail(zone, 'storages', s)"><span>{{s.name}}</span></a>
                  <ng-container *ngIf="showStorageDistance"><small class="ms-2" *ngIf="!checkMode('member', 'memberadmin')">{{s.member_details?.name}}</small>
                    <div *ngIf="s.distance">distance producteur - dépôt {{s.distance | distance}}</div>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="locations_details">
            <th cdk-header-cell *cdkHeaderCellDef>Localisation</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details.postcode}} {{row.mainlocation_details.city}}</td>
          </ng-container>
          <ng-container cdkColumnDef="logo_img">
            <th cdk-header-cell *cdkHeaderCellDef>Logo</th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-rimg [src]="row.logo_img" *ngIf="row.logo &amp;&amp; row.logo_img"></lvadg-rimg>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="documents">
            <th cdk-header-cell *cdkHeaderCellDef>Documents</th>
            <td cdk-cell *cdkCellDef="let row">{{row.documents}}</td>
          </ng-container>
          <ng-container cdkColumnDef="images_details">
            <th cdk-header-cell *cdkHeaderCellDef>Photos</th>
            <td cdk-cell *cdkCellDef="let row">Voir fiche détail</td>
          </ng-container>
          <ng-container cdkColumnDef="slabels">
            <th cdk-header-cell *cdkHeaderCellDef>Labels producteur</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let l of row.slabels"><span class="badge bg-info badg-spaced">{{l|slabelName}}</span></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="labels_details">
            <th cdk-header-cell *cdkHeaderCellDef>Détails labels</th>
            <td cdk-cell *cdkCellDef="let row">Voir fiche détail</td>
          </ng-container>
          <ng-container cdkColumnDef="direct_distance">
            <th cdk-header-cell *cdkHeaderCellDef>Distance
              <div *ngIf="selectedAddress"><small>{{selectedAddress.name}}</small></div>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <table class="distances">
                <ng-container *ngFor="let d of resultsDistances[row.id]">
                  <ng-container *ngIf="d.type=='SA' &amp;&amp; d.dist1">
                    <ng-container *ngIf="displayStorage(d.ref)">
                      <tr *ngIf="d.dist1.road&gt;=0">
                        <td>{{d.desc}}
                          <div class="via" *ngIf="d.via">via {{d.via}}</div>
                        </td>
                        <td class="dist">{{d.dist1.road|distance}}</td>
                      </tr>
                      <tr *ngIf="d.dist1.road&lt;0">
                        <td>{{d.desc}}
                          <div class="via" *ngIf="d.via">via {{d.via}}</div>
                        </td>
                        <td class="dist">~{{d.dist1.direct|distance}}<i class="fa fa-spinner fa-spin ms-2"></i></td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="d.type=='PA' &amp;&amp; d.dist1">
                    <tr *ngIf="d.dist1.road&gt;=0">
                      <td>{{d.desc}}
                        <div class="via" *ngIf="d.via">via {{d.via}}</div>
                      </td>
                      <td class="dist">{{d.dist1.road|distance}}</td>
                    </tr>
                    <tr *ngIf="d.dist1.road&lt;0">
                      <td>{{d.desc}}
                        <div class="via" *ngIf="d.via">via {{d.via}}</div>
                      </td>
                      <td class="dist">~{{d.dist1.direct|distance}}<i class="fa fa-spinner fa-spin ms-2"></i></td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="d.type=='PSA' &amp;&amp; d.dist1 &amp;&amp; d.dist2">
                    <tr *ngIf="d.dist1.road&lt;0 || d.dist2.road&lt;0">
                      <td>{{d.desc}}
                        <div class="via" *ngIf="d.via">via {{d.via}}</div>
                      </td>
                      <td class="dist">~{{d.dist1.direct+d.dist2.direct|distance}}<i class="fa fa-spinner fa-spin ms-2"></i></td>
                    </tr>
                    <tr *ngIf="d.dist1.road&gt;=0 &amp;&amp; d.dist2.road &gt;=0">
                      <td>{{d.desc}}
                        <div class="via" *ngIf="d.via">via {{d.via}}</div>
                      </td>
                      <td class="dist">{{d.dist1.road + d.dist2.road|distance}}</td>
                    </tr>
                  </ng-container>
                </ng-container>
              </table>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="qr">
            <th cdk-header-cell *cdkHeaderCellDef>QR Code</th>
            <td cdk-cell *cdkCellDef="let row"><a [href]="row.iourl.url" *ngIf="row.iourl" target="_blank"><img class="qr img-fluid" *ngIf="row.qr" [src]="row.qr"></a></td>
          </ng-container>
          <ng-container cdkColumnDef="iourl">
            <th cdk-header-cell *cdkHeaderCellDef>lvadg.io</th>
            <td cdk-cell *cdkCellDef="let row"><a [href]="row.iourl.url" *ngIf="row.iourl" target="_blank">{{row.iourl.code}}</a><span *ngIf="!row.iourl">-</span></td>
          </ng-container>
          <ng-container cdkColumnDef="activity">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="activity">Activité OL</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.activity}}</td>
          </ng-container>
          <!-- Custom data-->
          <ng-container *ngFor="let ck of cdatakeys">
            <ng-container [cdkColumnDef]="'customdata_'+ck.id">
              <th cdk-header-cell *cdkHeaderCellDef>{{ck.name}}</th>
              <td cdk-cell *cdkCellDef="let row">
                <lvadg-customdata-rowfield-display [mode]="mode" [editors]="['admin', 'memberadmin']" [model]="row" [dest]="ck.dest" [code]="ck.code"></lvadg-customdata-rowfield-display>
              </td>
            </ng-container>
          </ng-container>
          <ng-container cdkColumnDef="actions">
            <th cdk-header-cell *cdkHeaderCellDef>Actions</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkAction('quickprintproducer')">
                <button class="btn btn-link btn-sm ms-1 me-1" (click)="onAction({action: 'quickprintproducer', producer: row})"><i class="fa fa-print me-2"></i></button>
              </ng-container>
              <ng-container *ngIf="checkAction('quickprintproducerposter')">
                <button class="btn btn-link btn-sm ms-1 me-1" (click)="onAction({action: 'quickprintproducerposter', producer: row})"><i class="fa fa-file-image me-2"></i></button>
              </ng-container>
            </td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>