import {DSAutoField, DSCharField, DSDateTimeField, DSModel, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

export class StatsFile extends DSModel {
  @DSAutoField({
    required: false,
    name: "id",
    verboseName: "ID"
  })
  public id!: number;

  @DSCharField({
    required: false,
    name: "type",
    verboseName: "Type de stat"
  })
  public type!: string;

  @DSCharField({
    required: false,
    name: "params",
    verboseName: "Paramètres stat"
  })
  public params!: string;

  @DSDateTimeField({
    required: false,
    name: "created",
    verboseName: "Date de création"
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "expire",
    verboseName: "Date d'expiration"
  })
  public expire!: Date;

  @DSCharField({
    required: false,
    name: "sfile",
    verboseName: "URL fichier de stats"
  })
  public sfile!: string;

  @DSCharField({
    required: false,
    name: "filename",
    verboseName: "Nom du fichier"
  })
  public filename!: string;



}


@Injectable({providedIn: "root"})
export class StatsFileService extends DSRestCollection<StatsFile> {
  public adapter_config = {basePath: "/api/v2/statsfiles"};
  public model = StatsFile;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }

  public latestByType(type: string, params: string = ""): Observable<StatsFile> {
    return this.action(null,
      "latest",
      {method: "GET", query: {type: type, params: params}});
  }

}
