import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {Subject} from "rxjs";
import {DisplayinfosService} from "../displayinfos.service";
import {Rimg, RIMG_BANNERS, RIMG_DOWN_SIZES, RIMG_UP_SIZES} from "./rimg";
import {takeUntil} from "rxjs/operators";


@Directive({
  selector: "[lvadgRbg]"
})
export class RbgDirective implements OnInit, OnChanges, OnDestroy {
  @Input() public rsrc: any;
  @Input() public rdisplay = "thumb";  // NOT USED FOR NOW
  @Input() public rsize = "thumb";
  @Input() public rmode: "exact" | "up" | "down" = "exact";

  public source!: string;
  private rimg!: Rimg;
  private sz!: string;
  private _subscriptions$ = new Subject<void>();
  private _el!: HTMLElement;

  constructor(private _dispInfos: DisplayinfosService,
              private _elRef: ElementRef,
              private _renderer: Renderer2) {
    this._el = this._elRef.nativeElement;
  }


  public ngOnInit(): void {
    this._dispInfos.screenWidth.pipe(takeUntil((this._subscriptions$))).subscribe((sz) => {
      this.sz = sz;
      this.updateImage();
    });
  }

  public ngOnDestroy(): void {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  public updateImage() {
    this.source = "";
    if (this.rsrc !== null) {
      if (typeof this.rsrc === "string") {
        this.source = this.rsrc;
      } else {
        this.rimg = new Rimg(this.rsrc);
        if (this.rsize === "orig") {
          // Original size
          this.source = this.rimg.orig;
        } else if (this.rsize === "fwbanner") {
          // Banner
          if (this.sz) {
            this.source = this.rimg.byName(RIMG_BANNERS[this.sz])?.image || '';
          } else {
            this.source = this.rimg.byName(RIMG_BANNERS["md"])?.image || '';
          }
        } else {
          if (this.rmode === "exact") {
            this.source = this.rimg.byName(this.rsize)?.image || '';
          } else if (this.rmode === "up") {
            const start = RIMG_DOWN_SIZES.indexOf(this.rsize);
            const sizes = RIMG_DOWN_SIZES.slice(start, RIMG_DOWN_SIZES.length);
            this.source = this.rimg.byName(sizes)?.image || '';
          } else if (this.rmode === "down") {
            const start = RIMG_UP_SIZES.indexOf(this.rsize);
            const sizes = RIMG_UP_SIZES.slice(start, RIMG_UP_SIZES.length);
            this.source = this.rimg.byName(sizes)?.image || '';
          }
        }
      }
    }
    this._renderer.setStyle(this._el, "backgroundImage", `url(${this.source})`);
  }

  ngOnChanges(changes: any): void {
    this.updateImage();
  }

}
