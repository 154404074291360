import {Component, Input, OnInit} from "@angular/core";
import {Article, ArticleService} from "../article.service";
import {ArticleDetailStats, ArticleStatService} from "../article-stat.service";
import {NgbActiveOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {TimeSeriesChartDataset} from "../../../../comps/charts/formats";

@Component({
  selector: "lvadg-article-stat-detail",
  templateUrl: "./article-stat-detail.component.pug",
  styleUrls: ["./article-stat-detail.component.sass"]
})
export class ArticleStatDetailComponent implements OnInit {
  @Input() public article: Article;
  @Input() public stats: ArticleDetailStats;
  @Input() public focus: string;
  public dates: string[] = [];
  public fdates: Map<string, boolean>;
  public tarifs: { name: string, tariftype: number, pilotes: string[] }[];
  public prices: Map<string, { price: number, tarif: string, tarif_id: number }>;
  public graph: TimeSeriesChartDataset;

  constructor(public activeOffcanvas: NgbActiveOffcanvas, private _as: ArticleService, private _stat: ArticleStatService) {
  }

  ngOnInit(): void {
    // Full duration
    const tariftypes = new Map();
    const days = new Map();
    this.prices = new Map();
    const ftariftypes = new Map();
    const fintervals = new Map();
    const fprices = new Map();
    this.fdates = new Map();
    const groupers = Object.keys(this.stats.prices)
    const fpos = groupers.indexOf(this.focus);
    for (const k of Object.keys(this.stats.prices)) {
      const kpos = groupers.indexOf(k)
      if (fpos - 1 <= kpos && kpos <= fpos+1) {
        for (const f of this.stats.prices[k].details) {
          if (!tariftypes.has(f.tariftype_id)) {
            tariftypes.set(f.tariftype_id, {name: f.tariftype, tariftype: f.tariftype_id, pilotes: f.pilotes});
          }
          if (!days.has(f.date)) {
            days.set(f.date, f.date);
          }
          this.prices.set(`${f.tariftype_id}/${f.date}`, {price: f.price, tarif: f.tarif, tarif_id: f.tarif_id});
        }
      }
      if (fpos === kpos) {
        for (const f of this.stats.prices[k].details) {
          this.fdates.set(`${f.date}`, true)
        }
      }

      fintervals.set(k, {id: k, date: null});
      for (const f of this.stats.prices[k].details) {
        if (!ftariftypes.has(f.tariftype_id)) {
          ftariftypes.set(f.tariftype_id, {name: f.tariftype, tariftype: f.tariftype_id, pilotes: f.pilotes});
        }
        fprices.set(`${f.tariftype_id}/${k}`, {price: f.price, tarif: f.tarif, tarif_id: f.tarif_id});
        if (fintervals.get(k)!.date === null) {
          fintervals.set(k, {id: k, date: f.date});
        }
      }
    }

    this.dates = Array.from(days.values());
    this.tarifs = Array.from(tariftypes.values());

    const data = [];
    const dataHead = ["Date"];
    for (const t of ftariftypes.values()) {
      dataHead.push(t.name);
    }
    data.push(dataHead);

    for (const d of fintervals.values()) {
      if (d.date) {
        const line = [new Date(d.date)];
        for (const t of ftariftypes.values()) {
          const p = fprices.get(`${t.tariftype}/${d.id}`);
          line.push((p ? p.price : null) as any);
        }
        data.push(line);
      }
    }

    console.log(data);
    // Build graph
    this.graph = {
      axis: {
        time: {
          title: "date",
          unit: "hour"
        },
        value: {
          title: "Tarif",
          unit: "€"
        }
      },
      stacked: false,
      legend: true,
      title: "Evolution des tarifs",
      data: data
    };
  }


}

