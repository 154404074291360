import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {SeaProductVariantSelectComponent} from "./seaproductvariant-select/seaproductvariant-select.component";

@NgModule({
  imports: [CommonModule, BsacFormsModule],
  declarations: [SeaProductVariantSelectComponent],
  exports: [SeaProductVariantSelectComponent]
})
export class SeaProductVariantSelectModule {}
