// Producer list & filters.
// Display a list/map of producers, with filters.
// @copyright 2019 JM Barbier <jm.barbier@solidev.net>
// @status BETA
// @updated 2019-01-27-13:33:53

import {Component, OnDestroy, OnInit} from "@angular/core";
import {BsacMessageService, BsacModelListProvider, IFilterOutput} from "@solidev/bsadmincomponents";
import {GeoCommuneService} from "../../../customdata/geocommune/geocommune.service";
import {GeoDepartementService} from "../../../customdata/geodepartement/geodepartement.service";
import {GeoRegionService} from "../../../customdata/georegion/georegion.service";
import {Label, LabelService} from "../../../product/label/label.service";
import {ProductFamily, ProductFamilyService} from "../../../product/productfamily/productfamily.service";
import {MemberService} from "../../member/member.service";
import {StorageService} from "../../storage/storage.service";
import {Producer, ProducerService} from "../producer.service";
import {SLabelService} from "../../slabel/slabel.service";
import {Product} from "../../../product/product/product.service";
import {BaseProducerListComponent} from "../../_producer/producer-list-base/producer-list-base.component";
import {RoutesService} from "../../../../comps/routes.service";
import {CustomDataKeyService} from "../../../customdata/customdatakey/customdatakey.service";
import {DistanceService} from "../../location/distance.service";
import {Observable} from "rxjs";
import {assign} from "lodash-es";
import {map} from "rxjs/operators";


@Component({
  selector: "lvadg-producer-list",
  templateUrl: "./producer-list.component.pug",
  styleUrls: ["./producer-list.component.sass"]
})
export class ProducerListComponent extends BaseProducerListComponent<Producer, ProducerService,
  Product, Label, LabelService,
  ProductFamily, ProductFamilyService> implements OnInit, OnDestroy {

  constructor(_list: BsacModelListProvider,
              _items: ProducerService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              _storages: StorageService,
              _members: MemberService,
              _families: ProductFamilyService,
              _regions: GeoRegionService,
              _departements: GeoDepartementService,
              _labels: LabelService,
              _slabels: SLabelService,
              _communes: GeoCommuneService,
              _distances: DistanceService,
              _customdatakeys: CustomDataKeyService
  ) {
    super(_list, _items, _msgs, routes,
      _storages, _members, _families, _regions,
      _departements, _labels, _slabels, _communes,
      _distances, _customdatakeys);
    this.zone = "fl";
  }

  public get fullFilter$(): Observable<IFilterOutput> {
    return this.list.filter.output$.pipe(
      map((f) => {
        return assign({}, f, this.filter);
      })
    );

  }
}


