import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {CommField, IFieldParameter} from "../commfield.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {CommFieldParamsService} from "../commfieldparams.service";
import {Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {DSRestCollection} from "@solidev/ngdataservice";

@Component({
  selector: "lvadg-commfield-seltxtcustomimg",
  templateUrl: "./commfield-seltxtcustomimg.component.pug",
  styleUrls: ["./commfield-seltxtcustomimg.component.sass"]
})
export class CommFieldSelTxtCustomImgComponent implements OnInit, OnDestroy {
  @Input() public params!: CommFieldParamsService;
  // FIXME: add this fields$ to usage
  @Input() public fields$!: DSRestCollection<CommField>;
  @Input() public name!: string;
  @Input() public part!: string;
  @Input() public customLabel!: string;

  public _subscriptions = new Subject<void>();
  public param!: IFieldParameter;
  public form!: UntypedFormGroup;

  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public getImageUrl(): string {
    return (this.fields$.backend as any).getRequestUrl(this.fields$.adapter.retrieve(this.param.field_details));
  }

  public removeParam(): void {
    this.params.removeByName(this.part, this.name);
  }

  public onUploaded(event: any): void {
    this.params.triggerChange();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      "value": new UntypedFormControl("")
    });
    this.params.getByName(this.part, this.name)
      .pipe(takeUntil(this._subscriptions),
        filter((value) => !!value)
      )
      .subscribe((val) => {
        this.param = val as IFieldParameter;
        let curvalue = "";
        for (const o of this.param.choices) {
          if (o.current) {
            curvalue = o.value;
          }
          this.form.setValue({value: curvalue}, {emitEvent: false});
        }
      });
    this.form.valueChanges
      .pipe(takeUntil(this._subscriptions))
      .subscribe(async () => {
        this.params.setByName(this.part, this.name, {value: this.form.value.value});
      });

  }

}
