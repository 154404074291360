import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Article} from "../../catalog/article/article.service";
import {ArticleTarifItem} from "./articletarifitem.service";
import {Tarif} from "../tarif/tarif.service";
import {ArticleTarifTemplate} from "./articletariftemplate.service";


export interface LastPriceData {
  value: number;
  date: Date|null;
  current?: boolean;
}

export interface TarifMarcheData {
  marche: string;
  mname: string;
  start: Date;
  date: Date;
  end: Date;
  price: number;
  min: number;
  max: number;
  lib: string;
}


export interface TarifRelationData {
  cprice: number|null; // Computed price
  oprice: number|null; // Original price
  sprice: number|null; // Storages price
  soprice: number|null; // Storages original prices
  date: Date;
  end: Date;
  ref: number;
  rel: number;
  sto?: number;
  src: number;
  libelle?: string;
}


export enum ArticleTarifStatus {
  TOVALIDATE = "TV",
  VALID = "OK",
  NOPRICE = "NC",
  NOTAVAILABLE = "ND",
  INACTIVE = "KO"
}


export class ArticleTarif extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;
  @DSForeignKeyField({
    name: "tarif",
    verboseName: "Tarif",
    required: false,
    helpText: "Tarif",
    relatedModel: "tarifs.Tarif",
    remoteField: "id",
    relatedName: "lines"
  })
  public tarif!: number;
  @DSForeignKeyField({
    name: "att",
    verboseName: "Article",
    required: false,
    helpText: "Article",
    relatedModel: "catalog.ArticleTarifTemplate",
    remoteField: "id",
    relatedName: "tariflines"
  })
  public att!: number;
  public att_details!: ArticleTarifTemplate;
  @DSDecimalField({
    name: "price",
    verboseName: "Prix",
    required: false,
    helpText: "Prix",
    default: null,
    factor: 10000,
  })
  public price!: number;
  @DSTextField({
    name: "message",
    verboseName: "Message",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 5000,
    choices: []
  })
  public message!: string;
  @DSManyToManyField({
    name: "items",
    verboseName: "Eléments",
    required: false,
    relatedModel: "tarif.ArticleTarif",
    remoteField: "items",
    helpText: "Eléments",
    default: [],
  })
  public items!: number[];
  public items_details!: ArticleTarifItem[];

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "Etat",
    minLength: 0,
    maxLength: 2,
    choices: [
      [ArticleTarifStatus.VALID, "Valide"],
      [ArticleTarifStatus.NOPRICE, "Sans prix"],
      [ArticleTarifStatus.NOTAVAILABLE, "Non disponible"],
      [ArticleTarifStatus.TOVALIDATE, "A valider"],
      [ArticleTarifStatus.INACTIVE, "Désactivé"]
    ]
  })
  public status!: ArticleTarifStatus;
  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;
  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;
  public storageItems!: { [index: number]: ArticleTarifItem };
  public marcheItems!: { [index: string]: ArticleTarifItem };
  public lastprices!: LastPriceData[];
  public tarifsmarches!: TarifMarcheData[];
  public tarifsrelations!: TarifRelationData[];
  private _attObject!: ArticleTarifTemplate;

  @DSDefaultField({
    name: "article_uf",
    verboseName: "UF",
    required: false,
    helpText: "UF"
  })
  public get article_uf(): string|null {
    if (!this.hatt.harticle) {
      return null;
    }
    return this.hatt.harticle.udf_unit;
  }

  @DSDefaultField({
    name: "article_code",
    verboseName: "Code catalogue",
    required: false,
    helpText: "Code catalogue"
  })
  public get article_code(): string|null {
    if (!this.hatt.harticle) {
      return null;
    }
    return this.hatt.harticle.code;
  }

  @DSDefaultField({
    name: "article_family",
    verboseName: "Famille catalogue",
    required: false,
    helpText: "Famille catalogue"
  })
  public get article_family(): any {
    if (!this.hatt.harticle) {
      return null;
    }
    return (this.hatt.harticle?.family_cfullname?.cfullname || "").split(" : ");
  }

  @DSDefaultField({
    name: "eaclient_libelle",
    verboseName: "Libellé client",
    required: false,
    helpText: "Libellé client"
  })
  public get eaclient_libelle(): string {
    if (!this.hatt.heaclient) {
      return "";
    }
    return this.hatt.heaclient.libelle;
  }

  @DSDefaultField({
    name: "eaclient_code",
    verboseName: "Code client",
    required: false,
    helpText: "Code client"
  })
  public get eaclient_code(): string {
    if (!this.hatt.heaclient) {
      return "";
    }
    return this.hatt.heaclient.code;
  }

  public get hatt(): ArticleTarifTemplate {
    if (!this._attObject) {
      this._attObject = new ArticleTarifTemplate(undefined, this.att_details);
    }
    return this._attObject;
  }

  public processItems(): void {
    // SEE: check if still necessary
    this.storageItems = {};
    this.marcheItems = {};
    if (!this.items_details) {
      return;
    }
    for (const i of this.items_details) {
      if (i.storage) {
        this.storageItems[i.storage] = i;
      }
    }


  }
}

@Injectable({
  providedIn: "root"
})
export class ArticleTarifService extends DSRestCollection<ArticleTarif> {
  public adapter_config = {basePath: "/api/v2/tarifs/articletarifs"};
  public model = ArticleTarif;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
