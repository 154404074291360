import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {VideoPlayerComponent} from "./video-player/video-player.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsacModelListModule,
    BsacApiConstantsModule,

    BsacFormsModule,
    NgbModule,
    ReactiveFormsModule
  ],
  declarations: [VideoPlayerComponent],
  exports: [VideoPlayerComponent],
})
export class VideoModule {
}
