import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Article} from "../../catalog/article/article.service";
import {ArticleTarifRnm} from "./articletarifrnm.service";
import {ArticleEgalim} from "../../catalog/articledata/article-egalim.service";
import {ExternalArticle} from "../../catalog/externalarticle/externalarticle.service";
import {AttRelation} from "../tariftyperelation/tariftyperelation.service";

export enum ArticleTarifTemplateStatus {
  ACTIVE = "ACT",
  INACTIVE = "NAC",
  ARCHIVED = "ARC",
  DELETED = "DEL"
}
export class ArticleTarifTemplate extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public name!: string;

  @DSForeignKeyField({
    name: "tarif_type",
    verboseName: "Modèle tarif",
    required: false,
    helpText: "Modèle tarif",
    relatedModel: "tarif.TarifType",
    remoteField: "id",
    relatedName: "groups"
  })
  public tarif_type!: number;

  @DSForeignKeyField({
    name: "article",
    verboseName: "Article",
    required: false,
    helpText: "Article",
    relatedModel: "catalog.Article",
    remoteField: "id",
    relatedName: "tariftemplates",
  })
  public article!: number;
  public article_details!: Article;

  @DSForeignKeyField({
    name: "eaclient",
    verboseName: "Article client",
    required: false,
    helpText: "Article client",
    relatedModel: "catalog.ExternalArticle",
    remoteField: "id",
    relatedName: "clienttariftemplates"
  })
  public eaclient!: number;
  public eaclient_details!: ExternalArticle;

  @DSManyToManyField({
    name: "eastorages",
    verboseName: "Articles dépôts",
    required: false,
    helpText: "Articles dépôts",
    relatedModel: "catalog.ExternalArticle",
    remoteField: "id"
  })
  public eastorages!: number[];
  public eastorages_details!: ExternalArticle[];

  @DSDateField({
    name: "datestart",
    verboseName: "Date de début",
    required: false,
    helpText: "Date de début",
  })
  public datestart!: Date | null;

  @DSDateField({
    name: "dateend",
    verboseName: "Date de fin",
    required: false,
    helpText: "Date de fin",
  })
  public dateend!: Date | null;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "ACT",
    choices: [
      [ArticleTarifTemplateStatus.ACTIVE, "Actif"],
      [ArticleTarifTemplateStatus.INACTIVE, "Non actif"],
      [ArticleTarifTemplateStatus.ARCHIVED, "Archivé"],
      [ArticleTarifTemplateStatus.DELETED, "Supprimé"]
    ],
    maxLength: 10
  })
  public status!: ArticleTarifTemplateStatus;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  @DSDefaultField({
    name: "rnms",
    verboseName: "Liens RNM",
    required: false,
    helpText: "Liens RNM"
  })
  public rnms_details!: ArticleTarifRnm[];
  private _isEgalim: boolean = false;
  private _articleObject?: Article;
  private _eaclientObject?: ExternalArticle;

  @DSDefaultField({
    name: "article__family__cfullname",
    verboseName: "Famille article",
    required: false,
    helpText: "Famille article"
  })
  public get article__family__cfullname(): string {
    return (this.article_details as any)?.cfullname || "-"; // ArticleAttSerializer have direct cfullname field
  }

  @DSDefaultField({
    name: "article_calibre",
    verboseName: "Calibre",
    required: false,
    helpText: "Calibre"
  })
  public get article_calibre(): string {
    if (!this.article_details || !this.article_details.calibre_details) {
      return "-";
    }
    return this.article_details.calibre_details.name || this.article_details.calibre_details.code || "";
  }

  @DSDefaultField({
    name: "article_categorie",
    verboseName: "Catégorie",
    required: false,
    helpText: "Catégorie"
  })
  public get article_categorie(): string {
    if (!this.article_details || !this.article_details.categorie_details) {
      return "-";
    }
    return this.article_details.categorie_details.name || this.article_details.categorie_details.code || "";
  }

  @DSDefaultField({
    name: "article_egalim_details",
    verboseName: "Labels et certifications",
    required: false,
    helpText: "Labels et certification"
  })
  public get article_egalim_details(): ArticleEgalim[] {
    if (!this.article_details || !this.article_details.egalim_details) {
      return [];
    }
    return this.harticle.egalim_details;
  }

  @DSDefaultField({
    name: "article_origine",
    verboseName: "Origine",
    required: false,
    helpText: "Origine"
  })
  public get article_origine(): string {
    if (!this.article_details || !this.article_details.origine_details) {
      return "-";
    }
    return this.article_details.origine_details.name || this.article_details.origine_details.code || "";
  }

  @DSDefaultField({
    name: "article_local",
    verboseName: "Local",
    required: false,
    helpText: "Local"
  })
  public get article_local(): string {
    if (!this.harticle) {
      return "-";
    }
    return this.harticle.local;
  }

  @DSDefaultField({
    name: "article_is_egalim",
    verboseName: "Egalim",
    required: false,
    helpText: "Egalim",
  })
  public get article_is_egalim(): boolean {
    if (!this.article_details) {
      return false;
    }
    return this.article_details.isEgalim;
  }

  @DSDefaultField({
    name: "article_colisage",
    verboseName: "Colisage",
    required: false,
    helpText: "Colisage"
  })
  public get article_colisage(): Article | null {
    if (!this.harticle) {
      return null;
    }
    return this.harticle;
  }

  @DSDefaultField({
    name: "article_uci",
    verboseName: "UCI",
    required: false,
    helpText: "UCI"
  })
  public get article_uci(): Article | null {
    if (!this.harticle) {
      return null;
    }
    return this.harticle;
  }

  @DSDefaultField({
    name: "article_uc",
    verboseName: "UC",
    required: false,
    helpText: "UC"
  })
  public get article_uc(): any {
    if (!this.harticle) {
      return null;
    }
    return this.harticle.udv_value / 10000;
  }

  @DSDefaultField({
    name: "article_uf",
    verboseName: "UF",
    required: false,
    helpText: "UF"
  })
  public get article_uf(): string {
    if (!this.harticle) {
      return "";
    }
    return this.harticle.udf_unit;
  }

  @DSDefaultField ({
    name: "relations_details",
    verboseName: "Détails relations",
    required: false
  })
  public relations_details: AttRelation[]


  public get harticle(): Article {
    if (!this._articleObject) {
      this._articleObject = new Article(undefined, this.article_details);
    }
    return this._articleObject;
  }

  public get heaclient(): ExternalArticle {
    if (!this._eaclientObject) {
      this._eaclientObject = new ExternalArticle(undefined, this.eaclient_details);
    }
    return this._eaclientObject;
  }
}

@Injectable({
  providedIn: "root"
})
export class ArticleTarifTemplateService extends DSRestCollection<ArticleTarifTemplate> {
  public adapter_config = {basePath: "/api/v2/tarifs/articletariftemplates"};
  public model = ArticleTarifTemplate;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
