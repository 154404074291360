import {NgModule} from "@angular/core";
import {GroupuserListComponent} from "./groupuser-list/groupuser-list.component";
import {GroupuserManageComponent} from "./groupuser-manage/groupuser-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {GroupuserCreateComponent} from "./groupuser-create/groupuser-create.component";


@NgModule({
  declarations: [
    GroupuserListComponent,
    GroupuserManageComponent,
    GroupuserCreateComponent
  ],
  exports: [
    GroupuserListComponent,
    GroupuserManageComponent,
    GroupuserCreateComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS
  ]
})
export class GroupuserModule {
}
