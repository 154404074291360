import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FolderTreeComponent} from "./folder-tree/folder-tree.component";
import {FolderDetailComponent} from "./folder-detail/folder-detail.component";
import {FolderCreateComponent} from "./folder-create/folder-create.component";
import {ReactiveFormsModule} from "@angular/forms";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {FolderBreadcrumbComponent} from "./folder-breadcrumb/folder-breadcrumb.component";
import {CompsModule} from "../../../comps/comps.module";
import {DocumentModule} from "../document/document.module";
import {FolderPropertiesComponent} from "./folder-properties/folder-properties.component";
import {ImageModule} from "../../content/image/image.module";
import {FolderSetaccessComponent} from "./folder-setaccess/folder-setaccess.component";
import {RestoModule} from "../../structure/resto/resto.module";
import {MemberModule} from "../../structure/member/member.module";
import {PersonSelectModule} from "../../people/person/person-select.module";
import {ClientSelectModule} from "../../structure/client/client-select.module";
import {FolderIconComponent} from "./folder-icon/folder-icon.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsacFormsModule,
    CompsModule,
    DocumentModule,
    ImageModule,
    MemberModule,
    ClientSelectModule,
    RestoModule,
    PersonSelectModule
  ],
  declarations: [
    FolderTreeComponent,
    FolderDetailComponent,
    FolderCreateComponent,
    FolderBreadcrumbComponent,
    FolderPropertiesComponent,
    FolderSetaccessComponent,
    FolderIconComponent],
  exports: [
    FolderTreeComponent,
    FolderDetailComponent,
    FolderCreateComponent,
    FolderBreadcrumbComponent
  ]
})
export class FolderModule {
}
