import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Location} from "../location.service";
import {BsacGeolocService, BsacMessageService, IGeoLocResult} from "@solidev/bsadmincomponents";
import {GeoCommune} from "../../../customdata/geocommune/geocommune.service";
import {firstValueFrom} from "rxjs";
import {filter} from "rxjs/operators";

@Component({
  selector: "lvadg-location-manage",
  templateUrl: "./location-manage.component.pug",
  styleUrls: ["./location-manage.component.sass"]
})
export class LocationManageComponent implements OnInit {

  @Input() public location!: Location;
  @Input() public mode = "admin";
  @Input() public embedded = true;
  @Output() public closed = new EventEmitter<any>();
  @Output() public deleted = new EventEmitter<any>();
  @Input() public step = "infos";
  public geoloc!: IGeoLocResult | null;
  public geofailure = false;
  public geochanged = false;
  public editPostCode = false;
  public editCity = false;

  constructor(private _locator: BsacGeolocService,
              private _msgs: BsacMessageService) {
  }

  public ngOnInit(): void {
    if (!this.location.latitude || this.location.status in ["OK", "AY"]) {
      this.geofailure = true;
    } else {
      this.geoloc = {
        type: "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [this.location.longitude, this.location.latitude]
        },
        "properties": {}
      };
    }
  }


  public geoLocate(): void {
    this.geoloc = null;
    this._locator.geocodeAddress(`${this.location.address} ${this.location.caddress}, ${this.location.postcode} ${this.location.city}`)
      .pipe(filter((loc) => !!loc))
      .subscribe({
        next: (loc: IGeoLocResult) => {
          console.log("Geoloc result", loc);
          this.step = "geoloc";
          if (!loc.properties.type) {
            this.geofailure = true;
          } else {
            this.geoloc = loc;
            this.location.position = `POINT(${loc.geometry.coordinates[0]} ${loc.geometry.coordinates[1]})`;
            this.location.longitude = loc.geometry.coordinates[0];
            this.location.latitude = loc.geometry.coordinates[1];
            this.location.status = "AY";
            this.location.geotype = loc.properties.type;
            this.geochanged = true;
          }
        }, error: (err) => {
          this.step = "geoloc";
          this.geofailure = true;
          console.log("Geoloc error", err);
        }
      });
  }

  public async setCommune(commune: GeoCommune): Promise<void> {
    this.location.postcode = commune.postcode;
    this.location.city = commune.postname;
    await firstValueFrom(this.location.update(["postcode", "city", "geocommune"]));
    this._msgs.success("Commune changée avec succès");
    this.editPostCode = false;
    this.editCity = false;
  }

  public manualPosition(location: Location) {
    this.location.position = `POINT(${location.longitude} ${location.latitude})`;
    this.location.status = "OK";
    this.location.geotype = "housenumber";
    this.geochanged = true;
  }

  public savePosition() {
    this.location.status = "OK";
    this.location.update(["position", "status", "geotype"]).subscribe(() => {
      this._msgs.success("Position enregistrée avec succès");
      this.closed.emit(true);
    });
  }

  public onDelete() {
    this.deleted.emit(this.location);
  }

}
