import {NgModule} from "@angular/core";
import {ContactListViewComponent} from "./contact-list-view/contact-list-view.component";
import {ContactManageViewComponent} from "./contact-manage-view/contact-manage-view.component";
import {VIEWS_COMMON_COMMON_MODULES} from "../views-common-modules";
import {ContactModule} from "../../../lib/models/people/contact/contact.module";
import {SendDestinationJobStatusModule} from "../../../lib/models/comm/mailer/senddestinationjobstatus/senddestinationjobstatus.module";


@NgModule({
  declarations: [
    ContactListViewComponent,
    ContactManageViewComponent
  ],
  exports: [
    ContactListViewComponent,
    ContactManageViewComponent
  ],
  imports: [
    ...VIEWS_COMMON_COMMON_MODULES,
    ContactModule,
    SendDestinationJobStatusModule
  ]
})
export class ViewsContactModule {
}
