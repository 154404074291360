import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CustomDataCreateComponent} from "./customdata-create/customdata-create.component";
import {CustomDataDeleteComponent} from "./customdata-delete/customdata-delete.component";
import {CustomDataDisplayComponent} from "./customdata-display/customdata-display.component";
import {CustomDataListComponent} from "./customdata-list/customdata-list.component";
import {CustomDataManageComponent} from "./customdata-manage/customdata-manage.component";
import {CustomDataService} from "./customdata.service";
import {CustomDataResolve} from "./customdata.resolve";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomDataKeyModule} from "../customdatakey/customdatakey.module";
import {CustomDataFieldManageComponent} from "./customdata-field-manage/customdata-field-manage.component";
import {CustomDataValueDisplayComponent} from "./customdata-value-display/customdata-value-display.component";
import {CustomDataValueEditComponent} from "./customdata-value-edit/customdata-value-edit.component";
import {CustomDataFieldDisplayComponent} from "./customdata-field-display/customdata-field-display.component";
import {CustomDataRowfieldDisplayComponent} from "./customdata-rowfield-display/custom-data-rowfield-display.component";
import {CustomdataModellistFilterChoicesComponent} from "./customdata-modellist-filter-choices/customdata-modellist-filter-choices.component";
import {CustomdataValueEditDocumentComponent} from "./customdata-value-edit-document/customdata-value-edit-document.component";
import {CustomdataValueEditFolderComponent} from "./customdata-value-edit-folder/customdata-value-edit-folder.component";
import {CustomdataValueEditFileComponent} from "./customdata-value-edit-file/customdata-value-edit-file.component";
import {CustomdataModellistFiltersComponent} from "./customdata-modellist-filters/customdata-modellist-filters.component";
import {CustomdataNutriComponent} from "./customdata-nutri/customdata-nutri.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsacModelListModule,
    BsacApiConstantsModule,

    BsacFormsModule,
    NgbModule,
    ReactiveFormsModule,
    CustomDataKeyModule,
    FormsModule
  ],
  providers: [
    CustomDataService,
    CustomDataResolve
  ],
  declarations: [
    CustomDataDisplayComponent,
    CustomDataDeleteComponent,
    CustomDataListComponent,
    CustomDataManageComponent,
    CustomDataCreateComponent,
    CustomDataFieldManageComponent,
    CustomDataValueDisplayComponent,
    CustomDataValueEditComponent,
    CustomDataFieldDisplayComponent,
    CustomDataRowfieldDisplayComponent,
    CustomdataModellistFilterChoicesComponent,
    CustomdataValueEditDocumentComponent,
    CustomdataValueEditFolderComponent,
    CustomdataValueEditFileComponent,
    CustomdataModellistFiltersComponent,
    CustomdataNutriComponent
  ],
  exports: [
    CustomDataDisplayComponent,
    CustomDataDeleteComponent,
    CustomDataListComponent,
    CustomDataManageComponent,
    CustomDataCreateComponent,
    CustomDataFieldManageComponent,
    CustomDataValueDisplayComponent,
    CustomDataValueEditComponent,
    CustomDataFieldDisplayComponent,
    CustomDataRowfieldDisplayComponent,
    CustomdataModellistFilterChoicesComponent,
    CustomdataModellistFiltersComponent,
    CustomdataNutriComponent
  ],
})
export class CustomDataModule {
}
