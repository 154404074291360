import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {ProductPriceSelectComponent} from "./productprice-select/productprice-select.component";

@NgModule({
  imports: [CommonModule, BsacFormsModule],
  declarations: [ProductPriceSelectComponent],
  exports: [ProductPriceSelectComponent]
})
export class ProductPriceSelectModule {}
