import {NgModule} from "@angular/core";
import {UsageDeleteComponent} from "./usage-delete/usage-delete.component";
import {UsageDisplayComponent} from "./usage-display/usage-display.component";
import {UsageListComponent} from "./usage-list/usage-list.component";
import {UsageService} from "./usage.service";
import {UsageResolve} from "./usage.resolve";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS
  ],
  providers: [
    UsageService,
    UsageResolve
  ],
  declarations: [
    UsageDisplayComponent,
    UsageDeleteComponent,
    UsageListComponent,
  ],
  exports: [
    UsageDisplayComponent,
    UsageDeleteComponent,
    UsageListComponent,
  ],
})
export class UsageModule {
}
