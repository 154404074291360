import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {CommJob} from "../basecomm/commjob/commjob.service";


export interface SenderJobDataResult {
  [index: number]: string;
}

export interface SenderJobData {
  id: number;
  model: number;
  progress: number;
  result: SenderJobDataResult;
  status: string;
  total: number;
}

export class SenderJob extends CommJob {
  public data!: SenderJobData;
}


@Injectable({
  providedIn: "root"
})
export class SenderJobService extends DSRestCollection<SenderJob> {
  public adapter_config = {basePath: "/api/v2/senderjobs"};
  public model = SenderJob;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
