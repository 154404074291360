import {NgModule} from "@angular/core";
import {ArticletarifListmanageComponent} from "./articletarif-listmanage/articletarif-listmanage.component";
import {ArticletarifStatsComponent} from "./articletarif-stats/articletarif-stats.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ArticletarifPriceEditorComponent} from "./articletarif-listmanage/articletarif-price-editor/articletarif-price-editor.component";
import {
  ArticletarifStatsTimeserieComponent
} from "./articletarif-stats/articletarif-stats-timeserie/articletarif-stats-timeserie.component";
import {
  ArticletarifStatsTimeseriesComponent
} from "./articletarif-stats/articletarif-stats-timeseries/articletarif-stats-timeseries.component";
import {ArticletarifCreateComponent} from "./articletarif-create/articletarif-create.component";
import {ArticleModule} from "../../catalog/article/article.module";
import {MeteoModule} from "../../stats/meteo/meteo.module";
import {ArticletarifStatsScatterComponent} from "./articletarif-stats/articletarif-stats-scatter/articletarif-stats-scatter.component";
import {ArticletariftemplateListComponent} from "./articletariftemplate-list/articletariftemplate-list.component";
import {ArticletariftemplateManageComponent} from "./articletariftemplate-manage/articletariftemplate-manage.component";
import {NgxEchartsModule} from "ngx-echarts";
import {RnmModule} from "../rnm/rnm.module";
import {
  ArticletarifTarifmarcheDisplayComponent
} from "./articletarif-listmanage/articletarif-tarifmarche-display/articletarif-tarifmarche-display.component";
import {
  ArticletarifTarifrelationDisplayComponent
} from "./articletarif-listmanage/articletarif-tarifcommission-display/articletarif-tarifrelation-display.component";
import {
  ArticletarifLastpriceDisplayComponent
} from "./articletarif-listmanage/articletarif-lastprice-display/articletarif-lastprice-display.component";
import {
  ArticletarifStatsInfosMarchesComponent
} from "./articletarif-stats/articletarif-stats-infos-marches/articletarif-stats-infos-marches.component";
import {
  ArticletarifMessageManageComponent
} from "./articletarif-listmanage/articletarif-message-manage/articletarif-message-manage.component";
import {NgStringPipesModule} from "ngx-pipes";
import {ChartsModule} from "../../../comps/charts/charts.module";
import {LabelModule} from "../../product/label/label.module";
import {SeaLabelModule} from "../../product/sealabel/sealabel.module";
import {ArticleTarifTemplateStatsComponent} from "./articletariftemplate-stats/articletariftemplate-stats.component";
import {ArticletarifListComponent} from "./articletarif-list/articletarif-list.component";
import {
  ArticletarifColumnOperationsComponent
} from "./articletarif-listmanage/articletarif-column-operations/articletarif-column-operations.component";
import {AttrelationManageComponent} from "./attrelation-manage/attrelation-manage.component";

@NgModule({
  declarations: [
    ArticletarifListmanageComponent,
    ArticletarifStatsComponent,
    ArticletarifPriceEditorComponent,
    ArticletarifStatsTimeserieComponent,
    ArticletarifStatsTimeseriesComponent,
    ArticletarifCreateComponent,
    ArticletarifStatsScatterComponent,
    ArticletariftemplateListComponent,
    ArticletariftemplateManageComponent,
    ArticletarifTarifmarcheDisplayComponent,
    ArticletarifTarifrelationDisplayComponent,
    ArticletarifLastpriceDisplayComponent,
    ArticletarifStatsInfosMarchesComponent,
    ArticletarifMessageManageComponent,
    ArticleTarifTemplateStatsComponent,
    ArticletarifListComponent,
    ArticletarifColumnOperationsComponent,
    AttrelationManageComponent
  ],
  exports: [
    ArticletarifListmanageComponent,
    ArticletarifStatsComponent,
    ArticletariftemplateListComponent,
    ArticletariftemplateManageComponent,
    ArticleTarifTemplateStatsComponent,
    ArticletarifListComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    ArticleModule,
    ChartsModule,
    MeteoModule,
    LabelModule,
    SeaLabelModule,
    RnmModule,
    NgxEchartsModule,
    NgStringPipesModule
  ]
})
export class ArticletarifModule {
}
