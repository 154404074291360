import {Component, Input, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {NgbOffcanvasRef} from "@ng-bootstrap/ng-bootstrap/offcanvas/offcanvas-ref";
import {ColOpModes, IAtlmStorage} from "../articletarif-listmanage.interfaces";
import {Tarif, TarifStatuses} from "../../../tarif/tarif.service";
import {firstValueFrom, Subject} from "rxjs";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {FormControl, FormGroup} from "@angular/forms";


@Component({
  selector: "lvadg-articletarif-column-operations",
  templateUrl: "./articletarif-column-operations.component.pug",
  styleUrls: ["./articletarif-column-operations.component.sass"]
})
export class ArticletarifColumnOperationsComponent implements OnInit {
  @Input() public mode: ColOpModes = ColOpModes.GlobalPrice;
  @Input() public name = "Colonne";
  @Input() public tarif!: Tarif;
  @Input() public reload$!: Subject<void>;
  @Input() public storage?: IAtlmStorage;
  @Input() public storages: IAtlmStorage[] = [];

  @ViewChild("offcanvas", {static: true}) public ofcdiv!: TemplateRef<HTMLDivElement>;
  public colop = ColOpModes;
  private _ofcref?: NgbOffcanvasRef;
  public running = {
    import: false,
    validation: false
  }
  public copyForm = new FormGroup({
    source: new FormControl(),
    ops: new FormControl("")
  });

  constructor(private _ofc: NgbOffcanvas,
              private _msgs: BsacMessageService) {
  }

  ngOnInit(): void {
  }

  public async openOffCanvas(): Promise<void> {
    this._ofcref = this._ofc.open(this.ofcdiv, {position: "end"});
  }

  public async validateColumn(st?: IAtlmStorage): Promise<void> {
    this.running.validation = true;
    try {
      await firstValueFrom(this.tarif.action("column_action", {
        method: "POST",
        body: {
          mode: this.mode,
          action: "validate",
          storage: st?.storageId
        }
      }));
      this._msgs.success("Validation effectuée avec succés");
    } catch (e) {
      this._msgs.error("Erreur lors de la validation de la colonne", (e as any).message || `${e}`);
    }
    this.reload$.next();
    this.running.validation = false;
    this._ofcref.close();
  }

  public canEdit(): boolean {
    const editor = ["sadmin", "admin", "write"].indexOf(this.tarif.user_level) !== -1;
    return (editor && this.mode === ColOpModes.GlobalPrice && this.tarif.status === TarifStatuses.Preparation) ||
      (editor && this.mode === ColOpModes.StoragePrice && this.tarif.status === TarifStatuses.Preparation &&
        this.storage !== undefined && !this.storage.readonly);
  }

  public async importColumn(st: IAtlmStorage): Promise<void> {
    this.running.import = true;
    try {
      await firstValueFrom(this.tarif.action("column_action", {
        method: "POST",
        body: {
          mode: this.mode,
          action: "import",
          storage: st?.storageId,
          source: this.copyForm.value.source,
          ops: this.copyForm.value.ops
        }
      }));
      this._msgs.success("Importation effectuée avec succés");
    } catch (e) {
      this._msgs.error("Erreur lors de l'importation de la colonne", (e as any).message || `${e}`);
    }
    this.reload$.next();
    this.running.import = false;
    this._ofcref.close();

  }
}
