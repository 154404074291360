import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ProducerSelectComponent} from "./producer-select/producer-select.component";
import {BsacFormsModule} from "@solidev/bsadmincomponents";


@NgModule({
  imports: [
    CommonModule,
    BsacFormsModule
  ],
  declarations: [
    ProducerSelectComponent
  ],
  exports: [
    ProducerSelectComponent
  ],
})
export class ProducerSelectModule {
}
