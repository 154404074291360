import {Component, Input, OnChanges} from "@angular/core";
import {TimeSeriesChartDataset} from "../../../../../comps/charts/formats";

interface TimeSerieStat {
  name: string;
  title: string;
  unit: string;
  label: string;
  type: "timeserie";
  values: {
    year: number;
    week: number;
    value: number;
  }[];
}


@Component({
  selector: "lvadg-articletarif-stats-timeserie",
  templateUrl: "./articletarif-stats-timeserie.component.pug",
  styleUrls: ["./articletarif-stats-timeserie.component.sass"]
})
export class ArticletarifStatsTimeserieComponent implements OnChanges {
  @Input() public stat!: TimeSerieStat;
  public stats!: TimeSeriesChartDataset;

  constructor() {
  }

  ngOnChanges(): void {
    this.stats = {
      title: "",
      subtitle: "",
      data: [["Date", this.stat.label]],
      legend: false,
      stacked: false,
      axis: {
        time: {title: "Date", unit: ""},
        value: {title: this.stat.label, unit: this.stat.unit}
      }
    };
    for (const v of this.stat.values) {
      this.stats.data!.push([new Date(v.year, 0, v.week * 7), v.value]);
    }
  }

}
