import {NgModule} from "@angular/core";
import {ArticleListComponent} from "./article-list/article-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ArticleManageComponent} from "./article-manage/article-manage.component";
import {ProductCommonModule} from "../../product/common/product-common.module";
import {ArticleDetailsComponent} from "./article-details/article-details.component";
import {LabelModule} from "../../product/label/label.module";
import {SeaLabelModule} from "../../product/sealabel/sealabel.module";
import {BaseDocumentModule} from "../../document/basedocument/base-document.module";
import {ImageModule} from "../../content/image/image.module";
import {SelectionModule} from "../../selection/selection.module";
import {BsacFormatModule} from "@solidev/bsadmincomponents";
import {NgbOffcanvasModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ArticleStatDetailComponent} from "./article-stat-detail/article-stat-detail.component";
import {ChartsModule} from "../../../comps/charts/charts.module";


@NgModule({
  declarations: [ArticleListComponent, ArticleManageComponent, ArticleDetailsComponent, ArticleStatDetailComponent],
  imports: [
    ...MODELLIST_IMPORTS,
    LabelModule,
    SeaLabelModule,
    ImageModule,
    ProductCommonModule,
    BaseDocumentModule,
    SelectionModule,
    BsacFormatModule,
    NgbTooltipModule,
    NgbOffcanvasModule,
    ChartsModule
  ],
  exports: [ArticleListComponent, ArticleManageComponent, ArticleDetailsComponent]
})
export class ArticleModule {
}
