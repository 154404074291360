<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'statuses'; desc: 'Recherche par état(s)'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="statuses" choices="DETAILDOCUMENT_STATUSES"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'type'; desc: 'Recherche par type'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="type" choices="DETAILDOCUMENT_TYPES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'labels'; desc:'Recherche par label'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="labels" [choices]="labels"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producers'; desc:'Recherche par producteur(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="producers" [queryset]="producer$" descfield="name" hint="Sélectionnez un producteur" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storages'; desc:'Recherche par dépôt(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storages" [queryset]="storage$" descfield="name" hint="Sélectionnez un dépôt" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'members'; desc:'Recherche par adhérent(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="members" [queryset]="members$" descfield="name" hint="Sélectionnez un adhérent" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'providers'; desc:'Recherche par fournisseur(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="providers" [queryset]="provider$" descfield="name" hint="Sélectionnez un fournisseur" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status|choice:row:'status'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="title">
            <th cdk-header-cell *cdkHeaderCellDef>Titre</th>
            <td cdk-cell *cdkCellDef="let row">{{row.title}}</td>
          </ng-container>
          <ng-container cdkColumnDef="slug">
            <th cdk-header-cell *cdkHeaderCellDef>Slug</th>
            <td cdk-cell *cdkCellDef="let row">{{row.slug}}</td>
          </ng-container>
          <ng-container cdkColumnDef="mimetype">
            <th cdk-header-cell *cdkHeaderCellDef>Type de fichier</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mimetype}}</td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type de document</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type}}</td>
          </ng-container>
          <ng-container cdkColumnDef="filename">
            <th cdk-header-cell *cdkHeaderCellDef>Nom du fichier</th>
            <td cdk-cell *cdkCellDef="let row">{{row.filename}}</td>
          </ng-container>
          <ng-container cdkColumnDef="filesize">
            <th cdk-header-cell *cdkHeaderCellDef>Taille du fichier</th>
            <td cdk-cell *cdkCellDef="let row">{{row.filesize | bytes:2 }}</td>
          </ng-container>
          <ng-container cdkColumnDef="file">
            <th cdk-header-cell *cdkHeaderCellDef>URL fichier</th>
            <td cdk-cell *cdkCellDef="let row"><a [href]="row.file" target="_blank"><i class="fa fa-link"></i></a></td>
          </ng-container>
          <ng-container cdkColumnDef="image">
            <th cdk-header-cell *cdkHeaderCellDef>Image</th>
            <td cdk-cell *cdkCellDef="let row">{{row.image}}</td>
          </ng-container>
          <ng-container cdkColumnDef="start">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="start">Date de début</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.start|date:'shortDate'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="end">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="end">Date de fin</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.end|date:'shortDate'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="image_img">
            <th cdk-header-cell *cdkHeaderCellDef>Miniature</th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-rimg [src]="row.image_img" display="thumb32"></lvadg-rimg>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="download_link">
            <th cdk-header-cell *cdkHeaderCellDef>Lien .io</th>
            <td cdk-cell *cdkCellDef="let row">{{row.download_link}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producer">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="producer__name">Producteur</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.producer"><a [routerLink]="routes.detail('fl', 'producers', row.producer)">{{row.producer_details?.name}}</a></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="prlabel">
            <th cdk-header-cell *cdkHeaderCellDef>Label produit</th>
            <td cdk-cell *cdkCellDef="let row">{{row.prlabel_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="products">
            <th cdk-header-cell *cdkHeaderCellDef>Produits</th>
            <td cdk-cell *cdkCellDef="let row">{{row.products}}</td>
          </ng-container>
          <ng-container cdkColumnDef="seaproducer">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="seaproducer__name">Fournisseur marée</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.seaproducer"><a [routerLink]="routes.detail('fl', 'seaproducers', row.seaproducer)">{{row.seaproducer_details?.name}}</a></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="seaprlabel">
            <th cdk-header-cell *cdkHeaderCellDef>Label produit marée</th>
            <td cdk-cell *cdkCellDef="let row">{{row.seaprlabel}}</td>
          </ng-container>
          <ng-container cdkColumnDef="seaproducts">
            <th cdk-header-cell *cdkHeaderCellDef>Produits marée</th>
            <td cdk-cell *cdkCellDef="let row">{{row.seaproducts}}</td>
          </ng-container>
          <ng-container cdkColumnDef="provider">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="provider__name">Fournisseur</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.provider"><a [routerLink]="routes.detail('providers', row.provider)">{{row.provider_details?.name}}</a></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="storage">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="storage__name">Dépôt</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.storage"><a [routerLink]="routes.detail('storages', row.storage)">{{row.storage_details?.name}}</a></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="stlabel">
            <th cdk-header-cell *cdkHeaderCellDef>Label structure</th>
            <td cdk-cell *cdkCellDef="let row">{{row.stlabel}}</td>
          </ng-container>
          <ng-container cdkColumnDef="articlefamily">
            <th cdk-header-cell *cdkHeaderCellDef>Famille article</th>
            <td cdk-cell *cdkCellDef="let row">{{row.articlefamily}}</td>
          </ng-container>
          <ng-container cdkColumnDef="articles">
            <th cdk-header-cell *cdkHeaderCellDef>Articles</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let a of row.articles_details">{{a.libelle}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="egalim">
            <th cdk-header-cell *cdkHeaderCellDef>Egalim</th>
            <td cdk-cell *cdkCellDef="let row">{{row.egalim}}</td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>