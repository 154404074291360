import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef} from "@angular/core";
import {ArticleTarif, TarifRelationData} from "../../articletarif.service";
import {IAtlmRelation} from "../articletarif-listmanage.interfaces";
import {NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {TarifTypeRelation, TarifTypeRelationService} from "../../../tariftyperelation/tariftyperelation.service";
import {firstValueFrom, Observable} from "rxjs";
import {ArticleTarifTemplate, ArticleTarifTemplateService} from "../../articletariftemplate.service";
import {TarifRelationService} from "../../../tarifrelation/tarifrelation.service";
import {NgbOffcanvasRef} from "@ng-bootstrap/ng-bootstrap/offcanvas/offcanvas-ref";

@Component({
  selector: "lvadg-articletarif-tarifrelation-display",
  templateUrl: "./articletarif-tarifrelation-display.component.pug",
  styleUrls: ["./articletarif-tarifrelation-display.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticletarifTarifrelationDisplayComponent implements OnInit {
  @Input() public at!: ArticleTarif;
  @Input() public trel!: IAtlmRelation;
  @Input() public update$?: Observable<any>;
  @Output() public updated = new EventEmitter<ArticleTarif>();

  public ttrelation!: TarifTypeRelation;
  public coef = 10000;
  public last?: TarifRelationData;
  public modal?: NgbOffcanvasRef;
  constructor(private _modalService: NgbOffcanvas,
              private _ttrel: TarifTypeRelationService,
              private _trel: TarifRelationService,
              public atts$: ArticleTarifTemplateService) {
  }

  public ngOnInit(): void {
    if (this.update$ && this.update$.subscribe) {
      this.update$.subscribe(() => {
        this.getPrices();
      })
    } else {
      this.getPrices();
    }
  }



  public getPrices(): void {
    const found: TarifRelationData[] = [];
    if (this.at.tarifsrelations && this.trel) {
      for (const rp of this.at.tarifsrelations) {
        if (this.trel.stoId) {
          if (rp.rel === this.trel.relId && rp.sto === this.trel.stoId) {
            found.push(rp);
          }
        } else if (rp.rel === this.trel.relId && !rp.sto) {
          found.push(rp);
        }
      }
    }
    found.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
    this.last = found[0];
    console.log("Have last", this.last)
  }

  public async editRelation(modal: TemplateRef<any>) {
    const trel = await firstValueFrom(this._trel.get(this.trel.relId));
    this.ttrelation = await firstValueFrom(this._ttrel.get(trel.relation));
    this.modal = this._modalService.open(modal, {position: "end"});
  }

  public async updateRelation(att: ArticleTarifTemplate) {
    await firstValueFrom(this.ttrelation.action("attlink", {
      method: "POST",
      body: {
        src: att.id,
        dest: this.at.att,
        coef: this.coef
      }
    }));
    this.updated.emit(this.at);
    this.modal.close();
  }
}
