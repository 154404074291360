import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";
import {Client} from "../../structure/client/client.service";


export class ArticleClient extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "vvid",
    verboseName: "ID Vivalya",
    required: false,
    helpText: "ID Vivalya",
    minLength: 0,
    maxLength: 16,
    choices: []
  })
  public vvid!: string;


  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["MS", "Non précisé"]
    ]
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;

  @DSForeignKeyField({
    name: "client",
    verboseName: "Client",
    required: false,
    helpText: "Client Vivalya",
    relatedModel: "structure.Client",
    remoteField: "id",
    relatedName: "articleclients"
  })
  public client!: number;
  public client_details!: Client;
}

@Injectable({
  providedIn: "root"
})
export class ArticleClientService extends DSRestCollection<ArticleClient> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/clients"};
  public model = ArticleClient;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
