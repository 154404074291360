import {Component, Input, OnInit} from "@angular/core";
import {ArticleTarif} from "../../articletarif.service";
import {IAtlmMarche} from "../articletarif-listmanage.interfaces";

@Component({
  selector: "lvadg-articletarif-tarifmarche-display",
  templateUrl: "./articletarif-tarifmarche-display.component.pug",
  styleUrls: ["./articletarif-tarifmarche-display.component.sass"]
})
export class ArticletarifTarifmarcheDisplayComponent implements OnInit {
  @Input() public row!: ArticleTarif;
  @Input() public scol!: IAtlmMarche;
  constructor() {
  }

  ngOnInit(): void {
  }

}
