
<ng-container *ngIf="desc">
  <div *ngFor="let sh of desc; let shi = index">
    <div class="table-responsive">
      <table class="table table-sm table-bordered">
        <thead>
          <tr>
            <th colspan="10000">Feuille: {{sh.sheet}}<span class="ms-4 pointer" *ngIf="shi != sheet" (click)="setSheet(shi)">Utiliser cette feuille</span></th>
          </tr>
        </thead>
        <tbody *ngIf="shi==sheet">
          <ng-container *ngFor="let r of sh.headers; let i = index">
            <tr class="text-muted" *ngIf="i &lt; params.skip">
              <td class="pointer" (click)="setSkip(i)">en-tête</td>
              <td *ngFor="let h of r">{{h}}</td>
            </tr>
            <tr *ngIf="i==params.skip">
              <th class="pointer" (click)="setSkip(i)">en-tête</th>
              <th *ngFor="let h of r; let j=index">
                <div>{{h}}</div>
                <select class="form-select form-select-sm" [(ngModel)]="params.headers[j]">
                  <option *ngFor="let f of fields" [value]="f.name">{{f.desc}}</option>
                  <option value="-----">--------------</option>
                  <option [value]="'skip'">Non utilisé</option>
                  <option [value]="'asis'">Importer tel quel</option>
                </select>
              </th>
            </tr>
            <tr *ngIf="i &gt; params.skip">
              <td class="pointer" (click)="setSkip(i)">en-tête</td>
              <td *ngFor="let h of r">{{h}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <button class="btn btn-primary" (click)="valid()">Valider l'association des colonnes</button>
</ng-container>