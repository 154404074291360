import {isArray} from "lodash-es";

export class RimgResp {

  public id!: number;
  public name!: string;
  public orig!: number;
  public image!: string;
  public created!: Date;
  public updated!: Date;
  public status!: string;

  constructor(data: any) {
    Object.assign(<any>this, data);
  }
}


export class Rimg {
  public description!: string;
  public id!: number;
  public link!: string;
  public orig!: string;
  public properties!: any;
  public slug!: string;
  public title!: string;
  public type!: string;
  public flags!: string[];

  public created!: Date;
  public updated!: Date;
  public status!: string;

  public responsive: RimgResp[];

  constructor(data: any) {
    if (data) {
      Object.assign(<any>this, data);
      this.responsive = [];
      if (data.responsive) {
        for (const r of data.responsive) {
          this.responsive.push(new RimgResp(r));
        }
      }
    } else {
      this.responsive = [];
    }
  }

  public byName(name: string | string[]): RimgResp|null {
    if (!isArray(name)) {
      name = [name];
    }
    for (const n of name) {
      for (const r of this.responsive) {
        if (n === r.name) {
          return r;
        }
      }
    }
    return null;
  }

}


export const RIMG_BANNERS: {[index: string]: string} = {
  "xs": "smb",
  "sm": "smb",
  "md": "mdb",
  "lg": "lgb",
  "xl": "lgb"
};

export const RIMG_UP_SIZES = ["xxsm", "xsm", "sm", "md", "lg", "xlg", "xxlg"];
export const RIMG_DOWN_SIZES = ["xxlg", "xlg", "lg", "md", "sm", "xsm", "xxsm"];
