import {NgModule} from "@angular/core";
import {ProviderCreateComponent} from "./provider-create/provider-create.component";
import {ProviderDeleteComponent} from "./provider-delete/provider-delete.component";
import {ProviderListComponent} from "./provider-list/provider-list.component";
import {ProviderManageComponent} from "./provider-manage/provider-manage.component";
import {LocationModule} from "../location/location.module";
import {CompsModule} from "../../../comps/comps.module";
import {ImageModule} from "../../content/image/image.module";
import {StatsModule} from "../../stats/stats/stats.module";
import {SLabelModule} from "../slabel/slabel.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {BaseDocumentModule} from "../../document/basedocument/base-document.module";
import {ProviderDisplayComponent} from "./provider-display/provider-display.component";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    LocationModule,
    ImageModule,
    CompsModule,
    SLabelModule,
    StatsModule,
    CustomDataModule,
    BaseDocumentModule
  ],
  declarations: [
    ProviderDeleteComponent,
    ProviderListComponent,
    ProviderManageComponent,
    ProviderCreateComponent,
    ProviderDisplayComponent,
  ],
  exports: [
    ProviderDeleteComponent,
    ProviderListComponent,
    ProviderManageComponent,
    ProviderCreateComponent,
    ProviderDisplayComponent
  ],
})
export class ProviderModule {
}
