import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Offer} from "../offer.service";
import {SafeHtml} from "@angular/platform-browser";
import {ModelPrintService} from "../../../comm/print/modelprint.service";
import {Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ModelSend, ModelSendService} from "../../../comm/mailer/modelsend.service";
import {SenderFieldService} from "../../../comm/mailer/sender-field.service";
import {SenderFieldParamsService} from "../../../comm/mailer/sender-field-params.service";
import {BsacLiveService} from "@solidev/bsadmincomponents";
import {SenderJob, SenderJobDataResult, SenderJobService} from "../../../comm/mailer/senderjob.service";
import {SendDestination} from "../../../comm/mailer/senddestination/senddestination.service";


@Component({
  selector: "lvadg-offer-send",
  templateUrl: "./offer-send.component.pug",
  styleUrls: ["./offer-send.component.sass"]
})
export class OfferSendComponent implements OnInit, OnDestroy {
  @Input() public offer!: Offer;
  public pages: SendDestination[] = [];
  public jobs: SenderJob[] = [];
  public sender!: ModelSend;
  public sending = false;
  public check!: Subscription;
  public loaded = false;
  public preview!: SafeHtml;
  public curpage: number | null = null;
  public _curpage = new ReplaySubject<number | null>();
  public _reload = new ReplaySubject<ModelSend>(1);
  private _subscriptions = new Subject<void>();

  constructor(public params$: SenderFieldParamsService,
              public fields$: SenderFieldService,
              private _jobs: SenderJobService,
              private _mp: ModelPrintService,
              private _ms: ModelSendService,
              private _live: BsacLiveService) {
  }

  private _statusUpdates = new ReplaySubject<SenderJobDataResult>(1);

  public get statusUpdates(): Observable<SenderJobDataResult> {
    return this._statusUpdates.asObservable();
  }

  public get curpage$(): Observable<number | null> {
    return this._curpage.asObservable();
  }

  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public async ngOnInit(): Promise<void> {
    this.sender = await this._ms.create(
      await this.offer.action(
        "sender",
        {
          method: "POST",
          body: {}
        }).toPromise()
    ).toPromise();

    await this.getJobs();

    // PrintParams service setup
    this.params$.setUp({pageQuery: "page", pageParamKey: "destination"});
    // Subscribe to updated param just to re-emit pageChanged
    this.params$.updated.pipe(takeUntil(this._subscriptions)).subscribe(() => {
      this._curpage.next(this.curpage);
    });
    // Load current page (generic page)
    await this.params$.load(this.sender, this.curpage);
    this.loaded = true;
  }

  public async getJobs() {
    if (!this.sender) {
      return;
    }
    await this.sender.refresh().toPromise();
    const jobs: SenderJob[] = [];
    this.sending = false;
    for (const j of this.sender.jobs_details) {
      let found = false;
      for (const oj of this.jobs) {
        if (oj.id === j.id) {
          j.live = oj.live;
          jobs.push(j);
          found = true;
          break;
        }
      }
      if (!found || !j.live) {
        j.live = this._live.subscribe("lvadg." + j.channel)
          .pipe(takeUntil(this._subscriptions))
          .subscribe((message) => {
            console.log("Message from job", message);
            let sending = false;
            for (const jb of this.jobs) {
              if (message.data.id === jb.id) {
                jb.data = message.data;
                jb.status = message.data.status;
                jb.progress = message.data.progress;
                jb.total = message.data.total;
                this._statusUpdates.next(jb.data.result);
              }
              if (jb.status !== "done") {
                sending = true;
              }
            }
            this.sending = sending;
          });
        jobs.push(j);
      }
      if (j.status !== "done") {
        this.sending = true;
      }
    }
    this.jobs = jobs;
  }

  public async setPage(page: number) {
    this.curpage = page;
    if (!this.loaded) {
      this.params$.setUp({pageQuery: "page", pageParamKey: "destination"});
    }
    await this.params$.load(this.sender, this.curpage);
  }

  public async send(): Promise<void> {
    this.sending = true;
    const res = await this.sender.action("send", {method: "POST", body: {}}).toPromise();
    this._reload.next(this.sender);
    await this.getJobs();
  }

  public get modelReload(): Observable<ModelSend> {
    return this._reload.asObservable();
  }
  updatedDestination($event: SendDestination[]) {
    this.pages = $event;
  }
}
