
<div class="card">
  <div class="card-header"><i class="fa fa-file me-2"></i>Documents</div>
  <div class="card-body">
    <div class="row">
      <div class="col-12 mb-3">
        <h4>Ajouter un document</h4>
        <lvadg-upload-file [url]="getUploadUrl()" [data]="{tarif: tarif.id, status: 'OK', type: 'ET'}" [mimetypes]="[]" (uploaded)="ngOnInit()"></lvadg-upload-file>
      </div>
      <div class="col-6 col-md-6 col-lg-4 col-xl-3 mt-2" *ngFor="let d of documents">
        <div class="card h-100 document">
          <div class="row no-gutters h-100">
            <div class="col-lg-4 h-100">
              <div class="icon">
                <ng-container *ngIf="d.image">
                  <div class="img" lvadgRbg [rsrc]="d.image_img" rsize="sm" rmode="up"></div>
                </ng-container>
                <ng-container *ngIf="!d.image">
                  <div class="img text-center"><i class="fa fa-5x mt-4" [ngClass]="d.icon"></i></div>
                </ng-container>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="card-body">
                <h5 class="card-title">{{d.title || d.filename}}</h5>
                <div class="card-text">
                  <dl [class.text-muted]="d.status!='OK'">
                    <dt>Date & taille</dt>
                    <dd>{{d.created|date:'shortDate'}} {{d.filesize|bytes:1}}</dd>
                    <bsac-dd-field [model]="d" field="filename">Nom du fichier</bsac-dd-field>
                    <bsac-dd-field [model]="d" field="status" type="auto">Etat</bsac-dd-field>
                    <bsac-dd-field [model]="d" field="type" type="auto">Type</bsac-dd-field>
                  </dl>
                  <div class="mt-2">
                    <lvadg-confirm-delete [model]="d" type="button" (deleted)="ngOnInit()">Supprimer</lvadg-confirm-delete>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>