import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import {CdkTableModule} from "@angular/cdk/table";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {CompsModule} from "../../../comps/comps.module";

import {SeaLabelListComponent} from "./sealabel-list/sealabel-list.component";

import {SeaLabelCreateComponent} from "./sealabel-create/sealabel-create.component";

import {SeaLabelManageComponent} from "./sealabel-manage/sealabel-manage.component";

import {SeaLabelDeleteComponent} from "./sealabel-delete/sealabel-delete.component";

import {SeaLabelSelectModule} from "./sealabel-select.module";
import {SeaLabelDisplayComponent} from "./sealabel-display/sealabel-display.component";

@NgModule({
  imports: [
    CommonModule,
    SeaLabelSelectModule,
    CdkTableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    
    CompsModule
  ],
  declarations: [
    SeaLabelListComponent,
    SeaLabelDisplayComponent,
    SeaLabelCreateComponent,
    SeaLabelManageComponent,
    SeaLabelDeleteComponent
  ],
  exports: [
    SeaLabelSelectModule,
    SeaLabelDisplayComponent,
    SeaLabelListComponent,
    SeaLabelCreateComponent,
    SeaLabelManageComponent,
    SeaLabelDeleteComponent
  ]
})
export class SeaLabelModule {
}
