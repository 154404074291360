import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel
} from "@solidev/ngdataservice";


export class ModelComm extends DSModel {
  @DSAutoField({
    name: "id"
  })
  public id!: number;
  @DSCharField({
    name: "title",
    required: false,
    verboseName: "Titre",
  })
  public title!: string;


  @DSForeignKeyField({
    name: "storage",
    required: false,
    verboseName: "ID Dépôt"
  })
  public storage!: number;

  @DSDefaultField({
    name: "storage_details",
    required: false,
    verboseName: "Dépôt"
  })
  public storage_details!: { id: number, name: string };


  @DSForeignKeyField({
    name: "client",
    required: false,
    verboseName: "ID Client"
  })
  public client!: number;

  @DSDefaultField({
    name: "client_details",
    required: false,
    verboseName: "Client"
  })
  public client_details!: { id: number, name: string };


  @DSForeignKeyField({
    name: "resto",
    required: false,
    verboseName: "ID Restaurant"
  })
  public resto!: number;

  @DSDefaultField({
    name: "resto_details",
    required: false,
    verboseName: "Restaurant"
  })
  public resto_details!: { id: number, name: string };


  @DSForeignKeyField({
    name: "producer",
    required: false,
    verboseName: "Producteur ID"
  })
  public producer!: number;

  @DSDefaultField({
    name: "producer_details",
    required: false,
    verboseName: "Producteurs"
  })
  public producer_details!: { id: number, name: string };

  @DSManyToManyField({
    name: "producers",
    required: false,
    verboseName: "Producteurs (IDs)"
  })
  public producers!: number[];

  @DSDefaultField({
    name: "producers_details",
    required: false,
    verboseName: "Producteurs"
  })
  public producers_details!: { id: number, name: string }[];

  @DSForeignKeyField({
    name: "product",
    required: false,
    verboseName: "Produit ID"
  })
  public product!: number;

  @DSDefaultField({
    name: "product_details",
    required: false,
    verboseName: "Produits"
  })
  public product_details!: { id: number, name: string };

  @DSManyToManyField({
    name: "products",
    required: false,
    verboseName: "Produits (IDs)"
  })
  public products!: number[];

  @DSDefaultField({
    name: "products_details",
    required: false,
    verboseName: "Produits"
  })
  public products_details!: { id: number, name: string }[];


  @DSForeignKeyField({
    name: "seaproducer",
    required: false,
    verboseName: "ID Fournisseur marée"
  })
  public seaproducer!: number;

  @DSDefaultField({
    name: "seaproducer_details",
    required: false,
    verboseName: "Fournisseur marée"
  })
  public seaproducer_details!: { id: number, name: string };

  @DSForeignKeyField({
    name: "article",
    verboseName: "Article",
    required: false,
    helpText: "Article",
    relatedModel: "catalog.Article",
    remoteField: "id",
    relatedName: "prints"
  })
  public article!: number;

  @DSDefaultField({
    name: "article_details",
    required: false,
    verboseName: "Article"
  })
  public article_details!: { id: number, name: string };


  @DSManyToManyField({
    name: "seaproducers",
    required: false,
    verboseName: "IDs fournisseurs marée"
  })
  public seaproducers!: number[];

  @DSDefaultField({
    name: "seaproducers_details",
    required: false,
    verboseName: "Fournisseurs marée"
  })
  public seaproducers_details!: { id: number, name: string }[];

  @DSForeignKeyField({
    name: "seaproduct",
    required: false,
    verboseName: "ID Produit marée"
  })
  public seaproduct!: number;

  @DSDefaultField({
    name: "seaproduct_details",
    required: false,
    verboseName: "Produits marée"
  })
  public seaproduct_details!: { id: number, name: string };

  @DSManyToManyField({
    name: "seaproducts",
    required: false,
    verboseName: "IDs Produits marée"
  })
  public seaproducts!: number[];

  @DSDefaultField({
    name: "seaproducts_details",
    required: false,
    verboseName: "Produits marée"
  })
  public seaproducts_details!: { id: number, name: string }[];


  @DSDefaultField({
    name: "storages_details",
    required: false,
    verboseName: "Dépôts"
  })
  public storages_details!: { id: number, name: string }[];

  @DSManyToManyField({
    name: "articles",
    verboseName: "Articles",
    required: false,
    relatedModel: "catalog.Article",
    remoteField: "prints",
    helpText: "Articles",
    default: [],
  })

  public articles!: number[];
  @DSDefaultField({
    name: "articles_details",
    required: false,
    verboseName: "Articles"
  })
  public articles_details!: { id: number, name: string }[];


  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Dernière mise à jour",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    required: false,
    verboseName: "Etat",
  })
  public status!: string;

  @DSDefaultField({
    name: "query",
    required: false,
    verboseName: "Requète"
  })
  public query!: { [index: string]: any };


}
