import {Pipe, PipeTransform} from "@angular/core";
import {DSModel} from "@solidev/ngdataservice";
import {formatNumber} from "@angular/common";

// TODO: move to bsac
// TODO: add precision options (use decimalPipe)

@Pipe({
  name: "dsDecimal"
})
export class DsDecimalPipe implements PipeTransform {

  transform(value: DSModel, field: string, digitsInfo?: string): string {
    if (!(value as any)[field] && (value as any)[field] !== 0) {
      return "-";
    }
    const factor = (value._fields[field] as any).factor;
    if (factor) {
      return formatNumber((value as any)[field] / factor, "fr", digitsInfo);
    } else {
      return formatNumber((value as any)[field], "fr", digitsInfo);
    }
  }

}
