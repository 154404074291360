import {NgModule} from "@angular/core";
import {TokenListComponent} from "./token-list/token-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS
  ],
  declarations: [
    TokenListComponent,
  ],
  exports: [
    TokenListComponent,
  ],
})
export class TokenModule {
}
