import {Component, Input, OnChanges, OnDestroy, OnInit} from "@angular/core";
import {Rimg, RIMG_BANNERS} from "./rimg";
import {DisplayinfosService} from "../displayinfos.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: "lvadg-rimg",
  templateUrl: "./rimg.component.pug",
  styleUrls: ["./rimg.component.sass"]
})
export class RimgComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public src!: any;
  /**
   * Image size.
   * From th, smb, mdb, lgb, xlb, xxsm, xsm, sm, md, lg, xlg, xxlg
   */
  @Input() public size = "thumb";
  /**
   * Display type.
   * From thumb (square, 128px), thumb32, thumb64, thumb48, thumb16, gallery(height=128px, width: auto), full (100%)
   */
  @Input() public display = "thumb";
  /**
   * Display legend (default = true)
   */
  @Input() public legend = true;
  public source!: string;
  private rimg: Rimg;
  private sz: string;
  private _subscriptions$ = new Subject<void>();

  constructor(private _dispInfos: DisplayinfosService) {
  }


  public ngOnInit(): void {
    this._dispInfos.screenWidth.pipe(takeUntil((this._subscriptions$))).subscribe((sz) => {
      this.sz = sz;
      this.updateImage();
    });
  }

  public ngOnDestroy(): void {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  public updateImage() {
    if (this.src !== null) {
      this.rimg = new Rimg(this.src);
      if (this.size === "orig") {
        this.source = this.rimg.orig;
      } else if (this.size === "fwbanner") {
        if (this.sz) {
          if (this.rimg.byName(RIMG_BANNERS[this.sz])) {
            this.source = this.rimg.byName(RIMG_BANNERS[this.sz])?.image || '';
          } else {
            this.source = "";
          }
        }
      } else {
        if (this.rimg.byName(this.size)) {
          this.source = this.rimg.byName(this.size)?.image || '';
        } else {
          this.source = "";
        }
      }
    }
  }

  ngOnChanges(changes: any): void {
    this.updateImage();
  }

}
