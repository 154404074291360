import {Injectable} from "@angular/core";
import {DSAutoField, DSForeignKeyField, DSModel, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {Tarif} from "../tarif/tarif.service";
import {TarifTypeRelation} from "../tariftyperelation/tariftyperelation.service";


export class TarifRelation extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "relation",
    verboseName: "Relation",
    required: false,
    helpText: "Relation",
    relatedModel: "tarifs.TarifTypeRelation",
    remoteField: "id",
    relatedName: "relations"
  })
  public relation!: number;
  public relation_details!: TarifTypeRelation;

  @DSForeignKeyField({
    name: "src",
    verboseName: "Tarif source",
    required: false,
    helpText: "Tarif source",
    relatedModel: "tarifs.Tarif",
    remoteField: "id",
    relatedName: "srcrelations"
  })
  public src!: number;
  public src_details!: Tarif;

  @DSForeignKeyField({
    name: "dest",
    verboseName: "Tarif destination",
    required: false,
    helpText: "Tarif destination",
    relatedModel: "tarifs.Tarif",
    remoteField: "id",
    relatedName: "destrelations"
  })
  public dest!: number;
  public dest_details!: Tarif;

}

@Injectable({
  providedIn: "root"
})
export class TarifRelationService extends DSRestCollection<TarifRelation> {
  public adapter_config = {basePath: "/api/v2/tarifs/tarifrelations"};
  public model = TarifRelation;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
