
<div class="dests">
  <table class="table table-condensed table-bordered">
    <thead>
      <tr>
        <th>Type email</th>
        <th>Destinataire</th>
        <th>Etat</th>
        <th>Preview</th>
      </tr>
    </thead>
    <tbody *ngIf="dests">
      <tr *ngFor="let s of dests" [class.table-info]="s.status=='SND'" [class.text-muted]="s.status=='SKP' || s.status=='NOK'" [class.text-info]="s.status=='SNW' || s.status=='SNQ' || s.status=='SNI'" [class.text-warning]="s.status=='SNP'" [class.text-danger]="s.status=='FAT'" [class.text-success]="s.status=='SNT'" [class.table-success]="s.status=='OPN' || s.status=='CLK'" [class.table-warning]="s.status=='ATT' || s.status=='ATP'">
        <td *ngIf="s.resto_details">{{s.resto_details.name}}
          <div class="pointer" (click)="addDestination(s)">Ajouter un destinataire</div>
        </td>
        <td *ngIf="!s.resto_details"><i>GENERIQUE</i>
          <div class="pointer" (click)="addDestination()">Ajouter un destinataire</div>
        </td>
        <td>
          <lvadg-modelsend-contact-manage [model]="model" [destination]="s" [resto]="resto || $any(s.resto_details)" [client]="client" [member]="member" [storage]="storage" (updated)="loadDestinations()"></lvadg-modelsend-contact-manage>
        </td>
        <td (click)="changeStatus(s)" [class.pointer]="s.status=='SND' || s.status=='SKP'"><i class="fa fa-spinner fa-spin me-1" *ngIf="['SNW', 'SNQ', 'SNI'].indexOf(s.status) !== -1"></i><i class="fa fa-hourglass me-1" *ngIf="s.status=='SNP'"></i><i class="fa fa-check me-1" *ngIf="['SNT','OPN','CLK'].indexOf(s.status) !== -1"></i><span>{{s.status|choice:s:'status'}}</span></td>
        <td class="pointer text-center" (click)="selectPage(s)"><i class="fa fa-eye fa-2x"></i></td>
      </tr>
    </tbody>
  </table>
</div>