import {NgModule} from "@angular/core";
import {FichesDataListComponent} from "./fichesdata-list/fichesdata-list.component";
import {FichesDataManageComponent} from "./fichesdata-manage/fichesdata-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {FichesDataCreateComponent} from "./fichesdata-create/fichesdata-create.component";
import {QuillModule} from "ngx-quill";


@NgModule({
  declarations: [
    FichesDataListComponent,
    FichesDataManageComponent,
    FichesDataCreateComponent
  ],
  exports: [
    FichesDataListComponent,
    FichesDataManageComponent,
    FichesDataCreateComponent
  ],

  imports: [
    ...MODELLIST_IMPORTS,
    QuillModule
  ]
})
export class FichesdataModule {
}
