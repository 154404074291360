import {build, release, version} from "./version";

export const environment = {
  production: true,
  MODE: "production",
  API_URL: "https://api.lavieadugout.fr",
  LIVE_URL: "https://l1v3.lavieadugout.fr/lvadg",
  API_HEADERS: {"accept": "application/json"},
  MAIN_SITE_DOMAIN: "lavieadugout.fr",
  PRINTER_URL: "https://printer.lavieadugout.fr",
  MAPS_API_KEY: "AIzaSyAgBS_02Kl7I6-0nzd5GO4k8i9qTcRDzHA",
  BAN_PATH: "https://gis.lavieadugout.fr/geocoder/search",
  DEPLOY_URL_BASE: "https://d145241tyei3wv.cloudfront.net/client/production",
  PROJECT: "a",
  VERSION: version,
  BUILD: build,
  RELEASE: release,
  ANALYTICS: "UA-59222728-3",
  SOCKET_IO_URL: "https://l1v3.lavieadugout.fr/lvadg",
  SOCKET_IO_PATH: "",
  LVADGIO_API_URL: "https://lvadg.io"
};
