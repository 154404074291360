/**
 * Stattype : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>

 * @date 2018-08-23-12:31:13
 * @copyright (c) 2017 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {DSAutoField, DSCharField, DSModel, DSRestCollection, DSRestCollectionSetupProvider, DSTextField} from "@solidev/ngdataservice";

export class StatType extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSCharField({
    required: true,
    name: "code",
    helpText: "",
    verboseName: "Code",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public code!: string;

  @DSCharField({
    required: true,
    name: "type",
    helpText: "",
    verboseName: "Type",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public type!: string;

  @DSCharField({
    required: true,
    name: "sdesc",
    helpText: "",
    verboseName: "Titre",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public sdesc!: string;

  @DSTextField({
    required: true,
    name: "ldesc",
    helpText: "",
    verboseName: "Description",
    choices: [],
    default: null
  })
  public ldesc!: string;

}

@Injectable({providedIn: "root"})
export class StattypeService extends DSRestCollection<StatType> {
  public adapter_config = {basePath: "/api/v2/stattypes"};
  public model = StatType;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
