import {NgModule} from "@angular/core";
import {StorageCreateComponent} from "./storage-create/storage-create.component";
import {StorageDeleteComponent} from "./storage-delete/storage-delete.component";
import {StorageDisplayComponent} from "./storage-display/storage-display.component";
import {StorageListComponent} from "./storage-list/storage-list.component";
import {StorageManageComponent} from "./storage-manage/storage-manage.component";
import {LocationModule} from "../location/location.module";
import {ImageModule} from "../../content/image/image.module";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {StatsModule} from "../../stats/stats/stats.module";
import {StorageSelectModule} from "./storage-select.module";
import {StorageSelectComponent} from "./storage-select/storage-select.component";
import {SLabelModule} from "../slabel/slabel.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {SelectionModule} from "../../selection/selection.module";
import {NgbCarouselModule} from "@ng-bootstrap/ng-bootstrap";
import {VideoModule} from "../../content/video/video.module";
import {BillModule} from "../../sales/bill/bill.module";
import {BaseDocumentModule} from "../../document/basedocument/base-document.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    NgbCarouselModule,
    VideoModule,
    SelectionModule,
    LocationModule,
    ImageModule,
    SLabelModule,
    CustomDataModule,
    StatsModule,
    StorageSelectModule,
    BaseDocumentModule,
    BillModule
  ],
  declarations: [
    StorageDisplayComponent,
    StorageDeleteComponent,
    StorageListComponent,
    StorageManageComponent,
    StorageCreateComponent
  ],
  exports: [
    StorageDisplayComponent,
    StorageDeleteComponent,
    StorageListComponent,
    StorageManageComponent,
    StorageCreateComponent,
    StorageSelectComponent
  ],
})
export class StorageModule {
}
