import {Injectable} from "@angular/core";
import {DSCharField, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProducer} from "../_producer/producer-base.model";


export class SeaProducer extends BaseProducer {
  @DSCharField({
    required: false,
    name: "type",
    helpText: "",
    verboseName: "Type",
    choices: [
      ["MR", "Fournisseur marée"],
    ],
    default: "MR",
    maxLength: 4,
  })
  public type!: string;


}

@Injectable({providedIn: "root"})
export class SeaProducerService extends DSRestCollection<SeaProducer> {
  public adapter_config = {basePath: "/api/v2/structure/seaproducers"};
  public model = SeaProducer;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
