import {Component} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {FichesData, FichesDataService} from "../fichesdata.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";

@Component({
  selector: "lvadg-fichesdata-list",
  templateUrl: "./fichesdata-list.component.pug",
  styleUrls: ["./fichesdata-list.component.sass"]
})
export class FichesDataListComponent extends ModelListBaseComponent<FichesData, FichesDataService> {

  constructor(_list: BsacModelListProvider,
              _items: FichesDataService,
              _msgs: BsacMessageService,
              routes: RoutesService) {
    super(_list, _items, _msgs, routes);
  }

  public getDetailLink(model: FichesData): (string | number)[] {
    return this.routes.get(this.zone, this.type, model);
  }
}
