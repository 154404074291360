export interface IAtlmStorage {
  name: string;
  verbose_name: string;
  storageId: number;
  storage: string;
  member: string;
  readonly: boolean;
}

export interface IAtlmMarche {
  name: string;
  verbose_name: string;
  marcheId: string;
}

export interface IAtlmRelation {
  variation?: boolean;
  name: string;
  verbose_name: string;
  relId: number;
  srcId: number;
  stoId?: number;
  gsprice?: boolean; // Afficher le prix global des dépôts
  oprice?: boolean;  // Afficher le prix original
  libelle?: boolean; // Afficher le libellé de la source ?
  ops?: string;
  type: "COM"|"REF"|"LOC"|"NFO";
}

export enum ColOpModes {
  GlobalPrice= "GBPR",
  StoragePrice = "STPR",
  RnmPrice = "RNPR"
}
