// @changed 2018-04-29-16:29:41
//
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SiteCreateComponent} from "./site-create/site-create.component";
import {SiteDeleteComponent} from "./site-delete/site-delete.component";
import {SiteDisplayComponent} from "./site-display/site-display.component";
import {SiteListComponent} from "./site-list/site-list.component";
import {SiteSelectComponent} from "./site-select/site-select.component";
import {SiteManageComponent} from "./site-manage/site-manage.component";
import {SiteService} from "./site.service";
import {SiteResolve} from "./site.resolve";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {SiteHeaderComponent} from "./site-header/site-header.component";
import {CompsModule} from "../../../comps/comps.module";
import {ClientModule} from "../../structure/client/client.module";
import {RestoModule} from "../../structure/resto/resto.module";
import {MemberModule} from "../../structure/member/member.module";
import {PersonSelectModule} from "../../people/person/person-select.module";
import {ImageModule} from "../../content/image/image.module";
import {CdkTableModule} from "@angular/cdk/table";


@NgModule({
  imports: [
    CommonModule,
    CompsModule,
    RouterModule,
    BsacModelListModule,
    BsacApiConstantsModule,

    BsacFormsModule,
    NgbModule,
    CompsModule,
    ReactiveFormsModule,
    MemberModule,
    ClientModule,
    RestoModule,
    ImageModule,
    CdkTableModule,
    PersonSelectModule
  ],
  providers: [
    SiteService,
    SiteResolve
  ],
  declarations: [
    SiteSelectComponent,
    SiteDisplayComponent,
    SiteDeleteComponent,
    SiteListComponent,
    SiteManageComponent,
    SiteCreateComponent,
    SiteHeaderComponent
  ],
  exports: [
    SiteSelectComponent,
    SiteDisplayComponent,
    SiteDeleteComponent,
    SiteListComponent,
    SiteManageComponent,
    SiteCreateComponent,
    SiteHeaderComponent
  ],
})
export class SiteModule {
}
