import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ArticleTarifTemplate, ArticleTarifTemplateService} from "../articletariftemplate.service";
import {ArticleService} from "../../../catalog/article/article.service";
import {ArticleTarifRnm, ArticleTarifRnmService} from "../articletarifrnm.service";
import {firstValueFrom} from "rxjs";
import {Rnm, RnmService} from "../../rnm/rnm.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {TarifType} from "../../tariftype/tariftype.service";
import {ExternalArticle, ExternalArticleService} from "../../../catalog/externalarticle/externalarticle.service";
import {RouteV3} from "../../../../comps/routes.service";


@Component({
  selector: "lvadg-articletariftemplate-manage",
  templateUrl: "./articletariftemplate-manage.component.pug",
  styleUrls: ["./articletariftemplate-manage.component.sass"]
})
export class ArticletariftemplateManageComponent implements OnInit {
  @Input() public att!: ArticleTarifTemplate;
  @Input() public tarifType!: TarifType;
  @Input() public eaclient_detail_route?: RouteV3<ExternalArticle>;
  @Output() public deleted = new EventEmitter<ArticleTarifTemplate>();

  public rnms!: ArticleTarifRnm[];
  public curRnm!: Rnm;
  public isAdmin: boolean = false;
  public isWriter: boolean = false;

  constructor(public article$: ArticleService,
              private _msgs: BsacMessageService,
              private _atts: ArticleTarifTemplateService,
              private _rnm: RnmService,
              public ea$: ExternalArticleService,
              private _arnm: ArticleTarifRnmService) {
  }

  public async ngOnInit(): Promise<void> {
    this.rnms = (await firstValueFrom(this._arnm.queryset.query({att: this.att.id}).get())).items;
    this.isAdmin = this.tarifType && ["admin", "sadmin"].indexOf(this.tarifType.user_level) !== -1;
    this.isWriter = this.tarifType && ["write", "admin", "sadmin"].indexOf(this.tarifType.user_level) !== -1;
  }

  public async loadCandidates(): Promise<void> {
    console.log("Load candidates");
    await firstValueFrom(this.att.action("add_rnm_candidates", {method: "POST"}));
    await this.ngOnInit();
  }

  public async changedScore(r: ArticleTarifRnm, $event: number): Promise<void> {
    await firstValueFrom(r.update(["score_manual"]));
    await this.ngOnInit();
  }

  public async removeLink(r: ArticleTarifRnm) {
    await firstValueFrom(r.delete());
    await this.ngOnInit();
  }

  async loadStats(r: ArticleTarifRnm): Promise<void> {
    this.curRnm = await firstValueFrom(this._rnm.get(r.rnm_details.id));
  }

  public async onDeleted(att: ArticleTarifTemplate) {
    try {
      this.att = await firstValueFrom(this._atts.get(att.id));
      this._msgs.warning("Article non supprimable, désactivé uniquement");
      this.deleted.emit(att);
    } catch {
      this._msgs.success("Article supprimé avec succès");
      this.deleted.emit(att);
    }
  }
}
