
<table class="table table-sm table-striped">
  <thead>
    <tr>
      <th>Groupe</th>
      <th>Notification</th>
      <th *ngIf="['admin', 'sadmin'].indexOf(tarifType.user_level) !== -1">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tn of tngs">
      <td><code>{{tn.group_details.slug}}</code> {{tn.group_details.description}}</td>
      <td>{{tn.notification|choice:tn:'notification'}}</td>
      <td *ngIf="['admin', 'sadmin'].indexOf(tarifType.user_level) !== -1"><i class="fa fa-trash text-danger px-3" role="button" (click)="removeAction(tn)"></i></td>
    </tr>
    <tr class="table-primary" *ngIf="['admin', 'sadmin'].indexOf(tarifType.user_level) !== -1">
      <td class="text-center" colspan="3" role="button" (click)="addFormDisplay=!addFormDisplay"><i class="fa fa-plus me-2"></i>Ajouter un groupe de notification</td>
    </tr>
    <tr *ngIf="addFormDisplay">
      <td colspan="3">
        <form class="row" [formGroup]="addForm" (submit)="actionAdd()">
          <div class="col-6">
            <bsac-field-control [form]="addForm" [model]="tng" field="group" [related]="groups$" desctpl="[&lt;%= obj.slug %&gt;] &lt;%= obj.description %&gt;">
              <div class="label">Groupe</div>
            </bsac-field-control>
          </div>
          <div class="col-6">
            <bsac-field-control [form]="addForm" [model]="tng" field="notification">
              <div class="label">Notification</div>
            </bsac-field-control>
          </div>
          <div class="col-12">
            <button class="btn btn-primary w-100"><i class="fa fa-save me-2"></i>Enregistrer</button>
          </div>
        </form>
      </td>
    </tr>
  </tbody>
</table>