import {CompsModule} from "../../../comps/comps.module";
import {OfferTextCreateComponent} from "./offertext-create/offertext-create.component";
import {OfferTextDeleteComponent} from "./offertext-delete/offertext-delete.component";
import {OfferTextListComponent} from "./offertext-list/offertext-list.component";
import {OfferTextManageComponent} from "./offertext-manage/offertext-manage.component";
import {OfferTextSelectModule} from "./offertext-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";

@NgModule({
  imports: [
    CompsModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [OfferTextCreateComponent, OfferTextListComponent, OfferTextDeleteComponent, OfferTextManageComponent],
  exports: [
    OfferTextCreateComponent,
    OfferTextListComponent,
    OfferTextDeleteComponent,
    OfferTextManageComponent,
    OfferTextSelectModule
  ]
})
export class OfferTextModule {}
