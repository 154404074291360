
<div class="card">
  <div class="card-header text-danger"><i class="fa fa-file-excel me-2"></i>Import des contenus xlsx (preview)</div>
  <div class="card-body">
    <ng-container *ngIf="documents">
      <div class="row">
        <div class="col-sm-6">
          <h4>Etape 1 : choix du fichier</h4>
          <ul class="list-group">
            <li class="list-group-item list-group-item-action pointer" *ngFor="let d of documents" [class.list-group-item-primary]="d?.id==curdoc?.id" (click)="setCurdoc(d)">{{d.filename}}</li>
          </ul>
          <div class="alert alert-warning mt-3" *ngIf="curdoc &amp;&amp; !isXlsx(curdoc)">
            <p class="text-white">Ce document n'est pas au format XLSX nécessaire pour l'impotation, voulez-vous en créer une version XLSX ?</p>
            <button class="btn btn-primary" (click)="createXlsx()">Créer un XLSX</button>
          </div>
        </div>
        <div class="col-sm-6">
          <h4>Charger une association prédéfinie</h4>
          <ul class="list-group" *ngIf="curdoc">
            <ng-container *ngIf="params">
              <li class="list-group-item list-group-item-action pointer" *ngFor="let p of params" (click)="useParams(p)" [class.list-group-item-primary]="p.name === curparams?.name">{{p.name}}</li>
            </ng-container>
            <li class="list-group-item text-info" *ngIf="!params">Aucune association prédéfinie disponible</li>
          </ul>
        </div>
        <div class="col-12 mt-3" *ngIf="step &gt;= 2">
          <h4>Etape 2 : association feuilles / colonnes</h4>
          <lvadg-upload-xlsx-map [file]="curdoc" [fields]="fields" [defaults]="defaults" (mapped)="onMapped()" [name]="curparams?.name || 'default'" [previewLines]="9" [update]="updateMapping"></lvadg-upload-xlsx-map>
          <ng-container *ngIf="curdoc &amp;&amp; curdoc.parameters">
            <button class="btn btn-primary ms-3" (click)="onMapped()">EXTRAIRE LES DONNEES DU FICHIER</button>
            <div class="d-inline-block col-sm-3 ms-4">
              <input class="form-control" [(ngModel)]="mapName" placeholder="Nom de l'association">
            </div>
            <button class="btn btn-primary" (click)="saveMapping()">ENREGISTRER COMME ASSOC. PREFEFINIE</button>
          </ng-container>
        </div>
        <div class="col-12 mt-3">
          <ng-container *ngIf="result">
            <lvadg-oplog-display [result]="result"></lvadg-oplog-display>
          </ng-container>
          <ng-container *ngIf="curdoc &amp;&amp; curmap">
            <h4>Etape 3 : consulter les données extraites</h4>
            <button class="btn btn-outline-primary" (click)="curmapFoundFilter=!curmapFoundFilter">non matchés / tous</button>
            <table class="table table-sm table-hover table-bordered mt-2" *ngIf="curmap">
              <thead>
                <tr>
                  <th>Ligne</th>
                  <th>External ID</th>
                  <th>Code client</th>
                  <th>Gamme</th>
                  <th>Famille</th>
                  <th>Produit</th>
                  <th>Categorie</th>
                  <th>Calibre</th>
                  <th>Origine</th>
                  <th>Prix HT</th>
                  <th>Unité fact.</th>
                  <th>Unité cond.</th>
                  <th>Ratio uc/uf</th>
                  <th>Unité comm.</th>
                  <th>XlsxHash</th>
                  <th>ID Article Fred</th>
                  <th>Article catalogue</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let l of curmap">
                  <tr *ngIf="!curmapFoundFilter || (curmapFoundFilter &amp;&amp; l.external_details.articles.length == 0)" [class.text-warning]="!(l.external_details.articles.length&gt;0)">
                    <td>{{l.line}}</td>
                    <td>{{l.external}}</td>
                    <td>{{l.external_details.code}}</td>
                    <td>{{l.external_details.gamme}}</td>
                    <td>{{l.external_details.sgamme}}</td>
                    <td>{{l.external_details.libelle}}</td>
                    <td>{{l.external_details.categorie}}</td>
                    <td>{{l.external_details.calibre}}</td>
                    <td>{{l.external_details.origine}}</td>
                    <td>{{l.price/10000|currency:'EUR'}}</td>
                    <td>{{l.external_details.uf}}</td>
                    <td>{{l.external_details.uc}}</td>
                    <td>{{l.external_details.ucuf}}</td>
                    <td>{{l.external_details.ucond}}</td>
                    <td>{{l.external_details.xlsxhash}}</td>
                    <td><span class="mx-1" *ngFor="let e of l.external_details.mappings_details">{{e.article_details.vvid}}</span></td>
                    <td>
                      <div class="d-flex justify-content-between" *ngIf="l.external_details.articles.length &gt; 0">
                        <div>{{l.external_details.mappings_details[0].article_details.libelle}}</div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>