import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSManyToManyField,
  DSModel,
  DSTextField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";
import {LEGALTYPES} from "../../../comps/constants";
import {Location} from "../location/location.service";
import {DetailDocument} from "../../document/basedocument/basedocument.service";

export interface StorageDetails {
  id: number;
  name: string;
  member: number;
  member_details: {
    id: number;
    name: string;
  };
  mainlocation_details: Location;
  locations_details: Location[];
  distance: number;
}

interface ProducerRelation {
  id: number;
  type: string;
  storage_details: {
    id: number;
    name: string;
    member_details: {
      id: number;
      name: string;
    }
  };
  distance_details: {
    id: number;
    road: number;
    hops: number;
    rtime: number;
    direct: number;
  };
}

export interface LocationWithDeptDetails {
  city: string;
  postcode: string;
  address: string;
  latitude: number;
  longitude: number;
  geodepartement_details: {
    code: string;
    id: number;
    name: string;
  };
}

export class BaseProducer extends DSModel {

  @DSForeignKeyField({
    required: false,
    name: "locations",
    helpText: "",
    verboseName: "#&nbsp;Emplacements",
    choices: {},
    default: null,
    autoCreated: true,
    relatedModel: "Location",
    relatedName: "locations",
    remoteField: "producer",
    multiple: true,
  })
  public locations: number[];

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id: number;

  @DSTextField({
    required: true,
    name: "content_src",
    helpText: "",
    verboseName: "Description (texte)",
    choices: [],
    default: "",
    maxLength: 4000,
    minLength: 100
  })
  public content_src: string;

  @DSTextField({
    required: false,
    name: "content",
    helpText: "",
    verboseName: "Description (html)",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content: string;

  @DSCharField({
    required: true,
    name: "name",
    helpText: "",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 60,
  })
  public name: string;

  @DSCharField({
    required: false,
    name: "type",
    helpText: "",
    verboseName: "Type",
    choices: [
      ["FL", "Producteur de fruits et légumes"],
    ],
    default: "FL",
    maxLength: 4,
  })
  public type: string;

  @DSManyToManyField({
    name: "slabels",
    verboseName: "Labels structure",
    required: false
  })
  public slabels: number[];

  @DSTextField({
    required: false,
    name: "mainaddress",
    helpText: "",
    verboseName: "Adresse",
    choices: [],
    default: null,
    maxLength: 400,
  })
  public mainaddress: string;

  @DSCharField({
    required: false,
    name: "phone",
    helpText: "",
    verboseName: "Téléphone",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public phone: string;

  @DSCharField({
    required: false,
    name: "siren",
    helpText: "",
    verboseName: "SIREN",
    choices: [],
    default: "",
    maxLength: 20,
  })
  public siren: string;

  @DSCharField({
    required: false,
    name: "siret",
    helpText: "",
    verboseName: "SIRET",
    choices: [],
    default: "",
    maxLength: 20,
  })
  public siret: string;

  @DSCharField({
    required: false,
    name: "tvaic",
    helpText: "",
    verboseName: "N° TVA IC",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public tvaic: string;

  @DSCharField({
    required: false,
    name: "naf",
    helpText: "",
    verboseName: "Code NAF",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public naf: string;

  @DSCharField({
    required: false,
    name: "legal",
    helpText: "",
    verboseName: "Structure légale",
    choices: LEGALTYPES,
    default: null,
    maxLength: 50,
  })
  public legal: string;

  @DSCharField({
    required: false,
    name: "capital",
    helpText: "",
    verboseName: "Capital",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public capital: string;

  @DSCharField({
    required: false,
    name: "pubimage",
    helpText: "",
    verboseName: "Droit à l'image",
    choices: [["", "Inconnu"], ["OK", "Accepté"], ["NO", "Refusé"], ["NR", "En attente"]],
    default: "",
    maxLength: 2,
  })
  public pubimage: string;

  @DSForeignKeyField({
    required: false,
    name: "logo",
    helpText: "",
    verboseName: "Logo",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "logoproducer",
  })
  public logo: number;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Dernière mise à jour",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated: Date;

  @DSIntegerField({
    name: "activity",
    verboseName: "Activité offre locale",
    required: false,
    helpText: "Classement offre locale /100",
    default: 0,
    choices: []
  })
  public activity: number;


  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "En activité, valide"],
      ["KO", "Plus actif, désactivé"],
      ["EM", "Vide (pas de produit)"],
      ["NA", "Pas de dépôt associé"],
      ["EN", "Vide + non associé"],
      ["NL", "Aucun produit local"]],
    default: null,
    maxLength: 4,
  })
  public status: string;

  @DSManyToManyField({
    required: false,
    name: "storages",
    helpText: "",
    verboseName: "# Dépôts",
    choices: [],
    default: [],
  })
  public storages: number[];

  @DSManyToManyField({
    required: false,
    name: "images",
    helpText: "",
    verboseName: "# Photos",
    choices: [],
    default: [],
  })
  public images: number[];

  @DSDefaultField({
    required: false,
    name: "flags",
    verboseName: "Admin flags"
  })
  public flags: string[];

  @DSDefaultField({
    required: false,
    name: "products_count",
    verboseName: "Nb produits"
  })
  public products_count: number;

  @DSDefaultField({
    required: false,
    name: "storages_details",
    verboseName: "Détails dépôts"
  })
  public storages_details: StorageDetails[];

  @DSDefaultField({
    required: false,
    name: "locations_details",
    verboseName: "Détails localisation"
  })
  public locations_details: any[];

  @DSDefaultField({
    required: false,
    name: "logo_img",
    verboseName: "Logo (image)"
  })
  public logo_img: Rimg;

  @DSDefaultField({
    required: false,
    name: "images_details",
    verboseName: "Détails images"
  })
  public images_details: Rimg[];

  @DSManyToManyField({
    required: false,
    name: "labels",
    helpText: "",
    verboseName: "Labels et certifications",
    choices: [],
    default: [],
  })
  public labels: number[];

  @DSDefaultField({
    required: false,
    name: "labels_details",
    verboseName: "Détails labels et certifications"
  })
  public labels_details: any;

  @DSDefaultField({
    name: "iourl",
    verboseName: "lvadg.io",
    required: false,
    helpText: "lvadg.io url",
    choices: []
  })
  public iourl: { code: string; url: string };

  @DSCharField({
    name: "qr",
    verboseName: "QR",
    required: false,
    helpText: "QR code",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public qr: string;

  @DSManyToManyField({
    name: "documents",
    verboseName: "Documents producteur",
    required: false,
    relatedModel: "document.ProducerDocument",
    remoteField: "producerdocuments",
    helpText: "Documents producteur",
    default: [],
  })
  public documents: number[];
  public documents_details: DetailDocument[];

  @DSDefaultField({
    name: "documents_count",
    verboseName: "# Documents",
    required: false
  })
  public documents_count: number;

  public relations_details: ProducerRelation[];
  public mainlocation_details: LocationWithDeptDetails;
  public qrcode: string;


}
