<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'modetariftype1'; desc: 'Appartenance(s) - critère 1'; group: 'default'">
        <bsac-model-list-multiselect-filter [list]="list" field="modetariftype1" [choices]="inchoices"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'modetariftype2'; desc: 'Appartenance(s) - critère 2'; group: 'default'">
        <bsac-model-list-multiselect-filter [list]="list" field="modetariftype2" [choices]="inchoices"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="lastname">
            <th cdk-header-cell *cdkHeaderCellDef>Nom de famille</th>
            <td cdk-cell *cdkCellDef="let row">{{row.lastname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="firstname">
            <th cdk-header-cell *cdkHeaderCellDef>Prénom</th>
            <td cdk-cell *cdkCellDef="let row">{{row.firstname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="email">
            <th cdk-header-cell *cdkHeaderCellDef>Email</th>
            <td cdk-cell *cdkCellDef="let row">{{row.email}}</td>
          </ng-container>
          <ng-container cdkColumnDef="groups">
            <th cdk-header-cell *cdkHeaderCellDef>Groupes</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="badge bg-info me-2" *ngFor="let g of row.groups_details">{{g.slug}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="intariftype_starred">
            <th cdk-header-cell *cdkHeaderCellDef>Préféré ?</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa-star pointer" [class.text-muted]="!row.intariftype_starred" [class.fal]="!row.intariftype_starred" [class.text-warning]="row.intariftype_starred" [class.fa]="row.intariftype_starred" (click)="starToggle(row)"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="intariftype_notified">
            <th cdk-header-cell *cdkHeaderCellDef>Notifié ?</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa-bell pointer" [class.text-muted]="!row.intariftype_notified" [class.fal]="!row.intariftype_notified" [class.text-success]="row.intariftype_notified" [class.fa]="row.intariftype_notified" (click)="notifyToggle(row)"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="intariftype_groups">
            <th cdk-header-cell *cdkHeaderCellDef>Groupes du tarif</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="badge bg-success me-2" *ngFor="let g of row.intariftype_groups">{{g.slug}}</div>
            </td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>
<div class="card mt-3 border-warning">
  <div class="card-header bg-warning text-white">Actions de masse</div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <button class="btn btn-outline-danger w-100" (click)="viewAllAction('stargroup')">Mettre l'état "tarif préféré" pour tous les utilisateurs "viewall"</button>
      </div>
      <div class="col-12 mt-4">
        <button class="btn btn-outline-danger w-100" (click)="viewAllAction('notifygroup')">Mettre l'état "tarif notifié" pour tous les utilisateurs "viewall"</button>
      </div>
    </div>
  </div>
</div>