import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TimeSeriesChartComponent} from "./time-series-chart/time-series-chart.component";
import {NgxEchartsModule} from "ngx-echarts";
import {NumberCardComponent} from "./number-card/number-card.component";
import {CategSeriesChartComponent} from "./categ-series-chart/categ-series-chart.component";

@NgModule({
  declarations: [
    TimeSeriesChartComponent,
    NumberCardComponent,
    CategSeriesChartComponent,
  ],
  exports: [
    TimeSeriesChartComponent,
    NumberCardComponent,
    CategSeriesChartComponent,

  ],
  imports: [
    CommonModule,
    NgxEchartsModule
  ]
})
export class ChartsModule {
}
