/**
 * Token : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @changed 2018-01-05-05:17:49
 * @date 2018-01-01-18:56:45
 * @copyright (c) 2017 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export class Token extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSForeignKeyField({
    required: false,
    name: "person",
    helpText: "",
    verboseName: "person",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Person",
    remoteField: "token",
  })
  public person!: number;

  @DSForeignKeyField({
    required: false,
    name: "site",
    helpText: "",
    verboseName: "site",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Site",
    remoteField: "token",
  })
  public site!: number;

  @DSCharField({
    required: true,
    name: "ttype",
    helpText: "",
    verboseName: "Type",
    choices: [
      ["site", "Token de site"],
      ["adm", "Token d'administrateur"],
      ["app", "Token d'application"],
      ["pass", "Token de mot de passe"]
    ],
    default: "site",
    maxLength: 4,
  })
  public ttype!: string;

  @DSDefaultField({
    required: false,
    name: "scopes",
    helpText: "",
    verboseName: "scopes",
    choices: [],
    default: [],
  })
  public scopes!: any;

  @DSCharField({
    required: true,
    name: "token",
    helpText: "",
    verboseName: "token",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public token!: string;

  @DSDateTimeField({
    required: true,
    name: "expire",
    helpText: "",
    verboseName: "expire",
    choices: [],
    default: null,
  })
  public expire!: Date;

  @DSDefaultField({
    required: false,
    name: "networks",
    helpText: "",
    verboseName: "networks",
    choices: [],
    default: [],
  })
  public networks!: any;

  @DSCharField({
    required: false,
    name: "description",
    helpText: "",
    verboseName: "description",
    choices: [],
    default: "",
    maxLength: 300,
  })
  public description!: string;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "created",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "updated",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: true,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [["OK", "Token actif"], ["KO", "Token d\u00e9sactiv\u00e9"]],
    default: "OK",
    maxLength: 4,
  })
  public status!: string;

  public person_details!: {id: number, lastname: string, firstname: string, email: string};
  public site_details!: {id: number, type: string, domain: string};
}

@Injectable({providedIn: "root"})
export class TokenService extends DSRestCollection<Token> {
  public adapter_config = {basePath: "/api/v2/tokens"};
  public model = Token;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
