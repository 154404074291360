import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";


export class FichesData extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: true,
    helpText: "Type",
    minLength: 0,
    maxLength: 3,
    choices: [
      ["CRQ", "Critères de qualité"],
      ["MAR", "Marquage"]]
  })
  public type!: string;

  @DSCharField({
    name: "title",
    verboseName: "Titre",
    required: false,
    helpText: "Titre",
    minLength: 0,
    maxLength: 300,
    choices: []
  })
  public title!: string;

  @DSTextField({
    name: "text",
    verboseName: "Texte",
    required: false,
    helpText: "Texte",
    minLength: 0,
    maxLength: 5000,
    choices: []
  })
  public text!: string;

  @DSManyToManyField({
    name: "families",
    verboseName: "Familles",
    required: false,
    relatedModel: "catalog.ArticleFamily",
    remoteField: "id",
    helpText: "Familles concernées",
    default: [],
  })
  public families!: number[];
  public families_details!: { id: number; cfullname: string }[];

  @DSManyToManyField({
    name: "origins",
    verboseName: "Origines",
    required: false,
    relatedModel: "catalog.ArticleOrigin",
    remoteField: "id",
    helpText: "Origines concernées",
    default: [],
  })
  public origins!: number[];
  public origins_details!: { id: number; code: string; name: string }[];

  @DSBooleanField({
    name: "allorigins",
    verboseName: "Toutes origines",
    required: false,
    helpText: "Toutes origines",
    default: true
  })
  public allorigins!: boolean;

  @DSManyToManyField({
    name: "categories",
    verboseName: "Catégories",
    required: false,
    relatedModel: "catalog.ArticleCategory",
    remoteField: "id",
    helpText: "Catégories concernées",
    default: [],
  })
  public categories!: number[];
  public categories_details!: { id: number; code: string; name: string }[];

  @DSBooleanField({
    name: "allcategories",
    verboseName: "Toutes catégories",
    required: false,
    helpText: "Toutes catégories",
    default: true
  })
  public allcategories!: boolean;

  @DSManyToManyField({
    name: "egalims",
    verboseName: "Labels et certifs.",
    required: false,
    relatedModel: "catalog.ArticleEgalim",
    remoteField: "id",
    helpText: "Labels et certifications concernés",
    default: [],
  })
  public egalims!: number[];
  public egalims_details!: { id: number; code: string; name: string }[];

  @DSBooleanField({
    name: "allegalims",
    verboseName: "Tous labels/cerifs",
    required: false,
    helpText: "Tous labels/certifs",
    default: false
  })
  public allegalims!: boolean;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;

  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags!: any;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class FichesDataService extends DSRestCollection<FichesData> {
  public adapter_config = {basePath: "/api/v2/catalog/fichesdata"};
  public model = FichesData;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
