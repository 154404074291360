import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProduct} from "../_product/product-base.service";


export class SeaProduct extends BaseProduct {
}

@Injectable({providedIn: "root"})
export class SeaProductService extends DSRestCollection<SeaProduct> {
  public adapter_config = {basePath: "/api/v2/seaproducts"};
  public model = SeaProduct;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}

