import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleMarque, ArticleMarqueService} from "../article-marque.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";
import {ProducerService} from "../../../structure/producer/producer.service";
import {SeaProducerService} from "../../../structure/seaproducer/seaproducer.service";
import {FolderService} from "../../../document/folder/folder.service";
import {ProviderService} from "../../../structure/provider/provider.service";

@Component({
  selector: "lvadg-article-marques-manage",
  templateUrl: "./article-marques-manage.component.pug",
  styleUrls: ["./article-marques-manage.component.sass"]
})
export class ArticleMarquesManageComponent extends ArticleDataManageBaseComponent<ArticleMarque, ArticleMarqueService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleMarqueService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute,
              public producers$: ProducerService,
              public seaproducers$: SeaProducerService,
              public folders$: FolderService,
              public providers$: ProviderService) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "marqueId";
    this.filterField = "marque";
  }
}
