
<div class="offcanvas-header">
  <h3 class="offcanvas-title">{{article.libelle}} - détails {{focus}} - unité de facturation {{article.udf_unit}}</h3>
  <button class="btn-close text-reset" type="button" aria-label="Close" (click)="activeOffcanvas.dismiss('Cross click')"></button>
</div>
<div class="offcanvas-body">
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Tarif</th>
        <th>Pilote</th>
        <th class="text-center" *ngFor="let d of dates" [class.table-secondary]="!fdates.has(d)">{{d|date:"dd/MM"}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let t of tarifs">
        <td>{{t.name}}</td>
        <td>{{t.pilotes}}</td>
        <td class="text-center" *ngFor="let d of dates" [class.table-secondary]="!fdates.has(d)">
          <ng-container *ngIf="prices.get(t.tariftype+'/'+d); let price"><span>{{price.price|currency:"EUR"}}</span></ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="graph">
    <lvadg-time-series-chart [data]="graph" type="line" colors="default"></lvadg-time-series-chart>
  </div>
</div>