/**
 * Location module
 *
 * Common location management
 *
 * @status ALPHA
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @date 2018-05-09-00:29:53
 * @changed 2020-06-04-19:10:36
 * @copyright (c) 2017-2020 Jean-Matthieu BARBIER
 * @license AGPLv3
 */

import {NgModule} from "@angular/core";
import {LocationCreateComponent} from "./location-create/location-create.component";
import {LocationDeleteComponent} from "./location-delete/location-delete.component";
import {LocationListComponent} from "./location-list/location-list.component";
import {LocationSelectComponent} from "./location-select/location-select.component";
import {LocationManageComponent} from "./location-manage/location-manage.component";
import {LocationService} from "./location.service";
import {LocationResolve} from "./location.resolve";
import {LocationsManageComponent} from "./locations-manage/locations-manage.component";
import {GeoCommuneModule} from "../../customdata/geocommune/geocommune.module";
import {StorageSelectModule} from "../storage/storage-select.module";
import {NgStringPipesModule} from "ngx-pipes";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {LocationsOsmDisplayComponent} from "./locations-osm-display/locations-osm-display.component";
import {GenericUploadModule} from "../../document/upload/upload.module";
import {ProducerSelectModule} from "../producer/producer-select.module";
import {RestoSelectModule} from "../resto/resto-select.module";
import {SeaProducerSelectModule} from "../seaproducer/seaproducer-select.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    GeoCommuneModule,
    StorageSelectModule,
    ProducerSelectModule,
    SeaProducerSelectModule,
    RestoSelectModule,
    NgStringPipesModule,
    GenericUploadModule
  ],
  providers: [
    LocationService,
    LocationResolve
  ],
  declarations: [
    LocationSelectComponent,
    LocationDeleteComponent,
    LocationListComponent,
    LocationManageComponent,
    LocationCreateComponent,
    LocationsManageComponent,
    LocationsOsmDisplayComponent
  ],
  exports: [
    LocationSelectComponent,
    LocationDeleteComponent,
    LocationListComponent,
    LocationManageComponent,
    LocationCreateComponent,
    LocationsManageComponent,
    LocationsOsmDisplayComponent
  ],
})
export class LocationModule {
}
