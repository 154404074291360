import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField,
  DSTextField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";
import {first} from "rxjs/operators";


export class Document extends DSModel {
  @DSAutoField({
    name: "id"
  })
  public id!: number;
  @DSCharField({
    name: "title",
    verboseName: "Titre",
    minLength: 3,
    maxLength: 200,
    required: true
  })
  public title!: string;
  @DSSlugField({
    name: "slug",
    required: false
  })
  public slug!: string;
  @DSCharField({
    name: "mimetype",
    required: false,
    maxLength: 250,
    default: ""
  })
  public mimetype!: string;
  @DSCharField({
    name: "filename",
    required: false,
    maxLength: 255,
    default: ""
  })
  public filename!: string;
  @DSIntegerField({
    name: "filesize",
    required: false,
    default: 0
  })
  public filesize!: number;
  @DSCharField({
    name: "file",
    required: false,
    maxLength: 255,
    default: ""
  })
  public file!: string;
  @DSForeignKeyField({
    required: false,
    name: "image",
    helpText: "",
    verboseName: "ID Image",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
  })
  public image!: number;


  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;
  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date de mise à jour",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;
  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "En ligne"],
      ["LN", "Hors ligne, téléchargement autorisé"],
      ["ST", "En ligne - staff uniquement"],
      ["SU", "En ligne - superadmin uniquement"],
      ["KO", "Hors ligne"],
      ["DE", "Hors ligne, supprimé"]
    ],
    default: null,
    maxLength: 4,
  })
  public status!: string;
  @DSCharField({
    required: false,
    name: "content_type",
    helpText: "",
    verboseName: "Codage description",
    choices: [],
    default: "md",
    maxLength: 4,
  })
  public content_type!: string;
  @DSTextField({
    required: false,
    name: "content_src",
    helpText: "",
    verboseName: "Description",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content_src!: string;
  @DSTextField({
    required: false,
    name: "content",
    helpText: "",
    verboseName: "Description html",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content!: string;
  @DSDefaultField({
    required: false,
    name: "tags",
    helpText: "",
    verboseName: "tags",
    choices: [],
    default: [],
  })
  public tags!: any;
  @DSManyToManyField({
    required: false,
    name: "folders"
  })
  public folders!: number[];
  @DSDefaultField({
    name: "folders_details",
    required: false,
    verboseName: "Folders details"
  })
  public folders_details!: any[];
  @DSDefaultField({
    name: "image_img",
    required: false
  })
  public image_img!: Rimg;
  private _url: string;

  public get fullPath(): string {
    const out = [];
    for (const p of this.folders_details) {
      out.push(p.title);
    }
    return out.join(" :: ");
  }

  public download(newTab?: any): void {
    if (!newTab) {
      newTab = window.open();
    }
    if (!this._url) {
      this.action("download_link", {method: "POST", body: {}})
        .pipe(first())
        .subscribe((dl) => {
          if (newTab && newTab.location) {
            newTab.location.href = dl.url;
          } else {
            console.error("No location for async newTab, redirecting main window");
            window.location.assign(dl.url);
          }
        });
    } else {
      if (newTab && newTab.location) {
        newTab.location.href = this._url;
      } else {
        console.error("No location for sync newTab, redirecting main window");
        window.location.assign(this._url);
      }

    }
  }
}


@Injectable({
  providedIn: "root"
})
export class DocumentService extends DSRestCollection<Document> {
  public adapter_config = {basePath: "/api/v2/document/documents"};
  public model = Document;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
