import {Component, Input} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {TarifType, TarifTypeService} from "../tariftype.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {takeUntil} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: "lvadg-tariftype-list",
  templateUrl: "./tariftype-list.component.pug",
  styleUrls: ["./tariftype-list.component.sass"]
})
export class TariftypeListComponent extends ModelListBaseComponent<TarifType, TarifTypeService> {
  @Input() public tariftype_detail_route!: RouteV3<TarifType>;
  public flags!: Observable<any>;
  public PREFERRED_CHOICES = [
    {value: "all", desc: "Tous.."},
    {value: "starred", desc: "Préférés"},
    {value: "notified", desc: "Notifiés"}
  ];
  public ZONES_CHOICES = [
    {value: "FL", desc: "Fruits et légumes"},
    {value: "SEA", desc: "Marée"},
    {value: "45G", desc: "4&5eme gamme"}
  ];
  public LIFECYCLE_CHOICES = [
    {value: "UPLOAD", desc: "Upload tarif simple"},
    {value: "GENERIC", desc: "Cycle de vie générique"},
    {value: "GENNOVAL", desc: "Cycle de vie générique (sans validation)"},
  ];

  constructor(
    _list: BsacModelListProvider,
    _items: TarifTypeService,
    _msgs: BsacMessageService,
    routes: RoutesService
  ) {
    super(_list, _items, _msgs, routes);
  }

  public async preNgOnInit(): Promise<void> {
    this.flags = this._items.action(null, "get_flags",
      {method: "GET", body: {}})
      .pipe(
        takeUntil(this._subscriptions$)
      );
    return super.preNgOnInit();
  }


  public async postNgOnInit(): Promise<void> {
    this.list.emitFields = true;
    this.list.filter.set("statuses", {separator: ",", value: ["CUR"]}, {CUR: "Courant"});
  }

  public getRowClasses(model: TarifType): { [p: string]: string | boolean } {
    const rc = super.getRowClasses(model);
    for (const f of model.flags) {
      if (f.indexOf("alarm:nocurrent") !== -1) {
        rc["table-danger"] = true;
        return rc;
      }
    }
    for (const f of model.flags) {
      if (f.indexOf("alarm:") !== -1) {
        rc["table-warning"] = true;
        return rc;
      }
    }
    return rc;
  }

  public getDetailLink(model: TarifType): (string | number)[] {
    if (this.tariftype_detail_route) {
      return this.tariftype_detail_route.route({tarifType: model});
    } else {
      console.warn("FIXME: Using v2 route");
      return this.routes.get(this.zone, "tariftype", model);
    }
  }

}
