import {NgModule} from "@angular/core";
import {GeoCommuneCreateComponent} from "./geocommune-create/geocommune-create.component";
import {GeoCommuneDeleteComponent} from "./geocommune-delete/geocommune-delete.component";
import {GeoCommuneDisplayComponent} from "./geocommune-display/geocommune-display.component";
import {GeoCommuneListComponent} from "./geocommune-list/geocommune-list.component";
import {GeoCommuneSelectComponent} from "./geocommune-select/geocommune-select.component";
import {GeoCommuneManageComponent} from "./geocommune-manage/geocommune-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
  ],
  providers: [],
  declarations: [
    GeoCommuneSelectComponent,
    GeoCommuneDisplayComponent,
    GeoCommuneDeleteComponent,
    GeoCommuneListComponent,
    GeoCommuneManageComponent,
    GeoCommuneCreateComponent
  ],
  exports: [
    GeoCommuneSelectComponent,
    GeoCommuneDisplayComponent,
    GeoCommuneDeleteComponent,
    GeoCommuneListComponent,
    GeoCommuneManageComponent,
    GeoCommuneCreateComponent
  ],
})
export class GeoCommuneModule {
}
