import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, InjectionToken, Optional} from "@angular/core";
import {Router} from "@angular/router";
import {DS_REST_BACKEND_CONFIG} from "@solidev/ngdataservice";
import {Observable, ReplaySubject} from "rxjs";
import {ErrorService} from "../../../error/error.service";
import {Site} from "./site.service";

export const LVADG_MAIN_SITE_DOMAIN = new InjectionToken<string>("lvadg.site.maindomain");
export const LVADG_FORCE_DOMAIN = new InjectionToken<string>("lvadg.site.forcedomain")

@Injectable({
  providedIn: "root"
})
export class CurrentSiteService {

  private _current: Site|null = null;
  private _domain?: string;
  constructor(@Inject(DS_REST_BACKEND_CONFIG) private backendConfig: any,
              @Inject(LVADG_MAIN_SITE_DOMAIN) private mainsiteDomain: string,
              @Optional() @Inject(LVADG_FORCE_DOMAIN) private forceDomain: string = "",
              private _router: Router,
              private _err: ErrorService,
              private _http: HttpClient) {
  }

  private _site!: ReplaySubject<Site|null>;

  public get site() {
    return this._current;
  }

  public set site(site: Site|null) {
    if (!this._site) {
      this._site = new ReplaySubject<Site|null>(1);
    }
    this._current = site;
    this._site.next(this._current);
  }

  public get site$(): Observable<Site|null> {
    if (!this._site) {
      this._site = new ReplaySubject<Site>(1);
      this._getSite();
    }
    return this._site.asObservable();
  }




  private _getSite(): void {

    let domain = window.location.hostname;
    domain = domain.replace("next.", "");
    domain = domain.replace(this.mainsiteDomain, "");

    if (this.forceDomain) {
      domain = this.forceDomain;
    }

    if (domain === "") {
      return this._setPublicSite();
    }
    if (domain === "www.") {
      return this._setPublicSite();
    } else {
      domain = domain.replace("www.", "");
      domain = domain.replace("-admin", "");
      domain = domain.replace("-legacy", "");
      if (domain === "admin.") {
        return this._setAdminSite();
      } else if (domain === "vivalya.") {
        return this._setVivalyaSite();
      } else if (domain === "pilote.") {
        return this._setPiloteSite();
      } else {
        domain = domain.substring(0, domain.length - 1);
        return this._setCustomDomain(domain);
      }
    }
  }

  private _setPublicSite(): void {
    this.site = new Site(null, {
      id: -1,
      domain: "www",
      title: "#lavieadugout public",
      tags: ["public"],
      type: "public"
    });
  }

  private _setAdminSite(): void {
    this.site = new Site(null, {
      id: -1,
      domain: "admin",
      title: "#lavieadugout admin site",
      tags: ["admin"],
      type: "admin"
    });
  }

  private _setVivalyaSite(): void {
    this.site = new Site(null, {
      id: -1,
      domain: "vivalya",
      title: "#lavieadugout vivalya site",
      tags: ["vivalya"],
      type: "vvya"
    });
  }

  private _setPiloteSite(): void {
    this.site = new Site(null, {
      id: -1,
      domain: "pilote",
      title: "#lavieadugout pilote tarifaire",
      tags: ["pilote"],
      type: "pilo"
    });
  }


  private _setCustomDomain(domain: string): void {
    //  Use direct http as we are not yet connected
    console.log("Using domain", domain);
    this._http.get<Site>(this.backendConfig.url + "/api/v2/sites/bydomain", {params: {domain: domain}})
      .subscribe({
        next: (result) => {
          this.site = new Site(null, result);
        },
        error: () => {
          this._err.fatal(`Can't find ${domain} website`);
          this.site = null;
        }
      });
  }
}
