<!DOCTYPE html>
<div class="row" *ngIf="print &amp;&amp; loaded">
  <div class="col-sm-12 mb-3">
    <lvadg-modelprint-select-page [pages]="restos" (selectedPage)="selectPage($event)" emptyName="OFFRE GLOBALE"></lvadg-modelprint-select-page>
  </div>
  <div class="col-sm-6" *ngIf="loaded">
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel title="En-tête">
        <ng-template ngbPanelContent>
          <h4>Logos et arrière plan</h4>
          <div class="row">
            <div class="col-sm-6">
              <lvadg-commfield-seltxtcustomimg [params]="params$" part="header" name="logo1" [fields$]="fields$"></lvadg-commfield-seltxtcustomimg>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-seltxtcustomimg [params]="params$" part="header" name="logo2" [fields$]="fields$"></lvadg-commfield-seltxtcustomimg>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-seltxtcustomimg [params]="params$" part="header" name="logo3" [fields$]="fields$"></lvadg-commfield-seltxtcustomimg>
            </div>
            <div class="col-sm-12">
              <lvadg-commfield-selimgcustomimg [params]="params$" part="header" name="background" [fields$]="fields$"></lvadg-commfield-selimgcustomimg>
            </div>
          </div>
          <h4>Champs texte du cartouche</h4>
          <div class="row">
            <div class="col-sm-6">
              <lvadg-commfield-charfield [params]="params$" part="header" name="textline1"></lvadg-commfield-charfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-charfield [params]="params$" part="header" name="textline2"></lvadg-commfield-charfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-charfield [params]="params$" part="header" name="textline3"></lvadg-commfield-charfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-charfield [params]="params$" part="header" name="textline4"></lvadg-commfield-charfield>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel title="Textes">
        <ng-template ngbPanelContent>
          <h3>Textes</h3>
          <div class="row">
            <div class="col-12">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="introduction" mode="rich" [title]="false"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block1"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block2"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block3"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block4"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block5"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block6"></lvadg-commfield-textfield>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel title="Cartographie">
        <ng-template ngbPanelContent>
          <h3>Cartographie</h3>
          <div class="row">
            <div class="col-sm-6">
              <lvadg-commfield-select [params]="params$" part="maps" name="map1"></lvadg-commfield-select>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-select [params]="params$" part="maps" name="map1centeroptions"></lvadg-commfield-select>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-select [params]="params$" part="maps" name="map1formatoptions"></lvadg-commfield-select>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-select [params]="params$" part="maps" name="map1circleoptions"></lvadg-commfield-select>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-selectmultiple [params]="params$" part="maps" name="map1pointersoptions"></lvadg-commfield-selectmultiple>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel title="Liste produits">
        <ng-template ngbPanelContent>
          <h3>Liste produits</h3>
          <div class="row">
            <div class="col-12">
              <lvadg-commfield-textfield [params]="params$" part="products" name="introduction" mode="rich" [title]="false"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-12">
              <lvadg-commfield-selectmultiple [params]="params$" part="products" name="dispfields"></lvadg-commfield-selectmultiple>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="col-sm-6">
    <lvadg-modelcomm-preview [model]="print" [pageChanged]="page$" [pages]="restos" emptyName="OFFRE GLOBALE"></lvadg-modelcomm-preview>
  </div>
</div>