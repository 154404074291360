import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Client} from "../client/client.service";
import {Member} from "../member/member.service";

export interface RelationStructureDetails {
  id: number;
  name: string;
  cvva: string;
  status: string;
  client_details?: Client;
  member_details?: Member;
  mainlocation_details: Location
}


export interface RelationDistanceDetails {
  id: number;
  road: number;
  direct: number;
  rtime: number;
  updated: Date;
  created: Date;
}

export class Relation extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSForeignKeyField({
    required: false,
    name: "client",
    helpText: "",
    verboseName: "client",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Client",
    remoteField: "relations",
  })
  public client!: number;

  @DSForeignKeyField({
    required: false,
    name: "resto",
    helpText: "",
    verboseName: "resto",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Resto",
    remoteField: "relations",
  })
  public resto!: number;

  @DSForeignKeyField({
    required: false,
    name: "member",
    helpText: "",
    verboseName: "member",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Member",
    remoteField: "relations",
  })
  public member!: number;

  @DSForeignKeyField({
    required: false,
    name: "storage",
    helpText: "",
    verboseName: "storage",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Storage",
    remoteField: "relations",
  })
  public storage!: number;

  @DSForeignKeyField({
    required: false,
    name: "producer",
    helpText: "",
    verboseName: "producer",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Producer",
    remoteField: "relations",
  })
  public producer!: number;

  @DSForeignKeyField({
    required: false,
    name: "seaproducer",
    helpText: "",
    verboseName: "Producteur marée",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "SeaProducer",
    remoteField: "relations",
  })
  public seaproducer!: number;

  @DSForeignKeyField({
    required: false,
    name: "provider",
    helpText: "",
    verboseName: "Fournisseur référencé",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Provider",
    remoteField: "relations",
  })
  public provider!: number;

  @DSForeignKeyField({
    required: false,
    name: "distance",
    helpText: "",
    verboseName: "distance",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "DistanceCache",
    remoteField: "relations",
  })
  public distance!: number;

  @DSDefaultField({
    name: "distance__road",
    helpText: "Distance route",
    verboseName: "Distance route"
  })
  public distance__road!: number;

  @DSDefaultField({
    name: "distance__direct",
    helpText: "Distance directe",
    verboseName: "Distance directe"
  })
  public distance__direct!: number;

  @DSCharField({
    required: true,
    name: "type",
    helpText: "",
    verboseName: "type",
    choices: [
      ["SC", "Dépôt -> Client"],
      ["SR", "Dépôt -> Restaurant"],
      ["MC", "Adhérent -> Client"],
      ["MR", "Adhérent -> Resto"],
      ["PS", "Producteur -> Dépôt"],
      ["QS", "Producteur marée -> Dépôt"],
      ["WP", "Fournisseur référencé -> Producteur"],
      ["WS", "Fournisseur référencé -> Producteur marée"],
    ],
    default: null,
    maxLength: 10,
  })
  public type!: string;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "created",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "updated",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [["OK", "Valide"], ["KO", "Invalide"], ["OM", "Valide (manuel)"], ["KM", "Invalide (manuel)"]],
    default: null,
    maxLength: 4,
  })
  public status!: string;

  @DSDefaultField({
    required: false,
    name: "flags",
    helpText: "",
    verboseName: "Flags",
    default: [],
  })
  public flags!: string[];

  public resto_details!: RelationStructureDetails;
  public storage_details!: RelationStructureDetails;
  public member_details!: RelationStructureDetails;
  public client_details!: RelationStructureDetails;
  public producer_details!: RelationStructureDetails;
  public provider_details!: RelationStructureDetails;
  public distance_details!: RelationDistanceDetails;

}

@Injectable({
  providedIn: "root"
})
export class RelationService extends DSRestCollection<Relation> {
  public adapter_config = {basePath: "/api/v2/structure/relations"};
  public model = Relation;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
