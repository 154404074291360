import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {GeoRegionCreateComponent} from "./georegion-create/georegion-create.component";
import {GeoRegionDeleteComponent} from "./georegion-delete/georegion-delete.component";
import {GeoRegionListComponent} from "./georegion-list/georegion-list.component";
import {GeoRegionManageComponent} from "./georegion-manage/georegion-manage.component";
import {GeoRegionService} from "./georegion.service";
import {GeoRegionResolve} from "./georegion.resolve";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {CdkTableModule} from "@angular/cdk/table";
import {CompsModule} from "../../../comps/comps.module";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsacModelListModule,
    BsacApiConstantsModule,

    BsacFormsModule,
    NgbModule,
    CompsModule,
    CdkTableModule,
    ReactiveFormsModule
  ],
  providers: [
    GeoRegionService,
    GeoRegionResolve
  ],
  declarations: [
    GeoRegionDeleteComponent,
    GeoRegionListComponent,
    GeoRegionManageComponent,
    GeoRegionCreateComponent
  ],
  exports: [
    GeoRegionDeleteComponent,
    GeoRegionListComponent,
    GeoRegionManageComponent,
    GeoRegionCreateComponent
  ],
})
export class GeoRegionModule {
}
