import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ExternalArticleCollection, ExternalArticleCollectionService} from "./externalarticlecollection.service";

@Injectable({providedIn: "root"})
export class ExternalArticleCollectionResolve implements Resolve<ExternalArticleCollection> {
  constructor(private _bs: ExternalArticleCollectionService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ExternalArticleCollection> {
    return this._bs.get(+route.params["externalArticleCollectionId"]);
  }
}
