import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {EChartsOption, SeriesOption} from "echarts";
import {VV_SCHEMES, VVFL_SCHEME_1} from "../schemes";

export interface CategSeriesChartDataset {
  title?: string;
  subtitle?: string;
  axis: {
    categs: {
      title: string;
      unit: string;
    },
    value: {
      title: string;
      unit: string;
    }
  };
  legend: boolean;
  data: { value: number; name: string }[];
}


@Component({
  selector: "lvadg-categ-series-chart",
  templateUrl: "./categ-series-chart.component.pug",
  styleUrls: ["./categ-series-chart.component.sass"]
})
export class CategSeriesChartComponent implements OnInit, OnChanges {
  @Input() public data!: CategSeriesChartDataset;
  @Input() public type: "bar" | "pie" = "pie";
  @Input() public colors: "sea"|"fl" = "fl";
  public scheme: string[] = VVFL_SCHEME_1;
  public options!: EChartsOption;

  constructor() {
  }

  public ngOnInit(): void {
    if (this.data) {
      this.setOptions();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.scheme = VV_SCHEMES[this.colors];
    if (this.data) {
      this.setOptions();
    }
  }

  public setOptions(): void {
    this.options = {
      color: this.scheme,
      title: {
        text: this.data.title,
        subtext: this.data.subtitle,
        top: 10,
        left: "center",
        textAlign: "center",
        show: !!this.data.title
      },
      tooltip: {
        show: true
      },
      legend: {
        show: this.data.legend,
        align: "auto",
        orient: "vertical",
        top: "top",
        right: 10,
      },
      dataset: {
        source: this.data.data
      },
      series: [],
      grid: {
        left: 50,
        top: 50,
        bottom: 50,
        right: this.data.legend ? "12%" : 50
      }
    };

    const ser = (this.options.series as SeriesOption[]);

    if (this.type === "bar") {
      this.options.xAxis = {
        type: "category" as any,
        name: this.data.axis.categs.title + (this.data.axis.categs.unit ? ` (${this.data.axis.categs.unit})` : ""),
        axisLabel: {
          formatter: (value: number, index: number) => {
            return `${value} ${this.data.axis.value.unit}`;
          }
        }
      };
      this.options.yAxis = {
        type: "value",
        name: this.data.axis.value.title + (this.data.axis.value.unit ? ` (${this.data.axis.value.unit})` : ""),
      };
      ser.push({
        type: "bar",
        name: this.data.title
      });
    } else if (this.type === "pie") {
      ser.push({
        type: "pie",
        name: this.data.axis.categs.title + (this.data.axis.categs.unit ? ` (${this.data.axis.categs.unit})` : ""),
      });
    }
  }
}
