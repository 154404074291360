import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {ArticleEgalim} from "../articledata/article-egalim.service";
import {ArticleMarque} from "../articledata/article-marque.service";
import {ArticleCalibre} from "../articledata/article-calibre.service";
import {DetailDocument} from "../../document/basedocument/basedocument.service";
import {Client} from "../../structure/client/client.service";
import {CiqualColumn} from "../../customdata/ciqualcolumn/ciqualcolumn.service";
import {ArticleTarifsStats} from "./article-tarifs-stats";


export interface ISimpleIdName {
  id: number;
  code: string;
  name: string;
}

export interface IFamilyDetails {
  id: number;
  name: string;
  cdmode: string;
  cfullname?: string;
}


export class Article extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;
  @DSIntegerField({
    name: "vvid",
    verboseName: "ID Vivalya",
    required: false,
    helpText: "ID Vivalya",
    default: 0,
    choices: []
  })
  public vvid!: number;
  @DSTextField({
    name: "content_src",
    verboseName: "Description longue",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 4000
  })
  public content_src!: string;
  @DSTextField({
    name: "content",
    verboseName: "Description longue (html)",
    required: false,
    helpText: "",
    choices: []
  })
  public content!: string;
  @DSDefaultField({
    name: "seasons",
    verboseName: "Saisons",
    required: false,
    helpText: "",
    choices: []
  })
  public seasons!: any;
  @DSForeignKeyField({
    name: "family",
    verboseName: "Classification",
    required: false,
    helpText: "",
    relatedModel: "catalog.ArticleFamily",
    remoteField: "id",
    relatedName: "articles"
  })
  public family!: number;
  public family_details?: IFamilyDetails[];

  public family_cfullname!: IFamilyDetails;

  @DSDefaultField({
    name: "gamme",
    verboseName: "Gamme",
    required: false,
    helpText: "Gamme"
  })
  public gamme!: string;
  @DSDefaultField({
    name: "nature",
    verboseName: "Nature",
    required: false,
    helpText: "Nature"
  })
  public nature!: string;
  @DSDefaultField({
    name: "famille",
    verboseName: "Famille",
    required: false,
    helpText: "Famille"
  })
  public famille!: string;
  @DSDefaultField({
    name: "produit",
    verboseName: "Produit",
    required: false,
    helpText: "Produit"
  })
  public produit!: string;
  @DSDefaultField({
    name: "variete",
    verboseName: "Variété",
    required: false,
    helpText: "Variété"
  })
  public variete!: string;
  @DSCharField({
    name: "code",
    verboseName: "Code",
    required: true,
    helpText: "Code Vivalya",
    minLength: 0,
    maxLength: 50,
    choices: []
  })
  public code!: string;
  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: true,
    helpText: "",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public name!: string;
  @DSCharField({
    name: "libelle",
    verboseName: "Libellé",
    required: false,
    helpText: "Libellé",
    minLength: 0,
    maxLength: 500,
    choices: []
  })
  public libelle!: string;

  @DSCharField({
    name: "libelle_auto",
    verboseName: "Libellé (auto)",
    required: false,
    helpText: "Libellé (auto)",
    minLength: 0,
    maxLength: 500,
    choices: []
  })
  public libelle_auto!: string;


  @DSCharField({
    name: "scname",
    verboseName: "Nom scientifique",
    required: false,
    helpText: "Nom scientifique",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public scname!: string;
  @DSManyToManyField({
    name: "allergenes",
    verboseName: "Allergènes",
    required: false,
    relatedModel: "ArticleAllergene",
    remoteField: "",
    helpText: "",
    default: [],
  })
  public allergenes!: number[];
  public allergenes_details!: ISimpleIdName[];
  @DSForeignKeyField({
    name: "production",
    verboseName: "Production",
    required: false,
    helpText: "",
    relatedModel: "ArticleProduction",
    remoteField: "id",
    relatedName: "articles"
  })
  public production!: number;
  public production_details!: ISimpleIdName;
  @DSForeignKeyField({
    name: "calibre",
    verboseName: "Calibre",
    required: false,
    helpText: "Calibre",
    relatedModel: "ArticleCalibre",
    remoteField: "id",
    relatedName: "articles"
  })
  public calibre!: number;
  public calibre_details!: ArticleCalibre;
  @DSForeignKeyField({
    name: "marque",
    verboseName: "Marque",
    required: false,
    helpText: "Marque",
    relatedModel: "ArticleMarque",
    remoteField: "id",
    relatedName: "articles"
  })
  public marque!: number;
  public marque_details!: ArticleMarque;
  @DSForeignKeyField({
    name: "categorie",
    verboseName: "Catégorie",
    required: false,
    helpText: "Catégorie",
    relatedModel: "",
    remoteField: "id",
    relatedName: ""
  })
  public categorie!: number;
  public categorie_details!: ISimpleIdName;
  @DSIntegerField({
    name: "ciqual",
    verboseName: "Ciqual",
    required: false,
    helpText: "Ciqual",
    default: 0,
    choices: []
  })
  public ciqual!: number;
  @DSForeignKeyField({
    name: "client",
    verboseName: "Client spécifique",
    required: false,
    helpText: "Client spécifique",
    relatedModel: "ArticleClient",
    remoteField: "id",
    relatedName: "articles"
  })
  public client!: number;
  public client_details!: ISimpleIdName;
  @DSForeignKeyField({
    name: "colisage_cond",
    verboseName: "Colisage (conditionnement)",
    required: false,
    helpText: "Colisage (conditionnement)",
    relatedModel: "ArticleConditionnement",
    remoteField: "id",
    relatedName: "article_colisage"
  })
  public colisage_cond!: number;
  public colisage_cond_details!: ISimpleIdName;
  @DSDecimalField({
    name: "colisage_nombre",
    verboseName: "Colisage (nombre)",
    required: false,
    helpText: "Colisage (nombre)",
    default: null,
    factor: 10000,
    choices: []
  })
  public colisage_nombre!: number;
  @DSDecimalField({
    name: "colisage_poids",
    verboseName: "Colisage (poids)",
    required: false,
    helpText: "Colisage (poids)",
    default: null,
    factor: 10000,
    choices: []
  })
  public colisage_poids!: number;
  @DSCharField({
    name: "colisage_unit",
    verboseName: "Colisage (unité)",
    required: false,
    helpText: "Colisage (unité)",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["K", "Kg"],
      ["G", "g"],
      ["L", "L"],
      ["P", "Pce"],
      ["C", "Colis"],
      ["", "-"]
    ]
  })
  public colisage_unit!: string;
  @DSDecimalField({
    name: "dlc_value",
    verboseName: "DLC (valeur)",
    required: false,
    helpText: "DLC (valeur)",
    default: 0,
    factor: 1,
    choices: []
  })
  public dlc_value!: number;
  @DSCharField({
    name: "dlc_unit",
    verboseName: "Unité DLC",
    required: false,
    helpText: "Unité DLC",
    minLength: 0,
    maxLength: 4,
    choices: [
      [null, ""],
      ["", "-"],
      ["J", "Jours"],
      ["M", "Mois"]
    ]
  })
  public dlc_unit!: string;

  @DSDecimalField({
    name: "ddm_value",
    verboseName: "DDM (valeur)",
    required: false,
    helpText: "DDM (valeur)",
    default: 0,
    factor: 1,
    choices: []
  })
  public ddm_value!: number;
  @DSCharField({
    name: "ddm_unit",
    verboseName: "Unité DDM",
    required: false,
    helpText: "Unité DDM",
    minLength: 0,
    maxLength: 4,
    choices: [
      [null, ""],
      ["", "-"],
      ["J", "Jours"],
      ["M", "Mois"]
    ]
  })
  public ddm_unit!: string;

  @DSManyToManyField({
    name: "egalim",
    verboseName: "Labels & certifications",
    required: false,
    relatedModel: "ArticleEgalim",
    remoteField: "id",
    helpText: "Labels et certifications",
    default: [],
  })
  public egalim!: number[];
  public egalim_details!: ArticleEgalim[];

  @DSCharField({
    name: "local",
    verboseName: "Local",
    required: false,
    helpText: "Local",
    minLength: 0,
    maxLength: 1,
    default: "-",
    choices: [
      ["L", "Local"],
      ["U", "Ultra local"],
      ["-", "-"]
    ]
  })
  public local!: string;

  @DSForeignKeyField({
    name: "origine",
    verboseName: "Origine",
    required: false,
    helpText: "Origine",
    relatedModel: "ArticleOrigine",
    remoteField: "id",
    relatedName: "articles"
  })
  public origine!: number;
  public origine_details!: ISimpleIdName;
  @DSForeignKeyField({
    name: "particularite",
    verboseName: "Particularités",
    required: false,
    helpText: "Particularité",
    relatedModel: "ArticleParticularite",
    remoteField: "id",
    relatedName: "article"
  })
  public particularite!: number;
  public particularite_details!: ISimpleIdName;
  @DSDecimalField({
    name: "poids_min",
    verboseName: "Poids mini",
    required: false,
    helpText: "Poids mini",
    default: 0,
    factor: 10000,
    choices: []
  })
  public poids_min!: number;
  @DSDecimalField({
    name: "poids_max",
    verboseName: "Poids max",
    required: false,
    helpText: "Poids maxi",
    default: 0,
    factor: 10000,
    choices: []
  })
  public poids_max!: number;
  @DSForeignKeyField({
    name: "presentation",
    verboseName: "Présentation",
    required: false,
    helpText: "Présentation",
    relatedModel: "ArticlePresentation",
    remoteField: "id",
    relatedName: "article"
  })
  public presentation!: number;
  public presentation_details!: ISimpleIdName;
  @DSForeignKeyField({
    name: "process",
    verboseName: "Process",
    required: false,
    helpText: "Process",
    relatedModel: "ArticleProcess",
    remoteField: "id",
    relatedName: "article"
  })
  public process!: number;
  public process_details!: ISimpleIdName;
  @DSTextField({
    name: "recette",
    verboseName: "Recette",
    required: false,
    helpText: "Recette",
    minLength: 0,
    maxLength: 1000,
    choices: []
  })
  public recette!: string;
  @DSForeignKeyField({
    name: "region",
    verboseName: "Région",
    required: false,
    helpText: "Région",
    relatedModel: "ArticleRegion",
    remoteField: "id",
    relatedName: "article"
  })
  public region!: number;
  public region_details!: ISimpleIdName;
  @DSIntegerField({
    name: "rnm_code",
    verboseName: "Code RNM",
    required: false,
    helpText: "",
    default: 0,
    choices: []
  })
  public rnm_code!: number;
  @DSCharField({
    name: "temperature",
    verboseName: "Température",
    required: false,
    helpText: "Température",
    minLength: 0,
    maxLength: 50,
    choices: []
  })
  public temperature!: string;
  @DSForeignKeyField({
    name: "traitement",
    verboseName: "Traitement",
    required: false,
    helpText: "Traitement",
    relatedModel: "ArticleTraitement",
    remoteField: "id",
    relatedName: "article"
  })
  public traitement!: number;
  public traitement_details!: ISimpleIdName;
  @DSForeignKeyField({
    name: "uci_cond",
    verboseName: "UCI (conditionnement)",
    required: false,
    helpText: "",
    relatedModel: "ArticleConditionnement",
    remoteField: "id",
    relatedName: "article_uci"
  })
  public uci_cond!: number;
  public uci_cond_details!: ISimpleIdName;
  @DSDecimalField({
    name: "uci_value",
    verboseName: "Valeur UCI",
    required: false,
    helpText: "Valeur UCI",
    default: "",
    factor: 100000,
    choices: []
  })
  public uci_value!: number;
  @DSCharField({
    name: "uci_unit",
    verboseName: "Unité UCI",
    required: false,
    helpText: "Unité UCI",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["K", "Kg"],
      ["G", "g"],
      ["L", "L"],
      ["P", "Pce"],
      ["C", "Colis"],
      ["", "-"]
    ]
  })
  public uci_unit!: string;
  @DSCharField({
    name: "udf_unit",
    verboseName: "UDF (unité)",
    required: false,
    helpText: "UDF (unité)",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["K", "Kg"],
      ["G", "g"],
      ["L", "L"],
      ["P", "Pce"],
      ["C", "Colis"],
      ["", "-"]
    ]
  })
  public udf_unit!: string;
  @DSDecimalField({
    name: "udv_value",
    verboseName: "Unité de Vente",
    required: false,
    helpText: "",
    default: 0,
    factor: 100000,
    choices: []
  })
  public udv_value!: number;
  @DSForeignKeyField({
    name: "usage",
    verboseName: "Usage",
    required: false,
    helpText: "Usage",
    relatedModel: "ArticleUsage",
    remoteField: "id",
    relatedName: "article"
  })
  public usage!: number;
  public usage_details!: ISimpleIdName;
  @DSDefaultField({
    name: "clogo_img",
    verboseName: "Logo",
    required: false,
    helpText: "Logo",
    default: 0,
    choices: []
  })
  public clogo_img!: any;

  @DSForeignKeyField({
    name: "ciqual_alim",
    verboseName: "Ciqual Aliment",
    required: false,
    helpText: "Ref Ciqual",
    relatedModel: "commondata.CiqualAlim",
    remoteField: "id",
    relatedName: "alims"
  })
  public ciqual_alim!: number;
  public ciqual_alim_details!: {
    alim_code: number,
    values_details: {
      less: boolean,
      value: number,
      traces: boolean,
      column: number,
      column_details: CiqualColumn
    }[]
  };

  @DSForeignKeyField({
    name: "fournisseur",
    verboseName: "Fournisseur",
    required: false,
    helpText: "Fournisseur",
    relatedModel: "structure.Provider",
    remoteField: "id",
    relatedName: "artilce"
  })
  public fournisseur!: number;
  public fournisseur_details!: { id: number; cvva: string; name: string; };


  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;
  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSManyToManyField({
    name: "documents",
    verboseName: "Documents article",
    required: false,
    relatedModel: "document.DetailDocument",
    remoteField: "articleproviderdocuments",
    helpText: "Documents article",
    default: [],
  })
  public documents!: number[];
  public documents_details!: DetailDocument[];

  @DSDefaultField({
    required: false,
    name: "documents_count",
    verboseName: "Nb Documents",
    helpText: "Nombre de documents",
  })
  public documents_count!: number;

  @DSCharField({
    name: "url",
    verboseName: "URL",
    required: false,
    helpText: "Url",
    minLength: 0,
    maxLength: 255,
    choices: []
  })
  public url!: string;


  @DSDefaultField({
    name: "iourl",
    verboseName: "IO Url",
    required: false,
    helpText: "IO Url",
    choices: []
  })
  public iourl!: {url: string; code: string;};

  @DSCharField({
    name: "qr",
    verboseName: "QR Code",
    required: false,
    helpText: "QR Code",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public qr: string;


  public tarifs_stats?: ArticleTarifsStats;
  // public get tstats(): ArticleTarifsStats|undefined {
  //   if (!this._tstats) {
  //     this._tstats = new
  //   }
  // }

  /**
   * Receives 0/1 if article is in requested tarifType
   */
  public in_tarif!: number;


  private _isEgalimPlus!: boolean;

  private _isEgalim!: boolean;

  /**
   * Cache "isEgalim" result.
   */
  public get isEgalim(): boolean {
    if (this._isEgalim === undefined) {
      for (const e of this.egalim_details) {
        if (e.egalim) {
          this._isEgalim = true;
          return true;
        }
      }
      this._isEgalim = false;
    }
    return this._isEgalim;
  }

  public get zone(): "fl" | "mr" {
    if (this.family_details && this.family_details[this.family_details.length - 1]
      && this.family_details[this.family_details.length - 1].cdmode) {
      return this.family_details[this.family_details.length - 1].cdmode.indexOf("MR") !== -1 ? "mr" : "fl";
    }
    return "fl";
  }

  /**
   * Cache "isEgalimPlus" result.
   */
  public isEgalimPlus(client?: Client): boolean {
    // FIXME: use some flag for clients without egalimplus settings
    if (this._isEgalimPlus === undefined) {
      if (client) {
        for (const e of this.egalim_details) {
          for (const ec of e.egalim_plus_client) {
            if (client.id === ec) {
              this._isEgalimPlus = true;
              return true;
            }
          }
        }
      } else {
        for (const e of this.egalim_details) {
          if (e.egalim_plus) {
            this._isEgalimPlus = true;
            return true;
          }
        }
      }
      this._isEgalimPlus = false;
    }
    return this._isEgalimPlus;
  }
}

@Injectable({
  providedIn: "root"
})
export class ArticleService extends DSRestCollection<Article> {
  public adapter_config = {basePath: "/api/v2/catalog/articles"};
  public model = Article;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
