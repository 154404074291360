import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";

export class Client extends DSModel {
  // INFOS DE BASE
  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSCharField({
    required: true,
    name: "name",
    helpText: "",
    verboseName: "Nom de la société",
    choices: [],
    default: null,
    maxLength: 60,
  })
  public name!: string;

  @DSCharField({
    required: false,
    name: "cvva",
    helpText: "",
    verboseName: "Code Vivalya",
    choices: [],
    maxLength: 10,
  })
  public cvva!: string;

  // LIAISONS
  @DSForeignKeyField({
    required: false,
    name: "sites",
    helpText: "",
    verboseName: "IDs sites",
    choices: {},
    default: null,
    autoCreated: true,
    relatedModel: "Site",
    relatedName: "sites",
    remoteField: "client",
    multiple: true,
  })
  public sites!: number[];

  @DSForeignKeyField({
    required: false,
    name: "restos",
    helpText: "",
    verboseName: "IDs restos",
    choices: {},
    default: null,
    autoCreated: true,
    relatedModel: "Resto",
    relatedName: "restos",
    remoteField: "client",
    multiple: true,
  })
  public restos!: number[];

  @DSForeignKeyField({
    required: false,
    name: "locations",
    helpText: "",
    verboseName: "IDs localisations",
    choices: {},
    default: null,
    autoCreated: true,
    relatedModel: "Location",
    relatedName: "locations",
    remoteField: "client",
    multiple: true,
  })
  public locations!: number[];

  @DSForeignKeyField({
    required: false,
    name: "relations",
    helpText: "",
    verboseName: "IDs relations",
    choices: {},
    default: null,
    autoCreated: true,
    relatedModel: "Relation",
    relatedName: "relations",
    remoteField: "client",
    multiple: true,
  })
  public relations!: number[];

  // CONTENT FIELDS
  @DSCharField({
    required: false,
    name: "content_type",
    helpText: "",
    verboseName: "Format description",
    choices: [],
    default: "md",
    maxLength: 4,
  })
  public content_type!: string;

  @DSTextField({
    required: false,
    name: "content_src",
    helpText: "",
    verboseName: "Description (source)",
    choices: [],
    default: "",
    maxLength: 4000,
    minLength: 0
  })
  public content_src!: string;

  @DSTextField({
    required: false,
    name: "content",
    helpText: "",
    verboseName: "Description (html)",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content!: string;


  @DSManyToManyField({
    name: "slabels",
    verboseName: "Labels structure",
    required: false
  })
  public slabels!: number[];

  // INFOS STRUCTURE
  @DSCharField({
    required: false,
    name: "type",
    helpText: "",
    verboseName: "Type",
    choices: [
      ["GC", "Grand compte"],
      ["CD", "Commerce de d\u00e9tail"],
      ["CG", "Commerce de gros"],
      ["HS", "Hypermarch\u00e9 / supermarch\u00e9"],
      ["MB", "M\u00e9tiers de bouche"],
      ["RC", "Restauration collective"],
      ["OT", "Non cat\u00e9goris\u00e9"]],
    default: null,
    maxLength: 4,
  })
  public type!: string;

  @DSTextField({
    required: false,
    name: "mainaddress",
    helpText: "",
    verboseName: "Adresse principale",
    choices: [],
    default: null,
    maxLength: 400,
  })
  public mainaddress!: string;

  @DSCharField({
    required: false,
    name: "phone",
    helpText: "",
    verboseName: "Téléphone",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public phone!: string;

  @DSCharField({
    required: false,
    name: "fax",
    helpText: "",
    verboseName: "Fax",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public fax!: string;

  @DSCharField({
    required: false,
    name: "website",
    helpText: "",
    verboseName: "Site web",
    choices: [],
    default: null,
    maxLength: 400,
  })
  public website!: string;

  @DSCharField({
    required: false,
    name: "siren",
    helpText: "",
    verboseName: "SIREN",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public siren!: string;

  @DSCharField({
    required: false,
    name: "siret",
    helpText: "",
    verboseName: "SIRET",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public siret!: string;

  @DSCharField({
    required: false,
    name: "tvaic",
    helpText: "",
    verboseName: "N° TVA",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public tvaic!: string;

  @DSCharField({
    required: false,
    name: "naf",
    helpText: "",
    verboseName: "Code NAF",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public naf!: string;

  @DSCharField({
    required: false,
    name: "legal",
    helpText: "",
    verboseName: "Structure légale",
    choices: [],
    default: null,
    maxLength: 50,
  })
  public legal!: string;

  @DSCharField({
    required: false,
    name: "capital",
    helpText: "",
    verboseName: "Capital",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public capital!: string;

  // INFOS CREATION
  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [["OK", "Valide"], ["VV", "Valide[Vivalya]"], ["IM", "Valide[import]"], ["KO", "Non valide"]],
    default: null,
    maxLength: 4,
  })
  public status!: string;

  // IMAGES
  @DSForeignKeyField({
    required: false,
    name: "logo",
    helpText: "",
    verboseName: "ID Logo",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "logoclient",
  })
  public logo!: number;

  @DSManyToManyField({
    required: false,
    name: "images",
    helpText: "",
    verboseName: "IDs images",
    choices: [],
    default: [],
  })
  public images!: number[];

  @DSDefaultField({
    required: false,
    name: "images_details",
    verboseName: "Détails images"
  })
  public images_details: Rimg[] = [];

  @DSDefaultField({
    required: false,
    name: "logo_img",
    verboseName: "Détails logo"
  })
  public logo_img!: Rimg;


  // TREE FIELDS
  @DSManyToManyField({
    required: false,
    name: "children",
    helpText: "",
    verboseName: "IDs sous structures",
    choices: {},
    default: null,
    relatedModel: "Client"
  })
  public children!: number[];

  @DSForeignKeyField({
    required: true,
    name: "parent",
    helpText: "",
    verboseName: "ID Parent",
    choices: [],
    default: null,
  })
  public parent!: any;

  @DSDefaultField({
    required: false,
    name: "lft",
    helpText: "",
    verboseName: "Index left",
    choices: [],
    default: null,
  })
  public lft!: any;

  @DSDefaultField({
    required: false,
    name: "rght",
    helpText: "",
    verboseName: "Index right",
    choices: [],
    default: null,
  })
  public rght!: any;

  @DSDefaultField({
    required: false,
    name: "tree_id",
    helpText: "",
    verboseName: "ID Tree",
    choices: [],
    default: null,
  })
  public tree_id!: any;

  @DSDefaultField({
    required: false,
    name: "level",
    helpText: "",
    verboseName: "Niveau",
    choices: [],
    default: null,
  })
  public level!: any;

  @DSDefaultField({
    required: false,
    name: "parent_details",
    helpText: "Parents",
    verboseName: "Détails parent",
    choices: [],
    default: [],
  })
  public parent_details!: any;


  @DSDefaultField({
    required: false,
    name: "restos_count",
    verboseName: "Nb restaurants"
  })
  public restos_count!: number;

  @DSDefaultField({
    required: false,
    name: "locations_details",
    verboseName: "Détails localisations"
  })
  public locations_details: Location[] = [];
  public mainlocation_details!: { city: string; postcode: string; address: string; latitude: number; longitude: number };
}

@Injectable({
  providedIn: "root"
})
export class ClientService extends DSRestCollection<Client> {
  public adapter_config = {basePath: "/api/v2/structure/clients"};
  public model = Client;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
