import {DSRestCollection, DSRestQueryset, IDSModel} from "@solidev/ngdataservice";
import {Directive, Input} from "@angular/core";
import {Storage, StorageService} from "../../storage/storage.service";
import {Member, MemberService} from "../../member/member.service";
import {Resto} from "../../resto/resto.service";
import {Client} from "../../client/client.service";
import {BsacMessageService, BsacModelListProvider, IBsacApiConstants} from "@solidev/bsadmincomponents";
import {GeoRegion, GeoRegionService} from "../../../customdata/georegion/georegion.service";
import {GeoCommune, GeoCommuneService} from "../../../customdata/geocommune/geocommune.service";
import {GeoDepartement, GeoDepartementService} from "../../../customdata/geodepartement/geodepartement.service";
import {SEASONS} from "../../../product/common/seasons.enum";
import {firstValueFrom, Observable, Subject} from "rxjs";
import {SLabelService} from "../../slabel/slabel.service";
import {first, takeUntil} from "rxjs/operators";
import {filter, values} from "lodash-es";
import {BaseProducer, StorageDetails} from "../producer-base.model";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {BaseLabel, BaseLabelService} from "../../../product/_label/label-base.model";
import {RoutesService} from "../../../../comps/routes.service";
import {CustomDataKey, CustomDataKeyService} from "../../../customdata/customdatakey/customdatakey.service";
import {DistanceService, IDistanceCacheItem} from "../../location/distance.service";
import {ISelectedAddress} from "../../../product/_product/product-list-base/product-list-base.component";

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseProducerListComponent<P extends BaseProducer, PS extends DSRestCollection<P>,
  PR extends IDSModel, L extends BaseLabel, LS extends BaseLabelService<L>,
  PRF extends IDSModel, PRFS extends DSRestCollection<PRF>>
  extends ModelListBaseComponent<P, PS> {
  @Input() public zone = "fl";

  // Specific views
  @Input() public storage!: Storage;
  @Input() public member!: Member;
  @Input() public resto!: Resto;
  @Input() public client!: Client;

  // Storages custom selections & display
  public selectedStorages: number[] = [];
  public selectedAddress?: ISelectedAddress;
  public storagesDisplayMode: "full" | "sel" = "sel";
  public showStorageDistance = true;
  public resultStorages: StorageDetails[] = [];
  public resultsDistances: {
    [index: number]: {
      desc: string,
      via: string,
      ref: number,
      type: string,
      dist1: IDistanceCacheItem,
      dist2?: IDistanceCacheItem
    }[]
  };
  public mapStorages: StorageDetails[] = [];
  public displayStorages: Storage[] = [];
  public addStorageFilter: any = {};
  @Input() public storages_manage = false;

  public producers$!: DSRestQueryset<P>;
  public storages$!: DSRestQueryset<Storage>;
  public members$!: DSRestQueryset<Member>;
  public regions$!: DSRestQueryset<GeoRegion>;
  public communes$!: DSRestQueryset<GeoCommune>;
  public departements$!: DSRestQueryset<GeoDepartement>;
  public families1$!: DSRestQueryset<PRF>;
  public families2$!: DSRestQueryset<PRF>;
  public seasons = SEASONS;
  public labels!: IBsacApiConstants;
  public slabels!: IBsacApiConstants;
  public resultsMode: "list" | "map" | "families" = "list";
  public addstorage: { [index: number]: boolean } = [];
  public storageFilter: any = {};
  public cdatakeys!: CustomDataKey[];
  public flags!: Observable<any>;
  public mapRestos: Resto[] = [];

  // CUSTOM FILTERS CHOICES
  public PRODUCER_PRINTABLE_CHOICES = [
    {desc: "Tout état", value: "ALL"},
    {desc: "Affiche", value: "AFF"},
    {desc: "Galerie", value: "GAL"},
    {desc: "Vidéo", value: "VID"},
    {desc: "Affiche & Galerie", value: "GPR"},
  ];

  public MISSINGDOCS = [
    {desc: "Tous les producteurs", value: "all"},
    {desc: "Certificats manquants ou expirés", value: "miss"}];

  protected _subscriptions$ = new Subject<void>();

  constructor(_list: BsacModelListProvider,
              _items: PS,
              _msgs: BsacMessageService,
              routes: RoutesService,
              protected _storages: StorageService,
              protected _members: MemberService,
              protected _families: PRFS,
              protected _regions: GeoRegionService,
              protected _departements: GeoDepartementService,
              protected _labels: LS,
              protected _slabels: SLabelService,
              protected _communes: GeoCommuneService,
              protected _distances: DistanceService,
              protected _customdatakeys: CustomDataKeyService) {
    super(_list, _items, _msgs, routes);
    this.type = "producers";
    this.custom_fields = [
      {name: "producer_postcode", verbose_name: "CP producteur"},
      {name: "producer_city", verbose_name: "Ville producteur"},
      {name: "producer_dptcode", verbose_name: "N° dpt producteur"},
      {name: "producer_dptname", verbose_name: "Nom département producteur"},
      {name: "direct_distance", verbose_name: "Distances"},
      {name: "select", verbose_name: "Sélection"},
      {name: "actions", verbose_name: "Actions"}
    ];
  }

  public async preNgOnInit(): Promise<void> {
    // Get storages full display if storage management is enabled
    if (this.storages_manage || this.checkMode("member", "vivalya")) {
      this.filter.sstorages = 1;
    }
    Object.assign(this.storageFilter, this.filter);
    if (this.zone === "fl") {
      this.addStorageFilter.gtype = "FL";
    } else {
      this.addStorageFilter.gtype = "MR";
    }
    this.cdatakeys = await firstValueFrom(this._customdatakeys.getByDest(this.zone === "sea" ? "PMR" : "PFL", false));
    for (const ck of this.cdatakeys) {
      this.custom_fields.push({name: `customdata_${ck.id}`, verbose_name: ck.name});
    }

    // Refresh list results
    this.producers$ = this._items.queryset;
    this.members$ = this._members.queryset;
    this.storages$ = this._storages.queryset;
    this.families1$ = this._families.queryset;
    this.families2$ = this._families.queryset;
    this.regions$ = this._regions.queryset;
    this.communes$ = this._communes.queryset;
    this.departements$ = this._departements.queryset;
    this.labels = await firstValueFrom(this._labels.getCachedValues());
    this.flags = this._items.action(null, "get_flags", {method: "GET", body: {}});
  }

  public async postNgOnInit(): Promise<void> {


    this.list.results.pipe(
      takeUntil(this._subscriptions$)
    ).subscribe((results) => {
      // Get storages in result
      this.resultStorages = [];
      const std: {[index: number]: StorageDetails} = {};
      for (const r of this.results) {
        // TODO: check for member only storages if needed
        if (r.storages_details) {
          for (const s of r.storages_details) {
            std[s.id] = s;
          }
        }
      }
      this.resultStorages = values(std);
      this.updateMapStorages();
      this.updateDistances();
    });

    // Adapt result based on filters
    this.list.filter.output$.pipe(takeUntil(this._subscriptions$)).subscribe((filters) => {
      // Get current selected storages
      this.displayStorages = [];
      if (filters.storages) {
        this.selectedStorages = this.list.filter.get("storages").value as any;
      } else {
        this.selectedStorages = [];
      }
      this.updateMapStorages();
      // add reference address
      if (filters.producernear) {
        const f = this.list.filter.get("producernear");
        this.selectedAddress = {
          name: (f.desc as any).location,
          lng: +(f.value as string).split("|")[2],
          lat: +(f.value as string).split("|")[3]
        };
      } else if (this.resto) {
        this.selectedAddress = {
          name: this.resto.name,
          lat: this.resto.mainlocation_details.latitude,
          lng: this.resto.mainlocation_details.longitude
        };
        this.mapRestos = [this.resto];
      } else {
        this.selectedAddress = undefined;
      }
      this.updateDistances();
    });


    // TODO: add slabels constants$ w. prefetch cache
    this._slabels.constants$.pipe(first())
      .subscribe((cstt) => {
        this.slabels = cstt;
      });
  }

  // -----------------------------------------------------------------------------------------------------------
  // DISPLAY OPTIONS
  public displayStorage(id: number): boolean {
    if (this.storagesDisplayMode === "sel") {
      if (this.selectedStorages.length === 0) {
        return true;
      }
      return this.selectedStorages.indexOf(id) !== -1;
    }
    return true;
  }

  public updateMapStorages(): void {
    if (this.selectedStorages.length === 0) {
      this.mapStorages = this.resultStorages;
    } else {
      this.mapStorages = filter(this.resultStorages, (r) => {
        return this.selectedStorages.indexOf(r.id) !== -1;
      });
    }
  }

  public updateDistances(): void {
    this.resultsDistances = {};
    if (!this.results) {
      return;
    }
    for (const r of this.results) {
      if (r.mainlocation_details) {
        // Computed distances management
        if (this.selectedAddress) {
          // We have a reference address
          const d = this._distances.addDistance(this.selectedAddress.lat, this.selectedAddress.lng,
            r.mainlocation_details.latitude, r.mainlocation_details.longitude);
          this.resultsDistances[r.id] = [{desc: "Producteur", via: "", ref: 0, type: "PA", dist1: d}];
          if (this.storage) {
            // Si un dépôt est actuellement sélectionné, ajoute la distance du producteur à ce dépôt
            const dr = this._distances.addDistance(this.selectedAddress.lat, this.selectedAddress.lng,
              this.storage.mainlocation_details.latitude, this.storage.mainlocation_details.longitude);
            const dps = this._distances.addDistance(r.mainlocation_details.latitude, r.mainlocation_details.longitude,
              this.storage.mainlocation_details.latitude, this.storage.mainlocation_details.longitude);
            this.resultsDistances[r.id].push({desc: this.storage.name, via: "", ref: this.storage.id, type: "SA", dist1: dr});
            this.resultsDistances[r.id].push({
              desc: "Producteur", via: this.storage.name,
              ref: this.storage.id, type: "PSA", dist1: dps, dist2: dr
            });
          } else if (r.storages_details) {
            // Si pas de dépôt, on affiche les distances pour chacun des dépôts contenus dans "storages_details" du résultat
            for (const s of r.storages_details) {
              const dr = this._distances.addDistance(this.selectedAddress.lat, this.selectedAddress.lng,
                s.mainlocation_details.latitude, s.mainlocation_details.longitude);
              const dps = this._distances.addDistance(r.mainlocation_details.latitude, r.mainlocation_details.longitude,
                s.mainlocation_details.latitude, s.mainlocation_details.longitude);
              this.resultsDistances[r.id].push({desc: s.name, via: "", ref: s.id, type: "SA", dist1: dr});
              this.resultsDistances[r.id].push({desc: "Producteur", via: s.name, ref: s.id, type: "PSA", dist1: dps, dist2: dr});
            }
          }


        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------------
  // INLINE EDITION


  /**
   * Add storage to producer.
   * @param storage Storage object to be added
   * @param producer Producer object
   */
  public async addStorage(storage: Storage, producer: PR): Promise<any> {
    try {
      await producer.action("add_storage", {method: "POST", body: {storage: storage.id}}).toPromise();
      await producer.refresh().toPromise();
      this._msgs.success("Dépôt ajouté avec succès");
    } catch (e) {
      this._msgs.error("Echec de l'ajout du dépôt", `${e}`);
    }
    this.addstorage[producer.id as number] = false;
  }

  /**
   * Remove a storage from producer.
   * @param producer Producer object
   * @param storageId Storage id to remove.
   */
  public async removeStorage(producer: PR, storageId: number): Promise<any> {
    try {
      await firstValueFrom(producer.action("remove_storage", {method: "POST", body: {storage: storageId}}));
      await firstValueFrom(producer.refresh());
      this._msgs.success("Dépôt supprimé avec succès");
    } catch (e) {
      this._msgs.error("Echec de la suppression du dépôt", `${e}`);
    }
  }

  /**
   * Update "droit à l'image" inline.
   * Click circle between DI statuses.
   * @param producer
   */
  public updateDI(producer: PR): void {
    const cycle = ["", "OK", "NO", "NR"];
    let cur = cycle.indexOf((producer as any).pubimage);
    if (cur < 0) {
      cur = 0;
    }
    let next = cur + 1;
    if (next > cycle.length - 1) {
      next = 0;
    }
    (producer as any).pubimage = cycle[next];
    producer.update(["pubimage"]).subscribe(() => {
        this._msgs.success("Droit à l'image modifié avec succès");
      },
      (error) => {
        this._msgs.error("Modif. droit image failed", "API Return : " + error.toString(), error._body);
      });
  }


  public onAction(action: any): void {
    action.storage = this.storage;
    action.selectedStorages = this.selectedStorages;
    action.resultStorages = this.resultStorages;
    this.action.emit(action);
  }


}
