import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

export interface ILvadgIOParams {
  url: string;
  action: "redirect" | "proxy" | "mask";
  ttl?: number;
  title?: string;
  description?: string;
  key: string;
}

export interface ILvadgIOQrParams {
  code: string;
  size: "xs" | "sm" | "md" | "lg" | "xl";
  image: "vivalya" | "vivalyasea" | "none";
  color?: "";
}

export interface IShortenerResponse {
  code: string;
  url: string;
}

export const LVADGIO_QR_ALLOWED_SIZES = ["xs", "sm", "md", "lg", "xl"];
export const LVADGIO_QR_DEFAULT_SIZE = "md";
export const LVADGIO_QR_ALLOWED_IMAGES = ["vivalya", "vivalyasea", "none"];
export const LVADGIO_QR_DEFAULT_IMAGE = "vivalya";

export const LVADGIO_API_URL = new InjectionToken("lvadg.lvadgioapi.url");

@Injectable({
  providedIn: "root"
})
export class LvadgioService {

  public results: { params: ILvadgIOParams, res: IShortenerResponse, qr: string }[] = [];

  constructor(@Inject(LVADGIO_API_URL) public apiUrl: string,
              private _http: HttpClient) {
  }

  public shorten(params: ILvadgIOParams): Observable<IShortenerResponse> {
    return this._http.post<IShortenerResponse>(`${this.apiUrl}/_/shorten`, params)
      .pipe(tap((res) => {
        this.results.push({params, res, qr: this.qr({code: res.code, image: "none", size: "xs"})});
      }));
  }

  public qr(params: ILvadgIOQrParams): string {
    return `${this.apiUrl}/_/${params.code}/qr?size=${params.size}&image=${params.image}&color=${params.color}`;
  }

}
