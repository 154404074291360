import {Component, Input, OnInit} from "@angular/core";
import {Tarif, TarifService} from "../../tarif/tarif.service";
import {TarifTypeRelation, TarifTypeRelationService} from "../../tariftyperelation/tariftyperelation.service";
import {TarifRelation, TarifRelationService} from "../tarifrelation.service";
import {firstValueFrom} from "rxjs";
import {RouteV3} from "../../../../comps/routes.service";
import {TarifType} from "../../tariftype/tariftype.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: "lvadg-tarifrelation-listmanage",
  templateUrl: "./tarifrelation-listmanage.component.pug",
  styleUrls: ["./tarifrelation-listmanage.component.sass"]
})
export class TarifrelationListmanageComponent implements OnInit {
  @Input() public dest!: Tarif;
  @Input() public src!: Tarif;
  @Input() public tariftype_detail_route?: RouteV3<TarifType>;
  @Input() public tarif_detail_route?: RouteV3<any>;

  public trelations: TarifTypeRelation[] = [];
  public relations: { [index: number]: TarifRelation } = {};
  public relationManager: {
    [index: number]: {
      form: FormGroup,
      candidates: Tarif[]
    }
  } = {};
  public running = {dispo: false, ref: false, clone: false};

  constructor(private _ttr: TarifTypeRelationService,
              private _tr: TarifRelationService,
              public tar$: TarifService,
              private _msgs: BsacMessageService) {
  }

  public async ngOnInit(): Promise<void> {
    this.relations = {};
    this.trelations = [];
    this.relationManager = {};
    if (this.dest) {
      const relations = (await firstValueFrom(this._tr.queryset.query({dest: this.dest.id}).get())).items;
      for (const r of relations) {
        this.relations[r.relation] = r;
      }
      this.trelations = (await firstValueFrom(this._ttr.queryset.query({dest: this.dest.type}).get())).items;
    } else if (this.src) {
      const relations = (await firstValueFrom(this._tr.queryset.query({src: this.src.id}).get())).items;
      for (const r of relations) {
        this.relations[r.relation] = r;
      }
      this.trelations = (await firstValueFrom(this._ttr.queryset.query({src: this.src.type}).get())).items;
    }
  }

  public async toggleEditor(r: TarifTypeRelation) {
    if (this.relationManager[r.id]) {
      await this.ngOnInit();
      return;
    }
    let candidates: Tarif[] = [];
    if (this.dest && this.canEdit(r.dest_details) && this.canEdit(this.dest)) {
      candidates = (await firstValueFrom(this.tar$.queryset
        .query({type: r.src})
        .paginate({page_size: 5, page: 1})
        .sort([{field: "dateend", direction: "desc"}])
        .get())).items;
      this.relationManager[r.id] = {
        form: new FormGroup<any>({
          candidate: new FormControl(this.relations[r.id]?.src || null)
        }),
        candidates: candidates
      };
    } else if (this.src && this.canEdit(r.src_details) && this.canEdit(this.src)) {
      candidates = (await firstValueFrom(this.tar$.queryset
        .query({type: r.dest})
        .paginate({page_size: 5, page: 1})
        .sort([{field: "dateend", direction: "desc"}])
        .get())).items;
      this.relationManager[r.id] = {
        form: new FormGroup<any>({
          candidate: new FormControl(this.relations[r.id]?.dest || null)
        }),
        candidates: candidates
      };
    }
  }


  public async setSource(r: TarifTypeRelation, ev: Event) {
    await firstValueFrom(r.action("set_relation", {
      method: "POST",
      body: {
        dest: this.dest.id,
        src: (ev.target as any).value
      }
    }));
    await this.ngOnInit();
  }

  public async setDest(r: TarifTypeRelation, ev: Event) {
    await firstValueFrom(r.action("set_relation", {
      method: "POST",
      body: {
        src: this.src.id,
        dest: (ev.target as any).value
      }
    }));
    await this.ngOnInit();
  }

  public canEdit(tar?: Tarif|TarifType) {
    return tar && tar.user_level && ["admin", "sadmin", "write"].indexOf(tar.user_level) !== -1;
  }

  public async importDispo(r: TarifTypeRelation): Promise<void> {
    this.running.dispo = true;
    try {
      const rel = this.relations[r.id];
      await firstValueFrom(this.dest.action("relation_action", {
        method: "POST",
        body: {
          relation: rel.id,
          type: "import_dispo"
        }
      }));
      this._msgs.success("Disponibilité importée avec succès");
    } catch (e) {
      this._msgs.error("Erreur lors de l'importation des disponibilités", `${e}`);
    }
    this.running.dispo = false;
  }

  public async importRef(r: TarifTypeRelation): Promise<void> {
    this.running.ref = true;
    try {
      const rel = this.relations[r.id];
      const res = await firstValueFrom(this.dest.action("relation_action", {
        method: "POST",
        body: {
          relation: rel.id,
          type: "import_ref"
        }
      }));
      this._msgs.success(`Prix importés avec succès : ${res.updated} maj`);
    } catch (e) {
      this._msgs.error("Erreur lors de l'importation des prix", `${e}`);
    }
    this.running.ref = false;
  }

  public async importClone(r: TarifTypeRelation): Promise<void> {
    this.running.clone = true;
    try {
      const rel = this.relations[r.id];
      await firstValueFrom(this.dest.action("relation_action", {
        method: "POST",
        body: {
          relation: rel.id,
          type: "import_clone"
        }
      }));
      this._msgs.success("Clonage effectué avec succès");
    } catch (e) {
      this._msgs.error("Erreur lors du clonage", `${e}`);
    }
    this.running.clone = false;
  }

}
