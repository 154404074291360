import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SelectionSelitemComponent} from "./selection-selitem/selection-selitem.component";
import {SelectionSummaryComponent} from "./selection-summary/selection-summary.component";

@NgModule({
  imports: [
    CommonModule,
    NgbModule
  ],
  declarations: [SelectionSelitemComponent, SelectionSummaryComponent],
  exports: [SelectionSelitemComponent, SelectionSummaryComponent]
})
export class SelectionModule {
}
