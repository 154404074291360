
<form *ngIf="provider" [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <bsac-field-control field="name" [model]="provider" [form]="form"><span class="label">Nom de la société</span></bsac-field-control>
    </div>
    <div class="col-sm-6">
      <bsac-field-control field="type" [model]="provider" [form]="form"><span class="label">Type de société</span></bsac-field-control>
    </div>
    <div class="col-sm-6">
      <bsac-field-control field="siret" [model]="provider" [form]="form"><span class="label">SIRET</span></bsac-field-control>
    </div>
    <div class="col-sm-6">
      <bsac-field-control field="legal" [model]="provider" [form]="form"><span class="label">Structure légale</span></bsac-field-control>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <button class="btn btn-primary btn-block w-100" (click)="create()">Créer</button>
    </div>
    <div class="col-4">
      <button class="btn btn-secondary btn-block w-100" (click)="cancel()">Annuler</button>
    </div>
  </div>
</form>