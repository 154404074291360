import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {SeaProductPrice, SeaProductPriceService} from "../../../product/seaproductprice/seaproductprice.service";
import {SeaProductVariant, SeaProductVariantService} from "../../../product/seaproductvariant/seaproductvariant.service";
import {OfferSeaProduct, OfferSeaProductService} from "../../offerseaproduct/offerseaproduct.service";
import {SeaProduct, SeaProductService} from "../../../product/seaproduct/seaproduct.service";
import {SeaProducer, SeaProducerService} from "../../../structure/seaproducer/seaproducer.service";
import {OfferManageProductBaseComponent} from "../../_base/offer-manage-product/offer-manage-product.component";

@Component({
  selector: "lvadg-offer-manage-seaproduct",
  templateUrl: "./offer-manage-seaproduct.component.pug",
  styleUrls: ["./offer-manage-seaproduct.component.sass"]
})
export class OfferManageSeaProductComponent
  extends OfferManageProductBaseComponent<SeaProduct, SeaProducer, OfferSeaProduct,
    SeaProductVariant, SeaProductPrice> {
  constructor(_producers: SeaProducerService,
              _products: SeaProductService,
              _offerproducts: OfferSeaProductService,
              _variants: SeaProductVariantService,
              _prices: SeaProductPriceService,
              _fb: UntypedFormBuilder) {
    super(_producers, _products, _offerproducts, _variants, _prices, _fb);
  }

}
