import {Component} from "@angular/core";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {ReplaySubject, Subject} from "rxjs";
import {ArticleFamily} from "../../../../lib/models/catalog/family/article-family.service";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {NavDriver, TabMemoryService} from "../../../../lib/comps/tab-memory.service";
import {CommonBaseComponent} from "../../common-base.component";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {VIEW_DISPLAY} from "../../../view-constants";
import {Provider} from "../../../../lib/models/structure/provider/provider.service";

export interface CatalogArticleFamilyManageViewV3Params extends BaseRouteV3Params {
  article_detail_route: RouteConfigItemV3;
  articlefamily_detail_route: RouteConfigItemV3;
  provider_detail_route: RouteConfigItemV3;
  display: VIEW_DISPLAY;
}

export interface CatalogArticleFamilyManageViewV3Data extends CatalogArticleFamilyManageViewV3Params {
  articleFamily: ArticleFamily;
}

@Component({
  selector: "lvadg-catalog-family-manage-view",
  templateUrl: "./catalog-family-manage-view.component.pug",
  styleUrls: ["./catalog-family-manage-view.component.sass"]
})
export class CatalogArticleFamilyManageViewV3Component extends CommonBaseComponent {

  public articleFamily!: ArticleFamily;
  public remove = false;
  public now = Date.now();
  public reload = new Subject<void>();
  public navId!: NavDriver;
  public darticlesFilter = new ReplaySubject<{ [index: string]: (string | number) }>(1);
  public sarticlesFilter = new ReplaySubject<{ [index: string]: (string | number) }>(1);
  public article_detail_route!: RouteV3<Article>;
  public articlefamily_detail_route!: RouteV3<ArticleFamily>;
  public provider_detail_route!: RouteV3<Provider>;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute,
              private tms: TabMemoryService) {
    super(routes, router, route);
  }

  // Can delete if terminal node & contains nothing
  public get canDelete(): boolean {
    return (this.articleFamily.pcount === 0) && (this.articleFamily.rght === this.articleFamily.lft + 1);
  }

  public async preNgOnInit(): Promise<void> {
    const cdata = this.route.snapshot.data as CatalogArticleFamilyManageViewV3Data;
    this.article_detail_route = new RouteV3<Article>(cdata.article_detail_route, this);
    this.articlefamily_detail_route = new RouteV3<ArticleFamily>(cdata.articlefamily_detail_route, this);
    this.provider_detail_route = new RouteV3<Provider>(cdata.provider_detail_route, this);
    this.route.data.subscribe((d: Data) => {
      const data = d as CatalogArticleFamilyManageViewV3Data;
      this.articleFamily = data.articleFamily;
      this.article_detail_route.context(this);
      this.darticlesFilter.next({fields: "id,family,libelle,name,categorie,calibre", exactfamily: this.articleFamily.id});
      this.sarticlesFilter.next({fields: "id,family,libelle,name,categorie,calibre", infamily: this.articleFamily.id});
      this.navId = this.tms.memFor(["catalog", "familymanage"], "manage", false);
    });
    this.reload.next();
  }

  public removed(): void {
    this.router.navigate([".."]);
  }
}
