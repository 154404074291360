<!DOCTYPE html>
<ng-template #editor let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Propriétés de l'image</h4>
    <button class="btn-close" type="button" aria-label="Close" (click)="modal.dismiss('x')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <button class="btn btn-outline-danger float-end" (dblclick)="deleteImage(modal.dismiss)"><i class="fa fa-trash me-2"></i>Supprimer</button>
        <h3>Propriétés</h3>
        <dl class="row" *ngIf="curimage">
          <div class="col-sm-6">
            <bsac-dd-field [model]="curimage" field="id" type="auto" [editable]="false">ID</bsac-dd-field>
          </div>
          <div class="col-sm-6">
            <bsac-dd-field [model]="curimage" field="status" type="auto">Etat</bsac-dd-field>
          </div>
          <div class="col-sm-6">
            <bsac-dd-field [model]="curimage" field="width" type="auto" [editable]="false">Largeur</bsac-dd-field>
          </div>
          <div class="col-sm-6">
            <bsac-dd-field [model]="curimage" field="height" type="auto" [editable]="false">Hauteur</bsac-dd-field>
          </div>
          <div class="col-sm-8">
            <bsac-dd-field [model]="curimage" field="title" type="auto">Titre</bsac-dd-field>
          </div>
          <div class="col-sm-4">
            <bsac-dd-field [model]="curimage" field="order" type="auto">Priorité affichage</bsac-dd-field>
          </div>
          <div class="col-sm-12">
            <bsac-dd-field [model]="curimage" field="video" type="auto">URL Vidéo (vimeo, yt)</bsac-dd-field>
          </div>
          <div class="col-sm-12">
            <bsac-dd-field [model]="curimage" field="description" type="textarea">Texte de description</bsac-dd-field>
          </div>
          <div class="col-sm-12">
            <dt>Caractéristiques de l'image</dt>
            <dd>
              <ng-container *ngFor="let f of FLAGS">
                <ng-container *ngIf="!haveFlag(curimage, f.value)">
                  <div class="badge bg-secondary mx-1 pointer" (click)="toggleFlag(curimage, f.value)">{{f.desc}}</div>
                </ng-container>
                <ng-container *ngIf="haveFlag(curimage, f.value)">
                  <div class="badge bg-success mx-1 pointer" (click)="toggleFlag(curimage, f.value)">{{f.desc}}</div>
                </ng-container>
              </ng-container>
            </dd>
          </div>
        </dl>
      </div>
      <div class="col">
        <h3>Prévisualisation</h3>
        <p class="text-info">Cliquez sur l'image pour l'ouvrir dans un nouvel onglet</p>
        <div><strong class="me-2 pointer" *ngIf="curdisp=='orig'" (click)="curdisp='orig'">[orig]</strong><span class="me-2 pointer" *ngIf="curdisp!=='orig'" (click)="curdisp='orig'">[orig]</span>
          <ng-container *ngFor="let im of curimage.responsive"><strong class="me-2 pointer" *ngIf="curdisp==im.name" (click)="curdisp=im.name">[{{im.name}}]</strong><span class="me-2 pointer" *ngIf="curdisp!==im.name" (click)="curdisp=im.name">[{{im.name}}]</span></ng-container>
        </div><a [href]="getImageLink()" target="_blank">
          <lvadg-rimg [src]="curimage" display="img-fluid mr-auto ml-auto img-thumbnail d-block " [size]="curdisp"></lvadg-rimg></a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="modal.dismiss()">Fermer</button>
  </div>
</ng-template>
<div class="row">
  <div class="col" *ngIf="logofield">
    <div class="row">
      <div class="col-sm-12">
        <h3>{{logodesc}}</h3>
        <lvadg-upload-image class="h100" [url]="getLogoUrl()" [title]="model.name" (uploaded)="uploadedLogo($event)" [data]="{field: logofield}"></lvadg-upload-image>
      </div>
      <div class="col-sm-12">
        <div *ngIf="model[logofield+'_img']" (click)="setImageProperties(model[logofield+'_img'], editor, 'logo')">
          <lvadg-rimg class="me-auto" [src]="model[logofield+'_img']" size="sm" display="gallery mr-auto ml-auto d-block img-thumbnail"></lvadg-rimg>
          <div class="text-center"> {{model[logofield+'_img'].title}} ({{model[logofield+'_img'].width}}x{{model[logofield+'_img'].height}})<br><span class="badge bg-primary me-2" *ngFor="let f of model[logofield+'_img'].flags">{{FLAGSD[f]}}</span></div>
        </div>
        <div *ngIf="!model[logofield+'_img']">
          <p class="text-warning">No image</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col" *ngIf="imagesfield">
    <div class="row">
      <div class="col-sm-12">
        <h3>{{imagesdesc}}</h3>
        <lvadg-upload-image class="h100" [url]="getImagesAddUrl()" [title]="model.name" (uploaded)="uploadedImage($event)" [data]="{field: imagesfield}"></lvadg-upload-image>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4" *ngFor="let img of model[imagesfield+'_details']" (click)="setImageProperties(img, editor, 'image')">
        <lvadg-rimg [src]="img" display="gallery mr-auto ml-auto img-thumbnail d-block " size="sm">
          <div class="text-center">{{img.title}} ({{img.width}}x{{img.height}})<br><span class="badge bg-primary me-2" *ngFor="let f of img.flags">{{FLAGSD[f]}}</span></div>
        </lvadg-rimg>
      </div>
    </div>
  </div>
</div>