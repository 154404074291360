import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleRegion, ArticleRegionService} from "../article-region.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";
import {GeoRegionService} from "../../../customdata/georegion/georegion.service";
import {GeoCommuneService} from "../../../customdata/geocommune/geocommune.service";
import {GeoDepartementService} from "../../../customdata/geodepartement/geodepartement.service";

@Component({
  selector: "lvadg-article-regions-manage",
  templateUrl: "./article-regions-manage.component.pug",
  styleUrls: ["./article-regions-manage.component.sass"]
})
export class ArticleRegionsManageComponent extends ArticleDataManageBaseComponent<ArticleRegion, ArticleRegionService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleRegionService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute,
              public georegions$: GeoRegionService,
              public geocommunes$: GeoCommuneService,
              public geodepartements$: GeoDepartementService) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "regionId";
    this.filterField = "region";
  }
}
