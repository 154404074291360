import {CommonModule} from "@angular/common";
import {CompsModule} from "../../lib/comps/comps.module";
import {RouterModule} from "@angular/router";
import {BsacApiConstantsModule, BsacModelListModule, BsacUtilsModule} from "@solidev/bsadmincomponents";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

export const VIEWS_COMMON_COMMON_MODULES = [
  CommonModule,
  CompsModule,
  RouterModule,
  BsacModelListModule,
  BsacUtilsModule,
  BsacApiConstantsModule,
  NgbModule,
];
