import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Article} from "../../catalog/article/article.service";
import {ArticleFamily} from "../../catalog/family/article-family.service";
import {Producer} from "../../structure/producer/producer.service";
import {ExternalArticle} from "../../catalog/externalarticle/externalarticle.service";
import {VivalyaBill} from "../bill/bill.service";

export interface IVivalyaArticle {
  name: string;
}

export class VivalyaBillItem extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;
  @DSForeignKeyField({
    name: "bill",
    verboseName: "Facture",
    required: false,
    helpText: "Facture",
    relatedModel: "sales.VivalyaBill",
    remoteField: "id",
    relatedName: "lines"
  })
  public bill!: number;
  public bill_details!: VivalyaBill;
  @DSDefaultField({
    name: "bill__storage",
    verboseName: "Dépôt",
    helpText: "Dépôt",
  })
  public get bill__storage(): string {
    return this.bill_details?.storage_details?.name;
  }
  @DSDefaultField({
    name: "bill__billdate",
    verboseName: "Date facturation",
    helpText: "Date facturation",
  })
  public get bill__billdate(): Date {
    return this.bill_details?.billdate;
  }
  @DSDefaultField({
    name: "bill__client ",
    verboseName: "Client  ",
    helpText: "Client ",
  })
  public get bill__client(): string {
    return this.bill_details?.client_details?.name;
  }
  @DSDefaultField({
    name: "bill__resto",
    verboseName: "Exploitation",
    helpText: "Exploitation",
  })
  public get bill__resto(): string {
    return this.bill_details?.resto_details?.name;
  }
  @DSForeignKeyField({
    name: "article",
    verboseName: "Article catalogue",
    required: false,
    helpText: "Article catalogue",
    relatedModel: "catalog.Article",
    remoteField: "id",
    relatedName: "billlines"
  })
  public article!: number;
  public article_details!: Article;
  @DSForeignKeyField({
    name: "gc_article",
    verboseName: "Article GC",
    required: false,
    helpText: "Article GC",
    relatedModel: "catalog.ExternalArticle",
    remoteField: "id",
    relatedName: "bill_lines_gc"
  })
  public gc_article!: number;
  public gc_article_details!: ExternalArticle;
  @DSForeignKeyField({
    name: "adh_article",
    verboseName: "Article adhérent",
    required: false,
    helpText: "Article adhérent",
    relatedModel: "catalog.ExternalArticle",
    remoteField: "id",
    relatedName: "bill_lines_adh"
  })
  public adh_article!: number;
  public adh_article_details!: ExternalArticle;
  @DSCharField({
    name: "adh_code",
    verboseName: "Code adhérent",
    required: false,
    helpText: "Code adhérent",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public adh_code!: string;
  @DSCharField({
    name: "adh_libelle",
    verboseName: "Libellé adhérent",
    required: false,
    helpText: "Libellé adhérent",
    minLength: 0,
    maxLength: 250,
    choices: []
  })
  public adh_libelle!: string;
  @DSCharField({
    name: "gc_code",
    verboseName: "Code GC",
    required: false,
    helpText: "Code GC",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public gc_code!: string;
  @DSCharField({
    name: "gc_libelle",
    verboseName: "Libellé GC",
    required: false,
    helpText: "Libellé GC",
    minLength: 0,
    maxLength: 250,
    choices: []
  })
  public gc_libelle!: string;





  @DSDecimalField({
    name: "weight",
    verboseName: "Poids",
    required: false,
    helpText: "",
    default: 0,
    factor: 10000,
    choices: []
  })
  public weight!: number;
  @DSDecimalField({
    name: "quantity",
    verboseName: "Quantité",
    required: false,
    helpText: "Quantité",
    default: 0,
    factor: 10000,
    choices: []
  })
  public quantity!: number;
  @DSDecimalField({
    name: "pieces",
    verboseName: "Pièces",
    required: false,
    helpText: "Pièces",
    default: 0,
    factor: 10000,
    choices: []
  })
  public pieces!: number;
  @DSDecimalField({
    name: "packages",
    verboseName: "Packages",
    required: false,
    helpText: "Packages",
    default: 0,
    factor: 10000,
    choices: []
  })
  public packages!: number;
  @DSCharField({
    name: "uv",
    verboseName: "UV",
    required: false,
    helpText: "UV",
    minLength: 0,
    maxLength: 10,
    choices: []
  })
  public uv!: string;
  @DSDecimalField({
    name: "brut_amount",
    verboseName: "Montant brut",
    required: false,
    helpText: "Montant brut",
    default: 0,
    factor: 10000,
    choices: []
  })
  public brut_amount!: number;
  @DSDecimalField({
    name: "net_amount",
    verboseName: "Montant net",
    required: false,
    helpText: "Montant net",
    default: 0,
    factor: 10000,
    choices: []
  })
  public net_amount!: number;
  @DSDecimalField({
    name: "vat_amount",
    verboseName: "Montant taxes",
    required: false,
    helpText: "Montant taxes",
    default: 0,
    factor: 10000,
    choices: []
  })
  public vat_amount!: number;
  @DSDecimalField({
    name: "wvat_amount",
    verboseName: "Montant TTC",
    required: false,
    helpText: "Montant TTC",
    default: 0,
    factor: 10000,
    choices: []
  })
  public wvat_amount!: number;
  @DSForeignKeyField({
    name: "family",
    verboseName: "Famille catalogue",
    required: false,
    helpText: "Famille catalogue",
    relatedModel: "catalog.ArticleFamily",
    remoteField: "id",
    relatedName: "billlines"
  })
  public family!: number;
  public family_details!: ArticleFamily;
  @DSForeignKeyField({
    name: "producer",
    verboseName: "Producteur",
    required: false,
    helpText: "Producteur",
    relatedModel: "structure.Producer",
    remoteField: "id",
    relatedName: "billlines"
  })
  public producer!: number;
  public producer_details!: Producer;
  @DSCharField({
    name: "vvfamily",
    verboseName: "Famille (détail)",
    required: false,
    helpText: "Famille (détail)",
    minLength: 0,
    maxLength: 500,
    choices: []
  })
  public vvfamily!: string;


  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;
  @DSTextField({
    name: "comments",
    verboseName: "Commentaires (interne)",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 10000
  })
  public comments!: string;
  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;
  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;
  // Flags mixin
  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags!: any;
  // Internal
  @DSCharField({
    name: "hash",
    verboseName: "Hash line",
    required: false,
    helpText: "Hash",
    minLength: 0,
    maxLength: 32,
    choices: []
  })
  public hash!: string;


}

@Injectable({
  providedIn: "root"
})
export class VivalyaBillItemService extends DSRestCollection<VivalyaBillItem> {
  public adapter_config = {basePath: "/api/v2/vivalyabillitems"};
  public model = VivalyaBillItem;


  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }


}
