import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ModelPrint} from "../../modelprint.service";
import {PrinterJob, PrinterJobService} from "../../printjob.service";
import {BsacLiveService} from "@solidev/bsadmincomponents";

@Component({
  selector: "lvadg-modelprint-print",
  templateUrl: "./modelprint-print.component.pug",
  styleUrls: ["./modelprint-print.component.sass"]
})
export class ModelprintPrintComponent implements OnInit, OnDestroy {
  public job!: PrinterJob;
  public printing = false;
  public check!: any;

  @Input() public print!: ModelPrint;

  constructor(private _printerjobs: PrinterJobService,
              private _live: BsacLiveService) {
  }

  ngOnInit() {
  }

  public async startPrint(): Promise<void> {
    this.job = undefined;
    this.printing = true;
    const result = await this.print.action("print", {method: "POST", body: {}}).toPromise();
    this.job = await this._printerjobs.get(result.id, {query: {_cachebust: (Math.random() + 100000).toString()}}).toPromise();
    console.log("Subscribing to lvadg." + this.job.channel);
    this.check = this._live.subscribe("lvadg." + this.job.channel).subscribe((message) => {
      console.log("Live message received", message);
      this.job = message.data;
      if (this.job.status === "done") {
        this.printing = false;
        this.stopPrint();
      }
    });
  }

  public async stopPrint(): Promise<void> {
    this.printing = false;
    if (this.check) {
      this._live.unsubscribe("lvadg." + this.job.channel);
    }
  }

  public async ngOnDestroy(): Promise<void> {
    await this.stopPrint();
  }
}
