import {Component, Input, OnInit} from "@angular/core";
import {TarifType} from "../tariftype.service";
import {ClientService} from "../../../structure/client/client.service";
import {MemberService} from "../../../structure/member/member.service";
import {StorageService} from "../../../structure/storage/storage.service";
import {FormControl, UntypedFormGroup} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {Group, GroupService} from "../../../people/group/group.service";
import {PersonService} from "../../../people/person/person.service";
import {Contact, ContactService} from "../../../people/contact/contact.service";
import {AuthService} from "../../../user/auth.service";
import {RouteV3} from "../../../../comps/routes.service";

@Component({
  selector: "lvadg-tariftype-manage",
  templateUrl: "./tariftype-manage.component.pug",
  styleUrls: ["./tariftype-manage.component.sass"]
})
export class TariftypeManageComponent implements OnInit {
  @Input() public tarifType!: TarifType;
  @Input() public tariftype_detail_route?: RouteV3<TarifType>;
  public editorForm!: UntypedFormGroup;
  public addContact = false;
  public adminEditable = false;
  public writerEditable = false;
  public quillModules: any = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote", {"script": "sub"}, {"script": "super"}],
      [{"list": "ordered"}, {"list": "bullet"}],
      [{"color": []}, {"background": []}, "clean", "link"]
    ]
  };

  constructor(public groups$: GroupService,
              public clients$: ClientService,
              public members$: MemberService,
              public persons$: PersonService,
              public storages$: StorageService,
              private _contacts: ContactService,
              public auth: AuthService) {
  }


  public ngOnInit(): void {
    this.adminEditable = ["sadmin", "admin"].indexOf(this.tarifType.user_level) >= 0;
    this.writerEditable = ["sadmin", "admin", "write"].indexOf(this.tarifType.user_level) >= 0;
    this.editorForm = new UntypedFormGroup({
      text: new FormControl(this.tarifType.message),
      contract: new FormControl(this.tarifType.contract_message)
    });
  }

  public async saveText(): Promise<void> {
    this.tarifType.message = this.editorForm.value.text;
    await firstValueFrom(this.tarifType.update(["message"]));
  }

  public async saveContractText(): Promise<void> {
    this.tarifType.contract_message = this.editorForm.value.contract;
    await firstValueFrom(this.tarifType.update(["contract_message"]));
  }



  public async onRRuleSave($event: string): Promise<void> {
    this.tarifType.rrule = $event;
    await firstValueFrom(this.tarifType.update(["rrule"]));
  }

  public async contactAdded(): Promise<void> {
    await firstValueFrom(this.tarifType.refresh());
    this.addContact = false;
  }

  public async removeContact(c: Contact) {
    const ct = await firstValueFrom(this._contacts.get(c.id));
    await firstValueFrom(ct.delete());
    await firstValueFrom(this.tarifType.refresh());
  }

  public groupDesc(g: Group): string {
    console.log(g)
    return `[${g.slug}] ${g.description}`
  }
}
