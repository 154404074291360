import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import {CdkTableModule} from "@angular/cdk/table";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {CompsModule} from "../../../comps/comps.module";
import {ClientSelectModule} from "../../structure/client/client-select.module";

import {SeaProductVariantListComponent} from "./seaproductvariant-list/seaproductvariant-list.component";

import {SeaProductVariantCreateComponent} from "./seaproductvariant-create/seaproductvariant-create.component";

import {SeaProductVariantManageComponent} from "./seaproductvariant-manage/seaproductvariant-manage.component";

import {SeaProductVariantDeleteComponent} from "./seaproductvariant-delete/seaproductvariant-delete.component";

import {SeaProductVariantSelectModule} from "./seaproductvariant-select.module";
import {SeaProductVariantImportComponent} from "./seaproductvariant-import/seaproductvariant-import.component";

@NgModule({
  imports: [
    CommonModule,
    SeaProductVariantSelectModule,
    CdkTableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    
    CompsModule,
    RouterModule,
    ClientSelectModule
  ],
  declarations: [
    SeaProductVariantListComponent,
    SeaProductVariantCreateComponent,
    SeaProductVariantManageComponent,
    SeaProductVariantDeleteComponent,
    SeaProductVariantImportComponent
  ],
  exports: [
    SeaProductVariantSelectModule,

    SeaProductVariantListComponent,
    SeaProductVariantCreateComponent,
    SeaProductVariantManageComponent,
    SeaProductVariantDeleteComponent,
    SeaProductVariantImportComponent
  ]
})
export class SeaProductVariantModule {}
