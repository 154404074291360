import {NgModule} from "@angular/core";
import {RnmcotationListComponent} from "./rnmcotation-list/rnmcotation-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {RnmcotationManageComponent} from "./rnmcotation-manage/rnmcotation-manage.component";
import {QuillModule} from "ngx-quill";


@NgModule({
  declarations: [
    RnmcotationListComponent,
    RnmcotationManageComponent
  ],
  exports: [
    RnmcotationListComponent,
    RnmcotationManageComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    QuillModule
  ]
})
export class RnmcotationModule {
}
