<!DOCTYPE html>
<ng-template #addProductModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Infos, nomenclature & tarif</h4>
    <button class="btn-close" (click)="modal.dismiss('x')"></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="zone=='fl'">
      <lvadg-offer-manage-product [product]="addProduct" [offer]="offer" (added)="addedProduct($event)" (cancelled)="dispAddProduct.close()"></lvadg-offer-manage-product>
    </ng-container>
    <ng-container *ngIf="zone=='sea'">
      <lvadg-offer-manage-seaproduct [product]="addProduct" [offer]="offer" (added)="addedProduct($event)" (cancelled)="dispAddProduct.close()"></lvadg-offer-manage-seaproduct>
    </ng-container>
  </div>
</ng-template>
<ng-template #manageProductModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Infos, nomenclature & tarif</h4>
    <button class="btn-close" (click)="modal.dismiss('x')"></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="zone==='fl'">
      <lvadg-offer-manage-product [product]="manageProduct" [offer]="offer" (added)="addedProduct($event)" (cancelled)="dispManageProduct.close()"></lvadg-offer-manage-product>
    </ng-container>
    <ng-container *ngIf="zone==='sea'">
      <lvadg-offer-manage-seaproduct [product]="manageProduct" [offer]="offer" (added)="addedProduct($event)" (cancelled)="dispManageProduct.close()"></lvadg-offer-manage-seaproduct>
    </ng-container>
  </div>
</ng-template>
<ng-template #removeProductModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Supprimer un produit</h4>
    <button class="btn-close" (click)="modal.dismiss('x')"></button>
  </div>
  <div class="modal-body">
    <h3 class="text-danger">{{removeProduct.name}} ({{removeProduct.producer_details?.name}})</h3>
    <p class="text-danger mt-3 text-center">Etes vous certain de vouloir supprimer ce produit de cette offre locale ?</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-danger" (click)="modal.close('yes')"><i class="fa fa-trash me-2"></i>Supprimer le produit</button>
    <button class="btn btn-outline-primary" (click)="modal.dismiss('no')"><i class="fa fa-times me-2"></i>Annuler la suppression</button>
  </div>
</ng-template>
<ng-template #manageRestosModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Gestion des restaurants</h4>
    <button class="btn-close" (click)="modal.close('x')"></button>
  </div>
  <div class="modal-body">
    <lvadg-offer-manage-restos [offer]="offer" (changed)="refresh()"></lvadg-offer-manage-restos>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-primary" (click)="modal.close('yes')"><i class="fa fa-times me-2"></i>Fermer</button>
  </div>
</ng-template>
<nav class="nav-tabs nav-tabs-justified" ngbNav #nav="ngbNav">
  <ng-container ngbNavItem *ngIf="checkMode('admin', 'member', 'vivalya')"><a ngbNavLink>Infos générales</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <div class="card mt-3 mb-3" *ngIf="clone">
          <div class="card-header"><i class="fa fa-copy me-2"></i>Cloner cette offre</div>
          <div class="card-body">
            <lvadg-offer-clone [source]="offer" [member]="member" (cloned)="cloned($event)" (cancelled)="clone=false"></lvadg-offer-clone>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xl-8">
            <div class="row">
              <div class="col-md-12 col-xl-6">
                <button class="btn btn-outline-primary mb-3 btn-block w-100" (click)="clone=!clone" *ngIf="checkMode('admin', 'member')"><i class="fa fa-copy me-2"></i>Cloner cette offre locale</button>
                <div class="card">
                  <div class="card-header">Infos offre</div>
                  <div class="card-body">
                    <dl class="row" *ngIf="offer">
                      <div class="col-sm-12" *ngIf="offer.parent">
                        <dt>Offre parente</dt>
                        <dd><a [routerLink]="routes.detail(zone, 'offers', offer.parent)"><i class="fa fa-link me-2"></i>[{{offer.parent}}]</a></dd>
                      </div>
                      <div class="col-sm-3">
                        <bsac-dd-field type="auto" [model]="offer" field="id">ID</bsac-dd-field>
                      </div>
                      <div class="col-sm-9">
                        <bsac-dd-field type="auto" [model]="offer" field="title" [editable]="checkMode('admin', 'member')">Titre</bsac-dd-field>
                      </div>
                      <div class="col-sm-12">
                        <dt>Dépôt</dt>
                        <dd>{{offer.storage_details?.name}}</dd>
                      </div>
                      <div class="col-sm-12">
                        <lvadg-daterangepicker [start]="offer.datestart" [end]="offer.dateend" (selected)="changeDate($event)"></lvadg-daterangepicker>
                      </div>
                      <div class="col-sm-12">
                        <bsac-dd-field type="auto" [model]="offer" field="status" [editable]="checkMode('admin', 'member')">Etat</bsac-dd-field>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-xl-6 mt-3 mt-xl-0">
                <div class="card">
                  <div class="card-header">Infos destinataire(s)</div>
                  <div class="card-body">
                    <ng-container *ngIf="offer.client">
                      <dl>
                        <dt>Client</dt>
                        <dd>{{offer.client_details?.name}}</dd>
                        <dt>Restaurants</dt>
                        <dd>
                          <ul>
                            <li *ngFor="let r of offer.restos_details">{{r.name}}</li>
                          </ul>
                          <button class="btn btn-link" (click)="manageRestos()" *ngIf="checkMode('admin', 'member')"><i class="fa fa-utensils me-2"></i>Gérer les restaurants</button>
                        </dd>
                      </dl>
                    </ng-container>
                    <ng-container *ngIf="!offer.client">
                      <dl>
                        <dt>Dépôt</dt>
                        <dd>{{offer.storage_details?.name}}</dd>
                        <dt>Client</dt>
                        <dd>OFFRE GENERIQUE</dd>
                      </dl>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4 mt-3 mt-md-0">
            <lvadg-modelprint-job-history [print]="print" *ngIf="print"></lvadg-modelprint-job-history>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngIf="checkMode('admin', 'member')"><a ngbNavLink>Ajout produits</a>
    <ng-template ngbNavContent>
      <div class="tab-content" *ngIf="zone=='fl'">
        <lvadg-product-list [name]="name+'-products-add'" displayMode="vertical" [mode]="mode" [member]="member" [storage]="$any(offer.storage_details)" [default_fields]="productAddDefaultFields" [allowed_fields]="productAddAllowedFields" [default_filters]="productAddDefaultFilters" [allowed_filters]="productAddAllowedFilters" [seasons_perline]="3" [filter]="{storages: offer.storage, maxd: 200000, inoffer: offer.id, mode: 'member'}" [actions]="productAddActions" (action)="offerProductActions('add', $event)" [select_actions]="productAddSelectActions" [select_custom_actions]="productAddSelectCustomActions" [reload]="reloadProducts$"></lvadg-product-list>
      </div>
      <div class="tab-content" *ngIf="zone=='sea'">
        <lvadg-seaproduct-list [name]="name+'-seaproducts-add'" displayMode="vertical" [mode]="mode" [member]="member" [storage]="$any(offer.storage_details)" [default_fields]="productAddDefaultFields" [allowed_fields]="productAddAllowedFields" [default_filters]="productAddDefaultFilters" [allowed_filters]="productAddAllowedFilters" [seasons_perline]="3" [filter]="{storages: offer.storage, inoffer: offer.id,  mode: 'member'}" [actions]="productAddActions" (action)="offerProductActions('add', $event)" [select_actions]="productAddSelectActions" [select_custom_actions]="productAddSelectCustomActions" [reload]="reloadProducts$"></lvadg-seaproduct-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem><a ngbNavLink>Produits sélectionnés</a>
    <ng-template ngbNavContent>
      <div class="tab-content" *ngIf="zone=='fl'">
        <lvadg-product-list [name]="name+'-products'" displayMode="vertical" [mode]="mode" [member]="member" [resto]="resto" [storage]="$any(offer.storage_details)" [filter]="{offer: offer.id.toString(), storages: offer.storage.toString(), maxd: '200000', inoffer: offer.id.toString(), mode: 'member'}" [default_fields]="productListDefaultFields" [allowed_fields]="productListAllowedFields" [default_filters]="productListDefaultFilters" [allowed_filters]="productListAllowedFilters" [sorter_field]="productListSorterField" [actions]="productListActions" [select_actions]="productListSelectActions" [select_custom_actions]="productListSelectCustomActions" (action)="offerProductActions('list', $event)" [reload]="reloadProducts$"></lvadg-product-list>
      </div>
      <div class="tab-content" *ngIf="zone=='sea'">
        <lvadg-seaproduct-list [name]="name+'-seaproducts'" displayMode="vertical" [mode]="mode" [member]="member" [resto]="resto" [storage]="$any(offer.storage_details)" [filter]="{offer: offer.id.toString(), storages: offer.storage.toString(), inoffer: offer.id.toString(), mode: 'member'}" [default_fields]="productListDefaultFields" [allowed_fields]="productListAllowedFields" [default_filters]="productListDefaultFilters" [allowed_filters]="productListAllowedFilters" [sorter_field]="productListSorterField" [actions]="productListActions" [select_actions]="productListSelectActions" [select_custom_actions]="productListSelectCustomActions" (action)="offerProductActions('list', $event)" [reload]="reloadProducts$"></lvadg-seaproduct-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngIf="checkMode('admin', 'member')"><a ngbNavLink>Mise en page</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <h3><i class="fa fa-gears me-2"></i>Paramétrage</h3>
        <lvadg-offer-print-parameters [offer]="offer"></lvadg-offer-print-parameters>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngIf="print &amp;&amp; checkMode('admin', 'member')"><a ngbNavLink>Imprimer</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <h3><i class="fa fa-gears me-2"></i>Actions</h3>
        <lvadg-modelprint-print [print]="print"></lvadg-modelprint-print>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngIf="checkMode('admin', 'member') &amp;&amp; features.isEnabled('front_offer_send')"><a ngbNavLink>Envoyer</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <h3><i class="fa fa-email me-2"></i>Envoyer</h3>
        <lvadg-offer-send [offer]="offer"></lvadg-offer-send>
      </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav"></div>