import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {SeaProductPriceSelectComponent} from "./seaproductprice-select/seaproductprice-select.component";

@NgModule({
  imports: [CommonModule, BsacFormsModule],
  declarations: [SeaProductPriceSelectComponent],
  exports: [SeaProductPriceSelectComponent]
})
export class SeaProductPriceSelectModule {}
