import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {take, tap} from "rxjs/operators";
import {ModelSend} from "../../modelsend.service";
import {SendDestination, SendDestinationService} from "../../senddestination/senddestination.service";
import {Observable, ReplaySubject} from "rxjs";
import {SenderJobDataResult} from "../../senderjob.service";
import {Member} from "../../../../structure/member/member.service";
import {Storage} from "../../../../structure/storage/storage.service";
import {Client} from "../../../../structure/client/client.service";
import {Resto} from "../../../../structure/resto/resto.service";

@Component({
  selector: "lvadg-modelsend-destinations",
  templateUrl: "./modelsend-destinations.component.pug",
  styleUrls: ["./modelsend-destinations.component.sass"]
})
export class ModelSendDestinationsComponent implements OnInit {
  @Input() public mode = "offer";
  @Input() public model!: ModelSend;
  @Input() public member?: Member;
  @Input() public storage?: Storage;
  @Input() public client?: Client;
  @Input() public resto?: Resto;
  @Input() public updates!: Observable<SenderJobDataResult>;
  @Input() public reload!: Observable<ModelSend>;
  @Output() public destinations = new ReplaySubject<SendDestination[]>(1);
  @Output() public preview = new EventEmitter<number | null>();

  public qs!: DSRestQueryset<SendDestination>;
  public dests!: SendDestination[];

  constructor(private _dests: SendDestinationService) {
  }

  public async ngOnInit(): Promise<void> {
    this.qs = this._dests.queryset;
    if (this.reload && this.reload.subscribe) {
      this.reload.subscribe((model) => {
        this.loadDestinations();
      });
    }
    if (this.updates && this.updates.subscribe) {
      this.updates.subscribe((res) => {
        console.log("Status update received", res);
        if (this.dests) {
          for (const d of this.dests) {
            if (res[d.id]) {
              d.status = res[d.id];
            }
          }
        }
      });
    }
    await this.loadDestinations();
  }

  /**
   * Load destinations, sorting first by `sort` parm.
   * @param sort sort parameter (default = resto__name)
   */
  public async loadDestinations(sort: string = "resto__name"): Promise<void> {
    this.dests = (await this.qs.query({send: this.model.id, page_size: 300})
      .sort([{field: sort, direction: "asc"}, {field: "created", direction: "asc"}])
      .get()
      .pipe(
        take(1),
        tap((dests) => this.destinations.next(dests.items))
      ).toPromise()).items;
  }

  /**
   * Add an empty destination to current send model, then reload destinations.
   * @param s SendDestination template
   */
  public async addDestination(s?: SendDestination) {
    await this.model.action("add_destination", {
      method: "POST",
      body: {
        send: this.model.id,
        resto: s?.resto,
        status: "NOK"
      }
    }).toPromise();
    await this.loadDestinations();
  }

  public selectPage(s: SendDestination) {
    this.preview.emit(s.id);
  }

  public async changeStatus(s: SendDestination) {
    if (s.status === "SKP") {
      s.status = "SND";
      await s.update(["status"]).toPromise();
    } else if (s.status === "SND") {
      s.status = "SKP";
      await s.update(["status"]).toPromise();
    }
  }
}
