import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DocumentIconComponent} from "./document-icon/document-icon.component";
import {CompsModule} from "../../../comps/comps.module";
import {DocumentPropertiesComponent} from "./document-properties/document-properties.component";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {ImageModule} from "../../content/image/image.module";
import {FormsModule} from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    CompsModule,
    ImageModule,
    BsacFormsModule,
    FormsModule
  ],
  declarations: [DocumentIconComponent, DocumentPropertiesComponent],
  exports: [DocumentIconComponent, DocumentPropertiesComponent]
})
export class DocumentModule {
}
