/**
 * Person : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @changed 2018-04-29-23:46:33
 * @date 2018-04-29-23:45:59
 * @copyright (c) 2017 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Site} from "../../authentication/site/site.service";
import {Group} from "../group/group.service";

export class Person extends DSModel {


  @DSManyToManyField({
    required: false,
    name: "adminsites",
    helpText: "",
    verboseName: "IDs site admin",
    choices: {},
    default: null,
  })
  public adminsites!: number[];

  @DSManyToManyField({
    required: false,
    name: "usersites",
    helpText: "",
    verboseName: "IDs sites user",
    choices: {},
    default: null,
  })
  public usersites!: number[];

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSDateTimeField({
    required: false,
    name: "last_login",
    helpText: "",
    verboseName: "Dernier login",
    choices: [],
    default: null,
  })
  public last_login!: Date;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date de mise à jour",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [],
    default: null,
    maxLength: 4,
  })
  public status!: string;

  @DSCharField({
    required: false,
    name: "login",
    helpText: "",
    verboseName: "Identifiant",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public login!: string;

  @DSBooleanField({
    required: false,
    name: "is_active",
    helpText: "",
    verboseName: "Est actif ?",
    choices: [],
    default: true,
  })
  public is_active!: any;

  @DSBooleanField({
    required: false,
    name: "is_superuser",
    helpText: "",
    verboseName: "Est super-utilisateur ?",
    choices: [],
    default: false,
  })
  public is_superuser!: any;

  @DSBooleanField({
    required: false,
    name: "is_staff",
    helpText: "",
    verboseName: "Est staff ?",
    choices: [],
    default: false,
  })
  public is_staff!: any;

  @DSCharField({
    required: true,
    name: "firstname",
    helpText: "Prénom",
    verboseName: "Prénom",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public firstname!: string;

  @DSCharField({
    required: true,
    name: "lastname",
    helpText: "Nom de famille, en majuscules",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public lastname!: string;

  @DSCharField({
    required: false,
    name: "title",
    helpText: "",
    verboseName: "Titre",
    choices: [],
    default: null,
    maxLength: 10,
  })
  public title!: string;

  @DSCharField({
    required: true,
    name: "email",
    helpText: "",
    verboseName: "Email",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public email!: string;

  @DSCharField({
    required: false,
    name: "phone",
    helpText: "",
    verboseName: "N° téléphone",
    choices: [],
    default: null,
    maxLength: 40,
  })
  public phone!: string;

  @DSCharField({
    required: false,
    name: "mobile",
    helpText: "",
    verboseName: "N° portable",
    choices: [],
    default: null,
    maxLength: 40,
  })
  public mobile!: string;

  @DSCharField({
    required: false,
    name: "defaultpw",
    helpText: "",
    verboseName: "Mdp par défaut",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public defaultpw!: string;

  @DSForeignKeyField({
    required: false,
    name: "photo",
    helpText: "",
    verboseName: "ID Photo",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "photoperson",
  })
  public photo!: number;

  @DSCharField({
    required: false,
    name: "aduserid",
    helpText: "",
    verboseName: "AD User Id",
    choices: [],
    default: null,
    maxLength: 255,
  })
  public aduserid!: string;

  @DSCharField({
    required: false,
    name: "adgroupid",
    helpText: "",
    verboseName: "AD Group ID",
    choices: [],
    default: null,
    maxLength: 255,
  })
  public adgroupid!: string;

  @DSManyToManyField({
    required: false,
    name: "images",
    helpText: "",
    verboseName: "IDs images",
    choices: [],
    default: [],
  })
  public images!: number[];


  @DSDefaultField({
    required: false,
    name: "flags",
    verboseName: "Admin flags"
  })
  public flags: string[] = [];


  @DSManyToManyField({
    name: "groups",
    verboseName: "Groupes",
    required: false,
    relatedModel: "people.Group",
    remoteField: "users",
    helpText: "Groupes",
    default: [],
  })
  public groups!: number[];
  public groups_details!: Group[];


  public photo_img!: any;
  public adminsites_details!: any;
  public usersites_details!: any;
  public restos_details?: { id: number; name: string }[];
  public clients_details?: { id: number; name: string }[];
  public members_details?: { id: number; name: string }[];

  public intariftype_starred?: boolean;
  public intariftype_notified?: boolean;
  public intariftype_groups?: { id: number; slug: string }[];


  public isSiteAdmin(site: Site): boolean {
    return this.adminsites.indexOf(site.id) !== -1;
  }

  public isSiteUser(site: Site): boolean {
    return this.usersites.indexOf(site.id) !== -1;
  }

}

@Injectable({providedIn: "root"})
export class PersonService extends DSRestCollection<Person> {
  public adapter_config = {basePath: "/api/v2/persons"};
  public model = Person;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
