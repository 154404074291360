import {Component, Input, OnInit} from "@angular/core";
import {TarifType} from "../../tariftype/tariftype.service";
import {TarifNotificationGroup, TarifNotificationGroupService} from "../tarifnotificationgroup.service";
import {firstValueFrom} from "rxjs";
import {FormGroup} from "@angular/forms";
import {GroupService} from "../../../people/group/group.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";

@Component({
  selector: "lvadg-tarifnotificationgroup-listmanage",
  templateUrl: "./tarifnotificationgroup-listmanage.component.pug",
  styleUrls: ["./tarifnotificationgroup-listmanage.component.sass"]
})
export class TarifNotificationGroupListmanageComponent implements OnInit {
  @Input() public tarifType!: TarifType;
  public tngs: TarifNotificationGroup[] = [];
  public tng!: TarifNotificationGroup;
  public addFormDisplay: boolean = false;
  public addForm!: FormGroup;

  constructor(private _tngs: TarifNotificationGroupService,
              private _msgs: BsacMessageService,
              public groups$: GroupService) {
  }

  public async ngOnInit(): Promise<void> {
    this.addForm = new FormGroup({});
    this.tng = await firstValueFrom(this._tngs.create({tariftype: this.tarifType.id}));
    const qs = this._tngs.queryset;
    this.tngs = (await firstValueFrom(qs.query({tariftype: this.tarifType.id}).get())).items;

  }

  public async actionAdd() {
    if (!this.addForm.value.group || !this.addForm.value.notification) {
      this._msgs.error("Merci de compléter tous les champs");
      return;
    }
    await firstValueFrom(this.tarifType.action("contact_groups", {
      method: "POST",
      body: {
        action: "add",
        group: this.addForm.value.group,
        notification: this.addForm.value.notification
      }
    }));
    this.addFormDisplay = false;
    this.ngOnInit();
    this._msgs.success("Groupe ajouté avec succès");
  }

  public async removeAction(tn: TarifNotificationGroup): Promise<void> {
    await firstValueFrom(this.tarifType.action("contact_groups", {
      method: "POST",
      body: {
        action: "remove",
        group: tn.group,
        notification: tn.notification
      }
    }));
    this.ngOnInit();
    this._msgs.success("Groupe supprimé avec succès");
  }
}
