import {Component, Input} from "@angular/core";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {ProductPrice} from "../../../../lib/models/product/productprice/productprice.service";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {CommonBaseComponent} from "../../common-base.component";
import {AuthService} from "../../../../lib/models/user/auth.service";
import {NavDriver, TabMemoryService} from "../../../../lib/comps/tab-memory.service";
import {ExternalArticle} from "../../../../lib/models/catalog/externalarticle/externalarticle.service";
import {ArticleFamily} from "../../../../lib/models/catalog/family/article-family.service";
import {VivalyaBill} from "../../../../lib/models/sales/bill/bill.service";
import {VIEW_DISPLAY} from "../../../view-constants";
import {firstValueFrom} from "rxjs";
import {VivalyaBillItem} from "../../../../lib/models/sales/billitem/billitem.service";
import {Storage} from "../../../../lib/models/structure/storage/storage.service";
import {Resto} from "../../../../lib/models/structure/resto/resto.service";
import {Client} from "../../../../lib/models/structure/client/client.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {TimeSeriesChartDataset} from "../../../../lib/comps/charts/formats";

export interface CatalogArticleManageViewV3Params extends BaseRouteV3Params {
  externalarticle_detail_route: RouteConfigItemV3;
  article_detail_route: RouteConfigItemV3;
  articlefamily_detail_route: RouteConfigItemV3;
  bill_detail_route: RouteConfigItemV3;
  billitem_detail_route: RouteConfigItemV3;
  storage_detail_route: RouteConfigItemV3;
  resto_detail_route: RouteConfigItemV3;
  client_detail_route: RouteConfigItemV3;
  display?: VIEW_DISPLAY;
}

export interface CatalogArticleManageViewV3Data extends CatalogArticleManageViewV3Params {
  article: Article;
}


@Component({
  selector: "lvadg-catalog-article-manage-view",
  templateUrl: "./catalog-article-manage-view.component.pug",
  styleUrls: ["./catalog-article-manage-view.component.sass"],
})
export class CatalogArticleManageViewV3Component extends CommonBaseComponent {

  @Input() public article!: Article;
  public remove = false;
  public now = Date.now();
  public priceData: any = [{name: "Article", series: []}];
  public externalarticles_default_fields: string[] = [
    "id", "code", "type", "libelle", "client", "tariftype", "status"];
  public externalarticles_default_filters: string[] = [
    "search", "type"
  ];
  public articletarifs_default_fields: string[] = [];
  public articletarifs_default_filters: string[] = ["search"];
  public externalarticle_detail_route!: RouteV3<ExternalArticle>;
  public articlefamily_detail_route!: RouteV3<ArticleFamily>;
  public article_detail_route!: RouteV3<Article>;
  public bill_detail_route!: RouteV3<VivalyaBill>;
  public billitem_detail_route!: RouteV3<VivalyaBillItem>;
  public storage_detail_route!: RouteV3<Storage>;
  public resto_detail_route!: RouteV3<Resto>;
  public client_detail_route!: RouteV3<Client>;
  public navId!: NavDriver;

  // TODO: add article_list_route to go back from article removal

  constructor(routes: RoutesService,
              router: Router,
              route: ActivatedRoute,
              public auth: AuthService,
              private _msgs: BsacMessageService,
              private tms: TabMemoryService) {
    super(routes, router, route);
  }

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogArticleManageViewV3Data;
    this.externalarticle_detail_route = new RouteV3<ExternalArticle>(data.externalarticle_detail_route, this);
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    this.articlefamily_detail_route = new RouteV3<ArticleFamily>(data.articlefamily_detail_route, this);
    this.bill_detail_route = new RouteV3<VivalyaBill>(data.bill_detail_route, this);
    this.billitem_detail_route = new RouteV3<VivalyaBillItem>(data.billitem_detail_route, this);
    this.storage_detail_route = new RouteV3<Storage>(data.storage_detail_route, this);
    this.client_detail_route = new RouteV3<Client>(data.client_detail_route, this);
    this.resto_detail_route = new RouteV3<Resto>(data.resto_detail_route, this);
    this.route.data.subscribe((d: Data) => {
      const rdata = d  as CatalogArticleManageViewV3Data;
      this.article = rdata.article;
    });
    this.navId = this.tms.memFor(["articlemanage"], "show", false);
  }

  public removed(): void {
    this.router.navigate([".."]);
  }

  public updatePrices(prices: ProductPrice[]): void {
    const data: TimeSeriesChartDataset = {
      title: "Evolution des prix (€)",
      subtitle: this.article.libelle,
      axis: {
        time: {title: "Temps", unit: ""},
        value: {title: "Prix", unit: "€"}
      },
      legend: false,
      data: []
    };
    data.data!.push(["Date", this.article.libelle, "machin"]);
    for (const p of prices) {
      const date = new Date(p.date_start);
      data.data!.push([date, p.price / 100, Math.random() * 100]);
    }
    this.priceData = data;
  }

  public async cloneArticle(): Promise<void> {
    const nar = await firstValueFrom(this.article.action("clone", {
      method: "POST"
    }));
    this.router.navigate(this.article_detail_route.route({article: nar}));
  }

  public async deleteArticle() {
    try {
      await firstValueFrom(this.article.delete());
      this._msgs.success("Article supprimé avec succès");
      this.router.navigate([".."], {relativeTo: this.route})
    } catch (e) {
      this._msgs.error("Impossible de supprimer l'article")
    }
  }
}
