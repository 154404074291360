import {OfferCreateComponent} from "./offer-create/offer-create.component";
import {OfferDeleteComponent} from "./offer-delete/offer-delete.component";
import {OfferListComponent} from "./offer-list/offer-list.component";
import {OfferManageComponent} from "./offer-manage/offer-manage.component";
import {OfferSelectModule} from "./offer-select.module";
import {NgModule} from "@angular/core";
import {ProductModule} from "../../product/product/product.module";
import {OfferManageProductComponent} from "./offer-manage-product/offer-manage-product.component";
import {OfferPrintParametersComponent} from "./offer-print-parameters/offer-print-parameters.component";
import {ModelPrintModule} from "../../comm/print/modelprint/modelprint.module";
import {RestoModule} from "../../structure/resto/resto.module";
import {OfferCloneComponent} from "./offer-clone/offer-clone.component";
import {OfferManageRestosComponent} from "./offer-manage-restos/offer-manage-restos.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {SeaProductModule} from "../../product/seaproduct/seaproduct.module";
import {OfferManageSeaProductComponent} from "./offer-manage-seaproduct/offer-manage-seaproduct.component";
import {OfferSendComponent} from "./offer-send-parameters/offer-send.component";
import {ModelCommModule} from "../../comm/basecomm/modelcomm/modelcomm.module";
import {ModelSendModule} from "../../comm/mailer/modelsend/modelsend.module";
import {CommFieldModule} from "../../comm/basecomm/commfield/commfield.module";

@NgModule({
    imports: [
        ...MODELLIST_IMPORTS,
        ProductModule,
        SeaProductModule,
        CommFieldModule,
        RestoModule,
        ModelCommModule,
        ModelSendModule,
        ModelPrintModule
    ],
    declarations: [
        OfferCreateComponent,
        OfferCloneComponent,
        OfferListComponent,
        OfferDeleteComponent,
        OfferManageComponent,
        OfferManageProductComponent,
        OfferManageSeaProductComponent,
        OfferPrintParametersComponent,
        OfferSendComponent,
        OfferManageRestosComponent
    ],
    exports: [
        OfferCreateComponent,
        OfferCloneComponent,
        OfferListComponent,
        OfferDeleteComponent,
        OfferManageComponent,
        OfferSelectModule,
        OfferPrintParametersComponent,
        OfferSendComponent,
        OfferManageRestosComponent
    ]
})
export class OfferModule {
}
