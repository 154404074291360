import {NgModule} from "@angular/core";
import {ModelprintPrintComponent} from "./modelprint-print/modelprint-print.component";
import {ModelprintSelectPageComponent} from "./modelprint-select-page/modelprint-select-page.component";
import {ModelprintQuickprintComponent} from "./modelprint-quickprint/modelprint-quickprint.component";
import {ModelprintDataComponent} from "./modelprint-data/modelprint-data.component";
import {ModelprintJobHistoryComponent} from "./modelprint-job-history/modelprint-job-history.component";
import {ModelprintListComponent} from "./modelprint-list/modelprint-list.component";
import {MODELLIST_IMPORTS} from "../../../../../includes/components/modellist.imports";

@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
  ],
  declarations: [
    ModelprintPrintComponent,
    ModelprintSelectPageComponent,
    ModelprintQuickprintComponent,
    ModelprintDataComponent,
    ModelprintJobHistoryComponent,
    ModelprintListComponent,
  ],
  exports: [
    ModelprintPrintComponent,
    ModelprintSelectPageComponent,
    ModelprintQuickprintComponent,
    ModelprintDataComponent,
    ModelprintJobHistoryComponent,
    ModelprintListComponent,
  ],

})
export class ModelPrintModule {
}
