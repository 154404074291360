import {Injectable} from "@angular/core";
import {IDSModel} from "@solidev/ngdataservice";
import {isNumber, values} from "lodash-es";


@Injectable({
  providedIn: "root"
})
export class SelectionService {
  private _selected: { [index: string]: { [index: number]: any } } = {};

  constructor() {
  }

  public addSelection(stype: string, model: IDSModel | number): void {
    if (!this._selected[stype]) {
      this._selected[stype] = {};
    }
    // SEE: check if only a summary of model could be better (serializable, smaller..)
    if (isNumber(model)) {
      this._selected[stype][model] = {id: model};
    } else {
      this._selected[stype][model.id as number] = model;
    }
  }

  public removeSelection(stype: string, model: IDSModel | number): void {
    if (!this._selected[stype]) {
      return;
    }
    if (isNumber(model)) {
      delete this._selected[stype][model];
    } else {
      delete this._selected[stype][model.id as number];
    }
  }

  public clearSelection(stype: string): void {
    this._selected[stype] = {};
  }

  public isSelected(stype: string, model: IDSModel | number): boolean {
    if (!this._selected[stype]) {
      return false;
    }
    if (isNumber(model)) {
      return !!this._selected[stype][model];
    } else {
      return !!this._selected[stype][model.id as number];
    }
  }

  // SEE: make 3-state button for "all/some/none" ?
  public haveSelected(stype: string, models: (IDSModel | number)[]): boolean {
    for (const m of models) {
      if (this.isSelected(stype, m)) {
        return true;
      }
    }
    return false;
  }

  public addToSelection(stype: string, models: (IDSModel | number)[]): void {
    for (const m of models) {
      this.addSelection(stype, m);
    }
  }

  public removeFromSelection(stype: string, models: (IDSModel | number)[]): void {
    for (const m of models) {
      this.removeSelection(stype, m);
    }
  }

  public count(stype: string): number {
    if (!this._selected[stype]) {
      return 0;
    }
    return (Object.keys(this._selected[stype]).length);
  }

  public items(stype: string): any[] {
    if (!this._selected[stype]) {
      return [];
    }
    return values(this._selected[stype]);
  }



}
