import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Frequency, RRule, Weekday} from "rrule";
import {FRENCH_RRULE, GETTEXT_FRENCH_RRULE} from "./rrule-language";

@Component({
  selector: "lvadg-rrule-editor",
  templateUrl: "./rrule-editor.component.pug",
  styleUrls: ["./rrule-editor.component.sass"]
})
export class RruleEditorComponent implements OnInit {
  @Input() public rule!: string;
  @Input() public editable = true;
  public form!: UntypedFormGroup;
  public FRENCH = FRENCH_RRULE;
  public computedDates!: Date[];
  public freqOptions = [
    {freq: RRule.DAILY, desc: "Jours(s)"},
    {freq: RRule.WEEKLY, desc: "Semaine(s)"},
    {freq: RRule.MONTHLY, desc: "Mois"},
    {freq: RRule.YEARLY, desc: "Année(s)"}
  ];
  public hourOptions = [
    {hour: 0, desc: "00:00"},
    {hour: 1, desc: "01:00"},
    {hour: 2, desc: "02:00"},
    {hour: 3, desc: "03:00"},
    {hour: 4, desc: "04:00"},
    {hour: 5, desc: "05:00"},
    {hour: 6, desc: "06:00"},
    {hour: 7, desc: "07:00"},
    {hour: 8, desc: "08:00"},
    {hour: 9, desc: "09:00"},
    {hour: 10, desc: "10:00"},
    {hour: 11, desc: "11:00"},
    {hour: 12, desc: "12:00"},
    {hour: 13, desc: "13:00"},
    {hour: 14, desc: "14:00"},
    {hour: 15, desc: "15:00"},
    {hour: 16, desc: "16:00"},
    {hour: 17, desc: "17:00"},
    {hour: 18, desc: "18:00"},
    {hour: 19, desc: "19:00"},
    {hour: 20, desc: "20:00"},
    {hour: 21, desc: "21:00"},
    {hour: 22, desc: "22:00"},
    {hour: 23, desc: "23:00"},
  ];
  public dayOptions = [
    {wd: RRule.MO, desc: "Les lundis"},
    {wd: RRule.TU, desc: "Les mardis"},
    {wd: RRule.WE, desc: "Les mercredis"},
    {wd: RRule.TH, desc: "Les jeudis"},
    {wd: RRule.FR, desc: "Les vendredis"},
    {wd: RRule.SA, desc: "Les samedis"},
    {wd: RRule.SU, desc: "Les dimanches"},
    {wd: RRule.MO.nth(1), desc: "Le premier lundi"},
    {wd: RRule.MO.nth(2), desc: "Le deuxième lundi"},
    {wd: RRule.MO.nth(3), desc: "Le 3eme lundi"},
    {wd: RRule.MO.nth(4), desc: "Le 4eme lundi"},
    {wd: RRule.MO.nth(5), desc: "Le 5eme lundi"},
    {wd: RRule.MO.nth(-1), desc: "Le dernier lundi"},
    {wd: RRule.MO.nth(-2), desc: "L'avant dernier lundi"},

    {wd: RRule.TU.nth(1), desc: "Le premier mardi"},
    {wd: RRule.TU.nth(2), desc: "Le deuxième mardi"},
    {wd: RRule.TU.nth(3), desc: "Le 3eme mardi"},
    {wd: RRule.TU.nth(4), desc: "Le 4eme mardi"},
    {wd: RRule.TU.nth(5), desc: "Le 5eme mardi"},
    {wd: RRule.TU.nth(-1), desc: "Le dernier mardi"},
    {wd: RRule.TU.nth(-2), desc: "L'avant dernier mardi"},

    {wd: RRule.WE.nth(1), desc: "Le premier mercredi"},
    {wd: RRule.WE.nth(2), desc: "Le deuxième mercredi"},
    {wd: RRule.WE.nth(3), desc: "Le 3eme mercredi"},
    {wd: RRule.WE.nth(4), desc: "Le 4eme mercredi"},
    {wd: RRule.WE.nth(5), desc: "Le 5eme mercredi"},
    {wd: RRule.WE.nth(-1), desc: "Le dernier mercredi"},
    {wd: RRule.WE.nth(-2), desc: "L'avant dernier mercredi"},

    {wd: RRule.TH.nth(1), desc: "Le premier jeudi"},
    {wd: RRule.TH.nth(2), desc: "Le deuxième jeudi"},
    {wd: RRule.TH.nth(3), desc: "Le 3eme jeudi"},
    {wd: RRule.TH.nth(4), desc: "Le 4eme jeudi"},
    {wd: RRule.TH.nth(5), desc: "Le 5eme jeudi"},
    {wd: RRule.TH.nth(-1), desc: "Le dernier jeudi"},
    {wd: RRule.TH.nth(-2), desc: "L'avant dernier jeudi"},

    {wd: RRule.FR.nth(1), desc: "Le premier vendredi "},
    {wd: RRule.FR.nth(2), desc: "Le deuxième vendredi"},
    {wd: RRule.FR.nth(3), desc: "Le 3eme vendredi"},
    {wd: RRule.FR.nth(4), desc: "Le 4eme vendredi"},
    {wd: RRule.FR.nth(5), desc: "Le 5eme vendredi"},
    {wd: RRule.FR.nth(-1), desc: "Le dernier vendredi"},
    {wd: RRule.FR.nth(-2), desc: "L'avant dernier vendredi"},

    {wd: RRule.SA.nth(1), desc: "Le premier vsamedi"},
    {wd: RRule.SA.nth(2), desc: "Le deuxième samedi"},
    {wd: RRule.SA.nth(3), desc: "Le 3eme samedi"},
    {wd: RRule.SA.nth(4), desc: "Le 4eme samedi"},
    {wd: RRule.SA.nth(5), desc: "Le 5eme samedi"},
    {wd: RRule.SA.nth(-1), desc: "Le dernier samedi"},
    {wd: RRule.SA.nth(-2), desc: "L'avant dernier samedi"},

    {wd: RRule.SU.nth(1), desc: "Le premier dimanche"},
    {wd: RRule.SU.nth(2), desc: "Le deuxième dimanche"},
    {wd: RRule.SU.nth(3), desc: "Le 3eme dimanche"},
    {wd: RRule.SU.nth(4), desc: "Le 4eme dimanche"},
    {wd: RRule.SU.nth(5), desc: "Le 5eme dimanche"},
    {wd: RRule.SU.nth(-1), desc: "Le dernier dimanche"},
    {wd: RRule.SU.nth(-2), desc: "L'avant dernier dimanche"},

  ];
  public dateOptions: any[] = [];
  public rrule!: RRule;
  public showDates = false;
  public edit: boolean = false;

  @Output() public save: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  public defaultGetText = (id: string|number|Weekday): string => {
    const out = GETTEXT_FRENCH_RRULE[`${id}`];
    if (!out) {
      console.error("Missing translation for ", id);
      return id.toString();
    }
    return out;
  };

  ngOnInit(): void {
    if (this.rule) {
      this.rrule = RRule.fromString(this.rule);
    } else {
      this.rrule = new RRule({
        freq: Frequency.WEEKLY,
        interval: 1,
        byhour: [0],
        byweekday: ["MO"],
        dtstart: new Date("2021-10-01T00:00:00"),
        until: new Date("2024-01-01T00:00:00"),
      });
    }
    this.form = new UntypedFormGroup({
      freq: new UntypedFormControl(this.rrule.options.freq),
      interval: new UntypedFormControl(this.rrule.options.interval),
      byhour: new UntypedFormControl(this.rrule.options.byhour),
      byweekday: new UntypedFormControl(this.rrule.options.byweekday),
      bymonthday: new UntypedFormControl(this.rrule.options.bymonthday),
      dtstart: new UntypedFormControl((this.rrule.options.dtstart && this.rrule.options.dtstart.toISOString().slice(0, 16)) || ""),
      until: new UntypedFormControl((this.rrule.options.until && this.rrule.options.until.toISOString().slice(0, 16)) || ""),
      count: new UntypedFormControl(this.rrule.options.count)
    });
    for (let i = 1; i <= 31; i++) {
      this.dateOptions.push({day: i, desc: `le ${i} du mois`});
    }
    this.setRrule(this.form.value);
    this.form.valueChanges.subscribe((value) => this.setRrule(value));
  }

  public setRrule(value: any) {

    const rropts: any = {
      freq: parseInt(value.freq, 10),
      interval: value.interval || 1,
      byhour: [value.byhour],
      dtstart: new Date(value.dtstart),
    };
    if (value.byweekday && value.byweekday.length > 0) {
      rropts.byweekday = value.byweekday;
    }
    if (value.bymonthday && value.bymonthday.length > 0) {
      rropts.bymonthday = value.bymonthday;
    }
    if (value.count > 0) {
      rropts.count = value.count;
    } else if (value.until) {
      rropts.until = new Date(value.until);
    }

    this.rrule = new RRule(rropts, true);
    this.computedDates = this.rrule.all((date, i) => i < 100);
    for (const d of this.computedDates) {
      const offset = (d.getTimezoneOffset()) * 60 * 1000;
      d.setTime(d.getTime() + offset);
    }
  }

  public showCalendar() {
    console.log("TODO");
  }


  public saveRule(): void {
    this.save.next(this.rrule.toString());
    this.edit = false;
  }

  public toggleEdit(): void {
    if (this.edit) {
      this.rrule = RRule.fromString(this.rule);
      this.edit = false;
    } else if (this.editable) {
      this.edit = true;
    }
  }


}
