
<bsac-breadcrumb></bsac-breadcrumb>
<h1><i class="fa fa-table me-2"></i>
  <ng-container *ngIf="tarifType.type=='GEN'">Modèle tarif générique</ng-container>
  <ng-container *ngIf="tarifType.type=='CLI'">Modèle tarif client</ng-container><small class="ms-2">{{tarifType.name}} v3</small>
</h1>
<ng-container *ngIf="tarifType">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="tarifs"><a ngbNavLink>Tarifs</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-tarif-list [mode]="mode" [tarifType]="tarifType" [name]="tarifType.type.toLowerCase()+'-tariftypes-tarifs'" type="tarifs" [filter]="tarifs_filter" [tarif_detail_route]="tarif_detail_route"></lvadg-tarif-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="params_articles" *ngIf="isTarifPowerUser(tarifType)"><a ngbNavLink>Paramètres articles</a>
      <ng-template ngbNavContent>
        <div class="tab-content ps-3 pe-3">
          <lvadg-articletariftemplate-list [tarifType]="tarifType" [mode]="mode" [name]="'articletariftemplate-list'" type="tarifs" [filter]="att_filter" [default_filters]="['search', 'status']" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-articletariftemplate-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="params_tarif"><a ngbNavLink>Paramètres du tarif</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-tariftype-manage [tarifType]="tarifType" [tariftype_detail_route]="tariftype_detail_route"></lvadg-tariftype-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="notifgroups_tarif" *ngIf="['admin', 'sadmin'].indexOf(tarifType.user_level) !== -1"><a ngbNavLink>Notifications</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <h2>Groupes de notification</h2>
          <lvadg-tarifnotificationgroup-listmanage [tarifType]="tarifType"></lvadg-tarifnotificationgroup-listmanage>
          <hr>
          <h2 class="mt-3">Suivi des notifications</h2>
          <lvadg-tarifnotificationstatus-list name="tariftypemanage-notificationlist" [displayMode]="'horizontal'" [mode]="mode" [filter]="{tariftypes: tarifType.id.toString(10)}" [default_fields]="tarifnotifications_default_fields" [default_filters]="tarifnotifications_default_filters" [tarif_detail_route]="tarif_detail_route" [tariftype_detail_route]="tariftype_detail_route"></lvadg-tarifnotificationstatus-list>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="create_tarif" *ngIf="isTarifPowerUser(tarifType)"><a ngbNavLink>Ajouter un tarif</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-tarif-create [tarifType]="tarifType" (created)="onCreated($event)"></lvadg-tarif-create>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="tarif_users" *ngIf="auth.isSuperUser"><a ngbNavLink>Utilisateurs</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <lvadg-tariftype-userlist [mode]="mode" [name]="tarifType.type.toLowerCase()+'-tariftypes-users'" [tarifType]="tarifType" [person_detail_route]="person_detail_route"></lvadg-tariftype-userlist>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="tarif_match" *ngIf="['admin', 'sadmin'].indexOf(tarifType.user_level) !== -1"><a ngbNavLink>Match articles</a>
      <ng-template ngbNavContent>
        <div class="tab-content">
          <div class="mt-2">
            <div class="d-flex flex-row justify-content-between">
              <h4>Association articles tarif</h4>
              <button class="btn btn-sm btn-outline-primary my-2" *ngIf="['sadmin'].indexOf(tarifType.user_level) !== -1" (click)="createArticleTemplates()">Créer les lignes de tarif</button>
            </div>
            <lvadg-externalarticle-list name="tariftypemanage-externalarticle-match" zone="externalarticles" type="externalarticles" [mode]="mode" [tarifType]="tarifType" [default_fields]="ea_default_fields" [default_filters]="ea_default_filters" [filter]="{tarifs: tarifType.id.toString(10), type: 'CLI'}" [reload]="ea_reload" [article_detail_route]="article_detail_route" [externalarticle_detail_route]="externalarticle_detail_route"></lvadg-externalarticle-list>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>