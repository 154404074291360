import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StatsFileDownloadComponent} from "./statsfile-download/statsfile-download.component";

@NgModule({
  declarations: [StatsFileDownloadComponent],
  exports: [StatsFileDownloadComponent],
  imports: [
    CommonModule
  ]
})
export class StatsFileModule {
}
