import {NgModule} from "@angular/core";
import {CustomDataKeyCreateComponent} from "./customdatakey-create/customdatakey-create.component";
import {CustomDataKeyDeleteComponent} from "./customdatakey-delete/customdatakey-delete.component";
import {CustomDataKeyDisplayComponent} from "./customdatakey-display/customdatakey-display.component";
import {CustomDataKeyListComponent} from "./customdatakey-list/customdatakey-list.component";
import {CustomDataKeySelectComponent} from "./customdatakey-select/customdatakey-select.component";
import {CustomDataKeyManageComponent} from "./customdatakey-manage/customdatakey-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {CustomDataKeyChoiceModule} from "../customdatakeychoice/customdatakeychoice.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    CustomDataKeyChoiceModule
  ],
  declarations: [
    CustomDataKeySelectComponent,
    CustomDataKeyDisplayComponent,
    CustomDataKeyDeleteComponent,
    CustomDataKeyListComponent,
    CustomDataKeyManageComponent,
    CustomDataKeyCreateComponent
  ],
  exports: [
    CustomDataKeySelectComponent,
    CustomDataKeyDisplayComponent,
    CustomDataKeyDeleteComponent,
    CustomDataKeyListComponent,
    CustomDataKeyManageComponent,
    CustomDataKeyCreateComponent
  ],
})
export class CustomDataKeyModule {
}
