
<div class="form-group" *ngIf="param">
  <div class="form-group">
    <label><i class="fa fa-trash pointer float-end" (click)="removeParam()"></i>{{param.label}}</label>
    <div class="imglist">
      <ng-container *ngFor="let c of param.choices">
        <div class="pimg" *ngIf="c.value!=='CUSTOM' &amp;&amp; c.url">
          <div class="img pointer" [style.background-image]="getStyle(c)" [class.current]="isCurrent(c)" (click)="setImage(c)"></div>
        </div>
      </ng-container><i class="fa fa-image fa-4x" (click)="setCustom()"></i>
    </div>
    <div class="help-block" *ngIf="param.help">{{param.help}}</div>
    <div *ngIf="param.value=='CUSTOM'">
      <lvadg-upload-image [url]="getImageUrl()" (uploaded)="onUploaded($event)" method="PATCH" fieldName="fvalue"></lvadg-upload-image>
    </div>
  </div>
</div>