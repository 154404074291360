import {ApplicationRef, Injectable} from "@angular/core";
import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {filter, first, map, tap} from "rxjs/operators";
import {merge, Observable, ReplaySubject, timer} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class UpdateService {
  private stable = false;

  constructor(private appRef: ApplicationRef,
              private swUpdate: SwUpdate) {
    this._available$ = new ReplaySubject<boolean>(1);
    this._available$.next(false);
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true)).pipe(tap(() => console.log("App is stable")));
    const checkInterval$ = timer(10 * 1000, 5 * 60 * 1000);
    const checkWhenStable$ = merge(appIsStable$, checkInterval$);
    const updatesAvailable = swUpdate.versionUpdates.pipe(
      tap((evt) => console.log("Version updates event", evt)),
      filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
      map(evt => ({
        type: "UPDATE_AVAILABLE",
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );
    updatesAvailable.subscribe(async (evt) => {
      this._available$.next(true);
    });
    checkWhenStable$.subscribe(async () => {
      this.stable = true;
      try {
        console.log("Checking for update");
        const res = await swUpdate.checkForUpdate();
        console.log("Check for update result", res);
      } catch (e) {
        console.warn("No service worker");
      }
    });
  }

  private _available$: ReplaySubject<boolean>;

  public get available$(): Observable<boolean> {
    return this._available$.asObservable();
  }

  public activate(): void {
    this._available$.next(false);
    console.log("RELOADING FULL PAGE");
    document.location.reload();
  }

  public dismiss(): void {
    this._available$.next(false);
  }
}
