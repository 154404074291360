import {SeaProductFamily, SeaProductFamilyService} from "../seaproductfamily.service";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Observable} from "rxjs";

@Component({
  selector: "lvadg-seaproductfamily-select",
  templateUrl: "./seaproductfamily-select.component.pug",
  styleUrls: ["./seaproductfamily-select.component.sass"]
})
export class SeaProductFamilySelectComponent implements OnInit {
  @Input() public hint = "Selectionnez la categorie de produit";
  @Input() public reset!: Observable<any>;
  @Input() public label = "Sélectionnez un élément";
  @Input() public level?: number;
  @Input() public desctpl!: string;
  @Input() public descfield = "cfullname";
  @Input() public filter: any = {};
  @Input() public searchparam = "search";
  @Output() public selected: EventEmitter<SeaProductFamily> = new EventEmitter<SeaProductFamily>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(public _service: SeaProductFamilyService) {
  }

  public ngOnInit(): void {
    if (this.level !== undefined) {
      this.filter.level = this.level;
    }
  }

  public selItem(model: any): void {
    this.selected.emit(model);
  }
}
