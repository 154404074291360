import {NgModule} from "@angular/core";
import {VIEW_IMPORTS} from "../imports";
import {TarifTypeModule} from "../../lib/models/tarif/tariftype/tariftype.module";
import {TarifModule} from "../../lib/models/tarif/tarif/tarif.module";
import {ArticletarifModule} from "../../lib/models/tarif/articletarif/articletarif.module";
import {TarifStatsViewComponent} from "./tarif-stats-view/tarif-stats-view.component";
import {TarifRnmListViewComponent} from "./rnm-list-view/rnm-list-view.component";
import {RnmModule} from "../../lib/models/tarif/rnm/rnm.module";
import {RnmtarifListViewComponent} from "./rnmtarif-list-view/rnmtarif-list-view.component";
import {RnmtarifModule} from "../../lib/models/tarif/rnmtarif/rnmtarif.module";
import {RnmManageViewComponent} from "./rnm-manage-view/rnm-manage-view.component";
import {RnmMarcheListViewComponent} from "./rnmmarche-list-view/rnmmarche-list-view.component";
import {RnmMarcheManageViewComponent} from "./rnmmarche-manage-view/rnmmarche-manage-view.component";
import {RnmmarcheModule} from "../../lib/models/tarif/rnmmarche/rnmmarche.module";
import {RnmcotationListViewComponent} from "./rnmcotation-list-view/rnmcotation-list-view.component";
import {RnmcotationModule} from "../../lib/models/tarif/rnmcotation/rnmcotation.module";
import {RnmcotationManageViewComponent} from "./rnmcotation-manage-view/rnmcotation-manage-view.component";
import {TarifdocumentModule} from "../../lib/models/tarif/tarifdocument/tarifdocument.module";
import {TarifNotificationModule} from "../../lib/models/tarif/tarifnotification/tarifnotification.module";
import {ExternalArticleModule} from "../../lib/models/catalog/externalarticle/externalarticle.module";


@NgModule({
  declarations: [
    TarifStatsViewComponent,
    TarifRnmListViewComponent,
    RnmtarifListViewComponent,
    RnmManageViewComponent,
    RnmMarcheListViewComponent,
    RnmMarcheManageViewComponent,
    RnmcotationListViewComponent,
    RnmcotationManageViewComponent,
  ],
  exports: [
    TarifRnmListViewComponent,
    RnmMarcheListViewComponent,
    RnmMarcheManageViewComponent,
  ],
  imports: [
    ...VIEW_IMPORTS,
    TarifTypeModule,
    TarifModule,
    TarifNotificationModule,
    RnmModule,
    RnmtarifModule,
    RnmmarcheModule,
    RnmcotationModule,
    ArticletarifModule,
    ExternalArticleModule,
    TarifdocumentModule
  ]
})
export class FrontTarifModule {
}
