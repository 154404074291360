import {SeaProducer, SeaProducerService} from "../seaproducer.service";
import {Component, OnChanges} from "@angular/core";
import {BaseProducerSelectComponent} from "../../_producer/producer-select-base/producer-select-base.component";

@Component({
  selector: "lvadg-seaproducer-select",
  templateUrl: "./seaproducer-select.component.pug",
  styleUrls: ["./seaproducer-select.component.sass"]
})
export class SeaProducerSelectComponent extends BaseProducerSelectComponent<SeaProducer, SeaProducerService> implements OnChanges {
  constructor(public _service: SeaProducerService) {
    super(_service);
  }
}
