import {Directive, Input} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";
import {filter, switchMap, takeUntil} from "rxjs/operators";
import {ReplaySubject, Subject} from "rxjs";
import {DSModel, DSRestCollection} from "@solidev/ngdataservice";
import {Provider} from "../../../structure/provider/provider.service";
import {Article} from "../../article/article.service";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ArticleDataManageBaseComponent<T extends DSModel, TS extends DSRestCollection<T>> extends ModelListBaseComponent<T, TS> {
  public reload = new Subject<void>();
  public afilter$ = new ReplaySubject<any>(1);
  public afilter: any = {};
  public filterField: string = "";
  public paramId: string = "";
  public route!: ActivatedRoute;
  @Input() public articledata_detail_route!: RouteV3<any>;
  @Input() public article_detail_route!: RouteV3<Article>;
  @Input() public provider_detail_route!: RouteV3<Provider>;

  constructor(_list: BsacModelListProvider,
              _items: TS,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute) {
    super(_list, _items, _msgs, routes);
    this.route = route;
  }

  public async preNgOnInit(): Promise<void> {
    this.displayMode = "horizontal";
    this.route.params.pipe(
      filter((par) => par[this.paramId]),
      switchMap((par) => {
        return this._items.get(+par[this.paramId]);
      }),
      takeUntil(this._subscriptions$)
    ).subscribe((cur: T) => {
      const f: { [index: string]: number } = {};
      f[this.filterField] = cur._pk as number;
      this.afilter$.next(f);
      this.afilter = f;
      this.current = cur;
      this.displayMode = "horizontal2res";
    });
    return super.preNgOnInit();
  }

  public getDetailLink(model: T): (string | number)[] {
    return this.articledata_detail_route?.route({type: this.type, articleData: model});
  }

  public updated() {
    console.log("Updated");
    this.reload.next();
  }


}
