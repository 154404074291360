import {Component, Input} from "@angular/core";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonBaseComponent} from "../../common-base.component";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {Provider} from "../../../../lib/models/structure/provider/provider.service";

export interface CatalogArticleDataManageViewV3Params extends BaseRouteV3Params {
  dataType?: string;
  articledata_detail_route: RouteConfigItemV3;
  article_detail_route: RouteConfigItemV3;
  provider_detail_route: RouteConfigItemV3;
}

export interface CatalogArticleDataManageViewV3Data extends CatalogArticleDataManageViewV3Params {

}


@Component({
  selector: "lvadg-catalog-articledata-manage-view",
  templateUrl: "./catalog-articledata-manage-view.component.pug",
  styleUrls: ["./catalog-articledata-manage-view.component.sass"]
})
export class CatalogArticleDataManageViewV3Component extends CommonBaseComponent {
  @Input() public dataType!: string;
  public articledata_detail_route!: RouteV3<any>;
  public article_detail_route!: RouteV3<Article>;
  public provider_detail_route!: RouteV3<Provider>;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogArticleDataManageViewV3Data;
    this.dataType = data.dataType || this.dataType;
    this.articledata_detail_route = new RouteV3<any>(data.articledata_detail_route, this);
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    this.provider_detail_route = new RouteV3<Provider>(data.provider_detail_route, this);
  }
}
