import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Contact, ContactService} from "./contact.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class ContactResolve implements Resolve<Contact> {
  constructor(private _bs: ContactService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<Contact> {
    return this._bs.get(+route.params["contactId"]);
  }
}
