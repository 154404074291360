import {ImageModule} from "../../content/image/image.module";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {LocationModule} from "../../structure/location/location.module";
import {ProducerModule} from "../../structure/producer/producer.module";
import {StorageModule} from "../../structure/storage/storage.module";
import {ProductCommonModule} from "../common/product-common.module";
import {LabelModule} from "../label/label.module";
import {ProductFamilyModule} from "../productfamily/productfamily.module";
import {ProductCreateComponent} from "./product-create/product-create.component";
import {ProductDeleteComponent} from "./product-delete/product-delete.component";
import {ProductDisplayComponent} from "./product-display/product-display.component";
import {ProductListComponent} from "./product-list/product-list.component";
import {ProductManageComponent} from "./product-manage/product-manage.component";
import {ProductSelectComponent} from "./product-select/product-select.component";
import {NgModule} from "@angular/core";
import {SelectionModule} from "../../selection/selection.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {BaseDocumentModule} from "../../document/basedocument/base-document.module";

@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    ProductFamilyModule,
    ProductCommonModule,
    BaseDocumentModule,
    LocationModule,
    StorageModule,
    ImageModule,
    ProducerModule,
    LabelModule,
    CustomDataModule,
    SelectionModule
  ],
  providers: [],
  declarations: [
    ProductSelectComponent,
    ProductDisplayComponent,
    ProductDeleteComponent,
    ProductListComponent,
    ProductManageComponent,
    ProductCreateComponent,
  ],
  exports: [
    ProductSelectComponent,
    ProductDisplayComponent,
    ProductDeleteComponent,
    ProductListComponent,
    ProductManageComponent,
    ProductCreateComponent,
  ]
})
export class ProductModule {
}
