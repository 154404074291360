import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Storage, StorageService} from "./storage.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class StorageResolve implements Resolve<Storage> {
  constructor(private _bs: StorageService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Storage> {
    return this._bs.get(+route.params["storageId"]);
  }
}
