import {Producer, ProducerService} from "../producer.service";
import {Component, OnChanges} from "@angular/core";
import {BaseProducerSelectComponent} from "../../_producer/producer-select-base/producer-select-base.component";

@Component({
  selector: "lvadg-producer-select",
  templateUrl: "./producer-select.component.pug",
  styleUrls: ["./producer-select.component.sass"]
})
export class ProducerSelectComponent extends BaseProducerSelectComponent<Producer, ProducerService> implements OnChanges {
  constructor(public _service: ProducerService) {
    super(_service);
  }
}
