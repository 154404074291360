import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";
import {Producer} from "../../structure/producer/producer.service";
import {SeaProducer} from "../../structure/seaproducer/seaproducer.service";
import {Folder} from "../../document/folder/folder.service";
import {Provider} from "../../structure/provider/provider.service";


export class ArticleMarque extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "vvid",
    verboseName: "ID Vivalya",
    required: false,
    helpText: "ID Vivalya",
    minLength: 0,
    maxLength: 16,
    choices: []
  })
  public vvid!: string;

  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["MS", "Non précisé"]
    ]
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;

  @DSForeignKeyField({
    name: "producer",
    verboseName: "Producteur lvadg",
    required: false,
    helpText: "Producteur (lvadg)",
    relatedModel: "structure.Producer",
    remoteField: "id",
    relatedName: "articlemarque"
  })
  public producer!: number;
  public producer_details!: Producer;

  @DSForeignKeyField({
    name: "seaproducer",
    verboseName: "Producteur marée (lvadg)",
    required: false,
    helpText: "Producteur marée (lvadg)",
    relatedModel: "structure.SeaProducer",
    remoteField: "id",
    relatedName: "articlemarque"
  })
  public seaproducer!: number;
  public seaproducer_details!: SeaProducer;

  @DSForeignKeyField({
    name: "provider",
    verboseName: "Fournisseur référencé",
    required: false,
    helpText: "Fournisseur référencé",
    relatedModel: "structure.Provider",
    remoteField: "id",
    relatedName: "articlemarque"
  })
  public provider!: number;
  public provider_details!: Provider;

  @DSForeignKeyField({
    name: "folder",
    verboseName: "Dossier lié",
    required: false,
    helpText: "Dossier lié",
    relatedModel: "document.Folder",
    remoteField: "id",
    relatedName: "articlemarque"
  })
  public folder!: number;
  public folder_details!: Folder;

}

@Injectable({
  providedIn: "root"
})
export class ArticleMarqueService extends DSRestCollection<ArticleMarque> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/marques"};
  public model = ArticleMarque;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
