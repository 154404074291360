
<ng-container *ngIf="display==='view'">
  <bsac-breadcrumb></bsac-breadcrumb>
  <h1 class="page-header">
    <button class="btn btn-danger float-end" (click)="remove=!remove"><i class="fa fa-trash fa-s"></i><span class="hidden-sm">Supprimer</span></button>
    <button class="btn btn-warning mx-2 float-end" (click)="cloneArticle()"><i class="fa fa-copy me-2"></i><span class="hidden-sm">Cloner</span></button><i class="fa fa-book-open me-2"></i>[{{article?.vvid}}] {{article?.name}}<small>&nbsp; {{article?.libelle}}</small>
  </h1>
</ng-container>
<ng-container *ngIf="display==='content'">
  <h3 class="mt-2 mb-2"><i class="fa fa-book-open me-2"></i>[{{article?.vvid}}] {{article?.name}}<small>&nbsp; {{article?.libelle}}</small></h3>
</ng-container>
<div class="card my-3" *ngIf="remove">
  <div class="card-header bg-danger text-white"><i class="fa fa-warning me-2"></i>Supprimer l'article</div>
  <div class="card-body">
    <div class="text-danger">Seuls les articles "orphelins" (= non associés à un tarif, à des lignes de facturation ou à des articles catalogue externe) peuvent être supprimés</div>
    <button class="btn btn-outline-danger" (click)="deleteArticle()"><i class="fa fa-trash me-2"></i>Confirmer la suppression</button>
    <button class="ms-2 btn btn-outline-secondary" (click)="remove=false"><i class="fa fa-times me-2"></i>Annuler</button>
  </div>
</div>
<nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
  <ng-container ngbNavItem="show"><a ngbNavLink>Consultation article</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-article-details [article]="article" *ngIf="article"></lvadg-article-details>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="manage"><a ngbNavLink>Edition article</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-article-manage [article]="article" *ngIf="article" [articlefamily_detail_route]="articlefamily_detail_route"></lvadg-article-manage>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="externalarticles"><a ngbNavLink>Catalogue externe</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-externalarticle-list *ngIf="ready" [name]="currentRoute.name+'.ea'" [mode]="mode" [default_fields]="externalarticles_default_fields" [default_filters]="externalarticles_default_filters" [filter]="{articles: article.id}" [externalarticle_detail_route]="externalarticle_detail_route" [article_detail_route]="article_detail_route"></lvadg-externalarticle-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="articletariftemplates"><a ngbNavLink>Tarifs associés</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <pre class="todo">Vue en cours de construction, ne pas utiliser</pre>
        <lvadg-articletarif-list *ngIf="ready" [name]="currentRoute.name+'.ea'" [mode]="mode" [default_fields]="articletarifs_default_fields" [default_filters]="articletarifs_default_filters" [filter]="{articles: article.id}"></lvadg-articletarif-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="vivalyabillitems"><a ngbNavLink>Lignes de facturation</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-billitem-list *ngIf="ready" [name]="currentRoute.name+'.billitems'" [article]="article" [bill_detail_route]="bill_detail_route" [billitem_detail_route]="billitem_detail_route" [storage_detail_route]="storage_detail_route" [client_detail_route]="client_detail_route" [resto_detail_route]="resto_detail_route" [article_detail_route]="article_detail_route" [externalarticle_detail_route]="externalarticle_detail_route" [articlefamily_detail_route]="articlefamily_detail_route"></lvadg-billitem-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="rnmcandidates"><a ngbNavLink>Candidats RNM</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-rnm-article-details [article]="article"></lvadg-rnm-article-details>
      </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav"></div>