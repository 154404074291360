import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {BsacApiConstantsModule, BsacDevModule, BsacModelListModule, BsacUtilsModule} from "@solidev/bsadmincomponents";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {CompsModule} from "../lib/comps/comps.module";

export const VIEW_IMPORTS = [
  CommonModule,
  RouterModule,
  BsacModelListModule,
  BsacApiConstantsModule,
  BsacUtilsModule,
  BsacDevModule,
  NgbModule,
  CompsModule
];


