export const VVFL_SCHEME_1 = ["#a0d5d2", "#76c3c2", "#58b9b8", "#00b0ae"];
export const VVFL_SCHEME_2 = [
  "#00b1b5",
  "rgb(182, 225, 227)",
  "rgb(0, 179, 174)",
  "rgb(115, 205, 202)",
  "rgb(40, 192, 187)",
  "rgb(115, 205, 202)",
  "rgb(0, 185, 180)",
  "rgb(139, 211, 210)",
  "rgb(85, 199, 195)",
  "rgb(162, 220, 216)",
  "rgb(40, 192, 187)",
];

export const VVSEA_SCHEME_1 = ["#d6eaf8", "#aed6f1", "#85c1e9", "#5dade2"];


export const VV_SCHEMES = {
  fl: VVFL_SCHEME_1,
  sea: VVSEA_SCHEME_1
};
