import {Component, Input} from "@angular/core";
import {Relation, RelationService} from "../relation.service";
import {BsacApiConstantsService, BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {Client, ClientService} from "../../client/client.service";
import {Resto, RestoService} from "../../resto/resto.service";
import {Member, MemberService} from "../../member/member.service";
import {Producer, ProducerService} from "../../producer/producer.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {Storage, StorageService} from "../../storage/storage.service";
import {SeaProducer, SeaProducerService} from "../../seaproducer/seaproducer.service";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {Provider, ProviderService} from "../../provider/provider.service";
import {firstValueFrom} from "rxjs";


@Component({
  selector: "lvadg-relation-list",
  templateUrl: "./relation-list.component.pug",
  styleUrls: ["./relation-list.component.sass"]
})
export class RelationListComponent extends ModelListBaseComponent<Relation, RelationService> {

  @Input() public relation!: Relation;
  @Input() public storage!: Storage;
  @Input() public member!: Member;
  @Input() public resto!: Resto;
  @Input() public client!: Client;
  @Input() public provider!: Provider;
  @Input() public storages_manage = false;

  @Input() public detail_route!: RouteV3<Relation>;
  @Input() public resto_detail_route!: RouteV3<Resto>;
  @Input() public member_detail_route!: RouteV3<Member>;
  @Input() public storage_detail_route!: RouteV3<Storage>;
  @Input() public client_detail_route!: RouteV3<Client>;
  @Input() public producer_detail_route!: RouteV3<Producer>;
  @Input() public seaproducer_detail_route!: RouteV3<SeaProducer>;
  @Input() public provider_detail_route!: RouteV3<Provider>;

  public members$!: DSRestQueryset<Member>;
  public storages$!: DSRestQueryset<Storage>;
  public clients$!: DSRestQueryset<Client>;
  public restos$!: DSRestQueryset<Resto>;
  public producers$!: DSRestQueryset<Producer>;
  public seaproducers$!: DSRestQueryset<SeaProducer>;
  public providers$!: DSRestQueryset<Provider>;
  public dispDistanceUnit = true;
  constructor(_list: BsacModelListProvider,
              _items: RelationService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              private _members: MemberService,
              private _storages: StorageService,
              private _clients: ClientService,
              private _restos: RestoService,
              private _producers: ProducerService,
              private _seaproducers: SeaProducerService,
              private _providers: ProviderService,
              public _cstt: BsacApiConstantsService) {
    super(_list, _items, _msgs, routes);
    this.type = "relations";
    this.custom_fields = [
      {name: "storage_address", verbose_name: "Adresse dépôt"},
      {name: "storage_city", verbose_name: "Ville dépôt"},
      {name: "storage_postcode", verbose_name: "CP dépôt"},
      {name: "storage_geoloc", verbose_name: "Géoloc dépôt"},
      {name: "storage_geoloc_status", verbose_name: "Etat géoloc dépôt"},
      {name: "resto_address", verbose_name: "Adresse resto"},
      {name: "resto_city", verbose_name: "Ville resto"},
      {name: "resto_postcode", verbose_name: "CP resto"},
      {name: "resto_geoloc", verbose_name: "Géoloc resto"},
      {name: "resto_geoloc_status", verbose_name: "Etat géoloc resto"},
      {name: "resto_cvva", verbose_name: "CVVA resto"},
      // {name: "producer_address", verbose_name: "Adresse producteur"},
      // {name: "producer_city", verbose_name: "Ville producteur"},
      // {name: "producer_postcode", verbose_name: "CP producteur"},
      // {name: "producer_geoloc", verbose_name: "Géoloc producteur"},
      // {name: "seaproducer_address", verbose_name: "Adresse producteur marée"},
      // {name: "seaproducer_city", verbose_name: "Ville producteur marée"},
      // {name: "seaproducer_postcode", verbose_name: "CP producteur marée"},
      // {name: "seaproducer_geoloc", verbose_name: "Géoloc producteur marée"},
      // {name: "provider_address", verbose_name: "Adresse fournisseur"},
      // {name: "provider_city", verbose_name: "Ville fournisseur"},
      // {name: "provider_postcode", verbose_name: "CP fournisseur"},
      // {name: "provider_geoloc", verbose_name: "Géoloc fournisseur"}
    ]
  }

  public async preNgOnInit(): Promise<void> {
    this.members$ = this._members.queryset;
    this.storages$ = this._storages.queryset;
    this.clients$ = this._clients.queryset;
    this.restos$ = this._restos.queryset;
    this.producers$ = this._producers.queryset;
    this.seaproducers$ = this._seaproducers.queryset;
    this.providers$ = this._providers.queryset;
  }


  public async changeStatus(relation: Relation): Promise<void> {
    const rels = ["OK", "KO", "OM", "KM"]
    const idx = rels.indexOf(relation.status);
    if (idx === -1) {
      relation.status = "OK";
    } else {
      relation.status = rels[(idx+1) % rels.length]
    }
    await firstValueFrom(relation.update(["status"]));
  }

  public getDetailLink(model: Relation): (string | number)[] {
    if (this.detail_route) {
      return this.detail_route.route({relation: model});
    }
    return this.routes.get(this.zone, "relation", model);
  }

}
