import {GeoCommuneService} from "../../../customdata/geocommune/geocommune.service";
import {GeoDepartementService} from "../../../customdata/geodepartement/geodepartement.service";
import {GeoRegionService} from "../../../customdata/georegion/georegion.service";
import {MemberService} from "../../../structure/member/member.service";
import {Producer, ProducerService} from "../../../structure/producer/producer.service";
import {StorageService} from "../../../structure/storage/storage.service";
import {LabelService} from "../../label/label.service";
import {ProductFamily, ProductFamilyService} from "../../productfamily/productfamily.service";
import {Product, ProductService} from "../product.service";
import {Component} from "@angular/core";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RestoService} from "../../../structure/resto/resto.service";
import {BaseProductListComponent} from "../../_product/product-list-base/product-list-base.component";
import {RoutesService} from "../../../../comps/routes.service";
import {DistanceService} from "../../../structure/location/distance.service";
import {CustomDataKeyService} from "../../../customdata/customdatakey/customdatakey.service";

@Component({
  selector: "lvadg-product-list",
  templateUrl: "./product-list.component.pug",
  styleUrls: ["./product-list.component.sass"]
})
export class ProductListComponent extends BaseProductListComponent<Product, ProductService, ProductFamily, ProductFamilyService,
  Producer, ProducerService, LabelService> {


  constructor(
    _list: BsacModelListProvider,
    _items: ProductService,
    _msgs: BsacMessageService,
    routes: RoutesService,
    _producers: ProducerService,
    _members: MemberService,
    _storages: StorageService,
    _families: ProductFamilyService,
    _regions: GeoRegionService,
    _departements: GeoDepartementService,
    _labels: LabelService,
    _communes: GeoCommuneService,
    _restos: RestoService,
    _distances: DistanceService,
    _customdatakeys: CustomDataKeyService) {
    super(_list, _items, _msgs, routes,
      _producers, _members, _storages, _families, _regions, _departements, _labels, _communes, _restos, _distances, _customdatakeys);
    this.zone = "fl";
  }
}

