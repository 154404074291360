import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDecimalField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";


export class CiqualColumn extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSCharField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 10,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public name!: string;

  @DSCharField({
    name: "group",
    verboseName: "Groupe",
    required: false,
    helpText: "Group",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public group!: string;


  @DSCharField({
    name: "unit",
    verboseName: "Unité",
    required: false,
    helpText: "Unité (pour 100g)",
    minLength: 0,
    maxLength: 5,
    choices: []
  })
  public unit!: string;

  @DSIntegerField({
    name: "order",
    verboseName: "Ordre",
    required: false,
    helpText: "Ordre (décroissant)",
    default: 0,
    choices: []
  })
  public order!: number;

  @DSDecimalField({
    name: "ajr",
    verboseName: "AJR",
    required: false,
    helpText: "AJR",
    default: 0,
    factor: 100000,
    choices: []
  })
  public ajr!: number;

  @DSDecimalField({
    name: "maxjr",
    verboseName: "Max JR",
    required: false,
    helpText: "Max JR",
    default: 0,
    factor: 100000,
    choices: []
  })
  public maxjr!: number;

  @DSTextField({
    name: "desc",
    verboseName: "Description",
    required: false,
    helpText: "Description",
    minLength: 0,
    maxLength: 5000,
    choices: []
  })
  public desc!: string;

  @DSCharField({
    name: "display",
    verboseName: "Affichage",
    required: false,
    helpText: "Affichage",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["SS", "Starred short"],
      ["ST", "Starred"],
      ["NM", "Normal"],
      ["HD", "Hidden"]
    ]
  })
  public display!: string;


}

@Injectable({
  providedIn: "root"
})
export class CiqualColumnService extends DSRestCollection<CiqualColumn> {
  public adapter_config = {basePath: "/api/v2/ciqualcolumns"};
  public model = CiqualColumn;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
