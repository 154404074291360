import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Resto} from "../../../structure/resto/resto.service";
import {Client} from "../../../structure/client/client.service";
import {Storage} from "../../../structure/storage/storage.service";
import {Member} from "../../../structure/member/member.service";
import {Contact, ContactService} from "../contact.service";
import {UntypedFormGroup} from "@angular/forms";
import {firstValueFrom, Subject} from "rxjs";
import {TarifType} from "../../../tarif/tariftype/tariftype.service";
import {Person, PersonService} from "../../person/person.service";

@Component({
  selector: "lvadg-contact-manage-form",
  templateUrl: "./contact-manage-form.component.pug",
  styleUrls: ["./contact-manage-form.component.sass"]
})
export class ContactManageFormComponent implements OnInit, OnDestroy {
  @Input() public resto!: Resto;
  @Input() public client!: Client;
  @Input() public storage!: Storage;
  @Input() public member!: Member;
  @Input() public contact!: Contact;
  @Input() public tarifType!: TarifType;
  @Input() public searchUser = false;
  @Input() public type: "TR" | "OC" = "OC";
  @Output() public created = new EventEmitter<Contact>();
  @Output() public updated = new EventEmitter<Contact>();
  public contactForm = new UntypedFormGroup({});
  private _subscriptions = new Subject<void>();

  constructor(private _contacts: ContactService,
              public users$: PersonService) {
  }

  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public async ngOnInit(): Promise<void> {
    if (!this.contact) {
      this.contact = await firstValueFrom(this._contacts.create({
        title: "X"
      }, {save: false}));
    }

  }

  public async saveContact() {
    if (!this.contact.save) {
      this.contact = await firstValueFrom(this._contacts.create(this.contact, {save: false}));
    }
    this.contact.assign(this.contactForm.value);
    this.contact.type = this.type;
    if (this.tarifType && this.type === "TR") {
      this.contact.tarif = this.tarifType.id;
    }
    this.contact.status = "OK";
    if (!this.contact.id) {
      await firstValueFrom(this.contact.save());
      this.created.next(this.contact);
    } else {
      await firstValueFrom(this.contact.update());
      this.updated.next(this.contact);
    }
  }


  selectedUser(p: Person) {
    this.contactForm.patchValue({
      firstname: p.firstname,
      email: p.email,
      lastname: p.lastname
    });

  }
}
