<!DOCTYPE html>
<ng-template #confirmDeleteDialog>
  <p class="text-danger" *ngIf="mode==='delete'">Confirmer la suppression ?</p>
  <p class="text-danger" *ngIf="mode==='action'">Confirmer cette action ?</p>
  <button class="btn btn-sm btn-danger btn-block w-100" (click)="confirmDelete()">
    <ng-container *ngIf="mode==='delete'">Supprimer !</ng-container>
    <ng-container *ngIf="mode==='action'">Je confirme !</ng-container>
  </button>
  <button class="btn btn-sm btn-primary btn-block w-100" (click)="cancelDelete()">Annuler</button>
</ng-template>
<button class="btn" *ngIf="type=='button'" [ngbPopover]="confirmDeleteDialog" placement="auto" [ngClass]="classes" [popoverTitle]="mode==='delete' ? 'Supprimer ?':'Confirmer ?'" #confirmDeletePopover="ngbPopover">
  <ng-content></ng-content>
</button><i class="fa fa-trash text-danger fa-fw pointer" *ngIf="type=='trash'" [ngbPopover]="confirmDeleteDialog" placement="auto" [popoverTitle]="mode==='delete' ? 'Supprimer ?':'Confirmer ?'" #confirmDeletePopover="ngbPopover"></i>