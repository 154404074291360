import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {AuthService} from "../models/user/auth.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {RoutesService} from "../comps/routes.service";

export const BYPASS_403_URLS: string[] = [
  "/auth"
];


@Injectable({
  providedIn: "root"
})
/**
 * Intercept all 403 errors.
 * - if user is not authenticated, redirects to /p/login
 * - if user is already authenticated, tries to refresh toke
 * - if not token refresh successful, redirects to login
 * - if token refresh is successful, retries operation
 * - if operation still fails (same url), throw error and display error / debug message
 */
export class Http403Interceptor implements HttpInterceptor {
  private tried: string[] = [];

  constructor(private routes: RoutesService,
              private auth: AuthService,
              private msgs: BsacMessageService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err, caught) => {
        if (err.status !== 403) {
          return throwError(err);
        }
        if (this._bypassUrls(err.url)) {
          return throwError(err);
        } else {
          if (!this.auth.isAuthenticated) {
            this.msgs.info("Vous n'êtes pas identifié, merci de vous connecter");
            this.routes.navList("pub", "login");
          }
          return this.auth.tokenRefresh().pipe(
            switchMap((result) => {
              if (result.authenticated) {
                if (this.tried.indexOf(req.url) === -1) {
                  this.tried.push(req.url);
                  return caught;
                } else {
                  this.msgs.error("Opération invalide",
                    "ceci n'aurait pas dû arriver, vous pouvez le signaler à " +
                    "Javier OROZCO en précisant le contenu suivant : " + req.url);
                  return throwError(err);
                }
              } else {
                this.msgs.info("Session expirée ou invalide, merci de vous reconnecter");
                this.routes.navList("pub", "login");
              }
              return throwError(err);
            }));

        }

      })
    );
  }

  /**
   * Check if url should be bypassed by 403 interceptor.
   * @param url url to check
   * @return true if url must be bypassed
   */
  private _bypassUrls(url: string): boolean {
    for (const u of BYPASS_403_URLS) {
      if (url.search(u) > -1) {
        return true;
      }
    }
    return false;
  }
}
