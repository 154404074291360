import {Component, Input, OnInit} from "@angular/core";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {Member} from "../../member/member.service";
import {Storage} from "../../storage/storage.service";
import {Producer} from "../../producer/producer.service";
import {Client} from "../../client/client.service";
import {Resto} from "../../resto/resto.service";
import {first, switchMap} from "rxjs/operators";
import {SLabel, SLabelService} from "../slabel.service";
import {Provider} from "../../provider/provider.service";


@Component({
  selector: "lvadg-slabel-setunset",
  templateUrl: "./slabel-setunset.component.pug",
  styleUrls: ["./slabel-setunset.component.sass"]
})
export class SlabelSetunsetComponent implements OnInit {
  @Input() public model!: Member | Storage | Resto | Producer | Client | Provider;
  public labels: SLabel[] = [];

  constructor(private _msgs: BsacMessageService, private _slabels: SLabelService) {
  }

  public ngOnInit(): void {
    this._slabels.prefetch().pipe(first()).subscribe((labels) => {
      for (const k of Object.keys(labels)) {
        this.labels.push(labels[+k]);
      }
    });
  }

  public haveLabel(label: SLabel): boolean {
    return this.model.slabels.indexOf(label.id) >= 0;
  }

  public setLabel(label: SLabel): void {
    this.model.action("set_slabel", {method: "POST", body: {slabel: label.id}}).pipe(
      switchMap(() => {
        return this.model.refresh({query: {psall: 1}});
      })).subscribe(() => {
      this._msgs.success("Label structure ajouté avec succès");
    });
  }

  public unsetLabel(label: SLabel): void {
    this.model.action("unset_slabel", {method: "POST", body: {slabel: label.id}}).pipe(
      switchMap(() => {
        return this.model.refresh({query: {psall: 1}});
      })).subscribe(() => {
      this._msgs.success("Label structure supprimé avec succès");
    });
  }
}
