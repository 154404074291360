import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleTraitement, ArticleTraitementService} from "../article-traitement.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "lvadg-article-traitements-manage",
  templateUrl: "./article-traitements-manage.component.pug",
  styleUrls: ["./article-traitements-manage.component.sass"]
})
export class ArticleTraitementsManageComponent extends ArticleDataManageBaseComponent<ArticleTraitement, ArticleTraitementService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleTraitementService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute) {
    super(_list, _items, _msgs, routes, route);
    this.type = "articledata.traitements";
    this.paramId = "traitementId";
    this.filterField = "traitement";
    this.type = "articledata.traitements";
  }
}
