import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  ExternalArticleCollection,
  ExternalArticleCollectionService
} from "../../externalarticlecollection/externalarticlecollection.service";
import {Provider, ProviderService} from "../../../structure/provider/provider.service";
import {TarifType, TarifTypeService} from "../../../tarif/tariftype/tariftype.service";
import {Member, MemberService} from "../../../structure/member/member.service";
import {Client, ClientService} from "../../../structure/client/client.service";
import {UntypedFormGroup} from "@angular/forms";
import {ExternalArticle, ExternalArticleService, IExternalArticleType} from "../externalarticle.service";
import {StorageService} from "../../../structure/storage/storage.service";
import {ArticleService} from "../../article/article.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-externalarticle-create",
  templateUrl: "./externalarticle-create.component.pug",
  styleUrls: ["./externalarticle-create.component.sass"]
})
export class ExternalarticleCreateComponent implements OnInit {
  @Input() public type?: IExternalArticleType;
  @Input() public link?: Client | Member | Storage | TarifType | Provider | ExternalArticleCollection;
  public form = new UntypedFormGroup({});
  public ea!: ExternalArticle;
  @Output() public created = new EventEmitter<ExternalArticle>();
  @Output() public cancelled = new EventEmitter<void>();


  constructor(private ea$: ExternalArticleService,
              public clients$: ClientService,
              public storages$: StorageService,
              public members$: MemberService,
              public tariftypes$: TarifTypeService,
              public providers$: ProviderService,
              public articles$: ArticleService,
              public eacolls$: ExternalArticleCollectionService) {
  }

  ngOnInit(): void {
    this.ea = new ExternalArticle(this.ea$);
    if (this.type) {
      this.ea.type = this.type;
      if (this.link && this.link.id) {
        switch (this.type) {
          case "ADG":
            this.ea.collection = this.link.id;
            this.ea.collection_details = this.link as ExternalArticleCollection;
            break;
          case "CLB":
            this.ea.tariftype = this.link.id;
            this.ea.tariftype_details = this.link as TarifType;
            break;
          case "CLI":
            this.ea.client = this.link.id;
            this.ea.client_details = this.link as Client;
            break;
          case "STO":
            this.ea.storage = this.link.id;
            this.ea.storage_details = this.link as any;
            break;
          case "PRO":
            this.ea.provider = this.link.id;
            this.ea.provider_details = this.link as Provider;
            break;
          case "MEM":
            this.ea.member = this.link.id;
            this.ea.member_details = this.link as Member;
            break;
        }
      }
    }
    this.form.valueChanges.subscribe((vals) => {
      this.ea.type = vals.type;
    });
  }

  public async create(): Promise<void> {
    console.log("Create");
    this.ea.status = "OUT";
    this.ea.articles = [];
    this.ea.assign(this.form.value);
    await firstValueFrom(this.ea.save())
    this.created.emit(this.ea);
  }
}
