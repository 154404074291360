import {Offer} from "../offer.service";
import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from "@angular/core";
import {Product} from "../../../product/product/product.service";
import {NgbDate, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ModelPrint, ModelPrintService} from "../../../comm/print/modelprint.service";
import {firstValueFrom, Subject} from "rxjs";
import {SelectionService} from "../../../selection/selection.service";
import {map} from "lodash-es";
import {BsacMessageService, ISorterData} from "@solidev/bsadmincomponents";
import {Member} from "../../../structure/member/member.service";
import {Router} from "@angular/router";
import {RoutesService} from "../../../../comps/routes.service";
import {SeaProduct} from "../../../product/seaproduct/seaproduct.service";
import {Resto} from "../../../structure/resto/resto.service";
import {Client} from "../../../structure/client/client.service";
import {ICustomSelectAction} from "../../../../../includes/services/action";
import {FeaturesService} from "../../../customdata/features/features.service";

@Component({
  selector: "lvadg-offer-manage",
  templateUrl: "./offer-manage.component.pug",
  styleUrls: ["./offer-manage.component.sass"]
})
export class OfferManageComponent implements OnChanges {
  @Input() public offer!: Offer;
  @Input() public member!: Member;
  @Input() public resto!: Resto;
  @Input() public client!: Client;
  @Input() public name!: "lvadg-offer-manage";
  @Input() public mode = "member";
  @Input() public zone = "fl";
  @Input() public add_select_actions!: string[];
  @Input() public add_select_custom_actions!: ICustomSelectAction[];
  @Input() public add_actions!: string[];
  @Output() public add_on_action = new EventEmitter<any>();
  @Input() public list_select_actions!: string[];
  @Input() public list_select_custom_actions!: ICustomSelectAction[];
  @Input() public list_actions!: string[];
  @Output() public list_on_action = new EventEmitter<any>();

  public clone = false;

  public productAddDefaultFields = ["select", "name", "labels", "seasons", "producer_details", "storages_details", "actions"];
  public productAddAllowedFields = ["select", "id", "name", "labels", "producer_details",
    "storages_details", "seasons", "actions", "labels"];
  public productAddDefaultFilters = ["search", "producers", "families"];
  public productAddAllowedFilters: string[] = [];
  public productAddActions = ["select", "offer_add", "offer_remove", "offer_manage"];
  public productAddSelectActions: string[] = ["clear", "summary", "offer_addmany"];
  public productAddSelectCustomActions: ICustomSelectAction[] = [{
    name: "offer_addmany",
    description: "Ajouter à l'offre locale",
    icon: "fa-plus",
    route: ""
  }];
  public productListDefaultFields = ["select", "name", "producer_details", "labels", "offer_details", "storages_details", "actions"];
  public productListAllowedFields = ["id", "select", "name", "producer_details", "labels", "seasons",
    "offer_details", "storages_details", "actions"];
  public productListDefaultFilters = ["search", "producers", "families"];
  public productListAllowedFilters: string[] = [];
  public productListActions = ["offer_remove", "offer_manage"];
  public productListSelectActions: string[] = [];
  public productListSelectCustomActions: ICustomSelectAction[] = [];
  public productListSorterField: ISorterData = [
    {field: "offers__order", direction: "desc"},
    {field: "name", direction: "asc"}
  ];
  public dispAddResto = false;
  public dispAddProduct!: NgbModalRef;
  public addProduct!: Product;
  public dispManageProduct!: NgbModalRef;
  public manageProduct!: Product;
  public dispRemoveProduct!: NgbModalRef;
  public removeProduct!: Product;
  public dispManageRestos!: NgbModalRef;

  public reloadProducts$ = new Subject<void>();

  public params: any = {};
  public edit = {};
  public print!: ModelPrint;


  @ViewChild("addProductModal") addModal: ElementRef;
  @ViewChild("manageProductModal") manageModal: ElementRef;
  @ViewChild("removeProductModal") removeModal: ElementRef;
  @ViewChild("manageRestosModal") restosModal: ElementRef;

  constructor(private _modal: NgbModal,
              private _router: Router,
              private _sel: SelectionService,
              private _mp: ModelPrintService,
              private _msgs: BsacMessageService,
              public routes: RoutesService,
              public features: FeaturesService) {
  }

  public async ngOnChanges(): Promise<void> {
    this.clone = false;
    this._sel.clearSelection("product");

    // Read only view
    if (!this.checkMode("admin", "member")) {
      this.productListActions = [];
      if (this.list_select_actions || this.list_select_custom_actions) {
        this.productListDefaultFields = ["select", "name", "producer_details", "labels", "offer_details", "actions"];
      } else {
        this.productListDefaultFields = ["name", "producer_details", "labels", "offer_details"];
      }
    }

    // Merge custom actions from outside with offer customactions
    if (this.list_actions) {
      this.productListActions = this.productListActions.concat(this.list_actions);
    }
    if (this.list_select_actions) {
      this.productListSelectActions = this.productListSelectActions.concat(this.list_select_actions);
    }
    if (this.list_select_custom_actions) {
      this.productListSelectCustomActions = this.productListSelectCustomActions.concat(this.list_select_custom_actions);
    }

    if (this.add_actions) {
      this.productAddActions = this.productAddActions.concat(this.add_actions);
    }
    if (this.add_select_actions) {
      this.productAddSelectActions = this.productAddSelectActions.concat(this.add_select_actions);
    }
    if (this.add_select_custom_actions) {
      this.productAddSelectCustomActions = this.productAddSelectCustomActions.concat(this.add_select_custom_actions);
    }


    this.print = await firstValueFrom(this._mp.create(
      await firstValueFrom(this.offer.action(
        "print",
        {
          method: "POST",
          body: {}
        }))));
  }


  public async offerProductActions(mode: string,
                                   actdata: { action: string, product: Product | SeaProduct, items?: Product[] }): Promise<void> {
    if (mode === "list" && this.list_on_action) {
      this.list_on_action.next(actdata);
    }
    if (mode === "add" && this.add_on_action) {
      this.add_on_action.next(actdata);
    }


    if (actdata.action === "offer_add") {
      // Add product
      this.addProduct = actdata.product;
      await this.offer.action(this.zone === "fl" ? "add_product" : "add_seaproduct", {
        method: "POST",
        body: {product: actdata.product.id}
      }).toPromise();
      // this.dispAddProduct = this._modal.open(this.addModal, {size: "lg", centered: false});
      // try {
      //   await this.dispAddProduct.result;
      // } catch {
      // }
      actdata.product.inoffer = true;
      this.reloadProducts$.next();
    } else if (actdata.action === "offer_remove") {
      // Remove product
      this.removeProduct = actdata.product;
      this.dispRemoveProduct = this._modal.open(this.removeModal, {centered: true});
      try {
        await this.dispRemoveProduct.result;
        await this.offer.action(this.zone === "fl" ? "remove_product" : "remove_seaproduct",
          {method: "POST", body: {product: actdata.product.id}}).toPromise();
        actdata.product.inoffer = false;
        this.reloadProducts$.next();
      } catch {
      }
    } else if (actdata.action === "offer_manage") {
      // Manage product details
      this.manageProduct = actdata.product;
      this.dispManageProduct = this._modal.open(this.manageModal, {size: "lg", centered: false});
      try {
        await this.dispManageProduct.result;
      } catch {
      }
      this.reloadProducts$.next();
    } else if (actdata.action === "offer_addmany") {
      // Add multiple products from selection
      await this.offer.action(this.zone === "fl" ? "add_product" : "add_seaproduct", {
        method: "POST",
        body: {products: map(actdata.items, "id")}
      }).toPromise();
      for (const p of actdata.items) {
        p.inoffer = true;
      }
      this._sel.clearSelection("product");
      this.reloadProducts$.next();
    }
  }

  public async manageRestos(): Promise<void> {
    this.dispManageRestos = this._modal.open(this.restosModal, {size: "lg", centered: false});
    try {
      await this.dispManageRestos.result;
    } catch {
    }
  }

  // noinspection JSUnusedLocalSymbols
  public addedProduct(data: any): void {
  }

  public async changeDate(range: { from: NgbDate, to: NgbDate }) {
    if (!this.checkMode("admin", "member")) {
      return;
    }
    this.offer.datestart = new Date(Date.UTC(range.from.year, range.from.month - 1, range.from.day, 6, 0, 0));
    this.offer.dateend = new Date(Date.UTC(range.to.year, range.to.month - 1, range.to.day, 21, 0, 0));
    await this.offer.update(["datestart", "dateend"]).toPromise();
    this._msgs.success("Dates de l'offre changées avec succès");
  }

  public cloned(offerId: number): void {
    this._router.navigate(this.routes.detail(this.zone, "offers", offerId));
  }

  public checkMode(...args: string[]): boolean {
    return args.indexOf(this.mode) !== -1;
  }

  public async refresh() {
    this.print = await this._mp.create(
      await this.offer.action(
        "print",
        {
          method: "POST",
          body: {}
        }).toPromise()
    ).toPromise();
  }
}
