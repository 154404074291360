
<p class="text-info" *ngIf="existing">Modifier le message existant</p>
<p class="text-info" *ngIf="!existing">Créer un nouveau message</p>
<p class="text-danger" *ngIf="multiple">Multiples messages trouvés, ce n'est pas normal, merci de le signaler</p>
<form class="row" [formGroup]="form" *ngIf="tn" (submit)="save()">
  <div class="col-sm-6">
    <div class="form-group">
      <label>Titre de la notification</label>
      <bsac-field-control [form]="form" [model]="tn" field="title" data-cy="tarifnotification-title-input"></bsac-field-control>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="form-group">
      <label>Priorité</label>
      <bsac-field-control [form]="form" [model]="tn" field="priority" data-cy="tarifnotification-priority-input"></bsac-field-control>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="row g-1">
      <div class="col-12 col-lg-4">
        <button class="btn btn-sm btn-outline-primary w-100 h-100" type="button" (click)="getDiff()" data-cy="tarifnotification-list-modifs-button">Lister les modifications du tarif</button>
      </div>
      <div class="col-12 col-lg-4">
        <button class="btn btn-sm btn-outline-primary w-100 h-100" type="button" [class.btn-outline-secondary]="template==''" [disabled]="template==''" (click)="useTemplate()" data-cy="tarifnotification-use-template">Utiliser mon modèle</button>
      </div>
      <div class="col-12 col-lg-4">
        <button class="btn btn-sm btn-outline-primary w-100 h-100" type="button" (click)="saveTemplate()" data-cy="tarifnotification-save-template">Enregistrer comme modèle</button>
      </div>
    </div>
    <div class="form-group">
      <label>Message de notification</label>
      <quill-editor class="d-block" formControlName="message" [modules]="quillModules"></quill-editor>
    </div>
  </div>
</form>
<div class="mt-4" [class.text-center]="!cancel" [class.text-right]="cancel">
  <button class="btn btn-outline-success px-4 me-4" (click)="save()" data-cy="tarifnotification-save-message"><i class="fa fa-save me-2"></i>Enregistrer le message</button>
  <button class="btn btn-outline-warning" (click)="cancelled.emit()" *ngIf="cancel">Annuler</button>
</div>