import {NgModule} from "@angular/core";
import {GeoDepartementCreateComponent} from "./geodepartement-create/geodepartement-create.component";
import {GeoDepartementDeleteComponent} from "./geodepartement-delete/geodepartement-delete.component";
import {GeoDepartementDisplayComponent} from "./geodepartement-display/geodepartement-display.component";
import {GeoDepartementListComponent} from "./geodepartement-list/geodepartement-list.component";
import {GeoDepartementSelectComponent} from "./geodepartement-select/geodepartement-select.component";
import {GeoDepartementManageComponent} from "./geodepartement-manage/geodepartement-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS
  ],
  declarations: [
    GeoDepartementSelectComponent,
    GeoDepartementDisplayComponent,
    GeoDepartementDeleteComponent,
    GeoDepartementListComponent,
    GeoDepartementManageComponent,
    GeoDepartementCreateComponent
  ],
  exports: [
    GeoDepartementSelectComponent,
    GeoDepartementDisplayComponent,
    GeoDepartementDeleteComponent,
    GeoDepartementListComponent,
    GeoDepartementManageComponent,
    GeoDepartementCreateComponent
  ],
})
export class GeoDepartementModule {
}
