import {Component} from "@angular/core";
import {DetailDocument, DetailDocumentService} from "../basedocument.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {RoutesService} from "../../../../comps/routes.service";
import {Member} from "../../../structure/member/member.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {Provider, ProviderService} from "../../../structure/provider/provider.service";
import {Article, ArticleService} from "../../../catalog/article/article.service";
import {Producer, ProducerService} from "../../../structure/producer/producer.service";
import {Storage, StorageService} from "../../../structure/storage/storage.service";
import {ArticleFamily} from "../../../catalog/family/article-family.service";
import {LabelService} from "../../../product/label/label.service";

@Component({
  selector: "lvadg-detaildocument-list",
  templateUrl: "./detaildocument-list.component.pug",
  styleUrls: ["./detaildocument-list.component.sass"]
})
export class DetailDocumentListComponent extends ModelListBaseComponent<DetailDocument, DetailDocumentService> {

  public labels!: { value: number, desc: string }[];
  public provider$!: DSRestQueryset<Provider>;
  public producer$!: DSRestQueryset<Producer>;
  public storage$!: DSRestQueryset<Storage>;
  public article$!: DSRestQueryset<Article>;
  public articlefamilies$!: DSRestQueryset<ArticleFamily>;
  public members$!: DSRestQueryset<Member>;
  public EXPIRATIONS = [
    {desc: "Expirés depuis 1 mois", value: -31},
    {desc: "Expirés depuis 15 jours", value: -15},
    {desc: "Expirés depuis 1 semaine", value: -7},
    {desc: "Actuellement expirés", value: 0},
    {desc: "Expirant la semaine prochaine", value: 7},
    {desc: "Expirant dans la quinzaine", value: 15},
    {desc: "Expirant dans le mois", value: 31}
  ];

  constructor(_list: BsacModelListProvider,
              _items: DetailDocumentService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              private _storages: StorageService,
              private _producers: ProducerService,
              private _providers: ProviderService,
              private _articles: ArticleService,
              private _labels: LabelService) {
    super(_list, _items, _msgs, routes);
    this.type = "detaildocuments";
  }

  async preNgOnInit(): Promise<void> {
    this.labels = await this._labels.getCachedValues().toPromise();
    return super.preNgOnInit();
  }

  public async postNgOnInit(): Promise<void> {
    this.filter.rimgszs = "thumb";
    this.article$ = this._articles.queryset;
    this.provider$ = this._providers.queryset;
    this.producer$ = this._producers.queryset;
    this.storage$ = this._storages.queryset;
    this.list.emitFields = true;
  }

  public getRowClasses(model: DetailDocument): { [p: string]: string | boolean } {
    const cl = super.getRowClasses(model);
    if (model.status === "PRE1" || model.status === "PRE2") {
      cl["table-warning"] = true;
    }
    if (model.status === "ARCN") {
      cl["table-danger"] = true;
    }

    if (model.status === "ARCM") {
      cl["text-muted"] = true;
    }
    if (model.status === "OK") {
      cl["table-success"] = true;
    }

    return cl;
  }
}
