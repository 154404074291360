import {Component, Input, OnInit} from "@angular/core";
import {BsacModelList, IBsacApiConstants} from "@solidev/bsadmincomponents";
import {CustomDataKey, CustomDataKeyService} from "../../customdatakey/customdatakey.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-customdata-modellist-filter-choices",
  templateUrl: "./customdata-modellist-filter-choices.component.pug",
  styleUrls: ["./customdata-modellist-filter-choices.component.sass"]
})
export class CustomdataModellistFilterChoicesComponent implements OnInit {

  @Input() public field!: string;
  @Input() public name!: string;
  @Input() public list!: BsacModelList<any>;
  @Input() public label!: string;
  @Input() public filtersetName!: string;

  @Input() public dest!: string;
  @Input() public code!: string;

  public key!: CustomDataKey|null;
  public choices: IBsacApiConstants = [];

  constructor(private _cd: CustomDataKeyService) {
  }

  public async ngOnInit(): Promise<void> {
    this.key = await firstValueFrom(this._cd.getByDestCode(this.dest, this.code));
    if (this.key) {
      this.field = `customdata_${this.key.id}`;
      for (const ko of this.key.choices_details) {
        this.choices.push({desc: ko.name, value: ko.code});
      }
    } else {
      console.error("No customdatakey for this dest/code", this.dest, this.code);
    }
  }

}
