import {Component, Input, OnInit} from "@angular/core";
import {Tarif} from "../../tarif/tarif.service";
import {ArticleTarif} from "../articletarif.service";
import {combineLatest, Observable, Subject} from "rxjs";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {map, switchMap, throttleTime} from "rxjs/operators";

interface StatSlot {
  name: string;
  type: string;
  label: string;
  classes: string[];
}

@Component({
  selector: "lvadg-articletarif-stats",
  templateUrl: "./articletarif-stats.component.pug",
  styleUrls: ["./articletarif-stats.component.sass"]
})
export class ArticletarifStatsComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public current$!: Observable<ArticleTarif>;
  public reload$ = new Subject<boolean>();
  public current!: ArticleTarif;
  public stats!: any;
  public slotForm = new UntypedFormGroup({slot: new UntypedFormControl("")});
  public slots: StatSlot[] = [{name: "tarifs_marches", type: "tarifsmarches", label: "Tarifs des marchés (tableau)", classes: ["col-12"]}];
  public stats_names = [
    {name: "tarifs_marches", type: "tarifsmarches", label: "Tarifs des marchés (tableau)", classes: ["col-12"]},
    {name: "infos_marches", type: "infosmarches", label: "Infos marchés", classes: ["col-12"]},
    {name: "meteo_summary", type: "meteosummary", label: "Météo tableau résumé 15j", classes: ["col-12"]},
    {name: "meteo_smallmaps", type: "meteosmallmaps", label: "Météo minicartes", classes: ["col-12"]},
    {name: "meteo_bigmap", type: "meteobigmap", label: "Météo grande carte", classes: ["col-12"]},
    {name: "article_sales", type: "graph", label: "Volume de ventes (article exact)", classes: ["col-12"]},
    {name: "article_family_sales", type: "graph", label: "Volume de ventes (variété)", classes: ["col-12"]},
    {name: "article_family2_sales", type: "graph", label: "Volume de ventes (produit)", classes: ["col-12"]},
    {name: "history", type: "graph", label: "Historique des prix tarif", classes: ["col-12"]},
    {name: "article_family2_lib_sales", type: "graph", label: "Libellés de la famille", classes: ["col-12"]},
    {name: "empty", type: "empty", label: "Empty", classes: ["col-12"]},
  ];

  constructor() {
  }

  public get activeSlots(): string[] {
    const out: string[] = [];
    for (const s of this.slots) {
      out.push(s.name);
    }
    return out;
  }

  ngOnInit(): void {
    if (localStorage.getItem("xxx-stats")) {
      this.slots = JSON.parse(localStorage.getItem("xxx-stats") || "[]");
    }

    combineLatest([this.current$, this.reload$]).pipe(
      throttleTime(400),
      switchMap(([cur, reload]) => {
        console.log("Get stats");
        const stats: any = [];
        if (!this.current || this.current.id !== cur.id || reload) {
          this.current = cur;
          for (const slot of this.activeSlots) {
            for (const stat of this.stats_names) {
              if (stat.name === slot && stat.type === "graph") {
                console.log("Getting", slot);
                stats.push(this.current.action("stats", {method: "GET", query: {stats: slot}}));
                break;
              }
            }
          }
        }
        return combineLatest(stats);
      }),
      map((res) => {
        this.stats = res;
      }))
      .subscribe((res) => console.log(res));
    this.reload$.next(false);
  }

  public removeSlot(sl: number): void {
    this.slots.splice(sl, 1);
    localStorage.setItem("xxx-stats", JSON.stringify(this.slots));
    this.reload$.next(true);
  }

  public toggleColumns(sl: number): void {
    if (this.slots[sl].classes[0] === "col-12") {
      this.slots[sl].classes[0] = "col-6";
    } else if (this.slots[sl].classes[0] === "col-6") {
      this.slots[sl].classes[0] = "col-12";
    }
    localStorage.setItem("xxx-stats", JSON.stringify(this.slots));
    this.reload$.next(true);
  }

  public saveSlot(): void {
    this.slots.push(this.slotForm.value.slot);
    localStorage.setItem("xxx-stats", JSON.stringify(this.slots));
    this.reload$.next(true);
  }

}
