
<table class="table table-striped table-bordered" *ngIf="model">
  <tbody>
    <tr *ngFor="let d of pdocuments">
      <td class="pointer" (click)="download(d)"><i class="fa fa-download me-2"></i>{{d.id}} {{d.filename}}</td>
      <td *ngIf="display('title')">
        <bsac-dd-field [model]="d" field="title" type="auto">Titre</bsac-dd-field>
      </td>
      <td *ngIf="display('type')">
        <bsac-dd-field [model]="d" field="type" type="auto">Type</bsac-dd-field>
      </td>
      <td *ngIf="display('start')">
        <bsac-dd-field [model]="d" field="start" type="auto" [native]="false" dateFormat="shortDate">Début</bsac-dd-field>
      </td>
      <td *ngIf="display('end')">
        <bsac-dd-field [model]="d" field="end" type="auto" [native]="false" dateFormat="shortDate">Fin</bsac-dd-field>
      </td>
      <td *ngIf="display('status')">
        <bsac-dd-field [model]="d" field="status" type="auto">Etat</bsac-dd-field>
      </td>
      <td *ngIf="display('articles')">
        <bsac-dd-field [model]="d" field="articles" type="auto" [related]="article$" [relatedFilter]="{fournisseur: d.provider}" desctpl="[&lt;%= code %&gt;] &lt;%= libelle %&gt;" relatedLabel="Associer un article">Articles</bsac-dd-field>
      </td>
      <td *ngIf="display('articlefamily')">
        <bsac-dd-field [model]="d" field="articlefamily" type="auto" [related]="articlefamily$" descfield="cfullname" relatedLabel="Associer une famille d'article">Famille article</bsac-dd-field>
      </td>
      <td *ngIf="display('producer')">
        <bsac-dd-field [model]="d" field="producer" type="auto" [related]="producer$" descfield="name" relatedLabel="Associer un producteur F&amp;L">Producteur F&L</bsac-dd-field>
      </td>
      <td *ngIf="display('seaproducer')">
        <bsac-dd-field [model]="d" field="seaproducer" type="auto" [related]="seaproducer$" descfield="name" relatedLabel="Associer un producteur marée">Producteur marée</bsac-dd-field>
      </td>
      <td *ngIf="display('storage')">
        <bsac-dd-field [model]="d" field="storage" type="auto" [related]="storage$" descfield="name" relatedLabel="Associer un dépôt">Dépôt</bsac-dd-field>
      </td>
      <td *ngIf="display('provider')">
        <bsac-dd-field [model]="d" field="provider" type="auto" [related]="provider$" descfield="name" relatedLabel="Associer un fournisseur">Fournisseur</bsac-dd-field>
      </td>
      <td *ngIf="display('products')">
        <bsac-dd-field [model]="d" field="products" type="auto" [related]="product$" [relatedFilter]="d.producer?{producer: d.producer}:{}" descfield="name" relatedLabel="Ajouter un produit F&amp;L" relatedEmpty="Ajouter un produit">Produits F&L</bsac-dd-field>
      </td>
      <td *ngIf="display('seaproducts')">
        <bsac-dd-field [model]="d" field="seaproducts" type="auto" [related]="seaproduct$" [relatedFilter]="d.seaproducer?{seaproducer: d.seaproducer}:{}" descfield="name" relatedLabel="Ajouter un produit marée">Produits marée</bsac-dd-field>
      </td>
      <td *ngIf="display('prlabel')">
        <bsac-dd-field [model]="d" field="prlabel" type="auto" [related]="prlabel$" descfield="name" relatedLabel="Associer un label produit F&amp;L">Label produit F&L</bsac-dd-field>
      </td>
      <td *ngIf="display('seaprlabel')">
        <bsac-dd-field [model]="d" field="seaprlabel" type="auto" [related]="seaprlabel$" descfield="name" relatedLabel="Associer un label produit marée">Label produit marée</bsac-dd-field>
      </td>
      <td *ngIf="display('stlabel')">
        <bsac-dd-field [model]="d" field="stlabel" type="auto" [related]="stlabel$" descfield="name" relatedLabel="Associer un label structure">Label structure</bsac-dd-field>
      </td>
      <td>
        <button class="btn btn-outline-danger" (dblclick)="deleteDocument(d)"><i class="fa fa-trash"></i></button>
      </td>
    </tr>
    <tr>
      <td colspan="999">
        <lvadg-upload-file [url]="getUploadUrl()" [data]="uploadData" [multiple]="false" [auto]="true" [fieldName]="'file'" [mimetypes]="[]" (uploaded)="onUploaded($event)"></lvadg-upload-file>
      </td>
    </tr>
  </tbody>
</table>