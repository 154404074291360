import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Contact} from "../../../people/contact/contact.service";


export class SendDestination extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSForeignKeyField({
    name: "send",
    verboseName: "Impressions",
    required: false,
    helpText: "Impression",
    relatedModel: "printer.ModelPrint",
    remoteField: "id",
    relatedName: "destinations"
  })
  public send!: number;

  @DSCharField({
    name: "email",
    verboseName: "Email",
    required: false,
    helpText: "Email",
    minLength: 0,
    maxLength: 500,
    choices: []
  })
  public email!: string;

  @DSForeignKeyField({
    name: "person",
    verboseName: "Personnes",
    required: false,
    helpText: "Personne",
    relatedModel: "people.Person",
    remoteField: "id",
    relatedName: "printdestinations"
  })
  public person!: number|null;

  @DSForeignKeyField({
    name: "contact",
    verboseName: "Contact",
    required: false,
    helpText: "Contact",
    relatedModel: "people.Contact",
    remoteField: "id",
    relatedName: "printdestinations"
  })
  public contact!: number|null;

  @DSForeignKeyField({
    name: "resto",
    verboseName: "Restaurant",
    required: false,
    helpText: "Restaurant",
    relatedModel: "structure.Resto",
    remoteField: "id",
    relatedName: "printdestinations"
  })
  public resto!: number;


  @DSDefaultField({
    name: "dmail",
    verboseName: "Email",
    required: false,
  })
  public dmail!: string;

  @DSDefaultField({
    name: "dlastname",
    verboseName: "Nom de famille",
    required: false,
  })
  public dlastname!: string;

  @DSDefaultField({
    name: "dfirstname",
    verboseName: "Prénom",
    required: false,
  })
  public dfirstname!: string;

  @DSDefaultField({
    name: "djobtitle",
    verboseName: "Position",
    required: false,
  })
  public djobtitle!: string;


  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "KO",
    choices: [
      ["SND", "A envoyer"],
      ["SNP", "Dans la file de préparation"],
      ["SNW", "Préparation envoi"],
      ["ATP", "Erreur temporaire, en attente nouvel essai"],
      ["SNQ", "Dans la file d'envoi"],
      ["SNI", "En cours d'envoi"],
      ["SNT", "Envoyé"],
      ["OPN", "Ouvert"],
      ["CLK", "Cliqué"],
      ["SKP", "Ne pas envoyer"],
      ["ATT", "Erreur temporaire, en attente nouvel essai"],
      ["FAT", "Erreur fatale, échec envoi"],
      ["NOK", "Invalide"],
    ],
    maxLength: 10
  })
  public status!: string;


  @DSTextField({
    name: "comments",
    verboseName: "Commentaires (interne)",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 10000
  })
  public comments!: string;

  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags!: any;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  @DSDefaultField({
    name: "contact_details",
    verboseName: "Contact",
    required: false
  })
  public contact_details!: Contact;

  @DSDefaultField({
    name: "person_details",
    verboseName: "Person",
    required: false
  })
  public person_details: {
    id: number,
    lastname: string,
    firstname: string,
    email: string
  };

  @DSDefaultField({
    name: "resto_details",
    verboseName: "Restaurant",
    required: false
  })
  public resto_details: {
    id: number,
    name: string
  };

}

@Injectable({
  providedIn: "root"
})
export class SendDestinationService extends DSRestCollection<SendDestination> {
  public adapter_config = {basePath: "/api/v2/senddestinations"};
  public model = SendDestination;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
