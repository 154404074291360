import {Component, Input} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {TarifNotificationStatus, TarifNotificationStatuses, TarifNotificationStatusService} from "../tarifnotificationstatus.service";
import {TarifType, TarifTypeService} from "../../tariftype/tariftype.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {TarifNotificationPriorities, TarifNotificationTypes} from "../../tarifnotification/tarifnotification.service";
import {Tarif, TarifService} from "../../tarif/tarif.service";

@Component({
  selector: "lvadg-tarifnotificationstatus-list",
  templateUrl: "./tarifnotificationstatus-list.component.pug",
  styleUrls: ["./tarifnotificationstatus-list.component.sass"]
})
export class TarifnotificationstatusListComponent extends ModelListBaseComponent<TarifNotificationStatus, TarifNotificationStatusService> {
  @Input() public tarif_detail_route?: RouteV3<Tarif>;
  @Input() public tariftype_detail_route?: RouteV3<TarifType>;


  public custom_fields = [
    {name: "notif_tariftype", verbose_name: "Modèle de tarif"},
    {name: "notif_tarif", verbose_name: "Tarif"},
    {name: "notif_type", verbose_name: "Type"},
    {name: "notif_priority", verbose_name: "Priorité"},
    {name: "notif_title", verbose_name: "Titre"},
  ];

  public NOTIF_STATUSES = [
    {value: TarifNotificationStatuses.TOSEND, desc: "A envoyer"},
    {value: TarifNotificationStatuses.SEND_PREPARE, desc: "Préparation envoi"},
    {
      value: TarifNotificationStatuses.SEND_QUEUE, desc: "Dans la file d'envoi"
    },
    {
      value: TarifNotificationStatuses.SENDING, desc: "En cours d'envoi"
    },
    {
      value: TarifNotificationStatuses.SENT, desc: "Envoyé"
    },
    {
      value: TarifNotificationStatuses.OPENED, desc: "Ouvert"
    },
    {
      value: TarifNotificationStatuses.CLICKED, desc: "Cliqué"
    },
    {
      value: TarifNotificationStatuses.SKIP, desc: "Ne pas envoyer"
    },
    {
      value: TarifNotificationStatuses.TEMPORARY_ERROR, desc: "Erreur temporaire, en attente nouvel essai"
    },
    {
      value: TarifNotificationStatuses.FATAL_ERROR, desc: "Erreur fatale, échec envoi"
    },
    {
      value: TarifNotificationStatuses.INVALID, desc: "Invalide"
    },
  ];

  public NOTIF_PRIORITIES = [
    {value: TarifNotificationPriorities.P1, desc: "P1"},
    {value: TarifNotificationPriorities.P2, desc: "P2"},
    {value: TarifNotificationPriorities.P3, desc: "P3"},
  ];

  public NOTIF_TYPES = [
    {value: TarifNotificationTypes.INITIAL, desc: "Publication"},
    {value: TarifNotificationTypes.UPDATE, desc: "Mise à jour"},
    {value: TarifNotificationTypes.ALARM, desc: "Alarme"},
    {value: TarifNotificationTypes.NEGO, desc: "Négociation"},
  ];
  public tariftypes$: DSRestQueryset<TarifType>;
  public tarifs$: DSRestQueryset<Tarif>;

  constructor(
    _list: BsacModelListProvider,
    _items: TarifNotificationStatusService,
    _msgs: BsacMessageService,
    routes: RoutesService,
    public _tariftypes: TarifTypeService,
    public _tarifs: TarifService
  ) {
    super(_list, _items, _msgs, routes);
  }

  async preNgOnInit(): Promise<void> {
    this.tariftypes$ = this._tariftypes.queryset;
    this.tarifs$ = this._tarifs.queryset;
    return super.preNgOnInit();
  }

  public override getRowClasses(model: TarifNotificationStatus): { [p: string]: string | boolean } {
    const cls = super.getRowClasses(model);
    if (model.status === TarifNotificationStatuses.CLICKED ||
      model.status === TarifNotificationStatuses.OPENED ||
      model.status === TarifNotificationStatuses.SENT) {
      cls["table-success"] = true;
    }
    if (model.status === TarifNotificationStatuses.TEMPORARY_ERROR ||
      model.status === TarifNotificationStatuses.FATAL_ERROR) {
      cls["table-danger"] = true;
    }
    return cls;
  }

}
