import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Upload} from "../upload.service";
import {HeaderDescription, SheetDescription} from "../xlsx-map";
import {TarifDocument} from "../../../tarif/tarifdocument/tarifdocument.service";
import {firstValueFrom, Observable} from "rxjs";
import {BsacMessageService} from "@solidev/bsadmincomponents";

@Component({
  selector: "lvadg-upload-xlsx-map",
  templateUrl: "./upload-xlsx-map.component.pug",
  styleUrls: ["./upload-xlsx-map.component.css"]
})
export class UploadXlsxMapComponent implements OnInit {
  @Input() public file!: Upload | TarifDocument;
  @Input() public fields!: { name: string, desc: string }[];
  @Input() public defaults!: { [index: string]: string };
  @Input() public previewLines: number = 3;
  @Input() public update!: Observable<any>;
  @Input() public name: string = "default";
  @Output() public mapped = new EventEmitter<void>();
  public desc!: SheetDescription[];
  public skip: number = 0;
  public sheet: number = 0;
  public params!: HeaderDescription;

  constructor(private _msgs: BsacMessageService) {
  }

  ngOnInit(): void {
    this.doUpdate();
    if (this.update && this.update.subscribe) {
      this.update.subscribe(() => {
        this.doUpdate();
      });
    }
  }

  public doUpdate() {
    console.log("Updating");
    if (this.file) {
      this.file.action("xlsx_headers", {
        method: "GET",
        query: {
          lines: this.previewLines,
          skip: this.skip
        }
      }).pipe().subscribe((res) => {
        this.desc = res;
        if (this.file.parameters && this.file.parameters.sheet) {
          this.params = this.file.parameters;
          let found = false;
          for (let i = 0; i < this.desc.length; i++) {
            if (this.desc[i].sheet === this.params.sheet) {
              this.sheet = i;
              found = true;
              break;
            }
          }
          if (!found) {
            this._msgs.warning(`Feuille ${this.file.parameters.sheet} non trouvée`);
            this.setSheet(0);
          }
        } else {
          console.log("Invalid parameters");
          this.setSheet(0);
        }
      });
    }
  }

  public setSkip(i: number): void {
    this.params.skip = i;
  }

  setSheet(i: number): void {
    this.sheet = i;
    this.params = {
      name: this.name,
      sheet: this.desc[this.sheet].sheet,
      skip: 0,
      headers: {},
      defaults: this.defaults
    };
  }

  public select(i: number, j: number, event: any) {
    console.log(i, j, event.target.value);
    this.params.headers[j] = event.target.value;
  }

  public async valid(): Promise<void> {
    this.file.parameters = this.params;
    this.file.parameters.name = this.name;
    // Update defaults if changed since init
    this.params.defaults = this.defaults;
    await firstValueFrom(this.file.update(["parameters"]));
    this.mapped.emit();
  }

}
