import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BsacApiConstantsService} from "@solidev/bsadmincomponents";
import {first} from "rxjs/operators";

@Component({
  selector: "lvadg-select-stats",
  templateUrl: "./select-stats.component.pug",
  styleUrls: ["./select-stats.component.sass"]
})
export class SelectStatsComponent implements OnInit {
  @Input() public filter_stats: (v:{value: string; desc: string}) => boolean = (v) => true;
  @Input() public initial: string[] = [];
  @Output() public selected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() public available: string[] = [];
  public current!: string[];
  public namesReady = false;

  constructor(private _cstt: BsacApiConstantsService) {
  }

  public ngOnInit(): void {
    this.current = this.initial;
    this.selected.emit(this.current);
    this._cstt.get("STATS_NAMES").pipe(
      first()).subscribe((stats) => {
        if (this.available.length == 0) {
          for (let a of stats) {
            if (this.filter_stats(a)) {
              this.available.push(a.value);
            }
          }
        }
      this.namesReady = true;
    });
  }

  public isSelected(name: string): boolean {
    return this.current.indexOf(name) !== -1;
  }

  public click(name: string): void {
    const index = this.current.indexOf(name);
    if (index === -1) {
      this.current.push(name);
      this.selected.emit(this.current);
    } else {
      this.current.splice(index, 1);
      this.selected.emit(this.current);
    }
  }
}
