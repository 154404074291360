import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {ProductPrice, ProductPriceService} from "../../../product/productprice/productprice.service";
import {ProductVariant, ProductVariantService} from "../../../product/productvariant/productvariant.service";
import {OfferProduct, OfferProductService} from "../../offerproduct/offerproduct.service";
import {Product, ProductService} from "../../../product/product/product.service";
import {Producer, ProducerService} from "../../../structure/producer/producer.service";
import {OfferManageProductBaseComponent} from "../../_base/offer-manage-product/offer-manage-product.component";

@Component({
  selector: "lvadg-offer-manage-product",
  templateUrl: "./offer-manage-product.component.pug",
  styleUrls: ["./offer-manage-product.component.sass"]
})
export class OfferManageProductComponent
  extends OfferManageProductBaseComponent<Product, Producer, OfferProduct,
    ProductVariant, ProductPrice> {
  constructor(_producers: ProducerService,
              _products: ProductService,
              _offerproducts: OfferProductService,
              _variants: ProductVariantService,
              _prices: ProductPriceService,
              _fb: UntypedFormBuilder) {
    super(_producers, _products, _offerproducts, _variants, _prices, _fb);
  }

}
