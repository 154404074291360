import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'lvadg-customdata-value-edit-file',
  templateUrl: './customdata-value-edit-file.component.pug',
  styleUrls: ['./customdata-value-edit-file.component.sass']
})
export class CustomdataValueEditFileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
