
<form [formGroup]="form" (submit)="submit()" *ngIf="tt &amp;&amp; nextcode">
  <div class="row">
    <div class="col-sm-3">
      <div class="form-group">
        <label>Nom du modèle</label>
        <bsac-field-control [form]="form" [model]="tt" field="name" placeholder="Nom du modèle"></bsac-field-control>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label>Code du modèle</label>
        <bsac-field-control [form]="form" [model]="tt" field="code" placeholder="Code du modèle"></bsac-field-control>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label>Intervalle de base</label>
        <bsac-field-control [form]="form" [model]="tt" field="base_interval" placeholder="Intervalle de base"></bsac-field-control>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label>Zone du tarif</label>
        <bsac-field-control [form]="form" [model]="tt" field="zone" placeholder="Zone de tarif"></bsac-field-control>
      </div>
    </div>
  </div>
  <button class="btn btn-primary"><i class="fa fa-save me-2"></i>Enregistrer</button>
</form>