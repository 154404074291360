import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {SeaProductFamily, SeaProductFamilyService} from "./seaproductfamily.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SeaProductFamilyResolve implements Resolve<SeaProductFamily> {
  constructor(private _bs: SeaProductFamilyService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<SeaProductFamily> {
    return this._bs.get(+route.params["seaproductfamilyId"]);
  }
}
