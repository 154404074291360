import {NgModule} from "@angular/core";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {TarifnotificationCreateComponent} from "./tarifnotification-create/tarifnotification-create.component";
import {QuillModule} from "ngx-quill";
import {TarifnotificationNotifylistComponent} from "./tarifnotification-notifylist/tarifnotification-notifylist.component";
import {TarifnotificationUserManageComponent} from "./tarifnotification-user-manage/tarifnotification-user-manage.component";
import {NgbModalModule} from "@ng-bootstrap/ng-bootstrap";


@NgModule({
  declarations: [
    TarifnotificationCreateComponent,
    TarifnotificationNotifylistComponent,
    TarifnotificationUserManageComponent
  ],
  exports: [
    TarifnotificationCreateComponent,
    TarifnotificationNotifylistComponent,
    TarifnotificationUserManageComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    NgbModalModule,
    QuillModule
  ]
})
export class TarifNotificationModule {
}
