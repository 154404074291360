import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: "lvadg-modelprint-select-page",
  templateUrl: "./modelprint-select-page.component.pug",
  styleUrls: ["./modelprint-select-page.component.sass"]
})
export class ModelprintSelectPageComponent implements OnInit, OnDestroy {

  @Input() public pages: any[] = [];
  @Input() public descfield = "name";
  @Input() public emptyName = "";
  @Output() public selectedPage = new EventEmitter<number | null>();

  public form!: UntypedFormGroup;
  public curpage: number | null = null;
  private _subscriptions = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({page: new UntypedFormControl(null)});
    this.form.valueChanges.pipe(takeUntil(this._subscriptions)).subscribe((value) => {
      this.curpage = value.page;
      if (value.page === undefined) {
        this.selectedPage.emit(null);
      } else {
        this.selectedPage.emit(value.page);
      }

    });
  }

  ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public setCommon(): void {
    this.curpage = null;
    this.selectedPage.emit(null);
    this.form.setValue({page: null});
  }

  public getPageId(page: any): void {
    return page.id;
  }

  public getPageDesc(page: any): void {
    return page[this.descfield];
  }
}
