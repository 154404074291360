import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel
} from "@solidev/ngdataservice";

export class BaseProductPrice extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "variant",
    verboseName: "Nomenclature",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public variant: number;

  @DSDefaultField({
    name: "variant_details",
    verboseName: "Nomenclature details",
    required: false
  })
  public variant_details: {
    id: number;
    lname: string;
    ccode: string;
    packaging_value: number;
    packaging_unit: string;
    sales_value: string;
    sales_unit: string;
    packing: string;
    client_details: {
      id: number;
      name: string;
    }
  };

  @DSDecimalField({
    name: "price",
    verboseName: "Prix",
    required: false,
    helpText: "",
    default: "",
    choices: [],
    factor: 1000
  })
  public price: number;

  @DSCharField({
    name: "zone",
    verboseName: "Zone",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public zone: string;

  @DSDateTimeField({
    name: "date_start",
    verboseName: "Date de début",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public date_start: Date;

  @DSDateTimeField({
    name: "date_end",
    verboseName: "Date de fin",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public date_end: Date;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "",
    choices: [["OK", "OK"], ["KO", "Pas OK"]],
    maxLength: 4
  })
  public status: string;
}
