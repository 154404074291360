import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Upload, UploadService} from "../upload.service";
import {IGeocodedFile} from "./geocoded-file";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {GeoJSONSourceSpecification} from "maplibre-gl";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-upload-geocode",
  templateUrl: "./upload-geocode.component.pug",
  styleUrls: ["./upload-geocode.component.css"]
})
export class UploadGeocodeComponent implements OnInit {
  @Input() public file!: Upload;
  @Input() public types: { [index: string]: string } = {};
  @Output() public data = new EventEmitter<IGeocodedFile>();
  public uploadUrl!: string;
  public defaults = {type: ""};
  public itypes!: string[];
  public code!: string;
  public geoFields = [
    {name: "fulladdress", desc: "Adresse complète"},
    {name: "address", desc: "Adresse"},
    {name: "postcode", desc: "Code postal"},
    {name: "city", desc: "Ville"},
    {name: "type", desc: "Type"},
    {name: "latitude", desc: "Latitude"},
    {name: "longitude", desc: "Longitude"},
    {name: "nom", desc: "Nom (affiché sur carte)"}
  ];

  constructor(public _uploads: UploadService,
              private _msg: BsacMessageService) {
  }

  ngOnInit(): void {
    this.itypes = Object.keys(this.types);
    this.uploadUrl = (this._uploads.backend as any).getRequestUrl(this._uploads.adapter.list());
    this.code = "GC" + (new Date()).getFullYear() +
      // tslint:disable-next-line:no-bitwise
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    if (this.file) {
      this.file.action("geojson", {
        method: "POST",
        body: {}
      }).subscribe((res) => {
        console.log("res");
      });
    }
  }

  public async onUploaded($event: any) {
    this.file = await firstValueFrom(this._uploads.create($event[0]));
  }

  public onMapped($event: any) {
    this.file.action("geojson", {method: "GET"}).subscribe((res: GeoJSONSourceSpecification) => {
      if ((res as any).errors && (res as any).errors.length > 0) {
        this._msg.warning("Erreur(s) lors du géocodage", (res as any).errors.join("\n"));
      }
      this.data.emit({
        type: this.file.parameters.defaults.type,
        enabled: true,
        file: this.file,
        data: res
      });
    });
  }
}
