import {Injectable} from "@angular/core";
import {DSDefaultField, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProductFamily} from "../_productfamily/productfamily-base.model";
import {Producer} from "../../structure/producer/producer.service";
import {Label} from "../label/label.service";

export class ProductFamily extends BaseProductFamily {
  @DSDefaultField({
    required: false,
    name: "Détails producteurs",
    helpText: "Détails producteurs",
    verboseName: "Détails producteurs"
  })
  public producers_details!: Producer[];

  @DSDefaultField({
    required: false,
    name: "Liste labels",
    helpText: "Liste des labels",
    verboseName: "Liste des labels"
  })
  public labels_details!: Label[];

}

@Injectable(
  {providedIn: "root"}
)
export class ProductFamilyService extends DSRestCollection<ProductFamily> {
  public adapter_config = {basePath: "/api/v2/productfamilies"};
  public model = ProductFamily;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
