import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {SeaProducerSelectComponent} from "./seaproducer-select/seaproducer-select.component";


@NgModule({
  imports: [
    CommonModule,
    BsacFormsModule
  ],
  declarations: [
    SeaProducerSelectComponent
  ],
  exports: [
    SeaProducerSelectComponent
  ],
})
export class SeaProducerSelectModule {
}
