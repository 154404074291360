import {Injectable} from "@angular/core";
import {DSAutoField, DSCharField, DSModel, DSRestCollection, DSRestCollectionSetupProvider, DSSlugField} from "@solidev/ngdataservice";


export class ArticleParticularite extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "vvid",
    verboseName: "Id Vivalya",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 16,
    choices: []
  })
  public vvid!: string;

  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["FL", "Mode F&L"],
      ["MR", "Mode Marée"],
      ["MS", "Non précisé"],
    ]
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;
}

@Injectable({
  providedIn: "root"
})
export class ArticleParticulariteService extends DSRestCollection<ArticleParticularite> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/particularites"};
  public model = ArticleParticularite;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
