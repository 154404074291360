import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SeasonsManageComponent} from "./seasons-manage/seasons-manage.component";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SeasonsManageComponent],
  exports: [SeasonsManageComponent],
})
export class ProductCommonModule {
}
