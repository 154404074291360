import {NgModule} from "@angular/core";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {NgMathPipesModule} from "ngx-pipes";
import {DetailDocumentManageComponent} from "./detaildocument-manage/detaildocument-manage.component";
import {DetailDocumentDisplayComponent} from "./detaildocument-display/detaildocument-display.component";
import {DetailDocumentListComponent} from "./detaildocument-list/detaildocument-list.component";
import {DetailDocumentListmanageComponent} from "./detaildocument-listmanage/detaildocument-listmanage.component";


@NgModule({
  declarations: [
    DetailDocumentManageComponent,
    DetailDocumentDisplayComponent,
    DetailDocumentListComponent,
    DetailDocumentListmanageComponent
  ],
  exports: [
    DetailDocumentManageComponent,
    DetailDocumentDisplayComponent,
    DetailDocumentListComponent,
    DetailDocumentListmanageComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    NgMathPipesModule
  ]
})
export class BaseDocumentModule {
}
