import {NgModule} from "@angular/core";
import {RnmmarcheListComponent} from "./rnmmarche-list/rnmmarche-list.component";
import {RnmmarcheCreateComponent} from "./rnmmarche-create/rnmmarche-create.component";
import {RnmmarcheManageComponent} from "./rnmmarche-manage/rnmmarche-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  declarations: [
    RnmmarcheListComponent,
    RnmmarcheCreateComponent,
    RnmmarcheManageComponent
  ],
  exports: [
    RnmmarcheListComponent,
    RnmmarcheCreateComponent,
    RnmmarcheManageComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
  ]
})
export class RnmmarcheModule {
}
