import {GeoCommuneService} from "../../../customdata/geocommune/geocommune.service";
import {GeoDepartementService} from "../../../customdata/geodepartement/geodepartement.service";
import {GeoRegionService} from "../../../customdata/georegion/georegion.service";
import {MemberService} from "../../../structure/member/member.service";
import {SeaProducer, SeaProducerService} from "../../../structure/seaproducer/seaproducer.service";
import {StorageService} from "../../../structure/storage/storage.service";
import {SeaLabelService} from "../../sealabel/sealabel.service";
import {SeaProductFamily, SeaProductFamilyService} from "../../seaproductfamily/seaproductfamily.service";
import {SeaProduct, SeaProductService} from "../seaproduct.service";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RestoService} from "../../../structure/resto/resto.service";
import {BaseProductListComponent} from "../../_product/product-list-base/product-list-base.component";
import {RoutesService} from "../../../../comps/routes.service";
import {DistanceService} from "../../../structure/location/distance.service";
import {CustomDataKeyService} from "../../../customdata/customdatakey/customdatakey.service";

@Component({
  selector: "lvadg-seaproduct-list",
  templateUrl: "./seaproduct-list.component.pug",
  styleUrls: ["./seaproduct-list.component.sass"]
})
export class SeaProductListComponent extends BaseProductListComponent<SeaProduct, SeaProductService,
  SeaProductFamily, SeaProductFamilyService,
  SeaProducer, SeaProducerService,
  SeaLabelService> implements OnInit, OnDestroy {

  constructor(
    _list: BsacModelListProvider,
    _items: SeaProductService,
    _msgs: BsacMessageService,
    routes: RoutesService,
    _producers: SeaProducerService,
    _members: MemberService,
    _storages: StorageService,
    _families: SeaProductFamilyService,
    _regions: GeoRegionService,
    _departements: GeoDepartementService,
    _labels: SeaLabelService,
    _communes: GeoCommuneService,
    _restos: RestoService,
    _distances: DistanceService,
    _customdatakeys: CustomDataKeyService) {
    super(_list, _items, _msgs, routes,
      _producers, _members, _storages, _families, _regions, _departements, _labels, _communes, _restos, _distances, _customdatakeys);
    this.zone = "sea";
  }


  async preNgOnInit(): Promise<void> {
    return super.preNgOnInit();
  }

  public async edited(row: SeaProduct): Promise<void> {
    row.refresh({query: {psall: 1}}).toPromise();
  }

}

