import {Component, Input, OnInit} from "@angular/core";
import {CustomDataKey} from "../../customdatakey/customdatakey.service";
import {CustomData} from "../customdata.service";
import {RoutesService} from "../../../../comps/routes.service";
import {Document, DocumentService} from "../../../document/document/document.service";
import {Folder} from "../../../document/folder/folder.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-customdata-value-display",
  templateUrl: "./customdata-value-display.component.pug",
  styleUrls: ["./customdata-value-display.component.sass"]
})
export class CustomDataValueDisplayComponent implements OnInit {
  @Input() public key!: CustomDataKey;
  @Input() public value!: CustomData;
  @Input() public altvalue!: CustomData;
  @Input() public readonly = false;
  public current!: CustomData;
  public documents: Document[] = [];

  constructor(private _documents: DocumentService,
              public routes: RoutesService) {
  }

  ngOnInit() {
    this.current = this.value ? this.value : this.altvalue;
  }

  public async downloadDocument(document: Document) {
    const doc = await firstValueFrom(this._documents.get(document));
    doc.download();
    return false;
  }

  public async getFolderDocuments(event: Event, folder: Folder): Promise<boolean> {
    event.preventDefault();
    this.documents = (await firstValueFrom(this._documents.queryset.query({folders: folder.id}).get())).items;
    return false;
  }
}
