import {Inject, Injectable, InjectionToken} from "@angular/core";


export interface IEnvironment {
  production: boolean;
  MODE: string;
  API_URL: string;
  PROJECT: string;
  DEPLOY_URL_BASE: string;
  VERSION: string;
  MAIN_SITE_DOMAIN: string;
}


export const ENVIRONMENT = new InjectionToken<IEnvironment>("lvadg.environment");


@Injectable({
  providedIn: "root"
})
export class EnvService {

  constructor(@Inject(ENVIRONMENT) public env: IEnvironment) {
  }


  public deployUrl(s: string) {
    let base: string;
    if (this.env.MODE === "devel") {
      base = "";
    } else {
      base = `${this.env.DEPLOY_URL_BASE}/${this.env.VERSION}/${this.env.PROJECT}`;
    }

    if (s.length === 0) {
      return base;
    }
    if (s[0] === "/") {
      return `${base}${s}`;
    } else {
      return `${base}/${s}`;
    }
  }

  public deployBgUrl(s: string) {
    return `url(${this.deployUrl(s)})`;
  }

}
