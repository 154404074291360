import {CompsModule} from "../../lib/comps/comps.module";
import {BsacApiConstantsModule, BsacDevModule, BsacFormsModule, BsacGeoModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {CdkTableModule} from "@angular/cdk/table";

export const MODELLIST_IMPORTS = [
  CommonModule,
  CompsModule,
  BsacModelListModule,
  BsacGeoModule,
  BsacDevModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  CdkTableModule,
  NgbModule,
  BsacApiConstantsModule,
  BsacFormsModule,
];
