import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ErrorHandler, LOCALE_ID, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {ServiceWorkerModule} from "@angular/service-worker";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {
  BSAC_API_CONSTANTS_HEADERS,
  BSAC_API_CONSTANTS_URL,
  BSAC_ISSUE_CONFIG,
  BSAC_MAPS_BAN_PATH,
  BSAC_MODEL_LIST_PERSISTENCE,
  BSAC_SETTINGS_SERVICE,
  BSAC_SOCKET_IO_CLIENT,
  BSAC_SOCKET_IO_CONFIG,
  BsacApiConstantsService,
  BsacGeolocBanService,
  BsacGeolocService,
  BsacLiveModule,
  BsacMessageModule,
  BsacMessageService
} from "@solidev/bsadmincomponents";
import {
  DS_REST_BACKEND_AUTHENTICATOR,
  DS_REST_BACKEND_CONFIG,
  DSRestBackend,
  DSRestModule,
  DSTokenAuthentication
} from "@solidev/ngdataservice";
import {io} from "socket.io-client";
import {environment} from "../environments/environment";

import {ADMIN_ROUTES_CONFIG, AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {
  AnalyticsService,
  AuthService,
  AuthSiteGuard,
  CurrentSiteService,
  ENVIRONMENT,
  ErrorModule,
  ErrorService,
  KVStoreService,
  LOCAL_STORAGE_OBJECT,
  LVADG_ANALYTICS_ID,
  LVADG_MAIN_SITE_DOMAIN,
  LVADG_PRINTER_URL,
  LVADGIO_API_URL,
  ROUTES_CONFIG,
  RoutesService,
  SettingsValueService,
  UpdateModule,
  UserModule
} from "lvadg";
import {Http403Interceptor} from "../../../lvadg/src/lib/error/httperror.service";


import * as Sentry from "@sentry/browser";
import {SENTRY_MAIN, SentryErrorHandlerService} from "../../../lvadg/src/lib/error/sentry-error-handler.service";
import {registerLocaleData} from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";
import {QuillModule} from "ngx-quill";
import {NgxEchartsModule} from "ngx-echarts";

Sentry.init({
  enabled: environment.production,
  dsn: "https://b3e5e3634956487e8baa22bf44aeb233@sentry.solidev.net/4",
  environment: environment.MODE,
  release: environment.RELEASE,
});

registerLocaleData(localeFr, "fr", localeFrExtra);

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {enabled: environment.production}),
    BrowserAnimationsModule,
    ErrorModule,
    HttpClientModule,
    DSRestModule,
    AppRoutingModule,
    UserModule,
    UpdateModule,
    BsacMessageModule,
    BsacLiveModule,
    NgbModule,
    QuillModule.forRoot(),
    NgxEchartsModule.forRoot({
      // @ts-ignore
      echarts: () => import("echarts")
    }),
  ],
  providers: [
    {provide: ENVIRONMENT, useValue: environment},
    AnalyticsService,
    {provide: SENTRY_MAIN, useValue: Sentry},
    {provide: ErrorHandler, useClass: SentryErrorHandlerService},
    {provide: LVADG_MAIN_SITE_DOMAIN, useValue: environment.MAIN_SITE_DOMAIN},
    {provide: LVADG_ANALYTICS_ID, useValue: environment.ANALYTICS},
    {provide: LVADG_PRINTER_URL, useValue: environment.PRINTER_URL},
    {provide: LVADGIO_API_URL, useValue: environment.LVADGIO_API_URL},
    {provide: LOCALE_ID, useValue: "fr"},
    {provide: ROUTES_CONFIG, useValue: ADMIN_ROUTES_CONFIG},
    RoutesService,
    {
      provide: DS_REST_BACKEND_AUTHENTICATOR,
      useClass: DSTokenAuthentication
    },
    {
      provide: DS_REST_BACKEND_CONFIG,
      useValue: {
        url: environment.API_URL,
        headers: environment.API_HEADERS
      }
    },
    DSRestBackend,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (routes: RoutesService, auth: AuthService, msgs: BsacMessageService) {
        return new Http403Interceptor(routes, auth, msgs);
      }, multi: true,
      deps: [RoutesService, AuthService, BsacMessageService]
    },
    {
      provide: LOCAL_STORAGE_OBJECT,
      useValue: window.localStorage
    },
    {
      provide: BSAC_MAPS_BAN_PATH,
      useValue: environment.BAN_PATH
    },
    {
      provide: BsacGeolocService,
      useClass: BsacGeolocBanService
    },
    {
      provide: BSAC_SOCKET_IO_CLIENT,
      useValue: io
    },
    {
      provide: BSAC_SOCKET_IO_CONFIG,
      useValue: {
        url: environment.LIVE_URL,
        path: "/socket.io",
        appId: "socketLvadgId"
      }
    },
    {
      provide: BSAC_ISSUE_CONFIG,
      useValue: {
        server: "https://dev.solidev.net",
        project: "groups/vivalya/-"
      }
    },
    {
      provide: BSAC_API_CONSTANTS_URL,
      useValue: environment.API_URL + "/api/v2/constants"
    },
    {
      provide: BSAC_API_CONSTANTS_HEADERS,
      useValue: {}
    },
    KVStoreService,
    {
      provide: BSAC_MODEL_LIST_PERSISTENCE,
      useExisting: KVStoreService
    },
    SettingsValueService,
    {
      provide: BSAC_SETTINGS_SERVICE,
      useExisting: SettingsValueService
    },
    CurrentSiteService,
    AuthService,
    AuthSiteGuard,
    ErrorService,
    BsacMessageService,
    BsacApiConstantsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
