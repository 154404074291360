import {Offer, OfferService} from "./offer.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class OfferResolve implements Resolve<Offer> {
  constructor(private _model: OfferService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Offer> {
    return this._model.get(+route.params["offerId"]);
  }
}
