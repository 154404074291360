export class SheetDescription {
  sheet: string;
  headers: string[][];
}


export class HeaderDescription {
  name: string;
  sheet: string;
  skip: number;
  headers: { [index: number]: string };
  defaults: { [index: string]: string };
}
