<!DOCTYPE html>
<div class="uploader">
  <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
    <p class="dndhint">Drag &amp; drop</p>
    <div class="form-group">
      <label class="upload" *ngIf="!multiple">Sélectionner une image</label>
      <label class="upload" *ngIf="multiple">Sélectionner une ou plusieurs images</label>
      <input class="form-control-file" type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [multiple]="multiple">
    </div>
  </div>
  <button class="btn btn-primary" type="button" (click)="startUpload()" *ngIf="!auto">Démarrer le téléversement</button>
  <div class="upload-items">
    <div class="upload-item" *ngFor="let f of files; let i = index;"><span class="filename"><span>{{f.name}} ({{f.id}}) - {{f.progress?.data?.percentage}}% &nbsp;</span><span>uploading with {{ f.progress?.data?.speedHuman }} &nbsp;</span><span>ETA {{f.progress?.data?.etaHuman }}</span></span><span *ngIf="f?.progress?.data?.percentage&lt;95">
        <ngb-progressbar type="info" [value]="f?.progress?.data?.percentage"></ngb-progressbar></span><span *ngIf="f?.progress?.data?.percentage&gt;95">
        <ngb-progressbar type="success" [value]="f?.progress?.data?.percentage" [striped]="true" [animated]="true">Processing image</ngb-progressbar></span></div>
  </div>
</div>