import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseOfferProduct} from "../_base/baseofferproduct.service";

export class OfferProduct extends BaseOfferProduct {
}


@Injectable({
  providedIn: "root"
})
export class OfferProductService extends DSRestCollection<OfferProduct> {
  public adapter_config = {basePath: "/api/v2/offerproducts"};
  public model = OfferProduct;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
