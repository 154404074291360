
<div class="card" *ngIf="trelations.length">
  <div class="card-header ch-1"><i class="fa fa-link me-2"></i>
    <ng-container *ngIf="dest">Relations entrantes</ng-container>
    <ng-container *ngIf="src">Relations sortantes</ng-container>
  </div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Type</th>
        <th>
          <ng-container *ngIf="dest">Modèle de tarif source</ng-container>
          <ng-container *ngIf="src">Modèle de tarif cible</ng-container>
        </th>
        <th>
          <ng-container *ngIf="dest">Tarif source</ng-container>
          <ng-container *ngIf="src">Tarif cible</ng-container>
        </th>
        <th>Opérations</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let r of trelations">
        <td>{{r.type|choice:r:'type'}}</td>
        <td *ngIf="dest"><a [routerLink]="tariftype_detail_route?.route({tarifType: r.src_details})" target="_blank">{{r.src_details.name}}<i class="fa fa-external-link ms-2" *ngIf="tariftype_detail_route"></i></a></td>
        <td *ngIf="src"><a [routerLink]="tariftype_detail_route?.route({tarifType: r.dest_details})" target="_blank">{{r.dest_details.name}}<i class="fa fa-external-link ms-2" *ngIf="tariftype_detail_route"></i></a></td>
        <td *ngIf="src">
          <div *ngIf="relations[r.id]; let rel"><a [routerLink]="tarif_detail_route?.route({tarifType: r.dest_details, tarif: rel.dest_details})" target="_blank">{{rel.dest_details.name}}<i class="fa fa-external-link ms-2" *ngIf="tarif_detail_route"></i></a><span class="px-3" role="button" (click)="toggleEditor(r)" *ngIf="canEdit(dest) &amp;&amp; canEdit(r.dest_details)"><i class="fa fa-pencil"></i></span></div>
          <div *ngIf="!relations[r.id]" role="button" (click)="toggleEditor(r)" data-cy="tarifrelation-src-reference-empty-edit">-- Sélectionner un tarif de destination --</div>
          <form *ngIf="relationManager[r.id]" [formGroup]="relationManager[r.id].form">
            <select class="form-select" formControlName="candidate" (change)="setDest(r, $event)">
              <option *ngFor="let c of relationManager[r.id].candidates" [value]="c.id">[{{c.status}}] {{c.name}}</option>
            </select>
          </form>
        </td>
        <td *ngIf="dest">
          <div *ngIf="relations[r.id]; let rel"><a [routerLink]="tarif_detail_route?.route({tarifType: r.src_details, tarif: rel.src_details})" target="_blank">{{rel.src_details.name}}<i class="fa fa-external-link ms-2" *ngIf="tarif_detail_route"></i></a><span class="px-3" role="button" (click)="toggleEditor(r)" *ngIf="canEdit(src) &amp;&amp; canEdit(r.src_details)"><i class="fa fa-pencil"></i></span></div>
          <div *ngIf="!relations[r.id]" role="button" (click)="toggleEditor(r)" data-cy="tarifrelation-dest-reference-empty-edit">-- Sélectionner un tarif source --</div>
          <form *ngIf="relationManager[r.id]" [formGroup]="relationManager[r.id].form">
            <select class="form-select" formControlName="candidate" (change)="setSource(r, $event)">
              <option *ngFor="let c of relationManager[r.id].candidates" [value]="c.id">[{{c.status}}] {{c.name}}</option>
            </select>
          </form>
        </td>
        <td>{{r.ops}}</td>
        <td>
          <button class="btn btn-sm btn-danger" *ngIf="canEdit(dest) &amp;&amp; r.type==='LOC' &amp;&amp; dest &amp;&amp; relations[r.id]" [disabled]="running.dispo" (click)="importDispo(r)" data-cy="tarifrelation-dest-import-dispo-button"><i class="fa fa-spinner fa-spin me-2" *ngIf="running.dispo"></i>Importer les disponibilités</button>
          <button class="btn btn-sm btn-danger" *ngIf="canEdit(dest) &amp;&amp; r.type==='REF' &amp;&amp; dest &amp;&amp; relations[r.id]" [disabled]="running.ref" (click)="importRef(r)" data-cy="tarifrelation-dest-import-price-button"><i class="fa fa-spinner fa-spin me-2" *ngIf="running.ref"></i>Importer les prix</button>
          <button class="btn btn-sm btn-danger" *ngIf="canEdit(dest) &amp;&amp; r.type==='CLN' &amp;&amp; dest &amp;&amp; relations[r.id]" [disabled]="running.clone" (click)="importClone(r)" data-cy="tarifrelation-dest-clone-button"><i class="fa fa-spinner fa-spin me-2" *ngIf="running.clone"></i>Importer les prix & articles</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>