<!DOCTYPE html>
<ng-template #createModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Ajouter un article</h4>
    <button class="btn-close" (click)="modal.dismiss('x')" type="button"></button>
  </div>
  <div class="modal-body">
    <lvadg-articletarif-create [tarifType]="tarifType" (created)="onCreated($event)" (cancelled)="create=false" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-articletarif-create>
  </div>
</ng-template>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'status'; desc: 'Recherche par état'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="status" [choices]="STATUSES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <button class="btn btn-sm btn-primary" (click)="openCreate(createModal)">Ajouter un article</button>
    <button class="btn btn-sm btn-outline-primary ms-1" (click)="xlsxExport('Articles '+tarifType.name, 'Articles')" title="Export rapide de la liste courante"><i class="fa fa-file-excel mx-2"></i></button>
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>ID</th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="['./att/', row.id]">{{row.id}}</td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status|choice:row:'status'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="name">Nom</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article__family__cfullname">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="article__family__cfullname">Famille article</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.article_details?.cfullname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article">
            <th cdk-header-cell *cdkHeaderCellDef>Code article</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="article_detail_route?.route({article: row.article_details})">{{row.article_details?.code}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="rnms_details">
            <th cdk-header-cell *cdkHeaderCellDef>Liens RNM</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let r of row.rnms_details">
                <div *ngIf="r.score_manual&gt;0 || r.score_auto&gt;=10">[{{r.score_manual}}/5] [{{r.score_auto}}/20] {{r.rnm_details?.name}}<small class="d-block ms-3" *ngFor="let l of r.rnm_details?.libelles_details">{{l.libelle}} {{l.ulibelle}}</small></div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="article_calibre">
            <th cdk-header-cell *cdkHeaderCellDef>Calibre</th>
            <td cdk-cell *cdkCellDef="let row">{{row.article_calibre}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article_categorie">
            <th cdk-header-cell *cdkHeaderCellDef>Catégorie</th>
            <td cdk-cell *cdkCellDef="let row">{{row.article_categorie}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article_egalim_details">
            <th cdk-header-cell *cdkHeaderCellDef>Labels & certifications</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="d-flex flex-row">
                <div class="me-1" *ngFor="let e of row.article_egalim_details">
                  <lvadg-label-display [labelId]="e.flabel" *ngIf="e.flabel"></lvadg-label-display>
                  <lvadg-sealabel-display [labelId]="e.slabel" *ngIf="e.slabel"></lvadg-sealabel-display><span class="labeltitle" *ngIf="!(e.flabel || e.slabel)">{{e.name||e.code}}</span>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="article_origine">
            <th cdk-header-cell *cdkHeaderCellDef>Origine</th>
            <td cdk-cell *cdkCellDef="let row">{{row.article_origine}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article_local">
            <th cdk-header-cell *cdkHeaderCellDef>Local</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check text-success" *ngIf="row.article_local=='L'"></i>
              <ng-container *ngIf="row.article_local=='U'"><i class="fa fa-check text-success me-1"></i>ultra</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="article_is_egalim">
            <th cdk-header-cell *cdkHeaderCellDef>Egalim</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check text-success" *ngIf="row.article_is_egalim"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="article_colisage">
            <th cdk-header-cell *cdkHeaderCellDef>Colisage</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.article_colisage">
                <ng-container *ngIf="row.article_colisage.colisage_nombre">{{row.article_colisage.colisage_cond_details?.name || row.article_colisage.colisage_cond_details?.code}} {{row.article_colisage|dsDecimal:"colisage_nombre"}} &times; {{row.article_colisage|dsDecimal:"colisage_poids"}} {{row.article_colisage.colisage_unit|choice:row.article_colisage:'colisage_unit'}}</ng-container>
                <ng-container *ngIf="!row.article_colisage.colisage_nombre &amp;&amp; row.article_colisage.colisage_poids">{{row.article_colisage.colisage_cond_details?.name || row.article_colisage.colisage_cond_details?.code}} {{row.article_colisage|dsDecimal:"colisage_poids"}} {{row.article_colisage.colisage_unit|choice:row.article_colisage:'colisage_unit'}}</ng-container>
                <ng-container *ngIf="!row.article_colisage.colisage_nombre &amp;&amp; !row.article_colisage.colisage_poids">{{row.article_colisage.colisage_cond_details?.name || row.article_colisage.colisage_cond_details?.code}}</ng-container>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="article_uci">
            <th cdk-header-cell *cdkHeaderCellDef>UCI</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.article_uci.uci_value">{{row.article_uci.uci_cond_details?.name || row.article_uci.uci_cond_details?.code}} {{row.article_uci|dsDecimal:'uci_value'}} {{row.article_uci.uci_unit|choice:row.article_uci:'uci_unit'}}</ng-container>
              <ng-container *ngIf="!row.article_uci.uci_value">{{row.article_uci.uci_cond_details?.name || row.article_uci.uci_cond_details?.code}}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="article_uc">
            <th cdk-header-cell *cdkHeaderCellDef>UC</th>
            <td cdk-cell *cdkCellDef="let row">{{row.article_uc||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article_uf">
            <th cdk-header-cell *cdkHeaderCellDef>UF</th>
            <td cdk-cell *cdkCellDef="let row">{{row.article_uf||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="eaclient">
            <th cdk-header-cell *cdkHeaderCellDef>Article client</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-pencil ms-2 float-end" (click)="editEaclient[row.id]=!editEaclient[row.id]"></i>
              <ng-container *ngIf="!editEaclient[row.id]">
                <ng-container *ngIf="!row.eaclient"><span class="text-danger">AUCUN ARTICLE CLIENT ASSOCIE</span></ng-container>
                <ng-container *ngIf="row.eaclient"><code>{{row.eaclient_details?.code}}</code> {{row.eaclient_details?.libelle}}</ng-container>
              </ng-container>
              <ng-container *ngIf="editEaclient[row.id]">
                <bsac-dd-field [model]="row" field="eaclient" type="auto" [related]="ea$" [relatedFilter]="{client: tarifType.client_details.id, type:'CLI'}" relatedEmpty="AUCUN ARTICLE CLIENT ASSOCIE" relatedLabel="Sélectionnez un article client" desctpl="[&lt;%= obj.type %&gt;] &lt;%= obj.code %&gt; &lt;%= obj.libelle %&gt;" (changed)="editEaclient[row.id]=false">Modification article client associé</bsac-dd-field>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="eastorages">
            <th cdk-header-cell *cdkHeaderCellDef>Articles adhérent</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let eas of row.eastorages_details"><code>{{eas.code}}</code> {{eas.libelle}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="datestart">
            <th cdk-header-cell *cdkHeaderCellDef>Date début</th>
            <td cdk-cell *cdkCellDef="let row">{{row.datestart|date:'d MMMM'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="dateend">
            <th cdk-header-cell *cdkHeaderCellDef>Date fin</th>
            <td cdk-cell *cdkCellDef="let row">{{row.dateend|date:'d MMMM'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="tarif_type">
            <th cdk-header-cell *cdkHeaderCellDef>Modèle de tarif</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tarif_type}}</td>
          </ng-container>
          <ng-container cdkColumnDef="eaclient_code">
            <th cdk-header-cell *cdkHeaderCellDef>Code article client</th>
            <td cdk-cell *cdkCellDef="let row">{{row.eaclient_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="eaclient_libelle">
            <th cdk-header-cell *cdkHeaderCellDef>Libellé article client</th>
            <td cdk-cell *cdkCellDef="let row">{{row.eaclient_details?.libelle}}</td>
          </ng-container>
          <ng-container *ngFor="let r of ttrelations_columns">
            <ng-container [cdkColumnDef]="r.name">
              <th cdk-header-cell *cdkHeaderCellDef>{{r.verbose_name}}</th>
              <td cdk-cell *cdkCellDef="let row">
                <lvadg-attrelation-manage [relation]="r.relation" [att]="row" (updated)="reload.next()"></lvadg-attrelation-manage>
              </td>
            </ng-container>
          </ng-container>
          <ng-container cdkColumnDef="actions">
            <th cdk-header-cell *cdkHeaderCellDef>Actions</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="!actions || actions.indexOf('delete') !== -1">
                <lvadg-confirm-delete type="trash" (deleted)="onDeleted($event)" [model]="row"></lvadg-confirm-delete>
              </ng-container>
              <ng-container *ngIf="actions &amp;&amp; actions.indexOf('select') !== -1">
                <button class="btn btn-sm btn-primary" (click)="selected.emit(row)">Sélectionner</button>
              </ng-container>
            </td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>