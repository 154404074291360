
<div class="row g-3">
  <div class="col">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-info-circle me-2"></i>Infos article {{tarifType.name}}</div>
      <div class="card-body">
        <p class="text-info" *ngIf="isWriter &amp;&amp; !isAdmin">Seuls l'état et les dates de début/fin sont modifiables</p>
        <dl class="row">
          <bsac-dd-field class="col-6" [model]="att" field="id" type="auto" [editable]="false">ID</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="att" field="status" type="auto" [editable]="isWriter">Etat</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="att" field="name" type="auto" [editable]="isAdmin">Nom</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="att" field="article" type="auto" [related]="article$" descfield="libelle" [editable]="isAdmin">Article</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="att" field="datestart" type="auto" dateFormat="d MMMM" [editable]="isWriter">Date de début</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="att" field="dateend" type="auto" dateFormat="d MMMM" [editable]="isWriter">Date de fin</bsac-dd-field>
        </dl>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-link me-2"></i>Liens catalogues</div>
      <div class="card-body">
        <dl class="row">
          <bsac-dd-field class="col-8" [model]="att" field="eaclient" type="auto" [editable]="isAdmin" [related]="ea$" [relatedFilter]="{client: tarifType.client_details?.id, types:'CLI,CLB'}" relatedEmpty="AUCUN ARTICLE CLIENT ASSOCIE" relatedLabel="Sélectionnez un article client (CLI++/CLB--)" desctpl="[&lt;%= obj.type %&gt;] &lt;%= obj.code %&gt; &lt;%= obj.libelle %&gt;">Article client associé</bsac-dd-field>
          <div class="col-4" *ngIf="att.eaclient_details &amp;&amp; eaclient_detail_route"><a [routerLink]="eaclient_detail_route?.route({externalArticle: att.eaclient_details})"><i class="fa fa-link fa-2x"></i></a></div>
        </dl>
      </div>
    </div>
  </div>
  <div class="col" *ngIf="isWriter">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-trash me-2"></i>Suppression article</div>
      <div class="card-body">
        <div class="mt-3 text-danger">Seuls les articles n'apparaissant dans aucun tarif peuvent vraiment être supprimés. Les articles déjà présents dans un tarif seront juste désactivés (passage en état 'supprimé').</div>
        <lvadg-confirm-delete [model]="att" [dispMessage]="false" type="button" (deleted)="onDeleted(att)">Supprimer l'article</lvadg-confirm-delete>
      </div>
    </div>
  </div>
</div>
<div class="mt-3">
  <div class="card">
    <div class="card-header"><i class="fa fa-truck me-2"></i>Liens RNM</div>
    <table class="table table-striped table-condensed mb-3">
      <thead>
        <tr>
          <th>Libellé RNM</th>
          <th>Intitulés</th>
          <th>Origine</th>
          <th>Categorie</th>
          <th>Calibre</th>
          <th>Labels</th>
          <th>Score auto</th>
          <th>Score manuel</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let r of rnms" [class.table-success]="r.rnm_details?.id==curRnm?.id">
          <td class="pointer" (click)="loadStats(r)">{{r.rnm_details.name}}</td>
          <td>
            <div *ngFor="let l of r.rnm_details.libelles_details"><small>{{l.libelle}}</small></div>
          </td>
          <td>{{r.rnm_details.origine_details?.code}}</td>
          <td>{{r.rnm_details.categorie_details?.code}}</td>
          <td>{{r.rnm_details.calibre_details?.code}}</td>
          <td><span *ngFor="let l of r.rnm_details.egalim_details">{{l.code}}</span></td>
          <td>{{r.score_auto}}</td>
          <td>
            <ngb-rating [(rate)]="r.score_manual" [max]="5" (rateChange)="changedScore(r, $event)" [readonly]="!isWriter"></ngb-rating><i class="ms-4 fa fa-trash text-danger" (click)="removeLink(r)" *ngIf="isWriter"></i>
          </td>
        </tr>
        <tr class="table-info" *ngIf="isWriter">
          <td class="pointer" colspan="999" (click)="loadCandidates()">
            <div class="text-center">Rechercher des candidats</div>
          </td>
        </tr>
      </tbody>
    </table>
    <lvadg-rnm-graph [rnm]="curRnm" *ngIf="curRnm"></lvadg-rnm-graph>
  </div>
</div>