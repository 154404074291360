import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";


export class CiqualValue extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "alim",
    verboseName: "Aliment",
    required: false,
    helpText: "Aliment",
    relatedModel: "customdata.CiqualAlim",
    remoteField: "id",
    relatedName: "values"
  })
  public alim!: number;

  @DSForeignKeyField({
    name: "column",
    verboseName: "Référence",
    required: false,
    helpText: "Référence",
    relatedModel: "customdata.CiqualColumn",
    remoteField: "id",
    relatedName: "values"
  })
  public column!: number;

  @DSBooleanField({
    name: "less",
    verboseName: "Inférieur à  ?",
    required: false,
    helpText: "Inférieur à ?",
    default: false
  })
  public less!: boolean;

  @DSBooleanField({
    name: "traces",
    verboseName: "Traces ?",
    required: false,
    helpText: "Traces ?",
    default: false
  })
  public traces!: boolean;

  @DSDecimalField({
    name: "value",
    verboseName: "Valeur numérique",
    required: false,
    helpText: "Valeur numérique",
    default: 0,
    factor: 100000,
    choices: []
  })
  public value!: number;


  @DSDefaultField({
    name: "value_str",
    verboseName: "Valeur",
    required: false,
    helpText: "Valeur",
    default: ""
  })
  public value_str!: string;

}

@Injectable({
  providedIn: "root"
})
export class CiqualValueService extends DSRestCollection<CiqualValue> {
  public adapter_config = {basePath: "/api/v2/ciqualvalues"};
  public model = CiqualValue;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
