import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField,
  DSTextField
} from "@solidev/ngdataservice";
import {Client} from "../../structure/client/client.service";
import {Member} from "../../structure/member/member.service";
import {Storage} from "../../structure/storage/storage.service";
import {Group} from "../../people/group/group.service";
import {Person} from "../../people/person/person.service";
import {Contact} from "../../people/contact/contact.service";

export enum StoragesModes {
  Base = "BASE",
  Dispo = "DISP",
  Price = "STOR",
}

export enum TarifTypes {
  Generic = "GEN",
  Client = "CLI",
}

export enum LifeCycles {
  UPLOAD = "UPLOAD",
  GENERIC = "GENERIC",
  GENERIC_ADMIN = "GENERICA",
  GENNOVAL = "GENNOVAL",
  GENNOVAL_ADMIN = "GENNOVALA"
}

export class TarifType extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom du modèle",
    required: false,
    helpText: "Nom du modèle",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public name!: string;

  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 50,
    choices: []
  })
  public code!: string;


  @DSCharField({
    name: "name_template",
    verboseName: "Modèle de nom",
    required: false,
    helpText: "Modèle de nom",
    minLength: 0,
    maxLength: 400,
    choices: []
  })
  public name_template!: string;

  @DSTextField({
    name: "message",
    verboseName: "Message",
    required: false,
    helpText: "Message",
    minLength: 0,
    maxLength: 1000,
    choices: []
  })
  public message!: string;

  @DSTextField({
    name: "contract_message",
    verboseName: "Conditions de vente",
    required: false,
    helpText: "Conditions de vente",
    minLength: 0,
    maxLength: 2000,
    choices: []
  })
  public contract_message!: string;


  @DSTextField({
    name: "auto_message",
    verboseName: "Message (automatique)",
    required: false,
    helpText: "Message (automatique)",
    minLength: 0,
    maxLength: 1000,
    choices: []
  })
  public auto_message!: string;


  @DSCharField({
    name: "type",
    verboseName: "Type de tarif",
    required: false,
    helpText: "Type de tarif",
    minLength: 0,
    maxLength: 3,
    choices: [
      [TarifTypes.Generic, "Tarif générique"],
      [TarifTypes.Client, "Tarif client"]
    ]
  })
  public type!: TarifTypes;

  @DSCharField({
    name: "lifecycle",
    verboseName: "Cycle de vie",
    required: false,
    helpText: "Cycle de vie",
    minLength: 0,
    maxLength: 10,
    choices: [
      [LifeCycles.UPLOAD, "Upload tarif simple"],
      [LifeCycles.GENERIC, "Cycle de vie générique"],
      [LifeCycles.GENNOVAL, "Cycle de vie générique (sans validation)"],
      [LifeCycles.GENERIC_ADMIN, "Cycle de vie générique (admin seulement)"],
      [LifeCycles.GENNOVAL_ADMIN, "Cycle de vie générique (sans validation, admin seulement)"],
    ]
  })
  public lifecycle!: LifeCycles ;


  @DSManyToManyField({
    name: "read_groups",
    verboseName: "Accès en lecture",
    required: false,
    relatedModel: "people.Group",
    remoteField: "readtariftypes",
    helpText: "Accès en lecture",
    default: [],
  })
  public read_groups!: number[];
  public read_groups_details!: Group[];

  @DSManyToManyField({
    name: "write_groups",
    verboseName: "Accès en écriture",
    required: false,
    relatedModel: "people.Group",
    remoteField: "writetariftypes",
    helpText: "Accès en écriture",
    default: [],
  })
  public write_groups!: number[];
  public write_groups_details!: Group[];

  @DSManyToManyField({
    name: "admin_groups",
    verboseName: "Accès en administration",
    required: false,
    relatedModel: "people.Group",
    remoteField: "writetariftypes",
    helpText: "Accès en écriture",
    default: [],
  })
  public admin_groups!: number[];
  public admin_groups_details!: Group[];

  @DSForeignKeyField({
    name: "client",
    verboseName: "Client",
    required: false,
    helpText: "Client",
    relatedModel: "structure.Client",
    remoteField: "id",
    relatedName: "tarifgroups"
  })
  public client!: number;
  public client_details!: Client;

  @DSForeignKeyField({
    name: "member",
    verboseName: "Adhérent",
    required: false,
    helpText: "Adhérent",
    relatedModel: "structure.Member",
    remoteField: "id",
    relatedName: "tarifgroups"
  })
  public member!: number;
  public member_details!: Member;

  @DSForeignKeyField({
    name: "storage",
    verboseName: "Dépôt",
    required: false,
    helpText: "Dépôt",
    relatedModel: "structure.Storage",
    remoteField: "id",
    relatedName: "tarifgroups"
  })
  public storage!: number;
  public storage_details!: Storage;

  @DSManyToManyField({
    name: "contacts",
    verboseName: "Contacts",
    required: false,
    helpText: "Contacts"
  })
  public contacts!: number[];
  public contacts_details!: Contact[];

  @DSManyToManyField({
    name: "storages",
    verboseName: "Dépôts",
    required: false,
    relatedModel: "structure.Storage",
    remoteField: "mtarifgroups",
    helpText: "Dépôts",
    default: [],
  })
  public storages!: number[];
  public storages_details!: Storage[];

  @DSCharField({
    name: "storages_mode",
    verboseName: "Détails par dépôt",
    required: false,
    helpText: "Détails par dépôt",
    minLength: 0,
    maxLength: 5,
    choices: [
      [StoragesModes.Base, "Pas de détails par dépôt"],
      [StoragesModes.Dispo, "Infos de disponibilité par dépôt"],
      [StoragesModes.Price, "Prix par dépôt"]
    ],
    default: StoragesModes.Base
  })
  public storages_mode: StoragesModes;

  @DSManyToManyField({
    name: "members",
    verboseName: "Adhérents",
    required: false,
    relatedModel: "structure.Member",
    remoteField: "mtarifgroups",
    helpText: "Adhérents",
    default: [],
  })
  public members!: number[];
  public members_details!: Member[];

  @DSCharField({
    name: "base_interval",
    verboseName: "Intervalle de base",
    required: false,
    helpText: "Intervalle de base",
    minLength: 0,
    maxLength: 3,
    choices: [
      ["W1", "1 semaine"],
      ["W2", "2 semaines"],
      ["M1", "1 mois"],
      ["M2", "2 mois"],
      ["M3", "Trimestre"],
      ["M6", "Semestre"],
      ["M10", "10 mois"],
      ["M12", "Annuel"],
      ["CS", "Non définie"]
    ]
  })
  public base_interval!: string;

  @DSCharField({
    name: "zone",
    verboseName: "Zone",
    required: false,
    helpText: "Zone",
    minLength: 0,
    maxLength: 3,
    choices: [
      ["FL", "Fruits et légumes"],
      ["SEA", "Marée"],
      ["45G", "4/5eme gamme"]
    ]
  })
  public zone!: "FL" | "SEA" | "45G";

  @DSCharField({
    name: "decondi_status",
    verboseName: "Déconditionnement",
    required: false,
    helpText: "Déconditionnement",
    minLength: 0,
    maxLength: 3,
    default: "UNK",
    choices: [
      ["CTR", "Négocié au contrat"],
      ["KPK", "Au cas par cas"],
      ["NDC", "Pas de déconditionnement"],
      ["UNK", "Inconnu"]
    ]
  })
  public decondi_status!: "CTR" | "KPK" | "NDC" | "UNK";

  @DSDecimalField({
    name: "decondi_kg",
    verboseName: "Decondit. kg",
    required: false,
    helpText: "Tarif déconditionnement kg",
    default: 0,
    factor: 10000,
    choices: []
  })
  public decondi_kg!: number;

  @DSDecimalField({
    name: "decondi_pce",
    verboseName: "Décondit. pce",
    required: false,
    helpText: "Tarif déconditionnement pièce",
    default: 0,
    factor: 10000,
    choices: []
  })
  public decondi_pce!: number;


  @DSForeignKeyField({
    name: "asscom",
    verboseName: "Ass. Comm.",
    required: false,
    helpText: "Assistant(e) Commercial(e)",
    relatedModel: "people.Person",
    remoteField: "id",
    relatedName: "asscom_tarifs"
  })
  public asscom!: number;
  public asscom_details!: Person;

  @DSForeignKeyField({
    name: "respcom",
    verboseName: "Resp. Comm.",
    required: false,
    helpText: "Responsable Commercial(e)",
    relatedModel: "people.Person",
    remoteField: "id",
    relatedName: "respcom_tarifs"
  })
  public respcom!: number;
  public respcom_details!: Person;

  @DSManyToManyField({
    name: "pilotes",
    verboseName: "Pilote(s)",
    required: false,
    helpText: "Pilote(s) tarifaire",
    relatedModel: "people.Person",
    remoteField: "id",
  })
  public pilotes!: number[];
  public pilotes_details!: Person[];

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;
  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "Etat",
    minLength: 0,
    maxLength: 4,
    choices: [
      ["PRP", "Futur"],
      ["CUR", "Courant"],
      ["ARC", "Archivé"]
    ]
  })
  public status!: string;


  @DSCharField({
    name: "user_level",
    verboseName: "Droits utilisateur",
    required: false,
    helpText: "Droits utilisateur",
    minLength: 0,
    maxLength: 10,
    choices: []
  })
  public user_level!: "anon" | "sadmin" | "admin" | "write" | "read";

  @DSBooleanField({
    name: "user_starred",
    verboseName: "Starred",
    required: false,
    helpText: "Etoile utilisateur",
    default: false,
  })
  public user_starred!: boolean;

  @DSBooleanField({
    name: "user_notified",
    verboseName: "Notified",
    required: false,
    helpText: "Notification utilisateur",
    default: false,
  })
  public user_notified!: boolean;

  @DSDefaultField({
    name: "parameters",
    verboseName: "Paramètres divers",
    required: false,
    helpText: "Paramètres divers",
    default: {}
  })
  public parameters!: any;

  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "Flags",
    default: {}
  })
  public flags!: string[];

  @DSDefaultField({
    name: "tarifs_ok",
    verboseName: "Tarifs valides",
    required: false,
    helpText: "Tarifs valides",
    default: []
  })
  public tarifs_ok!: { id: number; status: string; datestart: Date; dateend: Date }[];

  @DSDefaultField({
    name: "tarifs_pr",
    verboseName: "Tarifs en préparation",
    required: false,
    helpText: "Tarifs en préparation",
    default: []
  })
  public tarifs_pr!: { id: number; status: string; datestart: Date; dateend: Date }[];

  @DSDefaultField({
    name: "tarifs_tv",
    verboseName: "Tarifs à valider",
    required: false,
    helpText: "Tarifs à valider",
    default: []
  })
  public tarifs_tv!: { id: number; status: string; datestart: Date; dateend: Date }[];

  @DSTextField({
    name: "rrule",
    verboseName: "Règle de récurrence",
    required: false,
    helpText: "Règle de récurrence",
    minLength: 0,
    maxLength: 5000,
    choices: []
  })
  public rrule!: string;

  @DSIntegerField({
    name: "delta_prepared",
    verboseName: "Delta préparation",
    required: false,
    helpText: "Delta préparation (heures)",
    default: 96 - 12,
    choices: []
  })
  public delta_prepared!: number;

  @DSIntegerField({
    name: "delta_validated",
    verboseName: "Delta validation",
    required: false,
    helpText: "Delta validation (heures)",
    default: 72 - 12,
    choices: []
  })
  public delta_validated!: number;

  @DSIntegerField({
    name: "delta_published",
    verboseName: "Delta publication",
    required: false,
    helpText: "Delta publication (heures)",
    default: 48 - 12,
    choices: []
  })
  public delta_published!: number;

  public isAuth(level: "admin" | "write" | "read" | "anon"): boolean {
    const ALLOWED_LEVELS = {
      admin: ["sadmin", "admin"],
      write: ["sadmin", "admin", "write"],
      read: ["sadmin", "admin", "write", "read"],
      anon: ["sadmin", "admin", "write", "read", "anon"]
    };
    return ALLOWED_LEVELS[level].indexOf(this.user_level) !== -1;
  }

}

@Injectable({
  providedIn: "root"
})
export class TarifTypeService extends DSRestCollection<TarifType> {
  public adapter_config = {basePath: "/api/v2/tarifs/tariftypes"};
  public model = TarifType;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
