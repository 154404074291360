import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleOrigine, ArticleOrigineService} from "../article-origine.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "lvadg-article-origines-manage",
  templateUrl: "./article-origines-manage.component.pug",
  styleUrls: ["./article-origines-manage.component.sass"]
})
export class ArticleOriginesManageComponent extends ArticleDataManageBaseComponent<ArticleOrigine, ArticleOrigineService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleOrigineService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "origineId";
    this.filterField = "origine";
  }
}
