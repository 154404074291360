import {Pipe, PipeTransform} from "@angular/core";
import {DSModel} from "@solidev/ngdataservice";

@Pipe({
  name: "choice"
})
export class ChoicePipe implements PipeTransform {

  transform(value: string, model: DSModel, field: string): string {
    const choices = model._fields[field].choices;
    if (choices && choices.length) {
      for (const c of choices) {
        if (value === c[0]) {
          return c[1];
        }
      }
    }
    return (model as any)[field];
  }

}
