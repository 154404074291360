import {Component, EventEmitter, Input, Output, TemplateRef} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {ArticleTarifTemplate, ArticleTarifTemplateService, ArticleTarifTemplateStatus} from "../articletariftemplate.service";
import {TarifType} from "../../tariftype/tariftype.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {firstValueFrom, Subject} from "rxjs";
import {Article} from "../../../catalog/article/article.service";
import {Provider} from "../../../structure/provider/provider.service";
import {ExternalArticleService} from "../../../catalog/externalarticle/externalarticle.service";
import {TarifTypeRelation, TarifTypeRelationService} from "../../tariftyperelation/tariftyperelation.service";

export interface TarifTypeRelationColumn {
  verbose_name: string;
  name: string;
  relation: TarifTypeRelation;
}
@Component({
  selector: "lvadg-articletariftemplate-list",
  templateUrl: "./articletariftemplate-list.component.pug",
  styleUrls: ["./articletariftemplate-list.component.sass"]
})
export class ArticletariftemplateListComponent extends ModelListBaseComponent<ArticleTarifTemplate, ArticleTarifTemplateService> {
  @Input() public article_detail_route!: RouteV3<Article>;
  @Input() public provider_detail_route!: RouteV3<Provider>;
  @Input() public tarifType!: TarifType;
  @Output() public deleted = new EventEmitter<ArticleTarifTemplate>();
  public create: boolean = false;
  public reload = new Subject<void>();
  public STATUSES = [
    {value: "OK", desc: "Actif"},
    {value: "NOK", desc: "Désactivé"},
  ];
  private _createInstance?: NgbModalRef;
  public editEaclient: {[index:number]: boolean} = {};
  public ttrelations_columns: TarifTypeRelationColumn[] = [];

  constructor(
    _list: BsacModelListProvider,
    public att$: ArticleTarifTemplateService,
    private _modal: NgbModal,
    private _ttrels: TarifTypeRelationService,
    public ea$: ExternalArticleService,
    _msgs: BsacMessageService,
    routes: RoutesService,
  ) {
    super(_list, att$, _msgs, routes);
  }

  public async preNgOnInit(): Promise<void> {
    if (!this.default_fields || this.default_fields.length === 0) {
      this.default_fields = ["id", "name", "article__family__cfullname",  "article", "article_calibre", "article_categorie", "article_origine",
        "article_local", "article_egalim_details", "article_is_egalim", "article_colisage", "article_uci", "article_uc", "article_uf",
        "datestart", "dateend", "status"];
    }
    this.custom_fields = [
      {verbose_name: "Actions", name: "actions"},
      {verbose_name: "Code article client", name: "eaclient_code"},
      {verbose_name: "Libellé article client", name: "eaclient_libelle"},
    ];
    const ttrelations = (await firstValueFrom(this._ttrels.queryset.query({dest: this.tarifType.id}).get())).items
    this.ttrelations_columns = ttrelations.map((r) => {
      return {
        verbose_name: `Lien ${r.src_details.name}`,
        name: `ttrel_${r.id}`,
        relation: r
      }
    })
    this.custom_fields = this.custom_fields.concat(this.ttrelations_columns.map((c) => {
      return {verbose_name: c.verbose_name, name: c.name}
    }))
    this.default_fields = this.default_fields.concat(this.ttrelations_columns.map(r => r.name))
    this.select_actions = ["selected"]
  }

  public openCreate(content: TemplateRef<any>) {
    this._createInstance = this._modal.open(content, {size: "xl"});
  }

  public onCreated($event: any) {
    this.reload.next();
  }

  public onDeleted($event: any) {
    this.deleted.next($event);
    this.reload.next();
  }


  public getRowClasses(model: ArticleTarifTemplate): { [p: string]: string | boolean } {
    const cls = super.getRowClasses(model);
    if (model.status !== ArticleTarifTemplateStatus.ACTIVE) {
      cls["text-muted"] = true;
    }
    return cls;
  }
}
