import {NgModule} from "@angular/core";
import {SettingsValueListComponent} from "./settingsvalue-list/settingsvalue-list.component";
import {SettingsValueCreateComponent} from "./settingsvalue-create/settingsvalue-create.component";
import {SettingsValueManageComponent} from "./settingsvalue-manage/settingsvalue-manage.component";
import {SettingsvalueQuicksetComponent} from "./settingsvalue-quickset/settingsvalue-quickset.component";
import {SettingsValueDeleteComponent} from "./settingsvalue-delete/settingsvalue-delete.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";

@NgModule({
  declarations: [
    SettingsValueListComponent,
    SettingsValueCreateComponent,
    SettingsValueManageComponent,
    SettingsvalueQuicksetComponent,
    SettingsValueDeleteComponent
  ],
  exports: [
    SettingsValueListComponent,
    SettingsValueCreateComponent,
    SettingsValueManageComponent,
    SettingsvalueQuicksetComponent,
    SettingsValueDeleteComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS
  ]
})
export class SettingsValueModule {
}
