/**
 * Create provider.
 *
 * Admin only. TODO
 *
 * @status TODO
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @date 2018-05-28-23:16:54
 * @changed 2018-05-28-23:16:54
 * @copyright (c) 2017-2018 Jean-Matthieu BARBIER
 * @license AGPLv3
 */
import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {Provider, ProviderService} from "../provider.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: "lvadg-provider-create",
  templateUrl: "./provider-create.component.pug",
  styleUrls: ["./provider-create.component.sass"]
})
export class ProviderCreateComponent implements OnInit {
  public provider!: Provider;
  public creation_fields: string[] = ["name", "type", "legal", "siret"];

  public form!: UntypedFormGroup;

  @Output() public created: EventEmitter<Provider> = new EventEmitter<Provider>();
  @Output() public cancelled: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _providers: ProviderService,
              private _fb: UntypedFormBuilder) { }

  public ngOnInit(): void {
    // FIXME: use better create mode
    // this._providers.create().subscribe((model) => {
    //   this.provider = model;
    //   const group = {};
    //   if (this.creation_fields.length === 0) {
    //     for (const f of Object.keys(this.provider._fields)) {
    //       if (this.provider._fields[f].required) {
    //         this.creation_fields.push(f);
    //       }
    //     }
    //   }
    //   for (const f of Object.keys(this.provider._fields)) {
    //     if (this.creation_fields.indexOf(f) !== -1) {
    //       group[f] = [this._providers[f],
    //         this.provider._fields[f].validators,
    //         this.provider._fields[f].asyncvalidators
    //       ];
    //     }
    //   }
    //   this.form = this._fb.group(group);
    // });
  }


  public create() {
    if (this.form.valid) {
      const data = this.form.value;
      data.status = "KO";
      this._providers.create(data, {save: true}).subscribe((saved) => {
        this.created.emit(saved);
      });
    }
  }

  public cancel() {
    this.cancelled.emit(true);
  }
}
