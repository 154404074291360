import {Resto, RestoService} from "../resto.service";
import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {Observable} from "rxjs";
import {Client} from "../../client/client.service";
import {Storage} from "../../storage/storage.service";

@Component({
  selector: "lvadg-resto-select",
  templateUrl: "./resto-select.component.pug",
  styleUrls: ["./resto-select.component.sass"]
})
export class RestoSelectComponent implements OnChanges {
  public queryset!: DSRestQueryset<Resto>;
  @Input() public hint = "Selectionnez un restaurant";
  @Input() public reset!: Observable<any>;
  @Input() public label = "Sélectionnez un restaurant";
  @Input() public desctpl!: string;
  @Input() public descfield = "name";
  @Input() public filter: any = {};
  @Input() public searchparam = "search";

  @Input() public client!: Client;
  @Input() public storage!: Storage;

  @Output() public selected: EventEmitter<Resto> = new EventEmitter<Resto>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();


  constructor(public _service: RestoService) {
  }

  public ngOnChanges(): void {
    if (this.client) {
      this.filter.client = this.client.id;
    }
    if (this.storage) {
      this.filter.storage = this.storage.id;
    }

  }

  public selItem(model: any): void {
    this.selected.emit(model);
  }


}
