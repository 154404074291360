// @changed 2018-03-10-00:40:54

import {ProductFamily, ProductFamilyService} from "../productfamily.service";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {IDSQueryset} from "@solidev/ngdataservice";
import {Observable} from "rxjs";
import {debounceTime, map, switchMap} from "rxjs/operators";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: "lvadg-productfamily-select",
  templateUrl: "./productfamily-select.component.pug",
  styleUrls: ["./productfamily-select.component.sass"]
})
export class ProductFamilySelectComponent implements OnInit {
  public queryset!: IDSQueryset<ProductFamily, any, any>;
  @Input() public hint = "Selectionnez la categorie de produit";
  @Input() public level?: number;
  @Input() public short = false;
  @Input() public clear!: Observable<any>;
  @Output() public selected: EventEmitter<ProductFamily> = new EventEmitter<ProductFamily>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();
  public family!: ProductFamily;
  public form!: UntypedFormGroup;
  public getName = this._getName.bind(this);
  public search = this._search.bind(this);


  constructor(private _model: ProductFamilyService, private _fb: UntypedFormBuilder) {
    this.queryset = this._model.queryset;
    if (this.level !== undefined) {
      this.queryset.query({level: this.level});
    }
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      "selector": [""]
    });
    if (this.clear && this.clear.subscribe) {
      this.clear.subscribe(() => {
        this.form.setValue({selector: ""}); // , {onlySelf: true});
      });
    }
  }

  public _search(text$: Observable<string>): Observable<any> {
    return text$.pipe(
      debounceTime(200),
      switchMap((term) => {
        const qs: any = {search: term};
        if (this.level) {
          qs.level = this.level;
        }
        this.queryset.query(qs).paginate({page_size: 100, page: 1});
        return this.queryset.get().pipe(
          map((result: any) => {
            return result.items;
          }));
      }));
  }

  public _getName(x: ProductFamily): string {
    if (this.short) {
      return x.cname;
    }
    return x.cfullname;
  }


  public selItem(model: any): void {
    this.selected.emit(model.item);
  }

}
