// Confirm delete button/popover.
// Add a confirmation step before model deletion action.
// @copyright 2019 JM Barbier <jm.barbier@solidev.net>
// @status OK
// @updated 2019-01-27-13:30:13

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {DSModel} from "@solidev/ngdataservice";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-confirm-delete",
  templateUrl: "./confirm-delete.component.pug",
  styleUrls: ["./confirm-delete.component.sass"]
})
export class ConfirmDeleteComponent implements OnInit {
  @Input() public model!: DSModel;
  @Input() public type: "button" | "trash" = "button";
  @Input() public mode: "delete" | "action" = "delete";
  @Input() public classes: string[] = [];
  @Input() public dispMessage: boolean = true;
  @Output() public deleted = new EventEmitter<any>();
  @Output() public confirm = new EventEmitter<any>();
  @ViewChild("confirmDeletePopover") private _confirmDeletePopover?: NgbPopover;

  constructor(private _msg: BsacMessageService) {
  }


  public ngOnInit() {
    if (this.type === "button") {
      if (this.classes && this.classes.length) {
        let found = false;
        for (const c of this.classes) {
          if (["btn-danger", "btn-outline-danger", "btn-warning", "btn-outline-warning",
            "btn-success", "btn-outline-success", "btn-primary", "btn-outline-primary"].indexOf(c) !== -1) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.classes.push("btn-danger");
        }
      } else {
        this.classes = ["btn-danger"];
      }
    }
  }

  public async confirmDelete(): Promise<any> {
    if (!this.model) {
      this.confirm.emit();
      this._confirmDeletePopover?.close();
    } else {
      try {
        await firstValueFrom(this.model.delete());
        if (this.dispMessage) {
          this._msg.success("Suppression réussie");
        }
        this.deleted.emit(this.model);
      } catch (e) {
        this._msg.error("Erreur lors de la suppression", `${e}`);
      }
        this._confirmDeletePopover?.close();
    }
  }

  public cancelDelete() {
    this._confirmDeletePopover?.close();
  }

}
