import {Injectable} from "@angular/core";
import {Folder, FolderService} from "./folder.service";
import {firstValueFrom, Observable, of, ReplaySubject} from "rxjs";
import {map, take, tap} from "rxjs/operators";
import {Document, DocumentService} from "../document/document.service";

export interface Selection {
  type: "folder" | "document" | "none";
  folder: Folder | null;
  document: Document | null;
}


export class FolderTree {
  // Initial load done ?
  public loaded = false;
  // Show folder/file properties ?
  public display = {create: false, createButton: false, aslist: false, properties: false};
  // Folder statuses (open/close)
  private _status: { [index: number]: any } = {};
  // Parent query cache
  private _qpcache: { [index: number]: ReplaySubject<Folder[]> } = {};
  // Subfolder query cache
  private _qsfcache: { [index: number]: ReplaySubject<Folder[]> } = {};
  // Zone
  private _zone = "FLD";

  // Single/double click detection
  private _isSingleClick = true;
  private _isSingleClickTimer: any;

  constructor(private _folders: FolderService,
              private _documents: DocumentService,
              zone: string = "FLD") {
    this._zone = zone;
  }

  // Current folder subject
  private _current$ = new ReplaySubject<Folder>(1);

  public get current$(): Observable<Folder> {
    return this._current$.asObservable();
  }

  // Current selected folder / document
  public _selected$ = new ReplaySubject<Selection>(1);

  public get selected$(): Observable<Selection> {
    return this._selected$.asObservable();
  }

  // Current selection
  private _selected: Selection = {type: "none", folder: null, document: null};

  public get selected(): Selection {
    return this._selected;
  }

  // Current folder private
  private _current: Folder | null;

  // Current folder getter
  public get current(): Folder | null {
    return this._current;
  }

  // Get zone folder queryset
  public get queryset(): any {
    return this._folders.queryset.query({gzone: this._zone});
  }

  public get filter(): any {
    return {gzone: this._zone};
  }

  // ------------------------------------ STATIC HELPERS -------------------------------------
  public static getIdFromPath(path: string): number | null {
    const m = /^[a-zA-Z0-9_-]+-([0-9]+)$/;
    const res = m.exec(path);
    if (res !== null) {
      return parseInt(res[1], 10);
    } else {
      return null;
    }
  }

  // ----------------------------------- CURRENT BEHAVIOUR --------------------------
  /**
   * Set current folder & open it
   * @param f
   */
  public async setCurrent(f: Folder | null = null): Promise<void> {
    if (f && f.id) {
      this._current = await firstValueFrom(this.getFolder(f.id));
    } else {
      this._current = f;
    }
    if (this._current) {
      await this.open(this._current);
      this.setSelected("folder", this._current);
      this._current$.next(this._current);
    }
  }

  /**
   * Check if given folder is current.
   * @param folder folder to check
   */
  public isCurrent(folder: Folder | null = null): boolean {
    if (!folder) {
      return !!this._current;
    }
    if (!this._current) {
      return false;
    }
    return this._current.id === folder.id;
  }

  // --------------------------------------------------------------------------------
  // -----------------    FOLDERS OPEN / CLOSE --------------------------------------

  /**
   * Load a folder, open it and set it to current
   * @param folderId
   */
  public async load(folderId: number | null) {
    const folder = await firstValueFrom(this.getFolder(folderId));
    await this.getSubFolders(folder, true);
    await this.getDocuments(folder);
    await this.open(folder, true);
    this._current = folder;
    this._current$.next(this._current);
    this.loaded = true;
  }

  /**
   * Reload current folder
   */
  public async reload() {
    if (this._current && this._current.id) {
      await this.load(this._current.id);
    } else {
      await this.load(null);
    }
  }

  /**
   * Open folder.
   * @param folder Folder to open
   * @param openParents If true, also recursively open parents
   */
  public async open(folder: Folder, openParents = true): Promise<any> {
    if (!folder) {
      return;
    }
    if (!this._status[folder.id]) {
      this._status[folder.id] = {};
    }
    this._status[folder.id].opened = true;
    if (openParents) {
      await firstValueFrom(this.getParents(folder).pipe(
        take(1),
        tap((parents) => {
          for (const parent of parents) {
            this.open(parent, false);
          }
        })));
    }
  }

  // ----------------------------------------------------------------------------------------------
  // -------------------  CONTENT RETRIEVAL  ------------------------------------------------------

  /**
   * Close a folder.
   * @param folder Folder to close.
   */
  public close(folder: Folder): void {
    if (!this._status[folder.id]) {
      this._status[folder.id] = {};
    }
    this._status[folder.id].opened = false;
  }

  /**
   * Check if a folder is opened.
   * @param folder Folder to check
   */
  public opened(folder: Folder): boolean {
    if (!this._status[folder.id]) {
      return false;
    }
    return this._status[folder.id].opened;
  }

  /**
   * Load folder details.
   * @param folderId Folder id to load.
   */
  public getFolder(folderId: number | null): Observable<Folder | null> {
    if (folderId !== null) {
      return this._folders.get(folderId).pipe(take(1));
    }
    return of(null);
  }

  /**
   * Load sub folders.
   * @param folder Parent folder
   * @param reload Force reload if true
   */
  public getSubFolders(folder: Folder | null, reload: boolean = false): Observable<Folder[]> {
    const qry: any = {};
    let id = -1;
    if (folder) {
      qry.parent = folder.id;
      id = folder.id;
    } else {
      qry.level = 0;
      qry.gzone = this._zone;
    }
    if (!this._qsfcache[id]) {
      this._qsfcache[id] = new ReplaySubject<Folder[]>(1);
      reload = true;
    }
    if (reload) {
      this._folders.queryset.query(qry).get().pipe(take(1))
        .subscribe((folders) => {
          this._qsfcache[id].next(folders.items);
        });
    }
    return this._qsfcache[id].asObservable();
  }

  /**
   * Load parent folder.
   * @param folder Child folder.
   */
  public getParent(folder: Folder): Observable<Folder | null> {
    if (folder.parent) {
      return this._folders.get(folder.parent).pipe(take(1));
    }
    return of(null);
  }

  // ----------------------------------- FOLDER DOCUMENTS -------------------------------------

  /**
   * Load folder parents. Queries are cached.
   * TODO: add another cache level for already fetched parents ?
   * @param folder Child folder
   * @param reload Force reload if true
   */
  public getParents(folder: Folder, reload: boolean = false): Observable<Folder[]> {
    if (!folder) {
      return of([]);
    }
    if (!this._qpcache[folder.id]) {
      reload = true;
      this._qpcache[folder.id] = new ReplaySubject<Folder[]>(1);
    }
    if (reload) {
      this._folders.action(folder, "parents", {method: "GET"}).pipe(
        take(1)
      ).subscribe((results) => {
        this._qpcache[folder.id].next(results);
      });
    }
    return this._qpcache[folder.id].asObservable();
  }

  /**
   * Load folder documents
   * @param folder Parent folder
   */
  public getDocuments(folder: Folder | null): Observable<any[]> {
    if (folder && folder.id) {
      return this._documents.queryset.query({folders: folder.id}).get().pipe(
        take(1),
        map((results) => {
          return results.items;
        })
      );
    } else {
      return of([]);
    }
  }

  // ---------------------------- SELECTION MANAGEMENT ---------------------------------------
  /**
   * Set current selected document / folder.
   * @param type selection type (folder, document or none)
   * @param item selected object
   */
  public setSelected(type: "document" | "folder" | "none", item: Document | Folder): void {
    this._selected.type = type;
    if (this._selected.type === "folder") {
      this._selected.document = null;
      this._selected.folder = item as Folder;
    } else if (this._selected.type === "document") {
      this._selected.folder = null;
      this._selected.document = item as Document;
    } else {
      this._selected.folder = null;
      this._selected.document = null;
    }
    this._selected$.next(this._selected);
  }


  public doubleClick(type: "document" | "folder" | "none", item: Document | Folder): void {
    this._isSingleClick = false;
    clearTimeout(this._isSingleClickTimer);
    if (this.display.properties) {
      if (type === "folder") {
        this.setCurrent(item as Folder);
      } else if (type === "document") {
        this.download(item as Document);
      } else {
        console.error("KOIFAIRE ?");
      }
    } else {
      this.display.properties = true;
      if (type === "folder") {
        this.setSelected(type, item);
      } else if (type === "document") {
        this.setSelected(type, item);
      } else {
        console.error("KOIFAIRE ?");
      }
    }
  }

  public singleClick(type: "document" | "folder" | "none", item: Document | Folder): void {
    this._isSingleClick = true;
    clearTimeout(this._isSingleClickTimer);
    this._isSingleClickTimer = setTimeout(() => {
      if (this._isSingleClick) {
        if (this.display.properties) {
          if (type === "folder") {
            this.setSelected(type, item);
          } else if (type === "document") {
            this.setSelected(type, item);
          } else {
            console.error("KOIFAIRE ?");
          }
        } else {
          if (type === "folder") {
            this.setCurrent(item as Folder);
          } else if (type === "document") {
            this.download(item as Document);
          } else {
            console.error("KOIFAIRE ?");
          }
        }
      }
    }, 250);
  }

  public download(item: Document): void {
    item.download();
  }

  // ----------------------------- FOLDER ACTIONS (remove, move) -----------------------------
  public async removeFolder(folder: Folder): Promise<void> {
    if (this._current && this._current.id === folder.id) {
      const parent = await firstValueFrom(this.getParent(folder));
      await firstValueFrom(folder.delete());
      await this.setCurrent(parent);
      await this.reload();
    } else {
      await firstValueFrom(folder.delete());
      await this.reload();
    }
  }

  public async moveFolder(folder: Folder, dest: Folder): Promise<void> {
    if (this._current && this._current.id === folder.id) {
      const parent = await firstValueFrom(this.getParent(folder));
      await this.setCurrent(parent);
      await firstValueFrom(folder.action("move", {method: "POST", body: {dest: dest.id}}));
      await this.reload();
    } else {
      await firstValueFrom(folder.action("move", {method: "POST", body: {dest: dest.id}}));
      await this.reload();
    }
    await firstValueFrom(this.getSubFolders(dest, true));
    await firstValueFrom(this.getParents(folder, true));

  }

}


@Injectable({
  providedIn: "root"
})
export class FolderTreeService {
  public _trees: { [index: string]: FolderTree } = {};

  constructor(private _folders: FolderService, private _documents: DocumentService) {

  }

  /**
   * Load or create a FolderTree object, with name and zone.
   * @param name folder tree name
   * @param zone folder tree zone
   */
  public load(name: string, zone: string = "FLD"): FolderTree {
    if (!this._trees[name]) {
      this._trees[name] = new FolderTree(this._folders, this._documents, zone);
    }
    return this._trees[name];
  }
}
