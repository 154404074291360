import {Injectable} from "@angular/core";
import {PrinterFieldService} from "./printerfield.service";
import {CommFieldParamsService} from "../basecomm/commfield/commfieldparams.service";


/**
 * Manage printer field parameter.
 *
 * Load, update, change print parameters.
 *
 */
@Injectable({
  providedIn: "root"
})
export class PrinterFieldParamsService extends CommFieldParamsService {

  constructor(_of: PrinterFieldService) {
    super();
    this._of = _of;
  }
}
