import {add, getWeek, set} from "date-fns";
import {TarifType} from "../tariftype/tariftype.service";

export const tarifTemplate = (tpl: string, datestart: Date, dateend: Date): string => {
  if (!tpl) {
    tpl = "du (DJJJ) (DJ) (DMM) (DY) (S(DW)) au (FJJJ) (FJ) (FMM) (FY)";
  }
  const TPLTAGS: {[index: string]: number|string} = {
    "DA": datestart.getFullYear(),
    "DY": datestart.getFullYear(),
    "DS": getWeek(datestart, {locale: {code: "fr-fr"}, weekStartsOn: 1, firstWeekContainsDate: 5}),
    "DW": getWeek(datestart, {locale: {code: "fr-fr"}, weekStartsOn: 1, firstWeekContainsDate: 5}),
    "DM": datestart.getMonth() + 1,
    "DMM": ["jan", "fév", "mar", "avr", "mai", "jun", "jul", "aou", "sep", "oct", "nov", "dec"][datestart.getMonth()],
    "DMMM": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet",
      "août", "septembre", "octobre", "novembre", "décembre"][datestart.getMonth()],
    "DJ": datestart.getDate(),
    "DJJ": ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"][datestart.getUTCDay()],
    "DJJJ": ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"][datestart.getUTCDay()],
    "DD": datestart.getDate(),
    "DDD": ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"][datestart.getUTCDay()],
    "DDDD": ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"][datestart.getUTCDay()],
    "FA": dateend.getFullYear(),
    "FY": dateend.getFullYear(),
    "FM": dateend.getMonth() + 1,
    "FS": getWeek(dateend, {locale: {code: "fr-fr"}, weekStartsOn: 1, firstWeekContainsDate: 5}),
    "FW": getWeek(dateend, {locale: {code: "fr-fr"}, weekStartsOn: 1, firstWeekContainsDate: 5}),
    "FMM": ["jan", "fév", "mar", "avr", "mai", "jun", "jul", "aou", "sep", "oct", "nov", "dec"][dateend.getMonth()],
    "FMMM": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet",
      "août", "septembre", "octobre", "novembre", "décembre"][dateend.getMonth()],
    "FJ": dateend.getDate(),
    "FJJ": ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"][dateend.getUTCDay()],
    "FJJJ": ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"][dateend.getUTCDay()],
    "FD": dateend.getDate(),
    "FDD": ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"][dateend.getUTCDay()],
    "FDDD": ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"][dateend.getUTCDay()],
  };

  for (const tp of Object.keys(TPLTAGS)) {
    tpl = tpl.replace("(" + tp + ")", `${TPLTAGS[tp]}`);
  }
  return tpl;
};


export const toDateEnd = (tt: TarifType, datestart: Date): Date => {
  const INTERVALS: {[index: string]:  (date:Date) => Date} = {
    W1: (date) => add(date, {weeks: 1}),
    W2: (date: Date) => add(date, {weeks: 2}),
    M1: (date: Date) => add(date, {months: 1}),
    M2: (date: Date) => add(date, {months: 2}),
    M3: (date: Date) => add(date, {months: 1}),
    M6: (date: Date) => add(date, {months: 6}),
    M10: (date: Date) => add(date, {months: 10}),
    M12: (date: Date) => add(date, {years: 1}),
    CS: (date: Date) => date
  };
  let fn = INTERVALS[tt.base_interval];
  if (!fn) {
    fn = INTERVALS["CS"];
  }
  return add(set(fn(datestart), {hours: 23, minutes: 59, seconds: 59, milliseconds: 0}), {days: -1});
};
