import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {CiqualColumn, CiqualColumnService} from "./ciqualcolumn.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class CiqualColumnResolve implements Resolve<CiqualColumn> {
  constructor(private _bs: CiqualColumnService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<CiqualColumn> {
    return this._bs.get(+route.params["ciqualcolumnId"]);
  }
}
