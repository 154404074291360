import {Component, Input} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {Tarif, TarifService} from "../tarif.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {TarifType, TarifTypeService} from "../../tariftype/tariftype.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {MIMEICONS, TarifDocument, TarifDocumentService} from "../../tarifdocument/tarifdocument.service";
import {ActivatedRoute} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-tarif-list",
  templateUrl: "./tarif-list.component.pug",
  styleUrls: ["./tarif-list.component.sass"],
})
export class TarifListComponent extends ModelListBaseComponent<Tarif, TarifService> {
  @Input() public tarif_detail_route!: RouteV3<Tarif>;
  @Input() public tariftype_detail_route!: RouteV3<TarifType>;
  @Input() public tarifType?: TarifType;

  public STATUSES = [
    {value: "PR", desc: "En préparation"},
    {value: "TV", desc: "A valider"},
    {value: "OK", desc: "Valide"},
    {value: "AR", desc: "Archivé"},
  ];
  public PREFERRED_CHOICES = [
    {value: "all", desc: "Tous.."},
    {value: "starred", desc: "Préférés"},
    {value: "notified", desc: "Notifiés"}
  ];
  public ZONES_CHOICES = [
    {value: "FL", desc: "Fruits et légumes"},
    {value: "SEA", desc: "Marée"},
    {value: "45G", desc: "4&5eme gamme"}
  ];

  public ttypes$!: DSRestQueryset<TarifType>;

  constructor(
    _list: BsacModelListProvider,
    _items: TarifService,
    _msgs: BsacMessageService,
    routes: RoutesService,
    private _td: TarifDocumentService,
    private _route: ActivatedRoute,
    private _ttypes: TarifTypeService
  ) {
    super(_list, _items, _msgs, routes);
  }

  public async preNgOnInit(): Promise<void> {
    this.ttypes$ = this._ttypes.queryset;
    this.custom_fields = [{name: "type__zone", verbose_name: "Zone"}];
  }

  public async postNgOnInit(): Promise<void> {
    this._route.queryParams.pipe(takeUntil(this._subscriptions$)).subscribe((qp) => {
      if (qp.status) {
        this.list.filter.set("status", qp.status);
      }
    });
    this.list.filter.set("type__status", "CUR", "Courant");
  }

  public getRowClasses(model: Tarif): { [p: string]: string | boolean } {
    const out: {[index: string]: boolean} = {};
    if (model.status === "AR") {
      out["text-muted"] = true;
    }
    if (model.status === "OK") {
      out["table-success"] = true;
    }
    if (model.status === "TV") {
      out["table-warning"] = true;
    }
    return out;
  }


  public docIcon(d: TarifDocument): string {
    if (MIMEICONS[d.mimetype]) {
      return MIMEICONS[d.mimetype];
    }
    return "fa-file";
  }

  public async download(f: TarifDocument) {
    const d = await firstValueFrom(this._td.create(f, {save: false}));
    d.download();
  }

  public getDetailLink(model: Tarif): (string | number)[] {
    if (this.tarif_detail_route) {
      return this.tarif_detail_route.route({tarif: model});
    }
    if (this.tarifType) {
      console.warn("Using legacy route");
      return this.routes.get(this.zone, "tarif", this.tarifType, model);
    }
    console.warn("Using legacy route");
    return this.routes.get(this.zone, "tarif", model);
  }

}
