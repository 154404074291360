import {Injectable} from "@angular/core";
import {DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseLabel, BaseLabelService} from "../_label/label-base.model";

export class Label extends BaseLabel {


}

@Injectable({providedIn: "root"})
export class LabelService extends BaseLabelService<Label> {
  public adapter_config = {basePath: "/api/v2/labels"};
  public model = Label;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}

