import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {CommonBaseComponent} from "../../common-base.component";
import {AuthService} from "../../../../lib/models/user/auth.service";
import {TarifType} from "../../../../lib/models/tarif/tariftype/tariftype.service";
import {Tarif} from "../../../../lib/models/tarif/tarif/tarif.service";

export interface TarifListViewV3Params extends BaseRouteV3Params {
  tarif_detail_route: RouteConfigItemV3;
  tariftype_detail_route: RouteConfigItemV3;
  ttype: "gen" | "cli" | "all";
}

export interface TarifListViewV3Data extends TarifListViewV3Params {
  tarifType?: TarifType;
}

@Component({
  selector: "lvadg-tarif-list-view",
  templateUrl: "./tarif-list-view.component.pug",
  styleUrls: ["./tarif-list-view.component.sass"]
})
export class TarifListViewV3Component extends CommonBaseComponent {
  public create = false;
  public ttype: "gen" | "cli" | "all" = "all";
  public tarifType?: TarifType;
  public default_fields = ["id", "name", "type", "datestart", "dateend", "documents_details", "status"];
  public default_filters = ["search", "tariftypes", "validdate", "preferred", "status"];
  public filter: { [index: string]: string } = {};

  public tarif_detail_route!: RouteV3<Tarif>;
  public tariftype_detail_route!: RouteV3<TarifType>;

  constructor(routes: RoutesService,
              router: Router,
              route: ActivatedRoute,
              public auth: AuthService) {
    super(routes, router, route);
  }

  public async preNgOnInit(): Promise<void> {
    console.log("Yo");
    const data = this.route.snapshot.data as TarifListViewV3Data;
    this.ttype = data.ttype;
    if (this.ttype !== "all") {
      this.filter = {type__type: this.ttype.toUpperCase()};
    } else {
      this.filter = {}
    }
    this.tarifType = data.tarifType;
    if (this.tarifType) {
      this.filter.type = this.tarifType.id.toString(10);
    }
    this.tarif_detail_route = new RouteV3<Tarif>(data.tarif_detail_route, this);
    this.tariftype_detail_route = new RouteV3<TarifType>(data.tariftype_detail_route, this);
    return super.preNgOnInit();
  }

  public onCreated(tr: Tarif) {
    this.router.navigate(this.tarif_detail_route.route({tarif: tr}));
  }


}
