import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProductPrice} from "../_productprice/productprice-base.model";

export class ProductPrice extends BaseProductPrice {
}

@Injectable({
  providedIn: "root"
})
export class ProductPriceService extends DSRestCollection<ProductPrice> {
  public adapter_config = {basePath: "/api/v2/productprices"};
  public model = ProductPrice;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
