import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TarifNotificationGroupListmanageComponent} from "./tarifnotificationgroup-listmanage/tarifnotificationgroup-listmanage.component";
import {ReactiveFormsModule} from "@angular/forms";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {CompsModule} from "../../../comps/comps.module";


@NgModule({
  declarations: [
    TarifNotificationGroupListmanageComponent
  ],
  exports: [
    TarifNotificationGroupListmanageComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsacFormsModule,
    CompsModule
  ]
})
export class TarifNotificationGroupModule { }
