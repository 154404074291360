
<div class="row g-3">
  <div class="col">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-info-circle me-2"></i>Infos fournisseur</div>
      <div class="card-body">
        <dl class="row" *ngIf="provider">
          <bsac-dd-field class="col-sm-4" *ngIf="checkMode('admin')" [model]="provider" field="id" type="auto" [editable]="false">ID</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" *ngIf="checkMode('admin')" [model]="provider" field="cvva" type="auto" [editable]="false">Code vivalya</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="name" type="auto" [editable]="checkMode('admin')">Nom</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="type" type="auto" [editable]="checkMode('admin')">Type</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="content_src" type="auto">Description</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="phone" type="auto">Téléphone</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="fax" type="auto">Fax</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="website" type="auto">Site internet</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="siren" type="auto">SIREN</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="siret" type="auto">SIRET</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="tvaic" type="auto">TVAIC</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="naf" type="auto">NAF</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="legal" type="auto">Forme légale</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="capital" type="auto">Capital</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="provider" field="status" type="auto" *ngIf="checkMode('admin')">Etat</bsac-dd-field>
          <bsac-flags-field class="col-sm-4" [model]="provider" [free]="true" *ngIf="checkMode('admin')">Flags</bsac-flags-field>
        </dl>
        <hr>
        <dl>
          <dt>Labels structure</dt>
          <dd>
            <lvadg-slabel-setunset [model]="provider"></lvadg-slabel-setunset>
          </dd>
        </dl>
      </div>
    </div>
  </div>
  <div class="col" *ngIf="checkMode('admin','provideradmin')">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-info-circle me-2"></i>Données spécifiques</div>
      <div class="card-body">
        <lvadg-customdata-manage [model]="provider" dest="PRV"></lvadg-customdata-manage>
      </div>
    </div>
  </div>
</div>
<div class="mt-3">
  <div class="card">
    <div class="card-header"><i class="fa fa-map-marker fa-s"></i>Localisation(s)</div>
    <div class="card-body">
      <lvadg-locations-manage [model]="provider" type="provider" [mode]="mode"></lvadg-locations-manage>
    </div>
  </div>
</div>
<div class="mt-3">
  <div class="card">
    <div class="card-header"><i class="fa fa-images fa-s"></i>Logos et images</div>
    <div class="card-body">
      <lvadg-images-manage [model]="provider"></lvadg-images-manage>
    </div>
  </div>
</div>
<div class="mt-3">
  <div class="card">
    <div class="card-header"><i class="fa fa-file-archive me-2"></i>Documents</div>
    <div class="card-body">
      <h3>Fiches techniques</h3>
      <lvadg-detaildocument-listmanage [model]="provider" [documents]="provider.documents_details" (deleted)="onDocumentDeleted()" (added)="onUploaded($event)" type="FT" [fields]="['title','type', 'status', 'prlabel', 'articles']"></lvadg-detaildocument-listmanage>
      <h3>Documents labels & certifications produits</h3>
      <lvadg-detaildocument-listmanage [model]="provider" [documents]="provider.documents_details" (deleted)="onDocumentDeleted()" (added)="onUploaded($event)" type="DE" [fields]="['title','type', 'start', 'end', 'status', 'prlabel', 'articles']"></lvadg-detaildocument-listmanage>
      <h3>Documents label structure</h3>
      <lvadg-detaildocument-listmanage [model]="provider" [documents]="provider.documents_details" (deleted)="onDocumentDeleted()" (added)="onUploaded($event)" type="DL" [fields]="['title','type', 'start', 'end', 'status', 'stlabel']"></lvadg-detaildocument-listmanage>
    </div>
  </div>
</div>