import {Inject, Injectable, InjectionToken, PLATFORM_ID} from "@angular/core";
import {CurrentSiteService} from "../../authentication/site/current-site.service";
import {first} from "rxjs/operators";
import "autotrack/autotrack.js";

export const LVADG_ANALYTICS_ID = new InjectionToken<string>("lvadg.analytics.id");


@Injectable({
  providedIn: "root"
})
export class AnalyticsService {

  constructor(private _site: CurrentSiteService,
              @Inject(PLATFORM_ID) private _platform: any,
              @Inject(LVADG_ANALYTICS_ID) private _analyticsId: string) {
  }


  public get ga(): any {
    return (window as any).ga;
  }

  public enable() {

    (function (window: Window, document: Document, strScript: string, url: string, variableName: string, scriptElement?: any, firstScript?: any) {

      // @ts-ignore
      window["GoogleAnalyticsObject"] = variableName;
      // @ts-ignore
      window[variableName] = window[variableName] || function () {
        // @ts-ignore
        (window[variableName].q = window[variableName].q || []).push(arguments);
      };
      // @ts-ignore
      window[variableName].l = 1 * (new Date() as any);
      // @ts-ignore
      scriptElement = document.createElement(strScript),
        // @ts-ignore
        firstScript = document.getElementsByTagName(strScript)[0];
      // @ts-ignore
      scriptElement.async = 1;
      // @ts-ignore
      scriptElement.src = url;
      // @ts-ignore
      firstScript.parentNode.insertBefore(scriptElement, firstScript);
    })(window, document, "script", "//www.google-analytics.com/analytics.js", "ga");

    this.ga("create", this._analyticsId, "auto");
    this.ga("require", "eventTracker");
    this.ga("require", "outboundLinkTracker");
    this.ga("require", "urlChangeTracker");
    this._site.site$.pipe(first()).subscribe((site) => {
      if (site) {
        this.ga("set", "dimension1", site.domain);
        if (site.domain === "admin") {
          this.ga("set", "dimension2", "admin");
        } else {
          this.ga("set", "dimension2", site.type);
        }
        this.ga("send", "pageview");
      }
    });
  }

  public event(cat: string, action: string, label: string, value: number | null = null) {
    if (value !== null) {
      this.ga("send", "event", cat, action, label, value);
    } else {
      this.ga("send", "event", cat, action, label);
    }
  }

}
