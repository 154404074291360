import {Component, Input} from "@angular/core";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {Client} from "../../../../lib/models/structure/client/client.service";
import {CommonBaseComponent} from "../../common-base.component";
import {Resto} from "../../../../lib/models/structure/resto/resto.service";
import {VivalyaBill} from "../../../../lib/models/sales/bill/bill.service";
import {ActivatedRoute, Router} from "@angular/router";

export interface BillListViewV3Params extends BaseRouteV3Params {
  bill_detail_route: RouteConfigItemV3;
}

export interface BillListViewV3Data extends BillListViewV3Params {
  client?: Client;
}

@Component({
  selector: "lvadg-bill-list-v3-view",
  templateUrl: "./bill-list-view.component.pug",
  styleUrls: ["./bill-list-view.component.sass"]
})
export class BillListViewV3Component extends CommonBaseComponent {
  @Input() public client!: Client;
  @Input() public resto!: Resto;

  public default_filters = [
    "search", "clients", "storages", "members",
    "articles", "restos", "tarifs", "datestart", "dateend"
  ];
  public bill_detail_route!: RouteV3<VivalyaBill>;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as BillListViewV3Data;
    this.client = data.client || this.client;
    this.bill_detail_route = new RouteV3<VivalyaBill>(data.bill_detail_route, this);
    return super.preNgOnInit();
  }
}
