import {Pipe, PipeTransform} from "@angular/core";
import {DSModel} from "@solidev/ngdataservice";
import {formatCurrency} from "@angular/common";


@Pipe({
  name: "dsCurrency"
})
export class DsCurrencyPipe implements PipeTransform {

  transform(value: DSModel, field: string, currency?: string, currencyCode?: string, digitsInfo?: string): string {
    if (!(value as any)[field] && (value as any)[field] !== 0) {
      return "-";
    }
    if (!currency) {
      currency = "€";
    }
    const factor = (value._fields[field] as any).factor;
    if (factor) {
      return formatCurrency((value as any)[field] / factor, "fr", currency, currencyCode, digitsInfo);
    } else {
      return formatCurrency((value as any)[field], "fr", currency, currencyCode, digitsInfo);
    }
  }

}
