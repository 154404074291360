import {Component, Input} from "@angular/core";
import {CommonBaseComponent} from "../../common-base.component";
import {VivalyaBill} from "../../../../lib/models/sales/bill/bill.service";
import {BaseRouteV3Params, RouteConfigItemV3, RouteV3} from "../../../../lib/comps/routes.service";
import {Client} from "../../../../lib/models/structure/client/client.service";
import {Resto} from "../../../../lib/models/structure/resto/resto.service";
import {ExternalArticle} from "../../../../lib/models/catalog/externalarticle/externalarticle.service";
import {Article} from "../../../../lib/models/catalog/article/article.service";

export interface BillDetailViewV3Params extends BaseRouteV3Params {
  bill_list_route: RouteConfigItemV3;
  article_detail_route: RouteConfigItemV3;
  ea_detail_route: RouteConfigItemV3;
}

export interface BillDetailViewV3Data extends BillDetailViewV3Params {
  client?: Client;
  bill?: VivalyaBill;
}

@Component({
  selector: "lvadg-bill-detail-view",
  templateUrl: "./bill-detail-view.component.pug",
  styleUrls: ["./bill-detail-view.component.sass"]
})
export class BillDetailViewComponent extends CommonBaseComponent {
  @Input() public bill!: VivalyaBill;
  @Input() public client!: Client;
  @Input() public resto!: Resto;
  public bill_list_route!: RouteV3<VivalyaBill>;
  public ea_detail_route!: RouteV3<ExternalArticle>;
  public article_detail_route!: RouteV3<Article>;

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as BillDetailViewV3Data;
    this.client = data.client || this.client;
    this.bill = data.bill || this.bill;
    this.bill_list_route = new RouteV3<VivalyaBill>(data.bill_list_route, this);
    this.ea_detail_route = new RouteV3<ExternalArticle>(data.ea_detail_route, this);
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    return super.preNgOnInit();
  }

}
