<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'pname'; desc: 'Recherche par nom de produit'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="pname"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'infamily'; desc: 'Recherche par famille'; group: 'general'">
        <bsac-model-list-tree-filter [list]="list" field="infamily" [related]="$any(families$)" descfield="name"></bsac-model-list-tree-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'with_document'; desc:'Recherche articles avec document ?'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="with_document" [choices]="[{desc:'Oui', value: 'yes'},{desc:'Non', value: 'no'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'marques'; desc: 'Recherche par marque(s)'; group: 'fourn'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="marques" [queryset]="marques$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une marque" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'fournisseurs'; desc: 'Recherche par fournisseur(s) référencé(s)'; group: 'fourn'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="fournisseurs" [queryset]="providers$" desctpl="&lt;%= cvva %&gt; &lt;%= name %&gt;" [filter]="{have_articles: 'yes'}" hint="Sélectionnez un fournisseur" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'egalims'; desc: 'Recherche par label(s) / certification(s)'; group: 'label'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="egalims" [queryset]="egalims$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez un label" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'egalim_status'; desc:'Recherche par statut Egalim'; group: 'label'">
        <bsac-model-list-select-filter [list]="list" field="egalim_status" [choices]="[{desc:'Non Egalim', value: 'no'}, {desc:'Egalim', value: 'yes'}, {desc:'Equivalence', value: mode=='client'?'eq'+this.client.id:'eq'},{desc:'Equivalence elior (tempo)', value: 'eq3'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'particularites'; desc: 'Recherche par particularite(s)'; group: 'caract'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="particularites" [queryset]="particularites$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une particularité" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'presentations'; desc: 'Recherche par presentation(s)'; group: 'caract'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="presentations" [queryset]="presentations$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une présentation" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'processs'; desc: 'Recherche par process(es)'; group: 'caract'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="processs" [queryset]="processs$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez un process" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'productions'; desc: 'Recherche par production(s)'; group: 'caract'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="productions" [queryset]="productions$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une production" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'calibres'; desc: 'Recherche par calibre(s)'; group: 'caract'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="calibres" [queryset]="calibres$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez un calibre" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'categories'; desc: 'Recherche par categorie(s)'; group: 'caract'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="categories" [queryset]="categories$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une catégorie" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'clients'; desc: 'Recherche par spécificité(s) client(s)'; group: 'caract'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="clients" [queryset]="clients$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une spécificité client" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'usages'; desc: 'Recherche par usage(s)'; group: 'caract'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="usages" [queryset]="usages$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez un usage" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'pricedclients'; desc: 'Recherche par tarif(s) client(s)'; group: 'client'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="pricedclients" [queryset]="pricedclients$" descfield="name" hint="Sélectionnez un client" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'withprices'; desc: 'Recherche articles avec tarif'; group: 'client'">
        <bsac-model-list-select-filter [list]="list" field="withprices" [choices]="WITH_PRICES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'origines'; desc: 'Recherche par origine'; group: 'origin'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="origines" [queryset]="origines$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une origine" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'origine_glob'; desc: 'Recherche par origine globale'; group: 'origin'">
        <bsac-model-list-select-filter [list]="list" field="origine_glob" [choices]="[{value: 'FR', desc: 'France'},{value:'UE', desc: 'Europe'},{value: 'IMP', desc: 'Import'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'local'; desc: 'Recherche produit local'; group: 'origin'">
        <bsac-model-list-select-filter [list]="list" field="local" [choices]="[{value: 'L', desc:'Local'}, {value:'U', desc: 'Ultra local'}, {value: '-', desc: '-'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'regions'; desc: 'Recherche par region(s)'; group: 'origin'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="regions" [queryset]="regions$" desctpl="&lt;%= code %&gt; &lt;%= name %&gt;" hint="Sélectionnez une région" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'stat_grouper'; desc: 'Regroupement statistique'; group: 'stats'">
        <bsac-model-list-select-filter [list]="list" field="stat_grouper" [choices]="statss.STAT_GROUPERS"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'stat_interval'; desc: 'Intervalle à afficher'; group: 'stats'">
        <bsac-model-list-select-filter [list]="list" field="stat_interval" [choices]="statss.STAT_INTERVALS"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'fourn'; desc: 'Recherche par fournisseur'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'client'; desc: 'Recherche par client'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'label'; desc: 'Recherche par label'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'origin'; desc: 'Recherche par origine'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'caract'; desc: 'Recherche par caractéristiques'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'stats'; desc: 'Statistiques'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <lvadg-modellist-xlsx-export [list]="list" [mode]="mode"></lvadg-modellist-xlsx-export>
    <ng-container *ngIf="checkAction('select')">
      <lvadg-selection-summary stype="article" unit="article(s)" (action)="onAction($event)" [actions]="select_actions" [custom_actions]="select_custom_actions"></lvadg-selection-summary>
    </ng-container>
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="select">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container *ngIf="checkAction('select')">
                <lvadg-selection-selitem [items]="results" stype="article"></lvadg-selection-selitem>
              </ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkAction('select')">
                <lvadg-selection-selitem [item]="row" stype="article"></lvadg-selection-selitem>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="clogo_img">
            <th cdk-header-cell *cdkHeaderCellDef>Image article</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="getDetailLink(row)">
                <lvadg-rimg [src]="row.clogo_img" display="thumb48" *ngIf="row.clogo_img"></lvadg-rimg></a></td>
          </ng-container>
          <ng-container cdkColumnDef="vvid">
            <th cdk-header-cell *cdkHeaderCellDef>ID Vivalya</th>
            <td cdk-cell *cdkCellDef="let row">{{row.vvid}}</td>
          </ng-container>
          <ng-container cdkColumnDef="family">
            <th cdk-header-cell *cdkHeaderCellDef>Classification</th>
            <td cdk-cell *cdkCellDef="let row"><span *ngFor="let fd of row.family_details; let index=index; let isLast=last"><span [class]="'fm-'+index">{{fd.name}}</span><span class="me-1" *ngIf="!isLast">:</span></span></td>
          </ng-container>
          <ng-container cdkColumnDef="seasons">
            <th cdk-header-cell *cdkHeaderCellDef>Saisonnalité</th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-seasons-manage [seasons]="row.seasons" [zone]="row.zone" [editable]="false" [perline]="6"></lvadg-seasons-manage>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="gamme">
            <th cdk-header-cell *cdkHeaderCellDef>Gamme</th>
            <td cdk-cell *cdkCellDef="let row">{{row.family_details[0]?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="nature">
            <th cdk-header-cell *cdkHeaderCellDef>Nature</th>
            <td cdk-cell *cdkCellDef="let row">{{row.family_details[1]?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="famille">
            <th cdk-header-cell *cdkHeaderCellDef>Famille</th>
            <td cdk-cell *cdkCellDef="let row">{{row.family_details[2]?.name}} {{row.family_details[3]?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="produit">
            <th cdk-header-cell *cdkHeaderCellDef>Produit</th>
            <td cdk-cell *cdkCellDef="let row">{{row.family_details[4]?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="variete">
            <th cdk-header-cell *cdkHeaderCellDef>Variete</th>
            <td cdk-cell *cdkCellDef="let row">{{row.family_details[5]?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="marque">
            <th cdk-header-cell *cdkHeaderCellDef>Marque</th>
            <td cdk-cell *cdkCellDef="let row">{{row.marque_details?.name||row.marque_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="fournisseur">
            <th cdk-header-cell *cdkHeaderCellDef>Fournisseur</th>
            <td cdk-cell *cdkCellDef="let row"><a *ngIf="row.fournisseur" [routerLink]="getProviderLink(row.fournisseur_details)">{{row.fournisseur_details?.name}}</a>
              <ng-container *ngIf="!row.fournisseur">-</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="content_src">
            <th cdk-header-cell *cdkHeaderCellDef>Description longue (source)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_src}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content">
            <th cdk-header-cell *cdkHeaderCellDef>Description longue (rendu)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content}}</td>
          </ng-container>
          <ng-container cdkColumnDef="code">
            <th cdk-header-cell *cdkHeaderCellDef>Code</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.code}}</code></td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="name">Nom</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="getDetailLink(row)">{{row.name}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="libelle">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="libelle">Libellé</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="getDetailLink(row)">{{row.libelle}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="libelle_auto">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="libelle_auto">Libellé (auto)</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="getDetailLink(row)">{{row.libelle_auto}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="scname">
            <th cdk-header-cell *cdkHeaderCellDef>Nom scientifique</th>
            <td cdk-cell *cdkCellDef="let row">{{row.scname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="allergenes">
            <th cdk-header-cell *cdkHeaderCellDef>Allergenes</th>
            <td cdk-cell *cdkCellDef="let row"><span class="me-2" *ngFor="let a of row.allergenes_details">{{a.code}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="production">
            <th cdk-header-cell *cdkHeaderCellDef>Production</th>
            <td cdk-cell *cdkCellDef="let row">{{row.production_details?.name||row.production_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="local">
            <th cdk-header-cell *cdkHeaderCellDef>Local</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check text-success" *ngIf="row.local=='L'"></i>
              <ng-container *ngIf="row.local=='U'"><i class="fa fa-check text-success me-1"></i>ultra</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="calibre">
            <th cdk-header-cell *cdkHeaderCellDef>Calibre</th>
            <td cdk-cell *cdkCellDef="let row">{{row.calibre_details?.name||row.calibre_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="categorie">
            <th cdk-header-cell *cdkHeaderCellDef> Catégorie</th>
            <td cdk-cell *cdkCellDef="let row">{{row.categorie_details?.name||row.categorie_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ciqual">
            <th cdk-header-cell *cdkHeaderCellDef>Num fiche ciqual</th>
            <td cdk-cell *cdkCellDef="let row">{{row.ciqual}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ciqual_alim">
            <th cdk-header-cell *cdkHeaderCellDef>Ref ciqual</th>
            <td cdk-cell *cdkCellDef="let row">{{row.ciqual_alim}}</td>
          </ng-container>
          <ng-container cdkColumnDef="client">
            <th cdk-header-cell *cdkHeaderCellDef>Client spécifique</th>
            <td cdk-cell *cdkCellDef="let row">{{row.client_details?.name||row.client_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="colisage_cond">
            <th cdk-header-cell *cdkHeaderCellDef>Colisage (conditionnement)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.colisage_cond_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="colisage_poids">
            <th cdk-header-cell *cdkHeaderCellDef>Colisage (poids)</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'colisage_poids'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="colisage_nombre">
            <th cdk-header-cell *cdkHeaderCellDef>Colisage (nombre)</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'colisage_nombre'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="colisage_unit">
            <th cdk-header-cell *cdkHeaderCellDef>Colisage (unité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.colisage_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="egalim">
            <th cdk-header-cell *cdkHeaderCellDef>Labels & certifications</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="d-flex flex-row">
                <div class="me-1" *ngFor="let e of row.egalim_details">
                  <lvadg-label-display [labelId]="e.flabel" *ngIf="e.flabel"></lvadg-label-display>
                  <lvadg-sealabel-display [labelId]="e.slabel" *ngIf="e.slabel"></lvadg-sealabel-display><span class="labeltitle" *ngIf="!(e.flabel || e.slabel)">{{e.name||e.code}}</span>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="isEgalim">
            <th cdk-header-cell *cdkHeaderCellDef>Egalim</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check text-success" *ngIf="row.isEgalim"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="isEgalimPlus">
            <th cdk-header-cell *cdkHeaderCellDef>Equivalence Egalim</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check text-success" *ngIf="row.isEgalimPlus(this.client)"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="origine">
            <th cdk-header-cell *cdkHeaderCellDef>Origine</th>
            <td cdk-cell *cdkCellDef="let row">{{row.origine_details?.name||row.origine_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="particularite">
            <th cdk-header-cell *cdkHeaderCellDef>Particularité</th>
            <td cdk-cell *cdkCellDef="let row">{{row.particularite_details?.name||row.particularite_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="poids_min">
            <th cdk-header-cell *cdkHeaderCellDef>Poids mini</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'poids_min'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="poids_max">
            <th cdk-header-cell *cdkHeaderCellDef>Poids maxi</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'poids_max'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="dlc_value">
            <th cdk-header-cell *cdkHeaderCellDef>Valeur DLC</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.dlc_value">{{row.dlc_value}} {{row.dlc_unit}}</ng-container>
              <ng-container *ngIf="!row.dlc_value">-</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="dlc_unit">
            <th cdk-header-cell *cdkHeaderCellDef>Unité DLC</th>
            <td cdk-cell *cdkCellDef="let row">{{row.dlc_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ddm_value">
            <th cdk-header-cell *cdkHeaderCellDef>Valeur DDM</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.ddm_value">{{row.ddm_value}} {{row.ddm_unit}}</ng-container>
              <ng-container *ngIf="!row.ddm_value">-</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="ddm_unit">
            <th cdk-header-cell *cdkHeaderCellDef>Unité DDM</th>
            <td cdk-cell *cdkCellDef="let row">{{row.ddm_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="presentation">
            <th cdk-header-cell *cdkHeaderCellDef>Présentation</th>
            <td cdk-cell *cdkCellDef="let row">{{row.presentation_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="process">
            <th cdk-header-cell *cdkHeaderCellDef>Process</th>
            <td cdk-cell *cdkCellDef="let row">{{row.process_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="recette">
            <th cdk-header-cell *cdkHeaderCellDef>Recette</th>
            <td cdk-cell *cdkCellDef="let row">{{row.recette}}</td>
          </ng-container>
          <ng-container cdkColumnDef="region">
            <th cdk-header-cell *cdkHeaderCellDef>Région</th>
            <td cdk-cell *cdkCellDef="let row">{{row.region_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="rnm_code">
            <th cdk-header-cell *cdkHeaderCellDef>Code RNM</th>
            <td cdk-cell *cdkCellDef="let row">{{row.rnm_code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="temperature">
            <th cdk-header-cell *cdkHeaderCellDef>Température</th>
            <td cdk-cell *cdkCellDef="let row">{{row.temperature}}</td>
          </ng-container>
          <ng-container cdkColumnDef="traitement">
            <th cdk-header-cell *cdkHeaderCellDef>Traitement</th>
            <td cdk-cell *cdkCellDef="let row">{{row.traitement_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uci_cond">
            <th cdk-header-cell *cdkHeaderCellDef>UCI (conditionnement)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.uci_cond_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="url">
            <th cdk-header-cell *cdkHeaderCellDef>URL infos</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.url"><a [href]="row.url">{{row.url|bsacFormatTrim:24}}</a></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="uci_value">
            <th cdk-header-cell *cdkHeaderCellDef>Valeur UCI</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'uci_value'}} {{row.uci_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uci_unit">
            <th cdk-header-cell *cdkHeaderCellDef>Unité UCI</th>
            <td cdk-cell *cdkCellDef="let row">{{row.uci_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="udf_unit">
            <th cdk-header-cell *cdkHeaderCellDef>UDF (unité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.udf_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="udv_value">
            <th cdk-header-cell *cdkHeaderCellDef>Valeur UDV</th>
            <td cdk-cell *cdkCellDef="let row">{{row.udv_value}}</td>
          </ng-container>
          <ng-container cdkColumnDef="usage">
            <th cdk-header-cell *cdkHeaderCellDef>Usage</th>
            <td cdk-cell *cdkCellDef="let row">{{row.usage_details?.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="colisage">
            <th cdk-header-cell *cdkHeaderCellDef>Colisage</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.colisage_nombre">{{row.colisage_cond_details?.name || row.colisage_cond_details?.code}} {{row|dsDecimal:"colisage_nombre"}} &times; {{row|dsDecimal:"colisage_poids"}} {{row.colisage_unit|choice:row:'colisage_unit'}}</ng-container>
              <ng-container *ngIf="!row.colisage_nombre">{{row.colisage_cond_details?.name || row.colisage_cond_details?.code}} {{row|dsDecimal:"colisage_poids"}} {{row.colisage_unit|choice:row:'colisage_unit'}}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="uci">
            <th cdk-header-cell *cdkHeaderCellDef>UCI</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'uci_value'}} {{row.uci_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="udf">
            <th cdk-header-cell *cdkHeaderCellDef>UDF</th>
            <td cdk-cell *cdkCellDef="let row">{{row.udf_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="documents">
            <th cdk-header-cell *cdkHeaderCellDef>Tous documents associés</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="(d.type=='DE' &amp;&amp; d.status=='OK') || (d.type=='DL' &amp;&amp; d.status=='OK') || d.type=='FT' || d.type=='OT' || checkMode('admin')" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.type}} {{d.title}}</div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="doc_FT">
            <th cdk-header-cell *cdkHeaderCellDef>Fiches techniques</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="d.type=='FT'" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.title}}<small class="ms-1" *ngIf="checkMode('admin')">{{d.status}}</small></div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="doc_DL">
            <th cdk-header-cell *cdkHeaderCellDef>Documents structure</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="d.type=='DL' &amp;&amp; (checkMode('admin') || d.status=='OK')" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.title}}<small class="ms-1" *ngIf="checkMode('admin')">{{d.status}}</small></div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="doc_DE">
            <th cdk-header-cell *cdkHeaderCellDef>Documents label/certification</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="d.type=='DE' &amp;&amp; (checkMode('admin') || d.status=='OK')" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.title}}<small class="ms-1" *ngIf="checkMode('admin')">{{d.status}}</small></div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="doc_OT">
            <th cdk-header-cell *cdkHeaderCellDef>Autres documents</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="pointer" *ngIf="d.type=='OT'" (click)="downloadDocument(d)"><i class="fa fa-external-link me-2"></i>{{d.title}}<small class="ms-1" *ngIf="checkMode('admin')">{{d.status}}</small></div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="iourl">
            <th cdk-header-cell *cdkHeaderCellDef>IO Url</th>
            <td cdk-cell *cdkCellDef="let row"><a [href]="row.iourl.url" target="_blank"><i class="fa fa-link me-2"></i>{{row.iourl.code}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="qr">
            <th cdk-header-cell *cdkHeaderCellDef>QR code</th>
            <td cdk-cell *cdkCellDef="let row"><img class="qr" [src]="row.qr"></td>
          </ng-container>
          <ng-container cdkColumnDef="documents_count">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="documents_count">Nb documents</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.documents_count}}</td>
          </ng-container>
          <ng-container cdkColumnDef="tarifs_stats">
            <th class="text-center" cdk-header-cell *cdkHeaderCellDef>Stat globale</th>
            <td class="text-center" cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.tarifs_stats &amp;&amp; row.tarifs_stats.avg"><span>{{row.tarifs_stats.avg|currency:"EUR"}}</span></ng-container>
            </td>
          </ng-container>
          <ng-container *ngFor="let i of stats_intervals">
            <ng-container [cdkColumnDef]="'tarifs_stats_'+i">
              <th class="text-center" cdk-header-cell *cdkHeaderCellDef>{{statss.column(i).name}}</th>
              <td class="text-center" cdk-cell *cdkCellDef="let row" [class.table-danger]="statss.danger(row, i)" [class.table-warning]="statss.warning(row, i)"><span [title]="statss.summary(row, i)" (click)="statss.dispDetail(row, i)" role="button">{{statss.display(row, i)}}</span></td>
            </ng-container>
          </ng-container>
          <ng-container cdkColumnDef="actions">
            <th cdk-header-cell *cdkHeaderCellDef>Actions</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkAction('quickprintarticle')">
                <button class="btn btn-link btn-sm ms-1 me-1 mt-1" (click)="onAction({action: 'quickprintarticle', article: row})"><i class="fa fa-print me-2"></i></button>
              </ng-container>
              <ng-container *ngIf="checkAction('addtotarif') &amp;&amp; !row.in_tarif">
                <button class="btn btn-outline-primary btn-sm ms-1 me-1 mt-1" (click)="onAction({action: 'tarifaddbtn', items: [row]})"><i class="fa fa-plus me-2"></i>Tarif</button>
              </ng-container>
            </td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>