import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Client, ClientService} from "./client.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class ClientResolve implements Resolve<Client> {
  constructor(private _bs: ClientService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Client> {
    return this._bs.get(+route.params["clientId"]);
  }
}
