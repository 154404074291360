import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {LabelService} from "./label.service";
import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {catchError, first} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class LabelPrefetchResolve implements Resolve<any> {
  constructor(private _bs: LabelService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._bs.prefetch().pipe(first(), catchError(() => {
      console.error("Unable to fetch labels");
      return of({});
    }));
  }
}
