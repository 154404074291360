// Geo regions service.
//
// @copyright 2019 JM Barbier <jm.barbier@solidev.net>
// @status OK
// @updated 2019-01-27-14:00:18


import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export class GeoRegion extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    verboseName: "ID",
  })
  public id!: number;

  @DSCharField({
    required: true,
    name: "name",
    verboseName: "Nom de la région",
    maxLength: 200,
  })
  public name!: string;

  @DSCharField({
    required: true,
    name: "code",
    verboseName: "code",
    maxLength: 5,
  })
  public code!: string;

  @DSCharField({
    name: "insee",
    verboseName: "Code INSEE",
    required: false,
    helpText: "Code INSEE",
    minLength: 0,
    maxLength: 2,
    choices: [],
    default: "??"
  })
  public insee!: string;


  @DSCharField({
    required: false,
    name: "type",
    verboseName: "Type",
    choices: [
      ["OLD", "Anciennes régions"],
      ["REG", "Régions (actuelles)"],
      ["VVA", "Régions Vivalya"],
      ["ELR", "Régions Elior"],
      ["SDX", "Régions Sodexo"],
      ["CUS", "Régions spéciales"]
    ],
    default: "CUS",
    maxLength: 4,
  })
  public type!: string;

  @DSManyToManyField({
    required: false,
    name: "departements",
    verboseName: "ID Départements"
  })
  public departements!: number[];


  public departements_details!: { id: number; name: string; code: string }[];

  @DSForeignKeyField({
    name: "country",
    verboseName: "Pays",
    required: false,
    helpText: "Pays",
    relatedModel: "customdata.Country",
    remoteField: "id",
    relatedName: "region"
  })
  public country!: number;
  public country_details!: { id: number; code: string; name: string };

  public get fullname(): string {
    if (this.type === "OLD") {
      return `[ANC.] ${this.name}`;
    }
    return this.name;
  }
}

@Injectable({providedIn: "root"})
export class GeoRegionService extends DSRestCollection<GeoRegion> {
  public adapter_config = {basePath: "/api/v2/georegions"};
  public model = GeoRegion;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
