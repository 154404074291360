
<ng-container *ngIf="key">
  <ng-container *ngIf="showLabel">
    <dl *ngIf="!edit">
      <dt class="pointer" *ngIf="!readonly" (click)="edit=!edit">{{key.name}}</dt>
      <dd>
        <lvadg-customdata-value-display [key]="key" [value]="value" [readonly]="readonly"></lvadg-customdata-value-display>
      </dd>
    </dl>
    <dl *ngIf="edit">
      <dt class="pointer" (click)="edit=!edit">{{key.name}}</dt>
      <dd>
        <lvadg-customdata-value-edit [key]="key" [value]="value" (edited)="save($event)" (removed)="save(null, true)"></lvadg-customdata-value-edit>
      </dd>
    </dl>
  </ng-container>
  <ng-container *ngIf="!showLabel">
    <div class="editor"><i class="fa fa-pencil pencil m-2 me-4 pointer" (click)="cancelled.emit()" *ngIf="!readonly"></i>
      <lvadg-customdata-value-edit [key]="key" [value]="value" (edited)="save($event)" (removed)="save(null, true)"></lvadg-customdata-value-edit>
    </div>
  </ng-container>
</ng-container>