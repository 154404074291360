import {Component, Input} from "@angular/core";
import {VivalyaBill, VivalyaBillService} from "../bill.service";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {Client, ClientService} from "../../../structure/client/client.service";
import {Resto, RestoService} from "../../../structure/resto/resto.service";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {Article, ArticleService} from "../../../catalog/article/article.service";
import {Storage, StorageService} from "../../../structure/storage/storage.service";
import {Member, MemberService} from "../../../structure/member/member.service";
import {TarifType, TarifTypeService} from "../../../tarif/tariftype/tariftype.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "lvadg-bill-list",
  templateUrl: "./bill-list.component.pug",
  styleUrls: ["./bill-list.component.sass"]
})
export class BillListComponent extends ModelListBaseComponent<VivalyaBill, VivalyaBillService> {
  @Input() public client!: Client;
  @Input() public resto!: Resto;
  @Input() public bill_detail_route!: RouteV3<VivalyaBill>;
  public clients$!: DSRestQueryset<Client>;
  public articles$!: DSRestQueryset<Article>;
  public storages$!: DSRestQueryset<Storage>;
  public members$!: DSRestQueryset<Member>;
  public tariftypes$!: DSRestQueryset<TarifType>;
  public restos$!: DSRestQueryset<Resto>;
  public articlesFilter = {bill_lines_assoc: "ASS", fields: "id,libelle"};

  constructor(_list: BsacModelListProvider,
              _items: VivalyaBillService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              private _articles: ArticleService,
              private _tariftypes: TarifTypeService,
              private _storages: StorageService,
              private _members: MemberService,
              private _modal: NgbModal,
              private _clients: ClientService,
              private _restos: RestoService) {
    super(_list, _items, _msgs, routes);
  }

  public getDetailLink(model: VivalyaBill): (string | number)[] {
    return this.bill_detail_route.route({bill: model});
  }

  public async postNgOnInit(): Promise<void> {
    this.clients$ = this._clients.queryset;
    this.members$ = this._members.queryset;
    this.storages$ = this._storages.queryset;
    this.articles$ = this._articles.queryset;
    this.tariftypes$ = this._tariftypes.queryset;
    this.restos$ = this._restos.queryset;
  }

}
