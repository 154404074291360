
<div class="folder pointer" (click)="tree.singleClick('folder', folder)" (dblclick)="tree.doubleClick('folder', folder)" [class.selected]="tree.selected.folder?.id==folder.id">
  <div class="foldericon">
    <div class="icon">
      <ng-container *ngIf="folder.logo">
        <div class="img" lvadgRbg [rsrc]="folder.logo_img" rdisplay="gallery" rsize="thumb"></div>
      </ng-container>
      <ng-container *ngIf="!folder.logo"><i class="fal fa-folder"></i></ng-container>
    </div>
    <div class="title">{{folder.title}}</div>
  </div>
</div>