
<div class="row">
  <div class="col-6">
    <select class="form-select" [(ngModel)]="curmarche">
      <option *ngFor="let m of marches">{{m}}</option>
    </select>
  </div>
  <div class="col-6">
    <select class="form-select" [(ngModel)]="curdate" *ngIf="curmarche">
      <option *ngFor="let d of getDates(curmarche)" [value]="d">{{d|date:'fullDate'}}</option>
    </select>
  </div>
  <div class="col-12">
    <div class="content" [innerHTML]="getMessage(curmarche, curdate)"></div>
  </div>
</div>