import {NgModule} from "@angular/core";
import {TarifnotificationstatusListComponent} from "./tarifnotificationstatus-list/tarifnotificationstatus-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  declarations: [
    TarifnotificationstatusListComponent
  ],
  exports: [
    TarifnotificationstatusListComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS
  ]
})
export class TarifnotificationstatusModule { }
