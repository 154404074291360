import {NgModule} from "@angular/core";
import {SlabelCreateComponent} from "./slabel-create/slabel-create.component";
import {SlabelDeleteComponent} from "./slabel-delete/slabel-delete.component";
import {SlabelDisplayComponent} from "./slabel-display/slabel-display.component";
import {SlabelListComponent} from "./slabel-list/slabel-list.component";
import {SlabelSelectComponent} from "./slabel-select/slabel-select.component";
import {SlabelManageComponent} from "./slabel-manage/slabel-manage.component";
import {SlabelSetunsetComponent} from "./slabel-setunset/slabel-setunset.component";
import {SlabelNamePipe} from "./slabel-name.pipe";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS
  ],
  declarations: [
    SlabelSelectComponent,
    SlabelDisplayComponent,
    SlabelDeleteComponent,
    SlabelListComponent,
    SlabelManageComponent,
    SlabelCreateComponent,
    SlabelSetunsetComponent,
    SlabelNamePipe
  ],
  exports: [
    SlabelSelectComponent,
    SlabelDisplayComponent,
    SlabelDeleteComponent,
    SlabelListComponent,
    SlabelManageComponent,
    SlabelCreateComponent,
    SlabelSetunsetComponent,
    SlabelNamePipe
  ],
})
export class SLabelModule {
}
