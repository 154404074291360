
<div class="snack" *ngIf="update.available$|async">
  <p>Une nouvelle version de l'application est disponible.</p>
  <div class="row">
    <div class="col-sm-6">
      <button class="btn btn-primary btn-block w-100" (click)="update.activate()">L'ACTIVER MAINTENANT</button>
    </div>
    <div class="col-sm-6">
      <button class="btn btn-warning btn-block w-100" (click)="update.dismiss()">Plus tard</button>
    </div>
  </div>
</div>