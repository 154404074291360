
<div class="row row-cols-md-2 row-cols-lg-2 g-2">
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Infos internes</div>
      <div class="card-body">
        <div class="row">
          <bsac-dd-field class="col-6" [model]="ea" field="id" type="auto" [editable]="false">ID</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="type" type="auto" [editable]="true">Type</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="status" type="auto" [editable]="true">Etat</bsac-dd-field>
          <bsac-flags-field class="col-6" [model]="ea" field="flags" [action]="true">Flags</bsac-flags-field>
          <bsac-dd-field class="col-6" [model]="ea" field="created" type="auto" [editable]="true">Date création</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="updated" type="auto" [editable]="true">Date update</bsac-dd-field>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Liaisons</div>
      <div class="card-body">
        <div class="row">
          <bsac-dd-field class="col-12" [model]="ea" field="client" type="auto" [editable]="true" [related]="clients$" desctpl="&lt;% if(obj.parent_details) { %&gt;&lt;%= obj.parent_details.name %&gt; / &lt;%= obj.name %&gt; &lt;% } else { %&gt;&lt;%= obj.name %&gt; &lt;% } %&gt;" relatedEmpty="Aucun client associé" relatedLabel="Sélectionnez un client">Client</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="ea" field="tariftype" type="auto" [editable]="true" [related]="tariftypes$" relatedLabel="Sélectionnez un tarif" relatedEmpty="Aucun tarif associé" descfield="name">Modèle tarif</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="ea" field="storage" type="auto" [editable]="true" [related]="storages$" relatedLabel="Sélectionnez un dépôt" relatedEmpty="Aucun dépôt associé" descfield="name">Dépôt</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="ea" field="member" type="auto" [editable]="true" [related]="members$" relatedLabel="Sélectionnez un adhérent" relatedEmpty="Aucun adhérent associé" descfield="name">Adhérent</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="ea" field="provider" type="auto" [editable]="true" [related]="providers$" relatedLabel="Sélectionnez un fournisseur" relatedEmpty="Aucun fournisseur associé" descfield="name">Fournisseur</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="ea" field="collection" type="auto" [editable]="true" [related]="eacolls$" relatedLabel="Sélectionnez un groupe de dépôts" relatedEmpty="Aucun groupe de dépôt associé" descfield="name">Groupe de dépôts</bsac-dd-field>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Infos produit</div>
      <div class="card-body">
        <div class="row">
          <bsac-dd-field class="col-6" [model]="ea" field="code" type="auto" [editable]="true">Code structure</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="icode" type="auto" [editable]="true">Code alternatif</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="gamme" type="auto" [editable]="true">Classification niveau 1</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="sgamme" type="auto" [editable]="true">Classification niveau 2</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="ean13" type="auto" [editable]="true">EAN13</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="marque" type="auto" [editable]="true">Marque</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="ea" field="libelle" type="auto" [editable]="true">Libellé</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="categorie" type="auto" [editable]="true">Catégorie</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="calibre" type="auto" [editable]="true">Calibre</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="origine" type="auto" [editable]="true">Origine</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="pe" type="auto" [editable]="true">Poids égoutté</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="pe_value" type="auto" [editable]="true">Poids égoutté (valeur)</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="pe_unit" type="auto" [editable]="true">Poids égoutté (unité)</bsac-dd-field>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Infos commercial</div>
      <div class="card-body">
        <div class="row">
          <bsac-dd-field class="col-4" [model]="ea" field="uf" type="auto" [editable]="true">Unité de facturation</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="uf_value" type="auto" [editable]="true">Unité de facturation (valeur)</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="uf_unit" type="auto" [editable]="true">Unité de facturation (unité)</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="uc" type="auto" [editable]="true">Unité de commande</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="uc_value" type="auto" [editable]="true">Unité de commande (valeur)</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="uc_unit" type="auto" [editable]="true">Unité de commande (unité)</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="ucond" type="auto" [editable]="true">Unité de conditionnement</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="ucond_value" type="auto" [editable]="true">Unité de conditionnement (valeur)</bsac-dd-field>
          <bsac-dd-field class="col-4" [model]="ea" field="ucond_unit" type="auto" [editable]="true">Unité de conditionnement (unité)</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="ucuf" type="auto" [editable]="true">Ratio UC UF</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="nego_unit" type="auto" [editable]="true">Unité de négociation</bsac-dd-field>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Catalogue</div>
      <div class="card-body">
        <div class="row">
          <!--bsac-dd-field.col-12([model]="ea", field="articles", type="auto", [editable]="true",-->
          <!--  [related]="articles$", relatedLabel="Sélectionnez un article", descfield="libelle",-->
          <!--  relatedEmpty="Aucun article associé") Articles associés-->
          <bsac-dd-field class="col-6" [model]="ea" field="flvariant" type="auto" [editable]="true" [related]="flvariants$" relatedLabel="Sélectionnez une nomenclature" descfield="sname" relatedEmpty="Aucune nomenclature FL associée">Nomenclature FL (historique)</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="ea" field="seavariant" type="auto" [editable]="true" [related]="seavariants$" relatedLabel="Sélectionnez une nomenclature FL" descfield="sname" relatedEmpty="Aucune nomenclature marée associée">Nomenclature Marée (historique)</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="ea" field="xlsxhash" type="auto" [editable]="true">Hash XLSX</bsac-dd-field>
          <bsac-dd-field class="col-12" [model]="ea" field="importref" type="auto" [editable]="true">Ref importation</bsac-dd-field>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header">Données import brutes</div>
      <div class="maxh">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>Champ</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let k of origData">
              <td>{{k[0]}}</td>
              <td>{{k[1]}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>