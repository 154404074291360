import {Component, Input, OnInit} from "@angular/core";
import {Tarif} from "../../../tarif/tarif.service";
import {firstValueFrom} from "rxjs";

interface InfoMarcheData {
  mcode: string;
  mname: string;
  date: Date;
  message: string;
}

@Component({
  selector: "lvadg-articletarif-stats-infos-marches",
  templateUrl: "./articletarif-stats-infos-marches.component.pug",
  styleUrls: ["./articletarif-stats-infos-marches.component.sass"]
})
export class ArticletarifStatsInfosMarchesComponent implements OnInit {
  @Input() public tarif!: Tarif;
  public messages: InfoMarcheData[] = [];
  public marches!: string[];
  public curmarche!: string;
  public curdate!: Date;
  public message!: string;

  constructor() {
  }

  public async ngOnInit(): Promise<void> {
    this.messages = await firstValueFrom(this.tarif.action("infos_marches", {
      method: "GET"
    }));
    this.marches = [];
    for (const m of this.messages) {
      if (m.mcode === "R1" && !this.curmarche) {
        this.curmarche = m.mname;
        this.curdate = m.date;
      }
      if (this.marches.indexOf(m.mname) === -1) {
        this.marches.push(m.mname);
      }
    }
  }

  public getDates(marche: string): Date[] {
    const dates: Date[] = [];
    for (const m of this.messages) {
      if (m.mname === marche) {
        dates.push(m.date);
      }
    }
    return dates;
  }

  public getMessage(marche: string, date: Date): string {
    for (const m of this.messages) {
      if (m.mname === marche && m.date === date) {
        return m.message;
      }
    }
    return "";
  }


}
