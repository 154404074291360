import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CompsModule} from "../../../../comps/comps.module";
import {ReactiveFormsModule} from "@angular/forms";
import {QuillModule} from "ngx-quill";
import {CommFieldTextFieldComponent} from "./commfield-textfield/commfield-textfield.component";
import {CommFieldCharFieldComponent} from "./commfield-charfield/commfield-charfield.component";
import {CommFieldSelectMultipleComponent} from "./commfield-selectmultiple/commfield-selectmultiple.component";
import {CommFieldSelectComponent} from "./commfield-select/commfield-select.component";
import {CommFieldSelImgCustomImgComponent} from "./commfield-selimgcustomimg/commfield-selimgcustomimg.component";
import {CommFieldSelTxtCustomImgComponent} from "./commfield-seltxtcustomimg/commfield-seltxtcustomimg.component";


@NgModule({
  declarations: [
    CommFieldCharFieldComponent,
    CommFieldSelectMultipleComponent,
    CommFieldSelectComponent,
    CommFieldSelImgCustomImgComponent,
    CommFieldSelTxtCustomImgComponent,
    CommFieldTextFieldComponent
  ],
  exports: [
    CommFieldCharFieldComponent,
    CommFieldSelectMultipleComponent,
    CommFieldSelectComponent,
    CommFieldSelImgCustomImgComponent,
    CommFieldSelTxtCustomImgComponent,
    CommFieldTextFieldComponent
  ],
  imports: [
    CommonModule,
    CompsModule,
    ReactiveFormsModule,
    QuillModule]
})
export class CommFieldModule {
}
