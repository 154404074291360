import {Offer} from "../offer.service";
import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: "lvadg-offer-delete",
  templateUrl: "./offer-delete.component.pug",
  styleUrls: ["./offer-delete.component.sass"]
})
export class OfferDeleteComponent {
  @Input() public offer!: Offer;
  @Output() public removed: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cancelled: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public delete(): void {
    this.offer.remove().subscribe(result => {
      this.removed.emit(true);
    });
  }
}
