import {Directive, Input, OnChanges} from "@angular/core";
import {BaseLabel, BaseLabelService} from "../label-base.model";
import {first} from "rxjs/operators";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class LabelDisplayBaseComponent<L extends BaseLabel, LS extends BaseLabelService<L>> implements OnChanges {

  @Input() public label!: L;
  @Input() public labelId!: number;
  @Input() public mode = "image";
  @Input() public imgSize = "xxsm";
  @Input() public imgDisplay = "thumb32";

  constructor(protected _labels: LS) {
  }

  public ngOnChanges(): void {
    if (this.labelId) {
      this._labels.getCached(this.labelId).pipe(first()).subscribe((lab) => {
        this.label = lab;
      });
    }
  }

}
