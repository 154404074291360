
<ng-template #gprice>
  <p class="text-info">Opérations sur la colonne</p>
  <button class="btn btn-primary w-100 my-3" (click)="validateColumn()" [disabled]="running.validation || !canEdit()"><i class="fa fa-spinner fa-spin me-2" *ngIf="running.validation"></i>Valider toute la colonne</button>
</ng-template>
<ng-template #sprice>
  <p class="text-info">Opération sur la colonne du dépôt</p>
  <button class="btn btn-primary w-100 my-3" (click)="validateColumn(storage)" [disabled]="running.validation || !canEdit()"><i class="fa fa-spinner fa-spin me-2" *ngIf="running.validation"></i>Valider toute la colonne du dépôt</button>
  <hr>
  <form class="my-3" [formGroup]="copyForm" (submit)="importColumn(storage)">
    <div class="mb-3">
      <label>Source</label>
      <select class="form-select" formControlName="source">
        <ng-container *ngFor="let s of storages">
          <option *ngIf="s.storageId !== storage?.storageId &amp;&amp; !s.readonly" [value]="s.storageId">{{s.verbose_name}}</option>
        </ng-container>
      </select>
    </div>
    <div class="mb-3">
      <label>Opération</label>
      <input class="form-control" formControlName="ops" type="text">
    </div>
    <button class="btn btn-primary w-100" [disabled]="running.import || !canEdit() || !copyForm.value.source" type="submit"><i class="fa fa-spinner fa-spin me-2" *ngIf="running.import"></i>Importer les prix d'un autre dépôt</button>
  </form>
</ng-template>
<ng-template #rprice>
  <pre class="todo">Affichage des prix RNM : TODO</pre>
</ng-template>
<ng-template #offcanvas let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">{{name}}</h4>
    <button class="btn-close" (click)="offcanvas.dismiss()"></button>
  </div>
  <div class="offcanvas-body">
    <ng-container [ngTemplateOutlet]="gprice" *ngIf="mode==colop.GlobalPrice"></ng-container>
    <ng-container [ngTemplateOutlet]="sprice" *ngIf="mode==colop.StoragePrice"></ng-container>
    <ng-container [ngTemplateOutlet]="rprice" *ngIf="mode==colop.RnmPrice"></ng-container>
  </div>
</ng-template>
<div role="button" (click)="openOffCanvas()">
  <ng-content></ng-content>
  <div class="badge bg-primary" *ngIf="mode==colop.StoragePrice">Opérations</div>
</div>