import {Component, Input} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {ExternalArticle, ExternalArticleService} from "../externalarticle.service";
import {TarifType, TarifTypeService} from "../../../tarif/tariftype/tariftype.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {Client, ClientService} from "../../../structure/client/client.service";
import {Article, ArticleService} from "../../article/article.service";
import {firstValueFrom} from "rxjs";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Storage, StorageService} from "../../../structure/storage/storage.service";
import {Member, MemberService} from "../../../structure/member/member.service";

@Component({
  selector: "lvadg-externalarticle-list",
  templateUrl: "./externalarticle-list.component.pug",
  styleUrls: ["./externalarticle-list.component.sass"]
})
export class ExternalarticleListComponent extends ModelListBaseComponent<ExternalArticle, ExternalArticleService> {

  @Input() public articleZone: string = "catalog.vivalya";
  @Input() public articleType: string = "article";
  @Input() public article_detail_route!: RouteV3<Article>;
  @Input() public externalarticle_detail_route!: RouteV3<ExternalArticle>;
  @Input() public tarifType?: TarifType;
  public importExcel = false;
  public tariftypes$!: DSRestQueryset<TarifType>;
  public clients$!: DSRestQueryset<Client>;
  public articles$!: DSRestQueryset<Article>;
  public storages$!: DSRestQueryset<Storage>;
  public members$!: DSRestQueryset<Member>;
  public articlesFilter = {external_assoc: "ASS", fields: "id,libelle"};

  public catalog_actions = ["addtotarif"];
  public catalog_default_fields = ["code", "libelle", "origine", "calibre", "categorie", "egalim", "udf_unit", "actions"];
  public catalog_default_filters = ["search", "pname", "infamily"];
  public ASSOC_CHOICES = [
    {desc: "Tous", value: "ALL"},
    {desc: "Associé", value: "ASS"},
    {desc: "Non associé", value: "NAS"},
    {desc: "Association multiples", value: "MUL"}
  ];
  private _artSearchInstance: NgbModalRef;

  constructor(_list: BsacModelListProvider,
              _items: ExternalArticleService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              private _articles: ArticleService,
              private _tariftypes: TarifTypeService,
              private _storages: StorageService,
              private _members: MemberService,
              private _modal: NgbModal,
              private _clients: ClientService) {
    super(_list, _items, _msgs, routes);
  }

  public async postNgOnInit(): Promise<void> {
    this.tariftypes$ = this._tariftypes.queryset;
    this.clients$ = this._clients.queryset;
    this.members$ = this._members.queryset;
    this.storages$ = this._storages.queryset;
    this.articles$ = this._articles.queryset;
  }

  public async setStatus(eat: ExternalArticle, out: string): Promise<void> {
    if (out === "INT") {
      this._msgs.error("Vous devez utiliser le bouton 'créer les lignes de tarif'...");
      return;
    }
    eat.status = out;
    await firstValueFrom(eat.update(["status"]));
  }

  public async removeArticle(row: ExternalArticle, a: Article) {
    this.current = row;
    try {
      const nc = await firstValueFrom(row.action("remove_mapping", {
        method: "POST",
        body: {article: a.id}
      }));
      row.assign(nc);
      this._msgs.success("Association supprimée avec succès");
    } catch (e) {
      this._msgs.error("Erreur lors de la suppression de l'association");
    }
    delete this.current;
  }

  public async addArticle(mod: any, row: ExternalArticle) {
    this.current = row;
    this._artSearchInstance = this._modal.open(mod, {size: "xl"});
    this._artSearchInstance.result.then(async (res) => {
      if (this.current && res.action === "tarifaddbtn" && res.items && res.items.length === 1) {
        const art: Article = res.items[0];
        console.log("Adding article ", art, "to current", this.current);
        const nc = await firstValueFrom(this.current.action("add_mapping", {
          method: "POST",
          body: {article: art.id}
        }));
        this.current.assign(nc);
        this._msgs.success("Association ajoutée avec succès");
        delete this.current;
      }
    }, () => {
    });
  }

  public getDetailLink(model: ExternalArticle): (string | number)[] {
    if (this.externalarticle_detail_route) {
      return this.externalarticle_detail_route.route({externalArticle: model});
    }
    return this.routes.get(this.zone, this.type, model);
  }
}
