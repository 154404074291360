import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";

@Component({
  selector: "lvadg-seasons-manage",
  templateUrl: "./seasons-manage.component.pug",
  styleUrls: ["./seasons-manage.component.sass"]
})
export class SeasonsManageComponent implements OnChanges {
  @Input() public item!: any;
  @Input() public seasons!: number[];
  @Input() public sstats!: number[];
  @Input() public editable = true;
  @Input() public zone = "fl";
  @Input() perline = 12;
  @Input() public size: "small"|"medium"|"large" = "small";

  public cseasons!: { number: number; status: boolean; intensity: number, letter: string }[];
  public cseasons_letters = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
  @Output() public changed: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.cseasons = [];
    if (this.seasons) {
      for (let i = 1; i <= 12; i++) {
        this.cseasons.push({number: i, status: false, intensity: 0, letter: this.cseasons_letters[i - 1]});
      }
      for (const s of this.seasons) {
        this.cseasons[s - 1].status = true;
        this.cseasons[s - 1].intensity = 5;
      }
    } else if (this.sstats) {
      let maxi = 0;
      for (let i = 1; i <= 12; i++) {
        const val = this.sstats[i - 1] || 0;
        if (val > maxi) {
          maxi = val;
        }
      }
      for (let i = 1; i <= 12; i++) {
        const val = this.sstats[i - 1] || 0;
        this.cseasons.push({number: i, status: val > 0, intensity: Math.round(val / maxi * 5), letter: this.cseasons_letters[i - 1]});
      }
    }
  }

  public updateSeason(num: number): void {
    const sout = [];
    for (const s of this.cseasons) {
      if (num === s.number) {
        s.status = !s.status;
      }
      if (s.status) {
        sout.push(s.number);
      }
    }
    this.changed.emit(sout);
  }

  public getClass(s: { number: number; status: boolean; intensity: number; letter: string }): string[] {
    const cl: string[] = [];
    if (s.status) {
      cl.push("a");
    } else {
      cl.push("n");
    }
    cl.push(`i-${s.intensity}`);
    return cl;
  }
}
