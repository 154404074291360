
<ng-container *ngIf="display==='view'">
  <bsac-breadcrumb></bsac-breadcrumb>
  <h1 class="page-header">
    <button class="btn btn-danger float-end" (dblclick)="remove=!remove" [disabled]="!canDelete"><i class="fa fa-trash fa-s"></i><span class="hidden-sm">Supprimer</span></button><i class="fa fa-cogs fa-s"></i><span *ngIf="articleFamily?.level&gt;0">{{articleFamily?.cfullname}}</span><small>&nbsp; détails</small>
  </h1>
</ng-container>
<ng-container *ngIf="display==='content'">
  <h2><i class="fa fa-cogs me-2"></i><span *ngIf="articleFamily?.level&gt;0">{{articleFamily?.cfullname}}</span><small>&nbsp; détails</small></h2>
</ng-container>
<nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
  <ng-container ngbNavItem="manage"><a ngbNavLink>Famille</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-article-family-manage [articleFamily]="articleFamily" [articlefamily_detail_route]="articlefamily_detail_route" *ngIf="ready"></lvadg-article-family-manage>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="darticles"><a ngbNavLink>Articles associés (direct)</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-article-list *ngIf="ready" [name]="currentRoute.name+'.darticles'" [family]="articleFamily" [filter$]="darticlesFilter" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route" [default_fields]="[&quot;id&quot;, &quot;family&quot;, &quot;libelle&quot;, &quot;name&quot;, &quot;categorie&quot;, &quot;calibre&quot;, &quot;uf&quot;]" [excluded_fields]="[]"></lvadg-article-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="sarticles"><a ngbNavLink>Articles associés (enfants)</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-article-list *ngIf="ready" [name]="currentRoute.name+'.sarticles'" [family]="articleFamily" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route" [filter$]="sarticlesFilter" [default_fields]="[&quot;id&quot;, &quot;family&quot;, &quot;libelle&quot;, &quot;name&quot;, &quot;categorie&quot;, &quot;calibre&quot;, &quot;uf&quot;]" [excluded_fields]="[]"></lvadg-article-list>
      </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav"></div>