import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleParticularite, ArticleParticulariteService} from "../article-particularite.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "lvadg-article-particularites-manage",
  templateUrl: "./article-particularites-manage.component.pug",
  styleUrls: ["./article-particularites-manage.component.sass"]
})
export class ArticleParticularitesManageComponent
  extends ArticleDataManageBaseComponent<ArticleParticularite, ArticleParticulariteService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleParticulariteService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "particulariteId";
    this.filterField = "particularite";
  }
}
