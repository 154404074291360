import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {SeaLabelService} from "./sealabel.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SeaLabelPrefetchResolve implements Resolve<any> {
  constructor(private _bs: SeaLabelService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._bs.prefetch().pipe(first());
  }
}
