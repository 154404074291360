import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";
import {HeaderDescription} from "../../document/upload/xlsx-map";
import {first} from "rxjs/operators";

export const MIMEICONS: {[index: string]: string} = {
  "image/jpg": "fa-file-image",
  "image/png": "fa-file-image",
  "application/pdf": "fa-file-pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "fa-file-excel",
  "application/msword": "fa-file-word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "fa-file-word",
  "application/vnd.ms-excel": "fa-file-excel",
  "application/vnd.ms-powerpoint": "fa-file-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "fa-file-powerpoint",
  "text/csv": "fa-file-spreadsheet",
  "text/plain": "fa-file-alt"
};



export class TarifDocument extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSForeignKeyField({
    name: "tarif",
    verboseName: "Tarif",
    required: false,
    helpText: "Tarif associé",
    relatedModel: "tarifs.Tarif",
    remoteField: "id",
    relatedName: "documents"
  })
  public tarif!: number;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type de document",
    minLength: 0,
    maxLength: 4,
    choices: [
      ["FT", "Fiche technique"],
      ["DL", "Document label structure"],
      ["DE", "Document label / certification"],
      ["AT", "Archive tarif"],
      ["ET", "Export tarif"],
      ["OT", "Autre document"],
    ]
  })
  public type!: string;


  @DSCharField({
    name: "title",
    verboseName: "Titre",
    minLength: 3,
    maxLength: 200,
    required: true
  })
  public title!: string;
  @DSSlugField({
    name: "slug",
    required: false
  })
  public slug!: string;
  @DSCharField({
    name: "mimetype",
    required: false,
    maxLength: 250,
    default: ""
  })
  public mimetype!: string;
  @DSCharField({
    name: "filename",
    required: false,
    maxLength: 255,
    default: ""
  })
  public filename!: string;
  @DSIntegerField({
    name: "filesize",
    required: false,
    default: 0
  })
  public filesize!: number;
  @DSCharField({
    name: "file",
    required: false,
    maxLength: 255,
    default: ""
  })
  public file!: string;
  @DSForeignKeyField({
    required: false,
    name: "image",
    helpText: "",
    verboseName: "ID Image",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
  })
  public image!: number;
  public image_img!: Rimg;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "OK",
    choices: [
      ["OK", "Document valide"],
      ["PRE1", "Document sans MAJ expirant dans - de 15 jours"],
      ["PRE2", "Document sans MAJ expirant dans - de 1 mois"],
      ["FUT", "Document futur"],
      ["ARCN", "Document archivé, sans MAJ"],
      ["ARCM", "Document archivé, avec MAJ"],
      ["KO", "Document non valide"]
    ],
    maxLength: 10
  })
  public status!: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  @DSDefaultField({
    name: "parameters",
    verboseName: "Params",
    required: false
  })
  public parameters?: HeaderDescription;

  @DSDefaultField({
    name: "content",
    verboseName: "Contenu",
    required: false
  })
  public content?: any;


  public get icon(): string {
    if (MIMEICONS[this.mimetype]) {
      return MIMEICONS[this.mimetype];
    }
    return "fa-file";
  }

  private _url: string;


  public download(newTab?: any): void {
    if (!newTab) {
      newTab = window.open();
    }
    if (!this._url) {
      this.action("download_link", {method: "POST", body: {ttl: 63072000}})
        .pipe(first())
        .subscribe((dl) => {
          if (newTab && newTab.location) {
            newTab.location.href = dl.url;
          } else {
            console.error("No location for async newTab, redirecting main window");
            window.location.assign(dl.url);
          }
        });
    } else {
      if (newTab && newTab.location) {
        newTab.location.href = this._url;
      } else {
        console.error("No location for sync newTab, redirecting main window");
        window.location.assign(this._url);
      }

    }
  }

}

@Injectable({
  providedIn: "root"
})
export class TarifDocumentService extends DSRestCollection<TarifDocument> {
  public adapter_config = {basePath: "/api/v2/tarifs/tarifdocuments"};
  public model = TarifDocument;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
