import {Component} from "@angular/core";
import {SeaLabel, SeaLabelService} from "../sealabel.service";
import {LabelDisplayBaseComponent} from "../../_label/label-display-base/label-display-base.component";

@Component({
  selector: "lvadg-sealabel-display",
  templateUrl: "./sealabel-display.component.pug",
  styleUrls: ["./sealabel-display.component.sass"]
})
export class SeaLabelDisplayComponent extends LabelDisplayBaseComponent<SeaLabel, SeaLabelService> {

  constructor(protected _labels: SeaLabelService) {
    super(_labels);
  }

}
