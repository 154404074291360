import {NgModule} from "@angular/core";
import {TarifCreateComponent} from "./tarif-create/tarif-create.component";
import {TarifListComponent} from "./tarif-list/tarif-list.component";
import {TarifManageComponent} from "./tarif-manage/tarif-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {NgStringPipesModule} from "ngx-pipes";
import {QuillModule} from "ngx-quill";
import {TarifImportdocComponent} from "./tarif-importdoc/tarif-importdoc.component";
import {GenericUploadModule} from "../../document/upload/upload.module";
import {TarifPublishComponent} from "./tarif-publish/tarif-publish.component";
import {TarifNotificationModule} from "../tarifnotification/tarifnotification.module";
import {ArticleModule} from "../../catalog/article/article.module";
import {BsacFormatModule} from "@solidev/bsadmincomponents";
import {TarifLifecycleComponent} from "./tarif-lifecycle/tarif-lifecycle.component";
import {TarifExportComponent} from "./tarif-export/tarif-export.component";
import {TarifSendComponent} from "./tarif-send/tarif-send.component";
import {TarifImportComponent} from "./tarif-import/tarif-import.component";


@NgModule({
  declarations: [
    TarifCreateComponent,
    TarifListComponent,
    TarifManageComponent,
    TarifImportdocComponent,
    TarifPublishComponent,
    TarifLifecycleComponent,
    TarifExportComponent,
    TarifSendComponent,
    TarifImportComponent
  ],
  exports: [
    TarifCreateComponent,
    TarifListComponent,
    TarifManageComponent,
    TarifImportdocComponent,
    TarifLifecycleComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    NgStringPipesModule,
    QuillModule,
    GenericUploadModule,
    TarifNotificationModule,
    ArticleModule,
    BsacFormatModule
  ]
})
export class TarifModule {
}
