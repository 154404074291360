
<bsac-breadcrumb></bsac-breadcrumb>
<h1>
  <button class="btn btn-sm btn-warning float-end" (click)="create=!create" *ngIf="auth.haveScope('pilote:staff:adm')"><i class="fa fa-plus me-2"></i>Ajouter un modèle de tarif</button><i class="fa fa-table me-2"></i>Modèles de tarif
</h1>
<div class="card mb-3" *ngIf="create">
  <div class="card-header"><i class="fa fa-plus me-2"></i>Ajouter un modèle</div>
  <div class="card-body">
    <lvadg-tariftype-create (created)="onCreated($event)" [ttype]="ttype"></lvadg-tariftype-create>
  </div>
</div>
<lvadg-tariftype-list mode="admin" [name]="'admin-tarifs-tariftypes-list'" [filter]="filter" [default_fields]="default_fields" [tariftype_detail_route]="tariftype_detail_route" type="tariftypes"></lvadg-tariftype-list>