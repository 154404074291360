import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {SeaProduct, SeaProductService} from "./seaproduct.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class SeaProductResolve implements Resolve<SeaProduct> {
  constructor(private _bs: SeaProductService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<SeaProduct> {
    return this._bs.get(+route.params["seaproductId"], {query: {psall: 1}});
  }
}
