import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleConditionnement, ArticleConditionnementService} from "../article-conditionnement.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "lvadg-article-conditionnements-manage",
  templateUrl: "./article-conditionnements-manage.component.pug",
  styleUrls: ["./article-conditionnements-manage.component.sass"]
})
export class ArticleConditionnementsManageComponent
  extends ArticleDataManageBaseComponent<ArticleConditionnement, ArticleConditionnementService> {

  public dest!: string;

  constructor(_list: BsacModelListProvider,
              _items: ArticleConditionnementService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "conditionnementId";
  }

  public async preNgOnInit(): Promise<void> {
    if (this.route.snapshot.data.dataType === "colisages") {
      this.filterField = "colisage_cond";
      this.dest = "Colisage";
    } else {
      this.filterField = "uci_cond";
      this.dest = "UCI";
    }
    return super.preNgOnInit();
  }
}
