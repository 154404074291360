
<ng-container *ngIf="bill">
  <h2>{{bill.billdate|date:'shortDate'}} - {{bill|dsCurrency:'net_amount'}}<small class="ms-2">{{bill.storage_details?.name}} &rightarrow; {{bill.resto_details.name}}</small></h2>
  <table class="table table-bordered table-striped table-sm">
    <thead>
      <tr>
        <th>Article</th>
        <th>Code/libellé GC</th>
        <th>Code/libelle Adh</th>
        <th>Quantité</th>
        <th>Pieces</th>
        <th>Packages</th>
        <th>Poids</th>
        <th>UV</th>
        <th>Brut</th>
        <th>Net</th>
        <th>Taxes</th>
        <th>TTC</th>
        <th>Flags</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let l of lines">
        <td>
          <ng-container *ngIf="l.article_details"><a [routerLink]="article_detail_route?.route({article: l.article_details})"><code>{{l.article_details?.code}}</code> {{l.article_details?.libelle}}</a></ng-container>
        </td>
        <td>
          <ng-container *ngIf="l.gc_article_details"><a [routerLink]="ea_detail_route?.route({externalArticle: l.gc_article_details})"><code>{{l.gc_article_details.code}}</code> {{l.gc_article_details.libelle}}</a></ng-container>
          <ng-container *ngIf="!l.gc_article_details"><code>{{l.gc_code}}</code> {{l.gc_libelle}}</ng-container>
        </td>
        <td>
          <ng-container *ngIf="l.adh_article_details"><a [routerLink]="ea_detail_route?.route({externalArticle: l.adh_article_details})"><code>{{l.adh_article_details.code}}</code> {{l.adh_article_details.libelle}}</a></ng-container>
          <ng-container *ngIf="!l.adh_article_details"><code>{{l.adh_code}}</code> {{l.adh_libelle}}</ng-container>
        </td>
        <td>{{l|dsDecimal:'quantity'}}</td>
        <td>{{l|dsDecimal:'pieces'}}</td>
        <td>{{l|dsDecimal:'packages'}}</td>
        <td>{{l|dsDecimal:'weight'}}</td>
        <td>{{l.uv}}</td>
        <td>{{l|dsCurrency:'brut_amount'}}</td>
        <td>{{l|dsCurrency:'net_amount'}}</td>
        <td>{{l|dsCurrency:'vat_amount'}}</td>
        <td>{{l|dsCurrency:'wvat_amount'}}</td>
        <td>
          <div class="badge mx-1 bg-info" *ngFor="let f of l.flags">{{f}}</div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th>{{bill|dsCurrency:'brut_amount'}}</th>
        <th>{{bill|dsCurrency:'net_amount'}}</th>
        <th>{{bill|dsCurrency:'vat_amount'}}</th>
        <th>{{bill|dsCurrency:'wvat_amount'}}</th>
        <th></th>
      </tr>
    </tfoot>
  </table>
</ng-container>