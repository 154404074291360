import {Component, Input, Output, TemplateRef} from "@angular/core";
import {Tarif} from "../../tarif/tarif.service";
import {ArticleTarif, ArticleTarifService, ArticleTarifStatus} from "../articletarif.service";
import {BsacMessageService, BsacModelList, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {firstValueFrom, ReplaySubject, Subject} from "rxjs";
import {Article, ArticleService} from "../../../catalog/article/article.service";
import {tap} from "rxjs/operators";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {IDSModelList} from "@solidev/ngdataservice";
import {ArticleTarifTemplateService} from "../articletariftemplate.service";
import {ColOpModes, IAtlmMarche, IAtlmRelation, IAtlmStorage} from "./articletarif-listmanage.interfaces";
import {ArticleFamilyService} from "../../../catalog/family/article-family.service";
import {IEditorData} from "./editors";
import {TarifRelation, TarifRelationService} from "../../tarifrelation/tarifrelation.service";
import {StoragesModes, TarifTypes} from "../../tariftype/tariftype.service";


@Component({
  selector: "lvadg-articletarif-listmanage",
  templateUrl: "./articletarif-listmanage.component.pug",
  styleUrls: ["./articletarif-listmanage.component.sass"]
})
export class ArticletarifListmanageComponent extends ModelListBaseComponent<ArticleTarif, ArticleTarifService> {
  @Input() public tarif!: Tarif;
  @Input() public article_detail_route!: RouteV3<Article>;
  @Output() public current$ = new ReplaySubject<ArticleTarif>(1);
  @Output() public curcol$ = new ReplaySubject<string>(1);
  public currentCol = "";
  public expand: { [index: number]: boolean } = {};
  public editors: { [index: string]: { [index: number]: any } } = {};
  public focus$ = new Subject<IEditorData>();
  public updates$ = new Subject<ArticleTarif>();
  public list!: BsacModelList<ArticleTarif>;
  public create: boolean = false;
  public groupForm = new UntypedFormGroup({group1: new UntypedFormControl(-1), group2: new UntypedFormControl(-1)});
  public reload = new Subject<void>();
  public storages_columns: IAtlmStorage[] = [];
  public marches_columns: IAtlmMarche[] = [];
  public trel_columns: IAtlmRelation[] = [];
  public familyDisplayInterval = {start: 0, end: 4, display: false};
  public max = Math.max;
  public min = Math.min;
  public colop = ColOpModes;


  public lastShowVariations: { [index: number]: boolean } = {};
  public MARCHES = [
    ["FB", "France Bio"],
    ["LO", "MIN Lomme"],
    ["LC", "MIN Lyon Corbas"],
    ["NA", "MIN Nantes"],
    ["R1", "MIN Rungis 1"],
    ["R2", "MIN Rungis 2"],
    ["TO", "MIN Toulouse"]];
  public DISPLAY_STATUSES = [
    {desc: "Tous (actifs avec/sans prix, inactifs)", value: "all"},
    {desc: "Actifs (avec/sans prix) uniquement", value: "active"},
    {desc: "Actifs avec prix uniquement", value: "activewithprice"},
    {desc: "Actifs sans prix uniquement", value: "activewithoutprice"}
  ];
  public showMessage!: boolean;
  public tRelations: TarifRelation[] = [];
  private _createInstance?: NgbModalRef;

  constructor(
    _list: BsacModelListProvider,
    public at$: ArticleTarifService,
    public atg$: ArticleTarifTemplateService,
    public articles$: ArticleService,
    private _modal: NgbModal,
    public families$: ArticleFamilyService,
    private _trel: TarifRelationService,
    _msgs: BsacMessageService,
    routes: RoutesService,
  ) {
    super(_list, at$, _msgs, routes);
  }


  async preNgOnInit(): Promise<void> {
    // Base custom fields
    this.custom_fields = [
      {name: "lastprice1", verbose_name: "Prix T-1"},
      {name: "lastprice2", verbose_name: "Prix T-2"},
      {name: "lastprice3", verbose_name: "Prix T-3"},
      {name: "variation1", verbose_name: "Variation %/T-1"},
      {name: "variation2", verbose_name: "Variation %/T-2"},
      {name: "variation3", verbose_name: "Variation %/T-3"},
      {name: "activation", verbose_name: "Activation"}
    ];
    this.default_fields = ["att", "message", "article_family", "activation", "price", "article_uf", "lastprice1", "variation1"];

    // TARIF RELATIONS
    this.tRelations = (await firstValueFrom(this._trel.queryset.query({dest: this.tarif.id}).get())).items;
    for (const r of this.tRelations) {
      // REF & NFO relation
      if (r.relation_details.type === "NFO") {
        this.custom_fields.push({name: `trel_${r.id}`, verbose_name: r.src_details?.name || "???"});
        this.trel_columns.push({
          name: `trel_${r.id}`,
          verbose_name: r.src_details?.type_details?.name,
          relId: r.id,
          srcId: r.src,
          type: r.relation_details.type,
          gsprice: true,
          oprice: false
        });
        this.default_fields.push(`trel_${r.id}`);
        if (r.src_details?.type_details?.storages_details?.length) {
          for (const s of r.src_details?.type_details?.storages_details) {
            for (const st of this.tarif.type_details.storages_details) {
              if (s.id == st.id) {
                this.custom_fields.push({name: `treld_${r.id}_${s.id}`, verbose_name: `${s.name}`});
                this.trel_columns.push({
                  name: `treld_${r.id}_${s.id}`,
                  verbose_name: `${s.name}`,
                  relId: r.id,
                  srcId: r.src,
                  stoId: s.id,
                  type: r.relation_details.type
                });
                this.default_fields.push(`treld_${r.id}_${s.id}`);
              }
            }
          }
        }
      } else if (r.relation_details.type === "REF" || r.relation_details.type === "LOC") {
        this.custom_fields.push({name: `trel_${r.id}`, verbose_name: r.src_details?.name || "???"});
        this.trel_columns.push({
          name: `trel_${r.id}`,
          verbose_name: r.src_details?.type_details?.name,
          relId: r.id,
          srcId: r.src,
          type: r.relation_details.type
        });
      }

    }

    this.filter = {tarif: this.tarif.id};
    this.name = this.name + `@T${this.tarif.type_details.id}`;
    this.reuseList = false;
    this.default_filters.push("displaystatus");

    // TARIFS MARCHES
    for (const m of this.MARCHES) {
      this.custom_fields.push({name: `marche_${m[0]}`, verbose_name: m[1]});
      this.marches_columns.push({name: `marche_${m[0]}`, verbose_name: m[1], marcheId: m[0]});
      this.default_fields.push("marche_R1");
    }

    // TARIFS PAR DEPOT - en premier les dépôts avec droits d'écriture
    // TODO: trier aussi (côté api?) sur l'adhérent
    const canWriteStorages: {[index: number]: true} = {};
    for (const s of this.tarif.type_details.storages_details) {
      let canwrite = this.tarif.user_storages.indexOf(s.id) !== -1 || this.tarif.user_level === "sadmin";
      canwrite = canwrite && (this.tarif.status !== "OK" && this.tarif.status !== "AR");
      if (canwrite) {
        canWriteStorages[s.member] = true;
        this.custom_fields.push({name: `storage_${s.id}`, verbose_name: s.name});
        this.storages_columns.push({name: `storage_${s.id}`, verbose_name: s.name, storageId: s.id,
          storage: s.name, member: s.member_details.name, readonly: !canwrite});
        this.default_fields.push(`storage_${s.id}`);
      }
    }
    for (const s of this.tarif.type_details.storages_details) {
      let canwrite = this.tarif.user_storages.indexOf(s.id) !== -1 || this.tarif.user_level === "sadmin";
      canwrite = canwrite && (this.tarif.status !== "OK" && this.tarif.status !== "AR");
      if (!canwrite && canWriteStorages[s.member]) {
        this.custom_fields.push({name: `storage_${s.id}`, verbose_name: s.name});
        this.storages_columns.push({name: `storage_${s.id}`, verbose_name: s.name, storageId: s.id,
          storage: s.name, member: s.member_details.name, readonly: !canwrite});
        this.default_fields.push(`storage_${s.id}`);
      }
    }
    for (const s of this.tarif.type_details.storages_details) {
      let canwrite = this.tarif.user_storages.indexOf(s.id) !== -1 || this.tarif.user_level === "sadmin";
      canwrite = canwrite && (this.tarif.status !== "OK" && this.tarif.status !== "AR");
      if (!canwrite && !canWriteStorages[s.member]) {
        this.custom_fields.push({name: `storage_${s.id}`, verbose_name: s.name});
        this.storages_columns.push({name: `storage_${s.id}`, verbose_name: s.name, storageId: s.id,
          storage: s.name, member: s.member_details.name, readonly: !canwrite});
        this.default_fields.push(`storage_${s.id}`);
      }
    }
  }


  public isEditable(): boolean {
    if (this.tarif.type_details.type === TarifTypes.Client) {
      if (this.storages_columns.length > 0 && this.tarif.type_details.storages_mode === StoragesModes.Price) {
        return false;
      }
      if (["PR", "TV", "VA"].indexOf(this.tarif.status) !== -1) {
        return true;
      }
      if (this.tarif.step && this.tarif.step.indexOf("UPD", 0) !== -1 && this.tarif.step.indexOf("pdone") === -1) {
        return true;
      }
    } else if(this.tarif.type_details.type === TarifTypes.Generic) {
      if (this.storages_columns.length > 0) {
        return false;
      }
      if (["PR", "TV", "VA"].indexOf(this.tarif.status) !== -1) {
        return true;
      }
      if (this.tarif.step && this.tarif.step.indexOf("UPD", 0) !== -1 && this.tarif.step.indexOf("pdone") === -1) {
        return true;
      }
    }
    return false;
  }

  public async postNgOnInit(): Promise<void> {
    this.list.filter.set("displaystatus", "active");
    this.list.emitFields = true;
    this.newResults.pipe(tap((res) => {
    }));
  }

  public processResults(results: IDSModelList<ArticleTarif>): ArticleTarif[] {
    for (const t of results.items) {
      t.processItems();
    }
    return results.items;
  }

  public onSelected(at: ArticleTarif): void {
    this.current$.next(at);
    this.current = at;
    localStorage.setItem("ARTICLETARIF_ID", at.id.toString());
  }

  public onSelectedCol($event: string) {
    this.curcol$.next($event);
    this.currentCol = $event;
  }


  public openCreate(content: TemplateRef<any>) {
    this._createInstance = this._modal.open(content, {size: "xl"});
  }

  public onCreated(close: boolean) {
    this.reload.next();
    if (close && this._createInstance) {
      this._createInstance.close();
    }
  }

  public onRelUpdated() {
    console.log("Need to reload...");
    this.reload.next();
  }

  public eltId(index: number, el: any): string {
    return `${el.id}-${el.updated}-${el.group_details?.length}`;
  }

  public trackColumn(index: number, el: any): string {
    return `${el.name}`;
  }

  public getRowClasses(model: ArticleTarif): { [index: string]: string | boolean } {
    const out: { [index: string]: string | boolean } = {};
    if (model && this.current && model.id === this.current.id) {
      out["bg-active"] = true;
    }
    if (model.status === ArticleTarifStatus.INACTIVE) {
      out["text-muted"] = true;
    }
    return out;
  }

  public getCellClasses(col: string, model: ArticleTarif): { [index: string]: string | boolean } {
    const out: { [index: string]: string | boolean } = {};
    if (col === this.currentCol) {
      out["bg-active"] = true;
    }
    return out;
  }


  public async removeArticleFromGroup(idGroup: number, idArticle: number): Promise<void> {
    const group = await firstValueFrom(this.atg$.get(idGroup));
    await firstValueFrom(group.action("remove_article", {
      method: "POST",
      body: {article: idArticle}
    }));
    this._msgs.success("Elément de groupe supprimé avec succès");
    this.reload.next();
  }

  public async removeArticleTarif(idArticle: number): Promise<void> {
    await firstValueFrom(this.at$.remove(idArticle));
    this._msgs.success("Ligne supprimée avec succès");
    this.reload.next();
  }

  public getTableClasses(): { [p: string]: string | boolean } {
    return {"table-sm": true};
  }

  public async toggleStatus(row: ArticleTarif) {
    const at = await firstValueFrom(row.action("toggle_active", {
      method: "POST",
      body: {}
    }));
    row.status = at.status;
    this.updates$.next(row);
  }

  public activateNext(editor: IEditorData, pos: number = 1) {
    if (!this.editors[editor.type]) {
      this.editors[editor.type] = {};
    }
    let available = [];
    for (const k of Object.keys(this.editors[editor.type])) {
      available.push(+k);
    }
    available = available.sort((a, b) => a - b);
    const next = available[available.indexOf(editor.id) + pos];
    if (next) {
      this.focus$.next(this.editors[editor.type][next]);
    }
  }

  public registerEditor(editor: IEditorData) {
    if (!this.editors[editor.type]) {
      this.editors[editor.type] = {};
    }
    this.editors[editor.type][editor.id] = editor;
  }

  public unregisterEditor(editor: IEditorData) {
    if (!this.editors[editor.type]) {
      this.editors[editor.type] = {};
    }
    delete this.editors[editor.type][editor.id];
  }

  public async savedPrice($event: IEditorData) {
    return this.updateArticle($event.at);
  }

  public async updateArticle(art: ArticleTarif) {
    const fields = (await firstValueFrom(this.list.fields.output$)).join(",");
    await firstValueFrom(art.refresh({query: {fields}}));
    this.reload.next();
  }
}
