import {Injectable} from "@angular/core";
import {DSAutoField, DSCharField, DSIntegerField, DSModel, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";


export class CiqualAlim extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSIntegerField({
    name: "alim_code",
    verboseName: "Code CIQUAL",
    required: false,
    helpText: "Code CIQUAL",
    default: 0,
    choices: []
  })
  public alim_code!: number;

  @DSCharField({
    name: "alim_grp_code",
    verboseName: "Code groupe aliment",
    required: false,
    helpText: "Code groupe aiment",
    minLength: 0,
    maxLength: 20,
    choices: []
  })
  public alim_grp_code!: string;

  @DSCharField({
    name: "alim_ssgrp_code",
    verboseName: "Code sous-groupe aliment",
    required: false,
    helpText: "Code sous-groupe aliment",
    minLength: 0,
    maxLength: 20,
    choices: []
  })
  public alim_ssgrp_code!: string;

  @DSCharField({
    name: "alim_ssssgrp_code",
    verboseName: "Code sous-sous-groupe aliment",
    required: false,
    helpText: "Code sous-sous-groupe aliment",
    minLength: 0,
    maxLength: 20,
    choices: []
  })
  public alim_ssssgrp_code!: string;

  @DSCharField({
    name: "alim_grp_nom_fr",
    verboseName: "Nom groupe aliment",
    required: false,
    helpText: "Nom groupe aliment",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public alim_grp_nom_fr!: string;

  @DSCharField({
    name: "alim_ssgrp_nom_fr",
    verboseName: "Nom sous-groupe aliment",
    required: false,
    helpText: "Nom sous-groupe aliment",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public alim_ssgrp_nom_fr!: string;

  @DSCharField({
    name: "alim_ssssgrp_nom_fr",
    verboseName: "Nom sous-sous-groupe aliment",
    required: false,
    helpText: "Nom sous-sous-groupe aliment",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public alim_ssssgrp_nom_fr!: string;

  @DSCharField({
    name: "alim_nom_fr",
    verboseName: "Nom aliment",
    required: false,
    helpText: "Nom aliment",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public alim_nom_fr!: string;

  @DSCharField({
    name: "alim_nom_sci",
    verboseName: "Nom scientifique",
    required: false,
    helpText: "Nom scientifique",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public alim_nom_sci!: string;


}

@Injectable({
  providedIn: "root"
})
export class CiqualAlimService extends DSRestCollection<CiqualAlim> {
  public adapter_config = {basePath: "/api/v2/ciqualalims"};
  public model = CiqualAlim;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
