import {Injectable} from "@angular/core";
import {DSAutoField, DSCharField, DSModel, DSRestCollection, DSRestCollectionSetupProvider, DSSlugField} from "@solidev/ngdataservice";


export class ArticleOrigine extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "vvid",
    verboseName: "Id Vivalya",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 16,
    choices: []
  })
  public vvid!: string;

  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["CN", "Pays"],
      ["CT", "Continent"],
      ["ZP", "Zone de pêche"],
      ["DV", "Divers"]
    ]
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;
}

@Injectable({
  providedIn: "root"
})
export class ArticleOrigineService extends DSRestCollection<ArticleOrigine> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/origines"};
  public model = ArticleOrigine;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
