import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PersonSelectComponent} from "./person-select/person-select.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  declarations: [
    PersonSelectComponent,
  ],
  exports: [
    PersonSelectComponent,
  ],
})
export class PersonSelectModule {
}
