import {NgModule} from "@angular/core";
import {LabelCreateComponent} from "./label-create/label-create.component";
import {LabelDeleteComponent} from "./label-delete/label-delete.component";
import {LabelDisplayComponent} from "./label-display/label-display.component";
import {LabelListComponent} from "./label-list/label-list.component";
import {LabelSelectComponent} from "./label-select/label-select.component";
import {LabelManageComponent} from "./label-manage/label-manage.component";
import {LabelService} from "./label.service";
import {LabelResolve} from "./label.resolve";
import {ReactiveFormsModule} from "@angular/forms";
import {LabelPrefetchResolve} from "./labelprefetch.resolve";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    ReactiveFormsModule
  ],
  providers: [
    LabelService,
    LabelResolve,
    LabelPrefetchResolve
  ],
  declarations: [
    LabelSelectComponent,
    LabelDisplayComponent,
    LabelDeleteComponent,
    LabelListComponent,
    LabelManageComponent,
    LabelCreateComponent
  ],
  exports: [
    LabelSelectComponent,
    LabelDisplayComponent,
    LabelDeleteComponent,
    LabelListComponent,
    LabelManageComponent,
    LabelCreateComponent
  ],
})
export class LabelModule {
}
