import {Injectable} from "@angular/core";
import {TarifService} from "../../tarif/tarif/tarif.service";
import {MeteoFull} from "./meteo-full";
import {Observable, ReplaySubject} from "rxjs";
import {first} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class MeteoService {
  private _meteo: MeteoFull;
  private _meteo$: ReplaySubject<MeteoFull>;

  constructor(private _ts: TarifService) {
  }

  public get full(): Observable<MeteoFull> {
    if (!this._meteo$) {
      this._meteo$ = new ReplaySubject<MeteoFull>(1);
      this._ts.action(null, "meteo", {method: "GET"})
        .pipe(first())
        .subscribe((meteo) => {
          this._meteo = meteo;
          this._meteo$.next(this._meteo);
        });
    }
    return this._meteo$.asObservable();
  }

}
