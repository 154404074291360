import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleEgalim, ArticleEgalimService} from "../article-egalim.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";
import {SeaLabelService} from "../../../product/sealabel/sealabel.service";
import {LabelService} from "../../../product/label/label.service";
import {ClientService} from "../../../structure/client/client.service";

@Component({
  selector: "lvadg-article-egalims-manage",
  templateUrl: "./article-egalims-manage.component.pug",
  styleUrls: ["./article-egalims-manage.component.sass"]
})
export class ArticleEgalimsManageComponent extends ArticleDataManageBaseComponent<ArticleEgalim, ArticleEgalimService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleEgalimService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute,
              public slabels$: SeaLabelService,
              public flabels$: LabelService,
              public clients$: ClientService) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "egalimId";
    this.filterField = "egalim";
  }
}
