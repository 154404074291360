import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Tarif} from "../tarif.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {TarifType, TarifTypeService} from "../../tariftype/tariftype.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";

export interface Destination {
  firstname: string;
  lastname: string;
  email: string;
  enabled: boolean;
}

@Component({
  selector: "lvadg-tarif-send",
  templateUrl: "./tarif-send.component.pug",
  styleUrls: ["./tarif-send.component.sass"]
})
export class TarifSendComponent implements OnInit {
  public contacts: Destination[] = [];
  public addContact = false;
  public sending = false;
  public tarifType!: TarifType;
  @Output() public sent = new EventEmitter<void>();
  @Input() public tarif!: Tarif;
  public template = "";

  public quillModules = {
    toolbar: [
      ["bold", "italic", "underline", {"script": "sub"}, {"script": "super"}],
      [{"list": "ordered"}, {"list": "bullet"}],
      ["clean", "link"]
    ]
  };
  public contentForm = new UntypedFormGroup({
    title: new UntypedFormControl(""),
    message: new UntypedFormControl("")
  });
  public contactForm = new UntypedFormGroup({
    firstname: new UntypedFormControl(""),
    lastname: new UntypedFormControl(""),
    email: new UntypedFormControl("")
  });

  constructor(private _tt: TarifTypeService, private _msgs: BsacMessageService) {
  }

  async ngOnInit(): Promise<void> {
    this.template = localStorage.getItem(`tmsgtpl-send-${this.tarif.type}`) || "";
    this.tarifType = await firstValueFrom(this._tt.get(this.tarif.type));
    for (const c of this.tarifType.contacts_details) {
      this.contacts.push({lastname: c.lastname, firstname: c.firstname, email: c.email, enabled: true});
    }
  }

  public createContact() {
    this.contacts.push({
      lastname: this.contactForm.value.lastname,
      firstname: this.contactForm.value.firstname,
      email: this.contactForm.value.email,
      enabled: true
    });
    this.addContact = false;
    this.contactForm.reset({
      lastname: "",
      firstname: "",
      email: ""
    });
  }

  public async sendMessages() {
    this.sending = true;
    try {
    const res = await firstValueFrom(
      this.tarif.action("send", {
        method: "POST",
        body: {
          destinations: this.contacts,
          title: this.contentForm.value.title,
          message: this.contentForm.value.message
        }
      }));
      this.sent.emit();
      this._msgs.success("Envoi effectué avec succès")
      this.sending = false;
    } catch (e) {
      console.log(e)
      this._msgs.error("Erreur lors de l'envoi", `Message non envoyé\n${(e as any).error}`,(e as any).message)
      this.sending = false;
    }
  }


  public async getDiff(): Promise<void> {
    const {diff} = await firstValueFrom<{ diff: string }>(this.tarif.action("diff", {
      method: "GET"
    }));
    this.contentForm.patchValue({message: (this.contentForm.value.message || "") + diff});
  }

  public useTemplate(): void {

    this.contentForm.patchValue({message: (this.contentForm.value.message || "") + this.template});
  }

  public saveTemplate(): void {
    localStorage.setItem(`tmsgtpl-send-${this.tarif.type}`, this.contentForm.value.message);
    this.template = this.contentForm.value.message;
  }

}
