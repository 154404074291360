import {NgModule} from "@angular/core";
import {MemberCreateComponent} from "./member-create/member-create.component";
import {MemberDeleteComponent} from "./member-delete/member-delete.component";
import {MemberDisplayComponent} from "./member-display/member-display.component";
import {MemberListComponent} from "./member-list/member-list.component";
import {MemberSelectComponent} from "./member-select/member-select.component";
import {MemberManageComponent} from "./member-manage/member-manage.component";
import {LocationModule} from "../location/location.module";
import {CompsModule} from "../../../comps/comps.module";
import {ImageModule} from "../../content/image/image.module";
import {StatsModule} from "../../stats/stats/stats.module";
import {SLabelModule} from "../slabel/slabel.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    LocationModule,
    ImageModule,
    CompsModule,
    SLabelModule,
    StatsModule,
    CustomDataModule
  ],
  declarations: [
    MemberSelectComponent,
    MemberDisplayComponent,
    MemberDeleteComponent,
    MemberListComponent,
    MemberManageComponent,
    MemberCreateComponent
  ],
  exports: [
    MemberSelectComponent,
    MemberDisplayComponent,
    MemberDeleteComponent,
    MemberListComponent,
    MemberManageComponent,
    MemberCreateComponent
  ],
})
export class MemberModule {
}
