import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticleCategorie, ArticleCategorieService} from "../article-categorie.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "lvadg-article-categories-manage",
  templateUrl: "./article-categories-manage.component.pug",
  styleUrls: ["./article-categories-manage.component.sass"]
})
export class ArticleCategoriesManageComponent extends ArticleDataManageBaseComponent<ArticleCategorie, ArticleCategorieService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleCategorieService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "categorieId";
    this.filterField = "categorie";
  }
}
