
<ng-template #ftree>
  <ng-container *ngIf="families">
    <div class="ms-2" *ngFor="let f of families"><span class="pointer" (click)="openclose(f)" [class.current]="current == f.id">{{f.name ? (f.name==="-" ? "générique" : f.name) : "générique"}}</span>
      <div class="ms-3" *ngIf="opened[f.id]">
        <lvadg-vivalya-family-tree-view [parent]="f"></lvadg-vivalya-family-tree-view>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-container *ngIf="ready &amp;&amp; !parent">
  <lvadg-dynamic-breadcrumb></lvadg-dynamic-breadcrumb>
  <h1 class="page-header"><i class="fa fa-folder-tree me-2"></i>Gestion des familles catalogue Vivalya</h1>
  <div class="row">
    <div class="col-sm-3 col-lg-2">
      <ng-container *ngTemplateOutlet="ftree"></ng-container>
    </div>
    <div class="col-sm-9 col-lg-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="ready &amp;&amp; parent">
  <ng-container *ngTemplateOutlet="ftree"></ng-container>
</ng-container>