
<ng-container *ngFor="let c of categs">
  <table class="table table-striped table-condensed">
    <thead>
      <tr>
        <th class="text-center" colspan="2">{{c.name}}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let k of keys">
        <tr *ngIf="k.parent == c.id">
          <td class="pointer" (click)="edit[k.id]=!readonly[k.id] &amp;&amp; !edit[k.id]">{{k.name}}</td>
          <td *ngIf="!edit[k.id]"><i class="fa fa-pencil me-2 pointer float-start" (click)="edit[k.id]=true" *ngIf="!readonly[k.id]"></i>
            <lvadg-customdata-value-display [key]="k" [value]="values[k.id]" [altvalue]="altvalues[k.id]" [readonly]="readonly[k.id]"></lvadg-customdata-value-display>
          </td>
          <td *ngIf="!readonly[k.id] &amp;&amp; edit[k.id]">
            <lvadg-customdata-value-edit [key]="k" [value]="values[k.id]" [altvalue]="altvalues[k.id]" [readonly]="readonly[k.id]" (edited)="save(k, $event)" (removed)="save(k, null, true)"></lvadg-customdata-value-edit>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>