import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {IDSModel} from "@solidev/ngdataservice";
import {CustomDataKey, CustomDataKeyService} from "../../customdatakey/customdatakey.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {CustomData} from "../customdata.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-customdata-field-manage",
  templateUrl: "./customdata-field-manage.component.pug",
  styleUrls: ["./customdata-field-manage.component.sass"]
})
export class CustomDataFieldManageComponent implements OnInit {
  @Input() public model!: IDSModel;
  @Input() public code!: string;
  @Input() public dest!: string;
  @Input() public showLabel = true;
  @Input() public alt!: string;
  @Input() public write!: string;
  @Output() public edited = new EventEmitter<IDSModel>();
  @Output() public cancelled = new EventEmitter<void>();

  public edit = false;
  public key!: CustomDataKey | null;
  public value!: CustomData;
  public altvalue!: CustomData;
  public readonly = false;

  constructor(private _customdatakeys: CustomDataKeyService,
              private _msgs: BsacMessageService) {
  }

  public async ngOnInit(): Promise<any> {
    this.key = await firstValueFrom(this._customdatakeys.getByDestCode(this.dest, this.code));
    if (this.key) {
      const kv = this.key.values(this.model, this.alt, this.write);
      this.readonly = kv.readonly;
      this.value = kv.value;
      this.altvalue = kv.altvalue;
    }
  }

  public async save(value: any, unset: boolean = false): Promise<void> {
    if (this.key) {
      if (this.key.type === "DOC") {
        value = value ? value.id : null;
      } else if (this.key.type === "FOL") {
        value = value ? value.id : null;
      }


      await firstValueFrom(this.model.action("update_customdata", {
        method: "POST", body: {
          key: this.key.id,
          value: value,
          unset: unset
        }
      }));
      await firstValueFrom(this.model.refresh());
      await this.ngOnInit();
      this.edited.emit(this.model);
      this.edit = false;
      this._msgs.success("Enregistré");
    }
  }

}
