export const LEGALTYPES = [
  ["APEA", "Affaire personnelle exploitant agricole"],
  ["EARL", "EARL: Entreprise agricole à responsabilité limitée"],
  ["EI", "EI: Entreprise individuelle"],
  ["EIRL", "EIRL: Entreprise individuelle à responsabilité limitée (01.01.2010)"],
  ["EURL", "EURL: Entreprise unipersonnelle à responsabilité limitée"],
  ["GAEC", "GAEC: Groupement agricole d'exploitation en commun"],
  ["GEIE", "GEIE: Groupement européen d'intérêt économique"],
  ["GIE", "GIE: Groupement d'intérêt économique"],
  ["SARL", "SARL: Société à responsabilité limitée"],
  ["SA", "SA: Société anonyme"], ["SAS", "Société par actions simplifiée"],
  ["SASU", "SASU: Société par actions simplifiée unipersonnelle"],
  ["SC", "SC: Société civile"],
  ["SCA", "SCA: Société en commandite par actions"],
  ["SCI", "SCI: Société civile immobilière"],
  ["SCIC", "SCIC: Société coopérative d'intérêt collectif"],
  ["SCM", "SCM: Société civile de moyens"],
  ["SCOP", "SCOP: Société coopérative ouvrière de production"],
  ["SCP", "SCP: Société civile provessionnelle"],
  ["SCS", "SCS: Société en commandite simple"],
  ["SEL", "SEL: Société d'exercice libéral"],
  ["SELAFA", "SELAFA: Société d'exercice libéral à forme anonyme"],
  ["SELARL", "SELARL: Société d'exercice libéral à responsabilité limitée"],
  ["SELAS", "SELAS: Société d'exercice libéral par actions simplifiée"],
  ["SELCA", "SELCA: Société d'exercice libéral en commandite par actions"],
  ["SEM", "SEM: Société d'économie mixte"],
  ["SEML", "SEML: Société d'économie mixte locale"],
  ["SEP", "SEP: Société en participation"],
  ["SICA", "SICA: Société d'intérêt collectif agricole"],
  ["SNC", "SNC: Société en nom collectif"],
  ["OTH", "???: Autre forme légale"]
];


