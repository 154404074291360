import {Component, Input, OnInit} from "@angular/core";
import {IDSModel} from "@solidev/ngdataservice";
import {CustomDataKey, CustomDataKeyService} from "../../customdatakey/customdatakey.service";
import {CustomData} from "../customdata.service";
import {first} from "rxjs/operators";

@Component({
  selector: "lvadg-customdata-field-display",
  templateUrl: "./customdata-field-display.component.pug",
  styleUrls: ["./customdata-field-display.component.sass"]
})
export class CustomDataFieldDisplayComponent implements OnInit {
  @Input() public model!: IDSModel;
  @Input() public code!: string;
  @Input() public default = "???";
  @Input() public alt!: string;
  @Input() public write!: string;
  public key!: CustomDataKey;
  public value!: CustomData;
  public altvalue!: CustomData;
  public readonly = false;


  constructor(private _keys: CustomDataKeyService) {
  }

  public ngOnInit(): void {
    const mmodel = (this.model as any);
    if (this.model && mmodel.customdata_details) {
      this._keys.getByCode(this.code).pipe(first())
        .subscribe((k) => {
          this.key = k as CustomDataKey;
          const kv = this.key.values(this.model, this.alt, this.write);
          if (kv.found) {
            this.value = kv.value;
            this.readonly = kv.readonly;
            this.altvalue = kv.altvalue;
          }
        });
    }
  }

}
