import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSManyToManyField,
  DSModel,
  DSTextField
} from "@solidev/ngdataservice";
import {ProductOfferDetailsDetails, SeaProductOfferDetailsDetails} from "../../product/_product/product-base.service";

export class BaseOfferProduct extends DSModel {
  @DSAutoField({
    name: "id"
  })
  public id!: number;


  @DSForeignKeyField({
    name: "offer",
    required: false,
    verboseName: "ID Offre"
  })
  public offer!: number;

  @DSIntegerField({
    name: "order",
    required: false,
    default: 0,
    verboseName: "Ordre"
  })
  public order!: number;

  @DSTextField({
    name: "comment",
    required: false,
    default: 0,
    verboseName: "Commentaires"
  })
  public comment!: string;

  @DSForeignKeyField({
    name: "product",
    required: false,
    verboseName: "ID Produit"
  })
  public product!: number;

  @DSManyToManyField({
    name: "details",
    required: false,
    verboseName: "ID Détails"
  })
  public details!: number[];

  @DSDefaultField({
    name: "details_details",
    required: false,
    verboseName: "Détails"
  })
  public details_details: (ProductOfferDetailsDetails | SeaProductOfferDetailsDetails)[];

  @DSDateTimeField({
    name: "created",
    required: false,
    verboseName: "Date de création"
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    required: false,
    verboseName: "Date de modification"
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    required: false,
    verboseName: "Etat",
    choices: [["OK", "Valide"], ["KO", "Non valide"]],
    default: "PRP"
  })
  public status!: string;

  public product_details!: any;
  public variant_details!: any;
  public price_details!: any;

}
