import {Offer, OfferService} from "../offer.service";
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {StorageService} from "../../../structure/storage/storage.service";
import {ClientService} from "../../../structure/client/client.service";
import {Member} from "../../../structure/member/member.service";
import {Subject} from "rxjs";
import {first, takeUntil} from "rxjs/operators";
import {Resto, RestoService} from "../../../structure/resto/resto.service";
import {map} from "lodash-es";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "lvadg-offer-create",
  templateUrl: "./offer-create.component.pug",
  styleUrls: ["./offer-create.component.sass"]
})
export class OfferCreateComponent implements OnInit, OnDestroy {
  public offer!: Offer;
  public form!: UntypedFormGroup;
  @Input() public mode = "member";
  @Input() public member!: Member;
  @Input() public zone = "fl";
  @Output() public created: EventEmitter<Offer> = new EventEmitter<Offer>();
  @Output() public cancelled: EventEmitter<any> = new EventEmitter<any>();

  public storageFilter: any = {};
  public clientFilter: any = {};
  public clientReset$ = new Subject<void>();
  public restoFilter: any = {};
  public generic = false;
  public restos: Resto[] = [];
  public selectedRestos: Resto[] = [];
  public datestart!: Date;
  public dateend!: Date;

  private _subscriptions$ = new Subject<void>();

  constructor(private _offers: OfferService,
              public storages$: StorageService,
              public clients$: ClientService,
              public restos$: RestoService) {
  }

  public ngOnDestroy(): void {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  public ngOnInit(): void {
    this._offers.create().subscribe(model => {
      this.offer = model;
      this.form = new UntypedFormGroup({
        storage: new UntypedFormControl(),
        client: new UntypedFormControl()
      });
    });
    // Changement de dépôt
    this.form.get("storage")?.valueChanges
      .pipe(takeUntil(this._subscriptions$))
      .subscribe((change) => this.onStorageChange(change));
    // Changement de client
    this.form.get("client")?.valueChanges
      .pipe(takeUntil(this._subscriptions$))
      .subscribe((change) => this.onClientChange(change));

    if (this.member) {
      this.restoFilter = {member: this.member.id, havesite: 1};
      this.storageFilter = {member: this.member.id, gtype: this.zone};
      this.clientFilter = {member: this.member.id};
    } else {
      this.restoFilter = {};
      this.storageFilter = {gtype: this.zone};
      this.clientFilter = {};
    }
  }

  // Set date interval
  public setDateRange(range: { from: NgbDate, to: NgbDate }): void {
    this.datestart = new Date(Date.UTC(range.from.year, range.from.month - 1, range.from.day, 6, 0, 0));
    this.dateend = new Date(Date.UTC(range.to.year, range.to.month - 1, range.to.day, 21, 0, 0));
  }

  // Modification storage (=> modification client et restos)
  public onStorageChange(newStorage: number): void {
    this.clientFilter.cstorage = newStorage;
    this.form.get("client")?.setValue(undefined);
    this.clientReset$.next();
    this.restoFilter.storage = newStorage;
    this.generic = true;
  }

  // Modification client
  public onClientChange(newClient?: number): void {
    this.restoFilter.cclient = newClient;
    this.restos = [];
    this.selectedRestos = [];
    if (newClient) {
      this.generic = false;
      // SEE: add large pagination (200 ?)
      this.restos$.queryset.query(this.restoFilter).get().pipe(first()).subscribe((restos) => {
        this.restos = restos.items;
        this.allRestos();
      });
    } else {
      this.generic = true;
    }
  }

  public allRestos(): void {
    this.selectedRestos = [];
    for (const r of this.restos) {
      this.selectedRestos.push(r);
    }

  }


  public makeGeneric(): void {
    this.onClientChange();
    this.create();
  }

  public create() {
    if (this.form.valid && this.datestart && this.dateend) {
      const data = this.form.value;
      data.type = this.zone.toUpperCase();
      data.datestart = this.datestart;
      data.dateend = this.dateend;
      if (this.member) {
        data.member = this.member.id;
      }
      data.status = "PRP";
      if (this.generic) {
        delete data.client;
      } else {
        data.restos = map(this.selectedRestos, "id");
      }
      this._offers.create(data, {save: true}).subscribe(saved => {
        this.created.emit(saved);
      });
    }
  }

  public cancel() {
    this.cancelled.emit(true);
  }

}
