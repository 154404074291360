/**
 * Ps list component
 *
 * Display a list of products, sorting and filtering
 *
 * @status BETA
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @date 2020-05-06-08:37:58
 * @copyright (c) 2017-2020 Jean-Matthieu BARBIER
 * @license AGPLv3
 */


import {GeoCommune, GeoCommuneService} from "../../../customdata/geocommune/geocommune.service";
import {GeoDepartement, GeoDepartementService} from "../../../customdata/geodepartement/geodepartement.service";
import {GeoRegion, GeoRegionService} from "../../../customdata/georegion/georegion.service";
import {Member, MemberService} from "../../../structure/member/member.service";
import {Storage, StorageService} from "../../../structure/storage/storage.service";
import {SEASONS} from "../../common/seasons.enum";
import {Directive, Input, OnDestroy, OnInit} from "@angular/core";
import {BsacMessageService, BsacModelListProvider, IBsacApiConstants} from "@solidev/bsadmincomponents";
import {DSModel, DSRestCollection, DSRestQueryset} from "@solidev/ngdataservice";
import {takeUntil} from "rxjs/operators";
import {Resto, RestoService} from "../../../structure/resto/resto.service";
import {BaseProduct, ProductStoragesDetails} from "../product-base.service";
import {BaseProducer} from "../../../structure/_producer/producer-base.model";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {RoutesService} from "../../../../comps/routes.service";
import {filter, values} from "lodash-es";
import {DistanceService, IDistanceCacheItem} from "../../../structure/location/distance.service";
import {CustomDataKey, CustomDataKeyService} from "../../../customdata/customdatakey/customdatakey.service";
import {Client} from "../../../structure/client/client.service";

export interface ISelectedAddress {
  name: string;
  lat: number;
  lng: number;
}


@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseProductListComponent<P extends BaseProduct, PS extends DSRestCollection<P>,
  PF extends DSModel, PFS extends DSRestCollection<PF>,
  PR extends BaseProducer, PRS extends DSRestCollection<PR>,
  LS extends DSRestCollection<DSModel>>
  extends ModelListBaseComponent<P, PS>
  implements OnInit, OnDestroy {

  // Querysets
  public producers$!: DSRestQueryset<PR>;
  public storages$!: DSRestQueryset<Storage>;
  public members$!: DSRestQueryset<Member>;
  public regions$!: DSRestQueryset<GeoRegion>;
  public communes$!: DSRestQueryset<GeoCommune>;
  public departements$!: DSRestQueryset<GeoDepartement>;
  public families1$!: DSRestQueryset<PF>;
  public families2$!: DSRestQueryset<PF>;
  public restos$!: DSRestQueryset<Resto>;
  public seasons = SEASONS;
  public labels!: IBsacApiConstants;

  // Edition
  public editName: { [index: number]: boolean } = {};
  public editFamily: { [index: number]: boolean } = {};
  public deleting!: number;

  // Navigation
  @Input() public storage!: Storage;
  @Input() public producer!: PR;
  @Input() public member!: Member;
  @Input() public client!: Client;
  @Input() public resto!: Resto;

  // Custom selections & display
  public selectedStorages: number[] = [];
  public storagesDisplayMode: "full" | "sel" = "sel";
  public selectedProducers: number[] = [];
  public selectedAddress!: ISelectedAddress;
  public showStorageDistance = true;
  @Input() public seasons_perline = 6;
  public cdatakeys!: CustomDataKey[];


  // Results
  public resultStorages: ProductStoragesDetails[] = [];
  public mapStorages: ProductStoragesDetails[]= [];
  public resultProducers: PR[] = [];
  public mapProducers: PR[] = [];
  public mapRestos: Resto[] = [];
  public resultsDistances: {
    [index: number]: {
      desc: string,
      ref: number,
      type: string,
      dist1: IDistanceCacheItem,
      dist2?: IDistanceCacheItem
    }[]
  };

  public EGALIM_STATUSES = [
    {desc: "Tout état", value: ""},
    {desc: "Egalim", value: "egalim"},
    {desc: "Egalim + Eq.Egalim", value: "egalimplus"},
    {desc: "Eq.Egalim seulement", value: "onlyplus"},
    {desc: "Ni Egalim ni eq. egalim", value: "noegalim"}
  ]

  constructor(
    _list: BsacModelListProvider,
    _items: PS,
    _msgs: BsacMessageService,
    routes: RoutesService,
    protected _producers: PRS,
    protected _members: MemberService,
    protected _storages: StorageService,
    protected _families: PFS,
    protected _regions: GeoRegionService,
    protected _departements: GeoDepartementService,
    protected _labels: LS,
    protected _communes: GeoCommuneService,
    protected _restos: RestoService,
    protected _distances: DistanceService,
    protected _customdatakeys: CustomDataKeyService
  ) {
    super(_list, _items, _msgs, routes);
    this.type = "products";
    this.custom_fields = [
      {name: "select", verbose_name: "Sélection"},
      {name: "actions", verbose_name: "Actions"},
      {name: "producerlocation_details", verbose_name: "Localisation producteur"},
      {name: "producer_name", verbose_name: "Nom du producteur"},
      {name: "producer_postcode", verbose_name: "CP producteur"},
      {name: "producer_city", verbose_name: "Ville producteur"},
      {name: "producer_dptcode", verbose_name: "N° dpt producteur"},
      {name: "producer_dptname", verbose_name: "Nom département producteur"},
      {name: "producer_distance", verbose_name: "Distance dépôt-producteur"},
      {name: "direct_distance", verbose_name: "Distances"}
    ];
  }

  public async preNgOnInit(): Promise<void> {
    this.producers$ = this._producers.queryset;
    this.members$ = this._members.queryset;
    this.storages$ = this._storages.queryset;
    this.restos$ = this._restos.queryset;
    this.families1$ = this._families.queryset;
    this.families2$ = this._families.queryset;
    this.regions$ = this._regions.queryset;
    this.communes$ = this._communes.queryset;
    this.departements$ = this._departements.queryset;
    this.cdatakeys = await this._customdatakeys.getByDest(this.zone === "sea" ? "PRM,PFM" : "PRF,PFF").toPromise();
    for (const ck of this.cdatakeys) {
      this.custom_fields.push({name: `customdata_${ck.id}`, verbose_name: ck.name});
    }
    this._labels.queryset
      .all()
      .pipe(takeUntil(this._subscriptions$))
      .subscribe(results => {
        this.labels = [];
        for (const l of results.items) {
          this.labels.push({value: l._pk as number, desc: (l as any).name});
        }
      });
    if (this.resto) {
      this.selectedAddress = {
        name: this.resto.name,
        lat: this.resto.mainlocation_details.latitude,
        lng: this.resto.mainlocation_details.longitude
      };
      this.mapRestos = [this.resto];
    }
  }


  // -----------------------------------------------------------------------------------------------------------

  public async postNgOnInit(): Promise<void> {
    // Get producers & storages from results
    this.list.results.pipe(takeUntil(this._subscriptions$))
      .subscribe((results) => {
        this.resultProducers = [];
        const rp = {};
        this.resultStorages = [];
        const rs = {};
        this.updateDistances(rp, rs);
        this.resultProducers = values(rp);
        this.updateMapProducers();
        this.resultStorages = values(rs);
        this.updateMapStorages();
      });

    // Adapt result based on filters
    this.list.filter.output$.pipe(takeUntil(this._subscriptions$)).subscribe((filters) => {
      // Get current selected storages
      if (filters.storages) {
        this.selectedStorages = this.list.filter.get("storages").value as any;
      } else {
        this.selectedStorages = [];
      }
      this.updateMapStorages();
      if (filters.producers) {
        this.selectedProducers = this.list.filter.get("producers").value as any;
      } else {
        this.selectedProducers = [];
      }
      this.updateMapProducers();
      // add reference address
      if (filters.producernear) {
        const f = this.list.filter.get("producernear");
        this.selectedAddress = {
          name: (f.desc as any).location,
          lng: +(f.value as string).split("|")[2],
          lat: +(f.value as string).split("|")[3],

        };
      } else if (this.resto) {
        this.selectedAddress = {
          name: this.resto.name,
          lat: this.resto.mainlocation_details.latitude,
          lng: this.resto.mainlocation_details.longitude
        };
      } else {
        this.selectedAddress = undefined;
      }

    });
  }

  // INLINE EDITION
  public saveName(product: P): void {
    product.update(["name"]).subscribe(() => {
      this.editName[product._pk as number] = false;
      this._msgs.success("Nom du produit modifié avec succès");
    });
  }

  public changeFamily(product: P, family: PF): void {
    (product as any).family = family._pk;
    product.update(["family"]).subscribe(() => {
      product.refresh({query: this.filter}).subscribe(() => {
        this.editFamily[product._pk as number] = false;
        this._msgs.success("Famille modifiée avec succès");
      });
    });
  }

  public changeStatus(product: P, ploc: number, status: string): void {
    product.action("set_storage_status", {method: "POST", body: {pk: ploc, status: status}}).subscribe(() => {
      product.refresh({query: this.filter}).subscribe(() => {
        this._msgs.success("Etat du dépôt modifié avec succès");
      });
    });
  }

  public updateSeasons(product: P, seasons: number[]): void {
    (product as any).seasons = seasons;
    product.update(["seasons"]).subscribe(() => {
      product.refresh({query: this.filter}).subscribe(() => {
        this._msgs.success("Saisonnalité modifiée avec succès");
      });
    });
  }

  // -----------------------------------------------------------------------------------------------------------

  public onDeleted(product: P): void {
    this.deleting = product._pk as number;
    // SEE: this is a hack to avoid some strange race condition (api returns deleted object in list..)
    setTimeout(() => {
      this.list.get({query: this.filter}, true);
    }, 1000);
  }

  // DISPLAY OPTIONS
  public displayStorage(id: number): boolean {
    if (this.storagesDisplayMode === "sel") {
      if (this.storage) {
        return this.storage.id === id;
      }
      if (this.selectedStorages.length === 0) {
        return true;
      }
      return this.selectedStorages.indexOf(id) !== -1;
    }
    return true;
  }

  public updateMapStorages(): void {
    if (this.selectedStorages.length === 0) {
      if (this.storage) {
        // Current storage is defined
        if (this.storage.mainlocation_details) {
          // Location is available ? use it
          this.mapStorages = [this.storage as any];
        } else {
          // No location available ? take it from results
          this.mapStorages = filter(this.resultStorages, (r) => {
            return r.id === this.storage.id;
          });
        }
      } else {
        this.mapStorages = this.resultStorages;
      }
    } else {
      this.mapStorages = filter(this.resultStorages, (r) => {
        return this.selectedStorages.indexOf(r.id) !== -1;
      });
    }
  }

  public updateMapProducers(): void {
    if (this.selectedProducers.length === 0) {
      this.mapProducers = this.resultProducers;
    } else {
      this.mapProducers = filter(this.resultProducers, (r) => {
        return (this.selectedProducers.indexOf(r.id) !== -1) || (this.selectedProducers.indexOf(r.id) !== -1);
      });
    }
  }

  public updateDistances(rp: any, rs: any): void {
    this.resultsDistances = {};
    for (const r of this.results) {
      if (r.producer_details) {
        rp[r.producer_details.id] = r.producer_details;
        // Computed distances management
        if (this.selectedAddress) {
          // We have a reference address
          const d = this._distances.addDistance(this.selectedAddress.lat, this.selectedAddress.lng,
            r.producer_details.mainlocation_details.latitude, r.producer_details.mainlocation_details.longitude);
          this.resultsDistances[r.id] = [{desc: "Producteur", ref: 0, type: "PA", dist1: d}];
        }
      }
      if (r.storages_details) {
        for (const ps of r.storages_details) {
          rs[ps.id] = ps;
          if (this.selectedAddress) {
            const d = this._distances.addDistance(this.selectedAddress.lat, this.selectedAddress.lng,
              ps.mainlocation_details.latitude, ps.mainlocation_details.longitude);
            if (!this.resultsDistances[r.id]) {
              this.resultsDistances[r.id] = [];
            }
            this.resultsDistances[r.id].push({desc: ps.name, ref: ps.id, type: "SA", dist1: d});
          }
        }
      }

    }
  }

  // -----------------------------------------------------------------------------------------------------------

  public changePerlineSeasons(): void {
    const available = [2, 3, 4, 6, 12];
    const cur = available.indexOf(this.seasons_perline);
    this.seasons_perline = available[(cur + 1) % available.length];
  }


  // -----------------------------------------------------------------------------------------------------------
  // LIST HELPERS

  public onAction(action: any): void {
    action.storage = this.storage;
    action.resto = this.resto;
    action.selectedStorages = this.selectedStorages;
    this.action.emit(action);
  }

}
