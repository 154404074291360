
<ng-container *ngIf="namesReady">
  <div class="d-inline-block" ngbDropdown>
    <div ngbDropdownToggle id="statstoggle">
      <ng-content></ng-content>
    </div>
    <div ngbDropdownMenu>
      <button class="dropdown-item" *ngFor="let cstt of available" (click)="click(cstt)"><span class="selected" *ngIf="isSelected(cstt)"><i class="fa fa-check-square me-2"></i><small class="me-2">{{cstt}}</small><b>{{cstt|bsacApiConstantSingle:'STATS_NAMES'}}</b></span><span class="notselected" *ngIf="!isSelected(cstt)"><i class="fa fa-plus-square me-2"></i><small class="me-2">{{cstt}}</small><span>{{cstt|bsacApiConstantSingle:'STATS_NAMES'}}</span></span></button>
    </div>
  </div>
</ng-container>