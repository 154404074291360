import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router, RouterState} from "@angular/router";
import {filter, takeUntil} from "rxjs/operators";
import {template} from "lodash-es";
import {Subject} from "rxjs";

export interface IPathTreeItem {
  path: string;
  title: string;
  icon: string;
  current: boolean;
}

@Component({
  selector: "lvadg-dynamic-breadcrumb",
  templateUrl: "./dynamic-breadcrumb.component.pug",
  styleUrls: ["./dynamic-breadcrumb.component.sass"]
})
export class DynamicBreadcrumbComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Additional data to be used in titleTemplate
   */
  @Input() public data!: any;
  /**
   * Do we display icons ?
   */
  @Input() public icons = true;
  public tree: IPathTreeItem[] = [];
  private _subscriptions = new Subject<void>();

  constructor(public router: Router,
              public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.getRouteTree(this.router.routerState);
    this.router.events
      .pipe(
        takeUntil(this._subscriptions),
        filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.getRouteTree(this.router.routerState);
      });

  }

  public ngOnDestroy() {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.getRouteTree(this.router.routerState);
  }

  public getRouteTree(current: RouterState): void {
    const tree: IPathTreeItem[] = [];
    const url: string[] = [];
    let p = current.root.snapshot;

    while (p != null) {
      const rtd: IPathTreeItem = {icon: "", title: "", path: "", current: false};
      if (p.data.icon) {
        rtd.icon = p.data.icon;
      }
      // Retrieve path data from route config
      if (p.routeConfig && p.routeConfig.data) {
        // There is a route config
        if (p.routeConfig.data.title) {
          if (p.routeConfig.data.title.search("<%=") !== -1) {
            const tpl = template(p.routeConfig.data.title);
            try {
              rtd.title = tpl({route: p, data: this.data});
            } catch (e) {
              console.warn("Unable to process bsac-breadcrumb title", e);
              rtd.title = p.data.title;
            }
          } else {
            rtd.title = p.routeConfig.data.title;
          }
        }
      } else {
        // No route config
        if (p.parent !== null) {
          console.warn("Missing route config for", p);
        }
        rtd.title = p.url.join(" ");
        rtd.icon = "";
      }
      // Url management, add all paths if existing
      if (p.url.length > 0) {
        url.push(p.url.join("/"));
        rtd.path = url.join("/");
        // fix missing first slash
        if (rtd.path.length > 0 && rtd.path[0] !== "/") {
          rtd.path = "/" + rtd.path;
        }
      }

      // Don't add root route / unconfigured routes / skipped routes
      if (!((p.routeConfig && p.routeConfig.data && p.routeConfig.data.skip) || p.parent === null || !p.routeConfig)) {
        tree.push(rtd);
      }
      p = p.firstChild;
    }
    if (tree.length > 0) {
      tree[tree.length - 1].current = true;
    }
    this.tree = tree;
  }

}
