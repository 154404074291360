import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ExternalArticle, ExternalArticleService} from "./externalarticle.service";

@Injectable({providedIn: "root"})
export class ExternalArticleResolve implements Resolve<ExternalArticle> {
  constructor(private _bs: ExternalArticleService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ExternalArticle> {
    return this._bs.get(+route.params["externalArticleId"]);
  }
}
