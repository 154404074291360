import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import {CdkTableModule} from "@angular/cdk/table";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {CompsModule} from "../../../comps/comps.module";
import {ClientSelectModule} from "../../structure/client/client-select.module";

import {ProductVariantListComponent} from "./productvariant-list/productvariant-list.component";

import {ProductVariantCreateComponent} from "./productvariant-create/productvariant-create.component";

import {ProductVariantManageComponent} from "./productvariant-manage/productvariant-manage.component";

import {ProductVariantDeleteComponent} from "./productvariant-delete/productvariant-delete.component";

import {ProductVariantSelectModule} from "./productvariant-select.module";
import {ProductVariantImportComponent} from "./productvariant-import/productvariant-import.component";

@NgModule({
  imports: [
    CommonModule,
    ProductVariantSelectModule,
    CdkTableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,

    CompsModule,
    ClientSelectModule
  ],
  declarations: [
    ProductVariantListComponent,
    ProductVariantCreateComponent,
    ProductVariantManageComponent,
    ProductVariantDeleteComponent,
    ProductVariantImportComponent
  ],
  exports: [
    ProductVariantSelectModule,

    ProductVariantListComponent,
    ProductVariantCreateComponent,
    ProductVariantManageComponent,
    ProductVariantDeleteComponent,
    ProductVariantImportComponent
  ]
})
export class ProductVariantModule {
}
