<!DOCTYPE html>
<ng-template #listCurrentFilters>
  <ng-content select=".list-current-filters"></ng-content>
</ng-template>
<ng-template #listSelectFilters>
  <ng-content select=".list-select-filters"></ng-content>
</ng-template>
<ng-template #listSelectColumns>
  <ng-content select=".list-select-columns"></ng-content>
</ng-template>
<ng-template #listPersistence>
  <ng-content select=".list-persistence"></ng-content>
</ng-template>
<ng-template #listActions>
  <ng-content select=".list-actions"></ng-content>
</ng-template>
<ng-template #listResults>
  <ng-content select=".list-results"></ng-content>
</ng-template>
<ng-template #listResultsSide>
  <ng-content select=".list-results-side"></ng-content>
</ng-template>
<ng-template #listPagination>
  <ng-content select=".list-pagination"></ng-content>
</ng-template>
<div class="row modellist-vertical" *ngIf="mode=='vertical'">
  <div class="col-3 left-filters d-print-none">
    <ng-container *ngTemplateOutlet="listCurrentFilters"></ng-container>
    <ng-container *ngTemplateOutlet="listSelectFilters"></ng-container>
  </div>
  <div class="col-9">
    <div class="row">
      <div class="col-sm-4 d-print-none">
        <ng-container *ngTemplateOutlet="listSelectColumns"></ng-container>
      </div>
      <div class="col-sm-4 d-print-none">
        <ng-container *ngTemplateOutlet="listPersistence"></ng-container>
      </div>
      <div class="col-sm-4 d-print-none">
        <ng-container *ngTemplateOutlet="listActions"></ng-container>
      </div>
      <div class="col-12">
        <ng-container *ngTemplateOutlet="listResults"></ng-container>
      </div>
      <div class="col-12 d-print-none">
        <ng-container *ngTemplateOutlet="listPagination"></ng-container>
      </div>
    </div>
  </div>
</div>
<div class="row modellist-vertical" *ngIf="mode=='vertical2res'">
  <div class="col-2 left-filters d-print-none">
    <ng-container *ngTemplateOutlet="listCurrentFilters"></ng-container>
    <ng-container *ngTemplateOutlet="listSelectFilters"></ng-container>
  </div>
  <div class="col-10">
    <div class="row">
      <div class="col-sm-4">
        <ng-container *ngTemplateOutlet="listSelectColumns"></ng-container>
      </div>
      <div class="col-sm-4">
        <ng-container *ngTemplateOutlet="listPersistence"></ng-container>
      </div>
      <div class="col-sm-4">
        <ng-container *ngTemplateOutlet="listActions"></ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <ng-container *ngTemplateOutlet="listResults"></ng-container>
        <ng-container *ngTemplateOutlet="listPagination"></ng-container>
      </div>
      <div class="col-6">
        <ng-container *ngTemplateOutlet="listResultsSide"></ng-container>
      </div>
    </div>
  </div>
</div>
<div class="row modellist-horizontal" *ngIf="mode=='horizontal'">
  <div class="col-12 d-print-none">
    <ng-container *ngTemplateOutlet="listCurrentFilters"></ng-container>
  </div>
  <div class="col-12 d-print-none">
    <div class="row filter-management">
      <div class="col-3">
        <ng-container *ngTemplateOutlet="listSelectFilters"></ng-container>
      </div>
      <div class="col-3">
        <ng-container *ngTemplateOutlet="listSelectColumns"></ng-container>
      </div>
      <div class="col-3">
        <ng-container *ngTemplateOutlet="listPersistence"></ng-container>
      </div>
      <div class="col-3">
        <ng-container *ngTemplateOutlet="listActions"></ng-container>
      </div>
    </div>
  </div>
  <div class="col-12">
    <ng-container *ngTemplateOutlet="listResults"></ng-container>
  </div>
  <div class="col-12 d-print-none">
    <ng-container *ngTemplateOutlet="listPagination"></ng-container>
  </div>
</div>
<div class="row modellist-horizontal" *ngIf="mode=='horizontal2res'">
  <div class="col-12 d-print-none">
    <ng-container *ngTemplateOutlet="listCurrentFilters"></ng-container>
  </div>
  <div class="col-12 d-print-none">
    <div class="row filter-management">
      <div class="col-3">
        <ng-container *ngTemplateOutlet="listSelectFilters"></ng-container>
      </div>
      <div class="col-3">
        <ng-container *ngTemplateOutlet="listSelectColumns"></ng-container>
      </div>
      <div class="col-3">
        <ng-container *ngTemplateOutlet="listPersistence"></ng-container>
      </div>
      <div class="col-3">
        <ng-container *ngTemplateOutlet="listActions"></ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <ng-container *ngTemplateOutlet="listResults"></ng-container>
      <ng-container *ngTemplateOutlet="listPagination"></ng-container>
    </div>
    <div class="col-6">
      <ng-container *ngTemplateOutlet="listResultsSide"></ng-container>
    </div>
  </div>
</div>