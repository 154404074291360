import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {cloneDeep} from "lodash-es";
import {DocumentService} from "../../document/document/document.service";
import {Router} from "@angular/router";

export class News extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "title",
    verboseName: "Titre",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 255
  })
  public title!: string;

  @DSCharField({
    name: "zone",
    verboseName: "Zone",
    required: true,
    helpText: "",
    choices: [["MR", "Marée"], ["FL", "Fruits & légumes"], ["FLMR", "Fruits & légumes & marée"]]
  })
  public zone!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: true,
    helpText: "",
    choices: [["INT", "Lien interne"], ["EXT", "Lien externe"]]
  })
  public type!: string;

  @DSIntegerField({
    name: "order",
    verboseName: "Ordre affichage",
    required: false,
    helpText: "",
    choices: []
  })
  public order!: number;

  @DSCharField({
    name: "url",
    verboseName: "URL lien",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 255
  })
  public url!: string;

  @DSForeignKeyField({
    name: "document",
    verboseName: "Document lié",
    required: false,
    helpText: "",
    choices: []
  })
  public document!: number;

  @DSForeignKeyField({
    name: "folder",
    verboseName: "Dossier lié",
    required: false,
    helpText: "",
    choices: []
  })
  public folder!: number;

  @DSDefaultField({
    name: "document_details",
    verboseName: "Document lié (détails)",
    required: false,
    helpText: "",
    choices: []
  })
  public document_details!: { id: number; title: string; slug: string; file: string, image_details: any; };

  @DSDefaultField({
    name: "folder_details",
    verboseName: "Dossier lié (détails)",
    required: false,
    helpText: "",
    choices: []
  })
  public folder_details!: { id: number; title: string; slug: string, logo_img: any };

  @DSTextField({
    name: "content_src",
    verboseName: "Contenu",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 2000
  })
  public content_src!: string;
  public content: string; // Compiled content
  @DSDefaultField({
    name: "dest",
    verboseName: "Destination",
    required: false,
    helpText: "",
    choices: []
  })
  public dest!: string[];

  @DSDefaultField({
    name: "tags",
    verboseName: "Tags",
    required: false,
    helpText: "",
    choices: []
  })
  public tags!: string[];

  @DSForeignKeyField({
    name: "logo",
    verboseName: "Logo",
    required: false,
    helpText: "",
    choices: []
  })
  public logo!: number;

  @DSCharField({
    name: "icon",
    verboseName: "Icône",
    required: false,
    helpText: "",
    choices: [
      ["", "Aucune"],
      ["fab fa-facebook", "Facebook"],
      ["fab fa-twitter", "Twitter"],
      ["fab fa-instagram", "Instagram"],
      ["fab fa-youtube", "Youtube"],
      ["fa fa-exclamation-triangle", "Warning"],
      ["fa fa-smile", "Smile"],
      ["fa fa-smile-wink", "Smile wink"],
      ["fa fa-grin", "Grin"],
      ["fa fa-heart", "Heart"],
      ["fa fa-grin-hearts", "Grin hearts"],
      ["fa fa-tv-retro", "Television"],
      ["fa fa-newspaper", "Newspaper"],
      ["fa fa-folder", "Folder"],
      ["fa fa-thumbs-up", "Thumbs up"]

    ]
  })
  public icon!: string;

  @DSDefaultField({
    name: "logo_img",
    verboseName: "Détails logo",
    required: false,
    helpText: "",
    choices: []
  })
  public logo_img!: any;

  @DSDateTimeField({
    name: "datestart",
    verboseName: "Début de publication",
    required: false,
    helpText: "",
    choices: []
  })
  public datestart!: Date;

  @DSDateTimeField({
    name: "dateend",
    verboseName: "Fin de publication",
    required: false,
    helpText: "",
    choices: []
  })
  public dateend!: Date;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: true,
    helpText: "",
    choices: [["OK", "OK"], ["KO", "Pas OK"]],
    maxLength: 4
  })
  public status!: string;

  public get displayLogo(): any {
    if (this.logo_img) {
      return this.logo_img;
    }
    if (this.document && this.document_details.image_details) {
      return this.document_details.image_details;
    }
    if (this.folder && this.folder_details.logo_img) {
      return this.folder_details.logo_img;
    }
    return null;
  }

  public linkClick(base: string[]): void {
    if (this.url) {
      const newTab = window.open();
      newTab.location.href = this.url;
    } else if (this.document) {
      const newTab = window.open();
      (this._collection as NewsService)._docs.create(this.document_details).subscribe((doc) => {
        doc.download(newTab);
      });
    } else if (this.folder) {
      const out = cloneDeep(base);
      out.push("documents");
      out.push(this.folder_details.slug + "-" + this.folder.toString());
      (this._collection as NewsService)._router.navigate(out);
    }
  }
}


@Injectable({
  providedIn: "root"
})
export class NewsService extends DSRestCollection<News> {
  public adapter_config = {basePath: "/api/v2/news"};
  public model = News;

  constructor(public setup: DSRestCollectionSetupProvider,
              public _docs: DocumentService,
              public _router: Router) {
    super(setup);
  }
}
