import {Component} from "@angular/core";
import {ArticleAllergene, ArticleAllergeneService} from "../article-allergene.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";

@Component({
  selector: "lvadg-article-allergenes-manage",
  templateUrl: "./article-allergenes-manage.component.pug",
  styleUrls: ["./article-allergenes-manage.component.sass"]
})
export class ArticleAllergenesManageComponent extends ArticleDataManageBaseComponent<ArticleAllergene, ArticleAllergeneService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticleAllergeneService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "allergeneId";
    this.filterField = "allergenes";
  }

}
