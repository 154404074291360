import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {of} from "rxjs";
import {Provider} from "../../../../../lib/models/structure/provider/provider.service";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../../lib/comps/routes.service";
import {Location, LocationService} from "../../../../../lib/models/structure/location/location.service";
import {NavDriver, TabMemoryService} from "../../../../../lib/comps/tab-memory.service";
import {CommonBaseComponent} from "../../../common-base.component";
import {Article} from "../../../../../lib/models/catalog/article/article.service";

export interface ProviderManageViewV3Params extends BaseRouteV3Params {
  article_detail_route: RouteConfigItemV3;
  provider_detail_route: RouteConfigItemV3;
}

export interface ProviderManageViewV3Data extends ProviderManageViewV3Params {
  provider: Provider;
}

@Component({
  selector: "lvadg-provider-manage-view",
  templateUrl: "./provider-manage-view.component.pug",
  styleUrls: ["./provider-manage-view.component.sass"]
})
export class ProviderManageViewV3Component extends CommonBaseComponent {
  public provider!: Provider;
  public remove = false;
  public location!: Location;
  public addStorage = false;
  public reload = of(new Date());
  public navId!: NavDriver;
  public article_detail_route!: RouteV3<Article>;
  public provider_detail_route!: RouteV3<Provider>;

  public documents_default_fields = ["id", "prlabel", "start", "end", "title", "filename",
    "filesize", "file", "status", "image_img"];
  public documents_default_filters = ["search", "statuses", "labels"];


  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute,
              private _locations: LocationService,
              private tms: TabMemoryService) {
    super(routes, router, route);
  }

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as ProviderManageViewV3Data;
    this.provider = data.provider || this.provider;
    this.provider_detail_route = new RouteV3<Provider>(data.provider_detail_route, this);
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    this.navId = this.tms.memFor(["ttypeview", this.provider.id], "manage", false);
    if (this.provider && this.provider.locations) {
      this._locations.get(this.provider.locations[0]).subscribe((result) => {
        this.location = result;
      });
    } else {
      console.warn("No location");
    }
  }

  public removed(): void {
    // FIXME: move up one level
    console.log("TODO");
  }

}
