import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MeteoSummaryComponent} from "./meteo-summary/meteo-summary.component";
import {MeteoMapComponent} from "./meteo-map/meteo-map.component";
import {MeteoFullmapComponent} from "./meteo-fullmap/meteo-fullmap.component";


@NgModule({
  declarations: [
    MeteoSummaryComponent,
    MeteoMapComponent,
    MeteoFullmapComponent
  ],
  exports: [
    MeteoSummaryComponent,
    MeteoMapComponent,
    MeteoFullmapComponent
  ],
  imports: [
    CommonModule
  ]
})
export class MeteoModule {
}
