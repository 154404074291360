import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {ProductPrice, ProductPriceService} from "./productprice.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProductPriceResolve implements Resolve<ProductPrice> {
  constructor(private _bs: ProductPriceService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<ProductPrice> {
    return this._bs.get(+route.params["productpriceId"]);
  }
}
