import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ArticleTarifTemplate, ArticleTarifTemplateService} from "./articletariftemplate.service";

@Injectable({providedIn: "root"})
export class ArticleTarifTemplateResolve implements Resolve<ArticleTarifTemplate> {
  constructor(private _bs: ArticleTarifTemplateService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ArticleTarifTemplate> {
    return this._bs.get(+route.params["attId"]);
  }
}
