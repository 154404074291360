import {Component, Input, OnChanges} from "@angular/core";
import {TimeSeriesChartDataset, TimeSeriesStat} from "../../../../../comps/charts/formats";


@Component({
  selector: "lvadg-articletarif-stats-timeseries",
  templateUrl: "./articletarif-stats-timeseries.component.pug",
  styleUrls: ["./articletarif-stats-timeseries.component.sass"]
})
export class ArticletarifStatsTimeseriesComponent implements OnChanges {
  @Input() public stat!: TimeSeriesStat;
  public stats!: TimeSeriesChartDataset;

  constructor() {
  }

  ngOnChanges(): void {
    console.log(this.stat);
    this.stats = {
      title: "",
      subtitle: "",
      legend: this.stat.series.length > 1,
      stacked: false,
      axis: {
        time: {title: "Date", unit: ""},
        value: {title: this.stat.label, unit: this.stat.unit}
      },
      series: []
    };
    for (const s of this.stat.series) {
      const ser = {
        type: "line",
        name: s.name,
        data: [] as [Date, number][],
        stack: "vals",
        areaStyle: {}
      };
      for (const v of s.values) {
        const [year, week] = v.name.split("/");
        ser.data.push([new Date(+year, 0, +week * 7), v.value]);
      }
      (this.stats.series as any[]).push(ser);
    }
  }


}
