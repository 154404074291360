<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'type'; desc: 'Recherche par type'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="type" choices="PROVIDER_TYPES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'refgammes'; desc: 'Recherche par gammes référencées'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="refgammes" [choices]="refgammes"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'status'; desc:' Recherche par état'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="status" choices="PROVIDER_STATUSES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'have_producers'; desc:'Recherche avec producteurs associés ?'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="have_producers" [choices]="[{desc:'Oui', value: 'yes'},{desc:'Non', value: 'no'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'have_articles'; desc:'Recherche avec articles associés ?'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="have_articles" [choices]="[{desc:'Oui', value: 'yes'},{desc:'Non', value: 'no'}]"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'families'; desc:'Recherche par famille articles associés'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="families" [queryset]="families$" descfield="cfullname" hint="Sélectionnez une famille" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'flags'; desc: 'Recherche par flags'; group: 'general'">
        <bsac-model-list-flags-filter [list]="list" field="flags" [flags]="flags"></bsac-model-list-flags-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'slabels'; desc: 'Recherche par label structure'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="slabels" [choices]="slabels"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <button class="btn btn-link" (click)="resultsMode='map'" *ngIf="resultsMode!='map'">Afficher sur une carte</button>
    <button class="btn btn-link" (click)="resultsMode='list'" *ngIf="resultsMode!='list'">Afficher la liste</button>
    <button class="btn btn-link" (click)="xlsxExport()" *ngIf="checkMode('admin')"><i class="fa fa-file-excel mx-2 px-2"></i></button>
  </div>
  <div class="list-results">
    <ng-container *ngIf="resultsMode=='map'">
      <ng-container *ngIf="list.connect(true)|async; let lc">
        <lvadg-locations-osm-display [providers]="lc" height="700px" mapStyle="osmlibertyeu" boundary="auto"></lvadg-locations-osm-display>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>Nom</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="getDetailLink(row)"><b>{{row.name}}</b></a></td>
          </ng-container>
          <ng-container cdkColumnDef="cvva">
            <th cdk-header-cell *cdkHeaderCellDef>Code Vivalya</th>
            <td cdk-cell *cdkCellDef="let row">{{row.cvva}}</td>
          </ng-container>
          <ng-container cdkColumnDef="mainaddress">
            <th cdk-header-cell *cdkHeaderCellDef>Adresse complète</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainaddress}}</td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type|choice:row:'type'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content_type">
            <th cdk-header-cell *cdkHeaderCellDef>Format desc.</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_type}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content_src">
            <th cdk-header-cell *cdkHeaderCellDef>Description (source)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_src}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content">
            <th cdk-header-cell *cdkHeaderCellDef>Description (html)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content}}</td>
          </ng-container>
          <ng-container cdkColumnDef="phone">
            <th cdk-header-cell *cdkHeaderCellDef>Téléphone</th>
            <td cdk-cell *cdkCellDef="let row">{{row.phone}}</td>
          </ng-container>
          <ng-container cdkColumnDef="fax">
            <th cdk-header-cell *cdkHeaderCellDef>Fax</th>
            <td cdk-cell *cdkCellDef="let row">{{row.fax}}</td>
          </ng-container>
          <ng-container cdkColumnDef="website">
            <th cdk-header-cell *cdkHeaderCellDef>Site web</th>
            <td cdk-cell *cdkCellDef="let row">{{row.website}}</td>
          </ng-container>
          <ng-container cdkColumnDef="open_date">
            <th cdk-header-cell *cdkHeaderCellDef>Date début référencement</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.open_date">{{row.open_date|date:'shortDate'}}</ng-container>
              <ng-container *ngIf="!row.open_date">-</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="close_date">
            <th cdk-header-cell *cdkHeaderCellDef>Date fin référencement</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.close_date">{{row.close_date|date:'shortDate'}}</ng-container>
              <ng-container *ngIf="!row.close_date">-</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="ref_1g">
            <th cdk-header-cell *cdkHeaderCellDef>Référencé 1G</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check text-success" *ngIf="row.ref_1g"></i><i class="fa fa-times text-danger" *ngIf="!row.ref_1g"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="ref_4g">
            <th cdk-header-cell *cdkHeaderCellDef>Référencé 4G</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check text-success" *ngIf="row.ref_4g"></i><i class="fa fa-times text-danger" *ngIf="!row.ref_4g"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="ref_5g">
            <th cdk-header-cell *cdkHeaderCellDef>Référencé 5G</th>
            <td cdk-cell *cdkCellDef="let row"><i class="fa fa-check text-success" *ngIf="row.ref_5g"></i><i class="fa fa-times text-danger" *ngIf="!row.ref_5g"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="producers_details">
            <th cdk-header-cell *cdkHeaderCellDef>Producteurs associés</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let p of row.producers_details"><a [routerLink]="getProducerRoute(p)"><i class="fa fa-link me-2"></i><span class="ms-2" *ngIf="checkMode('admin')">[{{p.id}}]</span><span>{{p.name}}</span></a></div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="article_count">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="article_count">Nombre d'articles</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.article_count}}</td>
          </ng-container>
          <ng-container cdkColumnDef="siren">
            <th cdk-header-cell *cdkHeaderCellDef>SIREN</th>
            <td cdk-cell *cdkCellDef="let row">{{row.siren}}</td>
          </ng-container>
          <ng-container cdkColumnDef="siret">
            <th cdk-header-cell *cdkHeaderCellDef>SIRET</th>
            <td cdk-cell *cdkCellDef="let row">{{row.siret}}</td>
          </ng-container>
          <ng-container cdkColumnDef="tvaic">
            <th cdk-header-cell *cdkHeaderCellDef>N°TVA</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tvaic}}</td>
          </ng-container>
          <ng-container cdkColumnDef="naf">
            <th cdk-header-cell *cdkHeaderCellDef>Code NAF</th>
            <td cdk-cell *cdkCellDef="let row">{{row.naf}}</td>
          </ng-container>
          <ng-container cdkColumnDef="legal">
            <th cdk-header-cell *cdkHeaderCellDef>Structure légale</th>
            <td cdk-cell *cdkCellDef="let row">{{row.legal|choice:row:'legal'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="capital">
            <th cdk-header-cell *cdkHeaderCellDef>Capital</th>
            <td cdk-cell *cdkCellDef="let row">{{row.capital}}</td>
          </ng-container>
          <ng-container cdkColumnDef="logo">
            <th cdk-header-cell *cdkHeaderCellDef>Logo</th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-rimg [src]="row.logo_img" size="xsm" display="thumb32"></lvadg-rimg>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="slabels">
            <th cdk-header-cell *cdkHeaderCellDef>Labels structure</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-info mx-1" *ngFor="let l of row.slabels">{{l|slabelName}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="locations">
            <th cdk-header-cell *cdkHeaderCellDef>Emplacements</th>
            <td cdk-cell *cdkCellDef="let row">{{row.locations}}</td>
          </ng-container>
          <ng-container cdkColumnDef="images">
            <th cdk-header-cell *cdkHeaderCellDef>Images</th>
            <td cdk-cell *cdkCellDef="let row">{{row.images?.length}}</td>
          </ng-container>
          <ng-container cdkColumnDef="documents">
            <th cdk-header-cell *cdkHeaderCellDef>Documents</th>
            <td cdk-cell *cdkCellDef="let row">{{row.documents?.length}}</td>
          </ng-container>
          <ng-container cdkColumnDef="city">
            <th cdk-header-cell *cdkHeaderCellDef>Ville</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.city}}</td>
          </ng-container>
          <ng-container cdkColumnDef="postcode">
            <th cdk-header-cell *cdkHeaderCellDef>Code postal</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.postcode}}</td>
          </ng-container>
          <ng-container cdkColumnDef="country">
            <th cdk-header-cell *cdkHeaderCellDef>Pays</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.country_details?.name}}</td>
          </ng-container>
          <ng-container *ngFor="let ck of cdatakeys">
            <ng-container [cdkColumnDef]="'customdata_'+ck.id">
              <th cdk-header-cell *cdkHeaderCellDef>{{ck.name}}</th>
              <td cdk-cell *cdkCellDef="let row">
                <lvadg-customdata-rowfield-display [mode]="mode" [editors]="['admin', 'provideradmin']" [model]="row" [dest]="ck.dest" [code]="ck.code"></lvadg-customdata-rowfield-display>
              </td>
            </ng-container>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>