<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type}}</td>
          </ng-container>
          <ng-container cdkColumnDef="title">
            <th cdk-header-cell *cdkHeaderCellDef>Titre</th>
            <td cdk-cell *cdkCellDef="let row">{{row.title}}</td>
          </ng-container>
          <ng-container cdkColumnDef="text">
            <th cdk-header-cell *cdkHeaderCellDef>Texte</th>
            <td cdk-cell *cdkCellDef="let row">
              <div [innerHTML]="row.text"></div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="families">
            <th cdk-header-cell *cdkHeaderCellDef>Familles</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let f of row.families_details">{{f.cfullname}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="origins">
            <th cdk-header-cell *cdkHeaderCellDef>Origines</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let f of row.origins_details">{{f.code}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="allorigins">
            <th cdk-header-cell *cdkHeaderCellDef>Toute origine</th>
            <td cdk-cell *cdkCellDef="let row">{{row.allorigins}}</td>
          </ng-container>
          <ng-container cdkColumnDef="categories">
            <th cdk-header-cell *cdkHeaderCellDef>Catégories</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let f of row.categories_details">{{f.code}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="allcategories">
            <th cdk-header-cell *cdkHeaderCellDef>Toutes catégories</th>
            <td cdk-cell *cdkCellDef="let row">{{row.allcategories}}</td>
          </ng-container>
          <ng-container cdkColumnDef="egalims">
            <th cdk-header-cell *cdkHeaderCellDef>Labels & certifs</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let f of row.egalims_details">{{f.code}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="allegalims">
            <th cdk-header-cell *cdkHeaderCellDef>Tous labels/certifs</th>
            <td cdk-cell *cdkCellDef="let row">{{row.allegalims}}</td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>