import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {FolderTree} from "../../folder/folder-tree.service";
import {Document} from "../document.service";
import {Folder} from "../../folder/folder.service";

@Component({
  selector: "lvadg-document-properties",
  templateUrl: "./document-properties.component.pug",
  styleUrls: ["./document-properties.component.sass"]
})
export class DocumentPropertiesComponent implements OnInit, OnChanges {
  @Input() public document!: Document;
  @Input() public tree!: FolderTree;
  @Input() public mode = "admin";
  @Output() public closed = new EventEmitter<void>();
  public moveAction = false;
  public addFolderAction = false;
  public shareTtl = 3600 * 24 * 365 * 4;
  public shareLink!: { url: string };

  public genPreviewRunning = false;

  constructor() {
  }

  ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.shareLink = undefined;
  }

  public async delete(): Promise<void> {
    await this.document.delete().toPromise();
    await this.tree.setCurrent(this.tree.current);
    await this.tree.reload();
  }

  public async move(folder: Folder): Promise<void> {
    await this.document.action("move",
      {
        method: "POST",
        body: {
          dest: folder.id,
          orig: this.tree.current.id
        }
      }).toPromise();
    await this.tree.setCurrent(this.tree.current);
    await this.tree.reload();
  }

  public async add_folder(folder: Folder): Promise<void> {
    await this.document.action("add_folder",
      {
        method: "POST",
        body: {
          dest: folder.id,
        }
      }).toPromise();
    await this.tree.reload();
  }

  public async remove_folder(folder: Folder): Promise<void> {
    await this.document.action("remove_folder",
      {
        method: "POST",
        body: {
          orig: folder.id,
        }
      }).toPromise();
    if (folder.id === this.tree.current.id) {
      await this.tree.setCurrent(this.tree.current);
    }
    await this.tree.reload();
  }

  public async get_share_link(): Promise<void> {
    this.shareLink = await this.document.action("download_link", {
      method: "POST",
      body: {
        ttl: this.shareTtl
      }
    }).toPromise();
  }

  public async genPreview(): Promise<void> {
    this.genPreviewRunning = true;
    await this.document.action("gen_preview", {method: "POST", body: {}}).toPromise();
    await this.document.refresh().toPromise();
    this.genPreviewRunning = false;
  }

  public checkMode(...args: string[]): boolean {
    return args.indexOf(this.mode) !== -1;
  }
}
