import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel
} from "@solidev/ngdataservice";

export interface IFieldParameterChoice  {
  value: string;
  desc: string;
  current: string;
  default: string;
  action: string | any;
  help?: string;
  url?: string;
}

export interface IFieldParameter {
  name: string;
  type: string;
  label: string;
  help: string;
  value: string | string[] | number | boolean;
  choices: IFieldParameterChoice[];
  field: number | null;
  field_details: CommField | any;
}

export interface IFieldParameters {
  [index: string]: { [index: string]: IFieldParameter };
}

export class CommField extends DSModel {
  @DSAutoField({
    name: "id"
  })
  public id!: number;


  @DSForeignKeyField({
    name: "print",
    required: false,
    verboseName: "ID Model"
  })
  public model!: number;

  @DSForeignKeyField({
    name: "resto",
    required: false,
    verboseName: "ID Resto"
  })
  public resto!: number;

  @DSCharField({
    name: "part",
    required: true,
    verboseName: "Part name"
  })
  public part!: string;

  @DSCharField({
    name: "name",
    required: true,
    verboseName: "Field name"
  })
  public name!: string;

  @DSCharField({
    name: "value",
    required: true,
    verboseName: "Value"
  })
  public value!: string;

  @DSBooleanField({
    name: "bvalue",
    required: false,
    verboseName: "Boolean value"
  })
  public bvalue!: boolean;

  @DSIntegerField({
    name: "ivalue",
    required: false,
    verboseName: "Integer value"
  })
  public ivalue!: number;

  @DSCharField({
    name: "svalue",
    required: false,
    verboseName: "String value"
  })
  public svalue!: string;

  @DSCharField({
    name: "tvalue",
    required: false,
    verboseName: "Text value"
  })
  public tvalue!: string;


  @DSCharField({
    name: "fvalue",
    required: false,
    verboseName: "File value"
  })
  public fvalue!: string;


  @DSDefaultField({
    name: "savalue",
    required: false,
    verboseName: "Liste de chaînes"
  })
  public savalue!: string[];


  @DSDateTimeField({
    name: "created",
    required: false,
    verboseName: "Date de création"
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    required: false,
    verboseName: "Date de modification"
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    required: false,
    verboseName: "Etat",
    choices: [["OK", "Valide"], ["KO", "Non valide"]],
    default: "OK"
  })
  public status!: string;
}
