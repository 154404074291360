import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {TarifType, TarifTypeService} from "./tariftype.service";

@Injectable({providedIn: "root"})
export class TarifTypeResolve implements Resolve<TarifType> {
  constructor(private _bs: TarifTypeService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<TarifType> {
    return this._bs.get(+route.params["tariftypeId"]);
  }
}
