<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="hash">
            <th cdk-header-cell *cdkHeaderCellDef>Hash</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.hash|uppercase|shorten:8:''}}</code></td>
          </ng-container>
          <ng-container cdkColumnDef="bill">
            <th cdk-header-cell *cdkHeaderCellDef>Facture</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="bill_detail_route.route({bill: row.bill_details})">{{row.bill_details?.code}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="bill__storage">
            <th cdk-header-cell *cdkHeaderCellDef>Dépôt</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="storage_detail_route.route({storage: row.bill_details?.storage_details})" *ngIf="row.bill_details &amp;&amp; row.bill_details.storage_details">{{row.bill__storage}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="bill__resto">
            <th cdk-header-cell *cdkHeaderCellDef>Restaurant</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="resto_detail_route.route({resto: row.bill_details?.resto_details})" *ngIf="row.bill_details &amp;&amp; row.bill_details.resto_details">{{row.bill__resto}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="bill__billdate">
            <th cdk-header-cell *cdkHeaderCellDef>Date</th>
            <td cdk-cell *cdkCellDef="let row">{{row.bill__billdate}}</td>
          </ng-container>
          <ng-container cdkColumnDef="bill__client">
            <th cdk-header-cell *cdkHeaderCellDef>Client</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="client_detail_route.route({client: row.bill_details?.client_details})" *ngIf="row.bill_details &amp;&amp; row.bill_details.client_details">{{row.bill__client}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="weight">
            <th cdk-header-cell *cdkHeaderCellDef>Poids</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'weight'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="quantity">
            <th cdk-header-cell *cdkHeaderCellDef>Quantité</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'quantity'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="pieces">
            <th cdk-header-cell *cdkHeaderCellDef>Pieces</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'pieces'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="packages">
            <th cdk-header-cell *cdkHeaderCellDef>Paquets</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'packages'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uv">
            <th cdk-header-cell *cdkHeaderCellDef>UV</th>
            <td cdk-cell *cdkCellDef="let row">{{row.uv}}</td>
          </ng-container>
          <ng-container cdkColumnDef="brut_amount">
            <th cdk-header-cell *cdkHeaderCellDef>Montant brut</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsCurrency:'brut_amount'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="net_amount">
            <th cdk-header-cell *cdkHeaderCellDef>Montant net</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsCurrency:'net_amount'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="vat_amount">
            <th cdk-header-cell *cdkHeaderCellDef>Montant taxes</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsCurrency:'vat_amount'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="wvat_amount">
            <th cdk-header-cell *cdkHeaderCellDef>Montant TTC</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsCurrency:'wvat_amount'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article">
            <th cdk-header-cell *cdkHeaderCellDef>Article</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="article_detail_route.route({article: row.article_details})" *ngIf="row.article_details"><code>{{row.article_details?.code}}</code> {{row.article_details?.libelle}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="gc_article">
            <th cdk-header-cell *cdkHeaderCellDef>Article GC</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="externalarticle_detail_route.route({externalArticle: row.gc_article_details})" *ngIf="row.gc_article_details"><code>{{row.gc_article_details?.code}}</code> {{row.gc_article_details?.libelle}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="adh_article">
            <th cdk-header-cell *cdkHeaderCellDef>Article Adh</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="externalarticle_detail_route.route({externalArticle: row.adh_article_details})" *ngIf="row.adh_article_details"><code>{{row.adh_article_details?.code}}</code> {{row.adh_article_details?.libelle}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="family">
            <th cdk-header-cell *cdkHeaderCellDef>Famille</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="articlefamily_detail_route.route({articleFamily: $any({id: row.family})})" *ngIf="row.family">{{row.family}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="producer">
            <th cdk-header-cell *cdkHeaderCellDef>Producteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.producer_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="vvfamily">
            <th cdk-header-cell *cdkHeaderCellDef>Famille vivalya</th>
            <td cdk-cell *cdkCellDef="let row">{{row.vvfamily}}</td>
          </ng-container>
          <ng-container cdkColumnDef="adh_code">
            <th cdk-header-cell *cdkHeaderCellDef>Code adhérent</th>
            <td cdk-cell *cdkCellDef="let row">{{row.adh_code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="adh_libelle">
            <th cdk-header-cell *cdkHeaderCellDef>Libelllé adhérent</th>
            <td cdk-cell *cdkCellDef="let row">{{row.adh_libelle}}</td>
          </ng-container>
          <ng-container cdkColumnDef="gc_code">
            <th cdk-header-cell *cdkHeaderCellDef>Code GC</th>
            <td cdk-cell *cdkCellDef="let row">{{row.gc_code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="gc_libelle">
            <th cdk-header-cell *cdkHeaderCellDef>Libellé GC</th>
            <td cdk-cell *cdkCellDef="let row">{{row.gc_libelle}}</td>
          </ng-container>
          <ng-container cdkColumnDef="comments">
            <th cdk-header-cell *cdkHeaderCellDef>Commentaire</th>
            <td cdk-cell *cdkCellDef="let row">{{row.comments}}</td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>