import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from "@angular/core";
import * as maplibregl from "maplibre-gl";
import {SymbolLayerSpecification} from "maplibre-gl";
import {MeteoFull} from "../meteo-full";
import {MeteoService} from "../meteo.service";
import {first} from "rxjs/operators";
import {combineLatest, fromEvent} from "rxjs";

const MAP_PARAMS: {[index: string]: {center: [number, number], zoom: number}} = {
  france: {
    center: [2.6390772508210603, 46.441826066304316],
    zoom: 2.8
  },
  europe: {
    center: [2.6390772508210603, 46.441826066304316],
    zoom: 1.1
  }
};



@Component({
  selector: "lvadg-meteo-map",
  templateUrl: "./meteo-map.component.pug",
  styleUrls: ["./meteo-map.component.sass"]
})
export class MeteoMapComponent implements OnInit, OnDestroy {
  @ViewChild("map", {static: true}) public mapEl!: ElementRef;
  @Input() public geo: "france"|"europe" = "france";
  @Input() public day = 0;
  public meteo!: MeteoFull;
  private map: maplibregl.Map;

  constructor(private _zone: NgZone,
              public mt: MeteoService) {
  }

  async ngOnInit(): Promise<void> {
    this._zone.runOutsideAngular(() => {

      this.map = new maplibregl.Map({
        container: this.mapEl.nativeElement,
        style: "https://maptiles.gis.lavieadugout.fr/styles/toner-vivalya-light/style.json",
        center: MAP_PARAMS[this.geo].center,
        zoom: MAP_PARAMS[this.geo].zoom,
        antialias: true,
        interactive: false,
        minZoom: 1, // was 4.4
        attributionControl: false,
        preserveDrawingBuffer: false
      });
      combineLatest([fromEvent(this.map, "load"), this.mt.full])
        .pipe(first())
        .subscribe(([cmap, meteo]) => {
          this.meteo = meteo;
          this.map.addSource("meteo", {type: "geojson", data: this.meteo as any});
          // map.addLayer({
          //   "id": "meteoHeatmap",
          //   "type": "heatmap",
          //   "source": "meteo",
          //   "paint": {
          //     "heatmap-weight": [
          //       "get",
          //       "T_max",
          //       [
          //         "at",
          //         this.day,
          //         ["get", "daily_forecast"]
          //       ]
          //     ],
          //     "heatmap-radius": 20,
          //     "heatmap-opacity": 0.3,
          //     "heatmap-intensity": 20 / 40 / 10
          //   }
          // });
          this.map.addLayer({
            id: "meteoMarkers",
            source: "meteo",
            type: "symbol",
            layout: <SymbolLayerSpecification["layout"]>{
              "icon-image": [
                "get",
                "daily_weather_icon",
                ["at", this.day, ["get", "daily_forecast"]]
              ],
              "icon-ignore-placement": true,
              "icon-allow-overlap": false,
              "icon-size": 1,
              "icon-anchor": "center"
            }
          });

        });
    });
  }

  public ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }

}
