import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Provider, ProviderService} from "./provider.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class ProviderResolve implements Resolve<Provider> {
  constructor(private _bs: ProviderService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Provider> {
    return this._bs.get(+route.params["providerId"]);
  }
}
