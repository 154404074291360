import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Storage} from "../../structure/storage/storage.service";


export class ExternalArticleCollection extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 3,
    choices: [
      ["STO", "Groupe de dépôts"],
    ],
    default: "STO"
  })
  public type: "STO" = "STO";

  @DSManyToManyField({
    name: "storages",
    verboseName: "Dépôts",
    required: false,
    relatedModel: "structure.Storage",
    remoteField: "id",
    helpText: "Dépôts",
    default: [],
  })
  public storages!: number[];
  public storages_details!: Storage[];


  // Tracking mixin

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;


  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags!: any;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class ExternalArticleCollectionService extends DSRestCollection<ExternalArticleCollection> {
  public adapter_config = {basePath: "/api/v2/catalog/externalarticlecollections"};
  public model = ExternalArticleCollection;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
