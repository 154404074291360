<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'marchedate'; desc: 'Date valeur marché'; group: 'general'">
        <bsac-model-list-datetime-filter [list]="list" field="marchedate"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'lastpricedate'; desc: 'Date dernier tarif'; group: 'general'">
        <bsac-model-list-datetime-filter [list]="list" field="lastpricedate"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'displaystatus'; desc: 'Affichage'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="displaystatus" [choices]="DISPLAY_STATUSES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'infamily'; desc: 'Filtrer par famille'; group: 'general'">
        <bsac-model-list-tree-filter [list]="list" field="infamily" [related]="$any(families$)" descfield="name"></bsac-model-list-tree-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <div class="text-warning" *ngIf="tarif.type_details.type=='GEN'">Prix rendu dépôt</div>
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="tarif">
            <th cdk-header-cell *cdkHeaderCellDef>Tarif</th>
            <td class="pcol" cdk-cell *cdkCellDef="let row">{{row.tarif}}</td>
          </ng-container>
          <ng-container cdkColumnDef="activation">
            <th cdk-header-cell *cdkHeaderCellDef><i class="fa fa-check mx-2" title="Activation / désactivation article"></i></th>
            <td class="text-center" cdk-cell *cdkCellDef="let row"><i class="fa fa-check" [class.text-success]="row.status!='KO'" [class.text-muted]="row.status=='KO'" role="button" (click)="toggleStatus(row)" title="Activation / désactivation article"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="att" [sticky]="true">
            <th class="attcol" cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="att__article__libelle">Libellé catalogue</bsac-model-list-sorter>
            </th>
            <td class="attcol" cdk-cell *cdkCellDef="let row" (click)="onSelected(row)">{{row.att_details?.article_details?.libelle}}</td>
          </ng-container>
          <ng-container cdkColumnDef="eaclient_libelle" [sticky]="true">
            <th class="attcol" cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="att__eaclient__libelle">Libellé client</bsac-model-list-sorter>
            </th>
            <td class="attcol" cdk-cell *cdkCellDef="let row" (click)="onSelected(row)">{{row.eaclient_libelle||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article_family">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="att__article__family__cfullname"><span class="text-center"><span class="mx-2" title="Famille catalogue, utilisez les flèches pour afficher + ou -"><i class="fa fa-folder-tree"></i><span class="ms-1" *ngIf="familyDisplayInterval.display">Famille catalogue</span></span><span class="text-nowrap text-muted" *ngIf="familyDisplayInterval.display"><i class="fa fa-caret-left me-1 hovb" (click)="familyDisplayInterval.start = max(familyDisplayInterval.start-1, 0)" role="button"></i><i class="fa fa-caret-right me-2 hovb" (click)="familyDisplayInterval.start = min(familyDisplayInterval.start+1, familyDisplayInterval.end)" role="button"></i><i class="fa fa-caret-left me-1 hovb" (click)="familyDisplayInterval.end = max(familyDisplayInterval.end-1, familyDisplayInterval.start)" role="button"></i><i class="fa fa-caret-right me-2 hovb" (click)="familyDisplayInterval.end = min(familyDisplayInterval.end+1, 6)" role="button"></i><i class="fa fa-eye-slash hovb" (click)="familyDisplayInterval.display = false"></i></span><i class="fa fa-eye text-muted hovb" (click)="familyDisplayInterval.display = true" *ngIf="!familyDisplayInterval.display"></i></span></bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="text-center" *ngIf="!familyDisplayInterval.display"><i class="fa fa-folder-tree" [title]="row.article_family.join(' : ')"></i></div>
              <ng-container *ngIf="familyDisplayInterval.display">
                <ng-container *ngFor="let f of row.article_family; let i=index">
                  <ng-container *ngIf="i &gt;= familyDisplayInterval.start &amp;&amp; i &lt;= familyDisplayInterval.end"><span class="fam" [ngClass]="'family-'+(i-familyDisplayInterval.start)">{{f}}</span></ng-container>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="article_code">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="att__article__code">Code catalogue</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="article_detail_route.route({article: row.hatt.harticle})" target="_blank">{{row.article_code||'-'}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="eaclient_code">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="att__eaclient__code">Code client</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.eaclient_code||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="article_uf">
            <th cdk-header-cell *cdkHeaderCellDef>UF</th>
            <td cdk-cell *cdkCellDef="let row">{{row.article_uf||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="message">
            <th cdk-header-cell *cdkHeaderCellDef><i class="fa fa-sticky-note pointer p-1" (click)="showMessage=!showMessage" [class.text-muted]="showMessage" title="afficher/masquer les messages"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-articletarif-message-manage [at]="row" [message]="row.message" *ngIf="!showMessage"></lvadg-articletarif-message-manage>
              <div class="message" *ngIf="showMessage">{{row.message}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="lastprice1">
            <th cdk-header-cell *cdkHeaderCellDef title="Prix tarif N-1">T-1<i class="fa fa-chart-line pointer ms-1" [class.text-muted]="!lastShowVariations[0]" (click)="lastShowVariations[0]=!lastShowVariations[0]" title="Afficher le prix / la variation"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-articletarif-lastprice-display [at]="row" [index]="0" [variation]="lastShowVariations[0]" [price]="row.price" [current]="current"></lvadg-articletarif-lastprice-display>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="lastprice2">
            <th cdk-header-cell *cdkHeaderCellDef title="Prix tarif N-2">T-2<i class="fa fa-chart-line pointer ms-1" [class.text-muted]="!lastShowVariations[1]" (click)="lastShowVariations[1]=!lastShowVariations[1]" title="Afficher le prix / la variation"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-articletarif-lastprice-display [at]="row" [index]="1" [variation]="lastShowVariations[1]" [price]="row.price" [current]="current"></lvadg-articletarif-lastprice-display>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="lastprice3">
            <th cdk-header-cell *cdkHeaderCellDef title="Prix tarif N-3">T-3<i class="fa fa-chart-line pointer ms-1" [class.text-muted]="!lastShowVariations[2]" (click)="lastShowVariations[2]=!lastShowVariations[2]" title="Afficher le prix / la variation"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-articletarif-lastprice-display [at]="row" [index]="2" [variation]="lastShowVariations[2]" [price]="row.price" [current]="current"></lvadg-articletarif-lastprice-display>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="variation1">
            <th cdk-header-cell *cdkHeaderCellDef>%/T-1</th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-articletarif-lastprice-display [at]="row" [index]="0" [variation]="true" [price]="row.price" [current]="current"></lvadg-articletarif-lastprice-display>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="variation2">
            <th cdk-header-cell *cdkHeaderCellDef>%/T-2<i class="fa fa-chart-line pointer ms-1" [class.text-muted]="!lastShowVariations[1]" (click)="lastShowVariations[1]=!lastShowVariations[1]"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-articletarif-lastprice-display [at]="row" [index]="1" [variation]="true" [price]="row.price" [current]="current"></lvadg-articletarif-lastprice-display>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="variation3">
            <th cdk-header-cell *cdkHeaderCellDef>%/T-3</th>
            <td cdk-cell *cdkCellDef="let row">
              <lvadg-articletarif-lastprice-display [at]="row" [index]="2" [variation]="true" [price]="row.price" [current]="current"></lvadg-articletarif-lastprice-display>
            </td>
          </ng-container>
          <ng-container *ngFor="let scol of storages_columns; trackBy: trackColumn">
            <ng-container [cdkColumnDef]="scol.name">
              <th class="stcol" cdk-header-cell *cdkHeaderCellDef>
                <lvadg-articletarif-column-operations [mode]="colop.StoragePrice" [tarif]="tarif" [reload$]="reload" [storage]="scol" [storages]="storages_columns" [name]="scol.verbose_name"><span class="member">{{scol.member}}</span><span class="storage">{{scol.storage}}</span></lvadg-articletarif-column-operations>
              </th>
              <td class="stcol" cdk-cell *cdkCellDef="let row; let index=index" [ngClass]="getCellClasses(scol.name, row)">
                <lvadg-articletarif-price-editor [tarif]="tarif" [at]="row" [ati]="row.storageItems[scol.storageId]" [storageId]="scol.storageId" [editable]="!scol.readonly" [index]="index" [type]="scol.name" (register)="registerEditor($event)" (unregister)="unregisterEditor($event)" (next)="activateNext($event, 1)" (previous)="activateNext($event, -1)" [focus$]="focus$" [updates$]="updates$" (updated)="savedPrice($event)" (selected)="onSelected($event)" (curcol)="onSelectedCol($event)"></lvadg-articletarif-price-editor>
              </td>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let scol of marches_columns; trackBy: trackColumn">
            <ng-container [cdkColumnDef]="scol.name">
              <th class="stcol" cdk-header-cell *cdkHeaderCellDef>
                <lvadg-articletarif-column-operations [mode]="colop.RnmPrice" [tarif]="tarif" [reload$]="reload" [name]="scol.verbose_name"><span>{{scol.verbose_name}}</span></lvadg-articletarif-column-operations>
              </th>
              <td class="stcol" cdk-cell *cdkCellDef="let row" [ngClass]="getCellClasses(scol.name, row)">
                <lvadg-articletarif-tarifmarche-display [row]="row" [scol]="scol"></lvadg-articletarif-tarifmarche-display>
              </td>
            </ng-container>
          </ng-container>
          <ng-container cdkColumnDef="price">
            <th class="pcol" cdk-header-cell *cdkHeaderCellDef>
              <lvadg-articletarif-column-operations [mode]="colop.GlobalPrice" [tarif]="tarif" [reload$]="reload" name="Prix"><span>Prix</span></lvadg-articletarif-column-operations>
            </th>
            <td class="pcol" cdk-cell *cdkCellDef="let row; let index=index" [ngClass]="getCellClasses('price', row)">
              <lvadg-articletarif-price-editor [tarif]="tarif" [at]="row" [editable]="isEditable()" [index]="index" type="price" (register)="registerEditor($event)" (unregister)="unregisterEditor($event)" (next)="activateNext($event, 1)" (previous)="activateNext($event, -1)" [focus$]="focus$" [updates$]="updates$" (updated)="savedPrice($event)" (selected)="onSelected($event)" (curcol)="onSelectedCol($event)"></lvadg-articletarif-price-editor>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="items">
            <th cdk-header-cell *cdkHeaderCellDef>Détails</th>
            <td cdk-cell *cdkCellDef="let row">{{row.items}}</td>
          </ng-container>
          <!-- PRIX PAR RELATION-->
          <ng-container *ngFor="let rcol of trel_columns; trackBy: trackColumn">
            <ng-container [cdkColumnDef]="rcol.name">
              <th cdk-header-cell *cdkHeaderCellDef>{{rcol.verbose_name}}<span ngbDropdown><a class="px-2" type="button" ngbDropdownToggle></a>
                  <div ngbDropdownMenu>
                    <ng-container *ngIf="!rcol.stoId">
                      <button ngbDropdownItem (click)="rcol.libelle=!rcol.libelle"><i class="fa fa-check me-2" *ngIf="!rcol.libelle"></i>Masquer le libellé source</button>
                      <button ngbDropdownItem (click)="rcol.libelle=!rcol.libelle"><i class="fa fa-check me-2" *ngIf="rcol.libelle"></i>Afficher le libellé source</button>
                      <div class="dropdown-divider"></div>
                    </ng-container>
                    <button ngbDropdownItem (click)="rcol.oprice=!rcol.oprice"><i class="fa fa-check me-2" *ngIf="!rcol.oprice"></i>Afficher le prix après calcul</button>
                    <button ngbDropdownItem (click)="rcol.oprice=!rcol.oprice"><i class="fa fa-check me-2" *ngIf="rcol.oprice"></i>Afficher le prix avant calcul</button>
                    <ng-container *ngIf="!rcol.stoId &amp;&amp; storages_columns.length&gt;0">
                      <div class="dropdown-divider"></div>
                      <button ngbDropdownItem (click)="rcol.gsprice=!rcol.gsprice"><i class="fa fa-check me-2" *ngIf="!rcol.gsprice"></i>Afficher le prix des dépôts affichés</button>
                      <button ngbDropdownItem (click)="rcol.gsprice=!rcol.gsprice"><i class="fa fa-check me-2" *ngIf="rcol.gsprice"></i>Afficher le prix de l'ensemble des dépôts</button>
                    </ng-container>
                  </div></span></th>
              <td cdk-cell *cdkCellDef="let row">
                <lvadg-articletarif-tarifrelation-display [at]="row" [trel]="rcol" [update$]="list.results" (updated)="updateArticle($event)"></lvadg-articletarif-tarifrelation-display>
              </td>
            </ng-container>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>