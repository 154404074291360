import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus} from "ngx-uploader";
import {AuthService} from "../../models/user/auth.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";

@Component({
  selector: "lvadg-upload-file",
  templateUrl: "./upload-file.component.pug",
  styleUrls: ["./upload-file.component.sass"]
})
export class UploadFileComponent implements OnInit {
  @Input() public url: string;       // image upload url
  @Input() public data: any = {};    // data to pass along image upload
  @Input() public multiple = false;  // allow sending multiple images
  @Input() public auto = true;       // auto send files on add
  @Input() public fieldName = "file"; // Field name for send
  @Input() public mimetypes: string[] = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  @Output() public uploaded: EventEmitter<any> = new EventEmitter<any>();  // uploaded event (when all files uploaded)

  public options!: UploaderOptions;
  public formData!: FormData;
  public files!: UploadFile[];
  public uploadInput!: EventEmitter<UploadInput>;
  public humanizeBytes!: Function;
  public dragOver!: boolean;
  public responses: any[] = [];
  private started = false;

  constructor(private _auth: AuthService, private _msg: BsacMessageService) {
  }

  ngOnInit() {
    this.options = {concurrency: 1};
    if (this.mimetypes && this.mimetypes.length > 0) {
      this.options.allowedContentTypes = this.mimetypes;
    }
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  public onUploadOutput(output: UploadOutput): void {
    if (output.type === "allAddedToQueue") {
      if (this.auto && this.files.length > 0) {
        this.responses = [];
        this.startUpload();
      }
    } else if (output.type === "addedToQueue" && typeof output.file !== "undefined") {
      this.files.push(output.file);
    } else if (output.type === "uploading" && typeof output.file !== "undefined") {
      const index = this.files.findIndex(file => typeof output.file !== "undefined" && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === "removed") {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === "dragOver") {
      this.dragOver = true;
    } else if (output.type === "dragOut") {
      this.dragOver = false;
    } else if (output.type === "drop") {
      this.dragOver = false;
    } else if (output.type === "done") {
      this.responses.push(output.file.response);
    } else if (output.type === "rejected" && typeof output.file !== "undefined") {
      this._msg.warning(output.file.name + " : type de fichier non autorisé");
    }

    this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
    if (this.started && this.files.length === 0) {
      this.uploaded.emit(this.responses);
      this.started = false;
    }
  }

  public startUpload(): void {
    this.started = true;
    const event: UploadInput = {
      type: "uploadAll",
      url: this.url,
      method: "POST",
      fieldName: this.fieldName,
      // FIXME: ensure auth.token.token exists ?
      headers: {
        "x-token": this._auth.token.token,
        "ngsw-bypass": "bypass"
      },
      data: this.data
    };

    this.uploadInput.emit(event);
  }

  public cancelUpload(id: string): void {
    this.uploadInput.emit({type: "cancel", id: id});
  }

  public removeFile(id: string): void {
    this.uploadInput.emit({type: "remove", id: id});
  }

  public removeAllFiles(): void {
    this.uploadInput.emit({type: "removeAll"});
  }
}
