import {NgModule} from "@angular/core";
import {PersonCreateComponent} from "./person-create/person-create.component";
import {PersonDeleteComponent} from "./person-delete/person-delete.component";
import {PersonDisplayComponent} from "./person-display/person-display.component";
import {PersonListComponent} from "./person-list/person-list.component";
import {PersonManageComponent} from "./person-manage/person-manage.component";
import {PersonService} from "./person.service";
import {PersonResolve} from "./person.resolve";
import {SiteModule} from "../../authentication/site/site.module";
import {PersonSelectModule} from "./person-select.module";
import {ImageModule} from "../../content/image/image.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {PersonAccountComponent} from "./person-account/person-account.component";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    PersonSelectModule,
    SiteModule,
    ImageModule,
  ],
  providers: [
    PersonService,
    PersonResolve
  ],
  declarations: [
    PersonDisplayComponent,
    PersonDeleteComponent,
    PersonListComponent,
    PersonManageComponent,
    PersonCreateComponent,
    PersonAccountComponent
  ],
  exports: [
    PersonSelectModule,
    PersonDisplayComponent,
    PersonDeleteComponent,
    PersonListComponent,
    PersonManageComponent,
    PersonCreateComponent,
    PersonAccountComponent
  ],
})
export class PersonModule {
}
