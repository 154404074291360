<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'status'; desc: 'Recherche par état'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="status" choices="OFFER_STATUSES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'member'; desc: 'Recherche par adhérent'; group: 'struct'">
        <bsac-model-list-autocomplete-filter [list]="list" field="member" [queryset]="members$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storage'; desc: 'Recherche par dépôt'; group: 'struct'">
        <bsac-model-list-autocomplete-filter [list]="list" field="storage" [queryset]="storages$" descfield="name" [filter]="storageFilter"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'client'; desc: 'Recherche par client'; group: 'struct'">
        <bsac-model-list-autocomplete-filter [list]="list" field="client" [queryset]="clients$" descfield="cfullname"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'client_tree'; desc: 'Recherche par client &amp; sous-enseigne'; group: 'struct'">
        <bsac-model-list-autocomplete-filter [list]="list" field="client_tree" [queryset]="clients$" descfield="cfullname"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'restos'; desc: 'Recherche par restaurant'; group: 'struct'">
        <bsac-model-list-autocomplete-filter [list]="list" field="restos" [queryset]="restos$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart'; desc: 'Recherche par date de début'; group: 'date'">
        <bsac-model-list-datetimerange-filter [list]="list" field="datestart"></bsac-model-list-datetimerange-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend'; desc: 'Recherche par date de fin'; group: 'date'">
        <bsac-model-list-datetimerange-filter [list]="list" field="dateend"></bsac-model-list-datetimerange-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'struct'; desc: 'Recherche par structure'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'date'; desc: 'Recherche par date'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status | bsacApiConstantSingle: 'OFFER_STATUSES'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="parent">
            <th cdk-header-cell *cdkHeaderCellDef>Offre parente</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="routes.detail(zone, 'offers', row.parent)" *ngIf="row.parent"><b>[{{row.parent}}]</b></a><span *ngIf="!row.parent">-</span></td>
          </ng-container>
          <ng-container cdkColumnDef="title">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id">Titre</bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="routes.detail(zone, type, row)"><b>{{row.title}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="member">
            <th cdk-header-cell *cdkHeaderCellDef>Adhérent</th>
            <td cdk-cell *cdkCellDef="let row">{{row.member_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="storage">
            <th cdk-header-cell *cdkHeaderCellDef>Dépôt</th>
            <td cdk-cell *cdkCellDef="let row">{{row.storage_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="client">
            <th cdk-header-cell *cdkHeaderCellDef>Client</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.client">{{row.client_details?.name}}</ng-container>
              <ng-container *ngIf="!row.client"><i>offre générique</i></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="restos">
            <th cdk-header-cell *cdkHeaderCellDef>Restaurants</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.restos?.length &gt; 0">{{row.restos.length}} restaurant(s)</ng-container>
              <ng-container *ngIf="row.restos?.length == 0">aucun</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="author">
            <th cdk-header-cell *cdkHeaderCellDef>Auteur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.author_details?.lastname}} {{row.author_details?.firstname}}</td>
          </ng-container>
          <ng-container cdkColumnDef="datestart">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="datestart">Date de début</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.datestart|date:'short'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="dateend">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="dateend">Date de fin</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.dateend|date:'short'}}</td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>