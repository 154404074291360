import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {debounceTime, filter, takeUntil} from "rxjs/operators";
import {CommFieldParamsService} from "../commfieldparams.service";
import {Subject} from "rxjs";
import {IFieldParameter} from "../commfield.service";

@Component({
  selector: "lvadg-commfield-textfield",
  templateUrl: "./commfield-textfield.component.pug",
  styleUrls: ["./commfield-textfield.component.sass"]
})
export class CommFieldTextFieldComponent implements OnInit {

  @Input() public params!: CommFieldParamsService;
  @Input() public part!: string;
  @Input() public name!: string;
  @Input() public mode: "text"|"rich" = "text";
  @Input() public title = true;
  public _subscriptions = new Subject<void>();
  public param!: IFieldParameter;
  public form!: UntypedFormGroup;
  public textForm!: UntypedFormGroup;
  public asktext = false;
  public valHelp = "";
  public quillModules: any = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote", {"align": []},
        {"script": "sub"}, {"script": "super"}],
      [{"header": 1}, {"header": 2}, {"list": "ordered"}, {"list": "bullet"}, {"indent": "-1"}, {"indent": "+1"}],
      [{"color": []}, {"background": []},  "clean", "link"]
    ]
  };

  constructor() {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      "value": new UntypedFormControl("")
    });
    this.textForm = new UntypedFormGroup({
      "tvalue": new UntypedFormControl(""),
      "svalue": new UntypedFormControl(""),
    });
    this.params.getByName(this.part, this.name)
      .pipe(takeUntil(this._subscriptions),
        filter((p) => !!p))
      .subscribe((val) => {
        this.param = val as IFieldParameter;
        for (const o of this.param.choices) {
          if (o.current) {
            this.asktext = o.action && o.action === "asktext";
            this.valHelp = o.help || "";
            this.form.setValue({
              value: o.value
            }, {emitEvent: false});
            if (this.param.field_details) {
              this.textForm.setValue({
                svalue: this.param.field_details.svalue || o.desc || "",
                tvalue: this.param.field_details.tvalue || "",
              }, {emitEvent: false});
            } else {
              this.textForm.setValue({
                svalue: o.desc || "",
                tvalue: "",
              }, {emitEvent: false});
            }
          }
        }
      });
    this.form.valueChanges
      .pipe(takeUntil(this._subscriptions),
        debounceTime(750))
      .subscribe(async () => {
        await this.params.setByName(this.part, this.name, {
          value: this.form.value.value,
          tvalue: this.textForm.value.tvalue,
          svalue: this.textForm.value.svalue
        });
      });
  }

  public removeParam(): void {
    this.params.removeByName(this.part, this.name);
  }

  public saveText(): void {
    this.params.setByName(this.part, this.name, {
      value: this.form.value.value,
      tvalue: this.textForm.value.tvalue,
      svalue: this.textForm.value.svalue
    });
  }
}
