
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <ng-container *ngFor="let p of tree">
      <li class="breadcrumb-item" *ngIf="!p.current"><a class="breadcrumb-item" [routerLink]="p.path">
          <ng-container *ngIf="icons &amp;&amp; p.icon"><i class="me-2" [ngClass]="p.icon"></i>{{p.title}}</ng-container>
          <ng-container *ngIf="!(icons &amp;&amp; p.icon)">{{p.title}}</ng-container></a></li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="p.current">
        <ng-container *ngIf="icons &amp;&amp; p.icon"><i class="me-2" [ngClass]="p.icon"></i>{{p.title}}</ng-container>
        <ng-container *ngIf="!(icons &amp;&amp; p.icon)">{{p.title}}</ng-container>
      </li>
    </ng-container>
  </ol>
</nav>