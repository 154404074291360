import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateField,
  DSDateTimeField,
  DSDefaultField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";


export class SettingsValue extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "key",
    verboseName: "Key",
    required: false,
    helpText: "Key",
    minLength: 0,
    maxLength: 50,
    choices: []
  })
  public key!: string;

  @DSCharField({
    name: "opt",
    verboseName: "Opt",
    required: false,
    helpText: "Opt",
    minLength: 0,
    maxLength: 50,
    choices: []
  })
  public opt!: string;


  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 50,
    choices: []
  })
  public type!: string;


  @DSIntegerField({
    required: false,
    name: "value_int",
    helpText: "",
    verboseName: "value int",
    choices: [],
    default: 0,
  })
  public value_int!: any;

  @DSCharField({
    required: false,
    name: "value_dec",
    helpText: "",
    verboseName: "value dec",
    choices: [],
    default: 0,
  })
  public value_dec!: any;

  @DSCharField({
    required: false,
    name: "value_stx",
    helpText: "",
    verboseName: "value stx",
    choices: [],
    default: "",
    maxLength: 250,
  })
  public value_stx!: string;

  @DSTextField({
    required: false,
    name: "value_ltx",
    helpText: "",
    verboseName: "value ltx",
    choices: [],
    default: "",
    maxLength: 3000,
  })
  public value_ltx!: string;

  @DSDateField({
    required: false,
    name: "value_dte",
    helpText: "",
    verboseName: "value dte",
    choices: [],
    default: null,
  })
  public value_dte!: Date;

  @DSDateTimeField({
    required: false,
    name: "value_dth",
    helpText: "",
    verboseName: "value dth",
    choices: [],
    default: null,
  })
  public value_dth!: Date;

  @DSDefaultField({
    required: false,
    name: "value_json",
    helpText: "",
    verboseName: "value json",
    choices: [],
    default: null
  })
  public value_json!: any;


  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class SettingsValueService extends DSRestCollection<SettingsValue> {
  public adapter_config = {basePath: "/api/v2/settingsvalues"};
  public model = SettingsValue;
  private _cache: { [index: string]: SettingsValue } = {};

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }

  public async loadCache(): Promise<boolean> {
    if (!this._cache) {
      const qr = await this.queryset.all().toPromise();
      for (const sr of qr.items) {
        this._cache[`${sr.type}@@${sr.key}@@${sr.opt}`] = sr;
      }
      return true;
    }
    return false;
  }

  public cget(type: string, key: string, opt: string): SettingsValue {
    return this._cache[`${type}@@${key}@@${opt}`];
  }
}
