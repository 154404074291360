
<ng-template #tpl>
  <form [formGroup]="form" (submit)="submit()" *ngIf="tg &amp;&amp; form">
    <div class="row">
      <div class="col-sm-12" *ngIf="!tarifType">
        <div class="form-group">
          <label>Modèle de tarif à utiliser</label>
          <bsac-field-control [form]="form" [model]="tg" field="type" [related]="tarifTypes$" descfield="name" relatedLabel="Sélectionnez le modèle" id="ttinput"></bsac-field-control>
        </div>
      </div>
      <ng-container *ngIf="tg.type">
        <div class="col-sm-3">
          <div class="form-group">
            <label>(1) Date de début</label>
            <bsac-field-control [form]="form" [model]="tg" field="datestart" dateFormat="short" id="startdateinput"></bsac-field-control>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="btn btn-primary d-block" id="startlastdate" (click)="setLastDate()" *ngIf="!duplicate">Commencer à la date de fin du dernier</div>
          <div class="btn btn-primary d-block" id="startlaststartinterval" (click)="setLastDate(true)" *ngIf="!duplicate">Commencer à la date de début du dernier + intervalle</div>
          <div class="btn btn-primary d-block" id="startenddate" (click)="setLastDate(false, duplicate)" *ngIf="duplicate">Commencer à la date de fin du tarif</div>
          <div class="btn btn-primary d-block" id="startenddateinterval" (click)="setLastDate(true, duplicate)" *ngIf="duplicate">Commencer à la date de début du tarif + intervalle</div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label>(2) Date de fin</label>
            <bsac-field-control [form]="form" [model]="tg" field="dateend" dateFormat="short" id="enddateinput"></bsac-field-control>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="btn btn-primary d-block" (click)="setDateEnd(form.value.datestart)" id="enddateinterval">Finir à la date de début + intervalle</div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label>(3) Nom du tarif</label>
            <bsac-field-control [form]="form" [model]="tg" field="name" id="tarifnameinput"></bsac-field-control>
          </div>
        </div>
        <div class="col-sm-12 text-right">
          <button class="btn btn-primary" id="savenewtarif" [disabled]="creating">
            <ng-container *ngIf="!creating"><i class="fa fa-save me-2"></i>Enregistrer</ng-container>
            <ng-container *ngIf="creating"><i class="fa fa-spinner fa-spin me-2"></i>Création en cours, merci de patienter</ng-container>
          </button>
        </div>
      </ng-container>
    </div>
  </form>
</ng-template>
<div class="card" *ngIf="!standAlone">
  <div class="card-header"><i class="fa fa-plus me-2"></i>Ajouter un tarif</div>
  <div class="card-body">
    <ng-container *ngTemplateOutlet="tpl"></ng-container>
  </div>
</div>
<ng-container *ngIf="standAlone">
  <ng-container *ngTemplateOutlet="tpl"></ng-container>
</ng-container>