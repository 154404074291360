import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Person, PersonService} from "./person.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class PersonResolve implements Resolve<Person> {
  constructor(private _bs: PersonService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Person> {
    return this._bs.get(+route.params["personId"]);
  }
}
