import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CustomDataKey} from "../../customdatakey/customdatakey.service";
import {Folder, FolderService} from "../../../document/folder/folder.service";

@Component({
  selector: "lvadg-customdata-value-edit-folder",
  templateUrl: "./customdata-value-edit-folder.component.pug",
  styleUrls: ["./customdata-value-edit-folder.component.sass"]
})
export class CustomdataValueEditFolderComponent implements OnInit {
  @Input() public key!: CustomDataKey;
  @Input() public value!: Folder;
  @Output() public save = new EventEmitter<Folder>();
  @Output() public clear = new EventEmitter<void>();

  constructor(public folders: FolderService) {
  }

  ngOnInit() {
  }

}
