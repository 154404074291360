import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";


export class ArticleConditionnement extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;

  @DSBooleanField({
    name: "colisage",
    verboseName: "Colisage ?",
    required: false,
    helpText: "Colisage ?",
    default: false
  })
  public colisage!: boolean;

  @DSBooleanField({
    name: "uci",
    verboseName: "UCI ?",
    required: false,
    helpText: "UCI ?",
    default: false
  })
  public uci!: boolean;


}

@Injectable({
  providedIn: "root"
})
export class ArticleConditionnementService extends DSRestCollection<ArticleConditionnement> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/conditionnements"};
  public model = ArticleConditionnement;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
