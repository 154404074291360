<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'members'; desc: 'Adhérent(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="members" [queryset]="members$" descfield="name" hint="Sélectionnez un adhérent" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'status'; desc: 'Etat du dépôt'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="status" choices="STORAGE_STATUSES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagenear'; desc: 'Dépôt proche de'; group: 'general'">
        <bsac-model-list-geodistance-filter [list]="list" field="storagenear"></bsac-model-list-geodistance-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeoregions'; desc: 'Région du dépôt'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeoregions" [queryset]="regions$" descfield="fullname" hint="Sélectionnez une région" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeodepartements'; desc: 'Département du dépôt'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeodepartements" [queryset]="departements$" descfield="fullname" hint="Sélectionnez un département" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagegeocommunes'; desc: 'Commune du dépôt'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storagegeocommunes" [queryset]="communes$" descfield="fullname" hint="Sélectionnez une commune" [select]="false" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <ng-container *ngIf="!checkMode('admin')">
      <button class="btn btn-link" (click)="displayMode='vertical'" *ngIf="displayMode=='vertical2res'">Masquer la carte</button>
      <button class="btn btn-link" (click)="displayMode='vertical2res'" *ngIf="displayMode=='vertical'">Afficher la carte</button>
      <ng-container *ngIf="checkAction('select')">
        <lvadg-selection-summary stype="storage" unit="dépots(s)" (action)="onAction($event)" [actions]="select_actions" [custom_actions]="select_custom_actions"></lvadg-selection-summary>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="checkMode('admin')">
      <button class="btn btn-link" (click)="resultsMode='map'" *ngIf="resultsMode!='map'">Afficher sur une carte</button>
      <button class="btn btn-link" (click)="resultsMode='list'" *ngIf="resultsMode!='list'">Afficher la liste</button>
      <button class="btn btn-link" (click)="resultsMode='stats'" *ngIf="resultsMode!='stats' &amp;&amp; checkMode('admin')">Afficher les stats</button>
    </ng-container>
    <lvadg-modellist-xlsx-export [list]="list" [mode]="mode"></lvadg-modellist-xlsx-export>
  </div>
  <div class="list-results">
    <ng-container *ngIf="resultsMode=='map'">
      <ng-container *ngIf="list.results|async; let res">
        <lvadg-locations-osm-display [storages]="res.items" height="700px"></lvadg-locations-osm-display>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="resultsMode=='stats' &amp;&amp; checkMode('admin')">
      <lvadg-billing-stats [storageFilter$]="this.list.filter.output$"></lvadg-billing-stats>
    </ng-container>
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="select">
            <th cdk-header-cell *cdkHeaderCellDef>
              <ng-container *ngIf="checkAction('select')">
                <lvadg-selection-selitem [items]="results" stype="storage"></lvadg-selection-selitem>
              </ng-container>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkAction('select')">
                <lvadg-selection-selitem [item]="row" stype="storage"></lvadg-selection-selitem>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="name">Nom</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="routes.detail(zone, type, row)">{{row.name}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="cvva">
            <th cdk-header-cell *cdkHeaderCellDef>Code vivalya</th>
            <td cdk-cell *cdkCellDef="let row">{{row.cvva}}</td>
          </ng-container>
          <ng-container cdkColumnDef="member">
            <th cdk-header-cell *cdkHeaderCellDef>Adhérent</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="routes.detail(zone, 'members', row.member)">{{row.member_details?.name}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="mainaddress">
            <th cdk-header-cell *cdkHeaderCellDef>Adresse complète</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.address}} {{row.mainlocation_details?.postcode}} {{row.mainlocation_details?.city}}</td>
          </ng-container>
          <ng-container cdkColumnDef="mainlocation__address">
            <th cdk-header-cell *cdkHeaderCellDef>Adresse</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.address}}</td>
          </ng-container>
          <ng-container cdkColumnDef="mainlocation__postcode">
            <th cdk-header-cell *cdkHeaderCellDef>Code postal</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.postcode}}</td>
          </ng-container>
          <ng-container cdkColumnDef="mainlocation__city">
            <th cdk-header-cell *cdkHeaderCellDef>Ville</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.city}}</td>
          </ng-container>
          <ng-container cdkColumnDef="mainlocation__departement">
            <th cdk-header-cell *cdkHeaderCellDef>Département</th>
            <td cdk-cell *cdkCellDef="let row">[{{row.mainlocation_details?.geodepartement_details?.code}}] {{row.mainlocation_details?.geodepartement_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="mainlocation__region">
            <th cdk-header-cell *cdkHeaderCellDef>Région</th>
            <td cdk-cell *cdkCellDef="let row">{{row.mainlocation_details?.georegion_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.type=='FL'">F&L</ng-container>
              <ng-container *ngIf="row.type=='MR'">Marée</ng-container>
              <ng-container *ngIf="row.type=='FLMR'">F&L&Marée</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="content_type">
            <th cdk-header-cell *cdkHeaderCellDef>Format desc.</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_type}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content_src">
            <th cdk-header-cell *cdkHeaderCellDef>Description (source)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_src}}</td>
          </ng-container>
          <ng-container cdkColumnDef="content">
            <th cdk-header-cell *cdkHeaderCellDef>Description (html)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content}}</td>
          </ng-container>
          <ng-container cdkColumnDef="phone">
            <th cdk-header-cell *cdkHeaderCellDef>Téléphone</th>
            <td cdk-cell *cdkCellDef="let row">{{row.phone}}</td>
          </ng-container>
          <ng-container cdkColumnDef="fax">
            <th cdk-header-cell *cdkHeaderCellDef>Fax</th>
            <td cdk-cell *cdkCellDef="let row">{{row.fax}}</td>
          </ng-container>
          <ng-container cdkColumnDef="website">
            <th cdk-header-cell *cdkHeaderCellDef>Site web</th>
            <td cdk-cell *cdkCellDef="let row">{{row.website}}</td>
          </ng-container>
          <ng-container cdkColumnDef="siren">
            <th cdk-header-cell *cdkHeaderCellDef>SIREN</th>
            <td cdk-cell *cdkCellDef="let row">{{row.siren}}</td>
          </ng-container>
          <ng-container cdkColumnDef="siret">
            <th cdk-header-cell *cdkHeaderCellDef>SIRET</th>
            <td cdk-cell *cdkCellDef="let row">{{row.siret}}</td>
          </ng-container>
          <ng-container cdkColumnDef="tvaic">
            <th cdk-header-cell *cdkHeaderCellDef>N°TVA</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tvaic}}</td>
          </ng-container>
          <ng-container cdkColumnDef="naf">
            <th cdk-header-cell *cdkHeaderCellDef>Code NAF</th>
            <td cdk-cell *cdkCellDef="let row">{{row.naf}}</td>
          </ng-container>
          <ng-container cdkColumnDef="legal">
            <th cdk-header-cell *cdkHeaderCellDef>Structure légale</th>
            <td cdk-cell *cdkCellDef="let row">{{row.legal}}</td>
          </ng-container>
          <ng-container cdkColumnDef="capital">
            <th cdk-header-cell *cdkHeaderCellDef>Capital</th>
            <td cdk-cell *cdkCellDef="let row">{{row.capital}}</td>
          </ng-container>
          <ng-container cdkColumnDef="logo">
            <th cdk-header-cell *cdkHeaderCellDef>Logo</th>
            <td cdk-cell *cdkCellDef="let row">{{row.logo}}</td>
          </ng-container>
          <ng-container cdkColumnDef="slabels">
            <th cdk-header-cell *cdkHeaderCellDef>Labels structure</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-info mx-1" *ngFor="let l of row.slabels">{{l|slabelName}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="locations">
            <th cdk-header-cell *cdkHeaderCellDef>Emplacements</th>
            <td cdk-cell *cdkCellDef="let row">{{row.locations}}</td>
          </ng-container>
          <ng-container cdkColumnDef="products_count">
            <th cdk-header-cell *cdkHeaderCellDef>Nb produits</th>
            <td cdk-cell *cdkCellDef="let row">{{row.products_count}}</td>
          </ng-container>
          <ng-container cdkColumnDef="producers_count">
            <th cdk-header-cell *cdkHeaderCellDef>Nb producteurs FL</th>
            <td cdk-cell *cdkCellDef="let row">{{row.producers_count}}</td>
          </ng-container>
          <ng-container cdkColumnDef="qr">
            <th cdk-header-cell *cdkHeaderCellDef>QR Code</th>
            <td cdk-cell *cdkCellDef="let row"><a [href]="row.iourl.url" *ngIf="row.iourl" target="_blank"><img class="qr img-fluid" *ngIf="row.qr" [src]="row.qr"></a></td>
          </ng-container>
          <ng-container cdkColumnDef="iourl">
            <th cdk-header-cell *cdkHeaderCellDef>lvadg.io</th>
            <td cdk-cell *cdkCellDef="let row"><a [href]="row.iourl.url" *ngIf="row.iourl" target="_blank">{{row.iourl.code}}</a><span *ngIf="!row.iourl">-</span></td>
          </ng-container>
          <ng-container cdkColumnDef="images">
            <th cdk-header-cell *cdkHeaderCellDef>Images</th>
            <td cdk-cell *cdkCellDef="let row">{{row.images}}</td>
          </ng-container>
          <ng-container cdkColumnDef="latest_stats">
            <th cdk-header-cell *cdkHeaderCellDef>
              <lvadg-select-stats [filter_stats]="filter_stats" [initial]="default_stats" [available]="available_stats" (selected)="statsSelection($event)">Stats locales (*)</lvadg-select-stats>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.stats_local}}
              <lvadg-disp-stats [stats]="row.latest_stats" format="inline" [displayed]="enabled_stats"></lvadg-disp-stats>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="documents">
            <th cdk-header-cell *cdkHeaderCellDef>Documents</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.documents?.length">{{row.documents?.length}} documents</ng-container>
            </td>
          </ng-container>
          <ng-container *ngFor="let ck of cdatakeys">
            <ng-container [cdkColumnDef]="'customdata_'+ck.id">
              <th cdk-header-cell *cdkHeaderCellDef>{{ck.name}}</th>
              <td cdk-cell *cdkCellDef="let row">
                <lvadg-customdata-rowfield-display [mode]="mode" [editors]="['admin', 'memberadmin']" [model]="row" [dest]="ck.dest" [code]="ck.code"></lvadg-customdata-rowfield-display>
              </td>
            </ng-container>
          </ng-container>
          <ng-container cdkColumnDef="actions">
            <th cdk-header-cell *cdkHeaderCellDef>Actions</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="checkAction('quickprintproducers')">
                <button class="btn btn-link btn-sm ms-1 me-1 mt-1" (click)="onAction({action: 'quickprintproducers', storage: row})"><i class="fa fa-print me-2"></i></button>
              </ng-container>
              <ng-container *ngIf="checkAction('quickprintproducts')">
                <button class="btn btn-link btn-sm ms-1 me-1 mt-1" (click)="onAction({action: 'quickprintproducts', storage: row})"><i class="fa fa-print me-2"></i></button>
              </ng-container>
            </td>
          </ng-container>
          <lvadg-locations-osm-display [storages]="mapmode === 'all' ? fullStorages : results" [members]="mapmode === 'all' ? fullMembers : members" [height]="'700px'"></lvadg-locations-osm-display>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
    <lvadg-locations-osm-display [storages]="mapmode === 'all' ? fullStorages : results" [members]="mapmode === 'all' ? fullMembers : members" [height]="'700px'"></lvadg-locations-osm-display>
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>