/**
 * Label : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @changed 2018-04-26-12:56:53
 * @date 2018-04-26-12:56:59
 * @copyright (c) 2018 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";
import {Observable, of, ReplaySubject} from "rxjs";
import {first, map} from "rxjs/operators";
import {Rimg} from "../../../comps/rimg/rimg";
import {IBsacApiConstants} from "@solidev/bsadmincomponents";

export class BaseLabel extends DSModel {
  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id: number;

  @DSSlugField({
    required: true,
    name: "code",
    helpText: "",
    verboseName: "Code",
    choices: [],
    default: null,
    maxLength: 10,
  })
  public code: string;

  @DSCharField({
    required: true,
    name: "Nom",
    helpText: "",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public name: string;

  @DSForeignKeyField({
    required: false,
    name: "logo",
    helpText: "",
    verboseName: "Logo",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "labellogo",
  })
  public logo: number;

  public logo_img: Rimg;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Modifié",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [["OK", "Validé - en service"], ["NOK", "Pas OK - non affiché"]],
    default: "NOK",
    maxLength: 4,
  })
  public status: string;


  @DSDefaultField({
    required: false,
    name: "num_products",
    verboseName: "Nombre de produits"
  })
  public num_products: number;
  @DSDefaultField({
    required: false,
    name: "num_producers",
    verboseName: "Nombre de producteurs"
  })
  public num_producers: number;

  public producers: number[];
  public products: number[];

}

@Injectable({providedIn: "root"})
export class BaseLabelService<T extends BaseLabel> extends DSRestCollection<T> {
  protected _labels: T[];
  protected _labels$: ReplaySubject<{ [index: number]: T }>;
  protected _cache: { [index: number]: T } = {};
  protected _cstt: { value: number, desc: string }[];

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }

  public get constants$(): Observable<IBsacApiConstants> {
    if (this._cstt) {
      return of(this._cstt);
    }
    return this.prefetch().pipe(
      map(() => {
        return this._cstt;
      })
    );
  }

  public prefetch(): Observable<{ [index: number]: T }> {
    if (this._labels$ === undefined) {
      this._labels$ = new ReplaySubject(1);
      return this.queryset.all().pipe(
        first(),
        map((labels: any) => {
          this._cache = {};
          this._labels = labels.items;
          for (const l of labels.items) {
            this._cache[l.id] = l;
          }
          this._labels$.next(this._cache);
          return this._cache;
        }));
    } else {
      return this._labels$.asObservable().pipe(first());
    }
  }

  public getCached(id: number): Observable<T> {
    return this.prefetch().pipe(map(() => {
      return this._cache[id];
    }));
  }

  public getCachedValues(): Observable<{ value: number, desc: string }[]> {
    const out: { value: number, desc: string }[]= [];
    if (this._labels !== undefined) {
      for (const l of this._labels) {
        out.push({value: l.id, desc: l.name});
      }
      return of(out);
    } else {
      this._labels = [];
      return this.prefetch().pipe(map(() => {
        for (const l of this._labels) {
          out.push({value: l.id, desc: l.name});
        }
        return out;
      }));
    }
  }
}
