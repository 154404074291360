import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Resto} from "../../structure/resto/resto.service";
import {Storage} from "../../structure/storage/storage.service";
import {Client} from "../../structure/client/client.service";

export class VivalyaBill extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSCharField({
    name: "hash",
    verboseName: "Hash",
    required: false,
    helpText: "Hash",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public hash!: string;


  @DSForeignKeyField({
    name: "storage",
    verboseName: "ID Dépôt",
    required: false,
    helpText: "",
    relatedModel: "structure.Storage",
    remoteField: "id",
    relatedName: "vivalyabill_set"
  })
  public storage!: number;
  public storage_details!: Storage;

  @DSForeignKeyField({
    name: "resto",
    verboseName: "ID Resto",
    required: false,
    helpText: "",
    relatedModel: "structure.Resto",
    remoteField: "id",
    relatedName: "vivalyabill_set"
  })
  public resto!: number;
  public resto_details!: Resto;

  @DSForeignKeyField({
    name: "client",
    verboseName: "ID GC",
    required: false,
    helpText: "",
    relatedModel: "structure.Client",
    remoteField: "id",
    relatedName: "vivalyabill_set"
  })
  public client!: number;
  public client_details!: Client;



  @DSCharField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public code!: string;

  @DSDateField({
    name: "billdate",
    verboseName: "Date facturation",
    required: false,
    helpText: "",
    choices: []
  })
  public billdate!: Date;

  // Precalculated fields
  @DSDecimalField({
    name: "brut_amount",
    verboseName: "Montant brut",
    required: false,
    helpText: "Montant brut",
    default: 0,
    factor: 10000,
    choices: []
  })
  public brut_amount!: number;

  @DSDecimalField({
    name: "net_amount",
    verboseName: "Montant net",
    required: false,
    helpText: "Montant net",
    default: 0,
    factor: 10000,
    choices: []
  })
  public net_amount!: number;

  @DSDecimalField({
    name: "vat_amount",
    verboseName: "Montant taxes",
    required: false,
    helpText: "Montant taxes",
    default: 0,
    factor: 10000,
    choices: []
  })
  public vat_amount!: number;

  @DSDecimalField({
    name: "wvat_amount",
    verboseName: "Montant TTC",
    required: false,
    helpText: "Montant TTC",
    default: 0,
    factor: 10000,
    choices: []
  })
  public wvat_amount!: number;


  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;

  @DSTextField({
    name: "comments",
    verboseName: "Commentaires (interne)",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 10000
  })
  public comments!: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  // Flags mixin
  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags!: any;


}

@Injectable({
  providedIn: "root"
})
export class VivalyaBillService extends DSRestCollection<VivalyaBill> {
  public adapter_config = {basePath: "/api/v2/vivalyabills"};
  public model = VivalyaBill;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
