import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {ProductVariant, ProductVariantService} from "./productvariant.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProductVariantResolve implements Resolve<ProductVariant> {
  constructor(private _bs: ProductVariantService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<ProductVariant> {
    return this._bs.get(+route.params["productvariantId"]);
  }
}
