import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {BarSeriesOption, EChartsOption, LineSeriesOption, ScatterSeriesOption, SeriesOption} from "echarts";
import {VV_SCHEMES, VVFL_SCHEME_1} from "../schemes";
import {TimeSeriesChartDataset} from "../formats";


@Component({
  selector: "lvadg-time-series-chart",
  templateUrl: "./time-series-chart.component.pug",
  styleUrls: ["./time-series-chart.component.sass"]
})
export class TimeSeriesChartComponent implements OnInit, OnChanges {
  public options!: EChartsOption;
  @Input() public data!: TimeSeriesChartDataset;
  @Input() public type: "scatter" | "line" | "bar" = "scatter";
  @Input() public colors: "sea" | "fl" | "default" = "fl";
  public scheme: string[]|undefined = VVFL_SCHEME_1;

  constructor() {
  }

  public ngOnInit(): void {
    if (this.data) {
      this.setOptions();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {

    if (this.data) {
      this.setOptions();
    }
  }

  public setOptions(): void {
    this.options = {
      title: {
        text: this.data.title,
        subtext: this.data.subtitle,
        top: 10,
        left: "center",
        textAlign: "center",
        show: !!this.data.title
      },
      tooltip: {
        show: true,
        confine: true,
        formatter: (params: any) => { // FIXME: any usage
          const v = params.value[params.encode.y[0]];
          let enc = params.marker + " " + params.seriesName + "<br/>";
          const dt: Date = params.value[params.encode.x[0]];
          enc += `${dt.getUTCDate()}/${dt.getUTCMonth() + 1}/${dt.getFullYear()} : `;
          if (this.data.axis.value.unit === "€") {
            enc += new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(v);
          } else {
            enc += new Intl.NumberFormat("fr-FR", {style: "decimal", maximumFractionDigits: 2}).format(v);
            if (this.data.axis.value.unit) {
              enc += " " + this.data.axis.value.unit;
            }
          }
          return enc;
        }
      },
      dataZoom: {
        type: "inside",
        xAxisIndex: 0
      },
      xAxis: {
        type: "time"
      },
      yAxis: {
        type: "value",
        name: this.data.axis.value.title + (this.data.axis.value.unit ? ` (${this.data.axis.value.unit})` : ""),
        nameLocation: "middle",
        nameRotate: 90,
        nameGap: 40,
        axisLabel: {
          formatter: (value: number, index: number) => {
            if (this.data.axis.value.unit === "€") {
              return new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
            } else {
              return new Intl.NumberFormat("fr-FR", {style: "decimal", maximumFractionDigits: 2}).format(value);
            }
          }
        }
      },
      legend: {
        show: this.data.legend,
        align: "auto",
        orient: "vertical",
        top: "top",
        right: 10,
        width: 200,
        type: "scroll",
      },
      series: [],
      grid: {
        left: 70,
        top: 50,
        bottom: 50,
        right: this.data.legend ? 260 : 50
      }
    };

    if (this.colors !== "default") {
      this.options.color = VV_SCHEMES[this.colors];
    }
    if (this.data.data) {
      this.options.dataset = {source: this.data.data};
      for (let i = 1; i < this.data.data[0].length; i++) {
        const ser = (this.options.series as SeriesOption[]);
        if (this.type === "scatter") {
          ser.push({
            type: "scatter",
            symbol: "circle",
            symbolSize: 8,
            name: this.data.data[0][i] as string,
            encode: {
              x: 0,
              y: i
            }
          } as ScatterSeriesOption);
        } else if (this.type === "bar") {
          const br: BarSeriesOption = {
            type: "bar",
            name: this.data.data[0][i] as string,
            encode: {
              x: 0,
              y: i
            }
          };
          if (this.data.stacked) {
            br.stack = "stack";
          }
          ser.push(br);
        } else {
          ser.push({
            type: "line",
            symbol: "circle",
            symbolSize: 8,
            name: this.data.data[0][i] as string,
            encode: {
              x: 0,
              y: i
            }
          } as LineSeriesOption);
        }
      }
    }

    if (this.data.series) {
      const ser = (this.options.series as SeriesOption[]);
      for (const s of this.data.series) {
        ser.push(s);
      }
    }

  }

}
