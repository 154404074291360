import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "lvadg-modellist-display",
  templateUrl: "./modellist-display.component.pug",
  styleUrls: ["./modellist-display.component.sass"]
})
export class ModellistDisplayComponent implements OnInit {
  @Input() public mode: "horizontal" | "vertical" | "vertical2res" | "horizontal2res" = "vertical";

  constructor() {
  }

  ngOnInit() {
  }

}
