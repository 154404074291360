import {NgModule} from "@angular/core";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ContactManageFormComponent} from "./contact-manage-form/contact-manage-form.component";
import {ContactListComponent} from "./contact-list/contact-list.component";
import {ContactManageComponent} from "./contact-manage/contact-manage.component";
import {ContactXlsxImportComponent} from "./contact-xlsx-import/contact-xlsx-import.component";
import {GenericUploadModule} from "../../document/upload/upload.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    GenericUploadModule
  ],
  providers: [],
  declarations: [
    ContactManageFormComponent,
    ContactListComponent,
    ContactManageComponent,
    ContactXlsxImportComponent
  ],
  exports: [
    ContactManageFormComponent,
    ContactListComponent,
    ContactManageComponent,
    ContactXlsxImportComponent
  ],
})
export class ContactModule {
}
