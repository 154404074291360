import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {Folder} from "../folder.service";
import {FolderTree, FolderTreeService} from "../folder-tree.service";
import {firstValueFrom} from "rxjs";


@Component({
  selector: "lvadg-folder-properties",
  templateUrl: "./folder-properties.component.pug",
  styleUrls: ["./folder-properties.component.sass"]
})
export class FolderPropertiesComponent implements OnInit, OnChanges {

  @Input() public folder!: Folder;
  @Input() public treeName = "default";
  @Input() public mode = "admin";
  @Output() public closed = new EventEmitter<void>();
  public curfolder!: Folder|null;
  public moveAction = false;


  @Input() public tree!: FolderTree;

  constructor(private _trees: FolderTreeService) {
  }

  ngOnInit() {
    if (!this.tree) {
      this.tree = this._trees.load(this.treeName);
    }
  }

  public delete(): void {
    this.tree.removeFolder(this.folder);
  }

  public async move(dest: Folder): Promise<void> {
    await this.tree.moveFolder(this.folder, dest);
    this.moveAction = false;
  }

  public async ngOnChanges(): Promise<void> {
    this.moveAction = false;
    if (!this.tree) {
      this.ngOnInit();
    }
    if (this.folder) {
      this.curfolder = await firstValueFrom(this.tree.getFolder(this.folder.id));
    } else {
      this.curfolder = await firstValueFrom(this.tree.getFolder(null));
    }
  }

  public async checkChanged(event: any): Promise<void> {
    if (this.curfolder) {
      await firstValueFrom(this.curfolder.action("change_zone", {
        method: "POST",
        body: {}
      }));
    }
  }
}
