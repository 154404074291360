import {Component, Input} from "@angular/core";
import {Client, ClientService} from "../../../structure/client/client.service";
import {Resto, RestoService} from "../../../structure/resto/resto.service";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {VivalyaBill} from "../../bill/bill.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {Article, ArticleService} from "../../../catalog/article/article.service";
import {Storage, StorageService} from "../../../structure/storage/storage.service";
import {Member, MemberService} from "../../../structure/member/member.service";
import {TarifType, TarifTypeService} from "../../../tarif/tariftype/tariftype.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {VivalyaBillItem, VivalyaBillItemService} from "../billitem.service";
import {ExternalArticle} from "../../../catalog/externalarticle/externalarticle.service";
import {ArticleFamily} from "../../../catalog/family/article-family.service";

@Component({
  selector: "lvadg-billitem-list",
  templateUrl: "./billitem-list.component.pug",
  styleUrls: ["./billitem-list.component.sass"]
})
export class BillItemListComponent extends ModelListBaseComponent<VivalyaBillItem, VivalyaBillItemService> {

  @Input() public client!: Client;
  @Input() public resto!: Resto;
  @Input() public article!: Article;
  @Input() public adhExternalArticle!: ExternalArticle;
  @Input() public gcExternalArticle!: ExternalArticle;
  @Input() public colExternalArticle!: ExternalArticle;
  @Input() public billitem_detail_route!: RouteV3<VivalyaBillItem>;
  @Input() public bill_detail_route!: RouteV3<VivalyaBill>;
  @Input() public storage_detail_route!: RouteV3<Storage>;
  @Input() public resto_detail_route!: RouteV3<Resto>;
  @Input() public client_detail_route!: RouteV3<Client>;
  @Input() public article_detail_route!: RouteV3<Article>;
  @Input() public articlefamily_detail_route!: RouteV3<ArticleFamily>;
  @Input() public externalarticle_detail_route!: RouteV3<ExternalArticle>;

  public clients$!: DSRestQueryset<Client>;
  public articles$!: DSRestQueryset<Article>;
  public storages$!: DSRestQueryset<Storage>;
  public members$!: DSRestQueryset<Member>;
  public tariftypes$!: DSRestQueryset<TarifType>;
  public restos$!: DSRestQueryset<Resto>;
  public articlesFilter = {bill_lines_assoc: "ASS", fields: "id,libelle"};

  constructor(_list: BsacModelListProvider,
              _items: VivalyaBillItemService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              private _articles: ArticleService,
              private _tariftypes: TarifTypeService,
              private _storages: StorageService,
              private _members: MemberService,
              private _modal: NgbModal,
              private _clients: ClientService,
              private _restos: RestoService) {
    super(_list, _items, _msgs, routes);
  }

  public getDetailLink(model: VivalyaBillItem): (string | number)[] {
    return this.billitem_detail_route.route({billItem: model});
  }

  public async preNgOnInit(): Promise<void> {
    if (this.article) {
      this.filter.article = this.article.id.toString();
    }
    if (this.adhExternalArticle) {
      this.filter.adh_article = this.adhExternalArticle.id.toString();
    }
    if (this.gcExternalArticle) {
      this.filter.gc_article = this.gcExternalArticle.id.toString();
    }
    if (this.colExternalArticle) {
      this.filter.col_article = this.colExternalArticle.id.toString();
    }

  }

  public async postNgOnInit(): Promise<void> {
    this.clients$ = this._clients.queryset;
    this.members$ = this._members.queryset;
    this.storages$ = this._storages.queryset;
    this.articles$ = this._articles.queryset;
    this.tariftypes$ = this._tariftypes.queryset;
    this.restos$ = this._restos.queryset;
  }

}
