import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

import {CommonBaseComponent} from "../../common-base.component";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {TarifType} from "../../../../lib/models/tarif/tariftype/tariftype.service";
import {AuthService} from "../../../../lib/models/user/auth.service";

export interface TarifTypeListViewV3Params extends BaseRouteV3Params {
  ttype: "gen" | "cli";
  tariftype_detail_route: RouteConfigItemV3;
}


@Component({
  selector: "lvadg-tariftype-list-view",
  templateUrl: "./tariftype-list-view.component.pug",
  styleUrls: ["./tariftype-list-view.component.sass"]
})
export class TarifTypeListViewV3Component extends CommonBaseComponent {
  public create = false;
  // Current type data and filters
  public ttype: "gen" | "cli" = "gen";
  public filter: { [index: string]: string } = {};
  public default_fields!: string[];
  public tariftype_detail_route!: RouteV3<TarifType>;

  constructor(routes: RoutesService,
              router: Router,
              route: ActivatedRoute,
              public auth: AuthService) {
    super(routes, router, route);
  }


  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as TarifTypeListViewV3Params;
    this.ttype = data.ttype;
    this.tariftype_detail_route = new RouteV3(data.tariftype_detail_route, this);
    this.filter = {type: this.ttype.toUpperCase()};
    if (this.ttype === "gen") {
      this.default_fields = [
        "id", "name", "read_groups", "write_groups", "admin_groups",
        "members", "storages", "base_interval", "flags",
      ];
    } else {
      this.default_fields = [
        "id", "name", "read_groups", "write_groups", "admin_groups",
        "client", "member", "storage", "base_interval", "flags"
      ];
    }
    return super.preNgOnInit();
  }

  public onCreated(tg: TarifType) {
    this.router.navigate(this.tariftype_detail_route.route({tarifType: tg}));
  }
}
