/**
 * GeoCommune : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @changed 2018-01-05-04:26:49
 * @date 2018-01-05-04:25:03
 * @copyright (c) 2017 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export class GeoCommune extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSCharField({
    required: true,
    name: "insee",
    helpText: "",
    verboseName: "Code Insee",
    choices: [],
    default: null,
    maxLength: 10,
  })
  public insee!: string;

  @DSCharField({
    required: true,
    name: "name",
    helpText: "",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public name!: string;

  @DSCharField({
    required: true,
    name: "postcode",
    helpText: "",
    verboseName: "Code postal",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public postcode!: string;

  @DSCharField({
    required: true,
    name: "postname",
    helpText: "",
    verboseName: "Nom postal",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public postname!: string;

  @DSCharField({
    required: false,
    name: "altname",
    helpText: "",
    verboseName: "Variante nom",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public altname!: string;

  @DSDefaultField({
    required: false,
    name: "position",
    helpText: "",
    verboseName: "Position",
    choices: [],
    default: null,
  })
  public position!: any;

  @DSForeignKeyField({
    required: false,
    name: "departement",
    helpText: "",
    verboseName: "Département",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "GeoDepartement",
    remoteField: "geocommune",
  })
  public departement!: number;
  public departement_details!: { code: string, name: string, id: number };

  @DSForeignKeyField({
    name: "country",
    verboseName: "Pays",
    required: false,
    helpText: "Pays",
    relatedModel: "customdata.Country",
    remoteField: "id",
    relatedName: "commune"
  })
  public country!: number;
  public country_details!: { id: number; code: string; name: string };


  public latitude!: number;
  public longitude!: number;


  public get fullname(): string {
    return `${this.postcode} - ${this.postname} (${this.altname})`;
  }

}

@Injectable({providedIn: "root"})
export class GeoCommuneService extends DSRestCollection<GeoCommune> {
  public adapter_config = {basePath: "/api/v2/geocommunes"};
  public model = GeoCommune;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
