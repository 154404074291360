import {NgModule} from "@angular/core";
import {RelationListViewV3Component} from "./relation-list-view/relation-list-view.component";
import {VIEWS_COMMON_COMMON_MODULES} from "../../views-common-modules";
import {RelationModule} from "../../../../lib/models/structure/relation/relation.module";


@NgModule({
  declarations: [
    RelationListViewV3Component
  ],
  exports: [
    RelationListViewV3Component
  ],
  imports: [
    ...VIEWS_COMMON_COMMON_MODULES,
    RelationModule
  ]
})
export class ViewsRelationsModule {
}

export * from "./relation-list-view/relation-list-view.component";
