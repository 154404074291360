
<ng-template #releditor let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Edition de la relation (WIP)</h4>
    <button class="btn-close" type="button" aria-label="Fermer" (click)="offcanvas.dismiss('x')"></button>
  </div>
  <div class="offcanvas-body">
    <bsac-select-related [source]="atts$" [filter]="{tarif_type: this.ttrelation.src}" descfield="name" (selected)="updateRelation($event)" label="Sélectionnez un article du tarif"></bsac-select-related>
  </div>
  <div class="offcanvas-footer">
    <button class="btn btn-light" type="button" (click)="offcanvas.close('b')">Fermer</button>
  </div>
</ng-template>
<ng-container *ngIf="(trel.type==='REF' || trel.type==='LOC' || trel.type==='NFO') &amp;&amp; !trel.stoId"><span *ngIf="last">
    <ng-container *ngIf="trel.oprice">
      <ng-container *ngIf="trel.gsprice">
        <!-- Display oprice-->
        <ng-container *ngIf="last.oprice===null">NC</ng-container><span class="me-2" *ngIf="last.oprice!==null" [class.text-success]="at.price &amp;&amp; at.price &gt; last.oprice" [class.text-danger]="at.price &amp;&amp; at.price &lt; last.oprice" [title]="last.libelle + ' : prix original tous dépôts au '+(last.date | date:'shortDate')">{{last.oprice/10000 | currency:'EUR'}}<small class="ms-2" *ngIf="trel.variation &amp;&amp; at.price &amp;&amp; at.price != last.oprice">{{  (at.price - last.oprice) / (at.price) | percent }}</small></span>
      </ng-container>
      <ng-container *ngIf="!trel.gsprice">
        <ng-container *ngIf="last.soprice===null">NC</ng-container><span class="me-2" *ngIf="last.soprice!==null" [class.text-success]="at.price &amp;&amp; at.price &gt; last.soprice" [class.text-danger]="at.price &amp;&amp; at.price &lt; last.soprice" [title]="last.libelle + ' : prix original dépôts affichés au '+(last.date | date:'shortDate')">{{last.soprice/10000 | currency:'EUR'}}<small class="ms-2" *ngIf="trel.variation &amp;&amp; at.price &amp;&amp; at.price != last.soprice">{{  (at.price - last.soprice) / (at.price) | percent }}</small></span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!trel.oprice">
      <ng-container *ngIf="trel.gsprice">
        <!-- Display cprice-->
        <ng-container *ngIf="last.cprice===null">NC</ng-container><span class="me-2" *ngIf="last.cprice!==null" [class.text-success]="at.price &amp;&amp; at.price &gt; last.cprice" [class.text-danger]="at.price &amp;&amp; at.price &lt; last.cprice" [title]="last.libelle + ' : prix calculé tous dépôts au '+(last.date | date:'shortDate')">{{last.cprice/10000 | currency:'EUR'}}<small class="ms-2" *ngIf="trel.variation &amp;&amp; at.price &amp;&amp; at.price != last.cprice">{{  (at.price - last.cprice) / (at.price) | percent }}</small></span>
      </ng-container>
      <ng-container *ngIf="!trel.gsprice">
        <!-- Display sprice-->
        <ng-container *ngIf="last.sprice===null">NC</ng-container><span class="me-2" *ngIf="last.sprice!==null" [class.text-success]="at.price &amp;&amp; at.price &gt; last.sprice" [class.text-danger]="at.price &amp;&amp; at.price &lt; last.sprice" [title]="last.libelle + ' : prix calculé dépôts affichés au ' + (last.date | date:'shortDate')">{{last.sprice/10000 | currency:'EUR'}}<small class="ms-2" *ngIf="trel.variation &amp;&amp; at.price &amp;&amp; at.price != last.sprice">{{  (at.price - last.sprice) / (at.price) | percent }}</small></span>
      </ng-container>
    </ng-container></span></ng-container>
<ng-container *ngIf="trel.type==='NFO' &amp;&amp; trel.stoId"><span *ngIf="last"><span *ngIf="last">
      <ng-container *ngIf="trel.oprice">
        <!-- Display oprice-->
        <ng-container *ngIf="last.oprice===null">NC</ng-container><span class="me-2" *ngIf="last.oprice!==null" [class.text-success]="at.price &amp;&amp; at.price &gt; last.oprice" [class.text-danger]="at.price &amp;&amp; at.price &lt; last.oprice" [title]="last.libelle + ' : prix original tous dépôts au '+(last.date | date:'shortDate')">{{last.oprice/10000 | currency:'EUR'}}<small class="ms-2" *ngIf="trel.variation &amp;&amp; at.price &amp;&amp; at.price != last.oprice">{{  (at.price - last.oprice) / (at.price) | percent }}</small></span>
      </ng-container>
      <ng-container *ngIf="!trel.oprice">
        <!-- Display cprice-->
        <ng-container *ngIf="last.cprice===null">NC</ng-container><span class="me-2" *ngIf="last.cprice!==null" [class.text-success]="at.price &amp;&amp; at.price &gt; last.cprice" [class.text-danger]="at.price &amp;&amp; at.price &lt; last.cprice" [title]="last.libelle + ' : prix calculé tous dépôts au '+(last.date | date:'shortDate')">{{last.cprice/10000 | currency:'EUR'}}<small class="ms-2" *ngIf="trel.variation &amp;&amp; at.price &amp;&amp; at.price != last.cprice">{{  (at.price - last.cprice) / (at.price) | percent }}</small></span>
      </ng-container></span></span></ng-container>
<ng-container *ngIf="trel.type==='LOC' &amp;&amp; trel.stoId"><span class="text-center" *ngIf="last" [title]="last.date">
    <ng-container *ngIf="last.cprice===null"><i class="fa fa-crosshairs"></i></ng-container>
    <ng-container *ngIf="last.cprice!==null"><i class="fa fa-check text-success" [title]="(last.date | date:'shortDate') + ' ' + (last.cprice/10000 | currency:'EUR') + ' ' + last.libelle"></i></ng-container></span></ng-container>
<ng-container *ngIf="!trel.stoId &amp;&amp; trel.libelle">
  <!-- div(role="button", (click)="editRelation(releditor)")-->
  <ng-container *ngIf="last?.libelle">{{last.libelle}}</ng-container>
  <ng-container *ngIf="!last?.libelle">--</ng-container>
</ng-container>