import {Injectable} from "@angular/core";
import {UrlSegment} from "@angular/router";


interface ITabStoredMem {
  name: string;
  value: string;
}

export class NavDriver {
  private lastActive: Date;


  constructor(private _parent: TabMemoryService, public name: string, public defaults: string, private save: boolean) {
    this._current = this.defaults;

    const value = this._parent.load(this.name);
    if (value !== null) {
      this._current = value;
    }
  }

  private _current: string;

  public get current(): string {
    return this._current;
  }

  public set current(value: string) {
    this._current = value;
    this.lastActive = new Date();
    this._parent.save();
  }

  public get value(): ITabStoredMem {
    return {name: this.name, value: this._current};
  }

}


@Injectable({
  providedIn: "root"
})
export class TabMemoryService {

  private tabMem: { [index: string]: NavDriver } = {};


  constructor() {
  }

  private static targetToString(target: (string | number | UrlSegment)[]): string {
    let out = "";
    for (const t of target) {
      out += `/${t}`;
    }
    return out;
  }

  public memFor(target: (string | number | UrlSegment)[], defaults: string, save: boolean = true): NavDriver {
    const tname = TabMemoryService.targetToString(target);
    if (this.tabMem[tname] === undefined) {
      this.tabMem[tname] = new NavDriver(this, tname, defaults, save);
    }
    return this.tabMem[tname];
  }

  public save() {
    const tosave: { [index: string]: ITabStoredMem } = {};
    for (const tn of Object.keys(this.tabMem)) {
      tosave[tn] = this.tabMem[tn].value;
    }
    localStorage.setItem("tabmem", JSON.stringify(tosave));
  }

  public load(name: string): string | null {
    const tm = localStorage.getItem("tabmem");
    if (tm) {
      const tmd = JSON.parse(tm);
      if (tmd && tmd[name] && tmd[name].value) {
        return tmd[name].value;
      }
    }
    return null;
  }

}
