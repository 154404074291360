
<bsac-breadcrumb></bsac-breadcrumb>
<h1>Détail article tarif</h1>
<ng-container *ngIf="ready">
  <nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
    <ng-container ngbNavItem="manage"><a ngbNavLink>Gestion article</a>
      <ng-template ngbNavContent>
        <div class="tab-content px-3">
          <lvadg-articletariftemplate-manage [att]="articleTarifTemplate" [eaclient_detail_route]="eaclient_detail_route" [tarifType]="tarifType" (deleted)="onDeleted()"></lvadg-articletariftemplate-manage>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="stats"><a ngbNavLink>Statistiques prix</a>
      <ng-template ngbNavContent>
        <div class="tab-content px-3">
          <lvadg-articletariftemplate-stats [att]="articleTarifTemplate"></lvadg-articletariftemplate-stats>
        </div>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</ng-container>