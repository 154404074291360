
<div class="editor">
  <div class="form">
    <form [formGroup]="form" *ngIf="form &amp;&amp; ['DOC', 'FOL', 'FIL'].indexOf(key.type)===-1">
      <ng-container *ngIf="key.type=='INT'">
        <input class="form-control" type="number" formControlName="value" (keyup.enter)="save()">
      </ng-container>
      <ng-container *ngIf="key.type=='CUR'">
        <input class="form-control" type="number" formControlName="value" (keyup.enter)="save()">
      </ng-container>
      <ng-container *ngIf="key.type=='DEC'">
        <input class="form-control" type="number" formControlName="value" (keyup.enter)="save()">
      </ng-container>
      <ng-container *ngIf="key.type=='STX'">
        <input class="form-control" type="text" formControlName="value" (keyup.enter)="save()">
      </ng-container>
      <ng-container *ngIf="key.type=='LTX'">
        <textarea class="form-control" formControlName="value" (keyup.ctrl.enter)="save()"></textarea>
      </ng-container>
      <ng-container *ngIf="key.type=='DTE'">
        <input class="form-control" type="date" formControlName="value" (keyup.enter)="save()">
      </ng-container>
      <ng-container *ngIf="key.type=='DTH'">
        <input class="form-control" type="datetime" formControlName="value" (keyup.enter)="save()">
      </ng-container>
      <ng-container *ngIf="key.type=='CH1'">
        <select class="form-select" formControlName="value">
          <option *ngFor="let o of key.choices_details" [ngValue]="o.id">{{o.name}}</option>
        </select>
      </ng-container>
    </form>
    <ng-container *ngIf="key.type=='DOC'">
      <lvadg-customdata-value-edit-document [key]="key" [value]="value?.document_details" (save)="save($event)" (clear)="removed.emit()"></lvadg-customdata-value-edit-document>
    </ng-container>
    <ng-container *ngIf="key.type=='FOL'">
      <lvadg-customdata-value-edit-folder [key]="key" [value]="value?.folder_details" (save)="save($event)" (clear)="removed.emit()"></lvadg-customdata-value-edit-folder>
    </ng-container>
    <ng-container *ngIf="key.type=='FIL'">
      <lvadg-customdata-value-edit-file></lvadg-customdata-value-edit-file>
    </ng-container>
  </div><i class="remove text-danger fa fa-trash m-2 ms-4 pointer" (click)="removed.emit()"></i>
</div>