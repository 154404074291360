import {Component, Input, OnInit} from "@angular/core";
import {Document} from "../document.service";
import {FolderTree} from "../../folder/folder-tree.service";

export const MIMEICONS : {[index: string]: string} = {
  "image/jpg": "fa-file-image",
  "image/png": "fa-file-image",
  "application/pdf": "fa-file-pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "fa-file-excel",
  "application/msword": "fa-file-word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "fa-file-word",
  "application/vnd.ms-excel": "fa-file-excel",
  "application/vnd.ms-powerpoint": "fa-file-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "fa-file-powerpoint",
  "text/csv": "fa-file-spreadsheet",
  "text/plain": "fa-file-alt"
};


@Component({
  selector: "lvadg-document-icon",
  templateUrl: "./document-icon.component.pug",
  styleUrls: ["./document-icon.component.sass"]
})
export class DocumentIconComponent implements OnInit {
  @Input() public document!: Document;
  @Input() public tree!: FolderTree;
  @Input() public mode: "default"|"extonly" = "default";

  constructor() {
  }

  public ngOnInit(): void {
  }

  public getFileType(): string {
    if (MIMEICONS[this.document.mimetype]) {
      return MIMEICONS[this.document.mimetype];
    }
    return "fa-file";
  }
}
