/**
 * Usage : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>

 * @date 2018-08-23-12:30:13
 * @copyright (c) 2017 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class Usage extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSCharField({
    required: false,
    name: "url",
    helpText: "",
    verboseName: "url",
    choices: [],
    default: null,
    maxLength: 1024,
  })
  public url!: string;

  @DSCharField({
    required: false,
    name: "act",
    helpText: "",
    verboseName: "act",
    choices: [],
    default: null,
    maxLength: 50,
  })
  public act!: string;

  @DSCharField({
    required: false,
    name: "method",
    helpText: "",
    verboseName: "method",
    choices: [],
    default: null,
    maxLength: 10,
  })
  public method!: string;

  @DSTextField({
    required: false,
    name: "ua",
    helpText: "",
    verboseName: "ua",
    choices: [],
    default: null
  })
  public ua!: string;

  @DSCharField({
    required: false,
    name: "referrer",
    helpText: "",
    verboseName: "referrer",
    choices: [],
    default: null,
    maxLength: 1024,
  })
  public referrer!: string;

  @DSDateTimeField({
    required: false,
    name: "timestamp",
    helpText: "",
    verboseName: "timestamp",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public timestamp!: Date;

  @DSForeignKeyField({
    required: false,
    name: "user",
    helpText: "",
    verboseName: "user",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Person",
    remoteField: "usage",
  })
  public user!: number;

  @DSForeignKeyField({
    required: false,
    name: "site",
    helpText: "",
    verboseName: "site",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Site",
    remoteField: "usage",
  })
  public site!: number;

  @DSCharField({
    required: false,
    name: "ttype",
    helpText: "",
    verboseName: "ttype",
    choices: [],
    default: null,
    maxLength: 5,
  })
  public ttype!: string;

  @DSCharField({
    required: false,
    name: "ip",
    helpText: "",
    verboseName: "ip",
    choices: [],
    default: null,
    maxLength: 255,
  })
  public ip!: string;

  @DSDefaultField({
    required: false,
    name: "data",
    helpText: "",
    verboseName: "data",
    choices: [],
    default: [],
  })
  public data!: any;

}

@Injectable({providedIn: "root"})
export class UsageService extends DSRestCollection<Usage> {
  public adapter_config = {basePath: "/api/v2/usages"};
  public model = Usage;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
