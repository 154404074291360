
<div class="row" *ngIf="sender &amp;&amp; loaded">
  <div class="col-sm-4">
    <button class="btn btn-block w-100 btn-outline-primary mb-2" (click)="send()" *ngIf="!sending">Envoyer aux destinataires sélectionnés</button>
    <button class="btn btn-block w-100 btn-outline-primary mb-2" *ngIf="sending"><i class="fa fa-spinner fa-spin me-2"></i>Envoi aux destinataires sélectionnés en cours</button>
    <div class="progress" *ngIf="jobs.length">
      <ng-container *ngFor="let job of jobs">
        <ngb-progressbar [showValue]="true" type="info" [value]="job.progress" [max]="job.total" [style.flex-grow]="job.total"><small class="text-info" *ngIf="job.status !== 'done'">Impression et envoi en cours, merci de patienter...</small></ngb-progressbar>
      </ng-container>
    </div>
    <lvadg-modelsend-destinations [model]="sender" [member]="$any(offer.member_details)" [storage]="$any(offer.storage_details)" [client]="$any(offer.client_details)" (preview)="setPage($event)" [updates]="statusUpdates" [reload]="modelReload" (destinations)="updatedDestination($event)"></lvadg-modelsend-destinations>
  </div>
  <div class="col-sm-4">
    <button class="btn btn-block w-100 btn-outline-primary mb-2" (click)="setPage(null)">Modifier le modèle de base</button>
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel title="En-tête">
        <ng-template ngbPanelContent>
          <h4>Logos et arrière plan</h4>
          <div class="row">
            <div class="col-sm-12">
              <lvadg-commfield-selimgcustomimg [params]="params$" part="header" name="background" [fields$]="fields$"></lvadg-commfield-selimgcustomimg>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel title="Textes libres">
        <ng-template ngbPanelContent>
          <h3>Textes libres</h3>
          <div class="row">
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="freetexts" name="intro" mode="rich" [title]="false"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="freetexts" name="conclusion" mode="rich" [title]="false"></lvadg-commfield-textfield>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel title="Textes prédéfinis">
        <ng-template ngbPanelContent>
          <h3>Textes</h3>
          <div class="row">
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block1"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block2"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block3"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block4"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block5"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="texts" name="block6"></lvadg-commfield-textfield>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel title="Pied de mail">
        <ng-template ngbPanelContent>
          <h4>Logos</h4>
          <div class="row">
            <div class="col-sm-12">
              <lvadg-commfield-textfield [params]="params$" part="footer" name="signature" mode="rich"></lvadg-commfield-textfield>
            </div>
            <div class="col-sm-6">
              <lvadg-commfield-seltxtcustomimg [params]="params$" part="footer" name="logo1" [fields$]="fields$"></lvadg-commfield-seltxtcustomimg>
            </div>
            <!--.col-sm-6-->
            <!--  lvadg-commfield-seltxtcustomimg([params]="params$", part="footer",-->
            <!--    name="logo2", [fields$]="fields$")-->
            <div class="col-sm-6">
              <lvadg-commfield-seltxtcustomimg [params]="params$" part="footer" name="logo3" [fields$]="fields$"></lvadg-commfield-seltxtcustomimg>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="col-sm-4">
    <lvadg-modelcomm-preview [model]="sender" [pageChanged]="curpage$" type="email" [pages]="pages" emptyName="Modèle de base"></lvadg-modelcomm-preview>
  </div>
</div>