<!DOCTYPE html>
<div class="card mb-3" *ngIf="importExcel">
  <div class="card-header"><i class="fa fa-file-excel me-2"></i>Import / match excel</div>
  <div class="card-body">
    <lvadg-externalarticle-load-xlsx></lvadg-externalarticle-load-xlsx>
  </div>
</div>
<ng-template #artmod let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Recherche article</h4>
    <button class="btn-close" (click)="modal.dismiss('x')"></button>
  </div>
  <div class="modal-body" *ngIf="current"><code>{{current.libelle}}</code>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Code client</th>
          <th>Gamme</th>
          <th>Famille</th>
          <th>Produit</th>
          <th>Categorie</th>
          <th>Calibre</th>
          <th>Origine</th>
          <th>Unité fact.</th>
          <th>Unité comm.</th>
          <th>Ratio uc/uf</th>
          <th>Unité cond.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{current.code}}</td>
          <td>{{current.gamme}}</td>
          <td>{{current.sgamme}}</td>
          <td>{{current.libelle}}</td>
          <td>{{current.categorie}}</td>
          <td>{{current.calibre}}</td>
          <td>{{current.origine}}</td>
          <td>{{current.uf}}</td>
          <td>{{current.uc}}</td>
          <td>{{current.ucuf}}</td>
          <td>{{current.ucond}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-body">
    <lvadg-article-list name="articleexternallist-associate" [in_tarif]="tarifType" [reuseList]="false" [default_fields]="catalog_default_fields" [default_filters]="catalog_default_filters" [article_detail_route]="article_detail_route" [actions]="catalog_actions" (action)="modal.close($event)"></lvadg-article-list>
  </div>
</ng-template>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'clients'; desc: 'Recherche par client(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="clients" desctpl="&lt;% if(obj.parent_details) { %&gt;&lt;%= obj.parent_details.name %&gt; / &lt;%= obj.name %&gt; &lt;% } else { %&gt;&lt;%= obj.name %&gt; &lt;% } %&gt;" [queryset]="clients$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'clients_from_sales'; desc: 'Recherche par client(s) via ventes'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="clients_from_sales" desctpl="&lt;% if(obj.parent_details) { %&gt;&lt;%= obj.parent_details.name %&gt; / &lt;%= obj.name %&gt; &lt;% } else { %&gt;&lt;%= obj.name %&gt; &lt;% } %&gt;" [queryset]="clients$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storages'; desc: 'Recherche par dépôt(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storages" descfield="name" [queryset]="storages$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'members'; desc: 'Recherche par adhérent(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="members" descfield="name" [queryset]="members$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'tarifs'; desc: 'Recherche par tarif(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="tarifs" descfield="name" [queryset]="tariftypes$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'tarifs_from_sales'; desc: 'Recherche par tarif(s) via ventes'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="tarifs_from_sales" descfield="name" [queryset]="tariftypes$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'articles'; desc: 'Recherche par article catalogue'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="articles" descfield="libelle" [filter]="articlesFilter" [queryset]="articles$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'assoc'; desc: 'Recherche par association'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="assoc" [choices]="ASSOC_CHOICES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'type'; desc: 'Recherche par type'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="type" choices="EXTERNAL_ARTICLE_TYPES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'statuses'; desc: 'Recherche par état(s)'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="statuses" choices="EXTERNAL_ARTICLE_STATUSES"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <ng-container *ngIf="mode==='admin'">
      <lvadg-modellist-xlsx-export [list]="list" [mode]="mode"></lvadg-modellist-xlsx-export>
      <button class="btn btn-link btn-sm" (click)="importExcel=!importExcel"><i class="fa fa-file-excel me-2"></i>Import</button>
    </ng-container>
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row"><i class="pointer fa fa-check me-1" [class.stt-inactive]="row.status!='INT'" [class.text-success]="row.status=='INT'" (dblclick)="setStatus(row, 'INT')" title="Dans tarif"></i><i class="pointer fa fa-sign-out me-1" [class.stt-inactive]="row.status!='OUT'" (dblclick)="setStatus(row, 'OUT')" title="Hors tarif"></i><i class="pointer fa fa-crosshairs me-1" [class.stt-inactive]="row.status!='TGT'" [class.text-info]="row.status=='TGT'" (dblclick)="setStatus(row, 'TGT')" title="Ciblé pour tarif"></i><i class="pointer fa fa-exclamation-triangle me-1" [class.stt-inactive]="row.status!='PBM'" [class.text-danger]="row.status=='PBM'" (dblclick)="setStatus(row, 'PBM')" title="Problème à régler"></i><i class="pointer fa fa-trash" [class.stt-inactive]="row.status!='NSG'" (dblclick)="setStatus(row, 'NSG')" title="Non significatif"></i></td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type|choice:row:'type'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="client">
            <th cdk-header-cell *cdkHeaderCellDef>Client</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.client_details?.parent_details"><b>{{row.client_details?.parent_details?.name}}</b> / {{row.client_details?.name}}</ng-container>
              <ng-container *ngIf="!row.client_details?.parent_details"><b>{{row.client_details?.name}}</b></ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="tariftype">
            <th cdk-header-cell *cdkHeaderCellDef>Tarif</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tariftype_details?.name}} <code>{{row.tariftype_details?.code}}</code></td>
          </ng-container>
          <ng-container cdkColumnDef="storage">
            <th cdk-header-cell *cdkHeaderCellDef>Dépôt</th>
            <td cdk-cell *cdkCellDef="let row">{{row.storage_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="member">
            <th cdk-header-cell *cdkHeaderCellDef>Adhérent</th>
            <td cdk-cell *cdkCellDef="let row">{{row.member_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="provider">
            <th cdk-header-cell *cdkHeaderCellDef>Fournisseur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.provider_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="collection">
            <th cdk-header-cell *cdkHeaderCellDef>Collection</th>
            <td cdk-cell *cdkCellDef="let row">{{row.collection_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="code">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="code"><b>Code</b></bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.code}}</code></td>
          </ng-container>
          <ng-container cdkColumnDef="icode">
            <th cdk-header-cell *cdkHeaderCellDef>Autre code</th>
            <td cdk-cell *cdkCellDef="let row">{{row.icode}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ean13">
            <th cdk-header-cell *cdkHeaderCellDef>EAN13</th>
            <td cdk-cell *cdkCellDef="let row">{{row.ean13}}</td>
          </ng-container>
          <ng-container cdkColumnDef="libelle">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="libelle"><b>Libellé</b></bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="getDetailLink(row)">{{row.libelle}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="gamme">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="gamme"><b>Classement<sub>1</sub></b></bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.gamme}}</td>
          </ng-container>
          <ng-container cdkColumnDef="sgamme">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="sgamme"><b>Classement<sub>2</sub></b></bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.sgamme}}</td>
          </ng-container>
          <ng-container cdkColumnDef="marque">
            <th cdk-header-cell *cdkHeaderCellDef>Marque</th>
            <td cdk-cell *cdkCellDef="let row">{{row.marque}}</td>
          </ng-container>
          <ng-container cdkColumnDef="categorie">
            <th cdk-header-cell *cdkHeaderCellDef>Catégorie</th>
            <td cdk-cell *cdkCellDef="let row">{{row.categorie}}</td>
          </ng-container>
          <ng-container cdkColumnDef="calibre">
            <th cdk-header-cell *cdkHeaderCellDef>Calibre</th>
            <td cdk-cell *cdkCellDef="let row">{{row.calibre}}</td>
          </ng-container>
          <ng-container cdkColumnDef="origine">
            <th cdk-header-cell *cdkHeaderCellDef>Origine</th>
            <td cdk-cell *cdkCellDef="let row">{{row.origine}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uf">
            <th cdk-header-cell *cdkHeaderCellDef>UF</th>
            <td cdk-cell *cdkCellDef="let row">{{row.uf}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uf_value">
            <th cdk-header-cell *cdkHeaderCellDef>UF (valeur)</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'uf_value'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uf_unit">
            <th cdk-header-cell *cdkHeaderCellDef>UF (unité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.uf_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uc">
            <th cdk-header-cell *cdkHeaderCellDef>UC</th>
            <td cdk-cell *cdkCellDef="let row">{{row.uc}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uc_value">
            <th cdk-header-cell *cdkHeaderCellDef>UC (valeur)</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'uc_value'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="uc_unit">
            <th cdk-header-cell *cdkHeaderCellDef>UC (unité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.uc_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="pe">
            <th cdk-header-cell *cdkHeaderCellDef>Poids égoutté</th>
            <td cdk-cell *cdkCellDef="let row">{{row.pe}}</td>
          </ng-container>
          <ng-container cdkColumnDef="pe_value">
            <th cdk-header-cell *cdkHeaderCellDef>PE (valeur)</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'pe_value'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="pe_unit">
            <th cdk-header-cell *cdkHeaderCellDef>PE (unité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.pe_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ucuf">
            <th cdk-header-cell *cdkHeaderCellDef>UC/UF</th>
            <td cdk-cell *cdkCellDef="let row">{{row.ucuf}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ucond">
            <th cdk-header-cell *cdkHeaderCellDef>UCond</th>
            <td cdk-cell *cdkCellDef="let row">{{row.ucond}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ucond_value">
            <th cdk-header-cell *cdkHeaderCellDef>UCond (valeur)</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsDecimal:'ucond_value'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="ucond_unit">
            <th cdk-header-cell *cdkHeaderCellDef>UCond (unité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.ucond_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="nego_unit">
            <th cdk-header-cell *cdkHeaderCellDef>Unité négo (unité)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.nego_unit}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flvariant">
            <th cdk-header-cell *cdkHeaderCellDef>Lien nomenclature lvadg fl</th>
            <td cdk-cell *cdkCellDef="let row">{{row.flvariant}}</td>
          </ng-container>
          <ng-container cdkColumnDef="seavariant">
            <th cdk-header-cell *cdkHeaderCellDef>Lien nomenclature lvadg marée</th>
            <td cdk-cell *cdkCellDef="let row">{{row.seavariant}}</td>
          </ng-container>
          <ng-container cdkColumnDef="xlsxhash">
            <th cdk-header-cell *cdkHeaderCellDef>XLSX Hash</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.xlsxhash}}</code></td>
          </ng-container>
          <ng-container cdkColumnDef="orig_data">
            <th cdk-header-cell *cdkHeaderCellDef>Données originales</th>
            <td cdk-cell *cdkCellDef="let row">{{row.orig_data|json}}</td>
          </ng-container>
          <ng-container cdkColumnDef="importref">
            <th cdk-header-cell *cdkHeaderCellDef>Import ref</th>
            <td cdk-cell *cdkCellDef="let row">{{row.importref}}</td>
          </ng-container>
          <ng-container cdkColumnDef="articles">
            <th cdk-header-cell *cdkHeaderCellDef><b class="me-3">Articles</b>
              <bsac-model-list-sorter class="me-2" [list]="list" field="articles__code">code</bsac-model-list-sorter>
              <bsac-model-list-sorter [list]="list" field="articles__libelle">libelle</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let m of row.mappings_details"><i class="fa fa-trash ms-2 text-danger pointer float-end" (dblclick)="removeArticle(row, m.article_details)" role="button" title="Double click pour supprimer"></i><a [routerLink]="this.article_detail_route?.route({article: m.article_details})">[<code>{{m.article_details.code}}</code>] {{m.article_details.libelle}}</a><span class="ms-2" *ngIf="m.flags?.length">
                  <div class="badge mx-1 bg-info" *ngFor="let f of m.flags">{{f}}</div></span></div>
              <div class="pointer" (click)="addArticle(artmod, row)"><i class="fa fa-plus me-2"></i>Associer à un article</div>
            </td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>