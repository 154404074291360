import {Component, Input, OnInit} from "@angular/core";
import {Tarif, TarifService} from "../tarif.service";
import {Upload, UploadService} from "../../../document/upload/upload.service";
import {firstValueFrom} from "rxjs";
import {OpLog} from "../../../../comps/oplog-display/oplog-display.component";

@Component({
  selector: "lvadg-tarif-import",
  templateUrl: "./tarif-import.component.pug",
  styleUrls: ["./tarif-import.component.sass"]
})
export class TarifImportComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public btnClasses: string = "btn btn-primary";
  public uploadImportUrl!: string;
  public upload?: Upload;
  public step = 0;
  public code: string = "";
  public type = "TARR";
  public showLog = false;
  public result?: {
    changed: { libelle: string; oldprice: number; newprice: number }[],
    log?: OpLog
  };

  constructor(private _tarifs: TarifService,
              private _uploads: UploadService) {
  }

  ngOnInit(): void {
    delete this.upload;
    delete this.result;
    this.uploadImportUrl = (<any>this._uploads).backend.getRequestUrl(
      (<any>this._uploads).adapter.create(null)
    );
    this.code = "UP" + (new Date()).getFullYear() +
      // tslint:disable-next-line:no-bitwise
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  public displayImport() {
    this.step = 1;
  }

  async onUploaded(upl: [Upload]) {
    if (upl && upl.length >= 1) {
      this.upload = upl[0];
      this.result = await firstValueFrom(this.tarif.action("reimport", {
        method: "POST",
        body: {
          upload: this.upload.id,
          confirm: false
        }
      }));
      console.log("Resultat", this.result);
    } else {
      console.error("Upload error");
    }
    this.step = 2;
  }

  public async validateImport(b: boolean) {
    if (b) {
      this.result = await firstValueFrom(this.tarif.action("reimport", {
        method: "POST",
        body: {
          upload: this.upload?.id,
          confirm: true
        }
      }));
     this.step = 3;
    } else {
      this.step = 0;
    }
    this.ngOnInit();
  }
}
