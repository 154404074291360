import {Component, Input} from "@angular/core";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {VivalyaBill} from "../../../../lib/models/sales/bill/bill.service";
import {Client} from "../../../../lib/models/structure/client/client.service";
import {CommonBaseComponent} from "../../common-base.component";
import {VivalyaBillItem} from "../../../../lib/models/sales/billitem/billitem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Storage} from "../../../../lib/models/structure/storage/storage.service";
import {Resto} from "../../../../lib/models/structure/resto/resto.service";
import {ExternalArticle} from "../../../../lib/models/catalog/externalarticle/externalarticle.service";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {ArticleFamily} from "../../../../lib/models/catalog/family/article-family.service";

export interface BillItemListViewV3Params extends BaseRouteV3Params {
  bill_detail_route: RouteConfigItemV3;
  billitem_detail_route: RouteConfigItemV3;
  storage_detail_route: RouteConfigItemV3;
  resto_detail_route: RouteConfigItemV3;
  client_detail_route: RouteConfigItemV3;
  article_detail_route: RouteConfigItemV3;
  externalarticle_detail_route: RouteConfigItemV3;
  articlefamily_detail_route: RouteConfigItemV3;
}

export interface BillItemListViewV3Data extends BillItemListViewV3Params {
  client?: Client;
  bill?: VivalyaBill;
}


@Component({
  selector: "lvadg-billitem-list-view",
  templateUrl: "./billitem-list-view.component.pug",
  styleUrls: ["./billitem-list-view.component.sass"]
})
export class BillItemListViewV3Component extends CommonBaseComponent {
  @Input() public client!: Client;
  @Input() public bill!: VivalyaBill;
  public bill_detail_route!: RouteV3<VivalyaBill>;
  public billitem_detail_route!: RouteV3<VivalyaBillItem>;
  public storage_detail_route!: RouteV3<Storage>;
  public resto_detail_route!: RouteV3<Resto>;
  public client_detail_route!: RouteV3<Client>;
  public externalarticle_detail_route!: RouteV3<ExternalArticle>;
  public article_detail_route!: RouteV3<Article>;
  public articlefamily_detail_route!: RouteV3<ArticleFamily>;


  constructor(routes: RoutesService,
              router: Router,
              route: ActivatedRoute) {
    super(routes, router, route);
  }

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as BillItemListViewV3Data;
    this.client = data.client || this.client;
    this.bill = data.bill || this.bill;
    this.billitem_detail_route = new RouteV3<VivalyaBillItem>(data.billitem_detail_route, this);
    this.bill_detail_route = new RouteV3<VivalyaBill>(data.billitem_detail_route, this);

    return super.preNgOnInit();
  }

}
