import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Contact, ContactService} from "../../../../people/contact/contact.service";
import {Resto, RestoService} from "../../../../structure/resto/resto.service";
import {Person} from "../../../../people/person/person.service";
import {Client, ClientService} from "../../../../structure/client/client.service";
import {Member, MemberService} from "../../../../structure/member/member.service";
import {ModelSend} from "../../modelsend.service";
import {SendDestination, SendDestinationService} from "../../senddestination/senddestination.service";
import {Storage} from "../../../../structure/storage/storage.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-modelsend-contact-manage",
  templateUrl: "./modelsend-contact-manage.component.pug",
  styleUrls: ["./modelsend-contact-manage.component.sass"]
})
export class ModelSendContactManageComponent implements OnInit {
  @Input() public destination!: SendDestination;
  @Input() public model?: ModelSend;
  @Input() public member!: Member;
  @Input() public storage!: Storage;
  @Input() public client!: Client;
  @Input() public resto!: Resto;

  @Output() public updated = new EventEmitter<void>();
  @ViewChild("addcontact") public addModal!: ElementRef;
  @ViewChild("editcontact") public editModal!: ElementRef;

  public addTempoContactForm = new UntypedFormGroup({
    email: new UntypedFormControl("")
  });
  public personSearchForm = new UntypedFormGroup({
    search: new UntypedFormControl("")
  });

  public mode: string = "";
  public contact?: Contact;
  public addModalInstance!: NgbModalRef;
  public editModalInstance!: NgbModalRef;
  public searchResults!: Person[];

  constructor(private _modal: NgbModal,
              private _restos: RestoService,
              private _clients: ClientService,
              private _members: MemberService,
              private _pdest: SendDestinationService,
              private _contacts: ContactService) {
  }

  ngOnInit(): void {
  }

  openAdd() {
    this.addModalInstance = this._modal.open(this.addModal, {size: "lg"});
    this.addModalInstance.result.then((result) => {
      console.log("Result", result);
    }, (reason) => {
      console.log("Close", reason);
    });
  }

  public async addRestoContact() {
    this.contact = await firstValueFrom(this._contacts.create({title: "X", resto: this.resto.id}, {save: false}));
    this.mode = "createContact";
  }

  public async addMemberContact() {
    this.contact = await firstValueFrom(this._contacts.create({title: "X", member: this.member.id}, {save: false}));
    this.mode = "createContact";
  }

  public async addStorageContact() {
    this.contact = await firstValueFrom(this._contacts.create({title: "X", storage: this.storage.id}, {save: false}));
    this.mode = "createContact";
  }

  public async addClientContact() {
    this.contact = await firstValueFrom(this._contacts.create({
      title: "X",
      storage: this.storage.id,
      client: this.client.id
    }, {save: false}));
    this.mode = "createContact";
  }


  public async onContact(contact: Contact) {
    this.contact = contact;
    if (!this.destination.update) {
      this.destination = await firstValueFrom(this._pdest.get(this.destination.id));
    }
    this.destination.contact = this.contact.id;
    this.destination.contact_details = this.contact;
    this.destination.person = null;
    this.destination.email = "";
    this.destination.status = "SND";
    await firstValueFrom(this.destination.update(["email", "contact", "status", "person"]));
    this.updated.emit();
    if (this.addModalInstance) {
      this.addModalInstance.close();
    }
    if (this.editModalInstance) {
      this.editModalInstance.close();

    }
    this.mode = "";
  }

  public async onTempoContact() {
    this.destination.email = this.addTempoContactForm.value.email;
    this.destination.contact = null;
    this.destination.person = null;
    this.destination.status = "SND";
    await firstValueFrom(this.destination.update(["email", "contact", "status", "person"]));
    this.updated.emit();
    this.addModalInstance.close();
    this.mode = "";
  }

  public addTempoContact() {
    this.mode = "tempoEmail";
  }

  // public searchPerson() {
  //   this.mode = "personSearch";
  // }

  // public async runPersonSearch() {
  //   const tosearch = this.personSearchForm.value.search;
  //   delete this.searchResults;
  //   const args = {
  //     method: "GET",
  //     query: {search: tosearch}
  //   };
  //   combineLatest([
  //     this._restos.action(null, "search_persons", args),
  //     this._clients.action(null, "search_persons", args),
  //     this._members.action(null, "search_persons", args)
  //   ]).subscribe(([r, c, m]) => {
  //     console.log(r, c, m);
  //     this.searchResults = r.concat(c, m);
  //     this.searchResults = orderBy(this.searchResults, ["lastname", "firstname", "email"], ["asc", "asc", "asc"]);
  //   });
  // }

  // public async savePerson(r: Person) {
  //   this.destination.person = r.id;
  //   this.destination.person_details = r;
  //   this.destination.email = "";
  //   this.destination.contact = null;
  //   this.destination.status = "SND";
  //   await this.destination.update(["person", "contact", "email", "status"]).toPromise();
  //   this.updated.emit();
  //   this.addModalInstance.close();
  // }

  public openEdit() {
    this.editModalInstance = this._modal.open(this.editModal, {size: "lg"});
    this.editModalInstance.result.then((result) => {
      console.log("Result", result);
    }, (reason) => {
      console.log("Close", reason);
    });
  }

  public async editContact() {
    this.contact = await firstValueFrom(this._contacts.create(this.destination.contact_details, {save: false}));
    this.mode = "editContact";
  }

  public async deactivateSend(full: boolean = false) {
    this.destination.status = "SKP";
    await firstValueFrom(this.destination.update(["status"]));
    this.updated.emit();
    this.editModalInstance.close();
    if (full) {
      this.contact = await firstValueFrom(this._contacts.create(this.destination.contact_details, {save: false}));
      this.contact.status = "NOK";
      await firstValueFrom(this.contact.update(["status"]));
    }
    this.mode = "";
    delete this.contact;
  }

  public async activateSend() {
    this.destination.status = "SND";
    await firstValueFrom(this.destination.update(["status"]));
    this.updated.emit();
    this.editModalInstance.close();
    this.mode = "";
    delete this.contact;
  }

  public async dropDestination() {
    await firstValueFrom(this.destination.delete());
    this.updated.emit();
    this.editModalInstance.close();
    this.mode = "";
    delete this.contact;
  }

  public async dropEmptyDestination() {
    await firstValueFrom(this.destination.delete());
    this.updated.emit();
    this.addModalInstance.close();
    this.mode = "";
    delete this.contact;
  }
}
