import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {combineLatest, Observable} from "rxjs";
import {first, map, switchMap} from "rxjs/operators";
import {AuthService} from "./auth.service";
import {FeaturesService} from "../customdata/features/features.service";
import {CurrentSiteService} from "../authentication/site/current-site.service";

@Injectable({providedIn: "root"})
export class AuthSiteGuard implements CanActivate,
  CanActivateChild {

  constructor(public _auth: AuthService,
              public _feat: FeaturesService,
              public _cs: CurrentSiteService,
              public _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    return true;
  }


  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._auth.auth$.pipe(first(),
      switchMap(() => {
        return combineLatest([this._feat.load(), this._cs.site$]);
      }),
      map(([feat, site]) => {
        if (site && site.type === "member" && feat["members_to_v3"]) {
          console.log("Should reload to v3 [members]");
          return this._fullreload();
        }
        if (site && site.type === "vvya" && feat["vivalya_to_v3"]) {
          console.log("Should reload to v3 [vivalya]");
          return this._fullreload();
        }
        if (site && site.type === "client" && feat["clients_to_v3"]) {
          console.log("Should reload to v3 [client]");
          return this._fullreload();
        }
        if (site && site.type === "resto" && feat["restos_to_v3"]) {
          console.log("Should reload to v3 [resto]");
          return this._fullreload();
        }
        return true;
      })
    );
  }

  private _fullreload(): boolean {
    // We only attempt a full reload every 10 minute
    const last = localStorage.getItem("lvadg:fullreload");
    if (last && Date.now() - parseInt(last, 10) < 10 * 60 * 1000) {
      return true;
    }
    // No last reload or last reload was more than 10 minutes ago, we try to reload
    localStorage.setItem("lvadg:fullreload", Date.now().toString());
    navigator.serviceWorker.getRegistration().then((reg) => {
      if (reg) {
        reg.unregister().then( () =>  {
          console.log("Service worker unregistered, reloading");
          // @ts-ignore
          window.location.reload(true);
        });
      } else {
        console.log("No service worker registered, reloading");
        // @ts-ignore
        window.location.reload(true);
      }
    });
    return false;

  }
}



