import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from "@angular/core";
import {NgbCalendar, NgbDate, NgbDatepicker} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "lvadg-daterangepicker",
  templateUrl: "./date-rangepicker.component.pug",
  styleUrls: ["./date-rangepicker.component.sass"]
})
export class DateRangepickerComponent implements OnChanges {
  @Input() start: Date;
  @Input() end: Date;
  @Output() selected = new EventEmitter<{ from: NgbDate, to: NgbDate }>();
  @ViewChild("dp", {static: true}) datepicker: NgbDatepicker;
  public hoveredDate!: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate|null;

  constructor(private _calendar: NgbCalendar) {
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
  }

  public ngOnChanges(changes: any): void {
    // TODO: check if ngOnChanges is valid here (may introduce some problems)
    if (this.start && this.start.getUTCFullYear) {
      this.fromDate = NgbDate.from({
        year: this.start.getUTCFullYear(),
        month: this.start.getUTCMonth() + 1,
        day: this.start.getUTCDate()
      }) as NgbDate;
    } else if (this.start && typeof this.start === "string") {
      this.start = new Date(this.start);
      this.fromDate = NgbDate.from({
        year: this.start.getUTCFullYear(),
        month: this.start.getUTCMonth() + 1,
        day: this.start.getUTCDate()
      })  as NgbDate;
    } else {
      this.fromDate = this._calendar.getToday();
    }
    if (this.end && this.end.getUTCFullYear) {
      this.toDate = NgbDate.from({
        year: this.end.getUTCFullYear(),
        month: this.end.getUTCMonth() + 1,
        day: this.end.getUTCDate()
      }) as NgbDate;
    } else if (this.end && typeof this.end === "string") {
      this.end = new Date(this.end);
      this.toDate = NgbDate.from({
        year: this.end.getUTCFullYear(),
        month: this.end.getUTCMonth() + 1,
        day: this.end.getUTCDate()
      }) as NgbDate;
    } else {
      this.toDate = this._calendar.getNext(this._calendar.getToday(), "d", 6);
    }
    this.datepicker.navigateTo(this.fromDate);
  }

  public onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate && this.toDate) {
      this.selected.emit({from: this.fromDate, to: this.toDate});
    }
  }

  public isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
}
