import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Tarif, TarifService} from "./tarif.service";

@Injectable({providedIn: "root"})
export class TarifResolve implements Resolve<Tarif> {
  constructor(private _bs: TarifService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<Tarif> {
    return this._bs.get(+route.params["tarifId"]);
  }
}
