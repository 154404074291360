import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ClientSelectComponent} from "./client-select/client-select.component";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    BsacFormsModule,
    NgbModule
  ],
  declarations: [
    ClientSelectComponent,
  ],
  exports: [
    ClientSelectComponent
  ]
})
export class ClientSelectModule {
}
