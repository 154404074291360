
<ng-container *ngIf="ready">
  <ng-container *ngIf="display==='view'">
    <lvadg-dynamic-breadcrumb></lvadg-dynamic-breadcrumb>
    <h1 class="page-header"><i class="fa fa-database me-2"></i>
      <ng-container [ngSwitch]="dataType">
        <ng-container *ngSwitchCase="'allergenes'">Gestion des allergènes</ng-container>
        <ng-container *ngSwitchCase="'calibres'">Gestion des calibres</ng-container>
        <ng-container *ngSwitchCase="'categories'">Gestion des catégories</ng-container>
        <ng-container *ngSwitchCase="'aclients'">Gestion des clients</ng-container>
        <ng-container *ngSwitchCase="'colisages'">Gestion des conditionnements (colisage)</ng-container>
        <ng-container *ngSwitchCase="'ucis'">Gestion des conditionnements (uci)</ng-container>
        <ng-container *ngSwitchCase="'egalims'">Gestion des champs Egalim</ng-container>
        <ng-container *ngSwitchCase="'marques'">Gestion des marques</ng-container>
        <ng-container *ngSwitchCase="'origines'">Gestion des origines</ng-container>
        <ng-container *ngSwitchCase="'particularites'">Gestion des particularités</ng-container>
        <ng-container *ngSwitchCase="'presentations'">Gestion des présentations</ng-container>
        <ng-container *ngSwitchCase="'processes'">Gestion des process</ng-container>
        <ng-container *ngSwitchCase="'productions'">Gestion des productions</ng-container>
        <ng-container *ngSwitchCase="'regions'">Gestion des régions</ng-container>
        <ng-container *ngSwitchCase="'traitements'">Gestion des traitements</ng-container>
        <ng-container *ngSwitchCase="'usages'">Gestion des usages</ng-container>
      </ng-container>
    </h1>
  </ng-container>
  <ng-container [ngSwitch]="dataType">
    <lvadg-article-allergenes-manage *ngSwitchCase="'allergenes'" mode="admin" [name]="currentRoute.name+'.allergenes'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-allergenes-manage>
    <lvadg-article-calibres-manage *ngSwitchCase="'calibres'" mode="admin" [name]="currentRoute.name+'.calibres'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-calibres-manage>
    <lvadg-article-categories-manage *ngSwitchCase="'categories'" mode="admin" [name]="currentRoute.name+'.categories'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-categories-manage>
    <lvadg-article-clients-manage *ngSwitchCase="'aclients'" mode="admin" [name]="currentRoute.name+'.aclients'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-clients-manage>
    <lvadg-article-conditionnements-manage *ngSwitchCase="'colisages'" mode="admin" [name]="currentRoute.name+'.colisages'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-conditionnements-manage>
    <lvadg-article-conditionnements-manage *ngSwitchCase="'ucis'" mode="admin" [name]="currentRoute.name+'.ucis'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-conditionnements-manage>
    <lvadg-article-egalims-manage *ngSwitchCase="'egalims'" mode="admin" [name]="currentRoute.name+'.egalims'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-egalims-manage>
    <lvadg-article-marques-manage *ngSwitchCase="'marques'" mode="admin" [name]="currentRoute.name+'.marques'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-marques-manage>
    <lvadg-article-origines-manage *ngSwitchCase="'origines'" mode="admin" [name]="currentRoute.name+'.origines'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-origines-manage>
    <lvadg-article-particularites-manage *ngSwitchCase="'particularites'" mode="admin" [name]="currentRoute.name+'.particularites'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-particularites-manage>
    <lvadg-article-presentations-manage *ngSwitchCase="'presentations'" mode="admin" [name]="currentRoute.name+'.presentations'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-presentations-manage>
    <lvadg-article-processes-manage *ngSwitchCase="'processes'" mode="admin" [name]="currentRoute.name+'.processes'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-processes-manage>
    <lvadg-article-productions-manage *ngSwitchCase="'productions'" mode="admin" [name]="currentRoute.name+'.productions'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-productions-manage>
    <lvadg-article-regions-manage *ngSwitchCase="'regions'" mode="admin" [name]="currentRoute.name+'.regions'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-regions-manage>
    <lvadg-article-traitements-manage *ngSwitchCase="'traitements'" mode="admin" [name]="currentRoute.name+'.traitements'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-traitements-manage>
    <lvadg-article-usages-manage *ngSwitchCase="'usages'" mode="admin" [name]="currentRoute.name+'.usages'" [articledata_detail_route]="articledata_detail_route" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route"></lvadg-article-usages-manage>
  </ng-container>
</ng-container>