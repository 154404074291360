import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FichesData} from "../../../../lib/models/catalog/fichesdata/fichesdata.service";
import {BaseRouteV3Params, RoutesService} from "../../../../lib/comps/routes.service";
import {CommonBaseComponent} from "../../common-base.component";
import {VIEW_DISPLAY} from "../../../view-constants";

export interface CatalogFicheDataManageViewV3Params extends BaseRouteV3Params {
  display?: VIEW_DISPLAY;
}

export interface CatalogFicheDataManageViewV3Data extends CatalogFicheDataManageViewV3Params {
  ficheData: FichesData;
}


@Component({
  selector: "lvadg-catalog-fichedata-manage-view",
  templateUrl: "./catalog-fichedata-manage.component.pug",
  styleUrls: ["./catalog-fichedata-manage.component.sass"]
})
export class CatalogFicheDataManageViewV3Component extends CommonBaseComponent {
  public ficheData!: FichesData;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }

  protected async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogFicheDataManageViewV3Data;
    this.ficheData = data.ficheData;
    return super.preNgOnInit();
  }


}
