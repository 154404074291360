import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Tarif} from "../../tarif/tarif.service";
import {TarifNotification, TarifNotificationService} from "../tarifnotification.service";
import {firstValueFrom} from "rxjs";

// FIXME: remove quill, use slatejs for editor
@Component({
  selector: "lvadg-tarifnotification-create",
  templateUrl: "./tarifnotification-create.component.pug",
  styleUrls: ["./tarifnotification-create.component.sass"]
})
export class TarifnotificationCreateComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public type: "PUB" | "UPD" = "PUB";
  @Input() public status: "PRP" | "PEN" = "PEN";
  @Input() public priority: "P1" | "P2" | "P3" = "P2";
  @Input() public cancel = true;
  @Input() public message?: string;
  @Input() public create: boolean = false;
  @Output() public created = new EventEmitter<TarifNotification>();
  @Output() public cancelled = new EventEmitter<void>();
  public template = "";
  public existing = false;
  public multiple = false;
  public form = new UntypedFormGroup({
    message: new UntypedFormControl("", Validators.maxLength(30000))
  });
  public tn!: TarifNotification;
  public quillModules: any = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote", {"script": "sub"}, {"script": "super"}],
      [{"list": "ordered"}, {"list": "bullet"}],
      [{"color": []}, {"background": []}, "clean", "link"]
    ]
  };

  constructor(private _tns: TarifNotificationService) {
  }

  public async ngOnInit(): Promise<void> {
    const trs = await firstValueFrom(this._tns.queryset.query({tarif: this.tarif.id, type: this.type, status: this.status}).get());
    if (trs.items.length === 0) {
      this.tn = await firstValueFrom(this._tns.create({
        tarif: this.tarif.id,
        status: this.status,
        priority: this.priority,
        type: this.type,
        title: this.type === "PUB" ? "Nouveau tarif" : "Mise à jour du tarif",
        message: this.message || this.tarif.message || ""
      }));
      this.existing = false;
    } else {
      if (trs.items.length > 1) {
        this.multiple = true;
      }
      this.tn = trs.items[0];
      this.existing = true;
    }
    this.form.setValue({
      message: this.tn.message,
    });
    this.template = localStorage.getItem(`tmsgtpl-${this.tarif.type}`) || "";

  }

  public async save(): Promise<void> {
    this.tn.assign(this.form.value);
    this.tn.tarif = this.tarif.id;
    if (this.create) {
      await firstValueFrom(this.tn.save());
    }
    this.created.emit(this.tn);
  }

  public async getDiff(): Promise<void> {
    const {diff} = await firstValueFrom<{ diff: string }>(this.tarif.action("diff", {
      method: "GET"
    }));
    this.form.patchValue({message: (this.form.value.message || "") + diff});
  }

  public useTemplate(): void {

    this.form.patchValue({message: (this.form.value.message || "") + this.template});
  }

  public saveTemplate(): void {
    localStorage.setItem(`tmsgtpl-${this.tarif.type}`, this.form.value.message);
    this.template = this.form.value.message;
  }
}
