import {Inject, Injectable, InjectionToken} from "@angular/core";
import {AuthService} from "../../user/auth.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Print} from "./print";

export * from "./print";

export const LVADG_PRINTER_URL = new InjectionToken("lvadg.printer.url");

@Injectable({providedIn: "root"})
export class FindprintService {

  constructor(@Inject(LVADG_PRINTER_URL) private _printerUrl: string,
              private _auth: AuthService,
              private _http: HttpClient) {

  }

  public findPrints(filter: any = {}): Observable<Print[]> {
    return this._http.post<Print[]>(this._printerUrl + "/prints/find",
      filter,
      {
        headers: {
          "x-token": this._auth.token.token
        }
      }
    );
  }

}
