
<form [formGroup]="contactForm" (submit)="saveContact()" *ngIf="contact">
  <div class="row">
    <div class="col-sm-12" *ngIf="searchUser">
      <label>Utilisateur lavieadugout</label>
      <bsac-field-control [form]="contactForm" [model]="contact" field="user" [related]="users$" type="auto" desctpl="&lt;%= obj.lastname %&gt; &lt;%= obj.firstname %&gt; (&lt;%= obj.email %&gt;)" (relatedSelected)="selectedUser($event)"></bsac-field-control>
    </div>
    <div class="col-sm-6">
      <label>Prénom</label>
      <bsac-field-control [form]="contactForm" [model]="contact" field="firstname"></bsac-field-control>
    </div>
    <div class="col-sm-6">
      <label>Nom de famille</label>
      <bsac-field-control [form]="contactForm" [model]="contact" field="lastname"></bsac-field-control>
    </div>
    <div class="col-sm-12">
      <label>Email (*)</label>
      <bsac-field-control [form]="contactForm" [model]="contact" field="email"></bsac-field-control>
    </div>
    <div class="col-sm-4">
      <label>Nom du métier</label>
      <bsac-field-control [form]="contactForm" [model]="contact" field="jobname"></bsac-field-control>
    </div>
    <div class="col-sm-4">
      <label>Téléphone</label>
      <bsac-field-control [form]="contactForm" [model]="contact" field="phone"></bsac-field-control>
    </div>
    <div class="col-sm-4">
      <label>Téléphone portable</label>
      <bsac-field-control [form]="contactForm" [model]="contact" field="mobile"></bsac-field-control>
    </div>
  </div>
  <button class="btn btn-primary btn-block w-100"><i class="fa fa-save me-2"></i>Enregistrer le contact</button>
</form>