import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {NewsSelectComponent} from "./news-select/news-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [NewsSelectComponent],
  exports: [NewsSelectComponent]
})
export class NewsSelectModule {}
