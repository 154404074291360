import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {CommField} from "../basecomm/commfield/commfield.service";


export class PrinterField extends CommField {
}


@Injectable({
  providedIn: "root"
})
export class PrinterFieldService extends DSRestCollection<PrinterField> {
  public adapter_config = {basePath: "/api/v2/printerfields"};
  public model = PrinterField;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
