
<div *ngIf="article">
  <table class="table table-condensed table-striped">
    <thead>
      <tr>
        <th>Critère</th>
        <th>RNM</th>
        <th>Prix moyen</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let r of rnmData">
        <tr class="table-success" *ngIf="r.crit=='exact_cvva'">
          <td>{{r.crit}}</td>
          <td>[{{r.id}}] {{r.rnm}}</td>
          <td>
            <ng-container *ngIf="r.price">{{r.price/10000|currency:'EUR'}}</ng-container>
            <ng-container *ngIf="!r.price">NC</ng-container>
          </td>
        </tr>
      </ng-container>
      <tr *ngFor="let r of rnmData">
        <td>{{r.crit}}</td>
        <td>[{{r.id}}] {{r.rnm}}</td>
        <td>
          <ng-container *ngIf="r.price">{{r.price/10000|currency:'EUR'}}</ng-container>
          <ng-container *ngIf="!r.price">NC</ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>