import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AuthService} from "./auth.service";
import {UserService} from "./user.service";
import {TokenService} from "../authentication/token/token.service";
import {DSRestModule} from "@solidev/ngdataservice";
import {HttpClientModule} from "@angular/common/http";

@NgModule({
  imports: [
    CommonModule,
    DSRestModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [AuthService, UserService, TokenService]
})
export class UserModule { }
