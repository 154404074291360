
<ng-template #publish>
  <lvadg-tarif-publish (published)="closePublish(true)" (cancelled)="closePublish(false)" [tarif]="tarif"></lvadg-tarif-publish>
</ng-template>
<div class="card mb-2" *ngIf="duplicate">
  <div class="card-header bg-warning text-white">Dupliquer le tarif courant</div>
  <div class="card-body">
    <lvadg-tarif-create [tarifType]="tarif.type_details" (created)="onCreated($event)" [duplicate]="tarif"></lvadg-tarif-create>
  </div>
</div>
<div class="row g-3">
  <div class="col">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-info-circle me-2"></i>Infos tarif</div>
      <div class="card-body">
        <dl class="row">
          <bsac-dd-field class="col-6" [model]="tarif" field="id" type="auto" [editable]="false">ID</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="tarif" field="name" type="auto" [editable]="true">Nom</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="tarif" field="datestart" type="auto" [editable]="true">Date début</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="tarif" field="dateend" type="auto" [editable]="true">Date fin</bsac-dd-field>
        </dl>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-sticky-note me-2"></i>Message</div>
      <div class="card-body">
        <form [formGroup]="editorForm" (submit)="saveText()" *ngIf="editorForm">
          <div class="form-group">
            <label><b>Message du tarif</b></label>
            <quill-editor class="d-block" formControlName="text" [modules]="quillModules"></quill-editor>
          </div>
          <button class="btn btn-sm btn-block w-100 btn-primary"><i class="fa fa-save me-2"></i>Enregistrer...</button>
        </form>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-cog me-2"></i>Opérations tarif</div>
      <div class="card-body">
        <dl class="row">
          <bsac-dd-field class="col-6" [model]="tarif" field="status" type="auto" [editable]="true">Etat</bsac-dd-field>
          <bsac-dd-field class="col-6" [model]="tarif" field="step" type="auto" [editable]="true">Step</bsac-dd-field>
        </dl>
        <div class="row mt-2">
          <div class="col-6">
            <lvadg-tarif-export [tarif]="tarif" btnClasses="btn btn-primary btn-sm btn-block" [mode]="tarif.type_details.lifecycle===LifeCycles.GENERIC ? 'nego':'export'"></lvadg-tarif-export>
          </div>
          <div class="col-6">
            <button class="btn btn-primary btn-block w-100" (click)="openPublish(publish)"><i class="fa fa-mail-bulk me-2"></i>Publier</button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <button class="btn btn-warning btn-block w-100" (click)="duplicate=!duplicate"><i class="fa fa-copy me-2"></i>Dupliquer</button>
          </div>
          <div class="col-6">
            <button class="btn btn-warning btn-block w-100" (click)="updateArticles()" [disabled]="updatingArticles"><i class="fa fa-sync-alt me-2" *ngIf="!updatingArticles"></i><i class="fa fa-sync-alt fa-spin me-2" *ngIf="updatingArticles"></i>MAJ Articles</button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <lvadg-confirm-delete class="d-block" type="button" (confirm)="deleteTarif()" [classes]="['btn', 'btn-danger', 'btn-block']"><i class="fa fa-trash me-2"></i>Supprimer</lvadg-confirm-delete>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>