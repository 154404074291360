
<h1>Statistiques facturation</h1>
<form [formGroup]="mainFilterForm">
  <div class="row">
    <div class="col-6 col-md-3">
      <div class="form-group">
        <label>Date de début</label>
        <bsac-datetime-input class="form-control" formControlName="datestart" [dateOnly]="true" [dateFormat]="'mediumDate'"></bsac-datetime-input>
      </div>
    </div>
    <div class="col-6 col-md-3">
      <div class="form-group">
        <label>Date de fin</label>
        <bsac-datetime-input class="form-control" formControlName="dateend" [dateOnly]="true" [dateFormat]="'mediumDate'"></bsac-datetime-input>
      </div>
    </div>
    <div class="col-6 col-md-3">
      <div class="form-group">
        <label>Statistique principale</label>
        <select class="form-select" formControlName="mainstat">
          <option value="bygamme">Par gamme</option>
          <option value="bynature">Par nature</option>
          <option value="byfamily">Par famille</option>
          <option value="bysubfamily">Par sous-famille</option>
          <option value="byorigin">Par origine</option>
          <option value="bystorage">Par dépôt</option>
          <option value="bylabel">Par label</option>
        </select>
      </div>
    </div>
    <div class="col-6 col-md-3">
      <div class="form-group">
        <label>Affichage</label>
        <select class="form-select" formControlName="statvalue">
          <option value="weight">Poids</option>
          <option value="amount">Montant brut</option>
        </select>
      </div>
    </div>
    <div class="col-6 col-md-3">
      <div class="form-group">
        <label>Dépôt</label>
        <select class="form-select" formControlName="storage">
          <option value="">Tous les dépôts</option>
          <ng-container *ngIf="storages">
            <option *ngFor="let s of storages" [value]="s.id">{{s.name}}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
</form>
<div class="text-center mt-4" *ngIf="loading">
  <div class="fa fa-spinner fa-spin fa-4x"></div>
  <div>Calcul des stats en cours, merci de patienter</div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-sm-4">
    <div class="card">
      <div class="card-header">Liste des factures</div>
      <ul class="list-group list-group-flush" *ngIf="bills">
        <li class="list-group-item" *ngFor="let b of bills">
          <div><i class="fa fa-caret-down float-end ms-2" role="button" (click)="openBill(b)"></i><span class="title">{{b.billdate | date:'mediumDate'}}<code class="ms-2" [routerLink]="['/', 'billing', 'bills', b.id]">{{b.code}}</code><span class="ms-2">{{b.brut_amount/10000|currency:"EUR"}}</span></span>
            <table class="border-top" *ngIf="billDetails[b.id]">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Article</th>
                  <th>Poids</th>
                  <th>Montant</th>
                </tr>
                <tr *ngFor="let bi of billDetails[b.id]">
                  <td>{{bi.id}}</td>
                  <td>{{bi.article_details?.libelle}}</td>
                  <td>{{bi.weight/10000}}</td>
                  <td>{{bi.brut_amount/10000|currency:"EUR"}}</td>
                </tr>
              </thead>
            </table>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-sm-8 col-12 mt-4 mt-sm-0">
    <div class="card">
      <div class="card-header">Statistiques</div>
      <div class="card-body">
        <div>
          <h3>Répartition</h3>
          <label>Répartition par {{mainStatTitles[mainstat]}} - {{statValueTitles[statvalue]}}</label>
          <div class="rgraph" [ngClass]="'size-'+screenWidth">
            <lvadg-categ-series-chart [data]="productTypeStats[mainstat][statvalue]" type="pie" *ngIf="productTypeStats"></lvadg-categ-series-chart>
          </div>
        </div>
        <div class="mt-3">
          <h3>Statistiques par semaine</h3>
          <label>Par {{statValueTitles[statvalue]}}</label>
          <div class="tgraph" [ngClass]="'size-'+screenWidth">
            <lvadg-time-series-chart [data]="amountTimeStats[statvalue]" type="bar" *ngIf="amountTimeStats"></lvadg-time-series-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>