<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="name">Nom</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="code">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="code">Code</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.code}}</td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type|choice:row:'type'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="vvid">
            <th cdk-header-cell *cdkHeaderCellDef>ID Vivalya</th>
            <td cdk-cell *cdkCellDef="let row">{{row.vvid}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flabel">
            <th cdk-header-cell *cdkHeaderCellDef>Label F&L</th>
            <td cdk-cell *cdkCellDef="let row">{{row.flabel_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="slabel">
            <th cdk-header-cell *cdkHeaderCellDef>Label marée</th>
            <td cdk-cell *cdkCellDef="let row">{{row.slabel_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="egalim">
            <th cdk-header-cell *cdkHeaderCellDef>Egalim ?</th>
            <td cdk-cell *cdkCellDef="let row">{{row.egalim}}</td>
          </ng-container>
          <ng-container cdkColumnDef="egalim_plus">
            <th cdk-header-cell *cdkHeaderCellDef>Egalim Plus (base)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.egalim_plus}}</td>
          </ng-container>
          <ng-container cdkColumnDef="egalim_plus_client">
            <th cdk-header-cell *cdkHeaderCellDef>Egalim Plus Clients</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let c of row.egalim_plus_client_details">{{c.name}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="content_src">
            <th cdk-header-cell *cdkHeaderCellDef>Description (source)</th>
            <td cdk-cell *cdkCellDef="let row">{{row.content_src|shorten:30:'...'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="url">
            <th cdk-header-cell *cdkHeaderCellDef>Url de description</th>
            <td cdk-cell *cdkCellDef="let row">{{row.url|shorten:20:'...'}}</td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
    <ng-container *ngIf="current">
      <h2>Attribut Egalim : [{{current.id}}] {{current.code}}</h2>
      <dl class="row">
        <bsac-dd-field class="col-6" [model]="current" field="vvid" type="auto" [editable]="false" [trackId]="true">ID Vivalya</bsac-dd-field>
        <bsac-dd-field class="col-6" [model]="current" field="code" type="auto" (changed)="updated()" [trackId]="true">Code</bsac-dd-field>
        <bsac-dd-field class="col-6" [model]="current" field="type" type="auto" (changed)="updated()" [trackId]="true">Type</bsac-dd-field>
        <bsac-dd-field class="col-6" [model]="current" field="name" type="auto" (changed)="updated()" [trackId]="true">Nom</bsac-dd-field>
      </dl>
      <dl class="row mt-3">
        <bsac-dd-field class="col-6" [model]="current" field="egalim" type="auto" (changed)="updated()" [trackId]="true">Egalim ?</bsac-dd-field>
        <bsac-dd-field class="col-6" [model]="current" field="egalim_plus" type="auto" (changed)="updated()" [trackId]="true">Egalim plus ?</bsac-dd-field>
        <bsac-dd-field class="col-12" [model]="current" field="egalim_plus_client" type="auto" (changed)="updated()" [trackId]="true" [related]="clients$" desctpl="&lt;%= obj.name %&gt;" relatedLabel="Sélectionnez un client">Egalim plus clients</bsac-dd-field>
      </dl>
      <dl class="row mt-3">
        <bsac-dd-field class="col-12" [model]="current" field="url" type="auto" (changed)="updated()" [trackId]="true">Url de description</bsac-dd-field>
        <bsac-dd-field class="col-12" [model]="current" field="content_src" type="auto" (changed)="updated()" [trackId]="true">Description</bsac-dd-field>
      </dl>
      <dl class="row mt-3">
        <bsac-dd-field class="col-12" [model]="current" field="flabel" type="foreignkey" (changed)="updated()" [trackId]="true" [related]="flabels$" desctpl="&lt;%= obj.name %&gt;" relatedLabel="Sélectionnez un label f&amp;l">Label F&L</bsac-dd-field>
        <bsac-dd-field class="col-12" [model]="current" field="slabel" type="foreignkey" (changed)="updated()" [trackId]="true" [related]="slabels$" desctpl="&lt;%= obj.name %&gt;" relatedLabel="Sélectionnez un label marée">Label Marée</bsac-dd-field>
      </dl>
      <lvadg-article-list [mode]="mode" [name]="name+'.articles'" [article_detail_route]="article_detail_route" [provider_detail_route]="provider_detail_route" displayMode="horizontal" [default_fields]="['id', 'libelle', 'family', filterField]" [default_filters]="['search']" [filter]="afilter" [filter$]="afilter$"></lvadg-article-list>
    </ng-container>
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>