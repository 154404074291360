import {Offer, OfferService} from "../offer.service";
import {Component, Input} from "@angular/core";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {Member, MemberService} from "../../../structure/member/member.service";
import {Storage, StorageService} from "../../../structure/storage/storage.service";
import {Resto, RestoService} from "../../../structure/resto/resto.service";
import {Client, ClientService} from "../../../structure/client/client.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {RoutesService} from "../../../../comps/routes.service";

@Component({
  selector: "lvadg-offer-list",
  templateUrl: "./offer-list.component.pug",
  styleUrls: ["./offer-list.component.sass"]
})
export class OfferListComponent extends ModelListBaseComponent<Offer, OfferService> {
  @Input() public member!: Member;
  @Input() public resto!: Resto;
  @Input() public client!: Client;
  @Input() public storage!: Storage;
  @Input() public display = {};

  public members$!: DSRestQueryset<Member>;
  public storages$!: DSRestQueryset<Storage>;
  public clients$!: DSRestQueryset<Client>;
  public restos$!: DSRestQueryset<Resto>;

  public storageFilter: any = {};


  constructor(_list: BsacModelListProvider,
              _items: OfferService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              private _members: MemberService,
              private _storages: StorageService,
              private _clients: ClientService,
              private _restos: RestoService) {
    super(_list, _items, _msgs, routes);
    this.type = "offers";
  }


  public async preNgOnInit(): Promise<void> {
    this.filter.type = this.zone.toUpperCase();
    if (this.member) {
      this.storageFilter = {member: this.member.id};
    }
    this.members$ = this._members.queryset;
    this.storages$ = this._storages.queryset;
    this.clients$ = this._clients.queryset;
    this.restos$ = this._restos.queryset;

    return super.preNgOnInit();
  }


  // ------------------------ ACTIONS  --------------------------------------
  public onAction(event: any): void {
    if (event === "create") {
      this.action.emit({type: "create"});
    }
  }
}
