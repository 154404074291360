import {Pipe, PipeTransform} from "@angular/core";
import {formatNumber} from "@angular/common";

@Pipe({
  name: "distance"
})
export class DistancePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return "-";
    }
    return formatNumber(Math.ceil(value / 100) / 10, "fr")+ " km";
  }

}
