import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Usage, UsageService} from "./usage.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class UsageResolve implements Resolve<Usage> {
  constructor(private _bs: UsageService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Usage> {
    return this._bs.get(+route.params["usageId"]);
  }
}
