import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "lvadg-disp-stats",
  templateUrl: "./disp-stats.component.pug",
  styleUrls: ["./disp-stats.component.sass"]
})
export class DispStatsComponent implements OnInit {
  @Input() stats: any[];
  @Input() format = "inline";
  @Input() displayed: string[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  public isDisplayed(name: string): boolean {
    if (this.displayed.length === 0) {
      return false;
    }
    return this.displayed.indexOf(name) !== -1;
  }

}
