import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {VivalyaBill} from "../bill.service";
import {VivalyaBillItem, VivalyaBillItemService} from "../../billitem/billitem.service";
import {firstValueFrom} from "rxjs";
import {RouteV3} from "../../../../comps/routes.service";
import {Article} from "../../../catalog/article/article.service";
import {ExternalArticle} from "../../../catalog/externalarticle/externalarticle.service";

@Component({
  selector: "lvadg-bill-detail",
  templateUrl: "./bill-detail.component.pug",
  styleUrls: ["./bill-detail.component.sass"]
})
export class BillDetailComponent implements OnChanges {
  @Input() bill!: VivalyaBill;
  @Input() bill_list_route!: RouteV3<VivalyaBill>;
  @Input() article_detail_route!: RouteV3<Article>;
  @Input() ea_detail_route!: RouteV3<ExternalArticle>;
  public lines: VivalyaBillItem[] = [];

  constructor(private _bi: VivalyaBillItemService) {
  }

  public async ngOnChanges(changes: SimpleChanges) {
    this.lines = [];
    this.lines = (await firstValueFrom(this._bi.queryset.query({bill: this.bill.id}).get())).items;
  }

}
