<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'type'; desc: 'Recherche par type'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" choices="RELATION_TYPES" field="type"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'status'; desc: 'Recherche par état'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" choices="RELATION_STATUSES" field="status"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'member'; desc: 'Adhérent (direct)'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="member" [queryset]="members$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'producer'; desc: 'Producteur (direct)'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="producer" [queryset]="producers$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'seaproducer'; desc: 'Producteur marée (direct)'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="seaproducer" [queryset]="seaproducers$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storage'; desc: 'Dépôt (direct)'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="storage" [queryset]="storages$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storagetypes'; desc: 'Type(s) de dépôt (direct)'; group: 'link'">
        <bsac-model-list-multiselect-filter [list]="list" field="storagetypes" choices="STORAGE_TYPES"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'client'; desc: 'Client (direct)'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="client" [queryset]="clients$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'resto'; desc: 'Restaurant (direct)'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="resto" [queryset]="restos$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'smember'; desc: 'Adhérent (via dépôt)'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="smember" [queryset]="members$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'rclient'; desc: 'Client (via resto)'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="rclient" [queryset]="clients$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'provider'; desc: 'Fournisseur référencé'; group: 'link'">
        <bsac-model-list-autocomplete-filter [list]="list" field="provider" [queryset]="providers$" descfield="name"></bsac-model-list-autocomplete-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'link'; desc: 'Recherche par lien'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
    <button class="btn btn-text btn-sm" (click)="xlsxExport('export-relations', 'relations', false)"><i class="fa fa-file-export me-1"></i>Export basique</button>
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">
              <div class="pointer" *ngIf="checkMode('admin')" (click)="changeStatus(row)"><i class="fa fa-pencil me-2"></i>{{row.status}}</div>
              <ng-container *ngIf="!checkMode('admin')">{{row.status}}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type|bsacApiConstantSingle:'RELATION_TYPES'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="member">
            <th cdk-header-cell *cdkHeaderCellDef>Adhérent</th>
            <td cdk-cell *cdkCellDef="let row"><b *ngIf="row.member"><a [routerLink]="member_detail_route?.route({member: row.member_details})">{{row.member_details?.name}}</a></b><small *ngIf="!row.member">{{row.storage_details?.member_details?.name||"-"}}</small></td>
          </ng-container>
          <ng-container cdkColumnDef="storage">
            <th cdk-header-cell *cdkHeaderCellDef>Dépôt</th>
            <td cdk-cell *cdkCellDef="let row"><b *ngIf="row.storage"><a [routerLink]="storage_detail_route?.route({storage: row.storage_details})">{{row.storage_details?.name}}</a></b><span *ngIf="!row.storage">-</span></td>
          </ng-container>
          <ng-container cdkColumnDef="client">
            <th cdk-header-cell *cdkHeaderCellDef>Client</th>
            <td cdk-cell *cdkCellDef="let row"><b *ngIf="row.client"><a [routerLink]="client_detail_route?.route({client: row.client_details})">{{row.client_details?.name}}</a></b><small *ngIf="!row.client">{{row.resto_details?.client_details?.name||"-"}}</small></td>
          </ng-container>
          <ng-container cdkColumnDef="resto">
            <th cdk-header-cell *cdkHeaderCellDef>Restaurant</th>
            <td cdk-cell *cdkCellDef="let row"><b *ngIf="row.resto"><a [routerLink]="resto_detail_route?.route({resto: row.resto_details})">{{row.resto_details?.name}}</a></b><span *ngIf="!row.resto">-</span></td>
          </ng-container>
          <ng-container cdkColumnDef="producer">
            <th cdk-header-cell *cdkHeaderCellDef>Producteur</th>
            <td cdk-cell *cdkCellDef="let row"><b *ngIf="row.producer"><a [routerLink]="producer_detail_route?.route({producer: row.producer_details})">{{row.producer_details?.name}}</a></b><span *ngIf="!row.producer">-</span></td>
          </ng-container>
          <ng-container cdkColumnDef="seaproducer">
            <th cdk-header-cell *cdkHeaderCellDef>Producteur Marée</th>
            <td cdk-cell *cdkCellDef="let row"><b *ngIf="row.seaproducer"><a [routerLink]="seaproducer_detail_route?.route({seaproducer: row.seaproducer_details})">{{row.seaproducer_details?.name}}</a></b><span *ngIf="!row.seaproducer">-</span></td>
          </ng-container>
          <ng-container cdkColumnDef="provider">
            <th cdk-header-cell *cdkHeaderCellDef>Fournisseur référencé</th>
            <td cdk-cell *cdkCellDef="let row"><b *ngIf="row.provider"><a [routerLink]="provider_detail_route?.route({provider: row.provider_details})">{{row.provider_details?.name}}</a></b><span *ngIf="!row.provider">-</span></td>
          </ng-container>
          <ng-container cdkColumnDef="distance">
            <th cdk-header-cell *cdkHeaderCellDef>Distance<i class="fa fa-ruler ms-2" [class.text-muted]="!dispDistanceUnit" role="button" (click)="dispDistanceUnit=!dispDistanceUnit"></i></th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="dispDistanceUnit">{{row.distance_details?.road | distance }}</ng-container>
              <ng-container *ngIf="!dispDistanceUnit">{{row.distance_details?.road / 1000 | number }}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="distance__road">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="distance__road">Distance route<i class="fa fa-ruler ms-2" [class.text-muted]="!dispDistanceUnit" role="button" (click)="dispDistanceUnit=!dispDistanceUnit"></i></bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="dispDistanceUnit">{{row.distance_details?.road | distance }}</ng-container>
              <ng-container *ngIf="!dispDistanceUnit">{{row.distance_details?.road / 1000 | number }}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="distance__direct">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="distance__direct">Distance directe<i class="fa fa-ruler ms-2" [class.text-muted]="!dispDistanceUnit" role="button" (click)="dispDistanceUnit=!dispDistanceUnit"></i></bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="dispDistanceUnit">{{row.distance_details?.direct | distance }}</ng-container>
              <ng-container *ngIf="!dispDistanceUnit">{{row.distance_details?.direct / 1000 | number }}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="storage_address">
            <th cdk-header-cell *cdkHeaderCellDef>Adresse dépôt</th>
            <td cdk-cell *cdkCellDef="let row">{{row.storage_details?.mainlocation_details?.address||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="storage_city">
            <th cdk-header-cell *cdkHeaderCellDef>Ville dépôt</th>
            <td cdk-cell *cdkCellDef="let row">{{row.storage_details?.mainlocation_details?.city||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="storage_postcode">
            <th cdk-header-cell *cdkHeaderCellDef>CP Dépôt</th>
            <td cdk-cell *cdkCellDef="let row">{{row.storage_details?.mainlocation_details?.postcode||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="storage_geoloc">
            <th cdk-header-cell *cdkHeaderCellDef>Geoloc Dépôt</th>
            <td cdk-cell *cdkCellDef="let row">{{row.storage_details?.mainlocation_details?.geolabel||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="storage_geoloc_status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat géoloc depot</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngIf="row.storage_details?.mainlocation_details?.geotype">
                <div class="badge bg-secondary">{{row.storage_details?.mainlocation_details?.geotype||'-'}}</div>
              </div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="resto_cvva">
            <th cdk-header-cell *cdkHeaderCellDef>Code resto</th>
            <td cdk-cell *cdkCellDef="let row">{{row.resto_details?.cvva}}</td>
          </ng-container>
          <ng-container cdkColumnDef="resto_address">
            <th cdk-header-cell *cdkHeaderCellDef>Adresse resto</th>
            <td cdk-cell *cdkCellDef="let row">{{row.resto_details?.mainlocation_details?.address||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="resto_city">
            <th cdk-header-cell *cdkHeaderCellDef>Ville resto</th>
            <td cdk-cell *cdkCellDef="let row">{{row.resto_details?.mainlocation_details?.city||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="resto_postcode">
            <th cdk-header-cell *cdkHeaderCellDef>CP resto</th>
            <td cdk-cell *cdkCellDef="let row">{{row.resto_details?.mainlocation_details?.postcode||'-'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="resto_geoloc">
            <th cdk-header-cell *cdkHeaderCellDef>Geoloc resto</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="['/', 'data', 'locations', row.resto_details?.mainlocation_details?.id]" *ngIf="row.resto_details?.mainlocation_details?.id">{{row.resto_details?.mainlocation_details?.geolabel||'-'}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="resto_geoloc_status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat géoloc dépôt</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngIf="row.resto_details?.mainlocation_details?.geotype">
                <div class="badge bg-secondary">{{row.resto_details?.mainlocation_details?.geotype||'-'}}</div>
              </div>
            </td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>