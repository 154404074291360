import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Producer, ProducerService} from "./producer.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class ProducerResolve implements Resolve<Producer> {
  constructor(private _bs: ProducerService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Producer> {
    return this._bs.get(+route.params["producerId"]);
  }
}


@Injectable({providedIn: "root"})
export class ProducerLocalResolve implements Resolve<Producer> {
  constructor(private _bs: ProducerService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Producer> {
    return this._bs.get(+route.params["producerId"], {query: {localstorage: 1}});
  }
}
