<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'clients'; desc: 'Recherche par client(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="clients" desctpl="&lt;% if(obj.parent_details) { %&gt;&lt;%= obj.parent_details.name %&gt; / &lt;%= obj.name %&gt; &lt;% } else { %&gt;&lt;%= obj.name %&gt; &lt;% } %&gt;" [queryset]="clients$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'storages'; desc: 'Recherche par dépôt(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="storages" descfield="name" [queryset]="storages$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'members'; desc: 'Recherche par adhérent(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="members" descfield="name" [queryset]="members$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'articles'; desc: 'Recherche par article catalogue'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="articles" descfield="libelle" [filter]="articlesFilter" [queryset]="articles$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'restos'; desc: 'Recherche par resto(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="restos" descfield="name" [queryset]="restos$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'tarifs'; desc: 'Recherche par tarif(s)'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="tarifs" descfield="name" [queryset]="tariftypes$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart'; desc: 'Recherche par date : début'; group: 'general'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend'; desc: 'Recherche par date : fin'; group: 'general'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
          </ng-container>
          <ng-container cdkColumnDef="hash">
            <th cdk-header-cell *cdkHeaderCellDef>Hash</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.hash|uppercase|shorten:8:''}}</code></td>
          </ng-container>
          <ng-container cdkColumnDef="storage">
            <th cdk-header-cell *cdkHeaderCellDef>Dépôt</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.storage_details?.cvva}}</code>{{row.storage_details?.name}}<br><small>{{row.storage_details?.member_details?.name}}</small></td>
          </ng-container>
          <ng-container cdkColumnDef="resto">
            <th cdk-header-cell *cdkHeaderCellDef>Restaurant</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.resto_details?.cvva}}</code> {{row.resto_details?.name}}<br/><small><code>{{row.resto_details?.client_details?.cvva}}</code> {{row.resto_details?.client_details?.name}}</small></td>
          </ng-container>
          <ng-container cdkColumnDef="client">
            <th cdk-header-cell *cdkHeaderCellDef>Client</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.client_details?.cvva}}</code> {{row.client_details?.name}}</td>
          </ng-container>
          <ng-container cdkColumnDef="billdate">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="billdate">Date facture</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.billdate|date:'shortDate'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="code">
            <th cdk-header-cell *cdkHeaderCellDef>Code</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.code}}</code></td>
          </ng-container>
          <ng-container cdkColumnDef="brut_amount">
            <th cdk-header-cell *cdkHeaderCellDef>Montant brut</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsCurrency:'brut_amount'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="net_amount">
            <th cdk-header-cell *cdkHeaderCellDef>Montant net</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsCurrency:'net_amount'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="vat_amount">
            <th cdk-header-cell *cdkHeaderCellDef>Montant taxes</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsCurrency:'vat_amount'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="wvat_amount">
            <th cdk-header-cell *cdkHeaderCellDef>Montant TTC</th>
            <td cdk-cell *cdkCellDef="let row">{{row|dsCurrency:'wvat_amount'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="comments">
            <th cdk-header-cell *cdkHeaderCellDef>Commentaires</th>
            <td cdk-cell *cdkCellDef="let row">{{row.comments}}</td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>