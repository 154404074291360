import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {FichesData, FichesDataService} from "../fichesdata.service";
import {firstValueFrom} from "rxjs";


@Component({
  selector: "lvadg-fichesdata-create",
  templateUrl: "./fichesdata-create.component.pug",
  styleUrls: ["./fichesdata-create.component.sass"]
})
export class FichesDataCreateComponent implements OnInit {
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public fd!: FichesData;
  @Output() public created = new EventEmitter<FichesData>();

  constructor(private _fd: FichesDataService) {
  }

  async ngOnInit(): Promise<void> {
    this.fd = await firstValueFrom(this._fd.create({status: "OK"}));
  }

  public async create(): Promise<void> {
    this.fd.assign(this.form.value);
    if (this.fd.type === "CRQ") {
      this.fd.title = "Criteres de qualité";
    } else {
      this.fd.title = "Marquage";
    }
    await firstValueFrom(this.fd.save());
    this.created.next(this.fd);
  }
}
