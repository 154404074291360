export enum Seasons {
  janvier = 1,
  fevrier = 2,
  mars = 3,
  avril = 4,
  mai = 5,
  juin = 6,
  juillet = 7,
  aout = 8,
  septembre = 9,
  octobre = 10,
  novembre = 11,
  decembre = 12
}

export const SEASONS = [
  {value: "1", desc: "janvier"},
  {value: "2", desc: "février"},
  {value: "3", desc: "mars"},
  {value: "4", desc: "avril"},
  {value: "5", desc: "mai"},
  {value: "6", desc: "juin"},
  {value: "7", desc: "juillet"},
  {value: "8", desc: "aout"},
  {value: "9", desc: "septembre"},
  {value: "10", desc: "octobre"},
  {value: "11", desc: "novembre"},
  {value: "12", desc: "décembre"}
];
