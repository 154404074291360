import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {GeoRegion, GeoRegionService} from "./georegion.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class GeoRegionResolve implements Resolve<GeoRegion> {
  constructor(private _bs: GeoRegionService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<GeoRegion> {
    return this._bs.get(+route.params["georegionId"]);
  }
}
