import {CustomDataKeyChoice} from "../customdatakeychoice/customdatakeychoice.service";
import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField,
  DSTextField
} from "@solidev/ngdataservice";
import {Observable, ReplaySubject} from "rxjs";
import {first, map} from "rxjs/operators";

export interface ICustomDataKey {
  id: number;
  code: string;
  created: Date;
  updated: Date;
  description: string;
  dest: string;
  type: "INT" | "DEC" | "CUR" | "STX" | "LTX" | "RTX" | "DTE" | "DTH";
}

export interface IKeyValueSearchResult {
  found: boolean;
  readonly: boolean;
  value?: any;
  altvalue?: any;
}

export class CustomDataKey extends DSModel {
  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;
  @DSForeignKeyField({
    name: "parent",
    verboseName: "Parent",
    required: false,
    helpText: "Parent",
    relatedModel: "common.CustomDataKey",
    remoteField: "id",
    relatedName: "children"
  })
  public parent!: number;
  @DSCharField({
    required: true,
    name: "name",
    helpText: "",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 50,
  })
  public name!: string;
  @DSTextField({
    required: true,
    name: "description",
    helpText: "",
    verboseName: "Description",
    choices: [],
    default: "",
    minLength: 10,
    maxLength: 3000,
  })
  public description!: string;
  @DSSlugField({
    required: true,
    name: "code",
    helpText: "",
    verboseName: "Code",
    choices: [],
    default: null,
    maxLength: 50,
  })
  public code!: string;
  @DSCharField({
    required: true,
    name: "type",
    helpText: "",
    verboseName: "Type",
    choices: [
      ["INT", "Nombre (entier)"],
      ["DEC", "Nombre (décimal)"],
      ["CUR", "Valeur monétaire (euros)"],
      ["STX", "Texte court (250 caractères)"],
      ["LTX", "Texte long"],
      ["RTX", "Texte riche"],
      ["DTE", "Date"],
      ["DTH", "Date & Heure"],
      ["CH1", "Choix unique"],
      ["CHX", "Choix multiple"],
      ["FIL", "Fichier attaché"],
      ["DOC", "Document"],
      ["FOL", "Dossier"],
      ["CAT", "Catégorie"]
    ],
    default: "STX",
    maxLength: 3,
  })
  public type!: string;
  @DSCharField({
    required: true,
    name: "dest",
    helpText: "",
    verboseName: "Destination",
    choices: [
      ["MBR", "Adhérent"],
      ["STO", "Dépôt"],
      ["PFL", "Producteur F&L"],
      ["PMR", "Fournisseur marée"],
      ["PRF", "Produit F&L"],
      ["PRM", "Produit marée"],
      ["PFF", "Famille F&L"],
      ["PFM", "Famille marée"],
      ["FCC", "Fiche catalogue"]
    ],
    default: "STO",
    maxLength: 3,
  })
  public dest!: string;
  @DSCharField({
    required: false,
    name: "unit",
    helpText: "",
    verboseName: "Unité",
    default: "",
    maxLength: 10,
  })
  public unit!: string;
  @DSIntegerField({
    required: false,
    name: "order",
    helpText: "Ordre d'affichage, petit en premier",
    verboseName: "Ordre d'affichage",
    default: 0,
  })
  public order!: number;
  @DSIntegerField({
    name: "lft",
    verboseName: "Left idx",
    required: false,
    helpText: "Left tree id",
    default: 0,
    choices: []
  })
  public lft!: number;
  @DSIntegerField({
    name: "rght",
    verboseName: "Right idx",
    required: false,
    helpText: "Right tree id",
    default: 0,
    choices: []
  })
  public rght!: number;

  @DSIntegerField({
    name: "level",
    verboseName: "Tree level",
    required: false,
    helpText: "Tree level",
    default: 0,
    choices: []
  })
  public level!: number;

  @DSDefaultField({
    required: false,
    name: "reference_int",
    helpText: "",
    verboseName: "reference int",
    choices: [],
    default: 0,
  })
  public reference_int!: any;

  @DSDefaultField({
    required: false,
    name: "reference_dec",
    helpText: "",
    verboseName: "reference dec",
    choices: [],
    default: 0,
  })
  public reference_dec!: any;

  @DSIntegerField({
    name: "tree_id",
    verboseName: "Tree id",
    required: false,
    helpText: "Tree id",
    default: 0,
    choices: []
  })
  public tree_id!: number;
  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;
  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Mise à jour",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSDefaultField({
    required: false,
    name: "flags",
    verboseName: "flags"
  })
  public flags!: string[];


  public choices_details!: CustomDataKeyChoice[];
  private _choices: { [index: number]: CustomDataKeyChoice };

  public get treename(): string {
    return "-".repeat(this.level * 2 + 1) + " " + this.name;
  }

  public choice(id: number): CustomDataKeyChoice {
    if (this._choices === undefined) {
      this._choices = {};
      for (const c of this.choices_details) {
        this._choices[c.id] = c;
      }
    }
    return this._choices[id];
  }

  public values(model: any, alt: string, dest: string): IKeyValueSearchResult {
    const out: IKeyValueSearchResult = {found: false, readonly: false};
    if (model && model.customdata_details) {
      for (const d of model.customdata_details) {
        if (d.key === this.id) {
          out.found = true;
          out.value = d;
          return out;
        }
      }
    }
    if (alt && model[alt]) {
      for (const d of model[alt]) {
        if (d.key === this.id) {
          out.found = true;
          out.altvalue = d;
          if (dest && this.dest !== dest) {
            out.readonly = true;
          }
          return out;
        }
      }
    }
    if (dest && this.dest !== dest) {
      out.readonly = true;
    }
    return out;
  }
}

@Injectable({providedIn: "root"})
export class CustomDataKeyService extends DSRestCollection<CustomDataKey> {
  public adapter_config = {basePath: "/api/v2/customdatakeys"};
  public model = CustomDataKey;
  private _cache: ReplaySubject<CustomDataKey[]>;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }

  public getByDestCode(dest: string, code: string): Observable<CustomDataKey | null> {
    this.checkCache();
    const dests = dest.split(",");
    const codes = code.split(",");
    return this._cache.pipe(
      first(),
      map((keys) => {
        for (const k of keys) {
          if (codes.indexOf(k.code) !== -1 && dests.indexOf(k.dest) !== -1) {
            return k;
          }
        }
        return null;
      }));
  }

  public getByLabel(label: string): Observable<CustomDataKey[]> {
    this.checkCache();
    return this._cache.pipe(
      first(),
      map((keys) => {
        const list: CustomDataKey[] = [];
        for (const k of keys) {
          if (k.flags.indexOf(label) !== -1) {
            list.push(k);
          }
        }
        return list;
      }));
  }

  public getByDest(dest: string, with_categs: boolean = true): Observable<CustomDataKey[]> {
    this.checkCache();
    const dests = dest.split(",");
    return this._cache.pipe(
      first(),
      map((keys) => {
        const list: CustomDataKey[] = [];
        for (const k of keys) {
          if (dests.indexOf(k.dest) !== -1) {
            if (k.type !== "CAT" || (k.type === "CAT" && with_categs)) {
              list.push(k);
            }
          }
        }
        return list;
      }));
  }

  public getByCode(code: string): Observable<CustomDataKey | null> {
    this.checkCache();
    const codes = code.split(",");
    return this._cache.pipe(
      first(),
      map((keys) => {
        for (const k of keys) {
          if (codes.indexOf(k.code) !== -1) {
            return k;
          }
        }
        return null;
      }));
  }

  private checkCache(): void {
    if (!this._cache) {
      this._cache = new ReplaySubject<CustomDataKey[]>(1);
      this.queryset.paginate({page_size: 300}).all().subscribe((keys) => {
        this._cache.next(keys.items);
      });
    }
  }
}
