import {Component} from "@angular/core";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../../lib/comps/routes.service";
import {CommonBaseComponent} from "../../../common-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Relation} from "../../../../../lib/models/structure/relation/relation.service";
import {Subject} from "rxjs";
import {Provider} from "../../../../../lib/models/structure/provider/provider.service";
import {Producer} from "../../../../../lib/models/structure/producer/producer.service";
import {SeaProducer} from "../../../../../lib/models/structure/seaproducer/seaproducer.service";
import {Resto} from "../../../../../lib/models/structure/resto/resto.service";
import {Client} from "../../../../../lib/models/structure/client/client.service";
import {Storage} from "../../../../../lib/models/structure/storage/storage.service";
import {Member} from "../../../../../lib/models/structure/member/member.service";


export interface RelationListViewV3Params extends BaseRouteV3Params {
  provider_detail_route: RouteConfigItemV3;
  producer_detail_route: RouteConfigItemV3;
  seaproducer_detail_route: RouteConfigItemV3;
  resto_detail_route: RouteConfigItemV3;
  client_detail_route: RouteConfigItemV3;
  storage_detail_route: RouteConfigItemV3;
  member_detail_route: RouteConfigItemV3;
  relation_detail_route: RouteConfigItemV3;
}

export interface RelationListViewV3Data extends RelationListViewV3Params {

}


@Component({
  selector: "lvadg-relation-list-view",
  templateUrl: "./relation-list-view.component.pug",
  styleUrls: ["./relation-list-view.component.sass"]
})
export class RelationListViewV3Component extends CommonBaseComponent {
  public reload$ = new Subject<void>();
  public create = false;

  public provider_detail_route!: RouteV3<Provider>;
  public producer_detail_route!: RouteV3<Producer>;
  public seaproducer_detail_route!: RouteV3<SeaProducer>;
  public resto_detail_route!: RouteV3<Resto>;
  public client_detail_route!: RouteV3<Client>;
  public storage_detail_route!: RouteV3<Storage>;
  public member_detail_route!: RouteV3<Member>;
  public relation_detail_route!: RouteV3<Relation>;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }


  public created(model: Relation): void {
    this.reload$.next();
    this.create = false;
  }

  public cancelled(): void {
    this.create = false;
  }

  protected async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as RelationListViewV3Data;
    this.provider_detail_route = new RouteV3<Provider>(data.provider_detail_route, this);
    this.producer_detail_route = new RouteV3<Producer>(data.producer_detail_route, this);
    this.seaproducer_detail_route = new RouteV3<SeaProducer>(data.seaproducer_detail_route, this);
    this.resto_detail_route = new RouteV3<Resto>(data.resto_detail_route, this);
    this.client_detail_route = new RouteV3<Client>(data.client_detail_route, this);
    this.storage_detail_route = new RouteV3<Storage>(data.storage_detail_route, this);
    this.member_detail_route = new RouteV3<Member>(data.member_detail_route, this);
    this.relation_detail_route = new RouteV3<Relation>(data.relation_detail_route, this);
    super.preNgOnInit();
  }

}
