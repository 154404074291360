import {OfferText, OfferTextService} from "./offertext.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class OfferTextResolve implements Resolve<OfferText> {
  constructor(private _model: OfferTextService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<OfferText> {
    return this._model.get(+route.params["offertextId"]);
  }
}
