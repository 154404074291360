<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'status'; desc: 'Recherche par état'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="status" [choices]="STATUSES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'type__status'; desc: 'Recherche par état du modèle'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="type__status" choices="TARIFTYPE_STATUSES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'type__zone'; desc: 'Recherche par zone du modèle'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="type__zone" [choices]="ZONES_CHOICES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'tariftypes'; desc: 'Recherche par modèle'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="tariftypes" [queryset]="ttypes$" descfield="name" [select]="true" [scroll]="true"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'validdate'; desc: 'Recherche par date de validité'; group: 'general'">
        <bsac-model-list-datetime-filter [list]="list" field="validdate"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'preferred'; desc: 'Filtrer mes préférés'; group: 'general'">
        <bsac-model-list-select-filter [list]="list" field="preferred" [choices]="PREFERRED_CHOICES"></bsac-model-list-select-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend__OK__lte'; desc: 'Date de fin (valide) avant'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend__OK__lte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend__OK__gte'; desc: 'Date de fin (valide) après'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend__OK__gte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend__OK_AR__lte'; desc: 'Date de fin (valide / archivé) avant'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend__OK_AR__lte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'dateend__OK_AR__gte'; desc: 'Date de fin (valide / archivé) après'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="dateend__OK_AR__gte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart__OK__lte'; desc: 'Date de début (valide) avant'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart__OK__lte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart__OK__gte'; desc: 'Date de début (valide) après'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart__OK__gte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart__OK_AR__lte'; desc: 'Date de début (valide / archivé) avant'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart__OK_AR__lte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetitem" *bsacFilterSetItem="'datestart__OK_AR__gte'; desc: 'Date de début (valide / archivé) après'; group: 'dates'">
        <bsac-model-list-datetime-filter [list]="list" field="datestart__OK_AR__gte"></bsac-model-list-datetime-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'dates'; desc: 'Recherche par dates'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status|choice:row:'status'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="step">
            <th cdk-header-cell *cdkHeaderCellDef>Etat cycle de vie</th>
            <td cdk-cell *cdkCellDef="let row">{{row.step}}</td>
          </ng-container>
          <ng-container cdkColumnDef="type">
            <th cdk-header-cell *cdkHeaderCellDef>Type de tarif</th>
            <td cdk-cell *cdkCellDef="let row"><a class="link" [routerLink]="tariftype_detail_route?.route({tarifType: row.type_details})">{{row.type_details?.name}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef>Nom</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="getDetailLink(row)">{{row.name}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="message">
            <th cdk-header-cell *cdkHeaderCellDef>Message</th>
            <td cdk-cell *cdkCellDef="let row">{{(row.message||'')|stripTags|shorten:50:'...'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="documents_details">
            <th cdk-header-cell *cdkHeaderCellDef>Documents</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngFor="let d of row.documents_details">
                <div class="document" *ngIf="d.type !== 'ARX'"><a class="icon pointer" [title]="d.title" (click)="download(d)">
                    <ng-container *ngIf="d.image">
                      <div class="img" lvadgRbg [rsrc]="d.image_img" rdisplay="gallery" rsize="thumb"></div>
                    </ng-container>
                    <ng-container *ngIf="!d.image"><i class="fa fa-2x" [ngClass]="docIcon(d)"></i></ng-container></a></div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="datestart">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="datestart">Date de début</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.datestart|date:'fullDate'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="dateend">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="dateend">Date de fin</bsac-model-list-sorter>
            </th>
            <td cdk-cell *cdkCellDef="let row">{{row.dateend|date:'fullDate'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="user_level">
            <th cdk-header-cell *cdkHeaderCellDef>Droits utilisateur</th>
            <td cdk-cell *cdkCellDef="let row">{{row.user_level}}</td>
          </ng-container>
          <ng-container cdkColumnDef="user_storages">
            <th cdk-header-cell *cdkHeaderCellDef>Dépôts utilisateur</th>
            <td cdk-cell *cdkCellDef="let row">
              <div *ngFor="let s of row.user_storages_details">{{s.name}}</div>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="type__zone">
            <th cdk-header-cell *cdkHeaderCellDef>Zone</th>
            <td cdk-cell *cdkCellDef="let row">{{row.type_details.zone|bsacApiConstantSingle:"TARIFTYPE_ZONES"}}</td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>