
<div class="form-group" *ngIf="param">
  <form [formGroup]="form">
    <div class="form-group">
      <label><i class="fa fa-trash pointer float-end" (click)="removeParam()"></i>{{param.label}}</label>
      <select class="form-select" formControlName="value">
        <option *ngFor="let c of param.choices" [ngValue]="c.value">{{c.desc}}</option>
      </select>
      <div class="help-block" *ngIf="param.help">{{param.help}}</div>
    </div>
    <form [formGroup]="txtform" *ngIf="form.value.value=='CUSTOM'">
      <div class="form-group">
        <label>Texte personnalisé</label>
        <div class="input-group">
          <input class="form-control" formControlName="svalue">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="saveCustom()"><i class="fa fa-save"></i></button>
          </div>
        </div>
      </div>
    </form>
  </form>
</div>