import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Location, LocationService} from "./location.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class LocationResolve implements Resolve<Location> {
  constructor(private _bs: LocationService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Location> {
    return this._bs.get(+route.params["locationId"]);
  }
}
