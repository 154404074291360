import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";
import {Location} from "../location/location.service";

export interface RestoRelation {
  id: number;
  type: string;
  distance_details: {
    road: number;
    direct: number;
    rtime: number;
  };
  storage_details: {
    id: number;
    name: string;
  };
}


export class Resto extends DSModel {


  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSCharField({
    required: true,
    name: "name",
    helpText: "",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 60,
  })
  public name!: string;

  @DSCharField({
    name: "cvva",
    verboseName: "Code Vivalya",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 20,
    choices: []
  })
  public cvva!: string;

  @DSCharField({
    name: "cclient",
    verboseName: "Code client",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 20,
    choices: []
  })
  public cclient!: string;

  @DSCharField({
    required: false,
    name: "type",
    helpText: "",
    verboseName: "Type",
    choices: [
      ["RINC", "Type non connu / non renseigné"],
      ["RCOH", "Rest. collective (hospitalier)"],
      ["RCOS", "Rest. collective (scolaire)"],
      ["RCOE", "Rest. collective (entreprise)"],
      ["RCOR", "Rest. collective (maison de retraite)"],
      ["RCOC", "Rest. collective (cuisine centrale)"],
      ["RCMR", "Rest. commerciale (restaurant)"],
      ["RCMT", "Rest. commerciale (traiteur)"],
      ["RCMR", "Rest. commerciale (restauration rapide à emporter"],
      ["RCMC", "Rest. commerciale (cafétérias)"],
      ["RCMB", "Rest. commerciale (boulangerie)"]
    ],
    default: null,
    maxLength: 4,
  })
  public type!: string;

  @DSCharField({
    required: false,
    name: "content_type",
    helpText: "",
    verboseName: "Format description",
    choices: [],
    default: "md",
    maxLength: 4,
  })
  public content_type!: string;

  @DSTextField({
    required: false,
    name: "content_src",
    helpText: "",
    verboseName: "Description (source)",
    choices: [],
    default: "",
    maxLength: 4000,
    minLength: 0
  })
  public content_src!: string;

  @DSTextField({
    required: false,
    name: "content",
    helpText: "",
    verboseName: "Description (html)",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content!: string;

  @DSManyToManyField({
    name: "slabels",
    verboseName: "Labels structure",
    required: false
  })
  public slabels!: number[];

  @DSTextField({
    required: false,
    name: "mainaddress",
    helpText: "",
    verboseName: "Adresse principale",
    choices: [],
    default: null,
    maxLength: 400,
  })
  public mainaddress!: string;

  @DSCharField({
    required: false,
    name: "phone",
    helpText: "",
    verboseName: "Téléphone",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public phone!: string;

  @DSCharField({
    required: false,
    name: "fax",
    helpText: "",
    verboseName: "Fax",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public fax!: string;

  @DSCharField({
    required: false,
    name: "website",
    helpText: "",
    verboseName: "Site web",
    choices: [],
    default: null,
    maxLength: 400,
  })
  public website!: string;

  @DSCharField({
    required: false,
    name: "siren",
    helpText: "",
    verboseName: "SIREN",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public siren!: string;

  @DSCharField({
    required: false,
    name: "siret",
    helpText: "",
    verboseName: "SIRET",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public siret!: string;

  @DSCharField({
    required: false,
    name: "tvaic",
    helpText: "",
    verboseName: "N°TVA",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public tvaic!: string;

  @DSCharField({
    required: false,
    name: "naf",
    helpText: "",
    verboseName: "Code NAF",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public naf!: string;

  @DSCharField({
    required: false,
    name: "legal",
    helpText: "",
    verboseName: "Structure légale",
    choices: [],
    default: null,
    maxLength: 50,
  })
  public legal!: string;

  @DSCharField({
    required: false,
    name: "capital",
    helpText: "",
    verboseName: "Capital",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public capital!: string;


  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "Valide"],
      ["KO", "Non valide - manuel"],
      ["CL", "Fermé"],
      ["UN", "Inconnu"],
      ["PR", "A prospecter"]
    ],
    default: null,
    maxLength: 4,
  })
  public status!: string;

  @DSDateTimeField({
    name: "datestart",
    verboseName: "Date ouverture",
    required: false,
    helpText: "",
    choices: []
  })
  public datestart!: Date;

  @DSDateTimeField({
    name: "dateend",
    verboseName: "Date fermeture",
    required: false,
    helpText: "",
    choices: []
  })
  public dateend!: Date;


  @DSManyToManyField({
    name: "locations",
    verboseName: "Emplacements",
    required: false,
    relatedModel: "structure.Locations",
    remoteField: "resto",
    helpText: "",
    default: [],
  })
  public locations!: number[];
  public locations_details: Location[] = [];

  @DSManyToManyField({
    required: false,
    name: "images",
    helpText: "",
    verboseName: "Images",
    choices: [],
    default: [],
  })
  public images!: number[];
  public images_details: Rimg[] = [];

  @DSForeignKeyField({
    required: false,
    name: "client",
    helpText: "",
    verboseName: "Client",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Client",
    remoteField: "restos",
  })
  public client!: number;
  public client_details!: any;

  @DSForeignKeyField({
    required: false,
    name: "logo",
    helpText: "",
    verboseName: "Logo",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "logoresto",
  })
  public logo!: number;
  public logo_img!: Rimg;

  @DSManyToManyField({
    name: "relations",
    verboseName: "Relations",
    required: false,
    relatedModel: "structure.Relation",
    remoteField: "restos",
    helpText: "",
    default: [],
  })
  public relations!: number[];
  public relations_details!: RestoRelation[];

  @DSManyToManyField({
    name: "sites",
    verboseName: "Sites",
    required: false,
    relatedModel: "space.Site",
    remoteField: "restos",
    helpText: "",
    default: [],
  })
  public sites!: number[];

  public mainlocation!: number;
  public mainlocation_details!: { city: string; postcode: string; address: string; latitude: number; longitude: number };

  // Stats
  @DSDecimalField({
    name: "amount_last30",
    verboseName: "Facturation J-30",
    required: false,
    helpText: "",
    default: 0,
    factor: 1000,
    choices: []
  })
  public amount_last30!: number;


}

@Injectable({
  providedIn: "root"
})
export class RestoService extends DSRestCollection<Resto> {
  public adapter_config = {basePath: "/api/v2/structure/restos"};
  public model = Resto;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
