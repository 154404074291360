import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UploadImportComponent} from "./upload-import/upload-import.component";
import {CompsModule} from "../../../comps/comps.module";
import {UploadDetailsComponent} from "./upload-details/upload-details.component";
import {ProductVariantModule} from "../../product/productvariant/productvariant.module";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UploadGeocodeComponent} from "./upload-geocode/upload-geocode.component";
import {UploadXlsxMapComponent} from "./upload-xlsx-map/upload-xlsx-map.component";

@NgModule({
  imports: [
    CommonModule,
    CompsModule,
    BsacFormsModule,
    ReactiveFormsModule,
    FormsModule,
    ProductVariantModule
  ],
  declarations: [UploadImportComponent, UploadDetailsComponent, UploadGeocodeComponent, UploadXlsxMapComponent],
  exports: [UploadImportComponent, UploadDetailsComponent, UploadGeocodeComponent, UploadXlsxMapComponent],
})
export class GenericUploadModule {
}
