import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ArticleTarifTemplate} from "../articletariftemplate.service";
import {firstValueFrom} from "rxjs";
import {TimeSeriesChartDataset, TimeSeriesStat} from "../../../../comps/charts/formats";

@Component({
  selector: "lvadg-articletariftemplate-stats",
  templateUrl: "./articletariftemplate-stats.component.pug",
  styleUrls: ["./articletariftemplate-stats.component.sass"],
})
export class ArticleTarifTemplateStatsComponent implements OnChanges {
  @Input() public att!: ArticleTarifTemplate;
  public charts!: TimeSeriesChartDataset[];

  constructor() {
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!changes["att"]) {
      return;
    }
    if (changes["att"].previousValue?.id !== changes["att"].currentValue?.id) {
      this.loadStats();
    }
  }

  public async loadStats(): Promise<void> {
    const res: TimeSeriesStat[] = (await firstValueFrom(this.att.action("stats", {
      method: "GET",
      query: {stats: "tarif_article_sales,all_article_sales,price_history"}
    })));
    this.charts = [];
    for (const c of res) {
      const chart = {
        title: c.title,
        subtitle: c.subtitle,
        legend: c.series.length > 1,
        stacked: false,
        axis: {
          time: {title: "Date", unit: ""},
          value: {title: c.label, unit: c.unit}
        },
        series: [] as any[]
      };
      for (const s of c.series) {
        const ser = {
          type: "line",
          symbol: "circle",
          symbolSize: 8,
          name: s.name,
          data: [] as ([Date, number][]),
          areaStyle: {opacity: res.length > 1 ? 0 : 0.5}
        };
        let haveZeros = false;
        for (const v of s.values) {
          if (v.value !== 0) {
            haveZeros = true;
            const [year, week] = v.name.split("/");
            ser.data.push([new Date(+year, 0, +week * 7), v.value]);
          }
        }
        if (haveZeros) {
          if (c.series.length > 1) {
            ser.type = "scatter";
          } else {
            ser.type = "bar";
          }
        }
        (chart.series as any[]).push(ser);
      }
      this.charts.push(chart);
    }
  }
}
