
<ng-container *ngIf="at.status!=ArticleTarifStatus.INACTIVE">
  <ng-container *ngIf="mode==='price'">
    <form [formGroup]="form" *ngIf="editable">
      <input class="form-control form-control-sm price-editor" #editor formControlName="price" (focus)="onFocus($event)" (blur)="onBlur($event)" (click)="onFocus($event)" [class.changed]="changed" [class.pristine]="isPristine" [class.nopristine]="!isPristine" (keyup.enter)="save(true, false)" (keyup.shift.enter)="save(false, true)"><span>{{ati?.created}}</span>
    </form>
    <div class="price" *ngIf="!editable &amp;&amp; ati">
      <ng-container *ngIf="ati?.price">{{ati!.price/10000|currency:'EUR':'symbol':'1.2-3':'fr'}}</ng-container>
    </div>
    <div class="price" *ngIf="!editable &amp;&amp; !ati &amp;&amp; !storageId &amp;&amp; !marcheId">
      <ng-container *ngIf="at?.price">{{at!.price/10000|currency:'EUR':'symbol':'1.2-3':'fr'}}</ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="mode==='dispo'">
    <ng-container *ngIf="!editable"><i class="fa fa-check text-success" *ngIf="available" [class.pristine]="isPristine" [class.nopristine]="!isPristine"></i></ng-container>
    <ng-container *ngIf="editable"><i class="fa fa-check text-success" *ngIf="available" role="button" (dblclick)="toggleAvailable()" title="Double clic pour désactiver" [class.pristine]="isPristine" [class.nopristine]="!isPristine"></i><i class="fa fa-crosshairs text-muted" *ngIf="!available" role="button" (dblclick)="toggleAvailable()" title="Double clic pour activer" [class.pristine]="isPristine" [class.nopristine]="!isPristine"></i></ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="at.status===ArticleTarifStatus.INACTIVE">NA</ng-container>