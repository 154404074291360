import {Component, Input, OnChanges} from "@angular/core";
import {Folder, FolderAccess} from "../folder.service";
import {Client} from "../../../structure/client/client.service";
import {Member} from "../../../structure/member/member.service";
import {Resto} from "../../../structure/resto/resto.service";
import {Person} from "../../../people/person/person.service";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Component({
  selector: "lvadg-folder-setaccess",
  templateUrl: "./folder-setaccess.component.pug",
  styleUrls: ["./folder-setaccess.component.sass"]
})
export class FolderSetaccessComponent implements OnChanges {
  @Input() public folder!: Folder;
  public curaccess!: FolderAccess;
  public show: any = {};

  constructor() {
  }

  public ngOnChanges(): void {
    if (this.folder.read_access_raw) {
      this.curaccess = new FolderAccess(this.folder.read_access_raw);
    } else {
      this.curaccess = new FolderAccess(this.folder.read_access_details);
      delete this.curaccess.id;
    }
  }

  public save(): Observable<void> {
    return this.folder.action("set_access", {method: "POST", body: this.curaccess})
      .pipe(tap((access) => {
        this.curaccess = new FolderAccess(access);
      }));
  }

  public async addMember(member: Member): Promise<void> {
    this.curaccess.members.push(member.id);
    await this.save().toPromise();
    this.show.addmember = false;

  }

  public async removeMember(memberId: number): Promise<void> {
    this.curaccess.members.splice(this.curaccess.members.indexOf(memberId), 1);
    await this.save().toPromise();
  }

  public async addClient(client: Client): Promise<void> {
    this.curaccess.clients.push(client.id);
    await this.save().toPromise();
    this.show.addclient = false;
  }

  public async removeClient(clientId: number): Promise<void> {
    this.curaccess.clients.splice(this.curaccess.clients.indexOf(clientId), 1);
    await this.save().toPromise();
  }

  public async addResto(resto: Resto): Promise<void> {
    this.curaccess.restos.push(resto.id);
    await this.save().toPromise();
    this.show.addresto = false;
  }

  public async removeResto(restoId: number): Promise<void> {
    this.curaccess.restos.splice(this.curaccess.restos.indexOf(restoId), 1);
    await this.save().toPromise();
  }

  public async addPerson(person: Person): Promise<void> {
    this.curaccess.persons.push(person.id);
    await this.save().toPromise();
    this.show.addperson = false;
  }

  public async removePerson(personId: number): Promise<void> {
    this.curaccess.persons.splice(this.curaccess.persons.indexOf(personId), 1);
    await this.save().toPromise();
  }


  public async setAdminOnly(status: boolean = true): Promise<void> {
    this.curaccess.admin_only = status;
    await this.save().toPromise();
  }

  public async setAllMembers(status: boolean = true): Promise<void> {
    this.curaccess.all_members = status;
    await this.save().toPromise();
  }

  public async setAllClients(status: boolean = true): Promise<void> {
    this.curaccess.all_clients = status;
    await this.save().toPromise();
  }

  public async setAllRestos(status: boolean = true): Promise<void> {
    this.curaccess.all_restos = status;
    await this.save().toPromise();
  }
}
