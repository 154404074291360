import {Storage, StorageService} from "../storage.service";
import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {Observable} from "rxjs";
import {Member} from "../../member/member.service";

@Component({
  selector: "lvadg-storage-select",
  templateUrl: "./storage-select.component.pug",
  styleUrls: ["./storage-select.component.sass"]
})
export class StorageSelectComponent implements OnChanges {
  public queryset!: DSRestQueryset<Storage>;
  @Input() public hint = "Selectionnez un dépôt";
  @Input() public reset!: Observable<any>;
  @Input() public label = "Sélectionnez un dépôt";
  @Input() public desctpl!: string;
  @Input() public descfield = "name";
  @Input() public filter: any = {};
  @Input() public searchparam = "search";

  @Input() public member!: Member;

  @Output() public selected: EventEmitter<Storage> = new EventEmitter<Storage>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();


  constructor(public _service: StorageService) {
  }

  public ngOnChanges(): void {
    if (this.member) {
      this.filter.member = this.member.id;
    }
  }

  public selItem(model: any): void {
    this.selected.emit(model);
  }

}
