import {NgModule} from "@angular/core";
import {CiqualColumnListComponent} from "./ciqualcolumn-list/ciqualcolumn-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {CiqualColumnManageComponent} from "./ciqualcolumn-manage/ciqualcolumn-manage.component";


@NgModule({
  declarations: [CiqualColumnListComponent, CiqualColumnManageComponent],
  exports: [CiqualColumnListComponent, CiqualColumnManageComponent],
  imports: [
    ...MODELLIST_IMPORTS
  ]
})
export class CiqualcolumnModule {
}
