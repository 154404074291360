import {Injectable} from "@angular/core";
import {DSAutoField, DSCharField, DSModel, DSRestCollection, DSRestCollectionSetupProvider, DSSlugField} from "@solidev/ngdataservice";


export class ArticleAllergene extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["PR", "Présence de"],
      ["TR", "Traces de"]
    ]
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;
}

@Injectable({
  providedIn: "root"
})
export class ArticleAllergeneService extends DSRestCollection<ArticleAllergene> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/allergenes"};
  public model = ArticleAllergene;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
