import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";


export class Upload extends DSModel {
  @DSAutoField({
    name: "id"
  })
  public id!: number;

  @DSSlugField({
    name: "code",
    verboseName: "Code",
    maxLength: 20,
    required: false
  })
  public code!: string;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    maxLength: 200,
    required: false
  })
  public name!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    maxLength: 5,
    required: false,
    choices: [["IMPV", "Importation nomenclatures FL"]]
  })
  public type!: string;

  @DSCharField({
    name: "mimetype",
    verboseName: "Mime type",
    maxLength: 250,
    required: false
  })
  public mimetype!: string;

  @DSCharField({
    name: "filename",
    verboseName: "Nom fichier",
    maxLength: 250,
    required: false
  })
  public filename!: string;

  @DSIntegerField({
    name: "filesize",
    verboseName: "Taille fichier",
    required: false
  })
  public filesize!: number;

  @DSCharField({
    name: "file",
    verboseName: "Fichier",
    required: false
  })
  public file!: string;

  @DSDefaultField({
    name: "tags",
    verboseName: "Tags",
    required: false
  })
  public tags!: string[];

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "MAJ",
    required: false
  })
  public updated!: Date;

  @DSDefaultField({
    name: "parameters",
    verboseName: "Params",
    required: false
  })
  public parameters!: any;

  @DSDefaultField({
    name: "output",
    verboseName: "Sortie",
    required: false
  })
  public output!: any;



}


@Injectable({
  providedIn: "root"
})
export class UploadService extends DSRestCollection<Upload> {
  public adapter_config = {basePath: "/api/v2/document/uploads"};
  public model = Upload;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
