import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {FolderTree, FolderTreeService} from "../folder-tree.service";
import {Folder} from "../folder.service";
import {Subject} from "rxjs";
import {switchMap, take, takeUntil} from "rxjs/operators";

@Component({
  selector: "lvadg-folder-breadcrumb",
  templateUrl: "./folder-breadcrumb.component.pug",
  styleUrls: ["./folder-breadcrumb.component.sass"]
})
export class FolderBreadcrumbComponent implements OnInit, OnDestroy {
  @Input() public treeName = "default";
  @Input() public mode = "admin";
  @Output() public create = new EventEmitter<void>();

  @Input() public tree!: FolderTree;
  public current!: Folder;
  public parents!: Folder[];
  private _subscriptions = new Subject<void>();

  constructor(private _trees: FolderTreeService) {

  }

  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public ngOnInit(): void {
    if (!this.tree) {
      this.tree = this._trees.load(this.treeName);
    }
    this.tree.current$.pipe(
      takeUntil(this._subscriptions),
      switchMap((current) => {
        this.current = current;
        return this.tree.getParents(current).pipe(
          take(1)
        );
      })
    ).subscribe((parents) => {
      this.parents = parents;
    });
  }

  public goCreate(): void {
    this.tree.display.create = !this.tree.display.create;
    this.create.emit();
  }

}
