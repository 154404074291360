import {Component, Input, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BsacMessageService, BsacModelList} from "@solidev/bsadmincomponents";
import {DSModel, DSRestBackend} from "@solidev/ngdataservice";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-modellist-xlsx-export",
  templateUrl: "./modellist-xlsx-export.component.pug",
  styleUrls: ["./modellist-xlsx-export.component.sass"]
})
export class ModellistXlsxExportComponent implements OnInit {
  @Input() public list!: BsacModelList<DSModel>;
  @Input() public mode: string = "admin";
  public exportingXlsx: boolean = false;

  constructor(private _http: HttpClient,
              private _msgs: BsacMessageService) {
  }

  ngOnInit(): void {
  }

  public checkMode(...args: string[]): boolean {
    return args.indexOf(this.mode) !== -1;
  }


  public async exportAsExcel(): Promise<void> {
    this.exportingXlsx = true;
    const a = document.createElement("a");
    document.body.appendChild(a);
    (a as any).style = "display: none";
    const createXlsxFromBlob = (file: Blob) => {
      a.download = "export.xlsx";
      a.href = window.URL.createObjectURL(file);
      a.click();
    };
    const query = await firstValueFrom(this.list.filter.output$);
    const fields = await firstValueFrom(this.list.fields.output$);
    // Get special qsparams from list and merge them to query
    const qsparams = this.list.params?.query || {};
    Object.assign(query, qsparams);
    query.fields = fields.join(",");
    query.exportas = "xlsx";
    const adapt = this.list.queryset.collection.adapter.list({query: query});
    const options = (this.list.queryset.collection.backend as DSRestBackend).getRequestOptions(adapt);
    // Force accept header to excel mimetype
    (options.headers as {[header: string]: string})["accept"] = "application/xlsx";
    options.responseType = "blob";
    (options.params as {[param: string]: string})["page_size"] = "10000";
    this._http.request("GET", (this.list.queryset.collection.backend as DSRestBackend).getRequestUrl(adapt), {
      responseType: "blob",
      headers: options.headers,
      params: options.params
    }).subscribe({
      next: (res) => {
        this.exportingXlsx = false;
        this._msgs.success("L'export Xslx est prêt");
        createXlsxFromBlob(res);
      },
      error: (err) => {
        this._msgs.error("Erreur lors de l'export xlsx", err);
        this.exportingXlsx = false;
      }
    });
  }

}
