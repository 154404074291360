
<div class="card mb-2" [ngClass]="getCardClasses('PRP')" *ngIf="mainSteps.indexOf('PRP') &gt;= 0">
  <div class="card-header pointer" [ngClass]="getCardHeaderClasses('PRP')" (click)="collapsed['PRP'] = !collapsed['PRP']">{{mainSteps.indexOf('PRP')+1}}. Préparation<small class="ms-2">date limite {{tarif.preparationLimitDate|date:'short'}}</small></div>
  <div class="row" [ngbCollapse]="collapsed['PRP']">
    <div class="col-md-4">
      <ol class="list-group list-group-flush">
        <li class="list-group-item" [ngClass]="getStepClasses('PRP', 'edt')">
          <div class="d-flex flex-row justify-content-between">
            <div class="pointer" (click)="setStep('PRP', 'edt')">1. Edition du tarif</div>
            <div><span><small>{{getStepLogItem('PRP', 'edt')?.created|date:'short'}}</small> {{getStepLogItem('PRP', 'edt')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses('PRP', 'edt')" (click)="checkStep('PRP', 'edt')"></i></div>
          </div>
        </li>
        <li class="list-group-item" [ngClass]="getStepClasses('PRP', 'exp')">
          <div class="d-flex flex-row justify-content-between">
            <div class="pointer" (click)="setStep('PRP', 'exp')">2. Export excel et vérification</div>
            <div><span><small>{{getStepLogItem('PRP', 'exp')?.created|date:'short'}}</small>  {{getStepLogItem('PRP', 'exp')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses('PRP', 'exp')" (click)="checkStep('PRP', 'exp')"></i></div>
          </div>
        </li>
        <li class="list-group-item" [ngClass]="getStepClasses('PRP', 'val')">
          <div class="d-flex flex-row justify-content-between">
            <div class="pointer" (click)="setStep('PRP', 'val')">
              <ng-container *ngIf="mainSteps.indexOf('VAL')&gt;=0">3. Passage en mode validation</ng-container>
              <ng-container *ngIf="mainSteps.indexOf('VAL')==-1">3. Passage en mode publication</ng-container>
            </div>
            <div><span><small>{{getStepLogItem('PRP', 'val')?.created|date:'short'}}</small> {{getStepLogItem('PRP', 'val')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses('PRP', 'val')" (click)="checkStep('PRP', 'val')"></i></div>
          </div>
        </li>
      </ol>
    </div>
    <div class="col-md-8">
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='PRP' &amp;&amp; currentStep.sub==='edt'">
        <button class="btn btn-primary mt-4 p-4 w-50" (click)="action.emit('editPrices')" data-cy="lifecycle-step1-editlines"><i class="fa fa-pencil me-2"></i>Editer les lignes de tarif</button>
        <button class="btn btn-primary mt-4 p-4 w-50" (click)="action.emit('editTarif')" data-cy="lifecycle-step1-editinfos"><i class="fa fa-cogs me-2"></i>Editer les infos du tarif</button>
        <button class="btn btn-outline-success mt-3 w-50" (click)="checkStep('PRP', 'edt')" data-cy="lifecycle-prp-edt-validate"><i class="fa fa-check me-2"></i>Valider l'étape</button>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='PRP' &amp;&amp; currentStep.sub==='exp'">
        <lvadg-tarif-export class="w-50 mt-4 d-block" [tarif]="tarif" [mode]="tarif.type_details?.lifecycle===LifeCycles.GENERIC?'nego':'export'" btnClasses="btn btn-primary p-4 w-100" label="Exporter le tarif"></lvadg-tarif-export>
        <button class="btn btn-outline-success mt-3 w-50" (click)="checkStep('PRP', 'exp')" data-cy="lifecycle-prp-exp-validate"><i class="fa fa-check me-2"></i>Valider l'étape</button>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='PRP' &amp;&amp; currentStep.sub==='val'">
        <button class="btn btn-outline-success mt-4 p-4 w-50" (click)="checkStep('PRP', 'val')" data-cy="lifecycle-prp-val-confirm"><i class="fa fa-check me-2"></i>
          <ng-container *ngIf="mainSteps.indexOf('VAL')&gt;=0">Confirmer le passage en mode "validation"</ng-container>
          <ng-container *ngIf="mainSteps.indexOf('VAL')==-1">Confirmer le passage en mode "publication"</ng-container>
        </button>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main!=='PRP'">
        <p class="text-success mt-5">Toutes les étapes de préparation ont été validées</p>
      </div>
    </div>
  </div>
</div>
<div class="card mb-2" [ngClass]="getCardClasses('VAL')" *ngIf="mainSteps.indexOf('VAL') &gt;= 0">
  <div class="card-header pointer" [ngClass]="getCardHeaderClasses('VAL')" (click)="collapsed['VAL'] = !collapsed['VAL']">{{mainSteps.indexOf('VAL')+1}}. Validation & négociation<small class="ms-2">date limite {{tarif.validationLimitDate|date:'short'}}</small></div>
  <div class="row" [ngbCollapse]="collapsed['VAL']">
    <div class="col-md-4">
      <ol class="list-group list-group-flush">
        <li class="list-group-item" [ngClass]="getStepClasses('VAL', 'snd')">
          <div class="d-flex flex-row justify-content-between">
            <div class="pointer" (click)="setStep('VAL', 'snd')">1. Envoi excel
              <ng-container class="ms-2" *ngIf="tarif.type_details.type==='CLI'">au client</ng-container>
            </div>
            <div><span><small>{{getStepLogItem('VAL', 'snd')?.created|date:'short'}}</small>  {{getStepLogItem('VAL', 'snd')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses('VAL', 'snd')" (click)="checkStep('VAL', 'snd')"></i></div>
          </div>
        </li>
        <li class="list-group-item" [ngClass]="getStepClasses('VAL', 'imp')">
          <div class="d-flex flex-row justify-content-between">
            <div class="pointer" (click)="setStep('VAL', 'imp')">2. Phase de négociation</div>
            <div><span><small>{{getStepLogItem('VAL', 'imp')?.created|date:'short'}}</small> {{getStepLogItem('VAL', 'imp')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses('VAL', 'imp')" (click)="checkStep('VAL', 'imp')"></i></div>
          </div>
        </li>
        <li class="list-group-item" [ngClass]="getStepClasses('VAL', 'pub')">
          <div class="d-flex flex-row justify-content-between">
            <div class="pointer" (click)="setStep('VAL', 'pub')">3. Passage en mode publication</div>
            <div><span><small>{{getStepLogItem('VAL', 'pub')?.created|date:'short'}}</small>  {{getStepLogItem('VAL', 'pub')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses('VAL', 'pub')" (click)="checkStep('VAL', 'pub')"></i></div>
          </div>
        </li>
      </ol>
    </div>
    <div class="col-md-8">
      <div class="d-flex flex-column align-items-center p-4" *ngIf="currentStep.main==='VAL' &amp;&amp; currentStep.sub==='snd'">
        <div class="w-75">
          <lvadg-tarif-send [tarif]="tarif" (sent)="checkStep('VAL', 'snd')"></lvadg-tarif-send>
        </div>
        <button class="btn btn-outline-danger btn-sm mt-3 w-75" (click)="checkStep('VAL', 'snd')" data-cy="lifecycle-val-snd-validate"><i class="fa fa-check me-2"></i>Message déjà envoyé ou pas de message, passer l'étape</button>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='VAL' &amp;&amp; currentStep.sub==='imp'">
        <lvadg-tarif-import class="w-50 mt-4" [tarif]="tarif" btnClasses="btn btn-primary p-4 w-100"></lvadg-tarif-import>
        <button class="btn btn-primary mt-4 p-4 w-50" (click)="action.emit('editPrices')" data-cy="lifecycle-val-imp-editprices"><i class="fa fa-pencil me-2"></i>Editer les lignes de tarif</button>
        <button class="btn btn-primary mt-4 p-4 w-50" (click)="action.emit('editTarif')" data-cy="lifecycle-val-imp-edittarif"><i class="fa fa-cogs me-2"></i>Editer les infos du tarif</button>
        <lvadg-tarif-export class="w-50 mt-4 d-block" [tarif]="tarif" btnClasses="btn btn-primary p-4 w-100" label="Exporter le tarif"></lvadg-tarif-export>
        <button class="btn btn-outline-success mt-3 w-50" (click)="checkStep('VAL', 'imp')"  data-cy="lifecycle-val-imp-validate"><i class="fa fa-check me-2"></i>Valider l'étape</button>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='VAL' &amp;&amp; currentStep.sub==='pub'">
        <button class="btn btn-primary mt-4 p-4 w-50" (click)="action.emit('editPrices')" data-cy="lifecycle-val-pub-editprices"><i class="fa fa-pencil me-2"></i>Si nécessaire, vérifier les prix</button>
        <button class="btn btn-primary mt-4 p-4 w-50" (click)="action.emit('editTarif')" data-cy="lifecycle-val-pub-edittarif"><i class="fa fa-cogs me-2"></i>Si nécessaire, vérifier les infos du tarif</button>
        <lvadg-tarif-export class="w-50 mt-4" [tarif]="tarif" btnClasses="btn btn-primary p-4 w-100" label="Si nécessaire, vérifier l'export excel"></lvadg-tarif-export>
        <button class="btn btn-outline-success mt-4 p-4 w-50" (click)="checkStep('VAL', 'pub')" data-cy="lifecycle-val-pub-validate"><i class="fa fa-check me-2"></i>Confirmer le passage en mode "publication"</button>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='PUB' || currentStep.main.startsWith('UPD')">
        <p class="text-success mt-5">Toutes les étapes de validation ont été effectuées</p>
      </div>
    </div>
  </div>
</div>
<div class="card mb-2" [ngClass]="getCardClasses('PUB')" *ngIf="mainSteps.indexOf('PUB') &gt;= 0">
  <div class="card-header pointer" [ngClass]="getCardHeaderClasses('PUB')" (click)="collapsed['PUB'] = !collapsed['PUB']">{{mainSteps.indexOf('PUB')+1}}. Publication<small class="ms-2">date limite {{tarif.publicationLimitDate|date:'short'}}</small></div>
  <div class="row" [ngbCollapse]="collapsed['PUB']">
    <div class="col-md-4">
      <ol class="list-group list-group-flush">
        <li class="list-group-item" [ngClass]="getStepClasses('PUB', 'msg')">
          <div class="d-flex flex-row justify-content-between">
            <div class="pointer" (click)="setStep('PUB', 'msg')">1. Création du message de notification</div>
            <div><span><small>{{getStepLogItem('PUB', 'msg')?.created|date:'short'}}</small>  {{getStepLogItem('PUB', 'msg')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses('PRP', 'msg')" (click)="checkStep('PRP', 'msg')"></i></div>
          </div>
        </li>
        <li class="list-group-item" [ngClass]="getStepClasses('PUB', 'pub')">
          <div class="d-flex flex-row justify-content-between">
            <div class="pointer" (click)="setStep('PUB', 'pub')">2. Publication du tarif</div>
            <div><span><small>{{getStepLogItem('PUB', 'pub')?.created|date:'short'}}</small>  {{getStepLogItem('PUB', 'pub')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses('PUB', 'pub')" (click)="checkStep('PUB', 'pub')"></i></div>
          </div>
        </li>
      </ol>
    </div>
    <div class="col-md-8">
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='PUB' &amp;&amp; currentStep.sub==='msg'">
        <lvadg-tarifnotification-create class="w-75" [tarif]="tarif" type="PUB" status="PRP" (created)="checkStep('PUB', 'msg')" [cancel]="false" [create]="true"></lvadg-tarifnotification-create>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='PUB' &amp;&amp; currentStep.sub==='pub'">
        <button class="btn btn-primary mt-4 p-4 w-50" (click)="tarifPublish('PUB', 'pub')" [disabled]="waiting.tarifPublish" data-cy="lifecycle-pub-pub-launch">
          <ng-container *ngIf="!waiting.tarifPublish"><i class="fa fa-rocket-launch me-2"></i>Lancer la publication</ng-container>
          <ng-container *ngIf="waiting.tarifPublish"><i class="fa fa-spin fa-spinner me-2"></i>Publication en cours, merci de patienter
            <div class="text-info">La publication peut prendre jusqu'à une vingtaine de secondes selon la taille du tarif</div>
          </ng-container>
        </button>
        <!--button.btn.btn-outline-danger.mt-3.w-50((click)="checkStep('PUB', 'pub')", data-cy="lifecycle-pub-pub-nolaunch")-->
        <!--  i.fa.fa-check.me-2-->
        <!--  | Valider l'étape sans lancer la publication-->
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main==='PUB' &amp;&amp; currentStep.sub ==='pdone'">
        <p class="text-success mt-5" data-cy="lifecycle-pub-initial-success-message">Toutes les étapes de publication ont été effectuées</p>
        <button class="btn btn-outline-warning mt-4 p-4 w-50" (click)="checkStep('PUB', 'pdone')"><i class="fa fa-pencil me-2"></i>Créer un erratum pour ce tarif</button>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="['PRP', 'VAL', 'PUB'].indexOf(currentStep.main) === -1">
        <p class="text-success mt-5" data-cy="lifecycle-pub-next-success-message">Toutes les étapes de publication ont été effectuées</p>
      </div>
    </div>
  </div>
</div>
<ng-container *ngFor="let upd of updateSteps">
  <ng-container *ngIf="mainSteps.indexOf(upd) &gt;= 0">
    <div class="card mb-2" [ngClass]="getCardClasses(upd)">
      <div class="card-header pointer" [ngClass]="getCardHeaderClasses(upd)" (click)="collapsed[upd] = !collapsed[upd]">({{mainSteps.indexOf(upd)+1}}.) Erratum</div>
      <div class="row" [ngbCollapse]="collapsed[upd]">
        <div class="col-md-4">
          <ol class="list-group list-group-flush">
            <li class="list-group-item" [ngClass]="getStepClasses(upd, 'prp')">
              <div class="d-flex flex-row justify-content-between">
                <div class="pointer" (click)="setStep(upd, 'prp')">1. Modification du tarif</div>
                <div><span><small>{{getStepLogItem(upd, 'prp')?.created|date:'short'}}</small>   {{getStepLogItem(upd, 'prp')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses(upd, 'prp')" (click)="checkStep('PRP', 'edt')"></i></div>
              </div>
            </li>
            <li class="list-group-item" [ngClass]="getStepClasses(upd, 'msg')">
              <div class="d-flex flex-row justify-content-between">
                <div class="pointer" (click)="setStep(upd, 'msg')">2. Création du message de notification</div>
                <div><span><small>{{getStepLogItem(upd, 'msg')?.created|date:'short'}}</small>  {{getStepLogItem(upd, 'msg')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses(upd, 'msg')" (click)="checkStep(upd, 'msg')"></i></div>
              </div>
            </li>
            <li class="list-group-item" [ngClass]="getStepClasses(upd, 'pub')">
              <div class="d-flex flex-row justify-content-between">
                <div class="pointer" (click)="setStep(upd, 'pub')">3. Publication de l'erratum</div>
                <div><span><small>{{getStepLogItem(upd, 'pub')?.created|date:'short'}}</small>  {{getStepLogItem(upd, 'pub')?.author_details?.lastname}}</span><i class="fa fa-check ms-2 pointer" [ngClass]="getCheckClasses(upd, 'pub')" (click)="checkStep(upd, 'pub')"></i></div>
              </div>
            </li>
          </ol>
        </div>
        <div class="col-md-8">
          <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main===upd &amp;&amp; currentStep.sub==='prp'">
            <button class="btn btn-primary mt-4 p-4 w-50" (click)="action.emit('editPrices')"><i class="fa fa-pencil me-2"></i>Editer les prix du tarif</button>
            <button class="btn btn-primary mt-4 p-4 w-50" (click)="action.emit('editTarif')"><i class="fa fa-cogs me-2"></i>Editer les infos du tarif</button>
            <lvadg-tarif-export class="w-50 mt-4 d-block" [tarif]="tarif" btnClasses="btn btn-primary p-4 w-100" label="Exporter le tarif"></lvadg-tarif-export>
            <button class="btn btn-outline-success mt-3 w-50" (click)="checkStep(upd, 'prp')"><i class="fa fa-check me-2"></i>Valider l'étape</button>
          </div>
          <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main===upd &amp;&amp; currentStep.sub==='msg'">
            <lvadg-tarifnotification-create class="w-75" [tarif]="tarif" type="UPD" status="PRP" (created)="checkStep(upd, 'msg')" [cancel]="false" [create]="true"></lvadg-tarifnotification-create>
          </div>
          <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main===upd &amp;&amp; currentStep.sub==='pub'">
            <button class="btn btn-primary mt-4 p-4 w-50" (click)="tarifPublish(upd, 'pub')" [disabled]="waiting.tarifPublish">
              <ng-container *ngIf="!waiting.tarifPublish"><i class="fa fa-rocket-launch me-2"></i>Lancer la publication</ng-container>
              <ng-container *ngIf="waiting.tarifPublish"><i class="fa fa-spin fa-spinner me-2"></i>Publication en cours, merci de patienter
                <div class="text-info">La publication peut prendre jusqu'à une vingtaine de secondes selon la taille du tarif</div>
              </ng-container>
            </button>
            <!--button.btn.btn-outline-danger.mt-3.w-50((click)="checkStep(upd, 'pub')")-->
            <!--  i.fa.fa-check.me-2-->
            <!--  | Valider l'étape sans lancer la publication-->
          </div>
          <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main===upd &amp;&amp; currentStep.sub ==='pdone'">
            <p class="text-success mt-5">Erratum publié avec succès</p>
            <button class="btn btn-outline-warning mt-4 p-4 w-50" (click)="checkStep(upd, 'pdone')"><i class="fa fa-pencil me-2"></i>Créer un nouvel erratum pour ce tarif</button>
          </div>
          <div class="d-flex flex-column justify-content-around align-items-center p-4" *ngIf="currentStep.main &gt; upd">
            <p class="text-success mt-5">Toutes les étapes de correction ont été effectuées</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>