import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from "@angular/core";
import {AttRelation, TarifTypeRelation, TarifTypeRelationService} from "../../tariftyperelation/tariftyperelation.service";
import {ArticleTarifTemplate} from "../articletariftemplate.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {firstValueFrom} from "rxjs";
import {FormControl} from "@angular/forms";

@Component({
  selector: "lvadg-attrelation-manage",
  templateUrl: "./attrelation-manage.component.pug",
  styleUrls: ["./attrelation-manage.component.sass"]
})
export class AttrelationManageComponent implements OnChanges {
  @Input() public relation!: TarifTypeRelation;
  @Input() public att!: ArticleTarifTemplate;
  @Output() public updated = new EventEmitter<void>();
  @ViewChild("createModal", {static: true}) public createModal: ElementRef;
  public opctrl = new FormControl("");
  public rmap: Map<number, AttRelation> = new Map();
  public att_select_default_fields = ["id", "name", "article__family__cfullname", "article", "article_calibre", "article_categorie", "article_origine",
    "article_local", "article_egalim_details", "article_is_egalim", "article_colisage", "article_uci", "article_uc", "article_uf",
    "status", "actions"];
  public modal?: NgbModalRef;
  public cur?: AttRelation;

  constructor(private _modal: NgbModal,
              private _attrs: TarifTypeRelationService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.rmap = new Map(this.att.relations_details.map((r) => [r.ttrel, r]));
    if (this.rmap.get(this.relation.id)) {
      this.cur = this.rmap.get(this.relation.id);
      this.opctrl.setValue(this.rmap.get(this.relation.id)!.ops);
    } else {
      delete this.cur;
      this.opctrl.setValue("");
    }
  }


  public setRelation(): void {
    console.log("set relation");
    this.modal = this._modal.open(this.createModal, {size: "xl"});
  }

  public async onSelected(att: ArticleTarifTemplate) {
    if (this.modal) {
      await firstValueFrom(this._attrs.action(this.relation, "attlink", {
        method: "POST",
        body: {
          action: "set",
          src: att.id,
          dest: this.att.id,
          ops: this.opctrl.value
        }
      }));
      await firstValueFrom(this.att.refresh());
      this.ngOnChanges({});
      this.updated.emit();
      this.modal.close();
    }
  }

  public async removeRelation(): Promise<void> {
    if (this.modal) {
      await firstValueFrom(this._attrs.action(this.relation, "attlink", {
        method: "POST",
        body: {
          action: "unset",
          dest: this.att.id,
        }
      }));
      await firstValueFrom(this.att.refresh());
      this.ngOnChanges({});
      this.updated.emit();
      this.modal.close();

    }
  }
}
