import {NgModule} from "@angular/core";
import {RestoCreateComponent} from "./resto-create/resto-create.component";
import {RestoDeleteComponent} from "./resto-delete/resto-delete.component";
import {RestoDisplayComponent} from "./resto-display/resto-display.component";
import {RestoListComponent} from "./resto-list/resto-list.component";
import {RestoManageComponent} from "./resto-manage/resto-manage.component";
import {LocationModule} from "../location/location.module";
import {ClientSelectModule} from "../client/client-select.module";
import {ImageModule} from "../../content/image/image.module";
import {StorageSelectModule} from "../storage/storage-select.module";
import {SLabelModule} from "../slabel/slabel.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {RestoSalesComponent} from "./resto-sales/resto-sales.component";
import {BillModule} from "../../sales/bill/bill.module";
import {RestoSelectModule} from "./resto-select.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    ImageModule,
    LocationModule,
    ClientSelectModule,
    StorageSelectModule,
    SLabelModule,
    BillModule,
    RestoSelectModule
  ],
  declarations: [
    RestoDisplayComponent,
    RestoDeleteComponent,
    RestoListComponent,
    RestoManageComponent,
    RestoCreateComponent,
    RestoSalesComponent
  ],
  exports: [
    RestoSelectModule,
    RestoDisplayComponent,
    RestoDeleteComponent,
    RestoListComponent,
    RestoManageComponent,
    RestoCreateComponent,
    RestoSalesComponent
  ],
})
export class RestoModule {
}
