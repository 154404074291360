import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, InjectionToken} from "@angular/core";
import {DS_REST_BACKEND_AUTHENTICATOR, DS_REST_BACKEND_CONFIG, IDSAuthentication, IDSRestBackendConfig} from "@solidev/ngdataservice";
import {indexOf, intersection, isRegExp} from "lodash-es";
import {Observable, of, ReplaySubject} from "rxjs";
import {Site} from "../authentication/site/site.service";
import {User, UserService} from "./user.service";
import {catchError, first, map, switchMap, tap} from "rxjs/operators";
import {Token, TokenService} from "../authentication/token/token.service";
import {CurrentSiteService} from "../authentication/site/current-site.service";

export interface LStorage {
  readonly length: number;

  clear(): void;

  getItem(key: string): string | null;

  key(index: number): string | null;

  removeItem(key: string): void;

  setItem(key: string, data: string): void;

  [key: string]: any;

  [index: number]: string;
}

/**
 * Localstorage injection token
 */
export let LOCAL_STORAGE_OBJECT = new InjectionToken<LStorage>("localstorage");
export const AUTH_VERSION = "2021-10-24-14:16:06";

/**
 * Authentication message.
 */
export interface IAuthMessage {
  authenticated: boolean;
  message?: string;
  error?: number;
}


/**
 * Authentication service.
 * Provides login, logout, autologin features.
 */
@Injectable({providedIn: "root"})
export class AuthService {
  public user!: User|null;
  public site!: Site;
  public token!: Token|null;
  public version!: string|null;

  private _auth!: ReplaySubject<AuthService>;

  constructor(@Inject(LOCAL_STORAGE_OBJECT) public storage: LStorage,
              @Inject(DS_REST_BACKEND_CONFIG) public backendConfig: IDSRestBackendConfig,
              @Inject(DS_REST_BACKEND_AUTHENTICATOR) public backendAuth: IDSAuthentication,
              public _site: CurrentSiteService,
              public _users: UserService,
              public _tokens: TokenService,
              public _http: HttpClient) {
    this.setAnonymousUser();
  }


  private _redirectUrl: string = "";

  /**
   * Redirect URL setter
   * Checks that url matches domain
   */
  public set redirectUrl(url: string) {
    // TODO: check hostname & host match
    this._redirectUrl = url;
  }


  public get auth$(): Observable<AuthService> {
    if (!this._auth) {
      this._auth = new ReplaySubject<AuthService>(1);
      this.autologin().pipe(first()).subscribe();
    }
    return this._auth.asObservable();
  }

  /**
   * Return true if user is authenticated.
   * Authenticated user is known, with a valid (not expired) token, and is_active status.
   * @returns boolean
   */
  public get isAuthenticated(): boolean {
    // FIXME: check token expire date
    return ((this.user !== null) && (this.token !== null) && (this.user.is_active));
  }

  /**
   * Return true if user is superuser.
   * Superuser have is_superuser true, and his token scope have superuser set.
   * @returns true or false
   */
  public get isSuperUser(): boolean {
    return this.isAuthenticated
      && this.token !== null
      && indexOf(this.token.scopes, "superuser") >= 0
      && this.user !== null
      && this.user.is_superuser;
  }

  /**
   * Return true if user have site admin powers.
   * To get site admin powers, user must be authenticated with a token with X:Y:admin scope (or be a superuser)
   */
  public get isSiteAdmin(): boolean {
    return this.isAuthenticated
      && this.token !== null
      && this.user !== null && (
      (intersection([
        "site:member:admin",
        "site:client:admin",
        "site:resto:admin"
      ], this.token.scopes).length > 0) ||
      (this.user.is_superuser));
  }

  /**
   * Return true if user have any of given scope.
   */
  public haveScope(...scopes: (string | RegExp)[]): boolean {
    if (!this.isAuthenticated) {
      return false;
    }
    if (this.isSuperUser) {
      return true;
    }
    for (const s of scopes) {
      if (isRegExp(s)) {
        for (const sc of this.token?.scopes || []) {
          if (s.test(sc)) {
            return true;
          }
        }
      } else {
        if (this.token !== null && this.token.scopes.indexOf(s) !== -1) {
          return true;
        }
      }
    }
    return false;
  }


  /**
   * Reset authentication.
   */
  public setAnonymousUser(tell: boolean = false): void {
    this.user = null;
    this.token = null;
    this.version = null;
    this.backendAuth.anonymous();
    if (tell) {
      if (!this._auth) {
        this._auth = new ReplaySubject<AuthService>(1);
      }
      this._auth.next(this);
    }
  }

  /**
   * Tries to log user in using username, password and site.
   * @param username
   * @param password
   * @param site domain part
   * @returns authentication result
   */
  public userPassLogin(username: string, password: string, site: string): Observable<IAuthMessage> {
    return this.authApiCall({username: username, password: password, site: site});
  }

  /**
   * Tries to log user in using token and site id.
   * @param token
   * @param site domain part
   * @returns authentication result
   */
  public tokenLogin(token: string, site: string): Observable<IAuthMessage> {
    return this.authApiCall({token: token, site: site});
  }

  /**
   * Logs user out.
   */
  public logout(): void {
    this.setAnonymousUser();
    this.persist();
    // Broadcast user:logout somewhere
  }

  /**
   * Redirect URL getter
   * Return to site homepage if empty or null
   */
  public getRedirectUrl(): Observable<string> {
    if (!this._redirectUrl) {
      // TODO: get url from site
      return of("/");
    } else {
      return of(this._redirectUrl);
    }
  }

  /**
   * Token refresh
   */
  public tokenRefresh(): Observable<IAuthMessage> {
    if (this.isAuthenticated) {
      return this.authApiCall({refresh: true, token: this.token!.token}).pipe(
        switchMap(() => {
          return this.autologin();
        }));
    } else {
      return of({authenticated: false});
    }
  }

  /**
   * Save authentication infos to local storage.
   */
  protected persist(): void {
    console.log("Set storage");
    this.storage.setItem("Authentication", JSON.stringify({user: this.user, token: this.token, version: this.version}));
  }

  /**
   * Load authentication infos from local storage.
   */
  protected retrieve(): AuthService {
    if (this.isAuthenticated) {
      return this;
    }
    if (this.storage.getItem("Authentication")) {
      const auth = JSON.parse(this.storage.getItem("Authentication") || "{}");
      if (auth && auth.user && auth.token) {
        this.user = new User(this._users, auth.user);
        this.token = new Token(this._tokens, auth.token);
        this.backendAuth.authenticate(this.token.token);
        this.version = auth.version;
        // Broadcast user:login message somewhere ?
        if (!this._auth) {
          this._auth = new ReplaySubject<AuthService>(1);
        }
        this._auth.next(this);
        return this;
      }
    }
    // Broadcast user:logout message somewhere ?
    this.setAnonymousUser(true);
    return this;

  }

  /**
   * Calls authentication api endpoint.
   * @param auth_body auth payload
   */
  protected authApiCall(auth_body: any): Observable<IAuthMessage> {
    // FIXME: use backend !!
    return this._http.post<any>(this.backendConfig.url + "/api/v2/auth", auth_body).pipe(
      map((result) => {
        this.token = new Token(this._tokens, result.token);
        this.user = new User(this._users, result.user);
        this.version = AUTH_VERSION;
        this.persist();
        this.backendAuth.authenticate(this.token.token);
        // Broadcast user:login to somewhere
        if (!this._auth) {
          this._auth = new ReplaySubject<AuthService>(1);
        }
        this._auth.next(this);
        return {authenticated: true};
      })
      , catchError((err, caught) => {
        this.setAnonymousUser(true);
        this.persist();
        const errbody = err.error;
        if (errbody.error) {
          // Broadcast user:login to somewhere
          return of({authenticated: false, message: errbody.error, error: err.status});
        } else {
          return of({authenticated: false, message: "Unknown error", error: err.status});
        }
      }));
  }

  /**
   * Tries to authenticate user from stored Authentication data.
   */
  protected autologin(): Observable<IAuthMessage> {
    this.retrieve();
    if (this.isAuthenticated) {
      if (this.version !== AUTH_VERSION) {
        console.log("Refreshing token for new api version");
        return this.authApiCall({refresh: true, token: this.token!.token})
          .pipe(
            tap(() => this._auth.next(this)),
            map(() => {
              return {authenticated: true};
            }));
      } else {
        this._auth.next(this);
        return of({authenticated: true});
      }
    } else {
      this.setAnonymousUser();
      this.persist();
      this._auth.next(this);
      return of({authenticated: false});
    }
  }


}
