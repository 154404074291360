import {NgModule} from "@angular/core";
import {ExternalarticleListComponent} from "./externalarticle-list/externalarticle-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ExternalarticleLoadXlsxComponent} from "./externalarticle-load-xlsx/externalarticle-load-xlsx.component";
import {ArticleModule} from "../article/article.module";
import {ExternalarticleManageComponent} from "./externalarticle-manage/externalarticle-manage.component";
import {ExternalarticleCreateComponent} from "./externalarticle-create/externalarticle-create.component";


@NgModule({
  declarations: [
    ExternalarticleListComponent,
    ExternalarticleLoadXlsxComponent,
    ExternalarticleManageComponent,
    ExternalarticleCreateComponent
  ],
  exports: [
    ExternalarticleListComponent,
    ExternalarticleManageComponent,
    ExternalarticleCreateComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    ArticleModule
  ]
})
export class ExternalArticleModule {
}
