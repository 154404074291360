// V3 OK

import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

import {firstValueFrom, Subject} from "rxjs";
import {CommonBaseComponent} from "../../common-base.component";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {LifeCycles, TarifType} from "../../../../lib/models/tarif/tariftype/tariftype.service";
import {NavDriver, TabMemoryService} from "../../../../lib/comps/tab-memory.service";
import {Tarif} from "../../../../lib/models/tarif/tarif/tarif.service";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {AuthService} from "../../../../lib/models/user/auth.service";
import {Provider} from "../../../../lib/models/structure/provider/provider.service";
import {Person} from "../../../../lib/models/people/person/person.service";
import {ExternalArticle} from "../../../../lib/models/catalog/externalarticle/externalarticle.service";


export interface TarifTypeManageViewV3Params extends BaseRouteV3Params {
  tarif_detail_route: RouteConfigItemV3;
  tariftype_detail_route: RouteConfigItemV3;
  article_detail_route: RouteConfigItemV3;
  provider_detail_route: RouteConfigItemV3;
  person_detail_route: RouteConfigItemV3;
  externalarticle_detail_route: RouteConfigItemV3;
}

export interface TarifTypeManageViewV3Data extends TarifTypeManageViewV3Params {
  tarifType: TarifType;
}

@Component({
  selector: "lvadg-tariftype-manage-v3-view",
  templateUrl: "./tariftype-manage-view.component.pug",
  styleUrls: ["./tariftype-manage-view.component.sass"]
})
export class TarifTypeManageViewV3Component extends CommonBaseComponent {
  // Base data
  public tarifType!: TarifType;
  public navId!: NavDriver;

  // Tarifs related data
  public tarifs_filter: { [index: string]: string | number } = {};
  public tarif_detail_route!: RouteV3<Tarif>;
  // Article tarif templates list
  public att_filter: { [index: string]: string | number } = {};

  // External links
  public tariftype_detail_route!: RouteV3<TarifType>;
  public article_detail_route!: RouteV3<Article>;
  public provider_detail_route!: RouteV3<Provider>;
  public person_detail_route!: RouteV3<Person>;
  public externalarticle_detail_route!: RouteV3<ExternalArticle>;
  public tarifnotifications_default_fields: string[] = ["notif_type", "notif_status", "notif_priority",
    "notif_tarif", "notif_status",  "user", "status", "title", "updated"];
  public tarifnotifications_default_filters: string[] = ["search",  "tarifs", "notiftypes", "statuses", "priorities"];
  // External articles data
  public ea_default_fields = ["id", "client", "tariftype",
    "code", "gamme", "libelle", "categorie", "calibre", "origine", "uf", "uc", "ucond",
    "articles", "status", "flags"];
  public ea_default_filters = ["search", "type", "statuses", "assoc"];
  public ea_reload = new Subject<void>();


  constructor(routes: RoutesService,
              router: Router,
              route: ActivatedRoute,
              public auth: AuthService,
              private tms: TabMemoryService) {
    super(routes, router, route);
  }

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as TarifTypeManageViewV3Data;
    this.tarifType = data.tarifType;
    this.att_filter.tarif_type = this.tarifType.id;
    this.tarifs_filter.type = this.tarifType.id;
    this.navId = this.tms.memFor(["ttypeview", this.tarifType.id], "tarifs", true);
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    this.provider_detail_route = new RouteV3<Provider>(data.provider_detail_route, this);
    this.tarif_detail_route = new RouteV3<Tarif>(data.tarif_detail_route, this);
    this.person_detail_route = new RouteV3<Person>(data.person_detail_route, this);
    this.externalarticle_detail_route = new RouteV3<ExternalArticle>(data.externalarticle_detail_route, this);
    this.tariftype_detail_route = new RouteV3<TarifType>(data.tariftype_detail_route, this);
  }

  public onCreated(tarif: Tarif) {
    this.router.navigate(this.tarif_detail_route.route({tarif}));
  }

  public async createArticleTemplates() {
    await firstValueFrom(this.tarifType.action("create_lines", {
      method: "POST",
      body: {mode: "external_target"}
    }));
    this.ea_reload.next();
  }

  public isTarifPowerUser(tt: TarifType): boolean {
    return (
      (tt.isAuth("write") && (tt.lifecycle === LifeCycles.GENERIC || tt.lifecycle === LifeCycles.GENNOVAL || tt.lifecycle === LifeCycles.UPLOAD)) ||
      (tt.isAuth("admin") && (tt.lifecycle === LifeCycles.GENERIC_ADMIN || tt.lifecycle === LifeCycles.GENNOVAL_ADMIN))
    )
  }
}
