import {NgModule} from "@angular/core";
import {GroupListComponent} from "./group-list/group-list.component";
import {GroupManageComponent} from "./group-manage/group-manage.component";
import {GroupCreateComponent} from "./group-create/group-create.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  declarations: [
    GroupListComponent,
    GroupManageComponent,
    GroupCreateComponent
  ],
  exports: [
    GroupListComponent,
    GroupManageComponent,
    GroupCreateComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
  ]
})
export class GroupModule {
}
