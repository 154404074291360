import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {SLabelService} from "./slabel.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class SlabelprefetchResolve implements Resolve<any> {
  constructor(private _bs: SLabelService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._bs.prefetch().pipe(first());
  }
}
