/**
 * Location : model & service providers
 *
 * Provides location for all location-dependent objects
 *
 * @status ALPHA
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @date 2018-05-09-00:30:25
 * @changed 2018-05-09-00:30:26
 * @copyright (c) 2017-2018 Jean-Matthieu BARBIER
 * @license AGPLv3
 */


import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Member} from "../member/member.service";
import {Client} from "../client/client.service";
import {Storage} from "../storage/storage.service";
import {Resto} from "../resto/resto.service";
import {Producer} from "../producer/producer.service";
import {SeaProducer} from "../seaproducer/seaproducer.service";
import {GeoRegion} from "../../customdata/georegion/georegion.service";
import {GeoCommune} from "../../customdata/geocommune/geocommune.service";
import {GeoDepartement} from "../../customdata/geodepartement/geodepartement.service";

export class Location extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSCharField({
    required: false,
    name: "name",
    helpText: "",
    verboseName: "Nom de l'emplacement",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public name!: string;

  @DSCharField({
    required: false,
    name: "type",
    helpText: "",
    verboseName: "Type d'emplacement",
    choices: [
      ["MAIN", "Adresse principale"],
      ["SEC", "Adresse secondaire"],
      ["SITE", "Site"],
      ["AREG", "Antenne régionale"],
      ["ADEP", "Antenne départementale"],
      ["AUTR", "Autre"]
    ],
    default: null,
    maxLength: 4,
  })
  public type!: string;

  @DSCharField({
    required: false,
    name: "phone",
    helpText: "",
    verboseName: "Téléphone",
    choices: [],
    default: null,
    maxLength: 30,
  })
  public phone!: string;

  @DSCharField({
    required: false,
    name: "fax",
    helpText: "",
    verboseName: "Fax",
    choices: [],
    default: null,
    maxLength: 30,
  })
  public fax!: string;

  @DSCharField({
    required: false,
    name: "website",
    helpText: "",
    verboseName: "Site internet",
    choices: [],
    default: null,
    maxLength: 300,
  })
  public website!: string;

  @DSCharField({
    required: true,
    name: "locname",
    helpText: "",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 300,
  })
  public locname!: string;

  @DSCharField({
    required: true,
    name: "address",
    helpText: "",
    verboseName: "Adresse",
    choices: [],
    default: null,
    maxLength: 300,
  })
  public address!: string;

  @DSCharField({
    required: false,
    name: "caddress",
    helpText: "",
    verboseName: "Complément d'adresse",
    choices: [],
    default: null,
    maxLength: 300,
  })
  public caddress!: string;

  @DSCharField({
    required: true,
    name: "city",
    helpText: "",
    verboseName: "Ville",
    choices: [],
    default: null,
    maxLength: 100,
  })
  public city!: string;

  @DSCharField({
    required: true,
    name: "postcode",
    helpText: "",
    verboseName: "Code Postal",
    choices: [],
    default: null,
    maxLength: 10,
  })
  public postcode!: string;

  @DSCharField({
    required: false,
    name: "cedex",
    helpText: "",
    verboseName: "CEDEX",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public cedex!: string;

  @DSCharField({
    required: false,
    name: "region",
    helpText: "",
    verboseName: "[deprectated] region",
    choices: [],
    default: null,
    maxLength: 50,
  })
  public region!: string;

  @DSCharField({
    required: true,
    name: "country",
    helpText: "",
    verboseName: "Pays",
    choices: [],
    default: "France",
    maxLength: 50,
  })
  public country!: string;

  @DSDefaultField({
    required: false,
    name: "position",
    helpText: "",
    verboseName: "Géolocalisation",
    choices: [],
    default: null,
  })
  public position!: any;

  @DSForeignKeyField({
    required: false,
    name: "member",
    helpText: "",
    verboseName: "ID Adhérent",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Member",
    remoteField: "locations",
  })
  public member!: number;

  @DSForeignKeyField({
    required: false,
    name: "producer",
    helpText: "",
    verboseName: "ID Producteur",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Producer",
    remoteField: "locations",
  })
  public producer!: number;

  @DSForeignKeyField({
    required: false,
    name: "seaproducer",
    helpText: "",
    verboseName: "ID Producteur marée",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Producer",
    remoteField: "locations",
  })
  public seaproducer!: number;

  @DSForeignKeyField({
    required: false,
    name: "client",
    helpText: "",
    verboseName: "ID Client",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Client",
    remoteField: "locations",
  })
  public client!: number;

  @DSForeignKeyField({
    required: false,
    name: "resto",
    helpText: "",
    verboseName: "ID Resto",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Resto",
    remoteField: "locations",
  })
  public resto!: number;

  @DSForeignKeyField({
    required: false,
    name: "storage",
    helpText: "",
    verboseName: "ID Dépôt",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Storage",
    remoteField: "locations",
  })
  public storage!: number;

  @DSForeignKeyField({
    required: false,
    name: "geodepartement",
    helpText: "",
    verboseName: "ID GéoDéartement",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "GeoDepartement",
    remoteField: "location",
  })
  public geodepartement!: number;

  @DSForeignKeyField({
    required: false,
    name: "geocommune",
    helpText: "",
    verboseName: "ID GéoCommune",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "GeoCommune",
    remoteField: "location",
  })
  public geocommune!: number;

  @DSForeignKeyField({
    required: false,
    name: "georegion",
    helpText: "",
    verboseName: "ID GéoRégion",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "GeoRegion",
    remoteField: "location",
  })
  public georegion!: number;

  @DSDefaultField({
    required: false,
    name: "flags",
    helpText: "",
    verboseName: "Etat géo"
  })
  public flags!: string[];

  @DSCharField({
    required: true,
    name: "loctype",
    helpText: "",
    verboseName: "Type",
    choices: [
      ["unknown", "Type inconnu"],
      ["member", "Adhérent"],
      ["storage", "Dépôt"],
      ["producer", "Producteur F&L"],
      ["seaproducer", "Producteur marée"],
      ["client", "Client"],
      ["resto", "Restaurant"],
      ["provider", "Fournisseur"]],
    default: "unkown",
    maxLength: 18,
  })
  public loctype!: string;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date mise à jour",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "Géoloc. valide"],
      ["NG", "Sans géolocalisation"],
      ["AY", "Goloc. automatique OK"],
      ["AN", "Echec géoloc. automatique"],
      ["KO", "Geoloc. non valide"],
      ["NA", "Ne pas géolocaliser"]],
    default: null,
    maxLength: 4,
  })
  public status!: string;

  @DSDefaultField({
    required: false,
    verboseName: "Latitude",
    name: "latitude"
  })
  public latitude!: number;

  @DSDefaultField({
    required: false,
    verboseName: "Longitude",
    name: "longitude"
  })
  public longitude!: number;


  @DSCharField({
    name: "geotype",
    verboseName: "Type géolocalisation",
    required: false,
    helpText: "Type géolocalisation",
    minLength: 0,
    maxLength: 40,
    choices: [
      ["", "Non défini"],
      ["housenumber", "Adresse exacte"],
      ["street", "Rue"],
      ["locality", "Localité"],
      ["municipality", "Municipalité"]
    ]
  })
  public geotype!: "" | "housenumber" | "street" | "locality";


  public member_details!: Member;
  public storage_details!: Storage;
  public client_details!: Client;
  public resto_details!: Resto;
  public producer_details!: Producer;
  public seaproducer_details!: SeaProducer;
  public georegion_details!: GeoRegion;
  public geocommune_details!: GeoCommune;
  public geodepartement_details!: GeoDepartement;

  @DSCharField({
    name: "geolabel",
    verboseName: "Geoloc label",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 300,
    choices: []
  })
  public geolabel!: string;

  @DSCharField({
    name: "geoprovider",
    verboseName: "Geoloc provider",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 4,
    choices: []
  })
  public geoprovider!: string;

  @DSCharField({
    name: "geopostcode",
    verboseName: "Geoloc cp",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 30,
    choices: []
  })
  public geopostcode!: string;


  public get mainaddress(): string {
    let out = "";
    out += this.address + "\n" + this.postcode + " " + this.city;
    return out;
  }
}

@Injectable({providedIn: "root"})
export class LocationService extends DSRestCollection<Location> {
  public adapter_config = {basePath: "/api/v2/structure/locations"};
  public model = Location;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}

export function getPrimaryLocation(locations: Location[]): Location|null {
  for (const l of locations) {
    if (l.type === "MAIN") {
      return l;
    }
  }
  if (locations.length > 0) {
    return locations[0];
  }
  console.error("No main location");
  return null;
}
