import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {EChartsOption} from "echarts";
import {Rnm, RnmService} from "../rnm.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-rnm-graph",
  templateUrl: "./rnm-graph.component.pug",
  styleUrls: ["./rnm-graph.component.sass"]
})
export class RnmGraphComponent implements OnChanges {
  public options!: EChartsOption;
  @Input() public rnm!: Rnm;
  public initCfg = {locale: "FR", renderer: "svg"};

  constructor(private _rnm: RnmService) {
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    console.log("Changes", changes);
    const data = await firstValueFrom(this.rnm.action("chart", {method: "GET"}));

    this.options = {
      tooltip: {
        trigger: "axis"
      },
      xAxis: {
        type: "time",
        scale: true
      },
      yAxis: {
        type: "value",
        name: "€/UV"
      },
      dataZoom: [
        {
          type: "inside",
          start: 20,
          end: 100
        },
        {
          show: true,
          type: "slider",
          top: "90%",
          start: 20,
          end: 100
        }
      ],
      legend: {
        orient: "horizontal",
        padding: 5,
        textStyle: {
          fontSize: 10
        }
      },
      dataset: {
        source: data
      },
      series: []
    };
    const series: any[] = [];
    for (let i = 1; i < data[0].length; i++) {
      series.push({
        name: data[0][i],
        type: "line",
        animation: false,
        symbol: "none",
        encode: {
          x: 0,
          y: i
        }
      });
    }
    this.options.series = series;
  }


}
