import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {GeoCommune} from "../../customdata/geocommune/geocommune.service";
import {GeoDepartement} from "../../customdata/geodepartement/geodepartement.service";
import {GeoRegion} from "../../customdata/georegion/georegion.service";


export class ArticleRegion extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "vvid",
    verboseName: "Id Vivalya",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 16,
    choices: []
  })
  public vvid!: string;

  @DSCharField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 3,
    choices: [
      ["FRG", "Région (France)"],
      ["MRG", "Région (monde)"],
      ["FCT", "Ville (France)"],
      ["FDP", "Département (France)"],
      ["DVS", "Divers"]
    ]
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;

  @DSForeignKeyField({
    name: "georegion",
    verboseName: "Région (géo)",
    required: false,
    helpText: "Région (géo)",
    relatedModel: "GeoRegion",
    remoteField: "id",
    relatedName: "articleregion"
  })
  public georegion!: number;
  public georegion_details!: GeoRegion;

  @DSForeignKeyField({
    name: "geodepartement",
    verboseName: "Département (géo)",
    required: false,
    helpText: "Département (géo)",
    relatedModel: "GeoDepartement",
    remoteField: "id",
    relatedName: "articleregion"
  })
  public geodepartement!: number;
  public geodepartement_details!: GeoDepartement;

  @DSForeignKeyField({
    name: "geocommune",
    verboseName: "Commune (géo)",
    required: false,
    helpText: "Commune (géo)",
    relatedModel: "GeoCommune",
    remoteField: "id",
    relatedName: "articleregion"
  })
  public geocommune!: number;
  public geocommune_details!: GeoCommune;


}

@Injectable({
  providedIn: "root"
})
export class ArticleRegionService extends DSRestCollection<ArticleRegion> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/regions"};
  public model = ArticleRegion;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
