import {NgModule} from "@angular/core";
import {RelationCreateComponent} from "./relation-create/relation-create.component";
import {RelationDeleteComponent} from "./relation-delete/relation-delete.component";
import {RelationDisplayComponent} from "./relation-display/relation-display.component";
import {RelationListComponent} from "./relation-list/relation-list.component";
import {RelationSelectComponent} from "./relation-select/relation-select.component";
import {RelationManageComponent} from "./relation-manage/relation-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS
  ],
  declarations: [
    RelationSelectComponent,
    RelationDisplayComponent,
    RelationDeleteComponent,
    RelationListComponent,
    RelationManageComponent,
    RelationCreateComponent
  ],
  exports: [
    RelationSelectComponent,
    RelationDisplayComponent,
    RelationDeleteComponent,
    RelationListComponent,
    RelationManageComponent,
    RelationCreateComponent
  ],
})
export class RelationModule {
}
