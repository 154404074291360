import {Language} from "rrule/dist/esm/nlp/i18n";

export const FRENCH_RRULE: Language = {
  dayNames: [
    "dimanche", "lundi", "mardi", "mercredi",
    "jeudi", "vendredi", "samedi"
  ],
  monthNames: [
    "janvier", "fevrier", "mars", "avril", "mai",
    "juin", "juillet", "août", "septembre", "octobre",
    "novembre", "décembre"
  ],
  tokens: {
    "SKIP": /^[ \r\n\t]+|^\.$/,
    "number": /^[1-9][0-9]*/,
    "numberAsText": /^(one|two|three)/i,
    "every": /^chaque/i,
    "day(s)": /^days?/i,
    "weekday(s)": /^weekdays?/i,
    "week(s)": /^weeks?/i,
    "hour(s)": /^hours?/i,
    "minute(s)": /^minutes?/i,
    "month(s)": /^months?/i,
    "year(s)": /^years?/i,
    "on": /^(on|in)/i,
    "at": /^(at)/i,
    "the": /^the/i,
    "first": /^first/i,
    "second": /^second/i,
    "third": /^third/i,
    "nth": /^([1-9][0-9]*)(\.|th|nd|rd|st)/i,
    "last": /^last/i,
    "for": /^for/i,
    "time(s)": /^times?/i,
    "until": /^(un)?til/i,
    "monday": /^mo(n(day)?)?/i,
    "tuesday": /^tu(e(s(day)?)?)?/i,
    "wednesday": /^we(d(n(esday)?)?)?/i,
    "thursday": /^th(u(r(sday)?)?)?/i,
    "friday": /^fr(i(day)?)?/i,
    "saturday": /^sa(t(urday)?)?/i,
    "sunday": /^su(n(day)?)?/i,
    "january": /^jan(uary)?/i,
    "february": /^feb(ruary)?/i,
    "march": /^mar(ch)?/i,
    "april": /^apr(il)?/i,
    "may": /^may/i,
    "june": /^june?/i,
    "july": /^july?/i,
    "august": /^aug(ust)?/i,
    "september": /^sep(t(ember)?)?/i,
    "october": /^oct(ober)?/i,
    "november": /^nov(ember)?/i,
    "december": /^dec(ember)?/i,
    "comma": /^(,\s*|(and|or)\s*)+/i
  },
};

export const GETTEXT_FRENCH_RRULE: {[index: string]: string} = {
  "every": "chaque",
  "week": "semaine",
  "weeks": "semaines",
  "months": "mois",
  "year": "année",
  "years": "années",
  "day(s)": "jour(s)",
  "on": "le",
  "until": "jusqu'au",
  "or": "ou",
  "the": "le",
  "th": "ème",
  "st": "er",
  "rd": "ème",
  "on the": "le",
  "and": "et",
  "month": "mois",
  "nd": "nd",
  "(~ approximate)": "(~ approximativement)",
  "for": "pour",
  "time": "fois",
  "last": "dernier",
  "time(s)": "fois",
  "times": "occurrences"
};
