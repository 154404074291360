/**
 * CustomData : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>

 * @date 2018-01-01-18:54:45
 * @copyright (c) 2017 Jean-Matthieu BARBIER
 */
import {CustomDataKey} from "../customdatakey/customdatakey.service";
import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

import {Document} from "../../document/document/document.service";
import {Folder} from "../../document/folder/folder.service";

export class CustomData extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSForeignKeyField({
    required: false,
    name: "storage",
    helpText: "",
    verboseName: "storage",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Storage",
    remoteField: "customdata",
  })
  public storage!: number;

  @DSForeignKeyField({
    name: "producer",
    verboseName: "Producteur",
    required: false,
    helpText: "Producteur F&L",
    relatedModel: "structure.Producer",
    remoteField: "id",
    relatedName: "customdata"
  })
  public producer!: number;

  @DSForeignKeyField({
    name: "seaproducer",
    verboseName: "Fournisseur marée",
    required: false,
    helpText: "Fournisseur marée",
    relatedModel: "structure.SeaProducer",
    remoteField: "id",
    relatedName: "customdata"
  })
  public seaproducer!: number;
  @DSForeignKeyField({
    name: "product",
    verboseName: "Produit F&L",
    required: false,
    helpText: "Produit F&L",
    relatedModel: "products.Product",
    remoteField: "id",
    relatedName: "customdata"
  })
  public product!: number;
  @DSForeignKeyField({
    name: "seaproduct",
    verboseName: "Produit marée",
    required: false,
    helpText: "Produit marée",
    relatedModel: "products.SeaProduct",
    remoteField: "id",
    relatedName: "customdata"
  })
  public seaproduct!: number;

  @DSForeignKeyField({
    required: true,
    name: "key",
    helpText: "",
    verboseName: "key",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "CustomDataKey",
    remoteField: "customdata",
  })
  public key!: number;

  @DSForeignKeyField({
    name: "choice",
    verboseName: "Choix",
    required: false,
    helpText: "Choix unique",
    relatedModel: "customdata.CustomDataKeyChoice",
    remoteField: "id",
    relatedName: "dataitems"
  })
  public choice!: number;

  @DSManyToManyField({
    name: "choices",
    verboseName: "Choix (multiples)",
    required: false,
    relatedModel: "Choix (multiples)",
    remoteField: "customdata.CustomDataKeyChoice",
    helpText: "id",
    default: [],
  })
  public choices!: number[];

  @DSForeignKeyField({
    name: "document",
    verboseName: "Document",
    required: false,
    helpText: "",
    relatedModel: "document.Document",
    remoteField: "id",
    relatedName: "dataitems"
  })
  public document!: number;
  public document_details!: Document;

  @DSForeignKeyField({
    name: "folder",
    verboseName: "Folder",
    required: false,
    helpText: "",
    relatedModel: "document.Folder",
    remoteField: "id",
    relatedName: "dataitems"
  })
  public folder!: number;
  public folder_details!: Folder;

  @DSDefaultField({
    required: false,
    name: "value_int",
    helpText: "",
    verboseName: "value int",
    choices: [],
    default: 0,
  })
  public value_int!: any;

  @DSDefaultField({
    required: false,
    name: "value_dec",
    helpText: "",
    verboseName: "value dec",
    choices: [],
    default: 0,
  })
  public value_dec!: any;

  @DSCharField({
    required: false,
    name: "value_stx",
    helpText: "",
    verboseName: "value stx",
    choices: [],
    default: "",
    maxLength: 250,
  })
  public value_stx!: string;

  @DSTextField({
    required: true,
    name: "value_ltx",
    helpText: "",
    verboseName: "value ltx",
    choices: [],
    default: "",
    maxLength: 3000,
  })
  public value_ltx!: string;

  @DSTextField({
    required: true,
    name: "value_rtx",
    helpText: "",
    verboseName: "value rtx",
    choices: [],
    default: "",
    maxLength: 10000,
  })
  public value_rtx!: string;

  @DSDateField({
    required: false,
    name: "value_dte",
    helpText: "",
    verboseName: "value dte",
    choices: [],
    default: null,
  })
  public value_dte!: Date;

  @DSDateTimeField({
    required: false,
    name: "value_dth",
    helpText: "",
    verboseName: "value dth",
    choices: [],
    default: null,
  })
  public value_dth!: Date;

  @DSDefaultField({
    required: false,
    name: "value_json",
    helpText: "",
    verboseName: "value json",
    choices: [],
    default: null
  })
  public value_json!: any;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "created",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "updated",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;
}

@Injectable({providedIn: "root"})
export class CustomDataService extends DSRestCollection<CustomData> {
  public adapter_config = {basePath: "/api/v2/customdatas"};
  public model = CustomData;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
