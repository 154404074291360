import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProductVariant} from "../_productvariant/productvariant-base.model";

export class ProductVariant extends BaseProductVariant {
}

@Injectable({
  providedIn: "root"
})
export class ProductVariantService extends DSRestCollection<ProductVariant> {
  public adapter_config = {basePath: "/api/v2/productvariants"};
  public model = ProductVariant;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
