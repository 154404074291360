
<div *ngIf="result">
  <div>Affichage des résultats :
    <button class="btn btn-outline-secondary btn-sm mx-1" (click)="minLog=-1">Aucun</button>
    <button class="btn btn-outline-primary btn-sm mx-1" (click)="minLog=0">Tous</button>
    <button class="btn btn-outline-warning btn-sm mx-1" (click)="minLog=4">Warning</button>
    <button class="btn btn-outline-danger btn-sm mx-1" (click)="minLog=5">Erreur</button>
  </div>
  <table class="table table-sm table-bordered" *ngIf="minLog!==-1">
    <thead>
      <tr>
        <th>Niveau</th>
        <th>Ligne</th>
        <th>Message</th>
        <th>Data</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let r of result">
        <tr *ngIf="r.level &gt;= minLog" [class.text-danger]="r.level&gt;=5" [class.text-warning]="r.level==4">
          <td>{{LEVELS[r.level]||"???"}}</td>
          <td>
            <ng-container *ngIf="!r.line">GLOBAL</ng-container>
            <ng-container *ngIf="r.line">{{r.line}}</ng-container>
          </td>
          <td>{{r.message}}</td>
          <td>{{r.data|json}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>