import {NgModule} from "@angular/core";
import {ArticleAllergenesManageComponent} from "./article-allergenes-manage/article-allergenes-manage.component";
import {ArticleCalibresManageComponent} from "./article-calibres-manage/article-calibres-manage.component";
import {ArticleCategoriesManageComponent} from "./article-categories-manage/article-categories-manage.component";
import {ArticleClientsManageComponent} from "./article-clients-manage/article-clients-manage.component";
import {ArticleEgalimsManageComponent} from "./article-egalims-manage/article-egalims-manage.component";
import {ArticleMarquesManageComponent} from "./article-marques-manage/article-marques-manage.component";
import {ArticleOriginesManageComponent} from "./article-origines-manage/article-origines-manage.component";
import {ArticleParticularitesManageComponent} from "./article-particularites-manage/article-particularites-manage.component";
import {ArticlePresentationsManageComponent} from "./article-presentations-manage/article-presentations-manage.component";
import {ArticleProcessesManageComponent} from "./article-processes-manage/article-processes-manage.component";
import {ArticleProductionsManageComponent} from "./article-productions-manage/article-productions-manage.component";
import {ArticleRegionsManageComponent} from "./article-regions-manage/article-regions-manage.component";

import {ArticleUsagesManageComponent} from "./article-usages-manage/article-usages-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ArticleModule} from "../article/article.module";
import {BsacFormatModule} from "@solidev/bsadmincomponents";
import {ArticleTraitementsManageComponent} from "./article-traitements-manage/article-traitements-manage.component";
import {ArticleConditionnementsManageComponent} from "./article-conditionnements-manage/article-conditionnements-manage.component";
import {NgStringPipesModule} from "ngx-pipes";


@NgModule({
  declarations: [
    ArticleAllergenesManageComponent,
    ArticleCalibresManageComponent,
    ArticleCategoriesManageComponent,
    ArticleClientsManageComponent,
    ArticleConditionnementsManageComponent,
    ArticleEgalimsManageComponent,
    ArticleMarquesManageComponent,
    ArticleOriginesManageComponent,
    ArticleParticularitesManageComponent,
    ArticlePresentationsManageComponent,
    ArticleProcessesManageComponent,
    ArticleProductionsManageComponent,
    ArticleRegionsManageComponent,
    ArticleTraitementsManageComponent,
    ArticleUsagesManageComponent],
  exports: [
    ArticleAllergenesManageComponent,
    ArticleCalibresManageComponent,
    ArticleCategoriesManageComponent,
    ArticleConditionnementsManageComponent,
    ArticleClientsManageComponent,
    ArticleEgalimsManageComponent,
    ArticleMarquesManageComponent,
    ArticleOriginesManageComponent,
    ArticleParticularitesManageComponent,
    ArticlePresentationsManageComponent,
    ArticleProcessesManageComponent,
    ArticleProductionsManageComponent,
    ArticleRegionsManageComponent,
    ArticleTraitementsManageComponent,
    ArticleUsagesManageComponent],
  imports: [
    ...MODELLIST_IMPORTS,
    ArticleModule,
    BsacFormatModule,
    NgStringPipesModule
  ]
})
export class ArticledataModule {
}
