import {Inject, Injectable, InjectionToken} from "@angular/core";
import {DSModel} from "@solidev/ngdataservice";
import {isArray, isFunction, isNumber, isString} from "lodash-es";
import {Router} from "@angular/router";
import {VIEW_DISPLAY, VIEW_MODE} from "../../views/view-constants";


export interface BaseRouteV3Params {
  skip?: boolean;
  title?: string;
  icon?: string;
  route: RouteConfigItemV3;
  display?: VIEW_DISPLAY;
  mode?: VIEW_MODE;
}

export type RouteFn = (...args: any[]) => (string | number)[];
export type PRouteFn = (params: { [index: string]: any }) => (string | number)[];

export interface RouteConfigItemV3 {
  params: string[];
  route: RouteFn;
  name: string;
}

export function RData<T extends BaseRouteV3Params>(value: T) {
  return value;
}

export type RouteConfigItem = (string | number)[] | RouteFn;

export interface RoutesConfig {
  [index: string]: RouteConfigItem;
}

export type RoutesConfigV3<T extends string> = { readonly [Property in T]: RouteConfigItemV3 };

export class RouteV3<T> {
  private _cargs: { [index: string]: any } = {};
  private _params: string[] = [];
  private _route: RouteFn;

  constructor(config: RouteConfigItemV3, context: any) {
    this._params = config.params || [];
    this._route = config.route;
    if (context) {
      this.context(context);
    }
  }

  public context(ctx: any): void {
    for (const n of this._params) {
      if (ctx && ctx[n]) {
        this._cargs[n] = ctx[n];
      } else {
        this._cargs[n] = null;
      }
    }
  }

  public route(params?: { [index: string]: T }): (string | number)[] {
    const rargs: any[] = [];
    for (const n of this._params) {
      if (params && params[n] !== undefined && params[n] !== null) {
        rargs.push(params[n]);
      } else if (this._cargs[n] !== undefined && this._cargs[n] !== null) {
        rargs.push(this._cargs[n]);
      } else {
        console.error("Missing route parameter", n);
        return [];
      }
    }
    // Check for undefined in rargs
    return this._route(...rargs);
  }

}


export const ROUTES_CONFIG = new InjectionToken<RoutesConfig>("routes.config");


@Injectable({
  providedIn: "root"
})
export class RoutesService {

  constructor(@Inject(ROUTES_CONFIG) private _config: RoutesConfig,
              private _router: Router) {
  }

  public get routes(): RoutesConfig {
    return this._config;
  }

  static _id(obj: any): string {
    if (isNumber(obj)) {
      return obj.toString();
    }
    if (obj.id !== undefined) {
      return obj.id.toString();
    }
    if (obj._pk !== undefined) {
      return obj._pk.toString();
    }
    return "";
  }


  public navDetail(arg1: string, arg2?: any, arg3?: any): Promise<boolean> {
    return this._router.navigate(this.detail(arg1, arg2, arg3));
  }

  public detail(type: string, obj: any): (string | number)[];
  public detail(zone: string, type: string, obj: any): (string | number)[];
  public detail(arg1: string, arg2: any, arg3?: any): (string | number)[] {
    let zone: string|null= null;
    let type: string;
    let obj: DSModel;

    if (arg3 === undefined) {
      type = arg1;
      obj = arg2;
    } else {
      zone = arg1;
      type = arg2 as string;
      obj = arg3;
    }

    // Get route config
    let rte: RouteConfigItem;
    if (zone) {
      rte = this._config[zone + "." + type];
      if (!rte) {
        rte = this._config[type as string];
      }
    } else {
      rte = this._config[type as string];
    }

    if (isArray(rte)) {
      // Basic route def style, add id to route
      let id: string;
      if (isString(obj)) {
        id = obj;
      } else {
        id = RoutesService._id(obj);
      }
      if (rte && id !== "") {
        return rte.concat([id]);
      }
    } else if (isFunction(rte)) {
      return rte(...arg3);
    } else {
      // FIXME: add debug switch here & ensure no logs flooding
      // console.log("Invalid route definition for route", arg1 + "." + arg2, "arguments args", arg3, "found", rte);
    }

    // FIXME: add path to 404 route
    console.log("No detail route for args", arg1, arg2, arg3);
    return [];
  }


  public navList(type: string): void;
  // tslint:disable-next-line:unified-signatures
  public navList(zone: string, type: string): void;
  public navList(arg1: string, arg2?: string): void {
    this._router.navigate(this.list(arg1, arg2));
  }

  public list(arg1: string, arg2?: string, ...arg3: any[]): (string | number)[] {
    let zone;
    let type;
    if (arg2 === undefined) {
      type = arg1;
    } else {
      zone = arg1;
      type = arg2;
    }
    let rte: RouteConfigItem;
    if (zone) {
      rte = this._config[zone + "." + type];
      if (!rte) {
        rte = this._config[type];
      }
    } else {
      rte = this._config[type];
    }
    if (isArray(rte)) {
      return rte;
    } else if (isFunction(rte)) {
      return rte(...arg3);
    }
    return [];
  }

  public setPath(that: any, key: string, zone: string, type: string): void {
    if (!that[key]) {
      that[key] = this.list(zone, type);
    }
  }

  public get(zone: string, type: string, ...args: any[]): (number | string)[] {
    const rte = this._config[zone + "." + type];
    if (isFunction(rte)) {
      return rte(...args);
    }
    return [];
    // FIXME: add debug switch here & ensure no logs flooding
    // console.log("Invalid or missing route function", zone + "." + type, "with args", args);
  }


  public process(route: string, args: any, ctx: any): (number | string)[] {
    return [];
  }

}
