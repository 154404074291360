import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateField,
  DSDateTimeField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {RnmMarche} from "../rnmmarche/rnmmarche.service";


export class RnmCotation extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "marche",
    verboseName: "Marché",
    required: false,
    helpText: "Marché",
    relatedModel: "tarif.RnmMarche",
    remoteField: "id",
    relatedName: "cotations"
  })
  public marche!: number;
  public marche_details!: RnmMarche;

  @DSDateField({
    name: "date",
    verboseName: "Date",
    required: false,
    helpText: "Date",
    choices: []
  })
  public date!: Date;

  @DSTextField({
    name: "message",
    verboseName: "Message",
    required: false,
    helpText: "Message",
    minLength: 0,
    maxLength: 10000,
    choices: []
  })
  public message!: string;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;


  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class RnmCotationService extends DSRestCollection<RnmCotation> {
  public adapter_config = {basePath: "/api/v2/tarifs/rnmcotations"};
  public model = RnmCotation;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
