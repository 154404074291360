import {Component, Input, OnInit} from "@angular/core";
import {filter, takeUntil} from "rxjs/operators";
import {CommFieldParamsService} from "../commfieldparams.service";
import {Subject} from "rxjs";
import {IFieldParameter} from "../commfield.service";
import {isArray} from "lodash-es";

@Component({
  selector: "lvadg-commfield-selectmultiple",
  templateUrl: "./commfield-selectmultiple.component.pug",
  styleUrls: ["./commfield-selectmultiple.component.sass"]
})
export class CommFieldSelectMultipleComponent implements OnInit {

  @Input() public params!: CommFieldParamsService;
  @Input() public part!: string;
  @Input() public name!: string;
  @Input() public title!: string;
  public _subscriptions = new Subject<void>();
  public param!: IFieldParameter;


  constructor() {
  }

  ngOnInit() {
    this.params.getByName(this.part, this.name)
      .pipe(takeUntil(this._subscriptions),
        filter((value) => !!value)
      )
      .subscribe((val) => {
        this.param = val as IFieldParameter;
      });
  }

  public isCurrent(v: any): boolean {
    if (isArray(this.param.value)) {
      return (this.param.value as string[]).indexOf(v.value) !== -1;
    }
    return false;
  }

  public selectValue(v: any): void {
    const pos = (this.param.value as string[]).indexOf(v.value);
    if (pos !== -1) {
      (this.param.value as string[]).splice(pos, 1);
      if (v.action && v.action.disable) {
        for (const ex of v.action.disable) {
          const xp = (this.param.value as string[]).indexOf(ex);
          if (xp !== -1) {
            (this.param.value as string[]).splice(xp, 1);
          }
        }
      }
    } else {
      (this.param.value as string[]).push(v.value);

      if (v.action && v.action.enable) {
        for (const ex of v.action.enable) {
          const xp = (this.param.value as string[]).indexOf(ex);
          if (xp === -1) {
            (this.param.value as string[]).push(ex);
          }
        }
      }

      if (v.action && v.action.exclude) {
        for (const ex of v.action.exclude) {
          const xp = (this.param.value as string[]).indexOf(ex);
          if (xp !== -1) {
            (this.param.value as string[]).splice(xp, 1);
          }
        }
      }
    }
    this.params.setByName(this.part, this.name, {value: "MULTIPLE", savalue: this.param.value});
  }

  public removeParam(): void {
    this.params.removeByName(this.part, this.name);
  }
}
