import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {
  Article,
  ArticleFamily,
  AuthSiteGuard,
  Contact,
  ErrorComponent,
  ExternalArticle,
  FichesData,
  Provider,
  Tarif,
  TarifType
} from "lvadg";
import {ArticleAllergene} from "../../../lvadg/src/lib/models/catalog/articledata/article-allergene.service";
import {ArticleCalibre} from "../../../lvadg/src/lib/models/catalog/articledata/article-calibre.service";
import {ArticleCategorie} from "../../../lvadg/src/lib/models/catalog/articledata/article-categorie.service";
import {ArticleClient} from "../../../lvadg/src/lib/models/catalog/articledata/article-client.service";
import {ArticleConditionnement} from "../../../lvadg/src/lib/models/catalog/articledata/article-conditionnement.service";
import {ArticleEgalim} from "../../../lvadg/src/lib/models/catalog/articledata/article-egalim.service";
import {ArticleMarque} from "../../../lvadg/src/lib/models/catalog/articledata/article-marque.service";
import {ArticleOrigine} from "../../../lvadg/src/lib/models/catalog/articledata/article-origine.service";
import {ArticleParticularite} from "../../../lvadg/src/lib/models/catalog/articledata/article-particularite.service";
import {ArticlePresentation} from "../../../lvadg/src/lib/models/catalog/articledata/article-presentation.service";
import {ArticleProcess} from "../../../lvadg/src/lib/models/catalog/articledata/article-process.service";
import {ArticleUsage} from "../../../lvadg/src/lib/models/catalog/articledata/article-usage.service";
import {ArticleTraitement} from "../../../lvadg/src/lib/models/catalog/articledata/article-traitement.service";
import {ArticleRegion} from "../../../lvadg/src/lib/models/catalog/articledata/article-region.service";
import {ArticleProduction} from "../../../lvadg/src/lib/models/catalog/articledata/article-production.service";

const MAIN_ROUTES: Routes = [
  {
    path: "err",
    component: ErrorComponent
  },
  {
    path: "",
    data: {skip: true},
    canActivateChild: [AuthSiteGuard],
    children: [
      {
        path: "",
        data: {skip: true},
        loadChildren: () => import("./admin.module").then(m => m.AdminZoneModule)
      },
      {
        path: "p",
        loadChildren: () => import("./public/public.module").then(m => m.PublicZoneModule)
      }

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(MAIN_ROUTES, {
      enableTracing: false,
      useHash: false,
      onSameUrlNavigation: "reload",
      paramsInheritanceStrategy: "always"
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}

export const ADMIN_ROUTES_CONFIG = {
  "err": ["/", "err"],
  "home": ["/"],
  "pub.login": ["/", "p", "login"],
  "pub.logout": ["/", "p", "logout"],
  "pub.home": ["/", "p"],

  "structure.home": ["/", "structure"],
  "storages": ["/", "structure", "storages"],
  "storages.documents": ["/", "structure", "storages", "documents"],
  "members": ["/", "structure", "members"],
  "clients": ["/", "structure", "clients"],
  "restos": ["/", "structure", "restos"],
  "relations": ["/", "structure", "relations"],

  // Fournisseurs référencés
  "providers.providers": () => ["/", "structure", "providers", "providers"],
  "providers.provider": (pr: Provider) => ["/", "structure", "providers", "providers", pr.id],
  "providers.provider.manage": (pr: Provider) => ["/", "structure", "providers", "providers", pr.id],
  "providers.provider.articles": (pr: Provider) => ["/", "structure", "providers", "providers", pr.id, "articles"],
  "providers.provider.article": (pr: Provider, ar: Article) => ["/", "structure", "providers", "providers", pr.id, "articles", ar.id],
  "providers.provider.documents": (pr: Provider) => ["/", "structure", "providers", "providers", pr.id, "documents"],
  "providers.documents": () => ["/", "structure", "providers", "documents"],


  "data.home": ["/", "data"],
  "locations": ["/", "data", "locations"],
  "customdatakeys": ["/", "data", "customdatakeys"],
  "georegions": ["/", "data", "georegions"],
  "geocommunes": ["/", "data", "geocommunes"],
  "geodepartements": ["/", "data", "geodepartements"],
  "settingsvalues": ["/", "data", "settingsvalues"],
  "ciqualvalues": ["/", "data", "ciqualvalues"],
  "ciqualalims": ["/", "data", "ciqualalims"],
  "ciqualcolumns": ["/", "data", "ciqualcolumns"],
  "images": ["/", "data", "images"],
  "slabels": ["/", "data", "slabels"],

  "stats.home": ["/", "stats"],
  "usages": ["/", "stats", "usages"],

  "access.home": ["/", "access"],
  "sites": ["/", "access", "sites"],
  "persons": ["/", "access", "persons"],
  "groups": ["/", "access", "groups"],
  "tokens": ["/", "access", "tokens"],

  "content.home": ["/", "content"],
  "news": ["/", "content", "news"],
  "lvadgio": ["/", "content", "lvadgio"],
  "ddocuments": ["/", "content", "ddocuments"],

  "offer.home": ["/", "offer"],
  "offertexts": ["/", "offer", "texts"],
  "offers": ["/", "offer", "offers"],

  "fl.prints": ["/", "offer", "prints"],
  "fl.offertexts": ["/", "offer", "texts"],

  "products.home": ["/", "product"],

  // ---------------------------------------------------------------------------------------------------------------------------------------
  // CATALOG ZONE, v3 compatible
  // Gestion directe articles
  "catalog.home": ["/", "catalog"],
  "catalog.vivalya.articles": () => ["/", "catalog", "articles"],
  "catalog.vivalya.article": (ar: Article) => ["/", "catalog", "articles", ar.id],

  // Gestion familles (arbre)
  "catalog.vivalya.familytree": () => ["/", "catalog", "families", "tree"],
  "catalog.vivalya.familytree.family": (arf: ArticleFamily) => ["/", "catalog", "families", "tree", arf.id],
  "catalog.vivalya.familytree.article": (arf: ArticleFamily, ar: Article) => ["/", "catalog", "families", "tree",
    arf.id, "articles", ar.id],

  // Gestion famille (critères)
  "catalog.vivalya.familyflat": () => ["/", "catalog", "families", "flat"],
  "catalog.vivalya.familyflat.family": (arf: ArticleFamily) => ["/", "catalog", "families", "flat", arf.id],
  "catalog.vivalya.familyflat.article": (arf: ArticleFamily, ar: Article) => ["/", "catalog", "families", "flat",
    arf.id, "articles", ar.id],

  // Données supplémentaires
  "catalog.vivalya.articledata.allergenes": () => ["/", "catalog", "articledata", "allergenes"],
  "catalog.vivalya.articledata.allergene": (all: ArticleAllergene) => ["/", "catalog", "articledata", "allergenes", all.id],
  "catalog.vivalya.articledata.calibres": () => ["/", "catalog", "articledata", "calibres"],
  "catalog.vivalya.articledata.calibre": (cal: ArticleCalibre) => ["/", "catalog", "articledata", "calibres", cal.id],
  "catalog.vivalya.articledata.categories": () => ["/", "catalog", "articledata", "categories"],
  "catalog.vivalya.articledata.categorie": (cat: ArticleCategorie) => ["/", "catalog", "articledata", "categories", cat.id],
  "catalog.vivalya.articledata.conditionnements.colisages": () => ["/", "catalog", "articledata", "conditionnements", "colisage"],
  "catalog.vivalya.articledata.conditionnements.colisage": (cd: ArticleConditionnement) => ["/", "catalog", "articledata", "conditionnements", "colisage", cd.id],
  "catalog.vivalya.articledata.conditionnements.ucis": () => ["/", "catalog", "articledata", "conditionnements", "uci"],
  "catalog.vivalya.articledata.conditionnements.uci": (cd: ArticleConditionnement) => ["/", "catalog", "articledata", "conditionnements", "uci", cd.id],
  "catalog.vivalya.articledata.aclients": () => ["/", "catalog", "articledata", "aclients"],
  "catalog.vivalya.articledata.aclient": (ac: ArticleClient) => ["/", "catalog", "articledata", "aclients", ac.id],
  "catalog.vivalya.articledata.egalims": () => ["/", "catalog", "articledata", "egalims"],
  "catalog.vivalya.articledata.egalim": (ae: ArticleEgalim) => ["/", "catalog", "articledata", "egalims", ae.id],
  "catalog.vivalya.articledata.marques": () => ["/", "catalog", "articledata", "marques"],
  "catalog.vivalya.articledata.marque": (am: ArticleMarque) => ["/", "catalog", "articledata", "marques", am.id],
  "catalog.vivalya.articledata.origines": () => ["/", "catalog", "articledata", "origines"],
  "catalog.vivalya.articledata.origine": (or: ArticleOrigine) => ["/", "catalog", "articledata", "origines", or.id],
  "catalog.vivalya.articledata.particularites": () => ["/", "catalog", "articledata", "particularites"],
  "catalog.vivalya.articledata.particularite": (ap: ArticleParticularite) => ["/", "catalog", "articledata", "particularites", ap.id],
  "catalog.vivalya.articledata.presentations": () => ["/", "catalog", "articledata", "presentations"],
  "catalog.vivalya.articledata.presentation": (ap: ArticlePresentation) => ["/", "catalog", "articledata", "presentations", ap.id],
  "catalog.vivalya.articledata.processes": () => ["/", "catalog", "articledata", "processes"],
  "catalog.vivalya.articledata.process": (pr: ArticleProcess) => ["/", "catalog", "articledata", "processes", pr.id],
  "catalog.vivalya.articledata.productions": () => ["/", "catalog", "articledata", "productions"],
  "catalog.vivalya.articledata.production": (ap: ArticleProduction) => ["/", "catalog", "articledata", "productions", ap.id],
  "catalog.vivalya.articledata.regions": () => ["/", "catalog", "articledata", "regions"],
  "catalog.vivalya.articledata.region": (ar: ArticleRegion) => ["/", "catalog", "articledata", "regions", ar.id],
  "catalog.vivalya.articledata.traitements": () => ["/", "catalog", "articledata", "traitements"],
  "catalog.vivalya.articledata.traitement": (at: ArticleTraitement) => ["/", "catalog", "articledata", "traitements", at.id],
  "catalog.vivalya.articledata.usages": () => ["/", "catalog", "articledata", "usages"],
  "catalog.vivalya.articledata.usage": (au: ArticleUsage) => ["/", "catalog", "articledata", "usages", au.id],
  // Données fiches techniques
  "catalog.vivalya.fichesdata": () => ["/", "catalog", "fichesdata"],
  "catalog.vivalya.fichedata": (fd: FichesData) => ["/", "catalog", "fichesdata", fd.id],
  // Catalogue externe
  "catalog.external.externalarticles": () => ["/", "catalog", "externalarticles"],
  "catalog.external.externalarticle": (ea: ExternalArticle) => ["/", "catalog", "externalarticles", ea.id],

  // ---------------------------------------------------------------------------------------------------------------------------------------
  // EMAILING ZONE, v3 compatible
  // Gestion des contacts
  "emailing.contacts": () => ["/", "contacts"],
  "emailing.contact": (ct: Contact) => ["/", "contacts", ct.id],


  "tarifs.home": ["/", "tarifs"],
  "tarifs.tariftypes": ["/", "tarifs", "tariftypes"],
  "tarifs.tariftypes.tariftype": (tt: TarifType) => ["/", "tarifs", "tariftypes", tt.id],
  "tarifs.tariftypes.tarif": (tt: TarifType, t: Tarif) => ["/", "tarifs", "tariftypes", tt.id, "tarifs", t.id],
  "tarifs.tarifs": ["/", "tarifs", "tarifs"],
  "tarifs.tarifs.tarif": (t: Tarif) => ["/", "tarifs", "tarifs", t.id],
  "tarifs.tarifs.tariftype": (tt: TarifType) => ["/", "tarifs", "tariftypes", tt.id],
  "tarifs.tarifgroups": ["/", "tarifs", "tarifgroups"],
  "tarifs.cli.home": ["/", "tarifs", "cli"],
  "tarifs.cli.tariftypes.tariftype": (tt: TarifType) => ["/", "tarifs", "cli", "tariftypes", tt.id],
  "tarifs.cli.tariftypes.tarifs": (tt: TarifType) => ["/", "tarifs", "cli", "tariftypes", tt.id, "tarifs"],
  "tarifs.cli.tariftypes.tarif": (tt: TarifType, t: Tarif) => ["/", "tarifs", "cli", "tariftypes", tt.id, "tarifs", t.id],
  "tarifs.cli.tarifs": ["/", "tarifs", "cli", "tarifs"],
  "tarifs.cli.tarifs.tarif": (t: Tarif) => ["/", "tarifs", "cli", "tarifs", t.id],
  "tarifs.cli.tarifs.tariftype": (tt: TarifType) => ["/", "tarifs", "cli", "tariftypes", tt.id],
  "tarifs.com.home": ["/", "tarifs", "gen"],
  "tarifs.com.tariftypes.tariftype": (tt: TarifType) => ["/", "tarifs", "gen", "tariftypes", tt.id],
  "tarifs.com.tariftypes.tarifs": (tt: TarifType) => ["/", "tarifs", "gen", "tariftypes", tt.id, "tarifs"],
  "tarifs.com.tariftypes.tarif": (tt: TarifType, t: Tarif) => ["/", "tarifs", "gen", "tariftypes", tt.id, "tarifs", t.id],
  "tarifs.com.tarifs": ["/", "tarifs", "gen", "tarifs"],
  "tarifs.com.tarifs.tarif": (t: Tarif) => ["/", "tarifs", "gen", "tarifs", t.id],
  "tarifs.com.tarifs.tariftype": (tt: TarifType) => ["/", "tarifs", "gen", "tariftypes", tt.id],
  "tarifs.rnms": ["/", "tarifs", "rnms"],
  "tarifs.rnmtarifs": ["/", "tarifs", "rnmtarifs"],
  "tarifs.rnmmarches": ["/", "tarifs", "rnmmarches"],
  "tarifs.rnmcotations": ["/", "tarifs", "rnmcotations"],


  "fl.producers": ["/", "structure", "producers"],
  "fl.producers.map": ["/", "structure", "producers", "map"],
  "fl.producers.documents": ["/", "structure", "producers", "documents"],
  "fl.producers.print": ["/", "structure", "producers"],
  "fl.products.home": ["/", "product", "fl"],
  "fl.products": ["/", "product", "fl", "products"],
  "fl.products.prints": ["/", "err"],
  "fl.products.labels": ["/", "product", "fl", "labels"],
  "fl.products.families": ["/", "product", "fl", "families"],
  "fl.products.variants": ["/", "product", "fl", "variants"],
  "fl.products.prices": ["/", "product", "fl", "prices"],
  "fl.documents": ["/", "content", "fl", "documents"],
  "fl.offers": ["/", "offer", "fl", "offers"],
  "fl.offers.stats": ["/", "offer", "fl", "offers", "stats"],

  "sea.producers": ["/", "structure", "seaproducers"],
  "sea.producers.documents": ["/", "structure", "seaproducers", "documents"],
  "sea.producers.map": ["/", "structure", "seaproducers", "map"],
  "sea.producers.print": ["/", "err"],
  "sea.products.prints": ["/", "err"],
  "sea.products.home": ["/", "product", "sea"],
  "sea.products": ["/", "product", "sea", "products"],
  "sea.products.labels": ["/", "product", "sea", "labels"],
  "sea.products.families": ["/", "product", "sea", "families"],
  "sea.products.variants": ["/", "product", "sea", "variants"],
  "sea.products.prices": ["/", "product", "sea", "prices"],
  "sea.documents": ["/", "content", "sea", "documents"],
  "sea.offers": ["/", "offer", "sea", "offers"],
  "sea.offers.stats": ["/", "offer", "sea", "offers", "stats"]
};
