
<ng-container *ngIf="doc &amp;&amp; mode=='preview'"><a class="pointer" (click)="download(doc)">
    <div class="icon">
      <ng-container *ngIf="doc.image &amp;&amp; doc.image_img">
        <div class="img" lvadgRbg [rsrc]="doc.image_img" rdisplay="gallery" rsize="thumb"></div>
      </ng-container>
      <ng-container *ngIf="!doc.image"><i class="fal" [ngClass]="getFileType()"></i></ng-container>
    </div>
    <div class="title">{{doc.title}}</div></a></ng-container>
<ng-container *ngIf="doc &amp;&amp; mode=='label'"><a class="pointer" (click)="download(doc)">
    <div class="icon">
      <ng-container *ngIf="label &amp;&amp; label.logo_img">
        <div class="img" lvadgRbg [rsrc]="label.logo_img" rdisplay="gallery" rsize="thumb"></div>
      </ng-container>
      <ng-container *ngIf="stlabel &amp;&amp; stlabel.logo_img">
        <div class="img" lvadgRbg [rsrc]="stlabel.logo_img" rdisplay="gallery" rsize="thumb"></div>
      </ng-container>
      <ng-container *ngIf="sealabel &amp;&amp; sealabel.logo_img">
        <div class="img" lvadgRbg [rsrc]="sealabel.logo_img" rdisplay="gallery" rsize="thumb"></div>
      </ng-container>
      <ng-container *ngIf="!label&amp;&amp;!sealabel&amp;&amp;!stlabel"><i class="fal" [ngClass]="getFileType()"></i></ng-container>
    </div>
    <div class="title">{{doc.title}}</div></a></ng-container>
<ng-container *ngIf="doc &amp;&amp; mode=='previewlabel'"><a class="pointer" (click)="download(doc)">
    <div class="icon">
      <div class="labelicon">
        <ng-container *ngIf="label &amp;&amp; label.logo_img">
          <div class="labelimg" lvadgRbg [rsrc]="label.logo_img" rdisplay="gallery" rsize="thumb"></div>
        </ng-container>
        <ng-container *ngIf="stlabel &amp;&amp; stlabel.logo_img">
          <div class="labelimg" lvadgRbg [rsrc]="stlabel.logo_img" rdisplay="gallery" rsize="thumb"></div>
        </ng-container>
        <ng-container *ngIf="sealabel &amp;&amp; sealabel.logo_img">
          <div class="labelimg" lvadgRbg [rsrc]="sealabel.logo_img" rdisplay="gallery" rsize="thumb"></div>
        </ng-container>
      </div>
      <ng-container *ngIf="doc.image &amp;&amp; doc.image_img">
        <div class="img" lvadgRbg [rsrc]="doc.image_img" rdisplay="gallery" rsize="thumb"></div>
      </ng-container>
      <ng-container *ngIf="!doc.image"><i class="fal" [ngClass]="getFileType()"></i></ng-container>
    </div>
    <div class="title">{{doc.title}}</div></a></ng-container>