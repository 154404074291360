import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RnmMarche, RnmMarcheService} from "./rnmmarche.service";

@Injectable({providedIn: "root"})
export class RnmMarcheResolve implements Resolve<RnmMarche> {
  constructor(private _bs: RnmMarcheService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<RnmMarche> {
    return this._bs.get(+route.params["rnmMarcheId"]);
  }
}
