/**
 * Images gallery management (logo & gallery)
 *
 * Display logo & gallery for any model with logo & images.
 *
 * @status ALPHA
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @date 2018-05-22-11:43:55
 * @changed 2018-05-22-11:43:55
 * @copyright (c) 2017-2018 Jean-Matthieu BARBIER
 * @license AGPLv3
 */

import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DSRestCollection} from "@solidev/ngdataservice";
import {BsacApiConstantsService, BsacMessageService, IBsacApiConstants} from "@solidev/bsadmincomponents";
import {Rimg} from "../../../../comps/rimg/rimg";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Image, ImageService} from "../image.service";
import {Subject} from "rxjs";
import {first} from "rxjs/operators";

@Component({
  selector: "lvadg-images-manage",
  templateUrl: "./images-manage.component.pug",
  styleUrls: ["./images-manage.component.sass"]
})
export class ImagesManageComponent implements OnInit, OnDestroy {

  @Input() public model: any;  // FIXME: add type or interface to cope with logo_img & images_details fields
  @Input() public logofield = "logo";
  @Input() public logoaction = "logo";
  @Input() public logodesc = "Logo";
  @Input() public imagesfield = "images";
  @Input() public imagesdesc = "Images (galeries, pointeurs, affiches, ...)";
  @Input() public imagesaction = "image";
  public curimage!: Image;
  public currimage!: Rimg;
  public curtype!: string;
  public FLAGS!: IBsacApiConstants;
  public FLAGSD: { [index: string]: string } = {};
  public curdisp = "sm";
  private _collection: DSRestCollection<any>;
  private _subscriptions$ = new Subject<void>();


  constructor(private _images: ImageService,
              private _msg: BsacMessageService,
              private _modal: NgbModal,
              private _cstt: BsacApiConstantsService) {
  }

  public ngOnDestroy(): void {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  public async ngOnInit(): Promise<any> {
    this._collection = (this.model as any)._collection;
    this.FLAGS = await this._cstt.get("IMAGE_FLAGS").pipe(
      first()
    ).toPromise();
    this.FLAGSD = {};
    for (const f of this.FLAGS) {
      this.FLAGSD[f.value] = f.desc;
    }
  }

  public async deleteImage(close: any): Promise<any> {
    if (this.curtype === "image") {
      try {
        await this.model.action("remove_" + this.imagesaction,
          {method: "POST", body: {
            pk: this.curimage.id,
            field: this.imagesfield
          }}).toPromise();
        await this.model.refresh().toPromise();
        this._msg.success("Image supprimée avec succès");
        close("deleted");
      } catch (e) {
        this._msg.error("Erreur lors de la suppression", "failed", e);
      }
    } else {
      try {
        await this.model.action("unset_" + this.logoaction,
          {method: "POST", body: {
            pk: this.curimage.id,
            field: this.logofield
          }}).toPromise();
        await this.model.refresh().toPromise();
        this._msg.success("Image supprimée avec succès");
        close("deleted");
      } catch (e) {
        this._msg.error("Erreur lors de la suppression", "failed", e);
      }
    }
  }

  public haveFlag(img: Image, flag: string) {
    return img.flags.indexOf(flag) !== -1;
  }

  public getImageLink(): string {
    if (this.curdisp === "orig") {
      return this.curimage.orig;
    }
    return this.currimage.byName(this.curdisp).image;
  }

  public toggleFlag(img: Image, flag: string) {
    const pos = img.flags.indexOf(flag);
    if (pos === -1) {
      img.flags.push(flag);
    } else {
      img.flags.splice(pos, 1);
    }
    img.update(["flags"]).subscribe(() => {
      this._msg.success("Flags updated with success");
    });
  }

  public async setImageProperties(image: Rimg, editor: any, type: string): Promise<any> {
    this.curtype = type;
    this.curimage = await this._images.get(image.id).toPromise();
    this.currimage = new Rimg(this.curimage);
    this._modal.open(editor, {size: "lg", centered: true}).result.then((result) => {
      Object.assign(image, this.curimage);
    }, (reason) => {
      Object.assign(image, this.curimage);
    });
  }

  public async uploadedLogo(data: any): Promise<any> {
    try {
      await this.model.refresh().toPromise();
      this._msg.success("Logo uploaded with success");
    } catch (error) {
      this._msg.error("Logo upload failed", "API Return : " + error.toString(), (error as any)._body);
    }
  }

  public async uploadedImage(data: any): Promise<any> {
    try {
      await this.model.refresh().toPromise();
      this._msg.success("Image uploaded with success");
    } catch (error) {
      this._msg.error("Image upload failed", "API Return : " + error.toString(), (error as any)._body);
    }
  }

  public getLogoUrl(): string {
    // TODO: check in ngdataservice why any is required
    const url = (this._collection.backend as any).getRequestUrl(
      (this._collection as any).adapter.detail_action(this.model, "set_" + this.logoaction)
    );
    return url;
  }

  public getImagesAddUrl(): string {
    // TODO: check in ngdataservice why any is required
    const url = (this._collection.backend as any).getRequestUrl(
      this._collection.adapter.detail_action(this.model, "add_" + this.imagesaction)
    );
    return url;
  }
}
