import {DSModel, DSRestCollection, IDSModelList} from "@solidev/ngdataservice";
import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {BsacCustomField, BsacMessageService, BsacModelList, BsacModelListProvider, ISorterData} from "@solidev/bsadmincomponents";
import {takeUntil} from "rxjs/operators";
import {RoutesService} from "../../../lib/comps/routes.service";
import {ICustomSelectAction} from "../../services/action";
import * as XLSX from "xlsx";

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ModelListBaseComponent<T extends DSModel,
  TS extends DSRestCollection<T>> implements OnInit, OnDestroy {
  @Input() public mode = "admin";
  @Input() public zone: string;
  @Input() public type: string;
  @Input() public displayMode: "horizontal" | "vertical" | "vertical2res" | "horizontal2res" = "horizontal";
  @Input() public tableResults: boolean = true;
  @Input() public filter: any = {};
  @Input() public filter$: Observable<any>;
  @Input() public name?: string;

  @Input() public reload: Subject<void> | Observable<void>;
  @Input() public maxpages = 1000;

  // Fields
  @Input() public default_fields: string[] = [];
  @Input() public allowed_fields: string[] = [];
  @Input() public excluded_fields: string[] = [];
  @Input() public sorter_field: ISorterData;

  // Filters
  @Input() public default_filters: string[] = ["search"];
  @Input() public allowed_filters: string[] = [];
  @Input() public excluded_filters: string[] = [];
  @Input() public context: any = {};

  // Actions
  @Input() public custom_fields: BsacCustomField[] = [];
  @Input() public actions: string[] = [];
  @Output() public action = new EventEmitter<any>();
  @Input() public select_actions: string[] = [];
  @Input() public select_custom_actions: ICustomSelectAction[] = [];
  @Input() public current?: T;

  // Outputs
  @Output() public selected = new EventEmitter<T>();
  @Output() public newResults = new EventEmitter<T[]>();

  @ViewChild("TABLE", {static: false}) table: ElementRef;

  public results: T[];
  public resultsMode = "list";
  @Input() public reuseList = true; // If false, a new list instance is created at each load

  public list: BsacModelList<T>;
  public ready: boolean = false;

  protected _subscriptions$ = new Subject<void>();

  constructor(
    protected _list: BsacModelListProvider,
    protected _items: TS,
    protected _msgs: BsacMessageService,
    public routes: RoutesService
  ) {
  }

  public ngOnDestroy(): void {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  /**
   * updateFilter sets filter values depending on given key:model values
   * @param fields
   */
  public updateFilter(fields: { [index: string]: DSModel | undefined } = {}): { [index: string]: string } {
    for (const k of Object.keys(fields)) {
      if (fields[k]) {
        this.filter[k] = fields[k]!._pk as string;
      }
    }
    return this.filter;
  }


  public async preNgOnInit(): Promise<void> {
  }

  public async postNgOnInit(): Promise<void> {
  }

  public async ngOnInit(): Promise<void> {
    await this.preNgOnInit();
    if (!this.name) {
      this.name = `${this.mode}.${this.zone}.${this.type}`;
      console.log("Set default name", this.name);
    }
    this.list = await this._list.sget<T>(this.name, this.mode, this._items, {
      allowed_fields: this.allowed_fields,
      default_fields: this.default_fields,
      custom_fields: this.custom_fields,
      excluded_fields: this.excluded_fields,
      allowed_filters: this.allowed_filters,
      default_filters: this.default_filters,
      excluded_filters: this.excluded_filters,
      qsparams: {query: this.filter, context: this.context},
      sorter: this.sorter_field,
      unsubscribe: this._subscriptions$,
      reload: !this.reuseList
    });

    // Results subscription
    this.list.results.subscribe((results) => {
      this.results = results.items;
      this.results = this.processResults(results);
      this.newResults.emit(this.results);
    });
    if (this.reload && this.reload.pipe !== undefined) {
      this.reload.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
        this.list.get({query: this.filter, context: this.context}, true);
      });
    }
    if (this.filter$ && this.filter$.pipe !== undefined) {
      this.filter$.pipe(takeUntil(this._subscriptions$)).subscribe((filter) => {
        Object.assign(this.filter, filter);
        this.list.get({query: this.filter, context: this.context}, true);
      });
    }
    if (this.checkMode("admin")) {
      this.maxpages = 10000;
    }

    await this.postNgOnInit();
    this.ready = true;
  }

  // -----------------------------------------------------------------------------------------------------------
  // LIST HELPERS

  public checkMode(...args: string[]): boolean {
    return args.indexOf(this.mode) !== -1;
  }

  public eltId(index: number, model: T): number | string {
    return model._pk as number;
  }

  // LINK
  public getDetailLink(model: T): (string | number)[] {
    return this.routes.detail(this.zone, this.type, model);
  }


  // ACTIONS
  public checkAction(action: string): boolean {
    return this.actions.indexOf(action) !== -1;
  }

  // Table classes
  public getTableClasses(): { [index: string]: string | boolean } {
    return {"table-hover": true};
  }

  // Row classes
  public getRowClasses(model: T): { [index: string]: string | boolean } {
    if (this.current && this.current._pk === model._pk) {
      return {"table-success": true};
    }
    return {};
  }

  // Cell classes
  public getCellClasses(
    cell: string,
    model: T
  ): { [index: string]: string | boolean } {
    return {};
  }

  public processResults(results: IDSModelList<T>): T[] {
    return results.items;
  }

  public xlsxExport(name: string = "export", sheet: string = "Export", stripLinks: boolean = true) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheet);
    const range = XLSX.utils.decode_range(ws["!ref"] as string);
    for (let rowNum = range.s.r; rowNum <= range.e.r; ++rowNum) {
      for (let colNum = range.s.c; colNum <= range.e.c; ++colNum) {
        const cell = ws[XLSX.utils.encode_cell({r: rowNum, c: colNum})];
        if (stripLinks) {
          cell.l = undefined;
        } else {
          // Fix base url
          if (cell.l && cell.l.Target && cell.l.Target[0] == "/") {
            cell.l.Target = window.location.protocol + "//" + window.location.hostname + cell.l.Target;
          }
        }
      }
    }
    XLSX.writeFile(wb, `${name}.xlsx`);
  }

}
