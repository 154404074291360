import {Component, Input} from "@angular/core";
import {Storage, StorageService} from "../storage.service";
import {BsacApiConstantsService, BsacMessageService, BsacModelListProvider, IBsacApiConstants} from "@solidev/bsadmincomponents";
import {Member, MemberService} from "../../member/member.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {SLabelService} from "../../slabel/slabel.service";
import {first} from "rxjs/operators";
import {combineLatest, firstValueFrom, Subject} from "rxjs";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {RoutesService} from "../../../../comps/routes.service";
import {CustomDataKey, CustomDataKeyService} from "../../../customdata/customdatakey/customdatakey.service";
import {GeoRegion, GeoRegionService} from "../../../customdata/georegion/georegion.service";
import {GeoCommune, GeoCommuneService} from "../../../customdata/geocommune/geocommune.service";
import {GeoDepartement, GeoDepartementService} from "../../../customdata/geodepartement/geodepartement.service";

@Component({
  selector: "lvadg-storage-list",
  templateUrl: "./storage-list.component.pug",
  styleUrls: ["./storage-list.component.sass"],
})
export class StorageListComponent extends ModelListBaseComponent<Storage,
  StorageService> {
  @Input() public filter_stats = (s: {value: string; desc: string}) => {
    return s.value.startsWith("STORAGE_");
  }
  @Input() public default_stats: string[] = [
    "STORAGE_LOCAL200_PRODUCER_COUNT",
    "STORAGE_LOCAL200_PRODUCT_COUNT",
  ];
  @Input() public available_stats: string[] = [];
  public enabled_stats: string[] = [];
  public slabels!: IBsacApiConstants;
  public members$!: DSRestQueryset<Member>;
  public members!: Member[];
  public cdatakeys!: CustomDataKey[];
  public fullMembers!: Member[];
  public fullStorages!: Storage[];
  public mapmode: "all" | "results" = "all";
  public regions$!: DSRestQueryset<GeoRegion>;
  public communes$!: DSRestQueryset<GeoCommune>;
  public departements$!: DSRestQueryset<GeoDepartement>;


  constructor(
    _list: BsacModelListProvider,
    _items: StorageService,
    _msgs: BsacMessageService,
    routes: RoutesService,
    public _cstt: BsacApiConstantsService,
    private _slabels: SLabelService,
    protected _regions: GeoRegionService,
    protected _departements: GeoDepartementService,
    protected _communes: GeoCommuneService,
    private _customdatakeys: CustomDataKeyService,
    private _storages: StorageService,
    private _members: MemberService
  ) {
    super(_list, _items, _msgs, routes);
    this.type = "storages";
  }

  public async preNgOnInit(): Promise<void> {
    this.custom_fields = [
      {name: "mainlocation__address", verbose_name: "Adresse"},
      {name: "mainlocation__postcode", verbose_name: "Code postal"},
      {name: "mainlocation__city", verbose_name: "Ville"},
      {name: "mainlocation__departement", verbose_name: "Département"},
      {name: "mainlocation__region", verbose_name: "Région"},
      {name: "select", verbose_name: "Sélection"},
      {name: "actions", verbose_name: "Actions"},
      {name: "latest_stats", verbose_name: "Dernières stats"},
    ];
    this.slabels = await firstValueFrom(this._slabels.constants$);
    this.members$ = this._members.queryset;
    this.regions$ = this._regions.queryset;
    this.communes$ = this._communes.queryset;
    this.departements$ = this._departements.queryset;
    this.cdatakeys = await firstValueFrom(this._customdatakeys
      .getByDest("STO", false));
    for (const ck of this.cdatakeys) {
      this.custom_fields.push({
        name: `customdata_${ck.id}`,
        verbose_name: ck.name,
      });
    }
    if (!this.filter) {
      this.filter = {};
    }
    this.filter.stats = this.default_stats.join(",");
    this.getFullMemberStorage();
    if (!this.reload) {
      this.reload = new Subject<void>();
    }

    return super.preNgOnInit();
  }


  public getFullMemberStorage(): void {
    combineLatest([
      this._members.queryset
        .query(this.filter)
        .get({query: {smap: 1, stats: ""}}),
      this._storages.queryset
        .query(this.filter)
        .get({query: {smap: 1, stats: ""}}),
    ])
      .pipe(first())
      .subscribe((res) => {
        this.fullMembers = res[0].items;
        this.fullStorages = res[1].items;
      });
  }

  public async postNgOnInit(): Promise<void> {
    // Update map mode if there is a filter or not...
    this.list.filter.output$.subscribe((filter) => {
      if (Object.keys(filter).length === 0) {
        this.mapmode = "all";
      } else {
        this.mapmode = "results";
      }
    });

    // Get members from current results
    this.newResults.subscribe((results) => {
      this.members = [];
      const mids = [];
      for (const s of results) {
        if (mids.indexOf(s.member) === -1) {
          mids.push(s.member);
          this.members.push(s.member_details);
        }
      }
    });
  }

  public statsSelection(enabled: string[]): void {
    this.enabled_stats = enabled;
    this.filter.stats = this.enabled_stats.join(",");

    (this.reload as Subject<void>).next()
  }

  public onAction(action: any): void {
    this.action.emit(action);
  }
}
