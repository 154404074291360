import {Component, Input, OnInit} from "@angular/core";
import {SelectionService} from "../selection.service";
import {IDSModel} from "@solidev/ngdataservice";

@Component({
  selector: "lvadg-selection-selitem",
  templateUrl: "./selection-selitem.component.pug",
  styleUrls: ["./selection-selitem.component.sass"]
})
export class SelectionSelitemComponent implements OnInit {

  @Input() public item!: IDSModel | number;
  @Input() public items: (IDSModel | number)[];
  @Input() public stype!: string;

  constructor(public sel: SelectionService) {
  }

  ngOnInit() {
  }
}
