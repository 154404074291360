import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {SelectionService} from "../selection.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ICustomSelectAction} from "../../../../includes/services/action";

@Component({
  selector: "lvadg-selection-summary",
  templateUrl: "./selection-summary.component.pug",
  styleUrls: ["./selection-summary.component.sass"]
})
export class SelectionSummaryComponent implements OnInit {
  @Input() public stype!: string;
  @Input() public unit = "éléments";
  @Input() public actions: string[] = ["clear", "print", "quickprint", "summary", "export"];
  @Input() public custom_actions: ICustomSelectAction[] = [];
  @Output() public action = new EventEmitter<any>();


  constructor(public sel: SelectionService,
              private _modal: NgbModal) {
  }

  ngOnInit() {
  }

  public showDetails(ref: any): void {
    this._modal.open(ref);
  }

  public print(): void {
    this.action.emit({action: "print", stype: this.stype, items: this.sel.items(this.stype)});
  }

  public quickprint(): void {
    this.action.emit({action: "quickprint", stype: this.stype, items: this.sel.items(this.stype)});
  }

  public export(): void {
    this.action.emit({action: "export", stype: this.stype, items: this.sel.items(this.stype)});
  }

  public custom(action: ICustomSelectAction): void {
    this.action.emit({action: action.name, stype: this.stype, items: this.sel.items(this.stype)});
  }

  public checkAction(action: string): boolean {
    if (!this.actions) {
      return true;
    }
    return this.actions.indexOf(action) !== -1;
  }
}
