
<bsac-breadcrumb></bsac-breadcrumb>
<h1 class="page-header">
  <button class="btn btn-sm btn-primary float-end" (click)="create=!create" *ngIf="mode==='admin'"><i class="fa fa-plus me-2"></i>Ajouter un tarif</button><i class="fa fa-table me-2"></i>
  <ng-container *ngIf="tarifType">{{tarifType.name}} <small>Liste des tarifs</small></ng-container>
  <ng-container *ngIf="!tarifType">
    <ng-container *ngIf="ttype==='cli'">Tarifs clients</ng-container>
    <ng-container *ngIf="ttype==='gen'">Tarifs génériques</ng-container>
  </ng-container>
</h1>
<div class="card mb-3" *ngIf="create">
  <div class="card-header"><i class="fa fa-plus me-2"></i>Ajouter un tarif</div>
  <div class="card-body">
    <lvadg-tarif-create (created)="onCreated($event)"></lvadg-tarif-create>
  </div>
</div>
<lvadg-tarif-list *ngIf="ready" [displayMode]="mode==='admin'?'horizontal':'vertical'" [mode]="mode" [tarifType]="tarifType" [tarif_detail_route]="tarif_detail_route" [tariftype_detail_route]="tariftype_detail_route" [filter]="filter" [default_fields]="default_fields" [default_filters]="default_filters"></lvadg-tarif-list>