import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {ExternalArticle} from "../../../../lib/models/catalog/externalarticle/externalarticle.service";
import {CommonBaseComponent} from "../../common-base.component";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {NavDriver, TabMemoryService} from "../../../../lib/comps/tab-memory.service";
import {ArticleFamily} from "../../../../lib/models/catalog/family/article-family.service";
import {VivalyaBill} from "../../../../lib/models/sales/bill/bill.service";
import {VivalyaBillItem} from "../../../../lib/models/sales/billitem/billitem.service";
import {Storage} from "../../../../lib/models/structure/storage/storage.service";
import {Resto} from "../../../../lib/models/structure/resto/resto.service";
import {Client} from "../../../../lib/models/structure/client/client.service";

export interface CatalogExternalArticleManageViewV3Params extends BaseRouteV3Params {
  article_detail_route: RouteConfigItemV3;
  externalarticle_detail_route: RouteConfigItemV3;
  articlefamily_detail_route: RouteConfigItemV3;
  bill_detail_route: RouteConfigItemV3;
  billitem_detail_route: RouteConfigItemV3;
  storage_detail_route: RouteConfigItemV3;
  resto_detail_route: RouteConfigItemV3;
  client_detail_route: RouteConfigItemV3;
}

export interface CatalogExternalArticleManageViewV3Data extends CatalogExternalArticleManageViewV3Params {
  externalArticle: ExternalArticle;
}


@Component({
  selector: "lvadg-catalog-externalarticle-manage-view",
  templateUrl: "./catalog-externalarticle-manage-view.component.pug",
  styleUrls: ["./catalog-externalarticle-manage-view.component.sass"]
})
export class CatalogExternalArticleManageViewV3Component extends CommonBaseComponent {
  public article_detail_route!: RouteV3<Article>;
  public externalarticle_detail_route!: RouteV3<ExternalArticle>;
  public articlefamily_detail_route!: RouteV3<ArticleFamily>;
  public bill_detail_route!: RouteV3<VivalyaBill>;
  public billitem_detail_route!: RouteV3<VivalyaBillItem>;
  public storage_detail_route!: RouteV3<Storage>;
  public resto_detail_route!: RouteV3<Resto>;
  public client_detail_route!: RouteV3<Client>;
  public externalArticle!: ExternalArticle;
  public navId!: NavDriver;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute,
              private tms: TabMemoryService) {
    super(routes, router, route);
  }

  protected async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogExternalArticleManageViewV3Data;
    this.externalArticle = data.externalArticle || this.externalArticle;
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    this.externalarticle_detail_route = new RouteV3<ExternalArticle>(data.externalarticle_detail_route, this);
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    this.articlefamily_detail_route = new RouteV3<ArticleFamily>(data.articlefamily_detail_route, this);
    this.bill_detail_route = new RouteV3<VivalyaBill>(data.bill_detail_route, this);
    this.billitem_detail_route = new RouteV3<VivalyaBillItem>(data.billitem_detail_route, this);
    this.storage_detail_route = new RouteV3<Storage>(data.storage_detail_route, this);
    this.client_detail_route = new RouteV3<Client>(data.client_detail_route, this);
    this.resto_detail_route = new RouteV3<Resto>(data.resto_detail_route, this);
    this.navId = this.tms.memFor(["externalarticlemanage"], "manage", false);
    return super.preNgOnInit();
  }

}
