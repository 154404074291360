/**
 * Provider manage.
 *
 * Admin & provider:admin modes.
 *
 * @status ALPHA
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @date 2021-04-08-13:24:20
 * @changed 2021-04-08-13:24:25
 * @copyright (c) 2017-2021 Jean-Matthieu BARBIER
 * @license AGPLv3
 */


import {Component, Input} from "@angular/core";
import {Provider, ProviderService} from "../provider.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";

@Component({
  selector: "lvadg-provider-manage",
  templateUrl: "./provider-manage.component.pug",
  styleUrls: ["./provider-manage.component.sass"]
})
export class ProviderManageComponent {

  @Input() public provider!: Provider;
  @Input() public mode = "admin";

  constructor(public providers$: ProviderService, protected _msg: BsacMessageService) {

  }

  public checkMode(...args: string[]): boolean {
    return args.indexOf(this.mode) !== -1;
  }
  public getUploadUrl(): string {
    if (!this.provider) {
      return "";
    }
    return (this.providers$.backend as any).getRequestUrl(
      (this.providers$ as any).adapter.detail_action(this.provider, "add_document")
    );
  }

  public async onUploaded(event: any): Promise<void> {
    await this.provider.refresh().toPromise();
  }

  public async onDocumentDeleted() {
    this._msg.success("Document supprimé avec succès");
    await this.provider.refresh().toPromise();
  }

}
