import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonBaseComponent} from "../../common-base.component";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {AuthService} from "../../../../lib/models/user/auth.service";
import {NavDriver, TabMemoryService} from "../../../../lib/comps/tab-memory.service";
import {TarifType} from "../../../../lib/models/tarif/tariftype/tariftype.service";
import {ArticleTarifTemplate} from "../../../../lib/models/tarif/articletarif/articletariftemplate.service";
import {ExternalArticle} from "../../../../lib/models/catalog/externalarticle/externalarticle.service";

export interface ArticleTarifTemplateManageViewV3Params extends BaseRouteV3Params {
  ttype: "cli" | "gen";
  tariftype_detail_route: RouteConfigItemV3;
  eaclient_detail_route?: RouteConfigItemV3;
}

export interface ArticleTarifTemplateManageViewV3Data extends ArticleTarifTemplateManageViewV3Params {
  tarifType: TarifType;
  articleTarifTemplate: ArticleTarifTemplate;
}


@Component({
  selector: "lvadg-articletariftemplate-manage-view",
  templateUrl: "./articletariftemplate-manage-view.component.pug",
  styleUrls: ["./articletariftemplate-manage-view.component.sass"]
})
export class ArticleTarifTemplateManageViewV3Component extends CommonBaseComponent {
  public ttype: "cli" | "gen" | "" = "";
  public articleTarifTemplate!: ArticleTarifTemplate;
  public tarifType!: TarifType;
  public navId!: NavDriver;
  public tariftype_detail_route!: RouteV3<TarifType>;
  public eaclient_detail_route?: RouteV3<ExternalArticle>;

  constructor(routes: RoutesService,
              router: Router,
              route: ActivatedRoute,
              public auth: AuthService,
              private tms: TabMemoryService) {
    super(routes, router, route);
  }

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as ArticleTarifTemplateManageViewV3Data;
    this.ttype = data.ttype;
    this.tarifType = data.tarifType;
    this.articleTarifTemplate = data.articleTarifTemplate;
    this.tariftype_detail_route = new RouteV3<TarifType>(data.tariftype_detail_route, this);
    if (data.eaclient_detail_route) {
      this.eaclient_detail_route = new RouteV3<ExternalArticle>(data.eaclient_detail_route, this);
    }
  }

  public onDeleted() {
    this.router.navigate(this.tariftype_detail_route.route({tarifType: this.tarifType!}));
  }

  protected async postNgOnInit(): Promise<void> {
    this.navId = this.tms.memFor([this.currentRoute!.name, this.articleTarifTemplate.id], "manage", false);
  }
}
