
<ng-template #popContent>
  <div class="msgpopup" *ngIf="edit.message">
    <button class="btn btn-link float-end" (click)="p.close(); edit.message=false;">&times;</button>
    <dl>
      <bsac-dd-field [model]="at" field="message" type="textarea" (changed)="onUpdated()" [edit]="edit">Editer le message</bsac-dd-field>
    </dl>
  </div>
  <div class="msgpopup" *ngIf="!edit.message &amp;&amp; message">
    <div>{{message}}</div>
  </div>
</ng-template><i class="fa fa-sticky-note p-1 pointer" [class.text-warning]="message" [title]="message||''" [ngbPopover]="popContent" [autoClose]="false" triggers="manual" #p="ngbPopover" (click)="onClick(p)" (mouseenter)="onEnter(p)" (mouseleave)="onLeave(p)"></i>