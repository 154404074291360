
<h3>Restaurants livrables</h3>
<div class="list-group">
  <div class="list-group-item list-group-item-action list-group-item-warning pointer" (click)="addAllRestos()"><i class="fa fa-plus me-2"></i>AJOUTER TOUS LES RESTAURANTS</div>
  <div class="list-group-item list-group-item-action list-group-item-warning pointer" (click)="removeRestos()"><i class="fa fa-trash me-2"></i>SUPPRIMER TOUS LES RESTAURANTS</div>
</div>
<div class="form-group mt-2">
  <input class="form-control" [(ngModel)]="curFilter" placeholder="Filtrer par nom">
</div>
<div class="list-group">
  <ng-container *ngFor="let r of available">
    <div class="list-group-item list-group-item-action pointer" *ngIf="filter(r)" [class.list-group-item-success]="selected(r)" (click)="toggleResto(r)">{{r.name}} <small>{{r.mainlocation_details?.city}}</small></div>
  </ng-container>
</div>
<!--.restolist-->
<!--  div(*ngFor="let r of offer.restos_details")-->
<!--    lvadg-confirm-delete((confirm)="removeResto(r.id)", type="trash", *ngIf="checkMode('admin', 'member')")-->
<!--    |  {{r.name}}-->
<!--.mt-3(*ngIf="offer.restos?.length && checkMode('admin', 'member')")-->
<!--  lvadg-confirm-delete((confirm)="removeRestos()", type="button")-->
<!--    i.fa.fa-trash.me-2-->
<!--    | Supprimer tous les restaurants-->
<!---->
<!--button.btn.btn-primary.pointer.mt-2((click)="dispAddResto=!dispAddResto", *ngIf="checkMode('admin', 'member')")-->
<!--  i.fa.fa-plus.me-2-->
<!--  | Ajouter un restaurant-->
<!--div(*ngIf="dispAddResto").mt-2-->
<!--  lvadg-resto-select((selected)="addResto($event)", [filter]="{cclient: offer.client_details.id, storage: offer.storage_details.id}")-->