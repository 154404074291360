import {Injectable} from "@angular/core";
import {DSCharField, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProductVariant} from "../_productvariant/productvariant-base.model";

export class SeaProductVariant extends BaseProductVariant {
  @DSCharField({
    name: "origin",
    verboseName: "Origin",
    required: false,
    helpText: "Origine",
    minLength: 0,
    maxLength: 150,
    choices: []
  })
  public origin!: string;
}

@Injectable({
  providedIn: "root"
})
export class SeaProductVariantService extends DSRestCollection<SeaProductVariant> {
  public adapter_config = {basePath: "/api/v2/seaproductvariants"};
  public model = SeaProductVariant;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
