import {Injectable} from "@angular/core";
import {DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseLabel, BaseLabelService} from "../_label/label-base.model";

export class SeaLabel extends BaseLabel {
}

@Injectable({providedIn: "root"})
export class SeaLabelService extends BaseLabelService<SeaLabel> {
  public adapter_config = {basePath: "/api/v2/sealabels"};
  public model = SeaLabel;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}

