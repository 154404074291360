import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {debounceTime, filter, takeUntil} from "rxjs/operators";
import {CommFieldParamsService} from "../commfieldparams.service";
import {Subject} from "rxjs";
import {IFieldParameter} from "../commfield.service";

@Component({
  selector: "lvadg-commfield-select",
  templateUrl: "./commfield-select.component.pug",
  styleUrls: ["./commfield-select.component.sass"]
})
export class CommFieldSelectComponent implements OnInit {

  @Input() public params!: CommFieldParamsService;
  @Input() public part!: string;
  @Input() public name!: string;
  public _subscriptions = new Subject<void>();
  public param!: IFieldParameter;
  public form!: UntypedFormGroup;


  constructor() {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      "value": new UntypedFormControl(""),
    });
    this.params.getByName(this.part, this.name)
      .pipe(takeUntil(this._subscriptions),
        filter((value) => !!value)
      )
      .subscribe((val) => {
        this.param = val as IFieldParameter;
        let curvalue = "";
        for (const o of this.param.choices) {
          if (o.current) {
            curvalue = o.value;
          }
          this.form.setValue({value: curvalue}, {emitEvent: false});
        }
      });
    this.form.valueChanges
      .pipe(takeUntil(this._subscriptions),
        debounceTime(750))
      .subscribe(async () => {
        this.params.setByName(this.part, this.name, {value: this.form.value.value});
      });
  }

  public removeParam(): void {
    this.params.removeByName(this.part, this.name);
  }
}
