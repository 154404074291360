import {Component, Input, OnInit} from "@angular/core";
import {ModelPrint} from "../../modelprint.service";
import {PrinterJob, PrinterJobService} from "../../printjob.service";
import {DSRestQueryset} from "@solidev/ngdataservice";

@Component({
  selector: "lvadg-modelprint-job-history",
  templateUrl: "./modelprint-job-history.component.pug",
  styleUrls: ["./modelprint-job-history.component.sass"]
})
export class ModelprintJobHistoryComponent implements OnInit {
  @Input() public print!: ModelPrint;
  public jobs!: PrinterJob[];
  public display: any = {};
  private _qs: DSRestQueryset<PrinterJob>;

  constructor(private _jobs: PrinterJobService) {
  }


  public ngOnInit() {
    this._qs = this._jobs.queryset;
    this.refresh();
  }

  public async refresh() {
    this.jobs = (await this._qs.query({model: this.print.id}).get().toPromise()).items;
  }

}
