import {Member, MemberService} from "../member.service";
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {IDSQueryset} from "@solidev/ngdataservice";
import {Observable} from "rxjs";
import {debounceTime, map, switchMap} from "rxjs/operators";

@Component({
  selector: "lvadg-member-select",
  templateUrl: "./member-select.component.pug",
  styleUrls: ["./member-select.component.sass"]
})
export class MemberSelectComponent {
  public queryset!: IDSQueryset<Member, any, any>;
  @Input() public hint = "Selectionnez un adherent";
  @Output() public selected: EventEmitter<Member> = new EventEmitter<Member>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();


  constructor(private _model: MemberService) {
    this.queryset = this._model.queryset;
  }


  public search = (text$: Observable<string>) => {
    return text$.pipe(debounceTime(200),
      switchMap((term) => {
        this.queryset.query({search: term});
        return this.queryset.get().pipe(map((result) => {
          return result.items;
        }));
      }));
  };

  public getName(x: any): string {
    return x.name;
  }


  public selItem(model: any): void {
    this.selected.emit(model.item);
  }

}
