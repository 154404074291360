
<div class="row g-3" *ngIf="article">
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-info-circle me-2"></i>Infos article</div>
      <div class="card-body">
        <dl class="row">
          <div class="col-12 text-info text-center mb-2"><b>Nomenclature & classification</b></div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="name" type="auto">Nom</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="code" type="auto">Code</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="scname" type="auto">Nom scientifique</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="libelle" type="auto">Libellé complet</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="libelle_auto" type="auto" [editable]="false">Libellé (auto)</bsac-dd-field>
          <div class="col-sm-6">
            <div class="btn-group">
              <button class="btn btn-outline-secondary" (click)="genLibelle()"><i class="fa fa-cogs me-2"></i>Régénérer le libellé auto</button>
              <button class="btn btn-outline-primary" (click)="useAutoLibelle()"><i class="fa fa-save me-2"></i>Utiliser le libellé auto</button>
            </div>
          </div>
          <bsac-dd-field class="col-sm-12" [model]="article" field="url" type="auto">URL infos spécifiques</bsac-dd-field>
          <div class="col-sm-12">
            <dt>Classification (liens)</dt>
            <dd>
              <ng-container *ngFor="let f of article.family_details"><a class="link" [routerLink]="articlefamily_detail_route?.route({articleFamily: $any({id: f.id})})"><span class="me-2">&gt; {{f.name}}</span></a></ng-container>
            </dd>
          </div>
          <bsac-dd-field class="col-sm-12" [model]="article" field="family" type="auto" [related]="family$" [descfn]="getFamilyName" relatedLabel="Sélectionnez une famille">Modification de la famille</bsac-dd-field>
        </dl>
        <dl class="row">
          <div class="col-12 text-info text-center mb-2"><b>DLC & Conservation</b></div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="dlc_value" type="auto">Valeur DLC</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="dlc_unit" type="auto">Unité DLC</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="ddm_value" type="auto">Valeur DDM</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="ddm_unit" type="auto">Unité DDM</bsac-dd-field>
          <bsac-dd-field class="col-sm-12" [model]="article" field="temperature" type="auto">Température</bsac-dd-field>
        </dl>
        <dl class="row">
          <div class="col-12 text-info text-center mb-2"><b>Saisonnalité et infos marché</b></div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="rnm" type="auto">RNM</bsac-dd-field>
          <div class="col-sm-6">
            <dt>Saisonnalité</dt>
            <dd>
              <lvadg-seasons-manage [seasons]="article.seasons" (changed)="updateSeasons($event)" zone="fl" [perline]="6"></lvadg-seasons-manage>
            </dd>
          </div>
        </dl>
        <dl class="row">
          <div class="col-12 text-info text-center mb-2"><b>Infos internes</b></div>
          <div class="col-sm-4">
            <dt>Création</dt>
            <dd>{{article.created|date:'short'}}</dd>
          </div>
          <div class="col-sm-4">
            <dt>Mise à jour</dt>
            <dd>{{article.updated|date:'short'}}</dd>
          </div>
          <bsac-dd-field class="col-sm-4" [model]="article" field="vvid" type="auto">ID Vivalya</bsac-dd-field>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-forklift me-2"></i>Infos conditionnement & facturation</div>
      <div class="card-body">
        <dl class="row">
          <div class="col-12 text-info text-center mb-2"><b>Poids min/max</b></div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="poids_min" type="auto">Poids minimum</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="poids_max" type="auto">Poids maxmimum</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 text-info text-center mb-2">
            <hr><b>Colisage</b>
          </div>
          <bsac-dd-field class="col-sm-12" [model]="article" field="colisage_cond" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;" [related]="conditionnement$" relatedLabel="Sélectionnez un conditionnement">Conditionnement</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="article" field="colisage_poids" type="auto">Poids</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="article" field="colisage_unit" type="auto">Unité</bsac-dd-field>
          <bsac-dd-field class="col-sm-4" [model]="article" field="colisage_nombre" type="auto">Nombre</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 text-info text-center mb-2">
            <hr><b>UCI</b>
          </div>
          <bsac-dd-field class="col-sm-12" [model]="article" field="uci_cond" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;" [related]="conditionnement$" relatedLabel="Sélectionnez un conditionnement">Conditionnement</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="uci_value" type="auto">Valeur</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="uci_unit" type="auto">Unité</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 text-info text-center mb-2">
            <hr><b>UDF / UDV</b>
          </div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="udf_unit" type="auto">Unité UDF</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="udv_value" type="auto">Valeur UDV</bsac-dd-field>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-info-circle me-2"></i>Infos qualité</div>
      <div class="card-body">
        <dl class="row">
          <div class="col-12 text-info text-center mb-2"><b>Egalim & labels</b></div>
          <bsac-dd-field class="col-12" [model]="article" field="egalim" type="manytomany" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionner un label / certification" [related]="egalim$">Labels & certifications</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 text-info text-center mb-2"><b>Infos qualité</b></div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="calibre" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt; [&lt;%= family_details.cfullname %&gt;]" relatedLabel="Sélectionnez un calibre" [related]="calibre$" [relatedFilter]="{article: article.id}">Calibre</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="categorie" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez une catégorie" [related]="categorie$">Catégorie</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="particularite" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez une particularité" [related]="particularite$">Particularité</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="presentation" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez une présentation " [related]="presentation$">Présentation</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 text-info text-center mb-2"><b>Infos production</b></div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="local" type="auto">Local</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="process" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez un process" [related]="process$">Process</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="traitement" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez un traitement " [related]="traitement$">Traitement</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="production" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez un mode de production " [related]="production$">Production</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <bsac-dd-field class="col-sm-6" [model]="article" field="marque" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez une marque" [related]="marque$">Marque</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="fournisseur" type="foreignkey" desctpl="[&lt;%= cvva %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez un fournisseur référencé" [related]="provider$">Fournisseur référencé</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 text-info text-center mb-2"><b>Infos géographiques</b></div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="origine" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez une origine" [related]="origine$">Origine</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="region" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez une région" [related]="region$">Région</bsac-dd-field>
        </dl>
        <dl class="row mt-3">
          <div class="col-12 text-info text-center mb-2"><b>Infos spécifiques</b></div>
          <bsac-dd-field class="col-sm-6" [model]="article" field="client" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez un client" [related]="aclient$">Client</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="usage" type="foreignkey" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionnez un usage" [related]="usage$">Usage</bsac-dd-field>
        </dl>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-knife-kitchen me-2"></i>Infos nutritionnelles</div>
      <div class="card-body">
        <dl class="row" *ngIf="article">
          <bsac-dd-field class="col-sm-6" [model]="article" field="ciqual" type="auto">ID Ciqual</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="allergenes" type="manytomany" desctpl="[&lt;%= code %&gt;] &lt;%= name %&gt;"  relatedLabel="Sélectionner les allergènes" [related]="allergene$">Allergènes</bsac-dd-field>
          <bsac-dd-field class="col-sm-6" [model]="article" field="recette" type="auto">Recette</bsac-dd-field>
        </dl>
        <div class="maxh">
          <table class="table table-sm table-borderless table-striped" *ngIf="article.ciqual_alim">
            <tbody>
              <tr *ngFor="let c of article.ciqual_alim_details?.values_details">
                <td>{{c.column_details.name}}</td>
                <td>
                  <ng-container *ngIf="c.value!=null">{{c.less?'< ': ''}} {{c.value/100000}} {{c.column_details.unit}}</ng-container>
                  <ng-container *ngIf="c.value==null">{{c.traces?'traces':''}}</ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-file-archive me-2"></i>Documents</div>
      <div class="card-body">
        <h3>Fiches techniques</h3>
        <lvadg-detaildocument-listmanage [model]="article" [documents]="article.documents_details" (deleted)="onDocumentDeleted()" (added)="onUploaded($event)" [uploadData]="article.fournisseur?{provider: article.fournisseur}:{}" type="FT" [fields]="['title','type', 'status', 'prlabel', 'articles', 'articlefamily']"></lvadg-detaildocument-listmanage>
        <h3>Autres documents</h3>
        <lvadg-detaildocument-listmanage [model]="article" [documents]="article.documents_details" (deleted)="onDocumentDeleted()" (added)="onUploaded($event)" [uploadData]="{}" type="OT" [fields]="['title','type', 'status', 'articles', 'articlefamily']"></lvadg-detaildocument-listmanage>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-image me-2"></i>Image & texte</div>
      <div class="card-body">
        <div class="row">
          <div class="col-4 text-info text-center mb-2">
            <div><b>Logo courant</b></div>
            <lvadg-rimg [src]="article.clogo_img" size="lg" display="gallery"></lvadg-rimg>
          </div>
          <div class="col-8">
            <lvadg-images-manage [model]="article"></lvadg-images-manage>
          </div>
          <div class="col-12 text-info text-center mb-2"><b>Texte complémentaire</b></div>
          <bsac-dd-field class="col-12" [model]="article" field="content_src" type="auto">Description longue</bsac-dd-field>
        </div>
      </div>
    </div>
  </div>
</div>