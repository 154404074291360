import {NgModule} from "@angular/core";
import {SeaProductCreateComponent} from "./seaproduct-create/seaproduct-create.component";
import {SeaProductDeleteComponent} from "./seaproduct-delete/seaproduct-delete.component";
import {SeaProductDisplayComponent} from "./seaproduct-display/seaproduct-display.component";
import {SeaProductListComponent} from "./seaproduct-list/seaproduct-list.component";
import {SeaProductSelectComponent} from "./seaproduct-select/seaproduct-select.component";
import {SeaProductManageComponent} from "./seaproduct-manage/seaproduct-manage.component";
import {SeaProductFamilyModule} from "../seaproductfamily/seaproductfamily.module";
import {LocationModule} from "../../structure/location/location.module";
import {StorageModule} from "../../structure/storage/storage.module";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {SeaProducerModule} from "../../structure/seaproducer/seaproducer.module";
import {ProductCommonModule} from "../common/product-common.module";
import {ImageModule} from "../../content/image/image.module";
import {SelectionModule} from "../../selection/selection.module";
import {SeaLabelModule} from "../sealabel/sealabel.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {BaseDocumentModule} from "../../document/basedocument/base-document.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    SeaProductFamilyModule,
    ProductCommonModule,
    LocationModule,
    StorageModule,
    ImageModule,
    SeaProducerModule,
    SeaLabelModule,
    CustomDataModule,
    SelectionModule,
    BaseDocumentModule
  ],
  declarations: [
    SeaProductSelectComponent,
    SeaProductDisplayComponent,
    SeaProductDeleteComponent,
    SeaProductListComponent,
    SeaProductManageComponent,
    SeaProductCreateComponent,
  ],
  exports: [
    SeaProductSelectComponent,
    SeaProductDisplayComponent,
    SeaProductDeleteComponent,
    SeaProductListComponent,
    SeaProductManageComponent,
    SeaProductCreateComponent,
  ],
})
export class SeaProductModule {
}
