import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {ProductVariantSelectComponent} from "./productvariant-select/productvariant-select.component";

@NgModule({
  imports: [CommonModule, BsacFormsModule],
  declarations: [ProductVariantSelectComponent],
  exports: [ProductVariantSelectComponent]
})
export class ProductVariantSelectModule {}
