/**
 * ProductFamily : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @changed 2018-04-19-09:57:31
 * @date 2018-04-19-09:57:36
 * @copyright (c) 2018 Jean-Matthieu BARBIER
 */

import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSTextField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";
import {Storage} from "../../structure/storage/storage.service";

export class BaseProductFamily extends DSModel {


  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id: number;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "OK, affiché"],
      ["UC", "Créé par utilisateur, affiché"],
      ["KO", "Pas OK, pas affiché"]
    ],
    default: null,
    maxLength: 4,
  })
  public status: string;

  @DSCharField({
    required: false,
    name: "content_type",
    helpText: "",
    verboseName: "Format description",
    choices: [],
    default: "md",
    maxLength: 4,
  })
  public content_type: string;

  @DSTextField({
    required: false,
    name: "content_src",
    helpText: "",
    verboseName: "Description famille",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content_src: string;

  @DSTextField({
    required: false,
    name: "content",
    helpText: "",
    verboseName: "Description famille (compilée)",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content: string;

  @DSForeignKeyField({
    required: true,
    name: "parent",
    helpText: "Famille parente",
    verboseName: "Famille parente",
    choices: [],
    default: null,
  })
  public parent: number;

  @DSCharField({
    required: true,
    name: "name",
    helpText: "",
    verboseName: "Nom variété",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public name: string;

  @DSCharField({
    required: false,
    name: "cfullname",
    helpText: "",
    verboseName: "Nom complet",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public cfullname: string;
  @DSCharField({
    required: false,
    name: "cname",
    helpText: "",
    verboseName: "Nom famille",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public cname: string;

  @DSForeignKeyField({
    required: false,
    name: "logo",
    helpText: "",
    verboseName: "ID Logo",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "photoproductfamily",
  })
  public logo: number;

  @DSForeignKeyField({
    required: false,
    name: "clogo",
    helpText: "",
    verboseName: "ID Logo (hérit.)",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "cachedphotoproductfamily",
  })
  public clogo: number;


  @DSCharField({
    required: false,
    name: "productname",
    helpText: "",
    verboseName: "Nom du produit",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public productname: string;

  @DSTextField({
    required: false,
    name: "productdesc",
    helpText: "",
    verboseName: "Description produit",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public productdesc: string;

  @DSDefaultField({
    required: false,
    name: "productseasons",
    helpText: "",
    verboseName: "Saisonnalité produit",
    choices: [],
    default: [],
  })
  public productseasons: any;

  @DSTextField({
    required: false,
    name: "cproductdesc",
    helpText: "",
    verboseName: "Description produit (hérit.)",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public cproductdesc: string;

  @DSDefaultField({
    required: false,
    name: "cproductseasons",
    helpText: "",
    verboseName: "Saisonnalité produit (hérit.)",
    choices: [],
    default: [],
  })
  public cproductseasons: any;


  @DSDefaultField({
    required: false,
    name: "clogo_img",
  })
  public clogo_img: Rimg;

  @DSDefaultField({
    required: false,
    name: "logo_img",
  })
  public logo_img: Rimg;

  @DSDefaultField({
    required: false,
    name: "flags",
    helpText: "",
    verboseName: "Flags",
    choices: [],
    default: [],
  })
  public flags: any;


  @DSBooleanField({
    required: false,
    name: "dispname",
    helpText: "Affichage de ce nom ?",
    verboseName: "Affichage du nom ?",
    default: true
  })
  public dispname: boolean;

  @DSDefaultField({
    required: false,
    name: "level",
    helpText: "",
    verboseName: "Niveau arborescence",
    choices: [],
    default: null,
  })
  public level: any;

  @DSDefaultField({
    required: false,
    name: "parents",
    helpText: "Liste parents",
    verboseName: "Liste parents",
    choices: [],
    default: [],
  })
  public parents: { name: string; id: number; cfullname?: string; }[];

  @DSDefaultField({
    required: false,
    name: "pcount",
    helpText: "Produits dans la famille (directe)",
    verboseName: "# Produits",
    choices: [],
    default: 0,
  })
  public pcount: number;

  @DSDefaultField({
    required: false,
    name: "pccount",
    helpText: "Produits dans la famille (enfants)",
    verboseName: "# Produits (enfants)",
    choices: [],
    default: 0,
  })
  public pccount: number;

  @DSDefaultField({
    required: false,
    name: "pseasons",
    helpText: "Stats saisonnalité produits (famille)",
    verboseName: "Stats saisonnalité produits (famille)",
    choices: [],
    default: []
  })
  public pseasons: number[];

  @DSDefaultField({
    required: false,
    name: "pcseasons",
    helpText: "Stats saisonnalité produits (enfants)",
    verboseName: "Stats saisonnalité produits (enfants)",
    choices: [],
    default: []
  })
  public pcseasons: number[];


  @DSManyToManyField({
    required: false,
    name: "images",
    helpText: "",
    verboseName: "ID images",
    choices: [],
    default: [],
  })
  public images: number[];

  @DSDefaultField({
    required: false,
    name: "storages_details",
    helpText: "Dépôts",
    verboseName: "Dépôts",
  })
  public storages_details: Storage[];


  public lft: any;
  public rght: any;
  public tree_id: any;

}
