import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSEmailField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";


export class Contact extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "owner",
    verboseName: "ID Propriétaire",
    required: false,
    helpText: "Propriétaire",
    relatedModel: "people.Person",
    remoteField: "id",
    relatedName: "contacts"
  })
  public owner!: number;
  public owner_details!: { id: number; lastname: string; firstname: string; email: string };

  @DSForeignKeyField({
    name: "user",
    verboseName: "ID Utilisateur lavieadugout",
    required: false,
    helpText: "Utilisateur lavieadugout",
    relatedModel: "people.Person",
    remoteField: "id",
    relatedName: "incontacts"
  })
  public user!: number;
  public user_details!: { id: number; lastname: string; firstname: string; email: string };


  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type de contact",
    minLength: 0,
    maxLength: 2,
    default: "OC",
    choices: [
      ["OC", "Contact offre locale"],
      ["TR", "Contact négociation tarif"]
    ],
  })
  public type!: "TR" | "OC";


  @DSCharField({
    name: "firstname",
    verboseName: "Prénom",
    required: false,
    helpText: "Prénom",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public firstname!: string;

  @DSCharField({
    name: "lastname",
    verboseName: "Nom de famille",
    required: false,
    helpText: "Nom de famille",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public lastname!: string;

  @DSCharField({
    name: "title",
    verboseName: "Titre",
    required: false,
    helpText: "Titre",
    minLength: 0,
    maxLength: 10,
    choices: [
      ["X", "Non précisé"],
      ["M.", "Monsieur"],
      ["Mme.", "Madame"],
    ],
    default: "X"
  })
  public title!: string;

  @DSCharField({
    name: "jobname",
    verboseName: "Nom du métier",
    required: false,
    helpText: "Nom du métier",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public jobname!: string;

  @DSEmailField({
    name: "email",
    verboseName: "Email",
    required: true,
    helpText: "Email",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public email!: string;

  @DSCharField({
    name: "phone",
    verboseName: "Téléphone",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 40,
    choices: []
  })
  public phone!: string;

  @DSCharField({
    name: "mobile",
    verboseName: "Tel portable",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 40,
    choices: []
  })
  public mobile!: string;

  @DSForeignKeyField({
    name: "resto",
    verboseName: "Restaurant",
    required: false,
    helpText: "Restaurant",
    relatedModel: "structure.Resto",
    remoteField: "id",
    relatedName: "contacts"
  })
  public resto!: number;
  public resto_details!: { id: number; name: string };

  @DSForeignKeyField({
    name: "member",
    verboseName: "Adhérent",
    required: false,
    helpText: "Adhérent",
    relatedModel: "structure.Member",
    remoteField: "id",
    relatedName: "contacts"
  })
  public member!: number;
  public member_details!: { id: number; name: string };

  @DSForeignKeyField({
    name: "storage",
    verboseName: "Dépôt",
    required: false,
    helpText: "Dépôt",
    relatedModel: "structure.Storage",
    remoteField: "id",
    relatedName: "contacts"
  })
  public storage!: number;
  public storage_details!: { id: number; name: string };

  @DSForeignKeyField({
    name: "client",
    verboseName: "Client",
    required: false,
    helpText: "Client",
    relatedModel: "structure.Client",
    remoteField: "id",
    relatedName: "contacts"
  })
  public client!: number;
  public client_details!: { id: number; name: string };

  @DSForeignKeyField({
    name: "tarif",
    verboseName: "Tarif",
    required: false,
    helpText: "Tarif",
    relatedModel: "tarif.TarifType",
    remoteField: "id",
    relatedName: "contacts"
  })
  public tarif!: number;
  public tarif_details!: { id: number; code: string, name: string; type: string };

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "Activé"],
      ["NOK", "Désactivé"],
      ["ARV", "A revoir"]
    ],
    maxLength: 10
  })
  public status!: string;


  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  @DSDefaultField({
    name: "sd_ok_count",
    verboseName: "Nb envois réussis",
    required: false,
    helpText: "Nombre d'envois réussis",
  })
  public sd_ok_count!: number;

  @DSDefaultField({
    name: "sd_nok_count",
    verboseName: "Nb envois en échec",
    required: false,
    helpText: "Nombre d'envois en échec",
  })
  public sd_nok_count!: number;
}

@Injectable({
  providedIn: "root"
})
export class ContactService extends DSRestCollection<Contact> {
  public adapter_config = {basePath: "/api/v2/contacts"};
  public model = Contact;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
