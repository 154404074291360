import {NgModule} from "@angular/core";
import {MODELLIST_IMPORTS} from "../../../../../includes/components/modellist.imports";
import {ModelcommPreviewComponent} from "./modelcomm-preview/modelcomm-preview.component";

@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
  ],
  declarations: [ModelcommPreviewComponent],
  exports: [ModelcommPreviewComponent],

})
export class ModelCommModule {
}
