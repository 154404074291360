// @protect
import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";
import {LEGALTYPES} from "../../../comps/constants";
import {DetailDocument} from "../../document/basedocument/basedocument.service";
import {Location} from "../location/location.service";

export class Storage extends DSModel {

  @DSAutoField({
    verboseName: "ID",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "id",
  })
  public id!: number;

  @DSCharField({
    maxLength: 60,
    verboseName: "Nom du dépôt",
    choices: [],
    default: null,
    required: true,
    helpText: "",
    name: "name",
  })
  public name!: string;

  @DSCharField({
    maxLength: 50,
    verboseName: "Code vivalya",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "cvva",
  })
  public cvva!: string;

  @DSCharField({
    maxLength: 4,
    verboseName: "Type de dépôt",
    choices: [
      ["FL", "Fruits / légumes"],
      ["MR", "Marée"],
      ["FLMR", "FL & Marée"]
    ],
    default: null,
    required: false,
    helpText: "",
    name: "type",
  })
  public type!: string;

  @DSCharField({
    required: false,
    name: "content_type",
    helpText: "",
    verboseName: "content type",
    choices: [],
    default: "md",
    maxLength: 4,
  })
  public content_type!: string;

  @DSTextField({
    required: false,
    name: "content_src",
    helpText: "",
    verboseName: "Description",
    choices: [],
    default: "",
    maxLength: 4000,
    minLength: 0
  })
  public content_src!: string;

  @DSTextField({
    required: false,
    name: "content",
    helpText: "",
    verboseName: "content",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content!: string;

  @DSTextField({
    maxLength: 400,
    verboseName: "Adresse",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "mainaddress",
  })
  public mainaddress!: string;

  @DSCharField({
    maxLength: 20,
    verboseName: "Téléphone",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "phone",
  })
  public phone!: string;

  @DSCharField({
    maxLength: 20,
    verboseName: "Fax",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "fax",
  })
  public fax!: string;

  @DSCharField({
    maxLength: 400,
    verboseName: "Site internet",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "website",
  })
  public website!: string;

  @DSCharField({
    maxLength: 20,
    verboseName: "SIREN",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "siren",
  })
  public siren!: string;

  @DSCharField({
    maxLength: 20,
    verboseName: "SIRET",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "siret",
  })
  public siret!: string;

  @DSCharField({
    maxLength: 20,
    verboseName: "N° TVA",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "tvaic",
  })
  public tvaic!: string;

  @DSCharField({
    maxLength: 20,
    verboseName: "Code NAF",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "naf",
  })
  public naf!: string;

  @DSCharField({
    maxLength: 50,
    verboseName: "Structure légale",
    choices: LEGALTYPES,
    default: null,
    required: false,
    helpText: "",
    name: "legal",
  })
  public legal!: string;

  @DSCharField({
    maxLength: 20,
    verboseName: "Capital",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "capital",
  })
  public capital!: string;

  @DSForeignKeyField({
    verboseName: "ID Logo",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    autoCreated: false,
    remoteField: "logostorage",
    relatedModel: "Image",
    name: "logo",
  })
  public logo!: number;
  public logo_img!: Rimg;

  @DSDateTimeField({
    verboseName: "Date de création",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "created",
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    verboseName: "Mise à jour",
    choices: [],
    default: null,
    required: false,
    helpText: "",
    name: "updated",
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    maxLength: 4,
    verboseName: "Etat",
    choices: [["OK", "Valide"], ["NEW", "Valide - nouveau"], ["KO", "Non valide"]],
    default: null,
    required: false,
    helpText: "",
    name: "status",
  })
  public status!: string;

  @DSManyToManyField({
    name: "slabels",
    verboseName: "Labels structure",
    required: false
  })
  public slabels!: number[];

  @DSManyToManyField({
    verboseName: "IDS Images",
    choices: [],
    default: [],
    required: false,
    helpText: "",
    name: "images",
  })
  public images!: number[];
  public images_details: Rimg[] = [];

  @DSForeignKeyField({
    name: "member",
    verboseName: "Adhérent",
    required: false,
    helpText: "Adhérent",
    relatedModel: "structure.Member",
    remoteField: "id",
    relatedName: "storages"
  })
  public member!: number;
  public member_details!: any;

  @DSDefaultField({
    required: false,
    verboseName: "# producteurs",
    name: "producers_count"
  })
  public producers_count!: number;
  @DSDefaultField({
    required: false,
    verboseName: "# produits",
    name: "products_count"
  })
  public products_count!: number;

  @DSManyToManyField({
    name: "locations",
    verboseName: "Emplacements",
    required: false,
    relatedModel: "structure.Locations",
    remoteField: "storages",
    helpText: "",
    default: [],
  })
  public locations!: number[];
  public locations_details!: Location[];

  @DSDefaultField({
    name: "iourl",
    verboseName: "lvadg.io",
    required: false,
    helpText: "lvadg.io url",
    choices: []
  })
  public iourl!: {code: string; url: string};

  @DSCharField({
    name: "qr",
    verboseName: "QR",
    required: false,
    helpText: "QR code",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public qr!: string;

  @DSManyToManyField({
    name: "documents",
    verboseName: "Documents fournisseur",
    required: false,
    relatedModel: "document.DetailDocument",
    remoteField: "articleproviderdocuments",
    helpText: "Documents fournisseur",
    default: [],
  })
  public documents!: number[];
  public documents_details!: DetailDocument[];


  public mainlocation_details!: Location;
  public current_distance!: number;
}

@Injectable({
  providedIn: "root"
})
export class StorageService extends DSRestCollection<Storage> {
  public adapter_config = {basePath: "/api/v2/structure/storages"};
  public model = Storage;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
