import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UpdateComponent} from "./update/update.component";

@NgModule({
  declarations: [UpdateComponent],
  exports: [UpdateComponent],
  imports: [
    CommonModule
  ]
})
export class UpdateModule {
}
