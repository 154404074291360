import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService} from "../../lib/comps/routes.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {VIEW_DISPLAY, VIEW_MODE} from "../view-constants";

@Component({
  template: ""
})
export class CommonBaseComponent implements OnInit, OnDestroy {
  @Input() public display!: VIEW_DISPLAY;
  @Input() public mode!: VIEW_MODE;
  @Input() public currentRoute!: RouteConfigItemV3;
  public ready: boolean = false;
  protected _subscriptions$ = new Subject<void>();

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {

  }


  public async ngOnInit(): Promise<void> {
    await this.preNgOnInit();
    const data = this.route.snapshot.data as BaseRouteV3Params;
    if (!this.display) {
      // If display have already been set in preNgOnInit or with Input,
      // skip retrieval from route snapshot. Default : "view"
      this.display = data.display || "view";
    }
    if (!this.mode) {
      // If mode have already been set in preNgOnInit or with Input,
      // skip retrieval from route snapshot. Default : "user"
      this.mode = data.mode || "user";
    }
    if (!this.currentRoute) {
      this.currentRoute = data.route;
    }

    await this.postNgOnInit();
    this.ready = true;
  }

  public ngOnDestroy(): void {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  protected async preNgOnInit() {

  }

  protected async postNgOnInit() {

  }
}
