import {Component, Input, OnInit} from "@angular/core";
import {ExternalArticle, ExternalArticleService} from "../externalarticle.service";
import {Observable} from "rxjs";
import {ClientService} from "../../../structure/client/client.service";
import {StorageService} from "../../../structure/storage/storage.service";
import {MemberService} from "../../../structure/member/member.service";
import {ProviderService} from "../../../structure/provider/provider.service";
import {Article, ArticleService} from "../../article/article.service";
import {ProductVariantService} from "../../../product/productvariant/productvariant.service";
import {SeaProductVariantService} from "../../../product/seaproductvariant/seaproductvariant.service";
import {TarifTypeService} from "../../../tarif/tariftype/tariftype.service";
import {RouteV3} from "../../../../comps/routes.service";
import {ExternalArticleCollectionService} from "../../externalarticlecollection/externalarticlecollection.service";

export interface IFlagItem {
  flag: string;
  count: number;
}
@Component({
  selector: "lvadg-externalarticle-manage",
  templateUrl: "./externalarticle-manage.component.pug",
  styleUrls: ["./externalarticle-manage.component.sass"]
})
export class ExternalarticleManageComponent implements OnInit {
  @Input() public ea!: ExternalArticle;
  @Input() public article_detail_route!: RouteV3<Article>;
  public flags$!: Observable<IFlagItem[]>;

  constructor(private _eas: ExternalArticleService,
              public clients$: ClientService,
              public storages$: StorageService,
              public members$: MemberService,
              public tariftypes$: TarifTypeService,
              public providers$: ProviderService,
              public articles$: ArticleService,
              public eacolls$: ExternalArticleCollectionService,
              public flvariants$: ProductVariantService,
              public seavariants$: SeaProductVariantService) {
  }

  public get origData(): [string, any][] {
    const out: [string, any][] = [];
    if (this.ea && this.ea.orig_data) {
      for (const k of Object.keys(this.ea.orig_data)) {
        if (this.ea.orig_data[k] !== "" || this.ea.orig_data[k] !== null) {
          out.push([k, this.ea.orig_data[k]]);
        }
      }
    }
    return out;
  }

  ngOnInit(): void {
    this.flags$ = this._eas.action(null, "get_flags", {
      method: "GET"
    });
  }

}
