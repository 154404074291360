import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {FolderTree, FolderTreeService} from "../folder-tree.service";
import {Folder, FolderService} from "../folder.service";
import {Document, DocumentService} from "../../document/document.service";
import {combineLatest, Subject} from "rxjs";
import {switchMap, takeUntil, tap} from "rxjs/operators";

@Component({
  selector: "lvadg-folder-detail",
  templateUrl: "./folder-detail.component.pug",
  styleUrls: ["./folder-detail.component.sass"]
})
export class FolderDetailComponent implements OnInit, OnDestroy {
  @Input() public treeName = "default";
  @Input() public mode = "admin";
  @Input() public tree!: FolderTree;

  public current!: Folder;
  public subfolders!: Folder[];
  public documents!: any[];
  public selected!: { type: string, item: Folder | Document };
  private _subscription = new Subject<void>();

  constructor(private _trees: FolderTreeService,
              private _folders: FolderService,
              private _documents: DocumentService) {
  }

  public ngOnDestroy(): void {
    this._subscription.next();
    this._subscription.complete();
  }

  public ngOnInit(): void {
    if (!this.tree) {
      this.tree = this._trees.load(this.treeName);
    }
    this.tree.current$.pipe(
      takeUntil(this._subscription),
      tap((current) => {
        this.current = current;
      }),
      switchMap((current) => {
        return combineLatest(
          this.tree.getSubFolders(current),
          this.tree.getDocuments(current)
        );
      }),
      takeUntil(this._subscription)
    ).subscribe((values) => {
      this.subfolders = values[0];
      this.documents = values[1];
    });
  }

  public getUploadUrl(): string {
    if (!this.current) {
      return "";
    }
    return (this._folders.backend as any).getRequestUrl(
      (this._folders as any).adapter.detail_action(this.current, "add_document")
    );
  }

  public select(type: string, item: Folder|Document): void {
    this.selected = {type: type, item: item};
  }

  public onUploaded(event: any): void {
    this.tree.reload();
  }

  public getContentContainerClasses(): string {
    if (this.selected) {
      return "col-8";
    } else {
      return "col-12";
    }
  }

  public getSideContainerClasses(): string {
    if (this.selected) {
      return "col-4";
    } else {
      return "d-none";
    }
  }

  public propsClosed(): void {
    this.tree.display.properties = false;
    this.tree.reload();
  }

  public checkMode(...args: string[]): boolean {
    return args.indexOf(this.mode) !== -1;
  }
}
