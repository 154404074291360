// @changed 2020-06-04-19:07:56
import {NgModule} from "@angular/core";
import {ProducerCreateComponent} from "./producer-create/producer-create.component";
import {ProducerDeleteComponent} from "./producer-delete/producer-delete.component";
import {ProducerDisplayComponent} from "./producer-display/producer-display.component";
import {ProducerListComponent} from "./producer-list/producer-list.component";
import {ProducerManageComponent} from "./producer-manage/producer-manage.component";
import {LocationModule} from "../location/location.module";
import {StorageModule} from "../storage/storage.module";
import {ImageModule} from "../../content/image/image.module";
import {NgStringPipesModule} from "ngx-pipes";
import {SLabelModule} from "../slabel/slabel.module";
import {LabelModule} from "../../product/label/label.module";
import {SelectionModule} from "../../selection/selection.module";
import {VideoModule} from "../../content/video/video.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {ProducerSelectModule} from "./producer-select.module";
import {BaseDocumentModule} from "../../document/basedocument/base-document.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    CustomDataModule,
    ImageModule,
    LocationModule,
    StorageModule,
    SelectionModule,
    SLabelModule,
    LabelModule,
    NgStringPipesModule,
    VideoModule,
    ProducerSelectModule,
    BaseDocumentModule
  ],
  providers: [],
  declarations: [
    ProducerDisplayComponent,
    ProducerDeleteComponent,
    ProducerListComponent,
    ProducerManageComponent,
    ProducerCreateComponent
  ],
  exports: [
    ProducerDisplayComponent,
    ProducerDeleteComponent,
    ProducerListComponent,
    ProducerManageComponent,
    ProducerCreateComponent,
    ProducerSelectModule
  ],
})
export class ProducerModule {
}
