import {Directive, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {DSRestCollection, DSRestQueryset} from "@solidev/ngdataservice";
import {Observable} from "rxjs";
import {Member} from "../../member/member.service";
import {Storage} from "../../storage/storage.service";
import {BaseProducer} from "../producer-base.model";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseProducerSelectComponent<P extends BaseProducer, PS extends DSRestCollection<P>> implements OnChanges {
  public queryset!: DSRestQueryset<P>;
  @Input() public hint = "Selectionnez un producteur";
  @Input() public reset!: Observable<any>;
  @Input() public label = "Sélectionnez un producteur";
  @Input() public desctpl!: string;
  @Input() public descfield = "name";
  @Input() public filter: any = {};
  @Input() public searchparam = "search";

  @Input() public member!: Member;
  @Input() public storage!: Storage;

  @Output() public selected: EventEmitter<P> = new EventEmitter<P>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(public _service: PS) {
  }

  public ngOnChanges(): void {
    if (this.member) {
      this.filter.member = this.member.id;
    }
    if (this.storage) {
      this.filter.storage = this.storage.id;
    }
  }

  public selItem(model: any): void {
    this.selected.emit(model);
  }

}
