import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from "@angular/core";
import * as maplibregl from "maplibre-gl";
import {SymbolLayerSpecification} from "maplibre-gl";
import {MeteoFull} from "../meteo-full";
import {MeteoService} from "../meteo.service";
import {first} from "rxjs/operators";
import {combineLatest, fromEvent} from "rxjs";

const MAP_PARAMS: { [index: string]: { center: [number, number], zoom: number } } = {
  france: {
    center: [2.6390772508210603, 46.441826066304316],
    zoom: 4.1
  },
  europe: {
    center: [2.6390772508210603, 48.441826066304316],
    zoom: 2.8
  }
};


@Component({
  selector: "lvadg-meteo-fullmap",
  templateUrl: "./meteo-fullmap.component.pug",
  styleUrls: ["./meteo-fullmap.component.sass"]
})
export class MeteoFullmapComponent implements OnInit, OnDestroy {
  @ViewChild("map", {static: true}) public mapEl!: ElementRef;
  @Input() public geo: "france" | "europe" = "france";
  @Input() public day = 0;
  public curday!: { id: string; date: Date };
  public meteo!: MeteoFull;
  public days: { id: string; date: Date }[] = [];
  private map: maplibregl.Map;

  constructor(private _zone: NgZone,
              public mt: MeteoService) {
  }

  async ngOnInit(): Promise<void> {
    this.map = new maplibregl.Map({
      container: this.mapEl.nativeElement,
      style: "https://maptiles.gis.lavieadugout.fr/styles/toner_eu_vivalya_light/style.json",
      center: MAP_PARAMS[this.geo].center,
      zoom: MAP_PARAMS[this.geo].zoom,
      antialias: false,
      interactive: true,
      minZoom: 1, // was 4.4
      maxZoom: 6,
      attributionControl: false,
      preserveDrawingBuffer: false
    });
    combineLatest([fromEvent(this.map, "load"), this.mt.full])
      .pipe(first())
      .subscribe(([cmap, meteo]) => {
        this.meteo = meteo;
        if (!this.meteo || !this.meteo.features) {
          return
        }
        this.map.addSource("meteo", {type: "geojson", data: this.meteo as any});
        for (let d = 0; d <= 15; d++) {
          if (d === 0) {
            this.curday = {id: `day-${d}`, date: this.meteo.features[0].properties.daily_forecast[d].time};
          }
          this.days.push({id: `day-${d}`, date: this.meteo.features[0].properties.daily_forecast[d].time});
          this.map.addLayer({
            id: `day-${d}`,
            source: "meteo",
            type: "symbol",
            layout: <SymbolLayerSpecification["layout"]>{
              "icon-image": [
                "get",
                "daily_weather_icon",
                ["at", d, ["get", "daily_forecast"]]
              ],
              "icon-padding": 0,
              "icon-anchor": "center",
              "icon-ignore-placement": true,
              "icon-allow-overlap": true,
              "text-ignore-placement": false,
              "text-allow-overlap": false,
              "icon-size": ["interpolate", ["linear"], ["zoom"], 0.5, 0.3, 3.2, 1.3],
              "text-field": [
                "concat",
                ["get", "T_min", ["at", d, ["get", "daily_forecast"]]],
                "°C - ",
                ["get", "T_max", ["at", d, ["get", "daily_forecast"]]],
                "°C\n",
                ["case",
                  ["<=", 5, ["get", "total_precipitation_24h", ["at", d, ["get", "daily_forecast"]]]],
                  ["concat",
                    "pluie : ",
                    ["to-string", ["ceil", ["get", "total_precipitation_24h", ["at", d, ["get", "daily_forecast"]]]]],
                    " mm\n"],
                  ""
                ],
                ["get", "name"]
              ],
              "text-anchor": "top",
              "text-offset": [0, 1],
              "text-optional": true,
              "text-size": ["interpolate", ["linear"], ["zoom"], 0.5, 0, 1, 0, 1.5, 6, 4, 8],
              "visibility": d === 0 ? "visible" : "none"
            }
          });
        }

      });
  }


  public ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }

  public setDay(day: any) {
    this.curday = day;
    for (let d = 0; d <= 15; d++) {
      this.map.setLayoutProperty(`day-${d}`, "visibility", `day-${d}` === day.id ? "visible" : "none");
    }
  }

}
