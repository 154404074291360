/**
 * GeoDepartement : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>

 * @date 2018-01-04-17:34:18
 * @copyright (c) 2017 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export class GeoDepartement extends DSModel {


  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;


  @DSManyToManyField({
    required: false,
    name: "regions",
    helpText: "Régions associées",
    verboseName: "Régions",
    choices: {},
    default: null,
  })
  public regions!: number[];
  public regions_details!: { id: number; code: number; name: string }[];

  @DSCharField({
    required: true,
    name: "name",
    helpText: "",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 200,
  })
  public name!: string;

  @DSCharField({
    required: true,
    name: "code",
    helpText: "",
    verboseName: "Code",
    choices: [],
    default: null,
    maxLength: 5,
  })
  public code!: string;

  @DSForeignKeyField({
    name: "country",
    verboseName: "Pays",
    required: false,
    helpText: "Pays",
    relatedModel: "customdata.Country",
    remoteField: "id",
    relatedName: "departement"
  })
  public country!: number;
  public country_details!: { id: number; code: string; name: string };


  public get fullname(): string {
    return `${this.name} (${this.code})`;
  }

}

@Injectable({providedIn: "root"})
export class GeoDepartementService extends DSRestCollection<GeoDepartement> {
  public adapter_config = {basePath: "/api/v2/geodepartements"};
  public model = GeoDepartement;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
