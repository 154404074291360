import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {TarifNotification} from "../tarifnotification/tarifnotification.service";
import {Person} from "../../people/person/person.service";

export enum TarifNotificationStatuses {
  PENDING = "PEN",
  TOSEND = "SND",
  SEND_PREPARE = "SNP",
  SEND_QUEUE = "SNQ",
  SENDING = "SNI",
  SENT = "SNT",
  OPENED = "OPN",
  CLICKED = "CLK",
  SKIP = "SKP",
  TEMPORARY_ERROR = "ATT",
  FATAL_ERROR = "FAT",
  INVALID = "NOK"
}

export class TarifNotificationStatus extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "notification",
    verboseName: "Notification",
    required: false,
    helpText: "Notification",
    relatedModel: "tarifs.TarifNotification",
    remoteField: "id",
    relatedName: "statuses"
  })
  public notification!: number;
  public notification_details!: TarifNotification;


  @DSForeignKeyField({
    name: "user",
    verboseName: "Utilisateur",
    required: false,
    helpText: "Utilisateur",
    relatedModel: "people.Person",
    remoteField: "id",
    relatedName: "tarifnotifications"
  })
  public user!: number;
  public user_details!: Person;


  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "PEN",
    choices: [
      [TarifNotificationStatuses.PENDING, "En attente d'envoi"],
      [TarifNotificationStatuses.TOSEND, "A envoyer"],
      [TarifNotificationStatuses.SEND_PREPARE, "Préparation envoi"],
      [TarifNotificationStatuses.SEND_QUEUE, "Dans la file d'envoi"],
      [TarifNotificationStatuses.SENDING, "En cours d'envoi"],
      [TarifNotificationStatuses.SENT, "Envoyé"],
      [TarifNotificationStatuses.OPENED, "Ouvert"],
      [TarifNotificationStatuses.CLICKED, "Cliqué"],
      [TarifNotificationStatuses.SKIP, "Ne pas envoyer"],
      [TarifNotificationStatuses.TEMPORARY_ERROR, "Erreur temporaire, en attente nouvel essai"],
      [TarifNotificationStatuses.FATAL_ERROR, "Erreur fatale, échec envoi"],
      [TarifNotificationStatuses.INVALID, "Invalide"],
    ],
    maxLength: 3
  })
  public status!: TarifNotificationStatuses;

  @DSCharField({
    name: "sref",
    verboseName: "Ref mailer",
    required: false,
    helpText: "Ref mailer",
  })
  public sref!: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

}

@Injectable({
  providedIn: "root"
})
export class TarifNotificationStatusService extends DSRestCollection<TarifNotificationStatus> {
  public adapter_config = {basePath: "/api/v2/tarifs/tarifnotificationstatuses"};
  public model = TarifNotificationStatus;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
