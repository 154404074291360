
<ng-container *ngIf="display=='view'">
  <bsac-breadcrumb></bsac-breadcrumb>
  <h1>Catalogue externe<small class="mx-2">{{externalArticle.libelle}}</small></h1>
</ng-container>
<nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
  <ng-container ngbNavItem="manage"><a ngbNavLink>Edition article</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-externalarticle-manage [ea]="externalArticle" [article_detail_route]="article_detail_route"></lvadg-externalarticle-manage>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="vivalyabillitems" *ngIf="externalArticle.type == 'STO'"><a ngbNavLink>Lignes de facturation (art. adh)</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-billitem-list *ngIf="ready" [name]="currentRoute.name+'.billitems-adh'" [adhExternalArticle]="externalArticle" [bill_detail_route]="bill_detail_route" [billitem_detail_route]="billitem_detail_route" [storage_detail_route]="storage_detail_route" [client_detail_route]="client_detail_route" [resto_detail_route]="resto_detail_route" [article_detail_route]="article_detail_route" [externalarticle_detail_route]="externalarticle_detail_route" [articlefamily_detail_route]="articlefamily_detail_route"></lvadg-billitem-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="vivalyabillitems" *ngIf="externalArticle.type == 'ADG'"><a ngbNavLink>Lignes de facturation (art. collection)</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-billitem-list *ngIf="ready" [name]="currentRoute.name+'.billitems-col'" [colExternalArticle]="externalArticle" [bill_detail_route]="bill_detail_route" [billitem_detail_route]="billitem_detail_route" [storage_detail_route]="storage_detail_route" [client_detail_route]="client_detail_route" [resto_detail_route]="resto_detail_route" [article_detail_route]="article_detail_route" [externalarticle_detail_route]="externalarticle_detail_route" [articlefamily_detail_route]="articlefamily_detail_route"></lvadg-billitem-list>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="vivalyabillitems" *ngIf="['CLI', 'CLB'].indexOf(externalArticle.type) !== -1"><a ngbNavLink>Lignes de facturation (art. client)</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-billitem-list *ngIf="ready" [name]="currentRoute.name+'.billitems-cli'" [gcExternalArticle]="externalArticle" [bill_detail_route]="bill_detail_route" [billitem_detail_route]="billitem_detail_route" [storage_detail_route]="storage_detail_route" [client_detail_route]="client_detail_route" [resto_detail_route]="resto_detail_route" [article_detail_route]="article_detail_route" [externalarticle_detail_route]="externalarticle_detail_route" [articlefamily_detail_route]="articlefamily_detail_route"></lvadg-billitem-list>
      </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav"></div>