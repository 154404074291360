
<div class="row">
  <div class="col-sm-12">
    <label>Destinataires<small class="ms-2">cliquer pour activer/désactiver un destinataire</small></label>
    <div class="mt-1">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Email</th>
            <th>Envoi ?</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let c of contacts">
            <tr [class.table-secondary]="!c.enabled" [class.table-success]="c.enabled" role="button" (click)="c.enabled=!c.enabled">
              <td>{{c.lastname}}</td>
              <td>{{c.firstname}}</td>
              <td>{{c.email}}</td>
              <td>{{c.enabled?"oui":"non"}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="mt-1">
      <button class="btn btn-outline-warning btn-small p-2 pointer" (click)="addContact=!addContact" role="button">Ajouter un destinataire temporaire</button><span class="ms-2 text-info">Pour ajouter de manière permanente un destinataire, il doit être ajouté via le modèle de tarif</span>
    </div>
    <form class="mt-3 bg-secondary" *ngIf="addContact" [formGroup]="contactForm">
      <div class="row m-3">
        <div class="col-6 p-1">
          <div class="form-group">
            <label>Nom de famille</label>
            <input class="form-control" formControlName="lastname">
          </div>
        </div>
        <div class="col-6 p-1">
          <div class="form-group">
            <label>Prénom</label>
            <input class="form-control" formControlName="firstname">
          </div>
        </div>
        <div class="col-6 p-1">
          <div class="form-group">
            <label>Email</label>
            <input class="form-control" formControlName="email">
          </div>
        </div>
        <div class="col-6 p-1">
          <button class="btn btn-primary h-100 btn-block w-100 w-100" (click)="createContact()"><i class="fa fa-plus me-2"></i>Ajouter le<br/>destinataire</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="row mt-1">
  <div class="col-12 col-lg-4">
    <button class="btn btn-sm btn-outline-primary w-100 h-100" type="button" (click)="getDiff()" data-cy="tarifnotification-list-modifs-button">Lister les modifications du tarif</button>
  </div>
  <div class="col-12 col-lg-4">
    <button class="btn btn-sm btn-outline-primary w-100 h-100" type="button" [class.btn-outline-secondary]="template==''" [disabled]="template==''" (click)="useTemplate()" data-cy="tarifnotification-use-template">Utiliser mon modèle</button>
  </div>
  <div class="col-12 col-lg-4">
    <button class="btn btn-sm btn-outline-primary w-100 h-100" type="button" (click)="saveTemplate()" data-cy="tarifnotification-save-template">Enregistrer comme modèle</button>
  </div>
</div>
<form class="mt-2" [formGroup]="contentForm">
  <div class="form-group">
    <label>Titre du message</label>
    <input class="form-control" formControlName="title">
  </div>
  <div class="form-group">
    <label>Contenu du message</label>
    <quill-editor class="d-block" formControlName="message" [modules]="quillModules"></quill-editor>
  </div>
</form>
<div class="row mt-3">
  <div class="col-sm-12">
    <button class="btn btn-primary w-100 p-3" (click)="sendMessages()" *ngIf="!sending"><i class="fa fa-mailbox me-2"></i>Envoyer les messages</button>
    <ng-container *ngIf="sending">
      <p class="text-warning">L'envoi des messages peut prendre quelques dizaines de secondes selon le nombre de destinataires et la taille du tarif. Merci de patienter jusqu'au message de succès.</p>
      <button class="btn btn-primary w-100 p-3" [disabled]="true"><i class="fa fa-spinner fa-spin me-2"></i>Envoi en cours, merci de patienter</button>
    </ng-container>
  </div>
</div>