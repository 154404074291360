import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {CommonBaseComponent} from "../../common-base.component";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {AuthService} from "../../../../lib/models/user/auth.service";
import {NavDriver, TabMemoryService} from "../../../../lib/comps/tab-memory.service";
import {ArticleTarif} from "../../../../lib/models/tarif/articletarif/articletarif.service";
import {Tarif} from "../../../../lib/models/tarif/tarif/tarif.service";
import {LifeCycles, TarifType} from "../../../../lib/models/tarif/tariftype/tariftype.service";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";


export interface TarifManageViewV3Params extends BaseRouteV3Params {
  tariftype_detail_route: RouteConfigItemV3;
  tarif_detail_route?: RouteConfigItemV3;
  article_detail_route: RouteConfigItemV3;
  ttype: "gen" | "cli";
}

export interface TarifManageViewV3Data extends TarifManageViewV3Params {
  tarif: Tarif;
  tarifType?: TarifType;
}


@Component({
  selector: "lvadg-tarif-manage-v3-view",
  templateUrl: "./tarif-manage-view.component.pug",
  styleUrls: ["./tarif-manage-view.component.sass"]
})
export class TarifManageViewV3Component extends CommonBaseComponent {

  public current$ = new ReplaySubject<ArticleTarif>(1);
  public stats = false;
  public t!: Tarif;
  public ttype!: string;
  public tarifType?: TarifType;
  public navId!: NavDriver;
  public tariftype_detail_route!: RouteV3<TarifType>;
  public article_detail_route!: RouteV3<Article>;
  public tarif_detail_route!: RouteV3<Tarif>;
  public LifeCycles = LifeCycles;
  public tarifnotifications_default_fields: string[] = ["notif_type", "notif_status", "notif_priority",
    "notif_tarif", "notif_status",  "user", "status", "title", "updated"];
  public tarifnotifications_default_filters: string[] = ["search",  "notiftypes", "statuses"];

  constructor(routes: RoutesService,
              router: Router,
              route: ActivatedRoute,
              private san: DomSanitizer,
              public auth: AuthService,
              private tms: TabMemoryService,
              private msgs: BsacMessageService) {
    super(routes, router, route);
  }

  public get htmlMessage(): SafeHtml {
    return this.san.bypassSecurityTrustHtml(this.t.message || "");
  }

  public async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as TarifManageViewV3Data;
    this.t = data.tarif;
    this.ttype = data.ttype;
    this.tarifType = data.tarifType;
    this.navId = this.tms.memFor(["ttypeview", this.t.id], this.t.type_details.lifecycle !== LifeCycles.UPLOAD ? "lines":"details", false);
    this.tariftype_detail_route = new RouteV3<TarifType>(data.tariftype_detail_route, this);
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    this.tarif_detail_route = new RouteV3(data.tarif_detail_route || data.route, this);
  }



  public async onDuplicated($event: Tarif) {
    this.msgs.success("Tarif dupliqué avec succès");
    await this.router.navigate([".."], {relativeTo: this.route});
  }


  public async lifecycleAction($event: string) {
    console.log("Event", $event);
    if ($event === "editPrices") {
      this.navId.current = "lines";
    } else if ($event === "editTarif") {
      this.navId.current = "details";
    }
    console.log("Event", $event);
  }

  public isTarifPowerUser(t: Tarif): boolean {
    return (
      (t.isAuth("write") && (t.type_details.lifecycle === LifeCycles.GENERIC || t.type_details.lifecycle === LifeCycles.GENNOVAL || t.type_details.lifecycle === LifeCycles.UPLOAD)) ||
      (t.isAuth("admin") && (t.type_details.lifecycle === LifeCycles.GENERIC_ADMIN || t.type_details.lifecycle === LifeCycles.GENNOVAL_ADMIN))
    )
  }

  public toggleStats(state?: boolean): void {
    if (state===undefined) {
      this.stats = !this.stats;
    } else {
      this.stats = state;
    }
  }
}
