
<button class="btn btn-outline-primary btn-block w-100" (click)="startPrint()" *ngIf="!printing">Imprimer l'ensemble des pages</button>
<button class="btn btn-outline-danger btn-block w-100" (click)="stopPrint()" *ngIf="printing">Stopper l'impression</button>
<ng-container *ngIf="job">
  <div class="mt-2">
    <ngb-progressbar class="mt-2" [showValue]="true" type="info" [value]="job.progress"></ngb-progressbar><small class="text-info" *ngIf="job.status !== 'done'">Impression en cours, merci de patienter...</small>
  </div>
  <ng-container *ngIf="job.result">
    <ul class="mt-2">
      <ng-container *ngFor="let pr of job.result?.pages">
        <li *ngIf="pr.url"><a [href]="pr.url" target="_blank"><i class="fa fa-file-pdf me-2"></i>{{pr.name}}</a></li>
      </ng-container>
      <li *ngIf="job.result?.print?.url"><a [href]="job.result?.print?.url" target="_blank"><i class="fa fa-file-pdf me-2"></i>Toutes les pages concaténées dans un seul pdf</a></li>
      <li *ngIf="job.result?.zip?.url"><a [href]="job.result?.zip?.url" target="_blank"><i class="fa fa-archive me-2"></i>Toutes les pages dans une archive zip</a></li>
    </ul>
  </ng-container>
</ng-container>