import {NgModule} from "@angular/core";
import {VIEWS_COMMON_COMMON_MODULES} from "../views-common-modules";
import {CatalogArticleListViewV3Component} from "./catalog-article-list-view/catalog-article-list-view.component";
import {ArticleModule} from "../../../lib/models/catalog/article/article.module";
import {CatalogArticleManageViewV3Component} from "./catalog-article-manage-view/catalog-article-manage-view.component";
import {RnmModule} from "../../../lib/models/tarif/rnm/rnm.module";
import {ExternalArticleModule} from "../../../lib/models/catalog/externalarticle/externalarticle.module";
import {CatalogExternalarticleListViewV3Component} from "./catalog-externalarticle-list-view/catalog-externalarticle-list-view.component";
import {
  CatalogExternalArticleManageViewV3Component
} from "./catalog-externalarticle-manage-view/catalog-externalarticle-manage-view.component";
import {CatalogArticleFamilyTreeViewV3Component} from "./catalog-family-tree-view/catalog-family-tree.component";
import {ArticleFamilyModule} from "../../../lib/models/catalog/family/article-family.module";
import {CatalogArticleFamilyListViewV3Component} from "./catalog-family-list-view/catalog-family-list-view.component";
import {CatalogFicheDataListV3Component} from "./catalog-fichesdata-list/catalog-fichesdata-list.component";
import {FichesdataModule} from "../../../lib/models/catalog/fichesdata/fichesdata.module";
import {CatalogArticleFamilyManageViewV3Component} from "./catalog-family-manage-view/catalog-family-manage-view.component";
import {CatalogFicheDataManageViewV3Component} from "./catalog-fichedata-manage/catalog-fichedata-manage.component";
import {ArticledataModule} from "../../../lib/models/catalog/articledata/articledata.module";
import {CatalogArticleDataManageViewV3Component} from "./catalog-articledata-manage-view/catalog-articledata-manage-view.component";
import {BillItemModule} from "../../../lib/models/sales/billitem/billitem.module";
import {
  CatalogExternalArticleCollectionListViewV3Component
} from "./catalog-externalarticlecollection-list-view/catalog-externalarticlecollection-list-view.component";
import {ExternalArticleCollectionModule} from "../../../lib/models/catalog/externalarticlecollection/externalarticlecollection.module";
import {
  CatalogExternalArticleCollectionManageViewV3Component
} from "./catalog-externalarticlecollection-manage-view/catalog-externalarticlecollection-manage-view.component";
import {ArticletarifModule} from "../../../lib/models/tarif/articletarif/articletarif.module";


@NgModule({
  exports: [
    CatalogArticleListViewV3Component,
    CatalogArticleManageViewV3Component,
    CatalogExternalarticleListViewV3Component,
    CatalogExternalArticleManageViewV3Component,
    CatalogArticleFamilyTreeViewV3Component,
    CatalogArticleFamilyListViewV3Component,
    CatalogArticleFamilyManageViewV3Component,
    CatalogFicheDataListV3Component,
    CatalogFicheDataManageViewV3Component,
    CatalogArticleDataManageViewV3Component,
    CatalogExternalArticleCollectionListViewV3Component
  ],
  declarations: [
    CatalogArticleListViewV3Component,
    CatalogArticleManageViewV3Component,
    CatalogExternalarticleListViewV3Component,
    CatalogExternalArticleManageViewV3Component,
    CatalogArticleFamilyTreeViewV3Component,
    CatalogArticleFamilyListViewV3Component,
    CatalogArticleFamilyManageViewV3Component,
    CatalogFicheDataListV3Component,
    CatalogFicheDataManageViewV3Component,
    CatalogArticleDataManageViewV3Component,
    CatalogExternalArticleCollectionListViewV3Component,
    CatalogExternalArticleCollectionManageViewV3Component
  ],
  imports: [
    ...VIEWS_COMMON_COMMON_MODULES,
    ArticleModule,
    ExternalArticleModule,
    ExternalArticleCollectionModule,
    ArticleFamilyModule,
    FichesdataModule,
    ArticledataModule,
    BillItemModule,
    RnmModule,
    ArticletarifModule
  ]
})
export class ViewsCatalogModule {
}

export * from "./catalog-article-list-view/catalog-article-list-view.component";
export * from "./catalog-article-manage-view/catalog-article-manage-view.component";
export * from "./catalog-externalarticle-list-view/catalog-externalarticle-list-view.component";
export * from "./catalog-externalarticle-manage-view/catalog-externalarticle-manage-view.component";
export * from "./catalog-family-tree-view/catalog-family-tree.component";
export * from "./catalog-family-list-view/catalog-family-list-view.component";
export * from "./catalog-family-manage-view/catalog-family-manage-view.component";
export * from "./catalog-fichesdata-list/catalog-fichesdata-list.component";
export * from "./catalog-fichedata-manage/catalog-fichedata-manage.component";
export * from "./catalog-articledata-manage-view/catalog-articledata-manage-view.component";
export * from "./catalog-externalarticlecollection-list-view/catalog-externalarticlecollection-list-view.component";
