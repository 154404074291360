
<ng-container *ngIf="article">
  <h1>{{article.libelle}}<i class="ms-3 fa fa-print pointer" (click)="article_action.emit({article: article, action: 'quickprintarticle'})"></i><a class="ms-3 ioicon" [href]="article.iourl.url" target="_blank" title="Lien lvadgio"><i class="fa fa-link"></i><span>io</span></a></h1>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
      <div class="row g-0">
        <div class="col-12 col-md-6 col-lg-12">
          <lvadg-rimg [src]="article.clogo_img" display="fw" size="md"></lvadg-rimg>
        </div>
        <div class="col-12 col-md-6 col-lg-12">
          <p *ngIf="article.content">{{article.content}}</p>
          <ng-container *ngIf="article.documents_details?.length">
            <h3 class="mt-3">Documents</h3>
            <div class="certificates">
              <ng-container *ngFor="let pd of article.documents_details">
                <div class="certificate">
                  <lvadg-detaildocument-display [document]="pd" mode="preview"></lvadg-detaildocument-display>
                </div>
              </ng-container><a class="qrcode" [href]="article.qr" target="_blank"><img [src]="article.qr" title="QR code"></a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 mt-0 mt-sm-4">
      <h3>Nomenclature & saisonnalité</h3>
      <dl>
        <dt>Nom</dt>
        <dd>{{article.name}}</dd>
        <ng-container *ngIf="article.url">
          <dt>Plus d'infos</dt>
          <dd><a [href]="article.url" target="_blank">{{article.url|bsacFormatTrim:25}}</a></dd>
        </ng-container>
        <dt>Code</dt>
        <dd>{{article.code}}</dd>
        <ng-container *ngIf="article.scname">
          <dt>Nom scientifique</dt>
          <dd>{{article.scname||'-'}}</dd>
        </ng-container>
        <dt>Libellé complet</dt>
        <dd>{{article.libelle}}</dd>
        <dt>Classification</dt>
        <dd><span class="me-2" *ngFor="let f of article.family_details">&gt; {{f.name}}</span></dd>
        <dt>Saisonnalité</dt>
        <dd>
          <lvadg-seasons-manage [seasons]="article.seasons" [zone]="article.zone" [perline]="12" [editable]="false" size="medium"></lvadg-seasons-manage>
        </dd>
      </dl>
      <hr>
      <h3 class="mt-3">Informations nutritionnelles</h3>
      <p class="text-info" *ngIf="article.ciqual"><a [href]="'https://ciqual.anses.fr/#/aliments/'+article.ciqual_alim_details?.alim_code+'/data'" target="_blank"><i class="fa fa-external-link me-2"></i>Source: CIQUAL</a></p>
      <dl>
        <dt>Allergènes<small class="ms-2">Informations non contractuelles</small></dt>
        <dd>
          <ng-container *ngIf="!article.allergenes_details">-</ng-container><span class="me-2" *ngFor="let a of article.allergenes_details">{{a.code||a.name}}</span>
        </dd>
        <dt>Recette</dt>
        <dd>{{article.recette||'-'}}</dd>
      </dl>
      <div class="maxh">
        <table class="table table-sm table-borderless" *ngIf="article.ciqual_alim">
          <thead>
            <tr>
              <th>
                <button class="btn btn-link btn-xs" *ngIf="ciqualFull" (click)="ciqualFull=!ciqualFull">Affichage résumé</button>
                <button class="btn btn-link btn-xs" *ngIf="!ciqualFull" (click)="ciqualFull=!ciqualFull">Affichage complet</button>
              </th>
              <th>Valeur pour 100g</th>
              <th>% AJR</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let cg of ciqualCategs">
              <tr *ngIf="ciqualFull || cg.starred">
                <td class="gtitle" colspan="3">
                  <ng-container *ngIf="cg.name[0]!=='_'">{{cg.name}}</ng-container>
                </td>
              </tr>
              <ng-container *ngFor="let c of cg.values">
                <ng-container *ngIf="ciqualFull || c.column_details.display === 'ST' || c.column_details.display === 'SS'">
                  <tr>
                    <td><span class="pointer" placement="top" [ngbTooltip]="c.column_details.desc" *ngIf="c.column_details.desc"><i class="fa fa-info-circle me-2"></i>{{c.column_details?.name}}</span><span *ngIf="!c.column_details.desc">{{c.column_details?.name}}</span></td>
                    <td>
                      <ng-container *ngIf="c.value!=null">{{c.less?'< ': ''}} {{c.value/100000}} {{c.column_details?.unit}}</ng-container>
                      <ng-container *ngIf="c.value==null">{{c.traces?'traces':''}}</ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="c.column_details.ajr &amp;&amp; c.value!=null">{{c.less?'< ': ''}}  {{c.value/c.column_details.ajr|percent:'1.0-2'}}</ng-container>
                      <ng-container *ngIf="!(c.column_details.ajr &amp;&amp; c.value!=null)">-</ng-container>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-0 mt-sm-4">
      <ng-container *ngIf="article.egalim_details?.length || article.local == 'L' || article.local == 'U'">
        <h3>Labels et certifications</h3>
        <div class="row">
          <div class="col" *ngIf="article.isEgalim">
            <div class="egalim" [class.mr]="article.zone=='mr'"><span>EG<wbr>alim</span></div>
          </div>
          <div class="col" *ngIf="article.isEgalimPlus(this.client)">
            <div class="egalimplus" [class.mr]="article.zone=='mr'"><span>EQUI<wbr>VA<wbr>LENCE EG<wbr>alim</span></div>
          </div>
          <div class="col" *ngIf="article.local === 'L'">
            <div class="egalim" [class.mr]="article.zone=='mr'"><span>LOCAL</span></div>
          </div>
          <div class="col" *ngIf="article.local === 'U'">
            <div class="egalim" [class.mr]="article.zone=='mr'"><span>ULTRA LOCAL</span></div>
          </div>
          <div class="col" *ngFor="let e of article.egalim_details">
            <div class="d-flex flex-column align-items-center ps-4 pe-4">
              <lvadg-label-display [labelId]="e.flabel" imgDisplay="thumb64" imgSize="sm" *ngIf="e.flabel"></lvadg-label-display>
              <lvadg-sealabel-display [labelId]="e.slabel" imgDisplay="thumb64" imgSize="sm" *ngIf="e.slabel"></lvadg-sealabel-display>
              <div class="text-center">{{e.name}}</div>
              <div class="text-center"><small *ngIf="e.url"><a [href]="e.url" target="_blank">+ d'infos</a></small></div>
            </div>
          </div>
        </div>
      </ng-container>
      <hr>
      <h3 class="mt-2">Conditionnement et facturation</h3>
      <dl>
        <dt>Colisage</dt>
        <dd>
          <ng-container *ngIf="article.colisage_nombre">{{article.colisage_cond_details?.name||article.colisage_cond_details?.code}} &times;{{article|dsDecimal:"colisage_nombre"}} {{article|dsDecimal:"colisage_poids"}}{{article.colisage_unit}}</ng-container>
          <ng-container *ngIf="!article.colisage_nombre">{{article.colisage_cond_details?.name||article.colisage_cond_details?.code}} {{article|dsDecimal:"colisage_poids"}}{{article.colisage_unit}}</ng-container>
        </dd>
        <dt>Poids min / max (kg)</dt>
        <dd>{{article|dsDecimal:"poids_min"}} / {{article|dsDecimal:"poids_max"}}</dd>
        <dt>UCI<small class="ms-2">Unité de Conditionnement Indivisible</small></dt>
        <dd>{{article.uci_cond_details?.name||article.uci_cond_details?.code}} {{article|dsDecimal:'uci_value'}}{{article.uci_unit}}</dd>
        <dt>UDF<small class="ms-2">Unité de facturation</small></dt>
        <dd>{{article.udf_unit}}</dd>
        <dt>UDV<small class="ms-2">Unité de vente</small></dt>
        <dd>{{article|dsDecimal:'udv_value'}}</dd>
      </dl>
      <hr>
      <h3 class="mt-2">Infos qualité</h3>
      <dl>
        <ng-container *ngIf="article.local &amp;&amp; article.local!= '-'">
          <dt>Local</dt>
          <dd>
            <ng-container *ngIf="article.local=='L'">oui</ng-container>
            <ng-container *ngIf="article.local=='U'">oui (ultralocal)</ng-container>
          </dd>
        </ng-container>
        <dt>Calibre</dt>
        <dd>
          <div>{{article.calibre_details?.code||article.calibre_details?.name}}</div>
          <div class="small" *ngIf="article.calibre_details?.content">{{article.calibre_details?.content}}</div>
          <div class="small" *ngIf="article.calibre_details?.poids||article.calibre_details?.taille||article.calibre_details?.nombre">Infos calibre :
            <ul>
              <li *ngIf="article.calibre_details?.poids">poids {{article.calibre_details?.poids}}</li>
              <li *ngIf="article.calibre_details?.taille">taille {{article.calibre_details?.taille}}</li>
              <li *ngIf="article.calibre_details?.nombre">nombre par kg : {{article.calibre_details?.nombre}}</li>
            </ul>
          </div>
        </dd>
        <dt>Catégorie</dt>
        <dd>{{article.calibre_details?.name||article.categorie_details?.code||"-"}}</dd>
        <dt>Process</dt>
        <dd>{{article.process_details?.name||article.process_details?.code||'-'}}</dd>
        <dt>Traitement</dt>
        <dd>{{article.traitement_details?.name||article.traitement_details?.code||'-'}}</dd>
        <ng-container *ngIf="article.production">
          <dt>Production</dt>
          <dd>{{article.production_details?.name||article.production_details?.code||'-'}}</dd>
        </ng-container>
        <ng-container *ngIf="article.marque">
          <dt>Marque</dt>
          <dd>
            <div>{{article.marque_details?.name||article.marque_details?.code||'-'}}</div>
            <div *ngIf="article.marque_details?.producer_details?.name"><a [routerLink]="routes.detail('fl', 'producer', article.marque_details?.producer_details)"><i class="fa fa-link me-2"></i>{{article.marque_details?.producer_details?.name}}</a></div>
            <div *ngIf="article.marque_details?.folder"><a [routerLink]="routes.detail('fl', 'documents', article.marque_details?.folder_details?.slug+'-'+article.marque_details?.folder_details?.id)"><i class="fa fa-folder me-2"></i>{{article.marque_details?.folder_details?.title}}</a></div>
          </dd>
        </ng-container>
        <ng-container *ngIf="article.fournisseur">
          <dt>Fournisseur</dt>
          <dd>{{article.fournisseur_details?.name}}</dd>
        </ng-container>
        <dt>DLC / DDM</dt>
        <dd>
          <div *ngIf="article.dlc_value">DLC {{article.dlc_value}} {{article.dlc_unit}}</div>
          <div *ngIf="article.ddm_value">DDM {{article.ddm_value}} {{article.ddm_unit}}</div>
          <ng-container *ngIf="!article.dlc_value &amp;&amp; !article.ddm_value"></ng-container>
        </dd>
        <dt>Origine</dt>
        <dd>{{article.origine_details?.code||article.origine_details?.name||'-'}}</dd>
        <dt>Région</dt>
        <dd>{{article.region_details?.code||article.region_details?.name||'-'}}</dd>
        <ng-container *ngIf="article.client">
          <dt>Spécificité client</dt>
          <dd>{{article.client_details?.code||article.client_details?.name||'-'}}</dd>
        </ng-container>
        <dt>Usage / divers</dt>
        <dd>{{article.usage_details?.code||article.usage_details?.name||'-'}}</dd>
      </dl>
    </div>
  </div>
  <!--.row-->
  <!--  .col-sm-4-->
  <!--    h3 Nomenclatures client ?-->
  <!--    pre.todo.-->
  <!--      TODO: affichage nomenclatures client-->
</ng-container>