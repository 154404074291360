import {Component} from "@angular/core";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FichesData} from "../../../../lib/models/catalog/fichesdata/fichesdata.service";
import {CommonBaseComponent} from "../../common-base.component";


export interface CatalogFicheDataListV3Params extends BaseRouteV3Params {
  fichedata_detail_route: RouteConfigItemV3;
}

export interface CatalogFicheDataListV3Data extends CatalogFicheDataListV3Params {

}


@Component({
  selector: "lvadg-catalog-fichedata-list-view",
  templateUrl: "./catalog-fichesdata-list.component.pug",
  styleUrls: ["./catalog-fichesdata-list.component.sass"]
})
export class CatalogFicheDataListV3Component extends CommonBaseComponent {
  public create = false;
  public fichedata_detail_route!: RouteV3<FichesData>;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }

  public onCreated(fd: FichesData): void {
    this.router.navigate(this.fichedata_detail_route.route({ficheData: fd}));
  }

  protected async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogFicheDataListV3Data;
    this.fichedata_detail_route = new RouteV3<FichesData>(data.fichedata_detail_route, this);
    super.preNgOnInit();
  }
}
