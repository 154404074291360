import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ProductFamilyCreateComponent} from "./productfamily-create/productfamily-create.component";
import {ProductFamilyDeleteComponent} from "./productfamily-delete/productfamily-delete.component";
import {ProductFamilyDisplayComponent} from "./productfamily-display/productfamily-display.component";
import {ProductFamilyListComponent} from "./productfamily-list/productfamily-list.component";
import {ProductFamilySelectComponent} from "./productfamily-select/productfamily-select.component";
import {ProductFamilyManageComponent} from "./productfamily-manage/productfamily-manage.component";
import {ProductFamilyService} from "./productfamily.service";
import {ProductFamilyResolve} from "./productfamily.resolve";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CompsModule} from "../../../comps/comps.module";
import {ProductCommonModule} from "../common/product-common.module";
import {ImageModule} from "../../content/image/image.module";
import {ProductVariantModule} from "../productvariant/productvariant.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {LabelModule} from "../label/label.module";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsacModelListModule,
    BsacApiConstantsModule,

    BsacFormsModule,
    NgbModule,
    FormsModule,
    CompsModule,
    ImageModule,
    ProductCommonModule,
    ProductVariantModule,
    LabelModule,
    CustomDataModule,
    ReactiveFormsModule,
    CdkTableModule
  ],
  providers: [
    ProductFamilyService,
    ProductFamilyResolve
  ],
  declarations: [
    ProductFamilySelectComponent,
    ProductFamilyDisplayComponent,
    ProductFamilyDeleteComponent,
    ProductFamilyListComponent,
    ProductFamilyManageComponent,
    ProductFamilyCreateComponent
  ],
  exports: [
    ProductFamilySelectComponent,
    ProductFamilyDisplayComponent,
    ProductFamilyDeleteComponent,
    ProductFamilyListComponent,
    ProductFamilyManageComponent,
    ProductFamilyCreateComponent
  ],
})
export class ProductFamilyModule { }
