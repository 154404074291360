import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ModelComm} from "../modelcomm.service";
import {first, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";

@Component({
  selector: "lvadg-modelcomm-preview",
  templateUrl: "./modelcomm-preview.component.pug",
  styleUrls: ["./modelcomm-preview.component.sass"]
})
export class ModelcommPreviewComponent implements OnInit, OnDestroy {
  public preview!: SafeHtml;
  @Input() public model!: ModelComm;
  // Zoom management
  public zooms = [25, 50, 75, 100, 125, 150, 200];
  public curzoom = 2;

  // Page navigation
  @Input() public pages!: any[];
  @Input() public pageChanged!: Observable<number | null>;
  @Input() public emptyName = "";
  @Input() public type: "page" | "email" = "page";
  public curpage!: { name?: string; dmail?: string } | null;
  public curPageIndex: number | null = null;
  public allowPageChange = false;
  public loading = true;

  private _subscriptions = new Subject<void>();

  constructor(private _ds: DomSanitizer) {
  }

  ngOnInit() {
    if (this.pageChanged && this.pageChanged.subscribe) {
      console.log("Subscribe to page change event");
      this.pageChanged.pipe(takeUntil(this._subscriptions)).subscribe((page) => {
        console.log("Page changed...", page);
        this.getPage(page);
      });
    } else if (this.pages && this.pages.length > 0) {
      this.getPage(this.pages[0].id);
    }
    this.getPage(null);
  }

  ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public getPage(page: number | null, manual: boolean = false): void {
    const qry: any = {};
    if (page !== null && page !== undefined) {
      qry.page = page;
      if (!manual) {
        this.allowPageChange = false;
      }
    } else {
      this.allowPageChange = this.pages && this.pages.length > 0;
    }
    this.curpage = null;
    this.curPageIndex = null;
    let i = 0;
    console.log("PAGES", this.pages);
    if (this.pages && this.pages.length > 0) {
      for (const p of this.pages) {
        if (page === p.id) {
          this.curpage = p;
          console.log("Current page", this.curpage);
          this.curPageIndex = i;
          break;
        }
        i++;
      }
    }
    this.loading = true;
    this.model.action("preview", {method: "GET", query: qry})
      .pipe(first())
      .subscribe((res) => {
        this.preview = this._ds.bypassSecurityTrustHtml(res);
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        if (isIEOrEdge) {
          // IE hack here
          try {
            const ifrm = (document.querySelector("#printpreview") as any).contentWindow.document;
            ifrm.open("text/html", "replace");
            ifrm.write(res);
            ifrm.close();
          } catch (e) {
            console.error("Cannot update preview", e);
          }
        }
        this.loading = false;
      });
  }

  public zoomPlus(): void {
    this.curzoom += 1;
    if (this.curzoom >= this.zooms.length) {
      this.curzoom = this.zooms.length - 1;
    }
  }

  public zoomMinus(): void {
    this.curzoom -= 1;
    if (this.curzoom < 0) {
      this.curzoom = 0;
    }
  }

  public zoomClass(): string {
    return `z${this.zooms[this.curzoom]}`;
  }

  public nextPage(): void {
    if (!this.allowPageChange) {
      return;
    }
    if (this.curPageIndex === null) {
      this.curPageIndex = 0;
    } else {
      this.curPageIndex += 1;
    }
    if (this.curPageIndex >= this.pages.length) {
      this.curPageIndex = this.pages.length - 1;
    }
    this.getPage(this.pages[this.curPageIndex].id, true);
  }

  public previousPage(): void {
    if (!this.allowPageChange) {
      return;
    }
    if (this.curPageIndex === null) {
      this.curPageIndex = 0;
    } else {
      this.curPageIndex -= 1;
    }
    if (this.curPageIndex < 0) {
      this.curPageIndex = 0;
      this.getPage(null, true);
    } else {
      this.getPage(this.pages[this.curPageIndex].id, true);
    }
  }

}
