import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel
} from "@solidev/ngdataservice";
import {BaseProductPrice} from "../_productprice/productprice-base.model";

export class BaseProductVariant extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "client",
    verboseName: "Client",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public client: number;
  public client_details: any;

  @DSCharField({
    name: "vcode",
    verboseName: "Code Vivalya",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public vcode: string;

  @DSCharField({
    name: "ccode",
    verboseName: "Code client",
    required: true,
    helpText: "",
    default: "",
    choices: []
  })
  public ccode: any;

  @DSCharField({
    name: "sname",
    verboseName: "Désignation courte",
    required: false,
    helpText: "",
    default: "",
    choices: [],
    maxLength: 100
  })
  public sname: string;

  @DSCharField({
    name: "lname",
    verboseName: "Désignation",
    required: true,
    helpText: "",
    default: "",
    choices: [],
    maxLength: 300
  })
  public lname: string;

  @DSCharField({
    name: "fname",
    verboseName: "Sous-groupe",
    required: false,
    helpText: "",
    default: "",
    choices: [],
    maxLength: 100
  })
  public fname: string;

  @DSManyToManyField({
    name: "families",
    verboseName: "Familles",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public families: number[];

  @DSDefaultField({
    name: "families_details",
    verboseName: "Familles details",
    required: false
  })
  public families_details: any[];

  @DSDecimalField({
    name: "packaging_value",
    verboseName: "UDP (valeur)",
    required: false,
    helpText: "",
    default: "",
    choices: [],
    factor: 1000
  })
  public packaging_value: number;

  @DSCharField({
    name: "packaging_unit",
    verboseName: "UDP (unité)",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public packaging_unit: string;

  @DSDecimalField({
    name: "sales_value",
    verboseName: "UDF (valeur)",
    required: false,
    helpText: "",
    default: "",
    choices: [],
    factor: 1000
  })
  public sales_value: number;

  @DSCharField({
    name: "sales_unit",
    verboseName: "UDF (unité)",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public sales_unit: string;

  @DSCharField({
    name: "packing",
    verboseName: "Colisage",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public packing: string;

  @DSManyToManyField({
    name: "prices",
    verboseName: "Tarif",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public prices: number[];


  @DSCharField({
    name: "importref",
    verboseName: "Ref importation",
    required: false,
    helpText: "",
    default: "",
    choices: [],
    maxLength: 20
  })
  public importref: string;

  @DSForeignKeyField({
    name: "catalog",
    verboseName: "Catalogue",
    required: false,
    helpText: "Catalogue",
    relatedModel: "VivalyaArticle",
    remoteField: "id",
    relatedName: "fvariants"
  })
  public catalog: number;
  public catalog_details: {id: number; name: string; description: string};


  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    default: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "insales",
    verboseName: "Dans tarif",
    required: false,
    helpText: "",
    default: "",
    choices: [["OK", "Oui"], ["NO", "Non"]],
    maxLength: 2
  })
  public insales: string;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "",
    choices: [["OK", "OK"], ["KO", "Pas OK"]],
    maxLength: 4
  })
  public status: string;

  public prices_details: BaseProductPrice[];

}
