import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RestoSelectComponent} from "./resto-select/resto-select.component";
import {BsacFormsModule} from "@solidev/bsadmincomponents";


@NgModule({
  imports: [
    CommonModule,
    BsacFormsModule,
  ],
  declarations: [
    RestoSelectComponent
  ],
  exports: [
    RestoSelectComponent
  ],
})
export class RestoSelectModule {
}
