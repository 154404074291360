import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Tarif} from "../tarif/tarif.service";

export enum TarifNotificationTypes {
  INITIAL = "PUB",
  UPDATE = "UPD",
  ALARM = "ALA",
  NEGO = "NEG"
}

export enum TarifNotificationPriorities {
  P1 = "P1",
  P2 = "P2",
  P3 = "P3"
}


export enum TarifNotificationStatuses {
  PREPARATION = "PRP",
  PENDING = "PEN",
  SENDING = "SND",
  SENT = "SNT"
}


export class TarifNotification extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "tarif",
    verboseName: "Tarif",
    required: false,
    helpText: "Tarif",
    relatedModel: "tarifs.Tarif",
    remoteField: "id",
    relatedName: "notifications"
  })
  public tarif!: number;
  public tarif_details!: Tarif;

  @DSCharField({
    name: "title",
    verboseName: "Titre",
    required: false,
    helpText: "Titre de la notification",
    minLength: 0,
    maxLength: 300,
    choices: []
  })
  public title!: string;

  @DSTextField({
    name: "message",
    verboseName: "Message",
    required: false,
    helpText: "Message de la notification",
    minLength: 0,
    maxLength: 30000,
    choices: []
  })
  public message!: string;

  @DSCharField({
    name: "priority",
    verboseName: "Priorité",
    required: false,
    helpText: "Priorité de la notification",
    minLength: 0,
    maxLength: 2,
    choices: [
      [TarifNotificationPriorities.P1, "Haute"],
      [TarifNotificationPriorities.P2, "Normale"],
      [TarifNotificationPriorities.P3, "Basse"]
    ]
  })
  public priority!: TarifNotificationPriorities


  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "PEN",
    choices: [
      [TarifNotificationStatuses.PREPARATION, "En préparation"],
      [TarifNotificationStatuses.PENDING, "En attente d'envoi"],
      [TarifNotificationStatuses.SENDING, "En cours d'envoi"],
      [TarifNotificationStatuses.SENT, "Envoyée"]
    ],
    maxLength: 10
  })
  public status!: TarifNotificationStatuses;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type de notification",
    minLength: 0,
    maxLength: 3,
    choices: [
      [TarifNotificationTypes.INITIAL, "Publication"],
      [TarifNotificationTypes.UPDATE, "Mise à jour"],
      [TarifNotificationTypes.ALARM, "Alarme"],
      [TarifNotificationTypes.NEGO, "Negociation"]
    ]
  })
  public type!: TarifNotificationTypes;


  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  public user_status!: string;

}

@Injectable({
  providedIn: "root"
})
export class TarifNotificationService extends DSRestCollection<TarifNotification> {
  public adapter_config = {basePath: "/api/v2/tarifs/tarifnotifications"};
  public model = TarifNotification;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
