
<div class="row">
  <div class="col-md-6">
    <form *ngIf="location" [formGroup]="form">
      <div class="row" *ngIf="location">
        <div class="col-sm-6">
          <bsac-field-control [model]="location" [form]="form" field="type" type="auto"><span class="label">Type</span></bsac-field-control>
        </div>
        <div class="col-sm-6">
          <bsac-field-control [model]="location" [form]="form" field="locname" type="auto"><span class="label">Nom (*)</span></bsac-field-control>
        </div>
        <div class="col-sm-12">
          <bsac-field-control [model]="location" [form]="form" field="address" type="mltext"><span class="label">Adresse (*)</span></bsac-field-control>
        </div>
        <div class="col-sm-12">
          <bsac-field-control [model]="location" [form]="form" field="caddress" type="mltext"><span class="label">Complément d'adresse</span></bsac-field-control>
        </div>
        <div class="col-sm-4">
          <bsac-field-control [model]="location" [form]="form" field="city" type="auto"><span class="label">Ville (*)</span></bsac-field-control>
        </div>
        <div class="col-sm-4">
          <bsac-field-control [model]="location" [form]="form" field="postcode" type="auto"><span class="label">Code postal (*)</span></bsac-field-control>
        </div>
        <div class="col-sm-4">
          <bsac-field-control [model]="location" [form]="form" field="cedex" type="auto"><span class="label">CEDEX</span></bsac-field-control>
        </div>
      </div>
      <dl class="row" *ngIf="location">
        <div class="col-sm-4">
          <bsac-field-control [model]="location" [form]="form" field="phone" type="auto"><span class="label">Téléphone</span></bsac-field-control>
        </div>
        <div class="col-sm-4">
          <bsac-field-control [model]="location" [form]="form" field="fax" type="auto"><span class="label">Fax</span></bsac-field-control>
        </div>
        <div class="col-sm-4">
          <bsac-field-control [model]="location" [form]="form" field="website" type="auto"><span class="label">Site internet</span></bsac-field-control>
        </div>
      </dl>
    </form>
  </div>
  <div class="col-md-6" *ngIf="!(form.value.postcode &amp;&amp; form.value.city)">
    <div class="alert alert-info"><i class="fa fa-info-circle fa-4x float-start me-3"></i>La géolocalisation nécessite au moins un code postal et une ville. Renseignez l'adresse complète pour plus de précision.</div>
  </div>
  <div class="col-md-6" *ngIf="form.value.postcode &amp;&amp; form.value.city">
    <ng-container *ngIf="!geoloc">
      <div class="text-center">
        <button class="btn btn-lg btn-outline-primary mt-5" (click)="geoLocate()"><i class="fa fa-map-marker me-2"></i>Géolocaliser</button>
      </div>
    </ng-container>
    <ng-container *ngIf="geofailure">
      <p class="text-danger">La géolocalisation n'a pas pu être effectuée, merci de vérifier les données entrées.</p>
      <button class="btn btn-primary" (click)="geoLocate()"><i class="fa fa-map-marker-alt me-2"></i>Géolocaliser à nouveau</button>
    </ng-container>
    <ng-container *ngIf="!geofailure &amp;&amp; geoloc">
      <div class="row">
        <div class="col-sm-6">
          <button class="btn btn-outline-primary btn-block w-100" (click)="geoLocate()"><i class="fa fa-map-marker-alt me-2"></i>Géolocaliser</button>
        </div>
        <div class="col-sm-6">
          <p class="text-success" *ngIf="geoloc.properties.type=='housenumber'">Géolocalisation précise à quelques mètres près, merci de vérifier / corriger ci dessous si nécessaire.</p>
          <p class="text-warning" *ngIf="geoloc.properties.type=='street'">Géolocalisation approximative à la rue près, merci de vérifier / corriger ci dessous.</p>
          <p class="text-warning" *ngIf="geoloc.properties.type=='city'">Géolocalisation approximative à la ville près, merci de vérifier / corriger ci dessous.</p>
        </div>
      </div>
      <lvadg-locations-osm-display [marker]="location" [boundary]="'auto'" (markerUpdated)="manualPosition($event)"></lvadg-locations-osm-display>
    </ng-container>
  </div>
  <div class="col-sm-12" *ngIf="save">
    <div class="row">
      <div class="col-8">
        <button class="btn btn-primary btn-block w-100" (click)="create()" [disabled]="form.pristine || !form.valid || !geoloc"><i class="fa fa-save me-2"></i>Enregister</button>
      </div>
      <div class="col-4">
        <button class="btn btn-secondary btn-block w-100" (click)="cancel()">Annuler</button>
      </div>
    </div>
  </div>
  <div class="col-sm-12" *ngIf="!save">
    <div class="row">
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <button class="btn btn-primary btn-block w-100" (click)="create()" [disabled]="form.pristine || !form.valid || !geoloc"><i class="fa fa-check me-2"></i>Valider la localisation</button>
      </div>
    </div>
  </div>
</div>