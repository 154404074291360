import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Tarif} from "../../tarif/tarif.service";
import {Article} from "../../../catalog/article/article.service";
import {ArticleTarifService} from "../articletarif.service";
import {ArticleTarifTemplateService, ArticleTarifTemplateStatus} from "../articletariftemplate.service";
import {TarifType} from "../../tariftype/tariftype.service";
import {firstValueFrom, Subject} from "rxjs";
import {RouteV3} from "../../../../comps/routes.service";
import {Provider} from "../../../structure/provider/provider.service";

@Component({
  selector: "lvadg-articletarif-create",
  templateUrl: "./articletarif-create.component.pug",
  styleUrls: ["./articletarif-create.component.sass"]
})
export class ArticletarifCreateComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public tarifType!: TarifType;
  @Output() public created = new EventEmitter<boolean>();
  @Input() public article_detail_route!: RouteV3<Article>;
  @Input() public provider_detail_route!: RouteV3<Provider>;
  public reload$ = new Subject<void>();
  public _asvc!: ArticleTarifService | ArticleTarifTemplateService;

  public default_fields = ["in_tarif", "code", "libelle", "origine",
    "calibre", "categorie", "egalim", "udf_unit", "actions"];
  public default_filters = ["search", "pname", "infamily"];

  public actions = ["addtotarif"];

  constructor(private _ati: ArticleTarifService,
              private _att: ArticleTarifTemplateService) {
  }

  ngOnInit(): void {
    if (this.tarif) {
      this._asvc = this._ati;
    } else {
      this._asvc = this._att;
    }
  }

  public async onAction($event: { action: string; stype: string; items: Article[] }) {
    if ($event.action === "tarifaddbtn") {
      const itm = $event.items[0];
      if (this.tarifType) {
        await firstValueFrom(
          this._att.create({
            article: itm.id,
            name: itm.libelle,
            status: ArticleTarifTemplateStatus.ACTIVE,
            tarif_type: this.tarifType.id,
            uc: itm.udf_unit
          }, {save: true})
        );
        this.created.emit(false);
        this.reload$.next();
      }
    }
  }


}
