import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {StoragesModes, TarifType, TarifTypeService} from "../tariftype.service";
import {UntypedFormGroup} from "@angular/forms";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-tariftype-create",
  templateUrl: "./tariftype-create.component.pug",
  styleUrls: ["./tariftype-create.component.sass"]
})
export class TariftypeCreateComponent implements OnInit {
  @Input() public ttype: "gen" | "cli" = "cli";
  public tt!: TarifType;
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public nextcode: string = "";
  @Output() public created = new EventEmitter<TarifType>();

  constructor(private _model: TarifTypeService) {
  }

  async ngOnInit(): Promise<void> {
    this.nextcode = (await firstValueFrom(this._model.action(null, "next_code", {method: "POST"})))[this.ttype.toUpperCase()];
    this.tt = await firstValueFrom(this._model.create({
      type: this.ttype.toUpperCase(),
      code: this.nextcode,
      status: "PRP",
      decondi_status: "UNK",
      storages_mode: StoragesModes.Base,
      lifecycle: "UPLOAD"
    }, {save: false}));
  }

  public async submit() {
    this.tt.assign(this.form.value);
    await firstValueFrom(this.tt.save());
    this.created.emit(this.tt);
  }

}
