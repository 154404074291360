import {Component, Input, OnInit} from "@angular/core";
import {ArticleTarif} from "../../articletarif.service";
import {DomSanitizer} from "@angular/platform-browser";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "lvadg-articletarif-message-manage",
  templateUrl: "./articletarif-message-manage.component.pug",
  styleUrls: ["./articletarif-message-manage.component.sass"]
})
export class ArticletarifMessageManageComponent implements OnInit {
  @Input() public at!: ArticleTarif;
  @Input() public message!: string;
  public edit = {message: false};
  public pop!: NgbPopover;
  constructor(private _san: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  public onClick(p: NgbPopover): void {
    this.pop = p;
    if (p.isOpen()) {
      this.edit.message = true;
    }
  }

  public onEnter(p: NgbPopover): void {
    this.pop = p;
    p.open();
  }

  public onLeave(p: NgbPopover): void {
    this.pop = p;
    if (!this.edit.message) {
      p.close();
    }
  }

  public onUpdated() {
    this.pop.close();
    this.edit.message = false;
  }
}
