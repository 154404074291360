import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Offer} from "../offer.service";
import {PrinterFieldParamsService} from "../../../comm/print/printerfieldparams.service";
import {map, takeUntil} from "rxjs/operators";
import {Resto, RestoService} from "../../../structure/resto/resto.service";
import {SafeHtml} from "@angular/platform-browser";
import {ModelPrint, ModelPrintService} from "../../../comm/print/modelprint.service";
import {Subject} from "rxjs";
import {PrinterFieldService} from "../../../comm/print/printerfield.service";


@Component({
  selector: "lvadg-offer-print-parameters",
  templateUrl: "./offer-print-parameters.component.pug",
  styleUrls: ["./offer-print-parameters.component.sass"]
})
export class OfferPrintParametersComponent implements OnInit, OnDestroy {
  @Input() public offer!: Offer;
  public loaded = false;
  public curPage!: number;
  public preview!: SafeHtml;
  public restos!: Resto[];
  public print!: ModelPrint;
  public page$ = new Subject<number>();
  private _subscriptions = new Subject<void>();

  constructor(public params$: PrinterFieldParamsService,
              public fields$: PrinterFieldService,
              private _restos: RestoService,
              private _mp: ModelPrintService) {
  }


  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public async ngOnInit(): Promise<void> {
    this.print = await this._mp.create(
      await this.offer.action(
        "print",
        {
          method: "POST",
          body: {}
        }).toPromise()
    ).toPromise();
    this.restos = await this._restos.queryset.query({offer: this.offer.id})
      .get()
      .pipe(map((res) => {
          return res.items;
        })
      ).toPromise();
    this.params$.setUp({pageQuery: "page", pageParamKey: "resto"});
    this.params$.updated.pipe(takeUntil(this._subscriptions)).subscribe(() => {
      this.page$.next(this.curPage);
    });
    await this.params$.load(this.print, this.curPage);
    this.loaded = true;
  }

  public async selectPage(page: number | null): Promise<void> {
    if (page === null) {
      this.curPage = null;
    } else {
      this.curPage = page;
    }

    await this.params$.load(this.print, this.curPage);
  }

}
