import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../../lib/comps/routes.service";
import {Provider} from "../../../../../lib/models/structure/provider/provider.service";
import {CommonBaseComponent} from "../../../common-base.component";
import {Producer} from "../../../../../lib/models/structure/producer/producer.service";

export interface ProviderListViewV3Params extends BaseRouteV3Params {
  provider_detail_route: RouteConfigItemV3;
  producer_detail_route: RouteConfigItemV3;
}

export interface ProviderListViewV3Data extends ProviderListViewV3Params {

}

@Component({
  selector: "lvadg-provider-list-view",
  templateUrl: "./provider-list-view.component.pug",
  styleUrls: ["./provider-list-view.component.sass"]
})
export class ProviderListViewV3Component extends CommonBaseComponent {
  public create!: any;
  public default_fields: string[] = ["id", "name", "status", "producers_details", "article_count",
    "open_date", "close_date", "ref_1g", "ref_4g", "ref_5g"];
  public allowed_fields: string[] = [];
  public excluded_fields: string[] = [];
  public default_filters: string[] = ["search", "have_articles", "have_producers"];
  public provider_detail_route!: RouteV3<Provider>;
  public producer_detail_route!: RouteV3<Producer>;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }

  public created(model: Provider): void {
    this.create = false;
  }

  public cancelled(): void {
    this.create = false;
  }

  protected async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as ProviderListViewV3Data;
    this.provider_detail_route = new RouteV3<Provider>(data.provider_detail_route, this);
    this.producer_detail_route = new RouteV3<Producer>(data.producer_detail_route, this);
    super.preNgOnInit();
  }

  protected async postNgOnInit(): Promise<void> {
    console.log("Current route", this.currentRoute);
  }

}
