import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProducer} from "../_producer/producer-base.model";

export class Producer extends BaseProducer {

}

@Injectable({providedIn: "root"})
export class ProducerService extends DSRestCollection<Producer> {
  public adapter_config = {basePath: "/api/v2/structure/producers"};
  public model = Producer;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
