import {Component, Input, OnInit} from "@angular/core";
import {TarifType, TarifTypeService} from "../../tariftype/tariftype.service";
import {TarifTypeRelation, TarifTypeRelationService} from "../tariftyperelation.service";
import {firstValueFrom, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {UntypedFormGroup} from "@angular/forms";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {RouteV3} from "../../../../comps/routes.service";

@Component({
  selector: "lvadg-tariftyperelation-listmanage",
  templateUrl: "./tariftyperelation-listmanage.component.pug",
  styleUrls: ["./tariftyperelation-listmanage.component.sass"]
})
export class TariftyperelationListmanageComponent implements OnInit {
  @Input() public src?: TarifType;
  @Input() public dest?: TarifType;
  @Input() public tariftype_detail_route?: RouteV3<TarifType>;
  @Input() public editable: boolean = false;
  public createForm = new UntypedFormGroup({});
  public newRelation!: TarifTypeRelation;
  public add: boolean = false;
  public qs!: DSRestQueryset<TarifTypeRelation>;
  public rels!: Observable<TarifTypeRelation[]>;
  public editOps: { [index: number]: boolean } = {};

  constructor(public tariftypes$: TarifTypeService,
              private _ttrel: TarifTypeRelationService) {
  }

  async ngOnInit(): Promise<void> {

    this.qs = this._ttrel.queryset;
    if (this.src) {
      this.qs.query({src: this.src.id});
    }
    if (this.dest) {
      this.qs.query({dest: this.dest.id});
    }
    this.rels = this.qs.results.pipe(map((r) => r.items));
    await this.reload();
  }

  public async initNewRelation() {
    this.newRelation = await firstValueFrom(this._ttrel.create({coef: 10000, src: this.src?.id, dest: this.dest?.id},
      {save: false}));
    this.add = true;
  }

  public async create() {
    this.newRelation.assign(this.createForm.value);
    await firstValueFrom(this.newRelation.save());
    await this.reload();
    this.add = false;

  }

  public async reload() {
    await firstValueFrom(this.qs.get());
  }


}
