import {Component, Input} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {ArticleFamily, ArticleFamilyService} from "../article-family.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {DetailDocument, DetailDocumentService} from "../../../document/basedocument/basedocument.service";
import {firstValueFrom} from "rxjs";
import {ArticleStatService} from "../../article/article-stat.service";
import {DSRestQueryset} from "@solidev/ngdataservice";
import {ArticleEgalim, ArticleEgalimService} from "../../articledata/article-egalim.service";
import {ArticleOrigine, ArticleOrigineService} from "../../articledata/article-origine.service";

@Component({
  selector: "lvadg-article-family-list",
  templateUrl: "./article-family-list.component.pug",
  styleUrls: ["./article-family-list.component.sass"]
})
export class ArticleFamilyListComponent extends ModelListBaseComponent<ArticleFamily, ArticleFamilyService> {
  @Input() public seasons_perline = 6;
  @Input() public articlefamily_detail_route!: RouteV3<ArticleFamily>;
  @Input() public stats: boolean = false;
  public stats_intervals: number[] = [];
  public egalims$!: DSRestQueryset<ArticleEgalim>;
  public origines$!: DSRestQueryset<ArticleOrigine>;

  constructor(_list: BsacModelListProvider,
              _items: ArticleFamilyService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              public statss: ArticleStatService,
              private _egalims: ArticleEgalimService,
              private _origines: ArticleOrigineService,
              private _ddocs: DetailDocumentService) {
    super(_list, _items, _msgs, routes);
    this.type = "catalog.families";
  }

  public async preNgOnInit(): Promise<void> {
    this.egalims$ = this._egalims.queryset;
    this.origines$ = this._origines.queryset;
    this.custom_fields = [
      {name: "doc_FT", verbose_name: "Fiches techniques"},
      {name: "doc_DL", verbose_name: "Documents structure"},
      {name: "doc_DE", verbose_name: "Documents label/certification"},
      {name: "doc_OT", verbose_name: "Autres documents"},
    ];
    if (this.stats) {
      await this.statss.setInterval();
      this.filter.tarifs_stats = 1;
      this.default_fields.push(`tarifs_stats`);
      for (const c of this.statss.defaultColumns) {
        this.stats_intervals.push(c.id);
        this.custom_fields.push({name: c.code, verbose_name: c.name});
        if (this.statss.column(c.id).active) {
          this.default_fields.push(c.code);
        }
      }
    }
  }

  public async postNgOnInit(): Promise<void> {
    await super.postNgOnInit();
    this.list.emitFields = true;
    if (this.stats) {
      this.statss.updateFields(this.list);
    }
    this.list.filter.output$.subscribe(async (f) => {
      if (f["stat_interval"] || f["stat_grouper"]) {
        await this.statss.setInterval(f["stat_interval"] as any, f["state_grouper"] as any);
        this.statss.updateFields(this.list);
      }
    });
  }


  public changePerlineSeasons(): void {
    const available = [2, 3, 4, 6, 12];
    const cur = available.indexOf(this.seasons_perline);
    this.seasons_perline = available[(cur + 1) % available.length];
  }

  public async downloadDocument(d: DetailDocument): Promise<void> {
    if (!d.download) {
      const doc = await firstValueFrom(this._ddocs.create(d, {save: false}));
      doc.download();
    }
  }

  public getDetailLink(model: ArticleFamily): (string | number)[] {
    if (this.articlefamily_detail_route) {
      return this.articlefamily_detail_route.route({articleFamily: model});
    }
    return this.routes.get(this.zone, this.type, model);
  }

  public async updateSeasons(af: ArticleFamily, seasons: number[]): Promise<any> {
    af.productseasons = seasons;
    await firstValueFrom(af.update(["productseasons"]));
    this._msgs.success("Saisonnalité changée avec succès");
  }


}
