import {Component} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {ArticleTarif, ArticleTarifService} from "../articletarif.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "lvadg-articletarif-list",
  templateUrl: "./articletarif-list.component.pug",
  styleUrls: ["./articletarif-list.component.sass"],
})
export class ArticletarifListComponent extends ModelListBaseComponent<ArticleTarif, ArticleTarifService> {

  constructor(
    _list: BsacModelListProvider,
    _items: ArticleTarifService,
    _msgs: BsacMessageService,
    routes: RoutesService,
    private _route: ActivatedRoute,
  ) {
    super(_list, _items, _msgs, routes);
  }


}
