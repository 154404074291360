import {Component, Input, OnInit} from "@angular/core";
import {Tarif} from "../../tarif/tarif.service";
import {TarifDocument, TarifDocumentService} from "../tarifdocument.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-tarifdocument-listmanage",
  templateUrl: "./tarifdocument-listmanage.component.pug",
  styleUrls: ["./tarifdocument-listmanage.component.sass"]
})
export class TarifdocumentListmanageComponent implements OnInit {
  @Input() public tarif!: Tarif;
  public documents: TarifDocument[] = [];

  constructor(private _td: TarifDocumentService) {
  }

  public async ngOnInit(): Promise<void> {
    this.documents = (await firstValueFrom(this._td.queryset.query({tarif: this.tarif.id}).get())).items;
  }
  public getUploadUrl(): string {
    if (!this.tarif || !this.tarif._collection) {
      return "";
    }
    return (this.tarif._collection.backend as any).getRequestUrl(
      (this.tarif._collection as any).adapter.detail_action(this.tarif, "add_document")
    );
  }
}
