import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import {CdkTableModule} from "@angular/cdk/table";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {CompsModule} from "../../../comps/comps.module";

import {ProductPriceListComponent} from "./productprice-list/productprice-list.component";

import {ProductPriceCreateComponent} from "./productprice-create/productprice-create.component";

import {ProductPriceManageComponent} from "./productprice-manage/productprice-manage.component";

import {ProductPriceDeleteComponent} from "./productprice-delete/productprice-delete.component";

import {ProductPriceSelectModule} from "./productprice-select.module";

@NgModule({
  imports: [
    CommonModule,
    ProductPriceSelectModule,
    CdkTableModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    
    CompsModule
  ],
  declarations: [
    ProductPriceListComponent,
    ProductPriceCreateComponent,
    ProductPriceManageComponent,
    ProductPriceDeleteComponent
  ],
  exports: [
    ProductPriceSelectModule,

    ProductPriceListComponent,
    ProductPriceCreateComponent,
    ProductPriceManageComponent,
    ProductPriceDeleteComponent
  ]
})
export class ProductPriceModule {}
