import {Component, Input, OnInit} from "@angular/core";

export interface OpLogItem {
  line: number;
  level: number;
  message: string;
  data: any;
}

export  type OpLog = OpLogItem[];


@Component({
  selector: "lvadg-oplog-display",
  templateUrl: "./oplog-display.component.pug",
  styleUrls: ["./oplog-display.component.sass"]
})
export class OplogDisplayComponent implements OnInit {
  @Input() public result?: OpLog;
  @Input() public minLog = 2;
  public LEVELS: {[index: number]: string} = {
    0: "DEBUG",
    1: "DETAILS FINS",
    2: "DETAILS",
    3: "INFO",
    4: "WARNING",
    5: "ERREUR",
    6: "CRITIQUE"
  }
  constructor() {
  }

  ngOnInit(): void {
  }

}
