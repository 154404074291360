import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import {CdkTableModule} from "@angular/cdk/table";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {CompsModule} from "../../../comps/comps.module";
import {ProductCommonModule} from "../common/product-common.module";
import {RouterModule} from "@angular/router";
import {ImageModule} from "../../content/image/image.module";

import {SeaProductFamilyListComponent} from "./seaproductfamily-list/seaproductfamily-list.component";

import {SeaProductFamilyCreateComponent} from "./seaproductfamily-create/seaproductfamily-create.component";

import {SeaProductFamilyManageComponent} from "./seaproductfamily-manage/seaproductfamily-manage.component";

import {SeaProductFamilyDeleteComponent} from "./seaproductfamily-delete/seaproductfamily-delete.component";

import {SeaProductFamilySelectModule} from "./seaproductfamily-select.module";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {SeaLabelModule} from "../sealabel/sealabel.module";

@NgModule({
  imports: [
    CommonModule,
    SeaProductFamilySelectModule,
    CdkTableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    CustomDataModule,
    SeaLabelModule,
    CompsModule,
    ProductCommonModule,
    RouterModule,
    ImageModule
  ],
  declarations: [
    SeaProductFamilyListComponent,
    SeaProductFamilyCreateComponent,
    SeaProductFamilyManageComponent,
    SeaProductFamilyDeleteComponent
  ],
  exports: [
    SeaProductFamilySelectModule,

    SeaProductFamilyListComponent,
    SeaProductFamilyCreateComponent,
    SeaProductFamilyManageComponent,
    SeaProductFamilyDeleteComponent
  ]
})
export class SeaProductFamilyModule {
}
