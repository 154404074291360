import {NgModule} from "@angular/core";
import {BillingStatsComponent} from "./billing-stats/billing-stats.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ChartsModule} from "../../../comps/charts/charts.module";
import {BillListComponent} from "./bill-list/bill-list.component";
import {NgStringPipesModule} from "ngx-pipes";
import {BillDetailComponent} from "./bill-detail/bill-detail.component";


@NgModule({
  declarations: [
    BillingStatsComponent,
    BillListComponent,
    BillDetailComponent],
  exports: [
    BillingStatsComponent,
    BillListComponent,
    BillDetailComponent],
  imports: [
    ...MODELLIST_IMPORTS,
    ChartsModule,
    NgStringPipesModule
  ]
})
export class BillModule {
}

export * from "./bill.resolve";
export * from "./bill.service";
