import {NgModule} from "@angular/core";
import {RnmListComponent} from "./rnm-list/rnm-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {RnmMatchComponent} from "./rnm-match/rnm-match.component";
import {RnmManageComponent} from "./rnm-manage/rnm-manage.component";
import {RnmArticleDetailsComponent} from "./rnm-article-details/rnm-article-details.component";
import {RnmGraphComponent} from "./rnm-graph/rnm-graph.component";
import {NgxEchartsModule} from "ngx-echarts";


@NgModule({
  declarations: [
    RnmListComponent,
    RnmMatchComponent,
    RnmManageComponent,
    RnmArticleDetailsComponent,
    RnmGraphComponent
  ],
  exports: [
    RnmListComponent,
    RnmMatchComponent,
    RnmManageComponent,
    RnmArticleDetailsComponent,
    RnmGraphComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    NgxEchartsModule
  ]
})
export class RnmModule {
}
