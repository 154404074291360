import {Component, OnInit} from "@angular/core";
import {ErrorService} from "../error.service";

@Component({
  selector: "lvadg-error",
  templateUrl: "./error.component.pug",
  styleUrls: ["./error.component.sass"]
})
export class ErrorComponent implements OnInit {

  constructor(public _err: ErrorService) {
  }

  ngOnInit() {
  }

}
