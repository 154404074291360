import {NgModule} from "@angular/core";
import {ImageDeleteComponent} from "./image-delete/image-delete.component";
import {ImageListComponent} from "./image-list/image-list.component";
import {ImageManageComponent} from "./image-manage/image-manage.component";
import {ImagesManageComponent} from "./images-manage/images-manage.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS
  ],
  declarations: [
    ImageDeleteComponent,
    ImageListComponent,
    ImageManageComponent,
    ImagesManageComponent
  ],
  exports: [
    ImageDeleteComponent,
    ImageListComponent,
    ImageManageComponent,
    ImagesManageComponent
  ],
})
export class ImageModule {
}
