import {NgModule} from "@angular/core";
import {TariftypeListComponent} from "./tariftype-list/tariftype-list.component";
import {TariftypeManageComponent} from "./tariftype-manage/tariftype-manage.component";
import {TariftypeCreateComponent} from "./tariftype-create/tariftype-create.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {QuillModule} from "ngx-quill";
import {NgStringPipesModule} from "ngx-pipes";
import {TariftypeClientlistComponent} from "./tariftype-clientlist/tariftype-clientlist.component";
import {TariftypeDisplayComponent} from "./tariftype-display/tariftype-display.component";
import {TariftypeUserlistComponent} from "./tariftype-userlist/tariftype-userlist.component";
import {ContactModule} from "../../people/contact/contact.module";
import {TariftyperelationModule} from "../tariftyperelation/tariftyperelation.module";


@NgModule({
  declarations: [
    TariftypeListComponent,
    TariftypeManageComponent,
    TariftypeCreateComponent,
    TariftypeClientlistComponent,
    TariftypeDisplayComponent,
    TariftypeUserlistComponent
  ],
  exports: [
    TariftypeListComponent,
    TariftypeManageComponent,
    TariftypeCreateComponent,
    TariftypeClientlistComponent,
    TariftypeDisplayComponent,
    TariftypeUserlistComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    QuillModule,
    ContactModule,
    NgStringPipesModule,
    TariftyperelationModule
  ]
})
export class TarifTypeModule {
}
