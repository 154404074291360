import {Component, Input, OnInit} from "@angular/core";
import {EChartsOption} from "echarts";

interface ScatterStat {
  name: string;
  label: string;
  type: "scatter";
  values: {
    datestart: Date;
    aprice: number;
    iprice: number;
  }[];
}

@Component({
  selector: "lvadg-articletarif-stats-scatter",
  templateUrl: "./articletarif-stats-scatter.component.pug",
  styleUrls: ["./articletarif-stats-scatter.component.sass"]
})
export class ArticletarifStatsScatterComponent implements OnInit {
  @Input() public stat!: ScatterStat;
  public initCfg = {locale: "FR", renderer: "svg"};

  public options!: EChartsOption;

  public ngOnInit(): void {
    this.options = {
      title: {
        text: this.stat.label,
        left: "center",
        top: 25,
        textStyle: {
          fontSize: 14
        }
      },
      tooltip: {
        trigger: "axis"
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
        name: "€/UV",
        // min: "dataMin",
        // max: "dataMax"
      },
      dataset: {
        dimensions: ["datestart", "mprice", "lprice", "iprice", "name"],
        source: this.stat.values
      },
      series: [
        {
          name: "Tarif moyen",
          type: "line",
          animation: false,
          encode: {y: "iprice", x: "datestart"}
        },
        {
          name: "Tarif min",
          type: "line",
          animation: false,
          showSymbol: false,
          encode: {y: "lprice", x: "datestart"}
        },
        {
          name: "Tarif max",
          type: "line",
          animation: false,
          showSymbol: false,
          encode: {y: "mprice", x: "datestart"}
        }]
    };
  }

}
