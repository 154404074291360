import {Component, HostListener, OnInit} from "@angular/core";
import {AnalyticsService, CurrentSiteService, DisplayinfosService} from "lvadg";
import {BsacLiveService} from "@solidev/bsadmincomponents";

@Component({
  selector: "lvadm-root",
  templateUrl: "./app.component.pug",
  styleUrls: ["./app.component.sass"]
})
export class AppComponent implements OnInit {
  title = "lvadg";

  constructor(public cursite: CurrentSiteService,
              private _displayInfos: DisplayinfosService,
              private _live: BsacLiveService,
              private _analytics: AnalyticsService) {

  }

  public ngOnInit(): void {
    // FIXME: hack for https://github.com/angular/angular/issues/20970
    // if (environment.production && "serviceWorker" in navigator) {
    //   navigator.serviceWorker.getRegistration()
    //     .then(active => !active && navigator.serviceWorker.register("/ngsw-worker.js"))
    //     .catch(console.error);
    // }
    this._analytics.enable();
    this._live.socket();
  }

  @HostListener("window:resize")
  public onResize() {
    // guard against resize before view is rendered
    this._displayInfos.checkWidth();
  }
}
