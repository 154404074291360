import {Product, ProductService} from "./product.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProductResolve implements Resolve<Product> {
  constructor(private _model: ProductService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Product> {
    return this._model.get(+route.params["productId"]);
  }
}

@Injectable({
  providedIn: "root"
})
export class ProductLocalResolve implements Resolve<Product> {
  constructor(private _model: ProductService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Product> {
    return this._model.get(+route.params["productId"], {query: {localstorage: 1}});
  }
}

