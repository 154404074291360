<!DOCTYPE html>
<ng-template #details let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Détails de la sélection</h4>
    <button class="btn-close" type="button" (click)="modal.dismiss('x')"></button>
  </div>
  <div class="modal-body">
    <div class="scroller">
      <div *ngFor="let s of sel.items(stype)"><i class="fa fa-trash me-4 pointer" (click)="sel.removeSelection(stype, s)"></i> {{s.name}}</div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-dark" type="button" (click)="modal.close('c')"><i class="fa fa-times me-2"></i>Fermer</button>
  </div>
</ng-template>
<div class="d-block" ngbDropdown>
  <button class="btn btn-link" id="btnsum" ngbDropdownToggle><span class="me-1" *ngIf="sel.count(stype)==0">Aucune sélection</span><span class="me-1" *ngIf="sel.count(stype)&gt;0">Sélection : {{sel.count(stype)}} {{unit}}</span></button>
  <div ngbDropdownMenu aria-labelledby="btnsum">
    <button class="dropdown-item" (click)="sel.clearSelection(stype)" *ngIf="checkAction('clear')"><i class="fa fa-trash me-2"></i>Vider la sélection</button>
    <button class="dropdown-item" (click)="showDetails(details)" *ngIf="checkAction('summary')"><i class="fa fa-search me-2"></i>Consulter / éditer</button>
    <button class="dropdown-item" (click)="print()" *ngIf="checkAction('print')"><i class="fa fa-print me-2"></i>Impression</button>
    <button class="dropdown-item" (click)="quickprint()" *ngIf="checkAction('quickprint')"><i class="fa fa-print me-2"></i>Impression rapide</button>
    <button class="dropdown-item" (click)="export()" *ngIf="checkAction('export')"><i class="fa fa-file-excel me-2"></i>Exportation</button>
    <ng-container *ngFor="let a of custom_actions">
      <button class="dropdown-item" (click)="custom(a)"><i class="fa me-2" [ngClass]="a.icon"></i>{{a.description}}</button>
    </ng-container>
  </div>
</div>