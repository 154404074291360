import {Component} from "@angular/core";
import {UpdateService} from "../update.service";

@Component({
  selector: "lvadg-update",
  templateUrl: "./update.component.pug",
  styleUrls: ["./update.component.sass"]
})
export class UpdateComponent {

  constructor(public update: UpdateService) {
  }


}
