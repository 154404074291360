import {Component, Input, OnInit} from "@angular/core";
import {Folder} from "../folder.service";
import {FolderTree} from "../folder-tree.service";

@Component({
  selector: "lvadg-folder-icon",
  templateUrl: "./folder-icon.component.pug",
  styleUrls: ["./folder-icon.component.sass"]
})
export class FolderIconComponent implements OnInit {
  @Input() public tree!: FolderTree;
  @Input() public folder!: Folder;
  @Input() public style = "default";

  constructor() {
  }

  ngOnInit() {
  }

}
