import {NgModule} from "@angular/core";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ArticleFamilyManageComponent} from "./article-family-manage/article-family-manage.component";
import {ImageModule} from "../../content/image/image.module";
import {CustomDataModule} from "../../customdata/customdata/customdata.module";
import {LabelModule} from "../../product/label/label.module";
import {BaseDocumentModule} from "../../document/basedocument/base-document.module";
import {ProductCommonModule} from "../../product/common/product-common.module";
import {ArticleFamilyListComponent} from "./article-family-list/article-family-list.component";


@NgModule({
  declarations: [
    ArticleFamilyListComponent,
    ArticleFamilyManageComponent],
  imports: [
    ...MODELLIST_IMPORTS,
    ImageModule,
    CustomDataModule,
    ProductCommonModule,
    LabelModule,
    BaseDocumentModule
  ],
  exports: [
    ArticleFamilyListComponent,
    ArticleFamilyManageComponent]
})
export class ArticleFamilyModule {
}
