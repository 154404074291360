import {Component, Input} from "@angular/core";
import {ModelListBaseComponent} from "../../../../../includes/components/modellist/component";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {ExternalArticleCollection, ExternalArticleCollectionService} from "../externalarticlecollection.service";
import {Storage} from "../../../structure/storage/storage.service";

@Component({
  selector: "lvadg-externalarticlecollection-list",
  templateUrl: "./externalarticlecollection-list.component.pug",
  styleUrls: ["./externalarticlecollection-list.component.sass"]
})
export class ExternalarticlecollectionListComponent
  extends ModelListBaseComponent<ExternalArticleCollection, ExternalArticleCollectionService> {
  @Input() public externalarticlecollection_detail_route!: RouteV3<ExternalArticleCollection>;
  @Input() public storage_detail_route!: RouteV3<Storage>;

  constructor(
    _list: BsacModelListProvider,
    _items: ExternalArticleCollectionService,
    _msgs: BsacMessageService,
    routes: RoutesService
  ) {
    super(_list, _items, _msgs, routes);
  }

  public getDetailLink(model: ExternalArticleCollection): (string | number)[] {
    return this.externalarticlecollection_detail_route.route({externalArticleCollection: model});
  }

  public getStorageLink(storage: Storage): (string | number)[] {
    return this.storage_detail_route.route({storage});
  }
}
