import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Article} from "../article.service";
import {RoutesService} from "../../../../comps/routes.service";
import {Client} from "../../../structure/client/client.service";
import {CiqualColumn} from "../../../customdata/ciqualcolumn/ciqualcolumn.service";

interface CiqualDetail {
  less: boolean;
  value: number;
  traces: boolean;
  column: number;
  column_details: CiqualColumn;
}

interface CiqualGroup {
  name: string;
  starred: boolean;
  stshort: boolean;
  values: CiqualDetail[];
}

@Component({
  selector: "lvadg-article-details",
  templateUrl: "./article-details.component.pug",
  styleUrls: ["./article-details.component.sass"]
})
export class ArticleDetailsComponent implements OnInit {
  @Input() public article!: Article;
  @Input() public client!: Client;
  @Output() public article_action = new EventEmitter<any>();

  public ciqualFull: boolean = false;
  private _ciqualGroup?: any;

  constructor(public routes: RoutesService) {
  }

  public get ciqualCategs(): CiqualGroup[] {
    if (this._ciqualGroup) {
      return this._ciqualGroup;
    }

    const categ: CiqualGroup[] = [];
    if (this.article && this.article.ciqual_alim_details && this.article.ciqual_alim_details.values_details) {
      for (const cc of this.article.ciqual_alim_details.values_details) {
        const cd = cc.column_details;
        let cg: CiqualGroup|undefined = undefined;
        for (const g of categ) {
          if (g.name === cd.group) {
            cg = g;
            break;
          }
        }
        if (!cg) {
          cg = {name: cd.group, starred: false, stshort: false, values: []};
          categ.push(cg);
        }
        cg.values.push(cc);
        if (cd.display === "ST") {
          cg.starred = true;
        } else if (cd.display === "SS") {
          cg.starred = true;
          cg.stshort = true;
        }
      }
    }
    this._ciqualGroup = categ;
    return this._ciqualGroup;
  }

  public ngOnInit() {
  }
}
