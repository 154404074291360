import {Component, Input, OnInit} from "@angular/core";
import {CustomDataKey} from "../../customdatakey/customdatakey.service";
import {BsacModelList} from "@solidev/bsadmincomponents";
import {IDSModel} from "@solidev/ngdataservice";

@Component({
  selector: "lvadg-customdata-modellist-filters",
  templateUrl: "./customdata-modellist-filters.component.pug",
  styleUrls: ["./customdata-modellist-filters.component.sass"]
})
export class CustomdataModellistFiltersComponent implements OnInit {
  @Input() public cdatakeys!: CustomDataKey[];
  @Input() public list!: BsacModelList<IDSModel>;
  @Input() public group = "properties";
  constructor() {
  }

  ngOnInit() {
  }

}
