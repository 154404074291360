import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {debounceTime, filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {IFieldParameter} from "../commfield.service";
import {CommFieldParamsService} from "../commfieldparams.service";

@Component({
  selector: "lvadg-commfield-charfield",
  templateUrl: "./commfield-charfield.component.pug",
  styleUrls: ["./commfield-charfield.component.sass"]
})
export class CommFieldCharFieldComponent implements OnInit {

  @Input() public params!: CommFieldParamsService;
  @Input() public part!: string;
  @Input() public name!: string;
  public _subscriptions = new Subject<void>();
  public param!: IFieldParameter;
  public form!: UntypedFormGroup;
  public txtform!: UntypedFormGroup;


  constructor() {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      "value": new UntypedFormControl(""),
    });
    this.txtform = new UntypedFormGroup({
      "svalue": new UntypedFormControl(""),
    });
    this.params.getByName(this.part, this.name)
      .pipe(
        takeUntil(this._subscriptions),
        filter((value) => !!value)
      )
      .subscribe((val) => {
        this.param = val as IFieldParameter;
        let curvalue = "";
        for (const o of this.param.choices) {
          if (o.current) {
            curvalue = o.value;
          }
          if (this.param.field_details && this.param.field_details.svalue) {
            this.form.setValue({value: curvalue}, {emitEvent: false});
            this.txtform.setValue({svalue: this.param.field_details.svalue}, {emitEvent: false});
          } else {
            this.form.setValue({value: curvalue}, {emitEvent: false});
            this.txtform.setValue({svalue: ""}, {emitEvent: false});
          }
        }
      });
    this.form.valueChanges
      .pipe(takeUntil(this._subscriptions),
        debounceTime(750))
      .subscribe(async () => {
        this.params.setByName(this.part, this.name, {value: this.form.value.value, svalue: this.txtform.value.svalue});
      });
  }

  public saveCustom(): void {
    this.params.setByName(this.part, this.name, {value: this.form.value.value, svalue: this.txtform.value.svalue});
  }

  public removeParam(): void {
    this.params.removeByName(this.part, this.name);
  }
}
