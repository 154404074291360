<!DOCTYPE html>
<ng-container *ngIf="mode=='default'">
  <div class="document pointer" (click)="tree.singleClick('document', document)" (dblclick)="tree.doubleClick('document', document)" [class.selected]="tree.selected.document?.id==document.id">
    <div class="docicon">
      <div class="icon">
        <ng-container *ngIf="document.image">
          <div class="img" lvadgRbg [rsrc]="document.image_img" rdisplay="gallery" rsize="thumb"></div>
        </ng-container>
        <ng-container *ngIf="!document.image"><i class="fal" [ngClass]="getFileType()"></i></ng-container>
      </div>
      <div class="title">{{document.title}}</div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="mode=='extonly'"><i class="fal" [ngClass]="getFileType()"></i></ng-container>