import {Component, Input, OnInit} from "@angular/core";
import {FichesData, FichesDataService} from "../fichesdata.service";
import {ArticleEgalimService} from "../../articledata/article-egalim.service";
import {ArticleOrigineService} from "../../articledata/article-origine.service";
import {ArticleCategorieService} from "../../articledata/article-categorie.service";
import {ArticleFamilyService} from "../../family/article-family.service";
import {Article, ArticleService} from "../../article/article.service";
import {firstValueFrom} from "rxjs";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: "lvadg-fichesdata-manage",
  templateUrl: "./fichesdata-manage.component.pug",
  styleUrls: ["./fichesdata-manage.component.sass"]
})
export class FichesDataManageComponent implements OnInit {
  @Input() public fd!: FichesData;
  public editorForm!: UntypedFormGroup;
  public articleFilter: any = {fields: "id,libelle"};
  public article!: Article;
  public articleFichesData!: FichesData[];
  public quillModules: any = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote", {"script": "sub"}, {"script": "super"}],
      [{"list": "ordered"}, {"list": "bullet"}],
      [{"color": []}, {"background": []}, "clean", "link"]
    ]
  };

  constructor(public families: ArticleFamilyService,
              public egalims: ArticleEgalimService,
              public origins: ArticleOrigineService,
              public articles: ArticleService,
              private fds: FichesDataService,
              private sanitizer: DomSanitizer,
              public categories: ArticleCategorieService) {
  }

  ngOnInit(): void {
    this.articleFilter.infamilies = this.fd.families.join(",");
    this.editorForm = new UntypedFormGroup({text: new UntypedFormControl(this.fd.text)});
  }

  public async articleSelected(article: Article) {
    console.log("Article selected", article);
    this.article = article;
    this.articleFichesData = [];
    this.articleFichesData = await firstValueFrom(this.fds.action(null, "article", {
      method: "GET",
      query: {article: this.article.id}
    }));
  }

  public async updatedFamily($event: any) {
    console.log("Updated family", $event);
    this.articleFilter.infamilies = $event.join(",");
    this.updated();
  }

  public async updated() {
    if (this.article) {
      this.articleSelected(this.article);
    }
  }

  public async saveText(): Promise<void> {
    this.fd.text = this.editorForm.value.text;
    await firstValueFrom(this.fd.update(["text"]));
    await this.updated();
  }

  public safe(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
