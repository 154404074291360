import {NgModule} from "@angular/core";
import {VIEWS_COMMON_COMMON_MODULES} from "../views-common-modules";
import {BillModule} from "../../../lib/models/sales/bill/bill.module";
import {BillListViewV3Component} from "./bill-list-view/bill-list-view.component";
import {BillDetailViewComponent} from "./bill-detail-view/bill-detail-view.component";
import {BillItemListViewV3Component} from "./billitem-list-view/billitem-list-view.component";
import {BillItemModule} from "../../../lib/models/sales/billitem/billitem.module";


@NgModule({
  exports: [],
  declarations: [
    BillListViewV3Component,
    BillDetailViewComponent,
    BillItemListViewV3Component
  ],
  imports: [
    ...VIEWS_COMMON_COMMON_MODULES,
    BillModule,
    BillItemModule
  ]
})
export class ViewsBillingModule {
}

export * from "./bill-list-view/bill-list-view.component";
export * from "./bill-detail-view/bill-detail-view.component";
export * from "./billitem-list-view/billitem-list-view.component";
