import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProductPrice} from "../_productprice/productprice-base.model";

export class SeaProductPrice extends BaseProductPrice {
}

@Injectable({
  providedIn: "root"
})
export class SeaProductPriceService extends DSRestCollection<SeaProductPrice> {
  public adapter_config = {basePath: "/api/v2/seaproductprices"};
  public model = SeaProductPrice;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
