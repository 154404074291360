import {Inject, Injectable} from "@angular/core";
import {ENVIRONMENT, IEnvironment} from "../../../../front/env.service";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {AuthService} from "../../user/auth.service";

@Injectable({
  providedIn: "root"
})
export class FeaturesService {

  private _features: IFeatures = {};

  constructor(@Inject(ENVIRONMENT) public env: IEnvironment,
              private _http: HttpClient,
              private _auth: AuthService) {
  }

  public isEnabled(feature: string): boolean {
    return !!this._features[feature];
  }

  public load() {
    if (this._auth && this._auth.token && this._auth.token.token) {
      return this._http.get<IFeatures>(this.env.API_URL + "/api/v2/features", {headers: {"x-token": this._auth.token.token}})
        .pipe(tap((feat) => this._features = feat));
    }
    return this._http.get<IFeatures>(this.env.API_URL + "/api/v2/features")
      .pipe(tap((feat) => this._features = feat));

  }
}

export interface IFeatures {
  [index: string]: boolean;
}
