import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class OfferText extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: true,
    helpText: "",
    choices: [
      ["INOUTC", "Entrées sorties de cotations"],
      ["NFOMRC", "Infos marché"],
      ["OPNFUT", "Opérations nationales à venir"],
      ["OPLFUT", "Opérations locales à venir"],
      ["OPNCUR", "Opérations nationales en cours"],
      ["OPLCUR", "Opérations locales en cours"],
      ["LBLBIO", "Bio"]
    ],
    minLength: 3,
    maxLength: 10
  })
  public type!: string;

  @DSCharField({
    name: "zone",
    verboseName: "Zone",
    required: true,
    helpText: "Zone d'affichage",
    minLength: 0,
    maxLength: 4,
    choices: [
      ["FL", "Fruits et légumes"],
      ["MR", "Marée"],
      ["FLMR", "Fruits & légumes & marée"]
    ]
  })
  public zone!: string;


  @DSTextField({
    name: "content_src",
    verboseName: "Texte (source)",
    required: false,
    helpText: "",
    choices: [],
    minLength: 3,
    maxLength: 2000
  })
  public content_src!: string;

  @DSTextField({
    name: "content",
    verboseName: "Texte (html)",
    required: false,
    helpText: "",
    choices: [],
    minLength: 3,
    maxLength: 2000
  })
  public content!: string;

  @DSCharField({
    name: "title",
    verboseName: "Titre",
    required: true,
    helpText: "",
    choices: [],
    minLength: 3,
    maxLength: 255
  })
  public title!: string;

  @DSForeignKeyField({
    name: "client",
    verboseName: "Client",
    required: false,
    helpText: "",
    choices: []
  })
  public client!: number;

  @DSDateTimeField({
    name: "datestart",
    verboseName: "Date de début",
    required: true,
    helpText: "",
    choices: []
  })
  public datestart!: Date;

  @DSDateTimeField({
    name: "dateend",
    verboseName: "Date de fin",
    required: true,
    helpText: "",
    choices: []
  })
  public dateend!: Date;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    verboseName: "",
    required: false,
    helpText: "",
    choices: [["PRP", "En préparation"], ["CUR", "En cours"], ["ARC", "Archivée"]],
    maxLength: 4
  })
  public status!: string;
}

@Injectable({
  providedIn: "root"
})
export class OfferTextService extends DSRestCollection<OfferText> {
  public adapter_config = {basePath: "/api/v2/offertexts"};
  public model = OfferText;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
