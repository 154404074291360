
<form *ngIf="offer" [formGroup]="form">
  <div class="row">
    <div class="col-lg-4 d-none d-lg-block">
      <p class="text-info">Le clonage de l'offre recopie les produits et les paramètres d'impression</p>
      <p class="text-info">Si le client est identique, les nomenclatures & tarifs sont aussi copiés dans la mesure du possible</p>
    </div>
    <div class="col-lg-8">
      <div class="row">
        <div class="col-sm-4">
          <bsac-field-control [model]="offer" field="title" [form]="form"><span class="label">Titre de l'offre locale</span></bsac-field-control>
        </div>
        <div class="col-sm-8">
          <lvadg-daterangepicker [start]="offer.datestart" [end]="offer.dateend" (selected)="setDateRange($event)"></lvadg-daterangepicker>
        </div>
        <div class="col-sm-4" *ngIf="form.value.storage">
          <bsac-field-control [model]="offer" field="client" [form]="form" [related]="clients$" [relatedFilter]="clientFilter" [relatedReset]="clientReset$" [descfield]="'name'"><span class="label">Client</span></bsac-field-control>
        </div>
        <div class="col-sm-4" *ngIf="form.value.storage">
          <div class="text-info">ou créer une offre générique</div>
          <button class="btn btn-link btn-block w-100 ms-3" (click)="makeGeneric()">Offre locale générique</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3" *ngIf="!(result?.success)">
    <div class="col-8">
      <button class="btn btn-primary btn-block w-100" (click)="clone()" [disabled]="!form.valid"><i class="fa fa-copy me-2"></i>Cloner</button>
    </div>
    <div class="col-4">
      <button class="btn btn-secondary btn-block w-100" (click)="cancel()"><i class="fa fa-times me-2"></i>Annuler</button>
    </div>
  </div>
</form>
<div class="mt-3" *ngIf="result">
  <div class="alert alert-success" *ngIf="result.success">
    <h3>Offre locale clonée avec succès</h3>
    <ul>
      <li *ngFor="let m of result.messages">{{m}}</li>
    </ul>
    <button class="btn btn-success" (click)="cloned.emit(result.result)">Voir l'offre clonée</button>
  </div>
  <div class="alert alert-danger" *ngIf="!result.success">
    <h3>Echec du clonage de l'offre locale</h3>
    <p>Merci de corriger les données ci-dessus</p>
    <ul>
      <li *ngFor="let m of result.messages">{{m}}</li>
    </ul>
  </div>
</div>