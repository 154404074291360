import {Component, OnDestroy, OnInit} from "@angular/core";

import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {GeoCommuneService} from "../../../customdata/geocommune/geocommune.service";
import {SeaProducer, SeaProducerService} from "../seaproducer.service";
import {SeaProductFamily, SeaProductFamilyService} from "../../../product/seaproductfamily/seaproductfamily.service";
import {MemberService} from "../../member/member.service";
import {SeaLabel, SeaLabelService} from "../../../product/sealabel/sealabel.service";
import {SeaProduct} from "../../../product/seaproduct/seaproduct.service";
import {GeoDepartementService} from "../../../customdata/geodepartement/geodepartement.service";
import {SLabelService} from "../../slabel/slabel.service";
import {GeoRegionService} from "../../../customdata/georegion/georegion.service";
import {StorageService} from "../../storage/storage.service";
import {BaseProducerListComponent} from "../../_producer/producer-list-base/producer-list-base.component";
import {RoutesService} from "../../../../comps/routes.service";
import {CustomDataKeyService} from "../../../customdata/customdatakey/customdatakey.service";
import {DistanceService} from "../../location/distance.service";


@Component({
  selector: "lvadg-seaproducer-list",
  templateUrl: "./seaproducer-list.component.pug",
  styleUrls: ["./seaproducer-list.component.sass"]
})
export class SeaProducerListComponent extends BaseProducerListComponent<SeaProducer, SeaProducerService,
  SeaProduct, SeaLabel, SeaLabelService, SeaProductFamily, SeaProductFamilyService> implements OnInit, OnDestroy {

  constructor(_list: BsacModelListProvider,
              _items: SeaProducerService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              _storages: StorageService,
              _members: MemberService,
              _families: SeaProductFamilyService,
              _regions: GeoRegionService,
              _departements: GeoDepartementService,
              _labels: SeaLabelService,
              _slabels: SLabelService,
              _communes: GeoCommuneService,
              _distances: DistanceService,
              _customdatakeys: CustomDataKeyService) {
    super(_list, _items, _msgs, routes,
      _storages, _members, _families, _regions,
      _departements, _labels, _slabels, _communes,
      _distances, _customdatakeys);
    this.zone = "sea";
  }
}


