import {Component, Input, OnInit} from "@angular/core";
import {Article, ArticleService} from "../article.service";
import {ArticleMarqueService} from "../../articledata/article-marque.service";
import {ArticleAllergeneService} from "../../articledata/article-allergene.service";
import {ArticleCalibreService} from "../../articledata/article-calibre.service";
import {ArticleCategorieService} from "../../articledata/article-categorie.service";
import {ArticleClientService} from "../../articledata/article-client.service";
import {ArticleEgalimService} from "../../articledata/article-egalim.service";
import {ArticleOrigineService} from "../../articledata/article-origine.service";
import {ArticleParticulariteService} from "../../articledata/article-particularite.service";
import {ArticlePresentationService} from "../../articledata/article-presentation.service";
import {ArticleProcessService} from "../../articledata/article-process.service";
import {ArticleProductionService} from "../../articledata/article-production.service";
import {ArticleRegionService} from "../../articledata/article-region.service";
import {ArticleUsageService} from "../../articledata/article-usage.service";
import {ArticleConditionnementService} from "../../articledata/article-conditionnement.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {ArticleTraitementService} from "../../articledata/article-traitement.service";
import {ProviderService} from "../../../structure/provider/provider.service";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {firstValueFrom} from "rxjs";
import {ArticleFamily, ArticleFamilyService} from "../../family/article-family.service";

@Component({
  selector: "lvadg-article-manage",
  templateUrl: "./article-manage.component.pug",
  styleUrls: ["./article-manage.component.sass"]
})
export class ArticleManageComponent implements OnInit {
  @Input() public article!: Article;
  @Input() public articleFamilyZone!: string;
  @Input() public articleFamilyType!: string;
  @Input() public articlefamily_detail_route!: RouteV3<ArticleFamily>;

  constructor(
    public marque$: ArticleMarqueService,
    public allergene$: ArticleAllergeneService,
    public calibre$: ArticleCalibreService,
    public categorie$: ArticleCategorieService,
    public aclient$: ArticleClientService,
    public egalim$: ArticleEgalimService,
    public origine$: ArticleOrigineService,
    public particularite$: ArticleParticulariteService,
    public presentation$: ArticlePresentationService,
    public process$: ArticleProcessService,
    public production$: ArticleProductionService,
    public region$: ArticleRegionService,
    public usage$: ArticleUsageService,
    public conditionnement$: ArticleConditionnementService,
    public traitement$: ArticleTraitementService,
    public articles$: ArticleService,
    public family$: ArticleFamilyService,
    public provider$: ProviderService,
    private _msg: BsacMessageService,
    public routes: RoutesService
  ) {
  }

  ngOnInit(): void {
  }

  public updateSeasons(seasons: number[]): void {
    this.article.seasons = seasons;
    this.article.update(["seasons"]).subscribe(() => {
      this.article.refresh({query: {psall: 1}}).subscribe(() => {
        this._msg.success("Saisonnalité modifiée avec succès");
      });
    });
  }

  public getUploadUrl(): string {
    if (!this.article) {
      return "";
    }
    return (this.articles$.backend as any).getRequestUrl(
      (this.articles$ as any).adapter.detail_action(this.article, "add_document")
    );
  }

  public async onUploaded(event: any): Promise<void> {
    await firstValueFrom(this.article.refresh());
  }

  public async onDocumentDeleted() {
    this._msg.success("Document supprimé avec succès");
    await firstValueFrom(this.article.refresh());
  }

  public getFamilyName(obj: any) {
    if (obj.cfullname) {
      return obj.cfullname;
    }
    if (Array.isArray(obj)) {
      return obj[obj.length - 1].cfullname;
    }
    return "Not available";
  }

  public async genLibelle() {
    await firstValueFrom(this.article.update(["libelle_auto"]));
  }

  public async useAutoLibelle() {
    this.article.libelle = this.article.libelle_auto
    await firstValueFrom(this.article.update(["libelle"]));
  }
}
