import {Injectable} from "@angular/core";
import {interval} from "rxjs";
import {filter, switchMap} from "rxjs/operators";
import {LocationService} from "./location.service";

export interface IDistanceCacheItem {
  lat1: number;
  lng1: number;
  lat2: number;
  lng2: number;
  direct: number;
  road: number;
  through?: IDistanceCacheItem[];
}

function getDistanceFromLatLon(lat1: number, lon1: number, lat2: number, lon2: number): number {

  function deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
  ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d * 1000;
}


@Injectable({
  providedIn: "root"
})
export class DistanceService {
  private _localCache: IDistanceCacheItem[] = [];
  private _roadMissing: IDistanceCacheItem[] = [];

  constructor(public _location: LocationService) {
    interval(500)
      .pipe(
        filter(() => {
          return this._roadMissing.length > 0;
        }),
        switchMap(() => {
            const out = this._location.action(null, "get_distances",
              {method: "POST", body: {distances: this._roadMissing}});
            this._roadMissing = [];
            return out;
          }
        )
      )
      .subscribe((result) => {
        for (const d of result.distances) {
          const c = this._findCache(d.lat1, d.lng1, d.lat2, d.lng2);
          if (c !== null) {
            c.direct = d.direct;
            c.road = d.road;
          }
        }
      });
  }

  public addDistance(lat1: number, lng1: number, lat2: number, lng2: number): IDistanceCacheItem {
    const lc = this._findCache(lat1, lng1, lat2, lng2);
    if (lc !== null) {
      return lc;
    } else {
      const m = {
        lat1: lat1,
        lng1: lng1,
        lat2: lat2,
        lng2: lng2,
        direct: getDistanceFromLatLon(lat1, lng1, lat2, lng2),
        road: -1,
      };
      this._localCache.push(m);
      this._roadMissing.push(m);
      return m;
    }
  }


  public getDistance(lat1: number, lng1: number, lat2: number, lng2: number): IDistanceCacheItem | null {
    if (lat1 === undefined || lat2 === undefined || lng1 === undefined || lng2 === undefined) {
      return null;
    }
    return this._findCache(lat1, lng1, lat2, lng2);
  }

  private _findCache(ilat1: number, ilng1: number, ilat2: number, ilng2: number): IDistanceCacheItem | null {
    for (const lc of this._localCache) {
      if (lc.lat1 === ilat1 && lc.lng1 === ilng1 && lc.lat2 === ilat2 && lc.lng2 === ilng2) {
        return lc;
      }
    }
    return null;
  }


}
