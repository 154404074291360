import {Inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {DS_REST_BACKEND_CONFIG, IDSRestBackendConfig} from "@solidev/ngdataservice";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../user/auth.service";
import {StatsFile} from "../statsfile/statsfile.service";

export interface Stat {
  id: number;
  type: string;
  name: string;
  label: string;
  member: number;
  storage: number;
  producer: number;
  client: number;
  params: string;
  rawvalue: string;
  ivalue: number;
  fvalue: number;
  created: Date;
  updated: Date;
  ttl: any;
  expire: Date;
  latest: boolean;
}

@Injectable({
  providedIn: "root"
})
export class StatsService {

  constructor(
    @Inject(DS_REST_BACKEND_CONFIG) public backendConfig: IDSRestBackendConfig,
    public _http: HttpClient,
    private _auth: AuthService
  ) {
  }

  public latestByNames(names: string[] = [], params: any = {}): Observable<Stat[]> {
    return this._http.get<Stat[]>(this.backendConfig.url + "/api/v2/stats",
      {
        headers: {
          "x-token": this._auth.token?.token || "-"
        },
        params: {
          stats: names.join(","),
          latest: "1",
          params: params
        }
      });
  }

  public export(export_action: string): Observable<StatsFile> {
    return this._http.get<StatsFile>(this.backendConfig.url + "/api/v2/exports",
      {
        headers: {
          "x-token": this._auth.token?.token || "-"
        },
        params: {
          export: export_action,
        }
      });
  }
}
