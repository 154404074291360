import {Injectable} from "@angular/core";
import {DSAutoField, DSCharField, DSModel, DSRestCollection, DSRestCollectionSetupProvider, DSSlugField} from "@solidev/ngdataservice";


export class ArticleUsage extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;
  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 2,
    choices: []
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;
}

@Injectable({
  providedIn: "root"
})
export class ArticleUsageService extends DSRestCollection<ArticleUsage> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/usages"};
  public model = ArticleUsage;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
