import {Component, Input, OnInit} from "@angular/core";
import {Tarif, TarifService} from "../tarif.service";
import {TarifDocument, TarifDocumentService} from "../../tarifdocument/tarifdocument.service";
import {firstValueFrom, ReplaySubject} from "rxjs";
import {TarifType, TarifTypeService} from "../../tariftype/tariftype.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {TarifExternalMap, TarifExternalMapService} from "../../tarifexternalmap/tarifexternalmap.service";
import {ExternalArticleService} from "../../../catalog/externalarticle/externalarticle.service";

@Component({
  selector: "lvadg-tarif-importdoc",
  templateUrl: "./tarif-importdoc.component.pug",
  styleUrls: ["./tarif-importdoc.component.sass"]
})
export class TarifImportdocComponent implements OnInit {


  @Input() public tarif!: Tarif;
  public updateMapping = new ReplaySubject<any>(1);
  public documents: TarifDocument[] = [];
  public curdoc!: TarifDocument;
  public curparams!: any;
  public mapName: string = "";
  public tarifType!: TarifType;
  public params!: any[];
  public curLine!: TarifExternalMap;
  public fields = [
    {name: "code", desc: "Code structure (client)"},
    {name: "icode", desc: "Code interne / autre code"},
    {name: "ean13", desc: "EAN13"},
    {name: "gamme", desc: "Gamme / classif niveau 1"},
    {name: "gammed", desc: "Gamme (multiligne)"},
    {name: "sgamme", desc: "Famille / classif niveau 2"},
    {name: "marque", desc: "Marque"},
    {name: "libelle", desc: "Libellé (*)"},
    {name: "categorie", desc: "Catégorie"},
    {name: "calibre", desc: "Calibre"},
    {name: "origine", desc: "Origine"},
    {name: "prix", desc: "Prix (*)"},
    {name: "uf", desc: "Unité facturation"},
    {name: "uf_value", desc: "Unité facturation (valeur)"},
    {name: "uf_unit", desc: "Unité facturation (unité)"},
    {name: "uc", desc: "Unité commande"},
    {name: "uc_value", desc: "Unité commande (valeur)"},
    {name: "uc_unit", desc: "Unité commande (unité)"},
    {name: "pe", desc: "Poids égoutté"},
    {name: "pe_value", desc: "Poids égoutté (valeur)"},
    {name: "pe_unit", desc: "Poids égoutté (unité)"},
    {name: "ucuf", desc: "Ratio UC/UF"},
    {name: "ucond", desc: "Unité conditionnement"},
    {name: "ucond_value", desc: "Unité conditionnement (valeur)"},
    {name: "ucond_unit", desc: "Unité conditionnement (unité)"},
    {name: "nego_unit", desc: "Unité de négociation (unité)"}
  ];
  public defaults = {};
  public step = 0;
  public default_fields = ["code", "libelle", "origine", "calibre", "categorie", "egalim", "udf_unit", "actions"];
  public default_filters = ["search", "pname", "infamily"];
  public actions = ["addtotarif"];
  public curmap!: TarifExternalMap[];
  private _artSearchInstance: NgbModalRef;
  public curmapFoundFilter = false;
  public result!: any[];

  constructor(private _td: TarifDocumentService,
              private _ta: TarifService,
              private _exa: ExternalArticleService,
              private _tmap: TarifExternalMapService,
              private _modal: NgbModal,
              private _tt: TarifTypeService,
              private _msgs: BsacMessageService) {
  }

  public async ngOnInit(): Promise<void> {
    this.tarifType = (await firstValueFrom(this._tt.get(this.tarif.type)));
    // Try to get parameters from model
    const params = await firstValueFrom(this.tarifType.action("xlsx_params", {method: "GET"}));
    this.curmap = (await firstValueFrom(this._tmap.queryset.query({tarif: this.tarif.id, page_size: 10000}).get())).items;
    this.params = [];
    for (const p of Object.keys(params)) {
      this.params.push({name: p, params: params[p]});
    }

    this.documents = (await firstValueFrom(this._td.queryset.query({tarif: this.tarif.id}).get())).items;
    let nbxlsx = 0;
    for (const d of this.documents) {
      if (d.mimetype === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.curdoc = d;
        nbxlsx += 1;
      }
    }
    if (nbxlsx !== 1) {
      return;
    }
    this.setCurdoc(this.curdoc);
  }

  public async onMapped() {
    this.result = await firstValueFrom(this.curdoc.action("xlsx_parse", {method: "POST"}));
    await firstValueFrom(this.curdoc.refresh());
    this.curmap = (await firstValueFrom(this._tmap.queryset.query({tarif: this.tarif.id, page_size: 10000}).get())).items;
  }

  public async validateTarif() {
    const res = await firstValueFrom(this.curdoc.action("xlsx_import", {method: "POST"}));
    await firstValueFrom(this.curdoc.refresh());
    console.log("Validated", res);
  }


  public async saveMapping() {
    const params = await firstValueFrom(this.tarifType.action("xlsx_params", {
      method: "POST",
      body: {
        name: this.mapName,
        params: this.curdoc.parameters
      }
    }));
  }

  public isXlsx(d: TarifDocument): boolean {
    return d.mimetype === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }

  public async setCurdoc(d: TarifDocument) {
    this.curdoc = d;
    this.step = 1;
    delete this.curparams;
    if (this.isXlsx(this.curdoc)) {
      await firstValueFrom(this.curdoc.action("xlsx_headers", {method: "GET", query: {lines: 8, skip: 0}}));
      this.step = 2;
    }
  }

  public useParams(p: any) {
    this.curparams = p;
    this.curdoc.parameters = p.params;
    this.updateMapping.next(p.params);
  }

  public async createXlsx() {
    const res = await firstValueFrom(this.curdoc.action("xlsx_gen", {method: "POST"}));
    this.documents = (await firstValueFrom(this._td.queryset.query({tarif: this.tarif.id}).get())).items;
  }

}
