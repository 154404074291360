
<div class="folder-detail">
  <div class="folder-detail-content" *ngIf="!tree.display.aslist">
    <div class="nfo text-muted">Dossiers</div>
    <div class="navicons">
      <div class="navicon" *ngFor="let f of subfolders">
        <lvadg-folder-icon [tree]="tree" [folder]="f"></lvadg-folder-icon>
      </div>
    </div>
    <div class="mt-2 nfo text-muted">Documents</div>
    <div class="navicons">
      <div class="navicon" *ngFor="let d of documents">
        <lvadg-document-icon [tree]="tree" [document]="d"></lvadg-document-icon>
      </div>
    </div>
    <ng-container *ngIf="current &amp;&amp; mode=='admin'">
      <div class="create mt-4">
        <lvadg-upload-file [url]="getUploadUrl()" [data]="{folders: [current.id]}" [multiple]="false" [auto]="true" [fieldName]="'file'" [mimetypes]="[]" (uploaded)="onUploaded($event)"></lvadg-upload-file>
      </div>
    </ng-container>
  </div>
  <div class="folder-detail-content" *ngIf="tree.display.aslist">
    <div class="nfo text-muted">Dossiers</div>
    <table class="table table-sm table-striped table-bordered">
      <ng-container *ngFor="let f of subfolders">
        <lvadg-folder-icon [tree]="tree" [folder]="f" style="list"></lvadg-folder-icon>
      </ng-container>
    </table>
    <div class="mt-2 nfo text-muted">Documents</div>
    <table class="table table-sm table-striped table-bordered">
      <ng-container *ngFor="let d of documents">
        <lvadg-document-icon [tree]="tree" [document]="d" mode="default"></lvadg-document-icon>
      </ng-container>
    </table>
    <ng-container *ngIf="current &amp;&amp; mode=='admin'">
      <div class="create mt-4">
        <lvadg-upload-file [url]="getUploadUrl()" [data]="{folders: [current.id]}" [multiple]="false" [auto]="true" [fieldName]="'file'" [mimetypes]="[]" (uploaded)="onUploaded($event)"></lvadg-upload-file>
      </div>
    </ng-container>
  </div>
  <div class="folder-detail-properties" *ngIf="tree.display.properties">
    <lvadg-folder-properties *ngIf="tree.selected?.type=='folder'" [folder]="tree.selected.folder" [tree]="tree" (closed)="propsClosed()" [mode]="mode"></lvadg-folder-properties>
    <lvadg-document-properties *ngIf="tree.selected?.type=='document'" [document]="tree.selected.document" [tree]="tree" (closed)="propsClosed()" [mode]="mode"></lvadg-document-properties>
  </div>
</div>