
<div class="row">
  <div class="col-md-6">
    <ng-container *ngIf="embedded">
      <lvadg-confirm-delete type="button" [model]="location" (deleted)="onDelete()"><i class="fa fa-trash"></i>Supprimer ?</lvadg-confirm-delete>
    </ng-container>
    <h3>Adresse postale</h3>
    <dl class="row" *ngIf="location">
      <div class="col-sm-12">
        <bsac-dd-field [model]="location" field="type" type="auto">Type</bsac-dd-field>
      </div>
      <div class="col-sm-12">
        <bsac-dd-field [model]="location" field="locname" type="auto">Nom</bsac-dd-field>
      </div>
      <div class="col-sm-6">
        <bsac-dd-field [model]="location" field="address" type="textarea">Adresse</bsac-dd-field>
      </div>
      <div class="col-sm-6">
        <bsac-dd-field [model]="location" field="caddress" type="textarea">Complément d'adresse</bsac-dd-field>
      </div>
      <ng-container *ngIf="embedded">
        <div class="col-sm-4">
          <bsac-dd-field [model]="location" field="postcode" type="auto">Code postal</bsac-dd-field>
        </div>
        <div class="col-sm-4">
          <bsac-dd-field [model]="location" field="city" type="auto">Ville</bsac-dd-field>
        </div>
      </ng-container>
      <ng-container *ngIf="!embedded">
        <div class="col-sm-4">
          <dt class="pointer" (click)="editPostCode=!editPostCode">Code postal</dt>
          <dd class="pointer" (click)="editPostCode=!editPostCode" *ngIf="!editPostCode">{{location.postcode}}</dd>
          <dd *ngIf="editPostCode">
            <lvadg-geocommune-select (selected)="setCommune($event)" (cancel)="editPostCode=false"></lvadg-geocommune-select>
          </dd>
        </div>
        <div class="col-sm-4">
          <dt class="pointer" (click)="editCity=!editCity">Ville</dt>
          <dd class="pointer" (click)="editCity=!editCity" *ngIf="!editCity">{{location.city}}</dd>
          <dd *ngIf="editCity">
            <lvadg-geocommune-select (selected)="setCommune($event)" (cancel)="editPostCode=false"></lvadg-geocommune-select>
          </dd>
        </div>
      </ng-container>
      <div class="col-sm-4">
        <bsac-dd-field [model]="location" field="cedex" type="auto">CEDEX</bsac-dd-field>
      </div>
    </dl>
    <h3>Contact</h3>
    <dl class="row" *ngIf="location">
      <div class="col-sm-4">
        <bsac-dd-field [model]="location" field="phone" type="auto">Téléphone</bsac-dd-field>
      </div>
      <div class="col-sm-4">
        <bsac-dd-field [model]="location" field="fax" type="auto">Fax</bsac-dd-field>
      </div>
      <div class="col-sm-4">
        <bsac-dd-field [model]="location" field="website" type="auto">Site internet</bsac-dd-field>
      </div>
    </dl>
    <button class="btn btn-block w-100 btn-primary" (click)="closed.emit(true)" *ngIf="embedded">Fermer</button>
  </div>
  <div class="col-md-6">
    <ng-container *ngIf="geofailure">
      <p class="text-danger">La géolocalisation n'a pas pu être effectuée, merci de vérifier les données entrées.</p>
      <button class="btn btn-primary" (click)="geoLocate()"><i class="fa fa-map-marker-alt me-2"></i>Géolocaliser à nouveau</button>
    </ng-container>
    <ng-container *ngIf="!geofailure &amp;&amp; geoloc">
      <div class="row">
        <div class="col-sm-6">
          <button class="btn btn-outline-primary btn-block w-100" (click)="geoLocate()"><i class="fa fa-map-marker-alt me-2"></i>Géolocaliser</button>
        </div>
        <div class="col-sm-6">
          <p class="text-success" *ngIf="geoloc.properties.type=='housenumber'">Géolocalisation précise à quelques mètres près, merci de vérifier / corriger ci dessous si nécessaire.</p>
          <p class="text-warning" *ngIf="geoloc.properties.type=='street'">Géolocalisation approximative à la rue près, merci de vérifier / corriger ci dessous.</p>
          <p class="text-warning" *ngIf="geoloc.properties.type=='city'">Géolocalisation approximative à la ville près, merci de vérifier / corriger ci dessous.</p>
        </div>
      </div>
      <lvadg-locations-osm-display [marker]="location" [boundary]="'auto'" (markerUpdated)="manualPosition($event)"></lvadg-locations-osm-display>
      <button class="btn btn-block w-100 btn-primary" *ngIf="geochanged" (click)="savePosition()"><i class="fa fa-save me-2"></i>Enregistrer la position</button>
    </ng-container>
  </div>
</div>