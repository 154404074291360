import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Group, GroupService} from "./group.service";

@Injectable({providedIn: "root"})
export class GroupResolve implements Resolve<Group> {
  constructor(private _bs: GroupService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<Group> {
    return this._bs.get(+route.params["groupId"]);
  }
}
