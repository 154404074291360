import {NgModule} from "@angular/core";
import {RnmtarifListComponent} from "./rnmtarif-list/rnmtarif-list.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {RnmModule} from "../rnm/rnm.module";


@NgModule({
  declarations: [
    RnmtarifListComponent
  ],
  exports: [
    RnmtarifListComponent
  ],

  imports: [
    RnmModule,
    ...MODELLIST_IMPORTS
  ]
})
export class RnmtarifModule {
}
