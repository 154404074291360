import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoragePrintComponent} from "./storage-print/storage-print.component";
import {ModelPrintModule} from "../modelprint/modelprint.module";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ProductPrintComponent} from "./product-print/product-print.component";
import {ProductposterPrintComponent} from "./productposter-print/productposter-print.component";
import {ProducerPrintComponent} from "./producer-print/producer-print.component";
import {ProducerposterPrintComponent} from "./producerposter-print/producerposter-print.component";
import {StorageProducersPrintComponent} from "./storageproducers-print/storageproducers-print.component";
import {ModelprintManageComponent} from "./modelprint-manage/modelprint-manage.component";
import {SeaProducerPrintComponent} from "./seaproducer-print/producer-print.component";
import {SeaProductposterPrintComponent} from "./seaproductposter-print/productposter-print.component";
import {SeaProductPrintComponent} from "./seaproduct-print/product-print.component";
import {SeaProducerposterPrintComponent} from "./seaproducerposter-print/producerposter-print.component";
import {SeaStoragePrintComponent} from "./seastorage-print/storage-print.component";
import {SeaStorageProducersPrintComponent} from "./seastorageproducers-print/storageproducers-print.component";
import {ProductlistPrintComponent} from "./productlist-print/productlist-print.component";
import {ModelCommModule} from "../../basecomm/modelcomm/modelcomm.module";
import {CommFieldModule} from "../../basecomm/commfield/commfield.module";
import {ArticlePrintComponent} from "./article-print/article-print.component";
import {ArticlelistPrintComponent} from "./articlelist-print/articlelist-print.component";
import {ProductV2PrintComponent} from "./productv2-print/productv2-print.component";
import {ProducerV2PrintComponent} from "./producerv2-print/producerv2-print.component";

@NgModule({
  declarations: [
    StorageProducersPrintComponent,
    StoragePrintComponent,
    ProductPrintComponent,
    ProductV2PrintComponent,
    ProductposterPrintComponent,
    ProductlistPrintComponent,
    ProducerPrintComponent,
    ProducerV2PrintComponent,
    ProducerposterPrintComponent,
    SeaStorageProducersPrintComponent,
    SeaStoragePrintComponent,
    SeaProductPrintComponent,
    SeaProductposterPrintComponent,
    SeaProducerPrintComponent,
    SeaProducerposterPrintComponent,
    ArticlePrintComponent,
    ArticlelistPrintComponent,

    ModelprintManageComponent,
  ],
  exports: [
    StorageProducersPrintComponent,
    StoragePrintComponent,
    ProductPrintComponent,
    ProductV2PrintComponent,
    ProductposterPrintComponent,
    ProductlistPrintComponent,
    ProducerPrintComponent,
    ProducerV2PrintComponent,
    ProducerposterPrintComponent,
    SeaStorageProducersPrintComponent,
    SeaStoragePrintComponent,
    SeaProductPrintComponent,
    SeaProductposterPrintComponent,
    SeaProducerPrintComponent,
    SeaProducerposterPrintComponent,
    ArticlePrintComponent,
    ArticlelistPrintComponent,

    ModelprintManageComponent
  ],
  imports: [
    ModelPrintModule,
    ModelCommModule,
    CommFieldModule,
    CommonModule,
    NgbModule
  ]
})
export class PrintModelsModule {
}
