import {News, NewsService} from "./news.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class NewsResolve implements Resolve<News> {
  constructor(private _model: NewsService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<News> {
    return this._model.get(+route.params["newsId"]);
  }
}
