import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Person} from "../../people/person/person.service";
import {Tarif} from "../tarif/tarif.service";


export class TarifLog extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "tarif",
    verboseName: "Tarif",
    required: false,
    helpText: "Tarif",
    relatedModel: "tarifs.Tarif",
    remoteField: "id",
    relatedName: "logs"
  })
  public tarif!: number;
  public tarif_details!: Tarif;

  @DSForeignKeyField({
    name: "author",
    verboseName: "Auteur",
    required: false,
    helpText: "Auteur",
    relatedModel: "people.Person",
    remoteField: "id",
    relatedName: "tariflogs"
  })
  public author!: number;
  public author_details!: Person;

  @DSCharField({
    name: "operation",
    verboseName: "Opération",
    required: false,
    helpText: "Opération",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public operation!: string;

  @DSCharField({
    name: "step",
    verboseName: "Etape",
    required: false,
    helpText: "Etape",
    minLength: 0,
    maxLength: 10,
    choices: []
  })
  public step!: string;

  @DSDefaultField({
    name: "data",
    verboseName: "Data",
    required: false,
    helpText: "Data"
  })
  public data!: any;

  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;

  @DSTextField({
    name: "comments",
    verboseName: "Commentaires (interne)",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 10000
  })
  public comments!: string;

  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags!: any;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class TarifLogService extends DSRestCollection<TarifLog> {
  public adapter_config = {basePath: "/api/v2/tarifs/tariflogs"};
  public model = TarifLog;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
