<!DOCTYPE html>
<lvadg-modellist-display [mode]="displayMode">
  <div class="list-current-filters d-print-none">
    <bsac-filter-set [list]="list">
      <div class="filtersetitem" *bsacFilterSetItem="'search'; desc: 'Recherche par texte'; group: 'general'">
        <bsac-model-list-text-filter [list]="list" field="search"></bsac-model-list-text-filter>
      </div>
      <div class="filtersetitem col-3" *bsacFilterSetItem="'tariftypes'; desc: 'Recherche par modèle de tarif'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="tariftypes" descfield="name" [queryset]="tariftypes$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem col-3" *bsacFilterSetItem="'tarifs'; desc: 'Recherche par tarif'; group: 'general'">
        <bsac-model-list-multiautocomplete-filter [list]="list" field="tarifs" descfield="name" [queryset]="tarifs$"></bsac-model-list-multiautocomplete-filter>
      </div>
      <div class="filtersetitem col-3" *bsacFilterSetItem="'notiftypes'; desc: 'Recherche par type de notification'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="notiftypes" [choices]="NOTIF_TYPES"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem col-3" *bsacFilterSetItem="'statuses'; desc: 'Recherche par état'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="statuses" [choices]="NOTIF_STATUSES"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetitem col-3" *bsacFilterSetItem="'priorities'; desc: 'Recherche par priorité'; group: 'general'">
        <bsac-model-list-multiselect-filter [list]="list" field="priorities" [choices]="NOTIF_PRIORITIES"></bsac-model-list-multiselect-filter>
      </div>
      <div class="filtersetgroup" *bsacFilterSetGroup="'general'; desc: 'Recherche générale'"></div>
    </bsac-filter-set>
  </div>
  <div class="list-select-filters">
    <bsac-model-list-filterset-select [list]="list" [restricted]="!checkMode('admin')"></bsac-model-list-filterset-select>
  </div>
  <div class="list-select-columns">
    <bsac-model-list-fields-select [list]="list">Colonnes à afficher</bsac-model-list-fields-select>
  </div>
  <div class="list-persistence">
    <bsac-model-list-persistence-save [list]="list">
      <button class="btn btn-link"><i class="fa fa-save me-2"></i>Enregister le filtre</button>
    </bsac-model-list-persistence-save>
    <bsac-model-list-persistence-load [list]="list">
      <button class="btn btn-link"><i class="fa fa-file me-2"></i>Charger un filre</button>
    </bsac-model-list-persistence-load>
  </div>
  <div class="list-actions">
  </div>
  <div class="list-results">
    <ng-container *ngIf="tableResults">
      <div class="table-responsive" *ngIf="list" [class.cdkSearching]="list.searching" #TABLE>
        <table class="table" cdk-table [dataSource]="list" [trackBy]="eltId" [ngClass]="getTableClasses()" #table>
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="id"><b>ID</b></bsac-model-list-sorter>
            </th>
            <td class="pointer" cdk-cell *cdkCellDef="let row" [routerLink]="getDetailLink(row)"><b>{{row.id}}</b></td>
          </ng-container>
          <ng-container cdkColumnDef="tags">
            <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
            <td cdk-cell *cdkCellDef="let row">{{row.tags}}</td>
          </ng-container>
          <ng-container cdkColumnDef="flags">
            <th cdk-header-cell *cdkHeaderCellDef>Flags</th>
            <td cdk-cell *cdkCellDef="let row"><span class="badge bg-secondary mx-1" *ngFor="let f of row.flags">{{f}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="created">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="created"></bsac-model-list-sorter>Date de création
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.created|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="updated">
            <th cdk-header-cell *cdkHeaderCellDef>
              <bsac-model-list-sorter [list]="list" field="updated"></bsac-model-list-sorter>Date de mise à jour
            </th>
            <td cdk-cell *cdkCellDef="let row"><span class="date">{{row.updated|date:'short'}}</span></td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef>Etat</th>
            <td cdk-cell *cdkCellDef="let row">{{row.status|choice:row:'status'}}</td>
          </ng-container>
          <ng-container cdkColumnDef="notif_tariftype">
            <th cdk-header-cell *cdkHeaderCellDef>Modèle de tarif</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="tariftype_detail_route?.route({tarifType: row.notification_details?.tariftype_details})" *ngIf="row.notification_details?.tariftype_details">{{row.notification_details?.tariftype_details?.name}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="notif_tarif">
            <th cdk-header-cell *cdkHeaderCellDef>Tarif</th>
            <td cdk-cell *cdkCellDef="let row"><a [routerLink]="tarif_detail_route?.route({tarifType: row.notification_details?.tariftype_details, tarif: row.notification_details?.tarif_details})" *ngIf="row.notification_details?.tarif_details">{{row.notification_details?.tarif_details?.name}}</a></td>
          </ng-container>
          <ng-container cdkColumnDef="notif_type">
            <th cdk-header-cell *cdkHeaderCellDef>Type</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.notification_details?.type==='PUB'">Publication</ng-container>
              <ng-container *ngIf="row.notification_details?.type==='UPD'">Mise à jour</ng-container>
              <ng-container *ngIf="row.notification_details?.type==='ALA'">Alarme</ng-container>
              <ng-container *ngIf="row.notification_details?.type==='NEG'">Négociation</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="notif_title">
            <th cdk-header-cell *cdkHeaderCellDef>Titre</th>
            <td cdk-cell *cdkCellDef="let row">{{row.notification_details?.title}}</td>
          </ng-container>
          <ng-container cdkColumnDef="notif_priority">
            <th cdk-header-cell *cdkHeaderCellDef>Priorité</th>
            <td cdk-cell *cdkCellDef="let row">{{row.notification_details?.priority}}</td>
          </ng-container>
          <ng-container cdkColumnDef="user">
            <th cdk-header-cell *cdkHeaderCellDef>Utilisateur / destinataire</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="row.user_details">{{row.user_details?.lastname}} {{row.user_details?.firstname}} {{row.user_details?.email}}</ng-container>
              <ng-container *ngIf="row.destination">{{row.destination}}</ng-container>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="notification">
            <th cdk-header-cell *cdkHeaderCellDef>Notification</th>
            <td cdk-cell *cdkCellDef="let row">{{row.notification}}</td>
          </ng-container>
          <ng-container cdkColumnDef="sref">
            <th cdk-header-cell *cdkHeaderCellDef>Mailer ref</th>
            <td cdk-cell *cdkCellDef="let row"><code>{{row.sref}}</code></td>
          </ng-container>
          <thead>
            <tr cdk-header-row *cdkHeaderRowDef="list.fields.enabled"></tr>
          </thead>
          <tbody>
            <tr cdk-row *cdkRowDef="let row; columns: list.fields.enabled" [ngClass]="getRowClasses(row)"></tr>
          </tbody>
        </table>
      </div>
      <div class="loading" *ngIf="list?.searching">
        <div class="text-center mt-4 mb-4"><i class="fa fa-spinner fa-spin fa-5x"></i>
          <p>Loading...</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="list-pagination">
    <bsac-model-list-paginator [list]="list" [update]="true" [maxpages]="maxpages"></bsac-model-list-paginator>
  </div>
  <div class="list-results-side">
  </div>
</lvadg-modellist-display>
<lvadg-modellist-admin class="mt-5" [list]="list" [mode]="mode"></lvadg-modellist-admin>