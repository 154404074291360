import {NgModule} from "@angular/core";
import {VIEWS_COMMON_COMMON_MODULES} from "../views-common-modules";
import {TarifTypeManageViewV3Component} from "./tariftype-manage-view/tariftype-manage-view.component";
import {TarifTypeModule} from "../../../lib/models/tarif/tariftype/tariftype.module";
import {ExternalArticleModule} from "../../../lib/models/catalog/externalarticle/externalarticle.module";
import {TarifModule} from "../../../lib/models/tarif/tarif/tarif.module";
import {ArticletarifModule} from "../../../lib/models/tarif/articletarif/articletarif.module";
import {TarifTypeListViewV3Component} from "./tariftype-list-view/tariftype-list-view.component";
import {ArticleTarifTemplateManageViewV3Component} from "./articletariftemplate-manage-view/articletariftemplate-manage-view.component";
import {TarifListViewV3Component} from "./tarif-list-view/tarif-list-view.component";
import {TarifListTreeViewV3Component} from "./tarif-listtree-view/tarif-listtree-view.component";
import {TarifNotificationModule} from "../../../lib/models/tarif/tarifnotification/tarifnotification.module";
import {TarifManageViewV3Component} from "./tarif-manage-view/tarif-manage-view.component";
import {TarifdocumentModule} from "../../../lib/models/tarif/tarifdocument/tarifdocument.module";
import {TarifRelationModule} from "../../../lib/models/tarif/tarifrelation/tarifrelation.module";
import {TarifsStatsViewV3Component} from "./tarifs-stats-view/tarifs-stats-view.component";
import {ArticleModule} from "../../../lib/models/catalog/article/article.module";
import {TarifNotificationGroupModule} from "../../../lib/models/tarif/tarifnotificationgroup/tarifnotificationgroup.module";
import {TarifNotificationStatusListViewV3Component} from "./tarifnotificationstatus-list-view/tarifnotificationstatus-list-view.component";
import {TarifnotificationstatusModule} from "../../../lib/models/tarif/tarifnotificationstatus/tarifnotificationstatus.module";
import {ArticleFamilyModule} from "../../../lib/models/catalog/family/article-family.module";
import {TarifFamilyStatsViewComponent} from "./tarif-family-stats-view/tarif-family-stats-view.component";


@NgModule({
  declarations: [
    TarifTypeManageViewV3Component,
    TarifTypeListViewV3Component,
    ArticleTarifTemplateManageViewV3Component,
    TarifListViewV3Component,
    TarifListTreeViewV3Component,
    TarifManageViewV3Component,
    TarifsStatsViewV3Component,
    TarifNotificationStatusListViewV3Component,
    TarifFamilyStatsViewComponent
  ],
  exports: [
    TarifTypeManageViewV3Component,
    TarifTypeListViewV3Component,
    ArticleTarifTemplateManageViewV3Component,
    TarifListViewV3Component,
    TarifListTreeViewV3Component,
    TarifManageViewV3Component,
    TarifsStatsViewV3Component,
    TarifNotificationStatusListViewV3Component,
    TarifFamilyStatsViewComponent

  ],
  imports: [
    ...VIEWS_COMMON_COMMON_MODULES,
    TarifTypeModule,
    TarifModule,
    TarifNotificationModule,
    TarifNotificationGroupModule,
    TarifnotificationstatusModule,
    TarifRelationModule,
    TarifdocumentModule,
    ArticletarifModule,
    ArticleModule,
    ArticleFamilyModule,
    ExternalArticleModule
  ]
})
export class ViewsTarifsModule {
}

export * from "./articletariftemplate-manage-view/articletariftemplate-manage-view.component";
export * from "./tariftype-list-view/tariftype-list-view.component";
export * from "./tariftype-manage-view/tariftype-manage-view.component";
export * from "./tarif-list-view/tarif-list-view.component";
export * from "./tarif-listtree-view/tarif-listtree-view.component";
export * from "./tarif-manage-view/tarif-manage-view.component";
