import {DSAutoField, DSCharField, DSDateTimeField, DSDefaultField, DSIntegerField, DSModel} from "@solidev/ngdataservice";

import {Subscription} from "rxjs";

export class CommJob extends DSModel {
  @DSAutoField({
    name: "id"
  })
  public id!: number;


  @DSIntegerField({
    name: "progress",
    required: false,
    verboseName: "Progress"
  })
  public progress!: number;

  @DSIntegerField({
    name: "total",
    required: false,
    verboseName: "Total"
  })
  public total!: number;


  @DSDateTimeField({
    name: "created",
    required: false,
    verboseName: "Date de création"
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    required: false,
    verboseName: "Date de modification"
  })
  public updated!: Date;

  @DSDefaultField({
    name: "result",
    required: false,
    verboseName: "Print result"
  })
  // FIXME: add format here for data
  public result!: any;

  @DSCharField({
    name: "channel",
    verboseName: "Live channel",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 128,
    choices: []
  })
  public channel!: string;


  @DSCharField({
    name: "status",
    required: false,
    verboseName: "Etat",
  })
  public status!: string;

  public live!: Subscription;

}
