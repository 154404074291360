
<ng-container *ngIf="current">
  <h1>{{current.att_details?.name}}</h1>
  <div class="row scroller">
    <ng-container *ngFor="let sl of slots; let i=index">
      <div *ngIf="sl.type==='tarifsmarches'" [ngClass]="sl.classes">
        <div class="card mt-1">
          <div class="card-header ps-3 pe-1 pt-1 pb-1"><i class="fa fa-eye-slash float-end p-2 pointer" (click)="removeSlot(i)"></i><i class="fa fa-columns float-end p-2 pointer" (click)="toggleColumns(i)"></i>Tarifs marchés</div>
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Marché</th>
                <th>Date valeur</th>
                <th>Date de fin</th>
                <th>Libellé</th>
                <th>Prix</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let m of current.tarifsmarches">
                <td>{{m.mname}}</td>
                <td>{{m.date|date:'shortDate'}}</td>
                <td>{{m.end|date:'shortDate'}}</td>
                <td>{{m.lib}}</td>
                <td>{{m.price/10000|currency:'EUR'}}
                  <ng-container *ngIf="m.min || m.max"><small *ngIf="m.min">{{m.min/10000|currency:'EUR'}}</small><small *ngIf="!m.min">-</small><small *ngIf="m.max">{{m.max/10000|currency:'EUR'}}</small><small *ngIf="!m.max">-</small></ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="sl.type==='infosmarches'" [ngClass]="sl.classes">
        <div class="card mt-1">
          <div class="card-header ps-3 pe-1 pt-1 pb-1"><i class="fa fa-eye-slash float-end p-2 pointer" (click)="removeSlot(i)"></i><i class="fa fa-columns float-end p-2 pointer" (click)="toggleColumns(i)"></i>Infos marchés</div>
          <div class="card-body">
            <lvadg-articletarif-stats-infos-marches [tarif]="tarif"></lvadg-articletarif-stats-infos-marches>
          </div>
        </div>
      </div>
      <div *ngIf="sl.type==='meteosummary'" [ngClass]="sl.classes">
        <div class="card mt-1">
          <div class="card-header ps-3 pe-1 pt-1 pb-1"><i class="fa fa-eye-slash float-end p-2 pointer" (click)="removeSlot(i)"></i><i class="fa fa-columns float-end p-2 pointer" (click)="toggleColumns(i)"></i>Résumé météo 15j</div>
          <div class="card-body">
            <lvadg-meteo-summary></lvadg-meteo-summary>
          </div>
        </div>
      </div>
      <div *ngIf="sl.type==='meteosmallmaps'" [ngClass]="sl.classes">
        <div class="card mt-1">
          <div class="card-header ps-3 pe-1 pt-1 pb-1"><i class="fa fa-eye-slash float-end p-2 pointer" (click)="removeSlot(i)"></i><i class="fa fa-columns float-end p-2 pointer" (click)="toggleColumns(i)"></i>Météo (minicartes)</div>
          <div class="card-body">
            <lvadg-meteo-map [day]="1"></lvadg-meteo-map>
          </div>
        </div>
      </div>
      <div *ngIf="sl.type==='meteobigmap'" [ngClass]="sl.classes">
        <div class="card mt-1">
          <div class="card-header ps-3 pe-1 pt-1 pb-1"><i class="fa fa-eye-slash float-end p-2 pointer" (click)="removeSlot(i)"></i><i class="fa fa-columns float-end p-2 pointer" (click)="toggleColumns(i)"></i>Météo (carte)</div>
          <div class="card-body">
            <lvadg-meteo-fullmap></lvadg-meteo-fullmap>
          </div>
        </div>
      </div>
      <ng-container *ngIf="sl.type==='graph'">
        <ng-container *ngFor="let s of stats">
          <div *ngIf="s.name===sl.name" [ngClass]="sl.classes">
            <div class="card mt-1">
              <div class="card-header ps-3 pe-1 pt-1 pb-1"><i class="fa fa-eye-slash float-end p-2 pointer" (click)="removeSlot(i)"></i><i class="fa fa-columns float-end p-2 pointer" (click)="toggleColumns(i)"></i>{{s.title}}</div>
              <lvadg-articletarif-stats-scatter *ngIf="s.type=='scatter'" [stat]="s"></lvadg-articletarif-stats-scatter>
              <lvadg-articletarif-stats-timeserie *ngIf="s.type=='timeserie'" [stat]="s"></lvadg-articletarif-stats-timeserie>
              <lvadg-articletarif-stats-timeseries *ngIf="s.type=='timeseries'" [stat]="s"></lvadg-articletarif-stats-timeseries>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="col-12">
      <div class="card mt-3 bg-info text-white">
        <div class="card-body">
          <form [formGroup]="slotForm" (submit)="saveSlot()">
            <div class="form-group">
              <label>Affichage</label>
              <select class="form-select" formControlName="slot">
                <option *ngFor="let s of stats_names" [ngValue]="s">{{s.label}}</option>
              </select>
            </div>
            <button class="btn btn-primary btn-block w-100"><i class="fa fa-plus me-2"></i>Ajouter un bloc</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>