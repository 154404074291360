
<div class="form-group" *ngIf="param">
  <form [formGroup]="form">
    <div class="form-group">
      <label><i class="fa fa-trash pointer float-end" (click)="removeParam()"></i>{{customLabel || param.label}}</label>
      <select class="form-select" formControlName="value">
        <option *ngFor="let c of param.choices" [ngValue]="c.value">{{c.desc}}</option>
      </select>
      <div class="help-block" *ngIf="param.help">{{param.help}}</div>
    </div>
    <div *ngIf="form.value.value=='CUSTOM'">
      <lvadg-upload-image [url]="getImageUrl()" (uploaded)="onUploaded($event)" method="PATCH" fieldName="fvalue"></lvadg-upload-image>
    </div>
  </form>
</div>