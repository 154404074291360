import {Component} from "@angular/core";
import {ArticleDataManageBaseComponent} from "../_article-data-manage-base/article-data-manage-base.component";
import {ArticlePresentation, ArticlePresentationService} from "../article-presentation.service";
import {BsacMessageService, BsacModelListProvider} from "@solidev/bsadmincomponents";
import {RoutesService} from "../../../../comps/routes.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "lvadg-article-presentations-manage",
  templateUrl: "./article-presentations-manage.component.pug",
  styleUrls: ["./article-presentations-manage.component.sass"]
})
export class ArticlePresentationsManageComponent extends ArticleDataManageBaseComponent<ArticlePresentation, ArticlePresentationService> {
  constructor(_list: BsacModelListProvider,
              _items: ArticlePresentationService,
              _msgs: BsacMessageService,
              routes: RoutesService,
              route: ActivatedRoute) {
    super(_list, _items, _msgs, routes, route);
    this.paramId = "presentationId";
    this.filterField = "presentation";
  }
}
