
<bsac-breadcrumb></bsac-breadcrumb>
<div class="row">
  <div class="col-sm-7">
    <h1><i class="fa fa-table me-2"></i>Tarif : {{t.name}}</h1>
    <div class="sttdate"><br>valable du {{t.datestart|date:'fullDate'}} au {{t.dateend|date:'fullDate'}}</div>
  </div>
  <div class="col-sm-3">
    <div class="ql-text" [innerHTML]="htmlMessage"></div>
  </div>
  <div class="col-sm-2"><a class="btn btn-outline-primary btn-sm" [routerLink]="tariftype_detail_route?.route({tarifType: t.type_details})">Modèle<br/>{{t.type_details.name}}</a></div>
</div>
<nav class="nav-tabs" ngbNav #nav="ngbNav" [(activeId)]="navId.current">
  <ng-container ngbNavItem="details" *ngIf="isTarifPowerUser(t)"><a ngbNavLink data-cy="tarifdetails-nav">Détails</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <lvadg-tarif-manage [tarif]="t" (duplicated)="onDuplicated($event)"></lvadg-tarif-manage>
        <div class="mt-2 d-flex flex-column">
          <lvadg-tarifrelation-listmanage [dest]="t" [tariftype_detail_route]="tariftype_detail_route" [tarif_detail_route]="tarif_detail_route"></lvadg-tarifrelation-listmanage>
          <lvadg-tarifrelation-listmanage [src]="t" [tariftype_detail_route]="tariftype_detail_route" [tarif_detail_route]="tarif_detail_route"></lvadg-tarifrelation-listmanage>
        </div>
        <div class="mt-2"></div>
        <lvadg-tarifdocument-listmanage [tarif]="t"></lvadg-tarifdocument-listmanage>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="lines" *ngIf="t.type_details.lifecycle !== LifeCycles.UPLOAD"><a ngbNavLink data-cy="tariflines-nav">Lignes tarif</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <div class="row mt-3">
          <div class="col-6"><a class="btn btn-primary w-100" [routerLink]="['/user', t.type_details.type.toLowerCase(), 'tarifs', 'standalonestats']" target="_blank" (click)="toggleStats(false)">Afficher les stats sur un autre écran</a></div>
          <div class="col-6"><a class="btn btn-primary w-100" (click)="stats=true" *ngIf="!stats">Afficher les statistiques</a><a class="btn btn-primary w-100" (click)="stats=false" *ngIf="stats">Masquer les statistiques</a></div>
        </div>
        <div class="row mt-2">
          <div class="col-12" [class.col-lg-6]="stats">
            <lvadg-articletarif-listmanage [article_detail_route]="article_detail_route" [tarif]="t" (current$)="current$.next($event)"></lvadg-articletarif-listmanage>
          </div>
          <div class="col-12 col-lg-6" *ngIf="stats">
            <div class="stats">
              <lvadg-articletarif-stats [tarif]="t" [current$]="current$"></lvadg-articletarif-stats>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="lifecycle" *ngIf="t.type_details.lifecycle !== LifeCycles.UPLOAD &amp;&amp; isTarifPowerUser(t)"><a ngbNavLink data-cy="tariflifecycle-nav">Cycle de vie</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <h3>Cycle de vie du tarif</h3>
        <lvadg-tarif-lifecycle [tarif]="t" (action)="lifecycleAction($event)"></lvadg-tarif-lifecycle>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="import" *ngIf="t.isAuth('admin')"><a ngbNavLink data-cy="tarifxlsximport-nav">Import xls</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <div class="mt-2">
          <lvadg-tarif-importdoc [tarif]="t"></lvadg-tarif-importdoc>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem="notifgroups_tarif" *ngIf="isTarifPowerUser(t)"><a ngbNavLink>Notifications</a>
    <ng-template ngbNavContent>
      <div class="tab-content">
        <h2 class="mt-3">Suivi notifications</h2>
        <lvadg-tarifnotificationstatus-list name="tarifmanage-notificationlist" [displayMode]="'horizontal'" [mode]="mode" [filter]="{tarifs: t.id.toString(10)}" [default_fields]="tarifnotifications_default_fields" [default_filters]="tarifnotifications_default_filters" [tarif_detail_route]="tarif_detail_route" [tariftype_detail_route]="tariftype_detail_route"></lvadg-tarifnotificationstatus-list>
      </div>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav"></div>