import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {IDSModel} from "@solidev/ngdataservice";
import {first} from "rxjs/operators";
import {CustomDataKey, CustomDataKeyService} from "../../customdatakey/customdatakey.service";
import {CustomData} from "../customdata.service";

@Component({
  selector: "lvadg-customdata-rowfield-display",
  templateUrl: "./custom-data-rowfield-display.component.pug",
  styleUrls: ["./customdata-rowfield-display.component.sass"]
})
export class CustomDataRowfieldDisplayComponent implements OnInit {
  @Input() public mode!: string;
  @Input() public editors: string[] = [];
  @Input() public model!: IDSModel;
  @Input() public dest!: string;
  @Input() public code!: string;
  @Input() public alt!: string;
  @Input() public write!: string;
  @Input() public edit = false;
  @Input() public default = "???";
  @Output() public edited = new EventEmitter<IDSModel>();
  public key!: CustomDataKey;
  public value!: CustomData;
  public altvalue!: CustomData;
  public readonly = false;


  constructor(private _keys: CustomDataKeyService) {
  }

  public ngOnInit(): void {
    const mmodel = (this.model as any);
    if (this.model && mmodel.customdata_details) {
      this._keys.getByDestCode(this.dest, this.code).pipe(first())
        .subscribe((k) => {
          this.key = k as CustomDataKey;
          const kv = this.key.values(this.model, this.alt, this.write);
          if (kv.found) {
            this.value = kv.value;
            this.readonly = kv.readonly;
            this.altvalue = kv.altvalue;
          } else {
            this.readonly = kv.readonly;
          }
        });
    }
  }
  public checkMode(args: string[]): boolean {
    return args.indexOf(this.mode) !== -1;
  }


}
