import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {SeaLabelSelectComponent} from "./sealabel-select/sealabel-select.component";

@NgModule({
  imports: [CommonModule, BsacFormsModule],
  declarations: [SeaLabelSelectComponent],
  exports: [SeaLabelSelectComponent]
})
export class SeaLabelSelectModule {}
