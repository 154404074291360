
<div class="row g-3">
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-info fa-s"></i>Infos famille</div>
      <div class="card-body">
        <dl class="row" *ngIf="articleFamily">
          <div class="col-sm-8">
            <bsac-dd-field [model]="articleFamily" field="name" type="auto">Nom</bsac-dd-field>
          </div>
          <div class="col-sm-4">
            <bsac-dd-field [model]="articleFamily" field="dispname" type="auto">Affichage du nom ?</bsac-dd-field>
          </div>
          <div class="col-sm-6">
            <bsac-dd-field [model]="articleFamily" field="dmode" type="auto">Type d'affichage</bsac-dd-field>
          </div>
          <div class="col-sm-6">
            <dt> Type d'affichage (hérité)</dt>
            <dd>{{articleFamily.cdmode|choice:articleFamily:'dmode'}}</dd>
          </div>
          <div class="col-sm-12">
            <bsac-dd-field [model]="articleFamily" field="content_src" type="auto">Description</bsac-dd-field>
          </div>
          <div class="col-sm-12">
            <dt>Hiérarchie</dt>
            <dd>
              <div *ngFor="let p of articleFamily.parents"><a [routerLink]="articlefamily_detail_route?.route({articleFamily: $any(p)})">{{p.cfullname}}</a></div>
            </dd>
          </div>
        </dl>
        <dl class="row">
          <div class="col-sm-12">
            <bsac-flags-field [model]="articleFamily" [action]="true">Flags</bsac-flags-field>
          </div>
          <div class="col-sm-4">
            <bsac-dd-field [model]="articleFamily" field="status" type="auto">Status</bsac-dd-field>
          </div>
          <div class="col-sm-4">
            <dt>Created</dt>
            <dd>{{articleFamily.created|date:'short'}}</dd>
          </div>
          <div class="col-sm-4">
            <dt>Updated</dt>
            <dd>{{articleFamily.updated|date:'short'}}</dd>
          </div>
        </dl>
        <hr>
        <div class="row">
          <div class="col-sm-8">
            <bsac-dd-field [model]="articleFamily" field="cname" type="auto" [editable]="false">Affichage restreint</bsac-dd-field>
            <bsac-dd-field [model]="articleFamily" field="cfullname" type="auto" [editable]="false">Affichage complet</bsac-dd-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-lemon me-2"></i>Champs produit par défaut</div>
      <div class="card-body">
        <div class="col-sm-8">
          <dl *ngIf="articleFamily">
            <bsac-dd-field [model]="articleFamily" field="productname" type="auto">Nom du produit</bsac-dd-field>
            <bsac-dd-field [model]="articleFamily" field="productdesc" type="auto">Description produit</bsac-dd-field>
            <dt>Saisonnalité</dt>
            <dd>
              <lvadg-seasons-manage [seasons]="articleFamily.productseasons" (changed)="updateSeasons($event)" zone="fl"></lvadg-seasons-manage>
            </dd>
          </dl>
        </div>
        <div class="col-sm-4">
          <lvadg-rimg [src]="articleFamily?.clogo_img" display="img-fluid img-thumbnail">Logo courant</lvadg-rimg>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-info-circle me-2"></i>Données produit</div>
      <div class="card-body">
        <lvadg-customdata-manage [model]="articleFamily" dest="CFM" [alt]="'ccustomdata_details'"></lvadg-customdata-manage>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-image me-2"></i>Image & texte</div>
      <div class="card-body">
        <div class="row">
          <div class="col-4 text-info text-center mb-2">
            <div><b>Logo courant</b></div>
            <lvadg-rimg [src]="articleFamily.clogo_img" size="lg" display="img-fluid img-thumbnail"></lvadg-rimg>
          </div>
          <div class="col-8">
            <lvadg-images-manage [model]="articleFamily"></lvadg-images-manage>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="articleFamily">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-file-archive me-2"></i>Fiches techniques</div>
      <div class="card-body">
        <lvadg-detaildocument-listmanage [model]="articleFamily" [documents]="articleFamily.documents_details" (deleted)="onDocumentDeleted()" (added)="onUploaded($event)" [uploadData]="{}" type="FT" [fields]="['title','type', 'status', 'prlabel']"></lvadg-detaildocument-listmanage>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="articleFamily">
    <div class="card h-100">
      <div class="card-header"><i class="fa fa-file-archive me-2"></i>Autres documents</div>
      <div class="card-body">
        <lvadg-detaildocument-listmanage [model]="articleFamily" [documents]="articleFamily.documents_details" (deleted)="onDocumentDeleted()" (added)="onUploaded($event)" [uploadData]="{}" type="OT" [fields]="['title','type', 'status']"></lvadg-detaildocument-listmanage>
      </div>
    </div>
  </div>
</div>