
<ng-container *ngIf="curfolder &amp;&amp; mode=='admin'">
  <h4><i class="fa fa-folder-open me-2"></i>{{curfolder.title}}</h4>
  <dl class="row">
    <bsac-dd-field class="col-12 col-xl-12" [model]="curfolder" field="title" type="auto">Titre</bsac-dd-field>
    <bsac-dd-field class="col-12 col-xl-6" [model]="curfolder" field="type" type="auto">Type</bsac-dd-field>
    <bsac-dd-field class="col-12 col-xl-6" [model]="curfolder" field="status" type="auto">Status</bsac-dd-field>
    <bsac-dd-field class="col-12 col-xl-12" [model]="curfolder" field="content_src" type="auto">Description</bsac-dd-field>
    <bsac-dd-field class="col-12 col-xl-12" [model]="curfolder" field="zone" type="auto" *ngIf="curfolder.level==0" (changed)="checkChanged($event)">Zone</bsac-dd-field>
  </dl>
  <hr>
  <lvadg-folder-setaccess [folder]="curfolder"></lvadg-folder-setaccess>
  <hr>
  <lvadg-confirm-delete (confirm)="delete()" [classes]="['btn-block', 'btn-sm']"><i class="fa fa-trash me-2"></i>Supprimer</lvadg-confirm-delete>
  <hr>
  <button class="btn btn-primary btn-block w-100 btn-sm" (click)="moveAction=true"><i class="fa fa-move me-2"></i>Déplacer</button>
  <ng-container *ngIf="moveAction">
    <div class="form-group">
      <label>Destination</label>
      <bsac-select-related [queryset]="tree.queryset" descfield="title" label="Sélectionner la destination" (selected)="move($event)"></bsac-select-related>
    </div>
  </ng-container>
  <hr>
  <lvadg-images-manage [model]="curfolder" [imagesfield]="null"></lvadg-images-manage>
</ng-container>
<ng-container *ngIf="curfolder &amp;&amp; mode!='admin'">
  <h4><i class="fa fa-times float-end pointer" (click)="closed.emit()"></i><i class="fa fa-folder-open me-2"></i>{{curfolder.title}}</h4>
  <dl>
    <dt>Nom du dossier</dt>
    <dd>{{curfolder.title}}</dd>
    <dt>Date de création</dt>
    <dd>{{curfolder.created | date:'short'}}</dd>
    <dt>Date de modification</dt>
    <dd>{{curfolder.updated | date:'short'}}</dd>
    <dt>Description</dt>
    <dd>{{curfolder.content_src}}</dd>
  </dl>
</ng-container>