
<!-- extends ../../_base/offer-manage-product/offer-manage-product.component.include.pug-->
<h4>Infos produit</h4>
<div class="row">
  <div class="col-sm-4">
    <dl>
      <dt>Produit</dt>
      <dd>{{product.name}}</dd>
      <dt>Famille</dt>
      <dd>{{product.family_details.cfullname}}</dd>
    </dl>
  </div>
  <div class="col-sm-4">
    <dl>
      <dt>Producteur</dt>
      <dd>{{product.producer_details.name}}</dd>
      <dt>Emplacement</dt>
      <dd>{{offer.storage_details.name}}</dd>
    </dl>
  </div>
  <div class="col-sm-4">
    <dl *ngIf="offerproduct">
      <bsac-dd-field [model]="offerproduct" field="order" type="auto">Priorité affichage</bsac-dd-field>
      <p class="text-info">
        L'ordre d'affichage des produits se fait d'abord par les produits ayant
        la <b>priorité la plus élevée</b>, puis par ordre alphabétique...
      </p>
      <bsac-dd-field [model]="offerproduct" field="comment" type="auto" [btn]="true">Commentaires</bsac-dd-field>
    </dl>
  </div>
</div>
<h4>Eléments de nomenclature & tarif disponibles</h4>
<table class="table table-sm table-striped table-bordered available">
  <thead>
    <tr>
      <th>Code client</th>
      <th>Désignation</th>
      <th>Calibre</th>
      <th>UDV</th>
      <th>UDP</th>
      <th>Packaging</th>
      <th>Tarif(s)</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let v of variants" [class.table-warning]="v.insales!='OK'">
      <td>{{v.ccode}}</td>
      <td>{{v.lname}}</td>
      <td>{{$any(v).caliber || "NC"}}</td>
      <td>{{v.sales_value/1000}} {{v.sales_unit}}</td>
      <td>{{v.packaging_value/1000}} {{v.packaging_unit}}</td>
      <td>{{v.packing || "NC"}}</td>
      <td>
        <div class="price mb-1" *ngFor="let p of getPrices(v)"><span class="zone me-2">{{p.zone}}</span><span class="price me-2">{{p.price/1000}} €</span>
          <button class="btn btn-primary btn-sm" (click)="addWithPrice(v, p)"><i class="fa fa-plus"></i></button>
        </div>
        <button class="btn btn-primary btn-sm" (click)="addWithoutPrice(v)"><i class="fa fa-plus me-1"></i>Sans tarif</button>
      </td>
    </tr>
    <tr class="table-info">
      <td class="pointer" colspan="7" (click)="addCustomVariant()">
        <div class="text-center"><i class="fa fa-plus me-2"></i>Ajouter une nomenclature & un tarif personnalisés</div>
      </td>
    </tr>
    <tr class="table-warning" *ngIf="!offerproduct">
      <td class="pointer" colspan="7" (click)="addEmpty()">
        <div class="text-center"><i class="fa fa-plus me-2"></i>Ajouter sans nomenclature ni tarif</div>
      </td>
    </tr>
  </tbody>
</table>
<ng-container *ngIf="editCustom || addCustom">
  <h4>Nomenclature & tarif personnalisés</h4>
  <form [formGroup]="customForm">
    <div class="row">
      <div class="col-sm-4">
        <label>Code client</label>
        <input class="form-control" formControlName="custccode">
      </div>
      <div class="col-sm-8">
        <label>Désignation</label>
        <input class="form-control" formControlName="custname">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-6">
        <label>Tarif</label>
        <input class="form-control" formControlName="custprice" type="number">
      </div>
      <div class="col-sm-6">
        <label>Tarif brut</label>
        <input class="form-control" formControlName="custrawprice" type="number">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-3">
        <label>Calibre</label>
        <input class="form-control" formControlName="custcaliber">
      </div>
      <div class="col-sm-3">
        <label>UDV</label>
        <input class="form-control" formControlName="custsalesunit">
      </div>
      <div class="col-sm-3">
        <label>UDP</label>
        <input class="form-control" formControlName="custpackaging">
      </div>
      <div class="col-sm-3">
        <label>Packaging</label>
        <input class="form-control" formControlName="custpacking">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-8" *ngIf="addCustom">
        <button class="btn btn-outline-primary btn-block w-100" [disabled]="!customForm.valid" (click)="addWithCustom()"><i class="fa fa-plus me-2"></i>Ajouter</button>
      </div>
      <div class="col-8" *ngIf="editCustom">
        <button class="btn btn-outline-primary btn-block w-100" [disabled]="!customForm.valid" (click)="editDetails()"><i class="fa fa-save me-2"></i>Enregistrer</button>
      </div>
      <div class="col-4">
        <button class="btn btn-outline-secondary btn-block w-100" (click)="cancelCustom()"><i class="fa fa-times me-2"></i>Annuler</button>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="offerproduct">
  <hr>
  <h4>Eléments sélectionnés pour l'offre</h4>
  <table class="table table-sm table-striped table-bordered offerdetails">
    <thead>
      <tr>
        <th>Code client</th>
        <th>Désignation</th>
        <th>Calibre</th>
        <th>UDV</th>
        <th>UDP</th>
        <th>Packaging</th>
        <th>Tarif</th>
        <th>Tarif brut</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let details of offerproduct.details_details">
        <td class="ccode">{{details.custccode || details.variant_details?.ccode}}</td>
        <td class="lname">{{details.custname || details.variant_details?.lname}}</td>
        <td class="caliber">{{details.custcaliber || details.variant_details?.caliber || "NC"}}</td>
        <td class="udv">
          <ng-container *ngIf="details.custsalesunit"><span>{{details.custsalesunit}}</span></ng-container>
          <ng-container *ngIf="!details.custsalesunit"><span *ngIf="details.variant_details">{{details.variant_details.sales_value/1000}} {{details.variant_details.sales_unit}}</span></ng-container>
        </td>
        <td class="udp">
          <ng-container *ngIf="details.custpackaging"><span>{{details.custpackaging}}</span></ng-container>
          <ng-container *ngIf="!details.custpackaging"><span *ngIf="details.variant_details">{{details.variant_details.packaging_value/1000}} {{details.variant_details.packaging_unit}}</span></ng-container>
        </td>
        <td class="packing">{{details.variant_details?.packing || details.custpacking || "NC"}}</td>
        <td class="price">{{ details.custprice/1000 || (details.price_details && details.price_details.price/1000) || "NC"}} &euro;</td>
        <td class="price">{{ details.custrawprice/1000 || (details.price_details && details.price_details.raw_price/1000) || "NC"}} &euro;<span class="ms-2" *ngIf="details.price_details?.zone">({{details.price_details?.zone}})</span></td>
        <td>
          <div class="text-center">
            <button class="btn btn-sm btn-danger me-2" (click)="removeDetails(details.id)"><i class="fa fa-trash"></i></button>
            <button class="btn btn-sm btn-primary" (click)="editCustomVariant(details)" *ngIf="!details.variant"><i class="fa fa-pencil"></i></button>
          </div>
        </td>
      </tr>
      <tr *ngIf="!offerproduct.details_details?.length">
        <td class="table-warning" colspan="9">
          <div class="text-center"><i class="fa fa-warning me-2"></i>Aucune nomenclature ni tarif associés</div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col-6"></div>
    <div class="col-6">
      <button class="btn btn-block w-100 btn-primary" (click)="cancelled.emit()"><i class="fa fa-times me-2"></i>Fermer</button>
    </div>
  </div>
</ng-container>