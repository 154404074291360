/**
 * Provider : model & service providers
 *
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @changed 2018-05-06-10:59:29
 * @date 2018-05-06-10:59:24
 * @copyright (c) 2017-2018 Jean-Matthieu BARBIER
 */

import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";
import {LEGALTYPES} from "../../../comps/constants";
import {Location} from "../location/location.service";
import {DetailDocument} from "../../document/basedocument/basedocument.service";

export class Provider extends DSModel {

  @DSAutoField({
    required: false,
    name: "id",
    helpText: "",
    verboseName: "ID",
    choices: [],
    default: null,
  })
  public id!: number;

  @DSCharField({
    name: "cvva",
    verboseName: "Code Vivalya",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 10,
    choices: []
  })
  public cvva!: string;


  @DSCharField({
    required: true,
    name: "name",
    helpText: "",
    verboseName: "Nom",
    choices: [],
    default: null,
    maxLength: 60,
  })
  public name!: string;


  @DSCharField({
    required: false,
    name: "type",
    helpText: "",
    verboseName: "Type de fournisseur",
    choices: [["FREF", "Fournisseur Référencé"]],
    default: null,
    maxLength: 3,
  })
  public type!: string;

  @DSCharField({
    required: false,
    name: "content_type",
    helpText: "",
    verboseName: "content type",
    choices: [],
    default: "md",
    maxLength: 4,
  })
  public content_type!: string;

  @DSTextField({
    required: false,
    name: "content_src",
    helpText: "",
    verboseName: "Description",
    choices: [],
    default: "",
    maxLength: 4000,
    minLength: 0
  })
  public content_src!: string;

  @DSTextField({
    required: false,
    name: "content",
    helpText: "",
    verboseName: "content",
    choices: [],
    default: null,
    maxLength: 4000,
  })
  public content!: string;

  @DSCharField({
    required: false,
    name: "phone",
    helpText: "",
    verboseName: "Téléphone",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public phone!: string;

  @DSCharField({
    required: false,
    name: "fax",
    helpText: "",
    verboseName: "Fax",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public fax!: string;

  @DSCharField({
    required: false,
    name: "website",
    helpText: "",
    verboseName: "Site internet",
    choices: [],
    default: null,
    maxLength: 400,
  })
  public website!: string;

  @DSCharField({
    required: false,
    name: "siren",
    helpText: "",
    verboseName: "SIREN",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public siren!: string;

  @DSCharField({
    required: false,
    name: "siret",
    helpText: "",
    verboseName: "SIRET",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public siret!: string;

  @DSCharField({
    required: false,
    name: "tvaic",
    helpText: "",
    verboseName: "TVAIC",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public tvaic!: string;

  @DSCharField({
    required: false,
    name: "naf",
    helpText: "",
    verboseName: "Code NAF",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public naf!: string;

  @DSCharField({
    required: false,
    name: "legal",
    helpText: "",
    verboseName: "Structure légale",
    choices: LEGALTYPES,
    default: null,
    maxLength: 50,
  })
  public legal!: string;

  @DSCharField({
    required: false,
    name: "capital",
    helpText: "",
    verboseName: "Capital",
    choices: [],
    default: null,
    maxLength: 20,
  })
  public capital!: string;

  @DSForeignKeyField({
    required: false,
    name: "logo",
    helpText: "",
    verboseName: "ID Logo",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
    remoteField: "logoprovider",
  })
  public logo!: number;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date de modification",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSManyToManyField({
    name: "slabels",
    verboseName: "Labels structure",
    required: false
  })
  public slabels!: number[];

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "Valide, en cours"],
      ["RS", "Résilié"],
      ["KO", "Non valide"]],
    default: null,
    maxLength: 4,
  })
  public status!: string;


  @DSDefaultField({
    required: false,
    name: "flags",
    verboseName: "Etiquettes"
  })
  public flags!: string[];


  @DSDateTimeField({
    name: "open_date",
    verboseName: "Date de référencement",
    required: false,
    helpText: "",
    choices: []
  })
  public open_date!: Date;

  @DSDateTimeField({
    name: "close_date",
    verboseName: "Date de fin de référencement",
    required: false,
    helpText: "",
    choices: []
  })
  public close_date!: Date;

  @DSBooleanField({
    name: "ref_1g",
    verboseName: "Référencé 1G",
    required: false,
    helpText: "Référencé 4G",
    default: false
  })
  public ref_1g!: boolean;

  @DSBooleanField({
    name: "ref_4g",
    verboseName: "Référencé 4G",
    required: false,
    helpText: "Référencé 4G",
    default: false
  })
  public ref_4g!: boolean;

  @DSBooleanField({
    name: "ref_5g",
    verboseName: "Référencé 5G",
    required: false,
    helpText: "Référencé 5G",
    default: false
  })
  public ref_5g!: boolean;


  @DSManyToManyField({
    name: "locations",
    verboseName: "Emplacements",
    required: false,
    relatedModel: "structure.Location",
    remoteField: "providers",
    helpText: "",
    default: [],
  })
  public locations!: number[];
  public locations_details!: Location[];

  @DSManyToManyField({
    required: false,
    name: "images",
    helpText: "",
    verboseName: "IDs images",
    choices: [],
    default: [],
  })
  public images!: number[];
  public images_details: Rimg[] = [];

  public logo_img!: Rimg;

  public mainlocation_details!: { city: string; postcode: string; address: string; latitude: number; longitude: number; country: string; country_details: {code: string; name: string}};


  @DSDefaultField({
    required: false,
    name: "article_count",
    helpText: "Nombre articles",
    verboseName: "Nombre d'articles"
  })
  public article_count!: number;


  @DSDefaultField({
    required: false,
    name: "producers_details",
    helpText: "Producteurs associés",
    verboseName: "Producteurs associés"
  })
  public producers_details!: { id: number; name: string }[];

  @DSManyToManyField({
    name: "documents",
    verboseName: "Documents fournisseur",
    required: false,
    relatedModel: "document.DetailDocument",
    remoteField: "articleproviderdocuments",
    helpText: "Documents fournisseur",
    default: [],
  })
  public documents!: number[];
  public documents_details!: DetailDocument[];
}

@Injectable({providedIn: "root"})
export class ProviderService extends DSRestCollection<Provider> {
  public adapter_config = {basePath: "/api/v2/structure/providers"};
  public model = Provider;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
