import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CustomDataKey} from "../../customdatakey/customdatakey.service";
import {Document, DocumentService} from "../../../document/document/document.service";

@Component({
  selector: "lvadg-customdata-value-edit-document",
  templateUrl: "./customdata-value-edit-document.component.pug",
  styleUrls: ["./customdata-value-edit-document.component.sass"]
})
export class CustomdataValueEditDocumentComponent implements OnInit {
  @Input() public key!: CustomDataKey;
  @Input() public value!: Document;
  @Output() public save = new EventEmitter<Document>();
  @Output() public clear = new EventEmitter<void>();

  constructor(public documents: DocumentService) {
  }

  ngOnInit() {
  }

}
