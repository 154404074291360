import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {Provider} from "../provider.service";

@Component({
  selector: "lvadg-provider-delete",
  templateUrl: "./provider-delete.component.pug",
  styleUrls: ["./provider-delete.component.sass"]
})
export class ProviderDeleteComponent implements OnChanges {

  @Input() public provider!: Provider;
  @Output() public removed: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cancelled: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public ngOnChanges(): void {
  }

  public delete(): void {
    this.provider.remove().subscribe((result) => {
      this.removed.emit(true);
    });
  }
}
