
<bsac-breadcrumb></bsac-breadcrumb>
<div class="card" *ngIf="create">
  <h4 class="card-header">Ajouter un fournisseur référencé</h4>
  <div class="card-body">
    <lvadg-provider-create (created)="created($event)" (cancelled)="create=false"></lvadg-provider-create>
  </div>
</div>
<h1 class="page-header">
  <button class="btn btn-info float-end" (click)="create=!create" *ngIf="mode==='admin'"><i class="fa fa-plus fa-fw"></i><span class="hidden-sm">Ajouter un fournisseur référencé</span></button><i class="fa fa-truck me-2"></i>Fournisseurs référencés
</h1>
<lvadg-provider-list *ngIf="ready" [name]="currentRoute.name" [mode]="mode" [displayMode]="mode==='user'?'vertical':'horizontal'" [detail_route]="provider_detail_route" [producer_detail_route]="producer_detail_route" [allowed_fields]="allowed_fields" [default_fields]="default_fields" [excluded_fields]="excluded_fields" [default_filters]="default_filters"></lvadg-provider-list>