import {Injectable} from "@angular/core";
import {DSCharField, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {ModelComm} from "../basecomm/modelcomm/modelcomm.service";


export class ModelPrint extends ModelComm {

  @DSCharField({
    name: "type",
    required: true,
    verboseName: "Type de l'impression",
    choices: [
      ["producer", "Fiche producteur"],
      ["producerv2", "Fiche producteur V2"],
      ["producerposter", "Affiche producteur"],
      ["offer", "Offre locale"],
      ["product", "Fiche produit"],
      ["productv2", "Fiche produit V2"],
      ["productposter", "Affiche produit"],
      ["productlist", "Liste de produits"],
      ["storage", "Fiche verte"],
      ["storageproducers", "Liste producteurs par dépôt"],
      ["seaproducer", "Fiche fournisseur marée"],
      ["seaproducerposter", "Affiche fournisseur marée"],
      ["seaoffer", "Offre locale marée"],
      ["seaproduct", "Fiche produit marée"],
      ["seaproductposter", "Affiche produit marée"],
      ["seaproductlist", "Liste de produits marée"],
      ["seastorage", "Fiche dépôt marée"],
      ["seastorageproducers", "Liste fournisseurs marée par dépôt"],
      ["productfamilylist", "Liste des familles de produits"],
      ["article", "Fiche article catalogue Vivalya"],
      ["articlelist", "Liste d'articles catalogue Vivalya"]
    ]
  })
  public type!: string;
}


@Injectable({
  providedIn: "root"
})
export class ModelPrintService extends DSRestCollection<ModelPrint> {
  public adapter_config = {basePath: "/api/v2/modelprints"};
  public model = ModelPrint;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
