import {Component} from "@angular/core";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonBaseComponent} from "../../common-base.component";
import {Article} from "../../../../lib/models/catalog/article/article.service";
import {ExternalArticle} from "../../../../lib/models/catalog/externalarticle/externalarticle.service";
import {BsacMessageService} from "@solidev/bsadmincomponents";


export interface CatalogExternalArticleListViewV3Params extends BaseRouteV3Params {
  article_detail_route: RouteConfigItemV3;
  externalarticle_detail_route: RouteConfigItemV3;
}

export interface CatalogExternalArticleListViewV3Data extends CatalogExternalArticleListViewV3Params {

}


@Component({
  selector: "lvadg-catalog-externalarticle-list-view",
  templateUrl: "./catalog-externalarticle-list-view.component.pug",
  styleUrls: ["./catalog-externalarticle-list-view.component.sass"]
})
export class CatalogExternalarticleListViewV3Component extends CommonBaseComponent {

  public article_detail_route!: RouteV3<Article>;
  public externalarticle_detail_route!: RouteV3<ExternalArticle>;

  public create!: any;
  public default_fields: string[] = ["id", "type", "client", "storage", "collection", "code", "libelle", "articles", "flags", "status", "created", "updated"];
  public allowed_fields: string[] = [];
  public excluded_fields: string[] = ["xlsxhash", "orig_data"];
  public default_filters: string[] = ["search", "clients", "tarifs", "articles", "assoc", "type", "statuses", "storages", "members"];
  public allowed_filters: string[] = [];
  public excluded_filters: string[] = [];

  public articleZone!: string;
  public articleType!: string;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute,
              private _msgs: BsacMessageService) {
    super(routes, router, route);
  }

  protected async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogExternalArticleListViewV3Data;
    this.article_detail_route = new RouteV3<Article>(data.article_detail_route, this);
    this.externalarticle_detail_route = new RouteV3<ExternalArticle>(data.externalarticle_detail_route, this);
    super.preNgOnInit();
  }

  public createdArticle($event: ExternalArticle): void {
    this.router.navigate(this.externalarticle_detail_route.route({externalArticle: $event}));
    this._msgs.success("Article créé avec succès");
  }
}
