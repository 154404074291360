
<h4><i class="fa fa-sync ms-2 float-end" (click)="refresh()"></i><i class="fa fa-clock me-2"></i>Historique des impressions</h4>
<ul class="list-group">
  <li class="list-group-item pointer" *ngFor="let job of jobs">
    <div class="job">
      <div class="desc" (click)="display[job.id]=!display[job.id]">
        <div class="printid"><i class="fa fa-print me-2"></i>Impression {{job.id}}</div>
        <div class="date">{{job.updated|date:"short"}}</div>
        <div class="status">
          <ng-container *ngIf="job.status!=='done'"><span class="me-3"><i class="fa fa-spinner fa-spin me-2"></i>{{job.progress}} %</span><span class="badge bg-warning">En cours</span></ng-container><span class="badge bg-success" *ngIf="job.status=='done'">Terminée</span>
        </div>
      </div>
      <div class="details" *ngIf="display[job.id]">
        <ul class="mt-2">
          <ng-container *ngFor="let pr of job.result?.pages">
            <li *ngIf="pr.url"><a [href]="pr.url" target="_blank"><i class="fa fa-file-pdf me-2"></i>{{pr.name}}</a></li>
          </ng-container>
          <li *ngIf="job.result?.print?.url"><a [href]="job.result?.print?.url" target="_blank"><i class="fa fa-file-pdf me-2"></i>Toutes les pages concaténées dans un seul pdf</a></li>
          <li *ngIf="job.result?.zip?.url"><a [href]="job.result?.zip?.url" target="_blank"><i class="fa fa-archive me-2"></i>Toutes les pages dans une archive zip</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>