import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {ArticleProcess} from "../../catalog/articledata/article-process.service";
import {ArticleTraitement} from "../../catalog/articledata/article-traitement.service";
import {ArticleRegion} from "../../catalog/articledata/article-region.service";
import {ArticlePresentation} from "../../catalog/articledata/article-presentation.service";
import {ArticleParticularite} from "../../catalog/articledata/article-particularite.service";
import {ArticleEgalim} from "../../catalog/articledata/article-egalim.service";
import {ArticleProduction} from "../../catalog/articledata/article-production.service";


export class Rnm extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSIntegerField({
    name: "cvva",
    verboseName: "Code Vivalya",
    required: false,
    helpText: "Code RNM",
    default: 0,
    choices: []
  })
  public cvva!: number;

  @DSCharField({
    name: "name",
    verboseName: "Libellé",
    required: false,
    helpText: "Libellé",
    minLength: 0,
    maxLength: 300,
    choices: []
  })
  public name!: string;

  @DSCharField({
    name: "cotation_unit",
    verboseName: "Unité de cotation",
    required: false,
    helpText: "Unité de cotation",
    minLength: 0,
    maxLength: 2,
    choices: [["K", "Kilogramme"],
      ["P", "Pièce"],
      ["C", "Colis"],
      ["L", "Palette"],
      ["B", "Barquette"]]
  })
  public cotation_unit!: string;

  @DSDecimalField({
    name: "cotation_value",
    verboseName: "Quantité de cotation",
    required: false,
    helpText: "Quantité de cotation",
    default: 0,
    factor: 10000,
    choices: []
  })
  public cotation_value!: number;

  @DSForeignKeyField({
    name: "process",
    verboseName: "Process",
    required: false,
    helpText: "Process",
    relatedModel: "",
    remoteField: "id",
    relatedName: ""
  })
  public process!: number;
  process_details: ArticleProcess;

  @DSForeignKeyField({
    name: "traitement",
    verboseName: "Traitement",
    required: false,
    helpText: "Traitement",
    relatedModel: "",
    remoteField: "id",
    relatedName: ""
  })
  public traitement!: number;
  public traitement_details!: ArticleTraitement;

  @DSForeignKeyField({
    name: "family",
    verboseName: "Famille",
    required: false,
    helpText: "Famille",
    relatedModel: "catalog.ArticleFamily",
    remoteField: "id",
    relatedName: "rnm"
  })
  public family!: number;
  public family_details!: { id: number; name: string; cfullname: string; };

  @DSForeignKeyField({
    name: "calibre",
    verboseName: "Calibre",
    required: false,
    helpText: "Calibre",
    relatedModel: "catalog.ArticleCalibre",
    remoteField: "id",
    relatedName: "rnm"
  })
  public calibre!: number;
  public calibre_details!: { id: number; code: string; name: string };

  @DSForeignKeyField({
    name: "categorie",
    verboseName: "Catégorie",
    required: false,
    helpText: "Catégorie",
    relatedModel: "catalog.ArticleCategorie",
    remoteField: "id",
    relatedName: "rnm"
  })
  public categorie!: number;
  public categorie_details!: { id: number; code: string; name: string };

  @DSForeignKeyField({
    name: "origine",
    verboseName: "Origine",
    required: false,
    helpText: "Origine",
    relatedModel: "catalog.ArticleOrigine",
    remoteField: "id",
    relatedName: "rnm"
  })
  public origine!: number;
  public origine_details!: { id: number; code: string; name: string };

  @DSCharField({
    name: "origine_glob",
    verboseName: "Origine globale",
    required: false,
    helpText: "Origine globale",
    minLength: 0,
    maxLength: 3,
    choices: [["FR", "France"],
      ["UE", "Europe"],
      ["IMP", "Import"]]
  })
  public origine_glob!: string;

  @DSForeignKeyField({
    name: "region",
    verboseName: "Région",
    required: false,
    helpText: "Région",
    relatedModel: "",
    remoteField: "id",
    relatedName: ""
  })
  public region!: number;
  public region_details!: ArticleRegion;

  @DSForeignKeyField({
    name: "presentation",
    verboseName: "Présentation",
    required: false,
    helpText: "Présentation",
    relatedModel: "",
    remoteField: "id",
    relatedName: ""
  })
  public presentation!: number;
  public presentation_details!: ArticlePresentation;

  @DSForeignKeyField({
    name: "particularite",
    verboseName: "Particularité",
    required: false,
    helpText: "Particularité",
    relatedModel: "",
    remoteField: "id",
    relatedName: ""
  })
  public particularite!: number;
  public particularite_details!: ArticleParticularite;

  @DSManyToManyField({
    name: "egalim",
    verboseName: "Egalim",
    required: false,
    relatedModel: "Egalim",
    remoteField: "",
    helpText: "",
    default: [],
  })
  public egalim!: number[];
  public egalim_details!: ArticleEgalim[];

  @DSForeignKeyField({
    name: "production",
    verboseName: "Production",
    required: false,
    helpText: "Production",
    relatedModel: "",
    remoteField: "id",
    relatedName: ""
  })
  public production!: number;
  public production_details!: ArticleProduction;

  @DSDecimalField({
    name: "uci_value",
    verboseName: "UCI",
    required: false,
    helpText: "UCI",
    factor: 10000,
    choices: []
  })
  public uci_value!: number;


  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;


  @DSDefaultField({
    name: "libelles_details",
    verboseName: "Libellés",
    required: false,
    helpText: "Libellés",
    default: [],
  })
  public libelles_details!: { id: number; libelle: string; ulibelle: string }[];


  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags!: any;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class RnmService extends DSRestCollection<Rnm> {
  public adapter_config = {basePath: "/api/v2/tarifs/rnm"};
  public model = Rnm;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
