import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField,
  DSTextField
} from "@solidev/ngdataservice";


export class ArticleCalibre extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "vvid",
    verboseName: "ID Vivalya",
    required: false,
    helpText: "ID Vivalya",
    minLength: 0,
    maxLength: 16,
    choices: []
  })
  public vvid!: string;


  @DSSlugField({
    name: "code",
    verboseName: "Code",
    required: false,
    helpText: "Code",
    minLength: 0,
    maxLength: 60,
    choices: []
  })
  public code!: string;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 2,
    choices: [
      ["FL", "F&L"],
      ["MR", "Marée"],
      ["MS", "Non précisé"]
    ]
  })
  public type!: string;


  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public name!: string;

  @DSForeignKeyField({
    name: "family",
    verboseName: "Famille",
    required: false,
    helpText: "Famille correspondante",
    relatedModel: "ArticleFamily",
    remoteField: "id",
    relatedName: "articlecalibre"
  })
  public family!: number;
  public family_details!: { id: number; cfullname: string };

  @DSTextField({
    name: "content_src",
    verboseName: "Description (source)",
    required: false,
    helpText: "Description (source md)",
    minLength: 0,
    maxLength: 20000,
    choices: []
  })
  public content_src!: string;
  public content!: string;

  @DSCharField({
    name: "poids",
    verboseName: "Poids",
    required: false,
    helpText: "Description poids",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public poids!: string;

  @DSCharField({
    name: "taille",
    verboseName: "Taille",
    required: false,
    helpText: "Description taille",
    minLength: 0,
    maxLength: 100,
    choices: []
  })
  public taille!: string;

  @DSCharField({
    name: "nombre",
    verboseName: "Nombre",
    required: false,
    helpText: "Description nombre",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public nombre!: string;




}

@Injectable({
  providedIn: "root"
})
export class ArticleCalibreService extends DSRestCollection<ArticleCalibre> {
  public adapter_config = {basePath: "/api/v2/catalog/fields/calibres"};
  public model = ArticleCalibre;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
