
<div class="loading" *ngIf="loading">
  <div class="text-center mt-4 text-muted"><i class="fa fa-spinner fa-pulse fa-10x"></i></div>
</div>
<div class="display" *ngIf="!edit &amp;&amp; !create">
  <div class="row">
    <div class="col-md-6">
      <div class="alert alert-danger pointer" *ngIf="!loading &amp;&amp; !locations.length" (click)="create=true"><i class="fa fa-exclamation-triangle me-2 fa-3x float-start"></i>Aucune localisation n'a été renseignée. Merci d'ajouter au moins un emplacement principal géolocalisé.</div>
      <ng-container *ngIf="!loading &amp;&amp; locations.length">
        <div class="table-responsive">
          <table class="table table-condensed table-striped">
            <thead>
              <tr>
                <th>Type</th>
                <th>Adresse</th>
                <th>Contact</th>
                <th>Etat</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let l of locations">
                <tr class="pointer" (click)="edit=l" [class.table-danger]="!(l.status=='AY' || l.status=='OK')">
                  <td>{{l.type|bsacApiConstant:'LOCATION_TYPES'}}</td>
                  <td>{{l.name}} {{l.locname}}<br/>{{l.address}} {{l.caddress}}<br/>{{l.postcode}} {{l.city}} {{l.cedex}} {{l.country}}
                    <ng-container *ngIf="mode=='admin'"><br><small>{{l.georegion_details?.name}} &gt; {{l.geodepartement_details?.name}} &gt; {{l.geocommune_details?.postcode}} {{l.geocommune_details?.name}}</small></ng-container>
                  </td>
                  <td>{{l.phone}} {{l.fax}}<br/>{{l.website}}</td>
                  <td>{{l.status | bsacApiConstant:'LOCATION_STATUSES'}}
                    <ng-container *ngIf="mode=='admin'"><br>
                      <div class="badge mx-1 bg-primary" *ngFor="let f of l.flags">{{f}}</div>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <button class="btn btn-link btn-block w-100" (click)="create=true"><i class="fa fa-plus me-2"></i>Ajouter une adresse</button>
      </ng-container>
    </div>
    <div class="col-md-6">
      <ng-container *ngIf="!loading &amp;&amp; locations.length">
        <lvadg-locations-osm-display *ngIf="type=='producer'" [producerLocations]="locations" [storageLocations]="storages" [boundary]="'auto'"></lvadg-locations-osm-display>
        <lvadg-locations-osm-display *ngIf="type=='seaproducer'" [seaproducerLocations]="locations" [storageLocations]="storages" [boundary]="'auto'"></lvadg-locations-osm-display>
        <lvadg-locations-osm-display *ngIf="type=='storage'" [storageLocations]="locations" [producerLocations]="producers" [boundary]="'auto'"></lvadg-locations-osm-display>
        <lvadg-locations-osm-display *ngIf="type=='provider'" [providerLocations]="locations" [boundary]="'auto'"></lvadg-locations-osm-display>
        <lvadg-locations-osm-display *ngIf="type=='member'" [memberLocations]="locations" [storageLocations]="storages" [boundary]="'auto'"></lvadg-locations-osm-display>
        <lvadg-locations-osm-display *ngIf="type=='resto'" [restoLocations]="locations" [storageLocations]="storages" [boundary]="'auto'"></lvadg-locations-osm-display>
      </ng-container>
    </div>
  </div>
</div>
<div class="edit" *ngIf="edit">
  <lvadg-location-manage [location]="edit" [step]="step" (closed)="edit=null" (deleted)="onDeleted($event)"></lvadg-location-manage>
</div>
<div class="create" *ngIf="create">
  <lvadg-location-create (created)="onCreated($event)" (cancelled)="onCancelCreate()" [type]="type" [model]="model"></lvadg-location-create>
</div>