import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Folder, FolderService} from "../folder.service";
import {FolderTree, FolderTreeService} from "../folder-tree.service";
import {Subject} from "rxjs";

@Component({
  selector: "lvadg-folder-tree",
  templateUrl: "./folder-tree.component.pug",
  styleUrls: ["./folder-tree.component.sass"]
})
export class FolderTreeComponent implements OnInit, OnDestroy {

  @Input() parent: Folder|null = null;
  @Input() treeName = "default";
  @Input() public tree!: FolderTree;
  public folders: Folder[] = [];
  private _subscriptions = new Subject<void>();

  constructor(private _folders: FolderService,
              private _trees: FolderTreeService) {
  }

  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public ngOnInit(): void {
    if (!this.tree) {
      this.tree = this._trees.load(this.treeName);
    }
    this.tree.getSubFolders(this.parent).subscribe((result) => {
      this.folders = result;
    });

  }

  public open(f: Folder) {
    this.tree.open(f);
  }

  public setCurrent(f: Folder) {
    this.tree.setCurrent(f);
  }
}
