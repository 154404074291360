import {Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from "@angular/core";
import {firstValueFrom, Subject} from "rxjs";
import {first} from "rxjs/operators";
import {filter} from "lodash-es";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BaseProductPrice} from "../../../product/_productprice/productprice-base.model";
import {BaseProductVariant} from "../../../product/_productvariant/productvariant-base.model";
import {BaseProduct} from "../../../product/_product/product-base.service";
import {BaseOfferProduct} from "../baseofferproduct.service";
import {DSRestCollection} from "@solidev/ngdataservice";
import {BaseProducer} from "../../../structure/_producer/producer-base.model";
import {Offer} from "../../offer/offer.service";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class OfferManageProductBaseComponent<P extends BaseProduct,
  PR extends BaseProducer,
  OP extends BaseOfferProduct,
  PV extends BaseProductVariant,
  PP extends BaseProductPrice> implements OnInit, OnDestroy, OnChanges {
  @Input() public offer!: Offer;
  @Input() public product!: P;
  @Input() public offerproduct?: OP;
  @Input() public zone!: string;
  @Output() public added = new EventEmitter<OP>();
  @Output() public cancelled = new EventEmitter<void>();


  public variants!: PV[];
  public prices!: PP[];
  public customId!: number|null;
  public editCustom = false;
  public addCustom = false;
  public customForm!: UntypedFormGroup;

  private _subscriptions$ = new Subject<void>();

  constructor(public _producers: DSRestCollection<PR>,
              public _products: DSRestCollection<P>,
              public _offerproducts: DSRestCollection<OP>,
              public _variants: DSRestCollection<PV>,
              public _prices: DSRestCollection<PP>,
              private _fb: UntypedFormBuilder) {
  }

  public ngOnInit(): void {
    this.customForm = this._fb.group({
      custcaliber: [""],
      custccode: [""],
      custname: [""],
      custpackaging: [""],
      custprice: [0],
      custrawprice: [0],
      custsalesunit: [""],
      custpacking: [""]
    });
  }

  public ngOnChanges(): void {
    if (this.offer.client) {
      this._variants.queryset
        .query({product: this.product.id, client: this.offer.client})
        .get()
        .pipe(first())
        .subscribe((variants) => {
          this.variants = variants.items;
        });
      this._prices.queryset
        .query({product: this.product.id, client: this.offer.client, validforoffer: this.offer.id})
        .get()
        .pipe(first())
        .subscribe((prices) => {
          this.prices = prices.items;
        });
    } else {
      this.variants = [];
      this.prices = [];
    }
    this.updateOfferProduct();
  }

  public getPrices(variant: PV): PP[] {
    return filter(this.prices, (item) => item.variant === variant.id);
  }

  public ngOnDestroy(): void {
    this._subscriptions$.next();
    this._subscriptions$.complete();
  }

  public async updateOfferProduct(): Promise<void> {
    const offerproducts = await firstValueFrom(this._offerproducts.queryset
      .query({offer: this.offer.id, product: this.product.id, details: 1})
      .get());
    if (offerproducts.items.length > 0) {
      this.offerproduct = offerproducts.items[0];
    } else {
      this.offerproduct = undefined;
    }
  }

  public async addEmpty(): Promise<void> {
    if (!this.offerproduct) {
      await this.createOfferProduct();
    }
    await this.updateOfferProduct();
  }

  public async addWithoutPrice(variant: PV): Promise<void> {
    if (!this.offerproduct) {
      await this.createOfferProduct();
    }
    await firstValueFrom(this.offerproduct!.action(
      "add_detail",
      {
        method: "POST", body: {
          offerproduct: this.offerproduct!.id,
          variant: variant.id
        }
      }));
    await this.updateOfferProduct();
  }

  public async addWithPrice(variant: PV, price: PP): Promise<void> {
    if (!this.offerproduct) {
      await this.createOfferProduct();
    }
    await firstValueFrom(this.offerproduct!.action(
      "add_detail",
      {
        method: "POST", body: {
          offerproduct: this.offerproduct!.id,
          variant: variant.id,
          price: price.id
        }
      }));
    await this.updateOfferProduct();
  }

  public async addWithCustom(): Promise<void> {
    if (!this.offerproduct) {
      await this.createOfferProduct();
    }
    const data = this.customForm.value;
    data.offerproduct = this.offerproduct?.id;
    if (data.custprice) {
      data.custprice = data.custprice * 1000;
    }
    if (data.custrawprice) {
      data.custrawprice = data.custrawprice * 1000;
    }
    await firstValueFrom(this.offerproduct!.action("add_detail",
      {
        method: "POST",
        body: data
      }));
    await this.updateOfferProduct();
    this.addCustom = this.editCustom = false;
  }

  public async createOfferProduct(): Promise<void> {
    await this.offer.action("add_product", {method: "POST", body: {product: this.product.id}}).toPromise();
    await this.updateOfferProduct();
  }

  public addCustomVariant(): void {
    this.customForm.reset();
    this.customId = null;
    this.addCustom = true;
    this.editCustom = false;
  }

  public editCustomVariant(details: any): void {
    this.customForm.reset(details);
    this.customForm.patchValue({custprice: details.custprice / 1000});
    this.customId = details.id;
    this.addCustom = false;
    this.editCustom = true;
  }

  public async removeDetails(id: number): Promise<void> {
    await firstValueFrom(this.offerproduct!.action("remove_detail",
      {
        method: "POST",
        body: {item: id}
      }));
    await this.updateOfferProduct();
  }

  public async editDetails(): Promise<void> {
    const data = this.customForm.value;
    data.id = this.customId;
    data.offerproduct = this.offerproduct?.id;
    data.custprice = data.custprice * 1000;
    data.curstrawprice = data.custrawprice * 1000;
    await firstValueFrom(this.offerproduct!.action("edit_detail",
      {
        method: "POST",
        body: data
      }));
    await this.updateOfferProduct();
    this.editCustom = false;
  }

  public cancelCustom(): void {
    this.customForm.reset();
    this.customId = null;
    this.addCustom = false;
    this.editCustom = false;
  }
}
