import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StorageSelectComponent} from "./storage-select/storage-select.component";
import {BsacFormsModule} from "@solidev/bsadmincomponents";


@NgModule({
  imports: [
    CommonModule,
    BsacFormsModule,
  ],
  declarations: [
    StorageSelectComponent
  ],
  exports: [
    StorageSelectComponent
  ],
})
export class StorageSelectModule {
}
