import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";


export class Group extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "slug",
    verboseName: "Slug",
    required: false,
    helpText: "Slug",
    minLength: 0,
    maxLength: 100,
    choices: [],
    pattern: "^[0-9a-z:-]+$"
  })
  public slug!: string;

  @DSCharField({
    name: "description",
    verboseName: "Description",
    required: false,
    helpText: "Description",
    minLength: 0,
    maxLength: 255,
    choices: []
  })
  public description!: string;


  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSDefaultField({
    required: false,
    name: "users_count",
    helpText: "Nb Utilisateurs",
    verboseName: "# utilisateurs",
  })
  public users_count!: number;

}

@Injectable({
  providedIn: "root"
})
export class GroupService extends DSRestCollection<Group> {
  public adapter_config = {basePath: "/api/v2/groups"};
  public model = Group;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
