import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {SeaProducer, SeaProducerService} from "./seaproducer.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class SeaProducerResolve implements Resolve<SeaProducer> {
  constructor(private _bs: SeaProducerService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<SeaProducer> {
    return this._bs.get(+route.params["seaproducerId"]);
  }
}
