
<div class="mb-3" *ngIf="step==1">
  <lvadg-upload-file [url]="uploadImportUrl" (uploaded)="onUploaded($event)" [data]="{code: code, type: type}"></lvadg-upload-file>
</div>
<div class="mb-3" *ngIf="step==2">
  <p class="text-warning"><span *ngIf="result?.changed?.length">Nouveaux prix détectés, merci de vérifier leur validité avant de confirmer.</span><span *ngIf="!result?.changed?.length">Aucun changement de prix détecté.</span><span class="pointer ms-2" (click)="showLog=!showLog">[log]</span></p>
  <lvadg-oplog-display [result]="result!.log" *ngIf="result?.log &amp;&amp; showLog"></lvadg-oplog-display>
  <table class="table table-sm table-striped" *ngIf="result?.changed?.length">
    <thead>
      <tr>
        <th>Libellé</th>
        <th>Ancien prix</th>
        <th>Nouveau prix</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of result?.changed">
        <td>{{c.libelle}}</td>
        <th>
          <ng-container *ngIf="c.oldprice">{{c.oldprice/10000|currency:'EUR'}}</ng-container>
          <ng-container *ngIf="!c.oldprice">Absent</ng-container>
        </th>
        <th>
          <ng-container *ngIf="c.newprice">{{c.newprice/10000|currency:'EUR'}}</ng-container>
          <ng-container *ngIf="!c.newprice">Absent</ng-container>
        </th>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col">
      <button class="btn btn-outline-primary w-100" (click)="validateImport(true)"><i class="fa fa-check me-2"></i>Valider l'importation</button>
    </div>
    <div class="col">
      <button class="btn btn-outline-secondary w-100" (click)="validateImport(false)"><i class="fa fa-check me-2"></i>Annuler l'importation</button>
    </div>
  </div>
</div>
<p class="text-success" *ngIf="step==3">Importation réalisée avec succès</p>
<button [ngClass]="btnClasses" (click)="displayImport()" *ngIf="step==0 || step==3"><i class="fa fa-file-spreadsheet me-2"></i>Réimporter le tarif excel</button>