import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export class Offer extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "parent",
    verboseName: "Offre parente",
    required: false,
    helpText: "Offre parente",
    relatedModel: "Offer",
    remoteField: "id",
    relatedName: "children"
  })
  public parent!: number;


  @DSCharField({
    name: "type",
    verboseName: "Type d'offre locale",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 200,
    default: "FL",
    choices: [["FL", "Fruits & légumes"], ["SEA", "Marée"]]
  })
  public type!: string;

  @DSCharField({
    name: "title",
    verboseName: "Titre",
    required: true,
    helpText: "",
    choices: [],
    minLength: 3,
    maxLength: 255
  })
  public title!: string;

  @DSForeignKeyField({
    name: "member",
    verboseName: "Adhérent",
    required: false,
    helpText: "",
    choices: []
  })
  public member!: number;
  public member_details: {id: number; name: string;}

  @DSForeignKeyField({
    name: "storage",
    verboseName: "Dépôt ID",
    required: true,
    helpText: "",
    choices: []
  })
  public storage!: number;

  @DSDefaultField({
    name: "storage_details",
    verboseName: "Dépôt",
    required: false,
    helpText: "",
    choices: []
  })
  public storage_details!: { id: number; name: string };

  @DSForeignKeyField({
    name: "client",
    verboseName: "Client ID",
    required: false,
    helpText: "",
    choices: []
  })
  public client!: number;

  @DSDefaultField({
    name: "client_details",
    verboseName: "Client",
    required: false,
    helpText: "",
    choices: []
  })
  public client_details!: { id: number; name: string };


  @DSManyToManyField({
    name: "restos",
    verboseName: "Restaurants (ids)",
    required: false,
    helpText: "",
    choices: []
  })
  public restos!: number[];

  @DSDefaultField({
    name: "restos_details",
    verboseName: "Restaurants",
    required: false,
  })
  public restos_details!: { id: number; name: string }[];

  @DSForeignKeyField({
    name: "author",
    verboseName: "Auteur",
    required: false,
    helpText: "",
    choices: []
  })
  public author!: number;

  @DSDateTimeField({
    name: "datestart",
    verboseName: "Date de début",
    required: true,
    helpText: "",
    choices: []
  })
  public datestart!: Date;

  @DSDateTimeField({
    name: "dateend",
    verboseName: "Date de fin",
    required: true,
    helpText: "",
    choices: []
  })
  public dateend!: Date;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    verboseName: "",
    required: false,
    helpText: "",
    choices: [["PRP", "En préparation"], ["CUR", "En cours"], ["ARC", "Archivée"]],
    maxLength: 4
  })
  public status!: string;
}

@Injectable({
  providedIn: "root"
})
export class OfferService extends DSRestCollection<Offer> {
  public adapter_config = {basePath: "/api/v2/offers"};
  public model = Offer;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
