import {Rimg} from "../../../comps/rimg/rimg";
import {
  DSAutoField,
  DSBooleanField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSTextField
} from "@solidev/ngdataservice";
import {CustomData} from "../../customdata/customdata/customdata.service";
import {Location} from "../../structure/location/location.service";

export interface ProductProducerLocationDetails {
  id: number;
  latitude: number;
  longitude: number;
  city: string;
  address: string;
  caddress: string;
  cedex: string;
  postcode: string;
  type: string;
  status: string;
  phone: string;
  fax: string;
  website: string;
  producer: number;
  seaproducer: number;
}

export interface ProductProducerDetails {
  id: number;
  name: string;
  mainlocation_details: Location;
  status: string;
}

export interface ProductStoragesDetails {
  id: number;
  psid: number;
  name: string;
  member_details: {id: number, name: string};
  mainlocation_details: {
    id: number,
    latitude: number,
    longitude: number,
    city: string,
    postcode: string,
    address: string,
  };
  distance: number;
  status: string;
}

export interface ProductOfferPriceDetails {
  id: number;
  date_start: Date;
  date_end: Date;
  price: number;
  raw_price: number;
  zone: string;
  importref: string;
  variant: number;
  created: Date;
  updated: Date;
  status: string;
}

// tslint:disable-next-line:no-empty-interface
export interface SeaProductOfferPriceDetails extends ProductOfferPriceDetails {

}

export interface ProductOfferVariantDetails {
  id: number;
  client: number;
  ccode: string;
  vcode: string;
  excluded: number[];
  families: number[];
  importref: string;
  lname: string;
  sname: string;
  fname: string;
  caliber: string;
  packaging_unit: string;
  packaging_value: number;
  packing: string;
  sales_unit: string;
  sales_value: number;
  created: Date;
  updated: Date;
  status: string;
}

// tslint:disable-next-line:no-empty-interface
export interface SeaProductOfferVariantDetails extends ProductOfferVariantDetails {

}

export interface ProductOfferDetails {
  id: number;
  offer: number;
  details_details: ProductOfferDetailsDetails[];
  order: number;
  product: number;
  created: Date;
  updated: Date;
  status: string;
}

export interface SeaProductOfferDetails extends ProductOfferDetails {
  details_details: SeaProductOfferDetailsDetails[];
}


export interface ProductOfferDetailsDetails {
  created: Date;
  custcaliber: string;
  custccode: string;
  custname: string;
  custpackaging: string;
  custprice: number;
  custsalesunit: string;
  custrawprice: number;
  custpacking: string;
  packing: string;
  id: number;
  offerproduct: number;
  price: number;
  price_details: ProductOfferPriceDetails;
  status: string;
  update: Date;
  variant: number;
  variant_details: ProductOfferVariantDetails;
}

export interface SeaProductOfferDetailsDetails extends ProductOfferDetailsDetails {
  price_details: SeaProductOfferPriceDetails;
  variant_details: SeaProductOfferVariantDetails;

}

export class BaseProduct extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSManyToManyField({
    name: "storages",
    verboseName: "IDS Dépôts",
    required: false,
    helpText: "",
    choices: []
  })
  public storages!: number[];

  @DSCharField({
    name: "content_type",
    verboseName: "Format description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 4
  })
  public content_type!: string;

  @DSTextField({
    name: "content_src",
    verboseName: "Description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 4000
  })
  public content_src!: string;

  @DSTextField({
    name: "content",
    verboseName: "Description (html)",
    required: false,
    helpText: "",
    choices: []
  })
  public content!: string;

  @DSTextField({
    name: "ccontent",
    verboseName: "Description famille (html)",
    required: false,
    helpText: "",
    choices: []
  })
  public ccontent!: string;

  @DSForeignKeyField({
    name: "family",
    verboseName: "Famille",
    required: false,
    helpText: "",
    choices: []
  })
  public family!: number;

  @DSForeignKeyField({
    name: "producer",
    verboseName: "ID Producteur",
    required: false,
    helpText: "",
    choices: []
  })
  public producer!: number;

  @DSForeignKeyField({
    name: "producerlocation",
    verboseName: "ID Localisation producteur",
    required: false,
    helpText: "",
    choices: []
  })
  public producerlocation!: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 200
  })
  public name!: string;

  @DSDefaultField({
    name: "seasons",
    verboseName: "Saisons",
    required: false,
    helpText: "",
    choices: []
  })
  public seasons!: any;

  @DSDefaultField({
    name: "cseasons",
    verboseName: "Saisons (famille)",
    required: false,
    helpText: "",
    choices: []
  })
  public cseasons!: any;

  @DSManyToManyField({
    name: "labels",
    verboseName: "Labels",
    required: false,
    helpText: "",
    choices: []
  })
  public labels!: number[];

  @DSForeignKeyField({
    name: "logo",
    verboseName: "ID Logo",
    required: false,
    helpText: "",
    choices: []
  })
  public logo!: number;

  @DSManyToManyField({
    name: "images",
    verboseName: "ID Images",
    required: false,
    helpText: "",
    choices: []
  })
  public images!: number[];

  @DSBooleanField({
    name: "inoffer",
    verboseName: "Dans l'offre ?",
    required: false,
    helpText: "",
    choices: []
  })
  public inoffer!: boolean;

  @DSDefaultField({
    name: "offer_details",
    verboseName: "Détails offre locale",
    required: false,
    helpText: "",
    choices: []
  })
  public offer_details!: ProductOfferDetails[];

  @DSDefaultField({
    name: "producer_details",
    verboseName: "Détails producteur",
    required: false,
    helpText: "",
    choices: []
  })
  public producer_details!: ProductProducerDetails;

  @DSDefaultField({
    name: "storages_details",
    verboseName: "Détails dépôts",
    required: false,
    helpText: "",
    choices: []
  })
  public storages_details!: ProductStoragesDetails[];

  @DSDefaultField({
    name: "logo_img",
    verboseName: "Détails logo",
    required: false,
    helpText: "",
    choices: []
  })
  public logo_img!: Rimg;

  @DSDefaultField({
    name: "clogo_img",
    verboseName: "Détails logo famille",
    required: false,
    helpText: "",
    choices: []
  })
  public clogo_img!: Rimg;

  @DSDefaultField({
    name: "images_details",
    verboseName: "Détails images",
    required: false,
    helpText: "",
    choices: []
  })
  public images_details!: Rimg[];

  @DSDefaultField({
    name: "family_details",
    verboseName: "Détails catégorie",
    required: false,
    helpText: "",
    choices: []
  })
  public family_details!: any;

  @DSDefaultField({
    name: "labels_details",
    verboseName: "Détails labels",
    required: false,
    helpText: "",
    choices: []
  })
  public labels_details!: any;

  @DSDefaultField({
    name: "tags",
    verboseName: "Tags",
    required: false,
    helpText: "",
    choices: []
  })
  public tags!: any;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 4
  })
  public status!: string;

  public customdata_details!: CustomData[];
  public cfcustomdata_details!: CustomData[];

  // TODO: is this function obsolete nown that we have cfullname build in ?
  public get namepath(): string {
    function getParent(plist: string[], item: any): void {
      if (item.parent) {
        getParent(plist, item.parent);
      }
      plist.push(item.name);
    }

    const parents = [] as any[];
    if (this.family_details) {
      getParent(parents, this.family_details.parent);
      parents.push(this.family_details.name);
      return parents.join(" / ");
    }
    return "";
  }
}
