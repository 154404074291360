import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {ArticleTarif, ArticleTarifService, ArticleTarifStatus} from "../../articletarif.service";
import {ArticleTarifItem} from "../../articletarifitem.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Tarif} from "../../../tarif/tarif.service";
import {firstValueFrom, Subject} from "rxjs";
import {IEditorData} from "../editors";
import {filter, takeUntil} from "rxjs/operators";


@Component({
  selector: "lvadg-articletarif-price-editor",
  templateUrl: "./articletarif-price-editor.component.pug",
  styleUrls: ["./articletarif-price-editor.component.sass"]
})
export class ArticletarifPriceEditorComponent implements OnInit, OnChanges, OnDestroy {
  /** Tarif reference */
  @Input() public tarif!: Tarif;
  /** Article tarif reference */
  @Input() public at!: ArticleTarif;
  /** Article tarif item (if available) */
  @Input() public ati?: ArticleTarifItem;
  /** Is globally editable ? (from column type) */
  @Input() public editable: boolean = true;
  /** External reference (storage id) */
  @Input() public storageId!: number;
  // TODO: see why marche could be editable ?
  /** External reference (marche id) */
  @Input() public marcheId!: string;
  /** Editor index in table (from row index) */
  @Input() public index!: number;
  /** Editor type */
  @Input() public type!: string;
  /** Focus subject, tells which editor should receive focus */
  @Input() public focus$?: Subject<IEditorData>;
  /** Updates subject, to check if internal update is needed */
  @Input() public updates$?: Subject<ArticleTarif>;

  /** Data have been updated */
  @Output() public updated = new EventEmitter<IEditorData>();
  // TODO: check if this could be passed through editorData
  /** Editor have been selected, sends current articleTarif */
  @Output() public selected = new EventEmitter<ArticleTarif>();
  // TODO: check if this could be passed through editorData
  /** Editor have been selected, returns current column */
  @Output() public curcol = new EventEmitter<string>();
  /** Emit editorData to be registered (on creation and changes) */
  @Output() public register = new EventEmitter<IEditorData>();
  /** Emit editorData to be unregistered (on destory and changes) */
  @Output() public unregister = new EventEmitter<IEditorData>();
  /** Emit to focus next editor */
  @Output() public next = new EventEmitter<IEditorData>();
  /** Emit to focus previous editor */
  @Output() public previous = new EventEmitter<IEditorData>();
  /** Editor view child, for focus and selection */
  @ViewChild("editor") editorEl?: ElementRef<HTMLInputElement>;

  /** Current editor data */
  public editor!: IEditorData;
  /** Form have been changed */
  public changed: boolean = false;
  /** Editor form group */
  public form = new UntypedFormGroup({price: new UntypedFormControl("")});

  /** Mode : price / dispo */
  public mode: "price" | "dispo" = "price";
  /** Availabbility */
  public available: number = 0;

  // Current subscriptions
  private _subscriptions = new Subject<void>();

  public ArticleTarifStatus = ArticleTarifStatus;

  constructor(private _at: ArticleTarifService) {
  }

  /**
   * Return true if editor is editable (editable from column and articleTarifTemplate status)
   */
  public get isEditable() {
    return this.editable && this.at.status != ArticleTarifStatus.INACTIVE;
  }

  /**
   * Return suitable mode (price / availability) for this editor
   */
  public get curMode(): "price" | "dispo" {
    if (this.storageId && this.tarif.type_details.storages_mode === "DISP") {
      return "dispo";
    }
    return "price";
  }

  /**
   * Return true if price have not been edited/validated
   */
  public get isPristine(): boolean {
    return !!(
      (this.ati && this.ati.status === "TV")
      || (!this.storageId && this.at.status === ArticleTarifStatus.TOVALIDATE)
      || (this.storageId && !this.ati));
  }

  /**
   * On changes, register / unregister editor.
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (this.isEditable) {
      this.editor = {type: this.type, id: this.index, at: this.at};
      this.mode = this.curMode;
      this.available = this.ati?.available ? this.ati.available : 0;
      this.initForms();
      this.register.emit(this.editor);
    } else {
      this.editor = {type: this.type, id: this.index, at: this.at};
      this.available = this.ati?.available ? this.ati.available : 0;
      this.mode = this.curMode;
      this.unregister.emit(this.editor);
    }
  }

  /**
   * On destroy, unregister editor, and complete all subscriptions.
   */
  public ngOnDestroy() {
    this.unregister.emit(this.editor);
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  /**
   * Format EU price.
   * @param price number
   */
  public formatPrice(price: number): string {
    return (price / 10000).toLocaleString("fr-FR", {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
      useGrouping: false
    });
  }

  /**
   * On init :
   * - subscribes to focus$
   * - subscribes to updates$
   * - sets form value
   * - subscribes to form change
   */
  public ngOnInit(): void {
    // Subscribes to focus (but only activate focus if isEditable)
    if (this.focus$) {
      this.focus$.pipe(takeUntil(this._subscriptions))
        .subscribe((editor) => {
          if (editor.type === this.editor.type && editor.id === this.editor.id && this.isEditable) {
            this.editorEl?.nativeElement.focus({preventScroll: false});
            this.editorEl?.nativeElement.select();
          }
        });
    }
    // Subscribes to updates (for now, just to check ngOnChanges)
    if (this.updates$) {
      this.updates$.pipe(
        takeUntil(this._subscriptions),
        filter((up) => up.id === this.at.id))
        .subscribe(() => {
          this.ngOnChanges({});
        });
    }
    this.initForms();
    this.form.valueChanges.pipe(takeUntil(this._subscriptions))
      .subscribe((val) => {
        this.changed = true;
      });
  }

  /**
   * Init forms with at/ati prices
   */
  public initForms() {
    // Sets initial form values, subscribes to form changes
    if (this.editable) {
      if (this.ati) {
        this.setFormPrice(this.ati.price);
      }
      if (!this.ati && !this.storageId && !this.marcheId) {
        this.setFormPrice(this.at.price);
      }
    }
  }

  /**
   * Set form price from rprice number or null
   * Use formatPrice to get price format
   * @param rprice
   */
  public setFormPrice(rprice: number | null) {
    if (rprice !== null && rprice !== 0) {
      this.form.setValue({price: this.formatPrice(rprice)}, {emitEvent: false});
    } else {
      this.form.setValue({price: "NC"}, {emitEvent: false});
    }
  }

  /**
   * Save price, and optionnaly moves to next/previous.
   * Uses update_price method to save price, using either :
   * - direct at price update
   * - ati price update
   * - ati "creation" with storageId price update.
   * @param toNext moves next if true
   * @param toPrevious moves previous if true
   */
  public async save(toNext: boolean = false, toPrevious: boolean = false) {
    const needSave = ((toNext || toPrevious) && this.isPristine);
    if (this.changed || needSave) {
      // Process price, keeping only valid values, null for others
      let price = `${this.form.value.price}`;
      price = price
        .replace(",", ".")
        .replace("€", "")
        .replace(/ /g, "")
        .trim();
      let fprice: number | null = parseFloat(price);
      if (isNaN(fprice) || fprice === 0) {
        fprice = null;
      } else {
        fprice *= 10000;
      }
      fprice = Math.round(fprice);
      let rprice: number | null;
      if (this.ati) {
        // Existing price, update this item
        rprice = (await firstValueFrom(this.at.action("update_price", {
          method: "POST",
          body: {
            itemId: this.ati.id,
            price: fprice
          }
        }))).price;
        this.setFormPrice(rprice);
        this.updated.emit(this.editor);
        this.changed = false;
      } else if (!this.storageId) {
        // Update at price (no storageId)
        rprice = (await firstValueFrom(this.at.action("update_price", {
          method: "POST",
          body: {
            price: fprice
          }
        }))).price;
        this.setFormPrice(rprice);
        this.updated.emit(this.editor);
        this.changed = false;

      } else if (this.storageId) {
        // Price creation with storage
        const res = (await firstValueFrom(this.at.action("update_price", {
          method: "POST",
          body: {
            storageId: this.storageId,
            price: fprice
          }
        })));
        rprice = res.price;
        this.ati = res.ati;
        this.setFormPrice(rprice);
        this.updated.emit(this.editor);
        this.changed = false;
      }
    }
    if (toNext) {
      this.next.emit(this.editor);
    } else if (toPrevious) {
      this.previous.emit(this.editor);
    }
  }

  public onFocus($event: any) {
    this.selected.emit(this.at);
    if (this.storageId) {
      this.curcol.emit(`storage_${this.storageId}`);
    } else if (this.marcheId) {
      this.curcol.emit(`marche_${this.marcheId}`);
    } else {
      this.curcol.emit("");
    }
  }

  public onBlur($event: any) {
    if (this.changed) {
      this.save();
    }
  }

  public async toggleAvailable() {
    console.log("Change availablility", this.ati);
    if (this.ati) {
      await firstValueFrom(this.at.action("update_price", {
        method: "POST",
        body: {
          itemId: this.ati.id,
          price: null,
          available: this.ati.available ? 0 : 1
        }
      }));
    } else {
      await firstValueFrom(this.at.action("update_price", {
        method: "POST",
        body: {
          storageId: this.storageId,
          price: null,
          available: 1
        }
      }));
    }
    this.available = this.available ? 0 : 1;
    this.updated.emit(this.editor);
    this.changed = false;
  }
}
