import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Tarif, TarifService} from "../tarif.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {TarifType, TarifTypeService} from "../../tariftype/tariftype.service";
import {firstValueFrom, Subject} from "rxjs";
import {tarifTemplate, toDateEnd} from "../tarifutils";
import {first, takeUntil} from "rxjs/operators";
import {add, parseISO, set} from "date-fns";
import {BsacMessageService} from "@solidev/bsadmincomponents";

@Component({
  selector: "lvadg-tarif-create",
  templateUrl: "./tarif-create.component.pug",
  styleUrls: ["./tarif-create.component.sass"]
})
export class TarifCreateComponent implements OnInit, OnDestroy {

  public tg!: Tarif;
  public form!: UntypedFormGroup;
  public autoName!: string;
  @Input() public tarifType!: TarifType;
  @Input() public standAlone = false;
  @Input() public duplicate!: Tarif;
  @Output() public created = new EventEmitter<Tarif>();
  private _subscriptions = new Subject<void>();
  public creating: boolean = false;

  constructor(private _model: TarifService,
              public tarifTypes$: TarifTypeService,
              private _msgs: BsacMessageService) {
  }

  async ngOnInit(): Promise<void> {
    this.tg = await firstValueFrom(this._model.create({status: "PR"}, {save: false}));
    this.tarifType = await firstValueFrom(this.tarifTypes$.get(this.tarifType?.id || this.duplicate?.type));
    let datestart = new Date();
    if (this.duplicate) {
      datestart = set(
        add(
          parseISO(this.duplicate.dateend as any),
          {days: 1}), {hours: 0, minutes: 0, seconds: 0, milliseconds: 1});
    }
    const dateend = toDateEnd(this.tarifType, datestart);
    const autoName = this.setAutoName(datestart, dateend);
    this.form = new UntypedFormGroup({
      type: new UntypedFormControl(this.tarifType?.id),
      name: new UntypedFormControl(autoName),
      datestart: new UntypedFormControl(datestart),
      dateend: new UntypedFormControl(dateend)
    });

    if (this.tarifType) {
      this.tg.name = autoName;
      this.tg.type = this.tarifType.id;
      this.checkForm();
    } else {
      this.form.controls.type.valueChanges.pipe(first()).subscribe(() => {
        this.checkForm();
      });
    }
  }

  public ngOnDestroy() {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public checkForm() {
    this.form.controls.datestart.valueChanges
      .pipe(takeUntil(this._subscriptions))
      .subscribe((newdate) => {
        newdate = set(newdate, {hours: 0, minutes: 0, seconds: 1, milliseconds: 0});
        this.form.controls.datestart.setValue(newdate, {emitEvent: false});
        const newdateend = toDateEnd(this.tarifType, newdate);
        this.form.controls.dateend.setValue(newdateend);
      });
    this.form.controls.dateend.valueChanges
      .pipe(takeUntil(this._subscriptions))
      .subscribe((newdate) => {
        this.form.controls.dateend.setValue(newdate, {emitEvent: false});
        this.setTitle();
      });
  }

  public async submit() {
    this.creating = true;
    try {
      this.tg.assign(this.form.value);
      const res = await firstValueFrom(this.tarifType.action("create_tarif", {
        method: "POST",
        body: this.tg.toJSON(),
        query: {att_update: "yes"}
      }));
      this.tg = await firstValueFrom(this._model.get(res.id));
      this.created.emit(this.tg);
    } catch (e) {
      console.error(e);
      this._msgs.error("Echec lors de la création du tarif", "...", (e as any).message || `${e}`);
    }
  }

  public setAutoName(datestart: Date, dateend: Date) {
    return tarifTemplate(this.tarifType.name_template, datestart, dateend);
  }

  public setDateEnd(datestart: Date) {
    const dateend = toDateEnd(this.tarifType, datestart);
    this.form.controls.dateend.setValue(dateend);
  }

  public setTitle() {
    const autoName = this.setAutoName(this.form.value.datestart, this.form.value.dateend);
    this.form.controls.name.setValue(autoName);
  }

  public async setLastDate(interval: boolean = false, last?: Tarif) {
    if (!last) {
      last = (await firstValueFrom(
        this._model.queryset
          .query({tariftypes: this.tarifType.id})
          .sort([{field: "dateend", order: "desc"}])
          .paginate({page_size: 1})
          .get())).items[0];
    }
    if (last) {
      console.log("LAST", last);
      if (!interval) {
        console.log("To date fin");
        this.form.controls.datestart.setValue(add(parseISO(last.dateend as any), {days: 1}));
      } else {
        console.log("Date debut + intervalle");
        this.form.controls.datestart.setValue(add(toDateEnd(this.tarifType, parseISO(last.datestart as any)), {days: 1}));
      }
    }
  }
}
