import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {SeaLabel, SeaLabelService} from "./sealabel.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SeaLabelResolve implements Resolve<SeaLabel> {
  constructor(private _bs: SeaLabelService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<SeaLabel> {
    return this._bs.get(+route.params["sealabelId"]);
  }
}
