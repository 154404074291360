
<div class="form-group" *ngIf="param">
  <form [formGroup]="form">
    <div class="form-group">
      <label><i class="fa fa-trash pointer float-end" (click)="removeParam()"></i>{{param.label}}</label>
      <select class="form-select" formControlName="value">
        <option *ngFor="let c of param.choices" [ngValue]="c.value">{{c.desc}}</option>
      </select>
      <div class="help-block" *ngIf="param.help">{{param.help}}</div>
      <div class="help-block" *ngIf="valHelp">{{valHelp}}</div>
    </div>
  </form>
  <form [formGroup]="textForm" *ngIf="asktext" (submit)="saveText()">
    <div class="form-group" *ngIf="title">
      <label>Titre</label>
      <input class="form-control" type="text" formControlName="svalue">
    </div>
    <div class="form-group">
      <label>Texte</label>
      <textarea class="form-control" formControlName="tvalue" rows="10" *ngIf="mode=='text'"></textarea>
      <quill-editor class="d-block" formControlName="tvalue" [modules]="quillModules" *ngIf="mode=='rich'"></quill-editor>
    </div>
    <button class="btn btn-primary btn-sm btn-block w-100"><i class="fa fa-save"></i></button>
  </form>
</div>