import {Component, Input, OnChanges} from "@angular/core";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {ArticleFamily, ArticleFamilyService} from "../article-family.service";
import {RoutesService, RouteV3} from "../../../../comps/routes.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: "lvadg-article-family-manage",
  templateUrl: "./article-family-manage.component.pug",
  styleUrls: ["./article-family-manage.component.sass"]
})
export class ArticleFamilyManageComponent implements OnChanges {


  @Input() public articleFamily!: ArticleFamily;
  @Input() public articlefamily_detail_route!: RouteV3<ArticleFamily>;
  public selectMove = false;
  public selectCategMove = false;
  public moveDest: ArticleFamily|null = null;
  public moveCategDest: ArticleFamily|null = null;

  constructor(public routes: RoutesService,
              private _articlefamilys: ArticleFamilyService,
              private _msg: BsacMessageService) {
  }

  public ngOnChanges(): void {
  }

  public async uploadedLogo(): Promise<void> {
    try {
      await firstValueFrom(this.articleFamily.refresh());
      this._msg.success("Logo uploaded with success");
    } catch (error) {
      this._msg.error("Logo upload failed",
        "API Return : " + (error as any).toString(), (error as any)._body);
    }
  }

  public getUrl(): string {
    // TODO: check in ngdataservice why any is required
    return (<any>this._articlefamilys).backend.getRequestUrl(
      (<any>this._articlefamilys).adapter.detail_action(this.articleFamily, "set_logo")
    );
  }

  // Move products operations
  public startMove(): void {
    this.selectMove = true;
    this.moveDest = null;
  }

  public confirmMove(dest: ArticleFamily): void {
    this.moveDest = dest;
    this.selectMove = false;
  }

  public realMove(): void {
    if (this.moveDest) {
    this.articleFamily.action("move_products",
      {method: "POST", body: {dest: this.moveDest.id}})
      .subscribe(() => {
        this.articleFamily.refresh().subscribe(() => {
          this._msg.success("Products moved with success");
          this.moveDest = null;
        });
      });
    }
  }

  public cancelMove(): void {
    this.selectMove = false;
    this.moveDest = null;
  }

  // Move categ operations
  public startCategMove(): void {
    this.selectCategMove = true;
    this.moveCategDest = null;
  }

  public confirmCategMove(dest: ArticleFamily): void {
    this.moveCategDest = dest;
    this.selectCategMove = false;
  }

  public realCategMove(): void {
    if (this.moveCategDest) {
    this.articleFamily.action("move_categ",
      {method: "POST", body: {dest: this.moveCategDest.id}})
      .subscribe(() => {
        this.articleFamily.refresh().subscribe(() => {
          this._msg.success("Category moved with success");
          this.moveCategDest = null;
        });
      });
    }
  }

  public cancelCategMove(): void {
    this.selectCategMove = false;
    this.moveCategDest = null;
  }

  // Update seasons

  public async updateSeasons(seasons: number[]): Promise<any> {
    this.articleFamily.productseasons = seasons;
    await firstValueFrom(this.articleFamily.update(["productseasons"]));
    this._msg.success("Saisonnalité changée avec succès");
  }


  public async onUploaded($event: void): Promise<void> {
    await firstValueFrom(this.articleFamily.refresh());
  }

  public async onDocumentDeleted() {
    this._msg.success("Document supprimé avec succès");
    await firstValueFrom(this.articleFamily.refresh());
  }

}
