import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Article, ArticleService} from "./article.service";

@Injectable({providedIn: "root"})
export class ArticleResolve implements Resolve<Article> {
  constructor(private _bs: ArticleService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<Article> {
    return this._bs.get(+route.params["articleId"]);
  }
}
