import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LvadgioCreateComponent} from "./lvadgio-create/lvadgio-create.component";
import {HttpClientModule} from "@angular/common/http";
import {ReactiveFormsModule} from "@angular/forms";
import {BsacMessageModule} from "@solidev/bsadmincomponents";
import {NgStringPipesModule} from "ngx-pipes";


@NgModule({
  declarations: [LvadgioCreateComponent],
  exports: [LvadgioCreateComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    BsacMessageModule,
    NgStringPipesModule
  ]
})
export class LvadgioModule {
}
