import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSSlugField
} from "@solidev/ngdataservice";
import {Rimg} from "../../../comps/rimg/rimg";
import {first} from "rxjs/operators";
import {Label} from "../../product/label/label.service";
import {Producer} from "../../structure/producer/producer.service";
import {SeaProducer} from "../../structure/seaproducer/seaproducer.service";
import {SeaLabel} from "../../product/sealabel/sealabel.service";
import {Product} from "../../product/product/product.service";
import {SeaProduct} from "../../product/seaproduct/seaproduct.service";
import {Provider} from "../../structure/provider/provider.service";
import {Storage} from "../../structure/storage/storage.service";
import {Article} from "../../catalog/article/article.service";
import {SLabel} from "../../structure/slabel/slabel.service";
import {ArticleEgalim} from "../../catalog/articledata/article-egalim.service";


export class DetailDocument extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID"
  })
  public id!: number;


  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 4,
    choices: [
      ["FT", "Fiche technique"],
      ["DL", "Document label structure"],
      ["DE", "Document label / certification"],
      ["OT", "Autre document"]
    ]
  })
  public type!: string;


  @DSCharField({
    name: "title",
    verboseName: "Titre",
    minLength: 3,
    maxLength: 200,
    required: true
  })
  public title!: string;

  @DSSlugField({
    name: "slug",
    verboseName: "Slug",
    required: false
  })
  public slug!: string;

  @DSCharField({
    name: "mimetype",
    verboseName: "Type",
    required: false,
    maxLength: 250,
    default: ""
  })
  public mimetype!: string;

  @DSCharField({
    name: "filename",
    verboseName: "Nom de fichier",
    required: false,
    maxLength: 255,
    default: ""
  })
  public filename!: string;

  @DSIntegerField({
    name: "filesize",
    verboseName: "Taille du fichier",
    required: false,
    default: 0
  })
  public filesize!: number;

  @DSCharField({
    name: "file",
    verboseName: "Fichier",
    required: false,
    maxLength: 255,
    default: ""
  })
  public file!: string;

  @DSForeignKeyField({
    required: false,
    name: "image",
    helpText: "",
    verboseName: "ID Image",
    choices: [],
    default: null,
    autoCreated: false,
    relatedModel: "Image",
  })
  public image!: number;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date de création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;

  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date de mise à jour",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    required: false,
    name: "status",
    helpText: "",
    verboseName: "Etat",
    choices: [
      ["OK", "Document valide"],
      ["PRE1", "Document sans MAJ expirant dans - de 15 jours"],
      ["PRE2", "Document sans MAJ expirant dans - de 1 mois"],
      ["FUT", "Document futur"],
      ["ARCN", "Document archivé, sans MAJ"],
      ["ARCM", "Document archivé, avec MAJ"],
      ["KO", "Document non valide"],
    ],
    default: null,
    maxLength: 4,
  })
  public status!: string;

  @DSDateField({
    required: false,
    name: "start",
    helpText: "Date de début",
    verboseName: "Date de fin",
  })
  public start!: Date;

  @DSDateField({
    required: false,
    name: "end",
    helpText: "Date de début",
    verboseName: "Date de fin",
  })
  public end!: Date;

  @DSDefaultField({
    required: false,
    name: "tags",
    helpText: "",
    verboseName: "tags",
    choices: [],
    default: [],
  })
  public tags!: any;

  @DSDefaultField({
    name: "image_img",
    verboseName: "Miniature",
    required: false
  })
  public image_img!: Rimg;


  @DSForeignKeyField({
    name: "producer",
    verboseName: "Producteur",
    required: false,
    helpText: "Producteur",
    relatedModel: "structure.Producer",
    remoteField: "id",
    relatedName: "documents"
  })
  public producer!: number;
  public producer_details!: Producer;

  @DSForeignKeyField({
    name: "prlabel",
    verboseName: "Label produit",
    required: false,
    helpText: "Label produit",
    relatedModel: "product.Label",
    remoteField: "id",
    relatedName: ""
  })
  public prlabel!: number;
  public prlabel_details!: Label;

  @DSManyToManyField({
    name: "products",
    verboseName: "Produits associés",
    required: false,
    helpText: "Produits spécifiques",
    relatedModel: "product.Product",
    remoteField: "id"
  })
  public products!: number[];
  public products_details!: Product[];


  @DSForeignKeyField({
    name: "seaproducer",
    verboseName: "Producteur marée",
    required: false,
    helpText: "Producteur marée",
    relatedModel: "structure.SeaProducer",
    remoteField: "id",
    relatedName: "documents"
  })
  public seaproducer!: number;
  public seaproducer_details!: SeaProducer;

  @DSForeignKeyField({
    name: "seaprlabel",
    verboseName: "Label marée",
    required: false,
    helpText: "Label",
    relatedModel: "product.SeaLabel",
    remoteField: "id",
    relatedName: ""
  })
  public seaprlabel!: number;
  public seaprlabel_details!: SeaLabel;

  @DSManyToManyField({
    name: "products",
    verboseName: "Produits associés",
    required: false,
    helpText: "Produits spécifiques",
    relatedModel: "product.SeaProduct",
    remoteField: "id"
  })
  public seaproducts!: number[];
  public seaproducts_details!: SeaProduct[];

  @DSForeignKeyField({
    name: "provider",
    verboseName: "Fournisseur",
    required: false,
    helpText: "",
    relatedModel: "structure.Provider",
    remoteField: "id",
    relatedName: "documents"
  })
  public provider!: number;
  public provider_details!: Provider;

  @DSForeignKeyField({
    name: "storage",
    verboseName: "Dépôt",
    required: false,
    helpText: "Dépôt",
    relatedModel: "structure.Storage",
    remoteField: "id",
    relatedName: "documents"
  })
  public storage!: number;
  public storage_details!: Storage;


  @DSManyToManyField({
    name: "articles",
    verboseName: "Articles",
    required: false,
    relatedModel: "catalog.Article",
    remoteField: "documents",
    helpText: "Articles",
    default: [],
  })
  public articles!: number[];
  public articles_details!: Article[];


  @DSForeignKeyField({
    name: "articlefamily",
    verboseName: "Famille Article",
    required: false,
    helpText: "Famille Article",
    relatedModel: "catalog.ArticleFamily",
    remoteField: "id",
    relatedName: "documents"
  })
  public articlefamily!: number;
  public articlefamily_details!: Article;

  @DSForeignKeyField({
    name: "stlabel",
    verboseName: "Label structure",
    required: false,
    helpText: "Label structure",
    relatedModel: "structure.Label",
    remoteField: "id",
    relatedName: "documents"
  })
  public stlabel!: number;
  public stlabel_details!: SLabel;

  @DSForeignKeyField({
    name: "egalim",
    verboseName: "Article Egalim",
    required: false,
    helpText: "Article Egalim",
    relatedModel: "catalog.ArticleEgalim",
    remoteField: "id",
    relatedName: "documents"
  })
  public egalim!: number;
  public egalim_details!: ArticleEgalim;

  @DSDefaultField({
    name: "download_link",
    required: false,
    verboseName: "Lien .io"
  })
  public download_link!: string;

  private _url: string;


  public download(newTab?: any): void {
    if (!newTab) {
      newTab = window.open();
    }
    if (!this._url) {
      this.action("download_link", {method: "POST", body: {ttl: 63072000}})
        .pipe(first())
        .subscribe((dl) => {
          if (newTab && newTab.location) {
            newTab.location.href = dl.url;
          } else {
            console.error("No location for async newTab, redirecting main window");
            window.location.assign(dl.url);
          }
        });
    } else {
      if (newTab && newTab.location) {
        newTab.location.href = this._url;
      } else {
        console.error("No location for sync newTab, redirecting main window");
        window.location.assign(this._url);
      }

    }
  }
}

@Injectable({
  providedIn: "root"
})
export class DetailDocumentService extends DSRestCollection<DetailDocument> {
  public adapter_config = {basePath: "/api/v2/detaildocuments"};
  public model = DetailDocument;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
