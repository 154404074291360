import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {TarifType} from "../tariftype/tariftype.service";
import {ArticleTarifTemplate} from "../articletarif/articletariftemplate.service";


export interface AttRelation {
  id: number;
  ttrel: number;
  src: number;
  src_details: ArticleTarifTemplate;
  dest: number;
  dest_details: ArticleTarifTemplate;
  ops: string;
  type: "COM" | "LOC" | "REF";
}

export class TarifTypeRelation extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "src",
    verboseName: "Modèle source",
    required: false,
    helpText: "Modèle source",
    relatedModel: "tarifs.TarifType",
    remoteField: "id",
    relatedName: "srcrelations"
  })
  public src!: number;
  public src_details!: TarifType;

  @DSForeignKeyField({
    name: "dest",
    verboseName: "Modèle destination",
    required: false,
    helpText: "Modèle destination",
    relatedModel: "tarifs.TarifType",
    remoteField: "id",
    relatedName: "destrelations"
  })
  public dest!: number;
  public dest_details!: TarifType;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 3,
    choices: [
      ["COM", "Commission"],
      ["LOC", "Dispo locale"],
      ["REF", "Référence (importation)"],
      ["NFO", "Référence (information)"],
      ["CLN", "Clone complet"]
    ]
  })
  public type!: "COM" | "LOC" | "REF" | "NFO" | "CLN";

  @DSCharField({
    name: "ops",
    verboseName: "Opérations",
    required: false,
    helpText: "Opérations",
    default: "",
    maxLength: 200
  })
  public ops!: string;

  public att_relations_details!: AttRelation[];


}

@Injectable({
  providedIn: "root"
})
export class TarifTypeRelationService extends DSRestCollection<TarifTypeRelation> {
  public adapter_config = {basePath: "/api/v2/tarifs/tariftyperelations"};
  public model = TarifTypeRelation;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
