
<form *ngIf="offer" [formGroup]="form">
  <div class="row">
    <div class="col-lg-4 d-none d-lg-block">
      <h2><i class="fa fa-info-circle me-2"></i>Infos utiles</h2>
      <p class="text-info">Le titre de l'offre est utilisé par défaut en première ligne du cartouche.</p>
      <p class="text-info">
        Pour une offre pour un client, vous pouvez supprimer / ajouter des restaurants sur la page de détail de l'offre, après la création.</p>
    </div>
    <div class="col-lg-4">
      <div class="row">
        <div class="col-sm-12">
          <bsac-field-control [model]="offer" field="title" [form]="form"><span class="label">Titre de l'offre</span></bsac-field-control>
        </div>
        <div class="col-sm-12">
          <bsac-field-control [model]="offer" field="storage" [form]="form" [related]="storages$" [relatedFilter]="storageFilter" [descfield]="'name'"><span class="label">Dépôt concerné</span></bsac-field-control>
        </div>
        <div class="col-sm-6" *ngIf="form.value.storage">
          <bsac-field-control [model]="offer" field="client" [form]="form" [related]="clients$" [relatedFilter]="clientFilter" [relatedReset]="clientReset$" [descfield]="'cfullname'"><span class="label">Client</span></bsac-field-control>
        </div>
        <div class="col-sm-6" *ngIf="form.value.storage">
          <div class="text-info">ou créer une offre générique</div>
          <button class="btn btn-outline-warning btn-block w-100" (click)="makeGeneric()">Offre locale générique</button>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <label class="d-block label">Intervalle de validité</label>
      <lvadg-daterangepicker [start]="offer.datestart" [end]="offer.dateend" (selected)="setDateRange($event)"></lvadg-daterangepicker>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-8">
      <button class="btn btn-primary btn-block w-100" (click)="create()" [disabled]="!form.valid || !datestart || !dateend"><i class="fa fa-plus me-2"></i>Créer</button>
    </div>
    <div class="col-4">
      <button class="btn btn-secondary btn-block w-100" (click)="cancel()"><i class="fa fa-times me-2"></i>Annuler</button>
    </div>
  </div>
</form>