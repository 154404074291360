import {Injectable} from "@angular/core";

export class ErrorMessage {
  constructor(public severity: string, public message: string, public details: any) {

  }
}


@Injectable({providedIn: "root"})
export class ErrorService {
  private _log: ErrorMessage[] = [];

  constructor() {
  }

  public fatal(message: string, details: any = {}) {
    this._log.push(new ErrorMessage("FATAL", message, details));
  }

  public get(level: string = "ERROR"): ErrorMessage[] {
    return this._log;
  }
}
