import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {SeaProductPrice, SeaProductPriceService} from "./seaproductprice.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SeaProductPriceResolve implements Resolve<SeaProductPrice> {
  constructor(private _bs: SeaProductPriceService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<SeaProductPrice> {
    return this._bs.get(+route.params["seaproductpriceId"]);
  }
}
