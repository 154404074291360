
<div *ngIf="rule &amp;&amp; rrule">
  <pre>{{rule}}</pre>
  <p class="font-weight-bold">{{rrule.toText(defaultGetText, FRENCH)}}<i class="fa fa-plus-circle mx-2 pointer" (click)="showDates=!showDates"></i><i class="fa fa-calendar mx-2 pointer" (click)="showCalendar()"></i><i class="fa fa-pencil mx-2 pointer" (click)="toggleEdit()"></i></p>
  <div class="datelist my-1" *ngIf="showDates">
    <ul>
      <li *ngFor="let d of computedDates">{{d|date:'full':"Europe/Paris"}}</li>
    </ul>
  </div>
</div>
<div class="pointer text-danger" *ngIf="!rule" (click)="toggleEdit()">Aucune règle définie, cliquez pour définir la récurrence</div>
<form *ngIf="form &amp;&amp; edit" [formGroup]="form">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Intervalle : tous/toutes les</label>
        <input class="form-control" formControlName="interval" type="number">
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>&nbsp;</label>
        <select class="form-select" formControlName="freq">
          <option *ngFor="let f of freqOptions" [value]="f.freq">{{f.desc}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Jour(s)</label>
        <select class="form-select" formControlName="byweekday" multiple>
          <option *ngFor="let f of dayOptions" [value]="f.wd">{{f.desc}}</option>
        </select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Jour(s) du mois</label>
        <select class="form-select" formControlName="bymonthday" multiple>
          <option *ngFor="let f of dateOptions" [value]="f.day">{{f.desc}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Heure</label>
        <select class="form-select" formControlName="byhour">
          <option *ngFor="let f of hourOptions" [value]="f.hour">{{f.desc}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Date de début</label>
        <input class="form-control" formControlName="dtstart" type="datetime-local">
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Date de fin</label>
        <input class="form-control" formControlName="until" type="datetime-local">
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Nombre d'occurrences</label>
        <input class="form-control" formControlName="count" type="number">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="w-100 btn btn-primary" (click)="saveRule()"><i class="fa fa-save me-2"></i>Enregistrer</button>
    </div>
    <div class="col">
      <button class="w-100 btn btn-outline-secondary" (click)="toggleEdit()"><i class="fa fa-crosshairs me-2"></i>Annuler</button>
    </div>
  </div>
</form>