import {NgModule} from "@angular/core";
import {ClientCreateComponent} from "./client-create/client-create.component";
import {ClientDeleteComponent} from "./client-delete/client-delete.component";
import {ClientDisplayComponent} from "./client-display/client-display.component";
import {ClientListComponent} from "./client-list/client-list.component";
import {ClientManageComponent} from "./client-manage/client-manage.component";
import {LocationModule} from "../location/location.module";
import {ClientSelectModule} from "./client-select.module";
import {ClientSelectComponent} from "./client-select/client-select.component";
import {SLabelModule} from "../slabel/slabel.module";
import {ImageModule} from "../../content/image/image.module";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ClientSalesComponent} from "./client-sales/client-sales.component";
import {BillModule} from "../../sales/bill/bill.module";


@NgModule({
  imports: [
    ...MODELLIST_IMPORTS,
    LocationModule,
    ImageModule,
    ClientSelectModule,
    SLabelModule,
    BillModule
  ],
  declarations: [
    ClientDisplayComponent,
    ClientDeleteComponent,
    ClientListComponent,
    ClientManageComponent,
    ClientCreateComponent,
    ClientSalesComponent
  ],
  exports: [
    ClientDisplayComponent,
    ClientDeleteComponent,
    ClientListComponent,
    ClientManageComponent,
    ClientCreateComponent,
    ClientSelectComponent,
    ClientSalesComponent
  ],
})
export class ClientModule {
}
