import {NgModule} from "@angular/core";
import {TarifdocumentListmanageComponent} from "./tarifdocument-listmanage/tarifdocument-listmanage.component";
import {NgMathPipesModule} from "ngx-pipes";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  declarations: [
    TarifdocumentListmanageComponent
  ],
  exports: [
    TarifdocumentListmanageComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    NgMathPipesModule
  ]
})
export class TarifdocumentModule {
}
