import {NgModule} from "@angular/core";
import {ExternalarticlecollectionListComponent} from "./externalarticlecollection-list/externalarticlecollection-list.component";
import {ExternalarticlecollectionCreateComponent} from "./externalarticlecollection-create/externalarticlecollection-create.component";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";
import {ExternalarticlecollectionManageComponent} from "./externalarticlecollection-manage/externalarticlecollection-manage.component";


@NgModule({
  declarations: [
    ExternalarticlecollectionListComponent,
    ExternalarticlecollectionCreateComponent,
    ExternalarticlecollectionManageComponent
  ],
  exports: [
    ExternalarticlecollectionListComponent,
    ExternalarticlecollectionCreateComponent,
    ExternalarticlecollectionManageComponent

  ],
  imports: [
    ...MODELLIST_IMPORTS
  ]
})
export class ExternalArticleCollectionModule {
}
