import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TarifNotification, TarifNotificationService} from "../../tarifnotification/tarifnotification.service";
import {Tarif} from "../tarif.service";
import {firstValueFrom} from "rxjs";
import {BsacMessageService} from "@solidev/bsadmincomponents";

@Component({
  selector: "lvadg-tarif-publish",
  templateUrl: "./tarif-publish.component.pug",
  styleUrls: ["./tarif-publish.component.sass"]
})
export class TarifPublishComponent implements OnInit {
  @Input() public tarif!: Tarif;
  @Input() public modal: boolean = true;
  @Output() published = new EventEmitter<TarifNotification>();
  @Output() cancelled = new EventEmitter<void>();

  public notifications: TarifNotification[] = [];

  constructor(private _notifs: TarifNotificationService,
              private _msgs: BsacMessageService) {
  }

  async ngOnInit(): Promise<void> {
    this.notifications = (await firstValueFrom(this._notifs.queryset.query({tarif: this.tarif.id}).get())).items;
  }

  public async onNotificationCreated($event: TarifNotification) {
    await firstValueFrom(this.tarif.action("publish", {method: "POST", body: {notif: $event.toJSON()}}));
    this.published.emit($event);
  }

  public async deletedNotif(not: TarifNotification) {
    this.notifications = (await firstValueFrom(this._notifs.queryset.query({tarif: this.tarif.id}).get())).items;
    this._msgs.success("Notification supprimée avec succès");
  }
}
