import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {GroupUser, GroupUserService} from "./groupuser.service";

@Injectable({providedIn: "root"})
export class GroupUserResolve implements Resolve<GroupUser> {
  constructor(private _bs: GroupUserService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<GroupUser> {
    return this._bs.get(+route.params["groupServiceId"]);
  }
}
