import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FichesData, FichesDataService} from "./fichesdata.service";

@Injectable({providedIn: "root"})
export class FichesDataResolve implements Resolve<FichesData> {
  constructor(private _bs: FichesDataService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<FichesData> {
    return this._bs.get(+route.params["fichesdataId"]);
  }
}
