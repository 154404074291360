import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {VivalyaBill, VivalyaBillService} from "./bill.service";

@Injectable({providedIn: "root"})
export class BillResolve implements Resolve<VivalyaBill> {
  constructor(private _bs: VivalyaBillService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<VivalyaBill> {
    return this._bs.get(+route.params["billId"]);
  }
}
