import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {IBsacApiConstants} from "@solidev/bsadmincomponents/lib/bsac-apiconstants/bsac-api-constants.service";
import {Observable, ReplaySubject} from "rxjs";
import {first, map} from "rxjs/operators";


export class RnmMarche extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "code",
    verboseName: "Code vivalya",
    required: false,
    helpText: "Code vivalya",
    minLength: 0,
    maxLength: 5,
    choices: []
  })
  public code!: string;

  @DSIntegerField({
    name: "facode",
    verboseName: "Code FAM",
    required: false,
    helpText: "Code FranceAgriMer",
    default: 0,
    choices: []
  })
  public facode!: number;

  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: false,
    helpText: "Type",
    minLength: 0,
    maxLength: 2,
    choices: [["FL", "Grossistes F&L"], ["MR", "Grossites Marée"]]
  })
  public type!: "FL" | "MR";

  @DSCharField({
    name: "sname",
    verboseName: "Nom court",
    required: false,
    helpText: "Nom court",
    minLength: 0,
    maxLength: 50,
    choices: []
  })
  public sname!: string;

  @DSCharField({
    name: "lname",
    verboseName: "Nom long",
    required: false,
    helpText: "Nom long",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public lname!: string;


  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;


  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class RnmMarcheService extends DSRestCollection<RnmMarche> {
  public adapter_config = {basePath: "/api/v2/tarifs/rnmmarches"};
  public model = RnmMarche;
  private _all: ReplaySubject<RnmMarche[]>;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }

  public getFromCache(): Observable<RnmMarche[]> {
    if (!this._all) {
      this._all = new ReplaySubject<RnmMarche[]>(1);
      this.queryset.query({status: "OK"}).get().pipe(first())
        .subscribe((res) => {
          this._all.next(res.items);
        });
    }
    return this._all.asObservable();
  }

  public getChoices(): Observable<IBsacApiConstants> {
    return this.getFromCache().pipe(map(marches => {
      const out: IBsacApiConstants = [];
      for (const m of marches) {
        out.push({desc: m.sname || m.lname, value: m.id});
      }
      return out;
    }));

  }
}
