import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Person} from "../person/person.service";
import {Group} from "../group/group.service";
import {Client} from "../../structure/client/client.service";
import {Storage} from "../../structure/storage/storage.service";


export class GroupUser extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;


  @DSForeignKeyField({
    name: "user",
    verboseName: "Utilisateur",
    required: false,
    helpText: "Utilisateur",
    relatedModel: "people.Person",
    remoteField: "id",
    relatedName: "groupusers"
  })
  public user!: number;
  public user_details!: Person;

  @DSForeignKeyField({
    name: "group",
    verboseName: "Groupe",
    required: false,
    helpText: "Groupe",
    relatedModel: "people.Group",
    remoteField: "id",
    relatedName: "groupusers"
  })
  public group!: number;
  public group_details!: Group;

  @DSManyToManyField({
    name: "storages",
    verboseName: "Dépôts",
    required: false,
    relatedModel: "structure.Storage",
    remoteField: "groupusers",
    helpText: "Dépôts",
    default: [],
  })
  public storages!: number[];
  public storages_details!: Storage[];

  @DSManyToManyField({
    name: "clients",
    verboseName: "Clients",
    required: false,
    relatedModel: "structure.Client",
    remoteField: "groupusers",
    helpText: "Clients",
    default: [],
  })
  public clients!: number[];
  public clients_details!: Client[];


  // Tracking mixin
  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status!: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created!: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated!: Date;


}

@Injectable({
  providedIn: "root"
})
export class GroupUserService extends DSRestCollection<GroupUser> {
  public adapter_config = {basePath: "/api/v2/groupusers"};
  public model = GroupUser;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
