import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CustomDataKey} from "../../customdatakey/customdatakey.service";
import {CustomData} from "../customdata.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: "lvadg-customdata-value-edit",
  templateUrl: "./customdata-value-edit.component.pug",
  styleUrls: ["./customdata-value-edit.component.sass"]
})
export class CustomDataValueEditComponent implements OnInit {
  @Input() public key!: CustomDataKey;
  @Input() public value!: CustomData;
  @Input() public altvalue!: CustomData;
  @Input() public readonly = false;
  @Output() public edited = new EventEmitter<any>();
  @Output() public removed  = new EventEmitter<any>();

  public form!: UntypedFormGroup;

  constructor() {
  }

  public save(value?: any): void {
    if (value === undefined) {
      if (this.form) {
        value = this.form.value.value;
      }
    }
    if (value !== undefined) {
      this.edited.emit(value);
    }
  }

  public ngOnInit(): void {
    if (this.key.type === "INT") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(this.value.value_int)});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(0)});
      }
    }
    if (this.key.type === "DEC") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(this.value.value_dec)});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(0)});
      }
    }
    if (this.key.type === "CUR") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(this.value.value_dec)});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(0)});
      }
    }
    if (this.key.type === "STX") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(this.value.value_stx)});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl("")});
      }
    }
    if (this.key.type === "LTX") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(this.value.value_ltx)});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl("")});
      }
    }
    if (this.key.type === "DTE") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(this.value.value_dte)});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(new Date())});
      }
    }
    if (this.key.type === "DTH") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(this.value.value_dth)});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(new Date())});
      }
    }
    if (this.key.type === "CH1") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl(this.value.choice)});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl()});
      }
      this.form.valueChanges.subscribe(() => {
        this.save(this.form.value.value);
      });
    }
    if (this.key.type === "DOC") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl((this.value.document))});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl()});
      }
    }
    if (this.key.type === "FOL") {
      if (this.value) {
        this.form = new UntypedFormGroup({value: new UntypedFormControl((this.value.folder))});
      } else {
        this.form = new UntypedFormGroup({value: new UntypedFormControl()});
      }
    }


  }

}
