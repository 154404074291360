import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";
import {Tarif} from "../tarif/tarif.service";
import {TarifType} from "../tariftype/tariftype.service";
import {Group} from "../../people/group/group.service";


export class TarifNotificationGroup extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSForeignKeyField({
    name: "tariftype",
    verboseName: "Tarif",
    required: false,
    helpText: "Tarif",
    relatedModel: "tarifs.TarifType",
    remoteField: "id",
    relatedName: "tarifnotificationgroups"
  })
  public tariftype!: number;
  public tariftype_details!: TarifType;



  @DSForeignKeyField({
    name: "group",
    verboseName: "Groupe",
    required: false,
    helpText: "Groupe",
    relatedModel: "person.Group",
    remoteField: "id",
    relatedName: "tarifnotificationgroups"
  })
  public group!: number;
  public group_details!: Group;

  @DSCharField({
    name: "notification",
    verboseName: "Notification",
    required: false,
    helpText: "Type de notification",
    minLength: 0,
    maxLength: 10,
    choices: [
      [ "NT_PUB", "Notifications de publication initiale"],
      [ "NT_UPD", "Notifications de publication initiale"],
      [ "NT_ALL", "Toutes les notifications de publication"],
      [ 'AL_NOCUR', "Alarme tarif manquant"],
      [ 'AL_DTPUB', "Alarme date de publication"],
      [ 'AL_DTVAL', "Alarme date de validation"],
      [ 'AL_DTPRP', "Alarme date de prepration"],
      [ 'AL_MISST', "Alarme données dépôt manquantes"],
      [ 'AL_ALL', "Toutes les alarmes"],
      [ 'ALL', "Toutes alarmes et notifications"],
    ]
  })
  public notification!: string;

}

@Injectable({
  providedIn: "root"
})
export class TarifNotificationGroupService extends DSRestCollection<TarifNotificationGroup> {
  public adapter_config = {basePath: "/api/v2/tarifs/tarifnotificationgroups"};
  public model = TarifNotificationGroup;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
