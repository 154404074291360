import {Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {Location, LocationService} from "../location.service";
import {Member, MemberService} from "../../member/member.service";
import {Producer, ProducerService} from "../../producer/producer.service";
import {SeaProducer, SeaProducerService} from "../../seaproducer/seaproducer.service";
import {Storage, StorageService} from "../../storage/storage.service";
import {Client, ClientService} from "../../client/client.service";
import {Resto, RestoService} from "../../resto/resto.service";
import {firstValueFrom, Observable, ReplaySubject} from "rxjs";
import * as maplibregl from "maplibre-gl";
import {FillLayerSpecification, GeoJSONSourceSpecification, LineLayerSpecification, SymbolLayerSpecification} from "maplibre-gl";
import {filter, first} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EnvService} from "../../../../../front/env.service";
import {BsacGeolocService, BsacMessageService, IGeoLocResult} from "@solidev/bsadmincomponents";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {RoutesService} from "../../../../comps/routes.service";
import {IGeocodedFile} from "../../../document/upload/upload-geocode/geocoded-file";
import {Provider, ProviderService} from "../../provider/provider.service";
import {StorageDetails} from "../../_producer/producer-base.model";
import {FeatureCollection, Point} from "geojson";
import {DSRestCollection} from "@solidev/ngdataservice";

type FCP = FeatureCollection<Point>;

function createGeoJSONCircleCoordinates(center: [number, number], radiusInKm: number, points: number = 64) {
  const coords = {
    latitude: center[1],
    longitude: center[0]
  };

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
  const distanceY = km / 110.574;

  let theta, x, y;
  for (let i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return ret;
}


@Component({
  selector: "lvadg-locations-osm-display",
  templateUrl: "./locations-osm-display.component.pug",
  styleUrls: ["./locations-osm-display.component.sass"]
})
export class LocationsOsmDisplayComponent implements OnInit, OnChanges {
  @Input() public memberLocations: Location[] = [];
  @Input() public producerLocations: Location[] = [];
  @Input() public seaproducerLocations: Location[] = [];
  @Input() public providerLocations: Location[] = [];
  @Input() public storageLocations: Location[] = [];
  @Input() public seastorageLocations: Location[] = [];
  @Input() public clientLocations: Location[] = [];
  @Input() public restoLocations: Location[] = [];
  @Input() public members: Member[] = [];
  @Input() public producers: Producer[] = [];
  @Input() public seaproducers: SeaProducer[] = [];
  @Input() public providers: Provider[] = [];
  @Input() public storages: (Storage | StorageDetails)[] = [];
  @Input() public seastorages: (Storage | StorageDetails)[] = [];
  @Input() public clients: Client[] = [];
  @Input() public restos: Resto[] = [];
  @Input() public marker!: Location;
  @Input() public layerDisplayStatus: { [index: string]: boolean } = {};
  @Input() public zone = "fl";
  @Input() public height = "300px";
  @Input() public margin = 20;
  @Input() public refresh!: Observable<any>;
  @Input() public boundary: "auto" | "france" | "europe" = "france";
  @Input() public zoom = -1;
  @Input() public mapStyle!: string;
  @Output() public markerUpdated = new EventEmitter<Location>();
  @ViewChild("map", {static: true}) public mapEl!: ElementRef;
  @ViewChild("popup", {static: true}) public popupEl!: ElementRef;
  // Add marker vars
  public customMarkerForm = new UntypedFormGroup({
    type: new UntypedFormControl(""),
    name: new UntypedFormControl(""),
    address: new UntypedFormControl("")
  });
  public customMarkers!: GeoJSONSourceSpecification;
  public xlsxCustomMarkers!: GeoJSONSourceSpecification;
  public xlsxCustomFiles: IGeocodedFile[] = [];
  public addXlsxFile = false;
  public markerInstance!: maplibregl.Marker;
  // Add circle vars
  public customCircleForm = new UntypedFormGroup({
    address: new UntypedFormControl(""),
    circleRadius: new UntypedFormControl(150),
    strokeWeight: new UntypedFormControl(2),
    strokeColor: new UntypedFormControl("#000000"),
    strokeOpacity: new UntypedFormControl(100),
    fillColor: new UntypedFormControl("#BBBBBB"),
    fillOpacity: new UntypedFormControl(50)
  });
  public customCircles!: GeoJSONSourceSpecification;
  // Info window
  public info: any = {
    display: false,
    latitude: 0,
    longitude: 0,
    title: ""
  };
  public DATA_LAYERS: string[] = [
    "members",
    "storages",
    "seastorages",
    "producers",
    "providers",
    "seaproducers",
    "clients",
    "restos"];
  public LAYER_TYPES: { [index: string]: string } = {
    vivalya: "Vivalya",
    producers: "Producteur",
    providers: "Fournisseur référencés",
    seaproducers: "Producteur marée",
    storages: "Dépôt",
    seastorages: "Dépôt marée",
    members: "Adhérent",
    clients: "Clients",
    restos: "Restaurant",
    "": "Inconnu"
  };
  // Available styles
  public AVAILABLE_STYLES = [
    {name: "osmlibertyfr", url: "https://maptiles.gis.lavieadugout.fr/styles/osmliberty-fr/style.json", description: "OSM Liberty (FR)"},
    {name: "osmlibertyeu", url: "https://maptiles.gis.lavieadugout.fr/styles/osmliberty-eu/style.json", description: "OSM Liberty (EU)"},
    {
      name: "tnvivalyadark",
      url: "https://maptiles.gis.lavieadugout.fr/styles/toner_vivalya_dark/style.json",
      description: "Vivalya (dark, FR)"
    },
    {
      name: "tnvivalyalight",
      url: "https://maptiles.gis.lavieadugout.fr/styles/toner_vivalya_light/style.json",
      description: "Vivalya (light, FR)"
    },
    {name: "osmbrightfr", url: "https://maptiles.gis.lavieadugout.fr/styles/osmbright-fr/style.json", description: "OSM Bright (FR)"},
    {name: "osmbrighteu", url: "https://maptiles.gis.lavieadugout.fr/styles/osmbright-eu/style.json", description: "OSM Bright (EU)"},
    {name: "basicfr", url: "https://maptiles.gis.lavieadugout.fr/styles/basic-fr/style.json", description: "Basic (FR)"},
    {name: "basiceu", url: "https://maptiles.gis.lavieadugout.fr/styles/basic-eu/style.json", description: "Basic (EU)"},
    {name: "tonerfr", url: "https://maptiles.gis.lavieadugout.fr/styles/toner-fr/style.json", description: "Toner (FR)"},
    {name: "tonereu", url: "https://maptiles.gis.lavieadugout.fr/styles/toner-eu/style.json", description: "Toner (EU)"},
    {name: "frboundaries", url: "https://maptiles.gis.lavieadugout.fr/styles/frboundaries/style.json", description: "France simple"},
    {name: "regions", url: "https://maptiles.gis.lavieadugout.fr/styles/regions/style.json", description: "Régions"},
    {name: "departements", url: "https://maptiles.gis.lavieadugout.fr/styles/departements/style.json", description: "Départements"},
    {name: "communes", url: "https://maptiles.gis.lavieadugout.fr/styles/communes/style.json", description: "Communes"},
    {
      name: "regions-noname",
      url: "https://maptiles.gis.lavieadugout.fr/styles/regions-noname/style.json",
      description: "Régions (sans texte)"
    },
    {
      name: "departements-noname",
      url: "https://maptiles.gis.lavieadugout.fr/styles/departements-noname/style.json",
      description: "Départements (sans texte)"
    },
    {
      name: "departements-borders-noname",
      url: "https://maptiles.gis.lavieadugout.fr/styles/departements-borders-noname/style.json",
      description: "Départements - limites (sans texte)"
    },

    {
      name: "communes-noname",
      url: "https://maptiles.gis.lavieadugout.fr/styles/communes-noname/style.json",
      description: "Communes (sans texte)"
    },
    {name: "empty", url: "https://maptiles.gis.lavieadugout.fr/styles/empty/style.json", description: "Vide"},
  ];
  public displayNames = true;
  private mapListenersAdded = false;
  private _map = new ReplaySubject<maplibregl.Map>(1);

  constructor(private _zone: NgZone,
              private _http: HttpClient,
              private _modal: NgbModal,
              private _members: MemberService,
              private _storages: StorageService,
              private _producers: ProducerService,
              private _seaproducers: SeaProducerService,
              private _providers: ProviderService,
              private _clients: ClientService,
              private _restos: RestoService,
              private _locator: BsacGeolocService,
              private _locations: LocationService,
              private _routes: RoutesService,
              private _messages: BsacMessageService,
              public env: EnvService) {

  }


  public get map$(): Observable<maplibregl.Map> {
    return this._map.asObservable();
  }


  public changeStyle(style: string): void {
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        cmap.setStyle(style, {diff: false});
      });
    });
  }

  ngOnInit(): void {
    this.customMarkers = {
      type: "geojson",
      data: {type: "FeatureCollection", features: []}
    };
    this.customCircles = {
      type: "geojson",
      data: {type: "FeatureCollection", features: []}
    };
    this.xlsxCustomMarkers = {
      type: "geojson",
      data: {type: "FeatureCollection", features: []}
    };


    this.mapEl.nativeElement.style.height = this.height;
    this._zone.runOutsideAngular(() => {
      let url = "https://maptiles.gis.lavieadugout.fr/styles/osmliberty-fr/style.json";
      if (this.mapStyle) {
        for (const av of this.AVAILABLE_STYLES) {
          if (this.mapStyle === av.name) {
            console.log("Found style, using it", av.name);
            url = av.url;
          }
        }
      }
      const map = new maplibregl.Map({
        container: this.mapEl.nativeElement,
        style: url,
        center: [2.6390772508210603, 46.441826066304316],
        zoom: 5.24,
        antialias: false,
        minZoom: 3, // was 4.4
        attributionControl: false,
        preserveDrawingBuffer: false
      });
      map.addControl(
        new maplibregl.AttributionControl({
          compact: true,
          customAttribution: [
            "Vivalya", "OpenStreetmap"
          ]
        }));
      map.addControl(new maplibregl.ScaleControl({}));
      map.addControl(new maplibregl.FullscreenControl({}));
      map.addControl(new maplibregl.GeolocateControl({}));
      map.addControl(new maplibregl.NavigationControl({visualizePitch: true}));
      map.on("load", () => {
        this._map.next(map);
      });
      map.on("styledata", () => {
        if (!map.getSource("custommarkers")) {
          map.addSource("custommarkers", this.customMarkers);
          map.addSource("customcircles", this.customCircles);
          map.addSource("xlsxcustommarkers", this.xlsxCustomMarkers);
          for (const l of this.DATA_LAYERS) {
            map.addSource(l, this.makeGeoJson(l, l));
          }
          this.createLayers(map);
          this.createMarker(map);
        }
      });
      // SEE: The following line is a bad hack for /vivalya page
      setTimeout(() => {
        map.resize();
      }, 300);
    });
  }

  public createLayers(cmap: maplibregl.Map): void {
    if (!cmap.getLayer("custommarkers")) {
      cmap.addLayer({
        id: "custommarkers",
        source: "custommarkers",
        type: "symbol",
        layout: <SymbolLayerSpecification["layout"]>{
          "icon-image": ["concat", "vvya-", ["get", "type"]],
          "icon-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-size": 1.1,
          "icon-anchor": "bottom"
        }
      });
    }
    if (!cmap.getLayer("custommarkers-text")) {
      cmap.addLayer({
        id: "custommarkers-text",
        source: "custommarkers",
        type: "symbol",
        minzoom: 5,
        layout: <SymbolLayerSpecification["layout"]>{
          "text-field": ["get", "name"],
          "text-anchor": "top",
          "text-size": 11,
          "text-font": ["texgyreadventorregular"]
        },
        paint: <SymbolLayerSpecification["paint"]>{
          "text-color": "rgba(0,0,0,1)",
        }
      });
    }
    if (!cmap.getLayer("xlsxcustommarkers")) {
      cmap.addLayer({
        id: "xlsxcustommarkers",
        source: "xlsxcustommarkers",
        type: "symbol",
        layout: <SymbolLayerSpecification["layout"]>{
          "icon-image": ["concat", "vvya-", ["get", "type"]],
          "icon-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-size": 1.1,
          "icon-anchor": "bottom"
        }
      });
    }
    if (!cmap.getLayer("xlsxcustommarkers-text")) {
      cmap.addLayer({
        id: "xlsxcustommarkers-text",
        source: "xlsxcustommarkers",
        type: "symbol",
        minzoom: 5,
        layout: <SymbolLayerSpecification["layout"]>{
          "text-field": ["get", "nom"],
          "text-anchor": "top",
          "text-size": 11,
          "text-font": ["texgyreadventorregular"]
        },
        paint: <SymbolLayerSpecification["paint"]>{
          "text-color": "rgba(0,0,0,1)",
        }
      });
    }
    if (!cmap.getLayer("customcircles_shade")) {
      cmap.addLayer({
        id: "customcircles_shade",
        source: "customcircles",
        type: "fill",
        paint: <FillLayerSpecification["paint"]>{
          "fill-color": ["get", "fillColor"],
          "fill-opacity": ["get", "fillOpacity"]
        }
      });
    }
    if (!cmap.getLayer("customcircles_lines")) {
      cmap.addLayer({
        id: "customcircles_lines",
        source: "customcircles",
        type: "line",
        paint: <LineLayerSpecification["paint"]>{
          "line-color": ["get", "strokeColor"],
          "line-opacity": ["get", "strokeOpacity"],
          "line-width": ["get", "strokeWeight"]
        }
      });
    }


    for (const l of this.DATA_LAYERS) {
      if (!cmap.getLayer(l)) {
        cmap.addLayer({
          id: l,
          source: l,
          type: "symbol",
          layout: <SymbolLayerSpecification["layout"]>{
            "icon-image": "vvya-" + l,
            "icon-ignore-placement": true,
            "icon-allow-overlap": true,
            "text-ignore-placement": true,
            "text-allow-overlap": true,
            "icon-size": 1,
            "icon-anchor": "bottom",
            "visibility": this.layerDisplayStatus[l] !== false ? "visible" : "none"
          },
          paint: <SymbolLayerSpecification["paint"]>{
            "text-color": "rgba(0,0,0,1)",
          }
        });
      }
      if (!cmap.getLayer(l + "-text")) {
        cmap.addLayer({
          id: l + "-text",
          source: l,
          type: "symbol",
          minzoom: 5,
          layout: <SymbolLayerSpecification["layout"]>{
            "text-field": ["get", "name"],
            "text-anchor": "top",
            "text-size": 11,
            "visibility": this.displayNames && this.layerDisplayStatus[l] !== false ? "visible" : "none"
          },
          paint: <SymbolLayerSpecification["paint"]>{
            "text-color": "rgba(0,0,0,1)",
          }
        });
      }
    }

    if (!this.mapListenersAdded) {
      this.mapListenersAdded = true;
      for (const l of this.DATA_LAYERS) {
        (() => {
          const oldp: string = cmap.getCanvas().style.cursor;
          cmap.on("mouseenter", l, (e) => {
            cmap.getCanvas().style.cursor = "pointer";
          });
          cmap.on("mouseleave", l, (e) => {
            cmap.getCanvas().style.cursor = oldp;
          });
          cmap.on("click", l, (e) => {
            if (e.features) {
              const coordinates = (e.features[0].geometry as any).coordinates.slice();
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              this.popupDisplay(cmap, coordinates, e.features[0].properties);
            }
          });
        })();
      }
    }
  }

  public createMarker(cmap: maplibregl.Map): void {
    if (this.marker) {
      if (!this.markerInstance) {
        this.markerInstance = new maplibregl.Marker({
          draggable: true
        })
          .setLngLat([this.marker.longitude, this.marker.latitude])
          .addTo(cmap);
        this.markerInstance.on("dragend", () => {
          const lngLat = this.markerInstance.getLngLat();
          this.marker.latitude = lngLat.lat;
          this.marker.longitude = lngLat.lng;
          this.marker.status = "OK";
          this._zone.runTask(() => {
            this.markerUpdated.emit(this.marker);
          });
        });
      } else {
        this.markerInstance.setLngLat([this.marker.longitude, this.marker.latitude]);
      }
    }
  }


  public popupDisplay(map: maplibregl.Map, coordinates: [number, number], properties: any) {

    const services: { [index: string]: DSRestCollection<any> } = {
      storages: this._storages,
      seastorages: this._storages,
      members: this._members,
      restos: this._restos,
      producers: this._producers,
      seaproducers: this._seaproducers,
      providers: this._providers
    };
    if (properties.id) {
      this._zone.runTask(async () => {
        this.info.waiting = true;
        const popum = new maplibregl.Popup()
          .setLngLat(coordinates)
          .setDOMContent(this.popupEl.nativeElement)
          .addTo(map);
        const infos = await firstValueFrom(services[properties.type].get(properties.id, {query: {rimgszs: "sm"}}));
        popum.setDOMContent(this.popupEl.nativeElement);

        this.info.item = infos;
        this.info.type = properties.type;
        this.info.waiting = false;
      });
    } else {
      console.error("Invalid item", properties);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        for (const l of this.DATA_LAYERS) {
          const loc = l.replace(/(.*)s/, "$1Locations");
          if ((changes[l] && changes[l].currentValue !== changes[l].previousValue)
            || (changes[loc] && changes[loc].currentValue !== changes[loc].previousValue)) {
            if (this.layerDisplayStatus[l] === undefined) {
              // Enable layer
              this.layerDisplayStatus[l] = true;
            }
            if (cmap.getSource(l)) {
              const data = this.makeGeoJson(l, l).data as any;
              data.features = data.features.concat((this.makeGeoJson(loc, l).data as any).features);
              (cmap.getSource(l) as any).setData(data);  // FIXME: typings, WAS GeoJSONSource

            } else {
              console.log("Layer ", l, "not ready");
            }
          }
        }
        this.createMarker(cmap);

        this.autoBounds();
      });
    });
  }

  public showModal(modal: any): void {
    this._modal.open(modal, {size: "lg", centered: true}).result.then(() => {
    }, () => {
    });
  }

  public addStructureCustomMarker(type: string, item: Storage | Producer | Resto | Client | SeaProducer) {
    const cd = this.customMarkers.data as FCP;
    cd.features.push({
      type: "Feature",
      properties: {
        type: type,
        name: item.name,
        address: item.mainlocation_details.address
      },
      geometry: {
        type: "Point",
        coordinates: [item.mainlocation_details.longitude, item.mainlocation_details.latitude]
      }
    });
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        if (cmap.getSource("custommarkers")) {
          (cmap.getSource("custommarkers") as any).setData(cd); // FIXME: typing
        }
        this.autoBounds();
      });
    });
  }


  // Custom markers management
  public addMarker(): void {
    this._locator.geocodeAddress(this.customMarkerForm.value.address)
      .pipe(filter((loc) => !!loc))
      .subscribe((loc: IGeoLocResult) => {
        const cd = this.customMarkers.data as FCP;
        cd.features.push({
          type: "Feature",
          properties: {
            type: this.customMarkerForm.value.type,
            name: this.customMarkerForm.value.name,
            address: this.customMarkerForm.value.address
          },
          geometry: {
            type: "Point",
            coordinates: loc.geometry.coordinates
          }
        });
        this.map$.pipe(first()).subscribe((cmap) => {
          this._zone.runOutsideAngular(() => {
            if (cmap.getSource("custommarkers")) {
              (cmap.getSource("custommarkers") as any).setData(cd); // FIXME: typing
            }
            this.autoBounds();
          });
        });
      });

  }

  public removeMarker(marker: any): void {
    const cd = (this.customMarkers.data as FCP);
    cd.features.splice(cd.features.indexOf(marker), 1);
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        if (cmap.getSource("custommarkers")) {
          (cmap.getSource("custommarkers") as any).setData(cd);  // FIXME: typing
        }
      });
    });
  }

  // Custom markers management
  public addCircle(): void {
    this._locator.geocodeAddress(this.customCircleForm.value.address)
      .pipe(filter((loc) => loc !== null))
      .subscribe((loc: IGeoLocResult) => {
        const cd = (this.customCircles.data as GeoJSON.FeatureCollection<GeoJSON.Polygon, any>);
        cd.features.push({
          type: "Feature",
          properties: {
            radius: this.customCircleForm.value.circleRadius,
            strokeColor: this.customCircleForm.value.strokeColor,
            strokeWeight: this.customCircleForm.value.strokeWeight,
            strokeOpacity: this.customCircleForm.value.strokeOpacity / 100,
            fillColor: this.customCircleForm.value.fillColor,
            fillOpacity: this.customCircleForm.value.fillOpacity / 100,
            address: this.customCircleForm.value.address
          },
          geometry: {
            type: "Polygon",
            coordinates: [createGeoJSONCircleCoordinates(loc.geometry.coordinates,
              this.customCircleForm.value.circleRadius, 64)]
          }
        });
        this.map$.pipe(first()).subscribe((cmap) => {
          this._zone.runOutsideAngular(() => {
            if (cmap.getSource("customcircles")) {
              (cmap.getSource("customcircles") as any).setData(cd);  // FIXME: typing
            }
          });
        });
      });
  }

  public setBoundary(zone: "auto" | "france" | "europe"): void {
    if (zone === "france") {
      this.map$.pipe(first()).subscribe((cmap) => {
        this._zone.runOutsideAngular(() => {
          cmap.fitBounds([[-5.922479, 41.173141], [10.009818, 51.202295]]);
        });
      });
    } else if (zone === "europe") {
      this.map$.pipe(first()).subscribe((cmap) => {
        this._zone.runOutsideAngular(() => {
          console.log("Zone to europe");
          cmap.fitBounds([[-17, 63], [28, 31]]);
        });
      });
    }
    this.boundary = zone;
    this.autoBounds();
  }

  public autoBounds(): void {
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        if (this.boundary === "auto") {
          const bounds = new maplibregl.LngLatBounds();
          let found = false;
          for (const l of this.DATA_LAYERS) {
            if (this.layerDisplayStatus[l] !== true) {
              continue;
            }
            const src = cmap.getSource(l) as any;
            if (src) {
              for (const d of src._data.features) {
                bounds.extend(d.geometry.coordinates);
                found = true;
              }
            }
          }
          if (cmap.getSource("custommarkers")) {
            const src = cmap.getSource("custommarkers") as any;
            if (src) {
              for (const d of src._data.features) {
                bounds.extend(d.geometry.coordinates);
                found = true;
              }
            }
          }
          if (cmap.getSource("xlsxcustommarkers")) {
            const src = cmap.getSource("xlsxcustommarkers") as any;
            if (src) {
              for (const d of src._data.features) {
                bounds.extend(d.geometry.coordinates);
                found = true;
              }
            }
          }
          if (this.marker) {
            found = true;
            bounds.extend([this.marker.longitude, this.marker.latitude]);
          }
          if (found) {
            console.log("Fitting bounds");
            cmap.fitBounds(bounds, {padding: 100, maxZoom: 11});
          }
        }
      });
    });
  }

  public removeCircle(circle: any): void {
    const cd = (this.customCircles.data as FCP);
    cd.features.splice(cd.features.indexOf(circle), 1);
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        if (cmap.getSource("customcircles")) {
          (cmap.getSource("customcircles") as any).setData(cd);  // FIXME: typing
        }
      });
    });
  }

  public toggleLayer(layer: string, status: boolean) {
    this.layerDisplayStatus[layer] = status;
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        if (cmap.getLayer(layer)) {
          cmap.setLayoutProperty(layer, "visibility", status ? "visible" : "none");
        }
        if (cmap.getLayer(layer + "-text")) {
          cmap.setLayoutProperty(layer + "-text", "visibility", status ? "visible" : "none");
        }
      });
    });
  }

  public toggleNames(status: boolean) {
    this.displayNames = status;
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        for (const l of this.DATA_LAYERS) {
          if (cmap.getLayer(l + "-text")) {
            cmap.setLayoutProperty(l + "-text", "visibility", status ? "visible" : "none");
          }
        }
      });
    });
  }


  public makeGeoJson(indata: string | any[], type: string): GeoJSONSourceSpecification {
    let data: any[];
    if (typeof indata === "string") {
      data = (this as any)[indata];
    } else {
      data = indata;
    }
    const geo: GeoJSONSourceSpecification = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    };
    if (data) {
      for (const d of data) {
        let location: Location | undefined;
        let id: number = -1;
        let name: string = "";
        if (typeof indata === "string" && indata.indexOf("Locations") !== -1) {
          location = d;
          id = d[indata.replace("Locations", "")];
          name = "";
        } else {
          if (d) {
            location = d.mainlocation_details || d.locations_details[0];
            id = d.id;
            name = d.name || "";
          }
        }

        if (!location || !location.latitude || !location.longitude) {
          console.log("No location for data", d);
          continue;
        }
        (geo.data as any).features.push({
          type: "Feature",
          properties: {
            type: type,
            id: id,
            name: name
          },
          geometry: {
            type: "Point",
            coordinates: [
              location.longitude,
              location.latitude]
          }
        });
      }
    }
    return geo;
  }

  public infoGetLink(): { internal?: (string | number)[], external?: string } {
    if (!this.info.item) {
      return {};
    }
    let rtype = this.info.type;
    if (rtype.search("sea") === 0) {
      rtype = rtype.substr(3);
    }
    const internal = this._routes.detail(this.zone, rtype, this.info.item);
    if (internal) {
      return {internal: internal};
    }
    if (this.info.item.website) {
      return {external: this.info.item.website};
    }
    return {};
  }

  public print(current: boolean = false) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    (a as any).style = "display: none";
    const createImageFromBlob = (image: Blob) => {
      a.download = "output.png";
      a.href = window.URL.createObjectURL(image);
      a.click();
    };

    this.map$.pipe(first()).subscribe((cmap) => {
      const style = cmap.getStyle();
      const bounds = cmap.getBounds();
      const mbearing = cmap.getBearing();
      const mpitch = cmap.getPitch();
      let center: number[];
      let zoom: number;
      let bearing: number;
      let pitch: number;
      if (current) {
        center = cmap.getCenter().toArray();
        zoom = cmap.getZoom();
        bearing = cmap.getBearing();
        pitch = cmap.getPitch();
      } else {
        center = [2.6390772508210603, 46.441826066304316];
        zoom = 5.5;
        bearing = 0;
        pitch = 0;
      }
      this._messages.info("Génération de la carte en cours...");
      this._http.post("https://renderer.gis.lavieadugout.fr/render", {
        height: 1024,
        width: 1024,
        ratio: 1,
        center, zoom, bearing, pitch, style
      }, {responseType: "blob"}).subscribe((res) => {
        createImageFromBlob(res);
        this._messages.success("Génération de la terminée avec succès...");
      }, (err) => {
        this._messages.error("Erreur lors de la génération de la carte", err);
      });
    });
  }

  public onCustomPointerFile(file: IGeocodedFile) {
    this.xlsxCustomFiles.push(file);
    this.updateXlsxMarkers();
    this.addXlsxFile = false;
  }

  public toggleXlsxMarkers(f: IGeocodedFile) {
    f.enabled = !f.enabled;
    this.updateXlsxMarkers();
  }

  public updateXlsxMarkers(): void {
    this.map$.pipe(first()).subscribe((cmap) => {
      this._zone.runOutsideAngular(() => {
        (this.xlsxCustomMarkers.data as FCP).features = [];
        for (const f of this.xlsxCustomFiles) {
          if (f.enabled) {
            (this.xlsxCustomMarkers.data as FCP).features =
              (this.xlsxCustomMarkers.data as FCP).features.concat((f.data.data as FCP).features);
          }
        }
        (cmap.getSource("xlsxcustommarkers") as any).setData(this.xlsxCustomMarkers.data);  // FIXME: typing
        this.autoBounds();
      });
    });

  }
}
