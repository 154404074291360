/**
 * Manage locations.
 *
 * Edit `model` locations, displaying a map with `producers`, `storages`, `members` locations.
 *
 * @status ALPHA
 * @author Jean-Matthieu BARBIER <jm.barbier@solidev.net>
 * @date 2018-06-12-16:52:53
 * @changed 2020-06-04-16:30:55
 * @copyright (c) 2017-2020 Jean-Matthieu BARBIER
 * @license AGPLv3
 */


import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {DSRestQueryset, IDSModel} from "@solidev/ngdataservice";
import {Location, LocationService} from "../location.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: "lvadg-locations-manage",
  templateUrl: "./locations-manage.component.pug",
  styleUrls: ["./locations-manage.component.sass"]
})
export class LocationsManageComponent implements OnInit, OnDestroy {
  @Input() public model!: IDSModel;
  @Input() public type!: string;
  @Input() public mode = "admin";
  @Input() public display = "horizontal";
  @Input() public producers: Location[] = [];
  @Input() public storages: Location[] = [];
  @Input() public members: Location[] = [];
  @Output() public created = new EventEmitter<Location>();
  public edit: Location | null = null;
  public create = false;
  public locations: Location[] = [];
  public step = "infos";
  public loading = true;
  private query: DSRestQueryset<Location>;
  private _subscription$ = new Subject<void>();

  constructor(private _loc: LocationService) {
  }

  public ngOnInit() {
    const query: {[index: string]: string} = {};
    query[this.type] = `${this.model.id}`;
    this.query = this._loc.queryset;
    this.query.query(query);
    this.query.results.pipe(takeUntil(this._subscription$)).subscribe((result) => {
      this.edit = null;
      this.create = false;
      this.loading = false;
      this.locations = result.items;
    });
    this.refresh();
  }

  public ngOnDestroy(): void {
    this._subscription$.next();
    this._subscription$.complete();
  }

  public onCreated(location: Location) {
    this.refresh();
  }

  public onCancelCreate() {
    this.edit = null;
    this.create = false;
  }

  public onDeleted(location: Location) {
    this.refresh();
  }

  public async refresh(): Promise<void> {
    this.loading = true;
    await this.query.get().toPromise();
  }
}
