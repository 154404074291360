import {Injectable} from "@angular/core";
import {DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {BaseProduct} from "../_product/product-base.service";

export class Product extends BaseProduct {
}

@Injectable({
  providedIn: "root"
})
export class ProductService extends DSRestCollection<Product> {
  public adapter_config = {basePath: "/api/v2/products"};
  public model = Product;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}

