import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Location, LocationService} from "../location.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {IDSModel} from "@solidev/ngdataservice";
import {BsacGeolocService, IGeoLocResult} from "@solidev/bsadmincomponents";
import {filter} from "rxjs/operators";

@Component({
  selector: "lvadg-location-create",
  templateUrl: "./location-create.component.pug",
  styleUrls: ["./location-create.component.sass"]
})
export class LocationCreateComponent implements OnInit {
  public location!: Location;
  public creation_fields: string[] = ["type", "locname", "address", "caddress", "postcode", "city", "cedex", "phone", "fax", "website"];

  public form!: UntypedFormGroup;
  @Input() public model!: IDSModel;
  @Input() public type!: string;
  @Input() public save = true;
  @Input() public prefill: { locname?: string } = {};
  @Output() public created: EventEmitter<Location> = new EventEmitter<Location>();
  @Output() public cancelled: EventEmitter<any> = new EventEmitter<any>();

  public geoloc!: IGeoLocResult | null;
  public geofailure = false;
  public status = "NG";

  constructor(private _locations: LocationService,
              private _fb: UntypedFormBuilder,
              private _locator: BsacGeolocService) {
  }

  public ngOnInit(): void {
    this._locations.create().subscribe((model) => {
      this.location = model;
      this.location.geotype = "";
      const group = {};
      this.form = new UntypedFormGroup({
        type: new UntypedFormControl("MAIN"),
        locname: new UntypedFormControl(this.prefill?.locname ? this.prefill.locname : "")
      })
    });
  }

  public geoLocate(): void {
    this.geoloc = null;
    this._locator.geocodeAddress(
      `${this.form.value.address} ${this.form.value.caddress},` +
      `${this.form.value.postcode} ${this.form.value.city}, France`)
      .pipe(filter((loc) => !!loc))
      .subscribe({
        next: (loc: IGeoLocResult) => {
          console.log("Geoloc result", loc);
          if (!loc.properties.type) {
            this.geofailure = true;
          } else {
            this.geoloc = loc;
            this.location.position = `POINT(${loc.geometry.coordinates[0]} ${loc.geometry.coordinates[1]})`;
            this.location.longitude = loc.geometry.coordinates[0];
            this.location.latitude = loc.geometry.coordinates[1];
            this.location.geotype = loc.properties.type;
            this.location.status = "AY";
          }
        }, error: (err) => {
          this.geofailure = true;
          console.log("Geoloc error : ", err);
        }
      });
  }

  public create() {
    if (this.form.valid) {
      const val = this.form.value;
      val.status = "NG";
      if (this.type) {
        val["loctype"] = this.type;
      }
      if (this.model && this.model.id) {
        val[this.type] = this.model.id;
      }
      val.position = this.location.position;
      val.status = this.location.status;
      val.country = "FR";
      val.geotype = this.location.geotype || "";
      this._locations.create(val, {save: this.save}).subscribe((saved) => {
        this.created.emit(saved);
      });
    }
  }


  public manualPosition(location: Location) {
    this.location.position = `POINT(${location.longitude} ${location.latitude})`;
    this.location.status = "OK";
  }

  public cancel() {
    this.cancelled.emit(true);
  }
}
