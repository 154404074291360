import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BsacMessageService} from "@solidev/bsadmincomponents";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../lib/models/user/auth.service";
import {CurrentSiteService} from "../../../lib/models/authentication/site/current-site.service";
import {first, switchMap} from "rxjs/operators";
import {EnvService} from "../../env.service";

@Component({
  selector: "lvadg-front-login",
  templateUrl: "./front-login.component.pug",
  styleUrls: ["./front-login.component.sass"]
})
export class FrontLoginComponent implements OnInit {
  public form!: UntypedFormGroup;
  public errorMessage: string|null = null;
  public bgUrl!: string;
  public vivalyaLogoUrl!: string;

  constructor(private _msg: BsacMessageService,
              private activatedRoute: ActivatedRoute,
              private _site: CurrentSiteService,
              private _auth: AuthService,
              private _fb: UntypedFormBuilder,
              private router: Router,
              public env: EnvService) {
  }

  public ngOnInit(): void {
    this.bgUrl = this.env.deployBgUrl("/assets/img/img-bg-main.jpg");
    this.vivalyaLogoUrl = this.env.deployBgUrl("/assets/img/site-headers/vivalya-only-256.png");

    if (this.activatedRoute.snapshot.queryParams.token) {
      this._site.site$.pipe(first(),
        switchMap(() => {
          return this._auth.tokenLogin(this.activatedRoute.snapshot.queryParams.token, this._site.site?.domain || "--");
        })).subscribe((result) => {
        if (result.authenticated === true) {
          this._auth.getRedirectUrl().subscribe((url) => {
            this._msg.success("Identification réussie...");
            this.router.navigateByUrl(url);
          });
        } else {
          this.errorMessage = result.message || '';
          this._msg.warning(result.message as string);
        }
      });
    }
    this.form = this._fb.group({
      username: ["", [Validators.required, Validators.minLength(4)]],
      password: ["", [Validators.required, Validators.minLength(6)]]
    });
  }

  public login(): void {
    if (this.form.valid) {
      this._auth.userPassLogin(this.form.value.username, this.form.value.password, this._site.site?.domain || "--")
        .subscribe((result) => {
          if (result.authenticated === true) {
            this._auth.getRedirectUrl().subscribe((url) => {
              this._msg.success("Identification réussie...");
              this.router.navigateByUrl(url);
            });
          } else {
            this.errorMessage = result.message || "";
            this._msg.warning(result.message as string);
          }
        });
    }
  }
}
