import {Injectable} from "@angular/core";
import {DSDefaultField, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {CommJob} from "../basecomm/commjob/commjob.service";


export interface PdfPrinterPrintResultItem {
  url: string;
  name?: string;
  file?: boolean;
  pages?: string;
  ref?: number;
}

export interface PdfPrinterPrintResult {
  pages: PdfPrinterPrintResultItem[];
  zip: PdfPrinterPrintResultItem;
  print: PdfPrinterPrintResultItem;
}


export class PrinterJob extends CommJob {
  @DSDefaultField({
    name: "result",
    required: false,
    verboseName: "Print result"
  })
  public result!: PdfPrinterPrintResult;
}


@Injectable({
  providedIn: "root"
})
export class PrinterJobService extends DSRestCollection<PrinterJob> {
  public adapter_config = {basePath: "/api/v2/printerjobs"};
  public model = PrinterJob;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
