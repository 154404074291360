
<ng-container *ngIf="document &amp;&amp; mode=='admin'">
  <h4><i class="fa fa-times float-end pointer" (click)="closed.emit()"></i><i class="fa fa-file me-2"></i>{{document.filename}}</h4>
  <dl class="row">
    <bsac-dd-field class="col-12" [model]="document" field="filename" type="auto">Nom du fichier</bsac-dd-field>
    <bsac-dd-field class="col-12" [model]="document" field="status" type="auto">Status</bsac-dd-field>
    <bsac-dd-field class="col-12" [model]="document" field="content_src" type="auto">Description</bsac-dd-field>
    <div class="col-sm-12">
      <dt>Taille du fichier</dt>
      <dd>{{document.filesize}}</dd>
    </div>
    <div class="col-sm-12">
      <dt>Date de création</dt>
      <dd>{{document.created | date:'short'}}</dd>
    </div>
    <div class="col-sm-12">
      <dt>Date de modification</dt>
      <dd>{{document.updated | date:'short'}}</dd>
    </div>
  </dl>
  <hr>
  <lvadg-confirm-delete (confirm)="delete()" [classes]="['btn-block', 'btn-sm']"><i class="fa fa-trash me-2"></i>Supprimer</lvadg-confirm-delete>
  <hr>
  <button class="btn btn-primary btn-block w-100 btn-sm" (click)="moveAction=true"><i class="fa fa-move me-2"></i>Déplacer</button>
  <ng-container *ngIf="moveAction">
    <div class="form-group">
      <label>Destination</label>
      <bsac-select-related [queryset]="tree.queryset" descfield="title" label="Sélectionner la destination" (selected)="move($event)"></bsac-select-related>
    </div>
  </ng-container>
  <hr>
  <dl>
    <dt>Dossiers multiples</dt>
    <dd>
      <div *ngFor="let f of document.folders_details">
        <lvadg-confirm-delete (confirm)="remove_folder(f)" type="trash"></lvadg-confirm-delete><span class="ms-2">{{f.title}}</span>
      </div>
      <div class="text-info" (click)="addFolderAction=true"><i class="fa fa-plus me-2"></i>Ajouter un dossier</div>
      <div *ngIf="addFolderAction">
        <bsac-select-related [queryset]="tree.queryset" descfield="title" label="Sélectionner le dossier" (selected)="add_folder($event)"></bsac-select-related>
      </div>
    </dd>
  </dl>
  <hr>
  <lvadg-images-manage [model]="document" [imagesfield]="null" logofield="image" logoaction="image"></lvadg-images-manage>
  <button class="btn btn-primary btn-sm btn-block w-100" (click)="genPreview()" [disabled]="genPreviewRunning"><i class="fa fa-image me-2"></i>Aperçu fichier<i class="fa fa-spinner fa-spin ms-2" *ngIf="genPreviewRunning"></i></button>
</ng-container>
<ng-container *ngIf="document &amp;&amp; mode!='admin'">
  <h4><i class="fa fa-times float-end pointer" (click)="closed.emit()"></i><i class="fa fa-file me-2"></i>{{document.filename}}</h4>
  <dl>
    <dt>Nom du fichier</dt>
    <dd>{{document.filename}}</dd>
    <dt>Taille du fichier</dt>
    <dd>{{document.filesize}}</dd>
    <dt>Date de création</dt>
    <dd>{{document.created | date:'short'}}</dd>
    <dt>Date de modification</dt>
    <dd>{{document.updated | date:'short'}}</dd>
    <dt>Description</dt>
    <dd>{{document.content_src}}</dd>
  </dl>
</ng-container>
<ng-container *ngIf="document &amp;&amp; checkMode('admin', 'vivalya', 'member')">
  <div class="mt-3"></div>
  <hr>
  <h4><i class="fa fa-share me-2"></i>Partage</h4>
  <div class="form-group">
    <label>Expiration</label>
    <select class="form-select" [(ngModel)]="shareTtl">
      <option [ngValue]="3600">1 heure</option>
      <option [ngValue]="3600*2">2 heures</option>
      <option [ngValue]="3600*24">24 heures</option>
      <option [ngValue]="3600*24*2">48 heure</option>
      <option [ngValue]="3600*24*7">1 semaine</option>
      <option [ngValue]="3600*24*7*5">5 semaines</option>
      <option [ngValue]="3600*24*7*4*6">6 mois</option>
      <option [ngValue]="3600*24*365">1 an</option>
      <option [ngValue]="3600*24*365*2">2 ans</option>
      <option [ngValue]="3600*24*365*4">4 ans</option>
    </select>
  </div>
  <button class="btn btn-primary btn-block w-100" (click)="get_share_link()">Lien de partage</button>
  <div class="mt-3"></div><code class="text-center" *ngIf="shareLink">{{shareLink.url}}</code>
</ng-container>