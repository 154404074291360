import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {ProductFamily, ProductFamilyService} from "./productfamily.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class ProductFamilyResolve implements Resolve<ProductFamily> {
  constructor(private _bs: ProductFamilyService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ProductFamily> {
    // Small hack : use pcount__gte=0 to ensure having pcount in returned results without having to provide all fields
    return this._bs.get(+route.params["productfamilyId"], {query: {pcount__gte: 0}});
  }
}
