import {Component} from "@angular/core";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {CommonBaseComponent} from "../../common-base.component";
import {ExternalArticleCollection} from "../../../../lib/models/catalog/externalarticlecollection/externalarticlecollection.service";
import {Storage} from "../../../../lib/models/structure/storage/storage.service";
import {ActivatedRoute, Router} from "@angular/router";

export interface CatalogExternalArticleCollectionListViewV3Params extends BaseRouteV3Params {
  externalarticlecollection_detail_route: RouteConfigItemV3;
  storage_detail_route: RouteConfigItemV3;
  // member_detail_route: RouteConfigItemV3;
}

export interface CatalogExternalArticleCollectionListViewV3Data extends CatalogExternalArticleCollectionListViewV3Params {

}

@Component({
  selector: "lvadg-catalog-externalarticlecollection-list-view",
  templateUrl: "./catalog-externalarticlecollection-list-view.component.pug",
  styleUrls: ["./catalog-externalarticlecollection-list-view.component.sass"]
})
export class CatalogExternalArticleCollectionListViewV3Component extends CommonBaseComponent {
  public externalarticlecollection_detail_route!: RouteV3<ExternalArticleCollection>;
  public storage_detail_route!: RouteV3<Storage>;

  // public member_detail_route!: RouteV3<Member>;

  public create = false;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }

  protected async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogExternalArticleCollectionListViewV3Data;
    this.externalarticlecollection_detail_route = new RouteV3<ExternalArticleCollection>(data.externalarticlecollection_detail_route, this);
    this.storage_detail_route = new RouteV3<Storage>(data.storage_detail_route, this);
    super.preNgOnInit();
  }
}
