import {NgModule} from "@angular/core";
import {BillItemListComponent} from "./billitem-list/billitem-list.component";
import {NgStringPipesModule} from "ngx-pipes";
import {MODELLIST_IMPORTS} from "../../../../includes/components/modellist.imports";


@NgModule({
  declarations: [
    BillItemListComponent
  ],
  exports: [
    BillItemListComponent
  ],
  imports: [
    ...MODELLIST_IMPORTS,
    NgStringPipesModule
  ]
})
export class BillItemModule {
}
