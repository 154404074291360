import {Component} from "@angular/core";
import {Subject} from "rxjs";
import {BaseRouteV3Params, RouteConfigItemV3, RoutesService, RouteV3} from "../../../../lib/comps/routes.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonBaseComponent} from "../../common-base.component";
import {ArticleFamily} from "../../../../lib/models/catalog/family/article-family.service";

export interface CatalogArticleFamilyListViewV3Params extends BaseRouteV3Params {
  articlefamily_detail_route: RouteConfigItemV3;
}

export interface CatalogArticleFamilyListViewV3Data extends CatalogArticleFamilyListViewV3Params {

}


@Component({
  selector: "lvadg-catalog-family-list-view",
  templateUrl: "./catalog-family-list-view.component.pug",
  styleUrls: ["./catalog-family-list-view.component.sass"]
})
export class CatalogArticleFamilyListViewV3Component extends CommonBaseComponent {
  public allowed_fields: string[] = [];
  public excluded_fields: string[] = [];
  public default_filters: string[] = ["search", "pricedclients", "withprices"];
  public allowed_filters: string[] = [];
  public excluded_filters: string[] = [];
  public default_fields: string[] = ["id", "name", "cname", "pcount", "logo", "status",
    "flags", "productname", "productseasons"];
  public current!: number;
  public filter$ = new Subject<any>();
  public articlefamily_detail_route!: RouteV3<ArticleFamily>;

  constructor(public routes: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
    super(routes, router, route);
  }

  protected async preNgOnInit(): Promise<void> {
    const data = this.route.snapshot.data as CatalogArticleFamilyListViewV3Data;
    this.articlefamily_detail_route = new RouteV3<ArticleFamily>(data.articlefamily_detail_route, this);
    super.preNgOnInit();
  }

}
