import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {TarifType} from "../tariftype/tariftype.service";
import {TarifDocument} from "../tarifdocument/tarifdocument.service";
import {addHours, parseISO} from "date-fns";
import {isString} from "lodash-es";

export enum TarifStatuses {
  Preparation = "PR",
  ToValidate = "TV",
  Valid = "VA",
  Published = "OK",
  Archived = "AR"
}
export class Tarif extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id!: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom du tarif",
    minLength: 0,
    maxLength: 300,
    choices: []
  })
  public name!: string;

  @DSTextField({
    name: "message",
    verboseName: "Message",
    required: false,
    helpText: "Message",
    minLength: 0,
    maxLength: 10000,
    choices: []
  })
  public message!: string;


  @DSForeignKeyField({
    name: "type",
    verboseName: "Type de tarif",
    required: false,
    helpText: "Type de tarif",
    relatedModel: "tarif.TarifType",
    remoteField: "id",
    relatedName: "tarifs"
  })
  public type!: number;
  public type_details!: TarifType;


  @DSDateTimeField({
    name: "datestart",
    verboseName: "Date de début",
    required: false,
    helpText: "",
    choices: []
  })
  public datestart!: Date;

  @DSDateTimeField({
    name: "dateend",
    verboseName: "Date de fin",
    required: false,
    helpText: "",
    choices: []
  })
  public dateend!: Date;

  @DSDateTimeField({
    required: false,
    name: "created",
    helpText: "",
    verboseName: "Date création",
    choices: [],
    default: null,
    autoNowAdd: true,
  })
  public created!: Date;
  @DSDateTimeField({
    required: false,
    name: "updated",
    helpText: "",
    verboseName: "Date MAJ",
    choices: [],
    default: null,
    autoNow: true,
  })
  public updated!: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "Etat",
    minLength: 0,
    maxLength: 2,
    choices: [
      [TarifStatuses.Preparation, "En préparation"],
      [TarifStatuses.ToValidate, "A valider"],
      [TarifStatuses.Valid, "Validé"],
      [TarifStatuses.Published, "Publié"],
      [TarifStatuses.Archived, "Archivé"]
    ]
  })
  public status!: TarifStatuses;

  @DSCharField({
    name: "step",
    verboseName: "Cycle de vie",
    required: false,
    helpText: "Etape cycle de vie",
    minLength: 0,
    maxLength: 10
  })
  public step!: string;
  @DSDefaultField({
    name: "documents_details",
    verboseName: "Documents",
    required: false,
    helpText: "Documents",
  })
  public documents_details!: TarifDocument[];
  @DSCharField({
    name: "user_level",
    verboseName: "Droits utilisateur",
    required: false,
    helpText: "Droits utilisateur",
    minLength: 0,
    maxLength: 10,
    choices: []
  })
  public user_level!: "anon" | "sadmin" | "admin" | "write" | "read";
  @DSDefaultField({
    name: "user_storages",
    verboseName: "Dépôts utilisateur",
    required: false,
    helpText: "Dépôts utilisateur",
  })
  public user_storages!: number[];
  public user_storages_details!: { id: number; name: string }[];

  public get step_main(): string {
    return this.step ? this.step.split("-")[0] : "";
  }

  public get step_sub(): string {
    return this.step ? this.step.split("-")[1] : "";
  }

  public get preparationLimitDate(): Date {
    let dt: Date;
    if (isString(this.datestart)) {
      dt = parseISO(this.datestart);
    } else {
      dt = this.datestart;
    }
    return addHours(dt, -this.type_details.delta_prepared);
  }

  public get validationLimitDate(): Date {
    let dt: Date;
    if (isString(this.datestart)) {
      dt = parseISO(this.datestart);
    } else {
      dt = this.datestart;
    }
    return addHours(dt, -this.type_details.delta_validated);
  }

  public get publicationLimitDate(): Date {
    let dt: Date;
    if (isString(this.datestart)) {
      dt = parseISO(this.datestart);
    } else {
      dt = this.datestart;
    }
    return addHours(dt, -this.type_details.delta_published);
  }


  public isAuth(level: "admin" | "write" | "read" | "anon"): boolean {
    const ALLOWED_LEVELS = {
      admin: ["sadmin", "admin"],
      write: ["sadmin", "admin", "write"],
      read: ["sadmin", "admin", "write", "read"],
      anon: ["sadmin", "admin", "write", "read", "anon"]
    };
    return ALLOWED_LEVELS[level].indexOf(this.user_level) !== -1;
  }
}

@Injectable({
  providedIn: "root"
})
export class TarifService extends DSRestCollection<Tarif> {
  public adapter_config = {basePath: "/api/v2/tarifs/tarifs"};
  public model = Tarif;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
