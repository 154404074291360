import {Injectable} from "@angular/core";
import {DSCharField, DSRestCollection, DSRestCollectionSetupProvider} from "@solidev/ngdataservice";
import {ModelComm} from "../basecomm/modelcomm/modelcomm.service";
import {SendDestination} from "./senddestination/senddestination.service";
import {SenderJob} from "./senderjob.service";


export class ModelSend extends ModelComm {

  @DSCharField({
    name: "type",
    required: true,
    verboseName: "Type de l'envoi",
    choices: [
      ["offersend", "Offre locale"],
    ]
  })
  public type!: string;

  public destinations_details!: SendDestination[];
  public jobs_details: SenderJob[] = [];


}


@Injectable({
  providedIn: "root"
})
export class ModelSendService extends DSRestCollection<ModelSend> {
  public adapter_config = {basePath: "/api/v2/modelsends"};
  public model = ModelSend;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
