import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";


export const RESPONSIVE_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

@Injectable({
  providedIn: "root"
})
export class DisplayinfosService {
  private _curWidth: "xs"|"sm"|"md"|"lg"|"xl";

  constructor() {
    this._screenWidth = new ReplaySubject<"xs"|"sm"|"md"|"lg"|"xl">(1);
    this._isMobile = new ReplaySubject<boolean>(1);
    this.checkWidth();
  }

  private _screenWidth: ReplaySubject<"xs"|"sm"|"md"|"lg"|"xl">;

  public get screenWidth(): Observable<"xs"|"sm"|"md"|"lg"|"xl"> {
    return this._screenWidth.asObservable();
  }

  public get currentWidth(): string {
    return this._curWidth;
  }
  private _isMobile: ReplaySubject<boolean>;

  public get isMobile(): Observable<boolean> {
    return this._isMobile.asObservable();
  }

  public checkWidth() {
    const width = window.innerWidth;
    if (width < RESPONSIVE_BREAKPOINTS["sm"]) {
      this._curWidth = "xs";
      this._screenWidth.next("xs");
      this._isMobile.next(true);
    } else if (width >= RESPONSIVE_BREAKPOINTS["xs"] && width < RESPONSIVE_BREAKPOINTS["md"]) {
      this._curWidth = "sm";
      this._screenWidth.next("sm");
      this._isMobile.next(true);
    } else if (width >= RESPONSIVE_BREAKPOINTS["md"] && width < RESPONSIVE_BREAKPOINTS["lg"]) {
      this._curWidth = "md";
      this._screenWidth.next("md");
      this._isMobile.next(false);
    } else if (width >= RESPONSIVE_BREAKPOINTS["lg"] && width < RESPONSIVE_BREAKPOINTS["xl"]) {
      this._curWidth = "lg";
      this._screenWidth.next("lg");
      this._isMobile.next(false);
    } else {
      this._curWidth = "xl";
      this._screenWidth.next("xl");
      this._isMobile.next(false);
    }
  }
}
