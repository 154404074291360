
<form [formGroup]="form" (submit)="create()">
  <div class="row">
    <bsac-field-control class="col-sm-4" [form]="form" [model]="ea" field="type" *ngIf="!type">
      <div class="label">Type d'article</div>
    </bsac-field-control>
    <div class="col-sm-8" *ngIf="!ea.type">
      <p class="text-danger">Sélectionnez le type d'article</p>
    </div>
    <bsac-field-control class="col-sm-8" [form]="form" [model]="ea" field="client" *ngIf="ea.type==='CLI'" [related]="clients$" desctpl="&lt;% if(obj.parent_details) { %&gt;&lt;%= obj.parent_details.name %&gt; / &lt;%= obj.name %&gt; &lt;% } else { %&gt;&lt;%= obj.name %&gt; &lt;% } %&gt;" relatedLabel="Sélectionnez un client">
      <div class="label">Client</div>
    </bsac-field-control>
    <bsac-field-control class="col-sm-8" [form]="form" [model]="ea" field="tariftype" *ngIf="ea.type==='CLB'" [related]="tariftypes$" relatedLabel="Sélectionnez un tarif" descfield="name">
      <div class="label">Modèle tarif</div>
    </bsac-field-control>
    <bsac-field-control class="col-sm-8" [form]="form" [model]="ea" field="storage" *ngIf="ea.type==='STO'" [related]="storages$" relatedLabel="Sélectionnez un dépôt" descfield="name">
      <div class="label">Dépôt</div>
    </bsac-field-control>
    <bsac-field-control class="col-sm-8" [form]="form" [model]="ea" field="member" *ngIf="ea.type==='MEM'" [related]="members$" relatedLabel="Sélectionnez un adhérent" descfield="name">
      <div class="label">Adhérent</div>
    </bsac-field-control>
    <bsac-field-control class="col-sm-8" [form]="form" [model]="ea" field="provider" *ngIf="ea.type==='PRO'" [related]="providers$" relatedLabel="Sélectionnez un fournisseur" descfield="name">
      <div class="label">Fournisseur</div>
    </bsac-field-control>
    <bsac-field-control class="col-sm-8" [form]="form" [model]="ea" field="collection" *ngIf="ea.type==='ADG'" [related]="eacolls$" relatedLabel="Sélectionnez un groupe de dépôts" descfield="name">
      <div class="label">Groupe de dépôts</div>
    </bsac-field-control>
    <bsac-field-control class="col-sm-6" [form]="form" [model]="ea" field="code">
      <div class="label">Code article</div>
    </bsac-field-control>
    <bsac-field-control class="col-sm-6" [form]="form" [model]="ea" field="libelle">
      <div class="label">Libellé article</div>
    </bsac-field-control>
    <div class="col-6">
      <button class="btn btn-primary w-100"><i class="fa fa-save me-2"></i>Enregistrer</button>
    </div>
    <div class="col-6">
      <button class="btn btn-secondary w-100" role="button" (click)="cancelled.emit()"><i class="fa fa-crosshairs me-2"></i>Annuler</button>
    </div>
  </div>
</form>